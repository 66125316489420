import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {EventDataService} from "../../services/event_data.service";
import {HeaderDataService} from "../../services/header_data.service";
import {DateTimePeriod, DateTimePeriodService} from "../../services/datetime_period.service";
import * as utils from "../../lib/utils";
import {MatTableDataSource} from "@angular/material";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: 'events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.less', '../../tables/series-focus-table.component.less']
})
export class EventsComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    public events: any;
    public dtp: DateTimePeriod;
    dataSource: MatTableDataSource<any>;

    columns: string[] = ['Event Type', 'Messsage', 'Factor', 'Affected series',
        'Affected Component', 'Duration', 'Start', 'End'];

    @Input()
    public config: any;

    public period: any;

    constructor(public api: ApiService,
                public eventData: EventDataService,
                private dateTimePeriodService: DateTimePeriodService,
                public headerData: HeaderDataService) {
    }

    ngOnInit() {
        const ctrl = this;

        ctrl.dateTimePeriodService.dtp_complete.promise.then(() => {
            ctrl.period = utils.deepCopy(ctrl.dateTimePeriodService.defaultPeriod);
            ctrl.dtp = ctrl.dateTimePeriodService.dtp;
            ctrl.getEvents();

        });

        ctrl.dateTimePeriodService.dtpReset
            .pipe(takeUntil(this.onDestroy))
            .subscribe((dtp) => {
                ctrl.dtp = dtp;
                ctrl.getEvents()
            });
        ctrl.dataSource = new MatTableDataSource<any>([]);
        this.dataSource.filterPredicate = (data, filter) => {
            if (data.attributes.event_type_name.toLowerCase().includes(filter)/* ||
                data.attributes.comment.toLowerCase().includes(filter) ||
                data.attributes.factor.toLowerCase().includes(filter) ||
                data.attributes.series_names.toLowerCase().includes(filter) ||
                data.attributes.component_names.toLowerCase().includes(filter) ||
                data.attributes.start.toLowerCase().includes(filter) ||
                data.attributes.duration.toLowerCase().includes(filter) ||
                data.attributes.end.toLowerCase().includes(filter)*/) {
                return true;
            }
            return false;
        };

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    getEvents() {
        const ctrl = this;
        ctrl.eventData.getEvents(ctrl.dtp.start, ctrl.dtp.end).pipe(takeUntil(this.onDestroy)).subscribe(response => {
            // ctrl.dataSource.connect().next(ctrl.events);
            ctrl.events = response.data;
            ctrl.dataSource.data = (ctrl.events);
            console.log('Events receieved', ctrl.events);

        });
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

}
