<div class="column series-table-form">
  <div class="row">
    <ng-container *ngIf="processes?.length>0 && config.process">
      <div class="select-search" style="margin-right:0;" (mouseenter)="hint='Process'">
        <select-search [(value)]="selected_process"
                       [data]="processes"
                       [fill_stubs]="true"
                       [label]="'Select a process'"
                       (selectChange)="selectionChange($event, 'process')">
        </select-search>
      </div>
    </ng-container>
    <div style="align-self: center;font-weight: bold;padding:0 10px; justify-self: center;">or</div>
    <ng-container *ngIf="full_series_list?.length>0 && config?.series_list">
      <div class="select-search" (mouseenter)="hint='Series'" style="position:relative;">
        <select-many-search [(value)]="selected_series"
                            [data]="full_series_list"
                            [fill_stubs]="true"
                            [label]="'Select series'"
                            (selectChange)="selectionChange($event, 'series')"
        >
        </select-many-search>
      </div>
    </ng-container>

  </div>
  <div class="row">
    <mat-form-field *ngIf="estimate_types">
      <mat-label>Estimate type</mat-label>
      <mat-select [(value)]="config.estimate_type"
                  (selectionChange)="updateColumns()" multiple>
        <mat-option *ngFor="let estimate of estimate_types"
                    [value]="estimate.attributes.name">
          {{estimate.attributes.name| upperfirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>KPI level</mat-label>
      <mat-select multiple
                  [(value)]="config.kpis">
        <mat-option *ngFor="let column of seriesData.KPI_levels" [value]="column">
          {{column| upperfirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox style="align-self: center; min-width: unset; flex-grow: 0;"
            [(ngModel)]="config.show_comments" [ngModelOptions]="{standalone:true}">Show comments</mat-checkbox>
  </div>
  <div class="row" *ngIf="all_columns">
    <div class="example-container">
      <h2>Selected Columns</h2>
      <div
              cdkDropList
              #selectedList="cdkDropList"
              [cdkDropListData]="config.columns"
              [cdkDropListConnectedTo]="[availableList]"
              class="example-list"
              (cdkDropListDropped)="drop($event)">

        <div *ngFor="let item of config.columns" cdkDrag matTooltip="{{col_dict[item]?.description?col_dict[item]?.description:''}}"
             class="example-box">{{col_dict[item] ? col_dict[item].title : item + 'had not key'}}
          <!--<mat-checkbox style="text-align:right" (change)="mobileSelect($event)"></mat-checkbox>-->
        </div>
      </div>
    </div>

    <div class="example-container">
      <h2>Available Columns</h2>
      <div
              cdkDropList

              #availableList="cdkDropList"
              [cdkDropListData]="all_columns.all"
              [cdkDropListConnectedTo]="[selectedList]"
              class="example-list"
              (cdkDropListDropped)="drop($event)">
        <div *ngFor="let item of all_columns.all" cdkDrag matTooltip="{{col_dict[item]?.description?col_dict[item]?.description:''}}"
             class="example-box">{{col_dict[item] ? col_dict[item].title : item + 'had not key'}}</div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="all_columns">
    <div class="example-container">
      <h2>Selected Mobile Columns</h2>
      <div
              cdkDropList
              #selectedListMobile="cdkDropList"
              [cdkDropListData]="config.mobile_columns"
              [cdkDropListConnectedTo]="[availableListMobile]"
              class="example-list"
              (cdkDropListDropped)="drop($event)">

        <div *ngFor="let item of config.mobile_columns" cdkDrag matTooltip="{{col_dict[item]?.description?col_dict[item]?.description:''}}"
             class="example-box">{{col_dict[item] ? col_dict[item].title : item + 'had not key'}}
          <!--<mat-checkbox style="text-align:right" (change)="mobileSelect($event)"></mat-checkbox>-->
        </div>
      </div>
    </div>

    <div class="example-container">
      <h2>Available Mobile Columns</h2>
      <div
              cdkDropList
              #availableListMobile="cdkDropList"
              [cdkDropListData]="all_columns.mobile"
              [cdkDropListConnectedTo]="[selectedListMobile]"
              class="example-list"
              (cdkDropListDropped)="drop($event)">
        <div *ngFor="let item of all_columns.mobile" cdkDrag matTooltip="{{col_dict[item]?.description?col_dict[item]?.description:''}}"
             class="example-box">{{col_dict[item] ? col_dict[item].title : item + 'had not key'}}</div>
      </div>
    </div>
  </div>

</div>