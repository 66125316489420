<div>
  <div class="row">
    <select-many-search (selectChange)="updateSelected();updateConfig()"
                        *ngIf="event_types.length>0 && content_type==='custom-events'"
                        [(value)]="selected_types"
                        [data]="event_types"
                        [fill_stubs]="true"
                        class="select-search"
                        label="Select event type">
    </select-many-search>

    <select-search (selectChange)="updateSelected($event.value);updateConfig()"
                   *ngIf="event_types.length>0 && content_type==='stockpile-events'"
                   [data]="event_types"
                   [fill_stubs]="true"
                   [value]="selected_types[0]"
                   class="select-search"
                   label="Select event type">
    </select-search>
  </div>

  <div>
    <mat-label>Custom events column order</mat-label>
  </div>

  <div class="column">
    <div (cdkDropListDropped)="reOrderList($event)" cdkDropList class="drop-list">
      <ng-container *ngIf="config.column_order">
        <div *ngFor="let option of config.column_order" cdkDrag class="drop-box">
          {{option| upperfirst}}
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row" style="margin-top:20px;">
    <mat-form-field>
      <mat-label>Non editable columns</mat-label>
      <mat-select [(ngModel)]="config.non_editable_columns" multiple name="non_editable_columns">
        <mat-option *ngFor="let column of config.column_order" [value]="column">
          {{column| upperfirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Hidden columns</mat-label>
      <mat-select [(ngModel)]="config.hidden_columns" multiple name="hidden_column_options">
        <mat-option *ngFor="let col of hidden_column_options" [value]="col">
          {{col| upperfirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>