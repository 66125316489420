/**
 * Created by phillip on 2017/07/18.
 */
/**
 * Created by phillip on 2016/07/17.
 */

import {Component, OnDestroy, OnInit} from "@angular/core";
import {HeaderDataService} from "../services/header_data.service";
import {PlantDataService} from "../services/plant_data.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: 'seriesSheetFocusView',
    templateUrl: 'series_sheet_focus_view.html'
})
export class SeriesSheetFocusViewCtrl implements OnInit, OnDestroy {
    components: any[];
    flowsheet: any;
    series: any;
    sheet_ready: boolean = false;
    process: any;
    params_subscription: Subscription;

    processID: string;

    constructor(private headerData: HeaderDataService,
                private plantData: PlantDataService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.params_subscription = ctrl.route.params.subscribe(params => {
            ctrl.sheet_ready = false;
            ctrl.processID = params.processID;

            ctrl.headerData.buttons = [];

            ctrl.flowsheet = ctrl.plantData.getFlowSheetData(ctrl.processID);
            ctrl.flowsheet.allDataFetched.promise.then(() => {
                ctrl.setupEditSheet();
                ctrl.process = ctrl.flowsheet.process_focus;
            });

            ctrl.buildHeader();
        });
    }

    ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
    }

    setupEditSheet() {
        const ctrl = this;
        ctrl.components = ctrl.plantData.components;
        ctrl.series = ctrl.flowsheet.selected_series;
        ctrl.series.$promise.then(() => ctrl.sheet_ready = true);
        ctrl.buildHeader();
    }

    refresh() {
        const ctrl = this;
        ctrl.sheet_ready = false;
        ctrl.flowsheet = ctrl.plantData.getFlowSheetData(ctrl.processID);
        ctrl.flowsheet.allDataFetched.promise.then(ctrl.setupEditSheet.bind(ctrl));
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Series List';
        ctrl.headerData.buttons = [
            {name: 'Refresh', func: ctrl.refresh.bind(ctrl), class: 'icon-refresh', params: {}}
        ];
    }
}
