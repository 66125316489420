import * as Handsontable from "handsontable"
import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {HeaderDataService} from "../services/header_data.service";
import * as _ from "lodash";
import {HotInstance} from "../services/hot-instance";

/**
 * This is called 'Report configuration' on the front-end
 */
@Component({
    selector: 'seriesComponentSheetView',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class SeriesComponentSheetViewCtrl implements OnInit {
    hot: HotInstance;
    @Input()
    process: any;
    @Input()
    components: any;
    @Input()
    seriescomponents: any;
    search: string;

    title: string;
    series_properties: any[];
    data: any;
    schema: any;
    column_list: any;

    load_pivot: boolean = false;
    load_history: boolean = false;

    series_light: any[];
    users: any[];

    constructor(private api: ApiService,
                private handsontableGenerator: HandsontableGenerator,
                private headerData: HeaderDataService) {
        this.hot = new HotInstance(["relationships.series.data.id"]);
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.headerData.title = 'Report Configuration';

        const promises = [];

        // TODO Filter for resource types
        if (ctrl.seriescomponents == null) {
            promises.push(ctrl.api.series_component.search().toPromise().then(response => ctrl.data = response.data));
        } else {
            ctrl.data = ctrl.seriescomponents;
        }

        promises.push(ctrl.api.series_property.search().toPromise().then(response => ctrl.series_properties = response.data));

        if (ctrl.components == null) {
            promises.push(ctrl.api.component.search().toPromise().then(response => ctrl.components = response.data));
        }

        promises.push(ctrl.api.series_light.search().toPromise().then(response => ctrl.series_light = response.data));
        promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));

        Promise.all(promises).then(()=>{ctrl.createTable()});

        ctrl.buildHeader();
    }

    createTable() {
        const ctrl = this;
        const componentLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.components);
        const seriesPropertiesLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.series_properties);
        const seriesLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.series_light);

        const userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);

        ctrl.schema = {
            id: null,
            type: 'series_component',
            attributes: {
                created_on: null,
                changed_on: null,
                report_group: null,
                can_edit: null,
                view_on_flowchart: null,
                series_order: null,
                latest_value: null
            },
            relationships: {
                series_property: {data: {id: null, type: 'series_property'}},
                series: {data: {id: null, type: 'series'}},
                component: {data: {id: null, type: 'component'}},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };

        if (ctrl.schema.relationships.process && ctrl.process !== undefined) {
            ctrl.schema.relationships.process.id = ctrl.process.id;
        }

        ctrl.column_list = [{
            data: 'attributes.report_group',
            title: 'Group',
        }, {
            data: 'attributes.series_order',
            title: 'Order',
            type: 'numeric',
        }, {
            data: 'attributes.can_edit',
            title: 'Edit',
            type: 'checkbox',
        }, {
            data: 'attributes.view_on_flowchart',
            title: 'FlowChart',
            type: 'checkbox',
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(seriesPropertiesLookups, 'series_property'),
            title: 'Property',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesPropertiesLookups.source,
            allowInvalid: false,
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(seriesLookups, 'series'),
            title: 'Series',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesLookups.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(componentLookups, 'component'),
            title: 'Component',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: componentLookups.source,
            allowInvalid: false,
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'created_by'),
            readOnly: true,
            title: 'Created By',
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'changed_by'),
            readOnly: true,
            title: 'Changed By',
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];

        const extraAfterChange = (changes: [number, string | number, any, any][], source: string) => {
            // This will fire after every afterChange event, if there is a noticeable performance hit, it can be limited with
            //      if (!['edit', 'CopyPaste.paste'].includes(source)) return;
            // though a few cases might be missing.
            ctrl.refreshTable();
        };

        ctrl.hot = ctrl.handsontableGenerator.generateTable(ctrl.api.series_component, ctrl.schema, ctrl.column_list, ctrl.hot,
            extraAfterChange.bind(ctrl));
        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.data;
        ctrl.hot.settings.rowHeaders = false;
        setTimeout(() => ctrl.hot.instance = new Handsontable(document.getElementById(ctrl.hot.handson_id), ctrl.hot.settings), 200);
    }

    refreshTable() {
        const ctrl = this;
        if (ctrl.hot.instance) {
            // @ts-ignore
            _.remove(ctrl.data, item => !(item.relationships.series.data && item.relationships.series.data.id));
            ctrl.hot.instance.alter('insert_row', ctrl.data.length, 0);
            ctrl.hot.instance.render();
        }
    }

    save() {
        //TODO figure out why when this saves everything goes blank, but it actually saves
        // It does not do this on the series_sheet
        const ctrl = this;
        const results = ctrl.hot.save();
        ctrl.data = results.data;
        this.refreshTable();
    }

    download() {
        const ctrl = this;
        ctrl.hot.download();
    }

    buildHeader() {
        const ctrl = this;
        if (ctrl.process) {
            ctrl.headerData.title = 'Report Configuration: ' + ctrl.process.attributes.name;
        } else {
            ctrl.headerData.title = 'Report Configuration';
        }
        ctrl.headerData.buttons = [
            {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save', params: {}},
            {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download', params: {}}
        ];
    }
}
