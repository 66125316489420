import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {ApiService} from "../services/api.service";
import {AppScope} from "../services/app_scope.service";
import {HttpClient} from "@angular/common/http";
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
    MatSnackBar,
    MatSnackBarRef,
    MatSort,
    MatTableDataSource,
    SimpleSnackBar,
    Sort
} from "@angular/material";
import {DateTimePeriodService} from "../services/datetime_period.service";
import * as utils from '../lib/utils';
import * as _ from "lodash";
import {EstimateDialogData, EstimateFormComponent} from "../forms/estimate-form.component";
import {Series, SeriesDataService} from "../services/series_data.service";
import {HeaderDataService} from "../services/header_data.service";

@Component({
    selector: 'estimate-data-sheet',
    templateUrl: 'estimate-data-sheet.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class EstimateDataSheetComponent implements OnInit, OnDestroy {
    @Input()
    seriesList: {
        attributes: {
            description: string,
            event_type_name?: string,
            kpi_level: string,
            name: string
        }, id: string, type: string
    }[];

    private _estimateList: any[];

    @Input()
    set estimateList(estimateList: any[]) {
        this._estimateList = estimateList;
        console.log('estimates updated');
    }

    get estimateList() {
        return this._estimateList;
    }

    @Input()
    permissions: any;
    @Input()
    forecastYear: number;
    @Input()
    selectedEstimate;
    @Input()
    kpis;

    @Input()
    process: any;

    dataSource: MatTableDataSource<any>;
    series_list: Series[] = [];
    private sort;

    all_headers: string[];
    col_headers: string[];
    date: Date;
    events: any;
    events_ready: boolean;
    forecast_year: number;
    full_series_list: {
        attributes: {
            description: string,
            event_type_name?: string,
            kpi_level: string,
            name: string
        }, id: string, type: string
    }[];
    minimum_headers: string[];
    months: any[];
    raw_data_map: {};
    selected_estimate: {
        attributes: {
            allow_calculations: boolean
            changed_by_name: string
            changed_on: string,
            created_by_name: string,
            created_on: string,
            description: string,
            name: string
        },
        id: string,
        relationships: any,
        type: string
    };

    @ViewChild(MatSort, {static: false}) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    series_parents: any[];
    series_summary: any[];
    show_detail: boolean;

    private loading_snackbar: MatSnackBarRef<SimpleSnackBar>;

    constructor(private api: ApiService,
                private appScope: AppScope,
                private http: HttpClient,
                private dialog: MatDialog,
                private dateTimePeriod: DateTimePeriodService,
                private changeDetectorRef: ChangeDetectorRef,
                private snackBar: MatSnackBar,
                private headerData: HeaderDataService,
                private seriesData: SeriesDataService) {
    }

    ngOnInit(): void {
        const ctrl = this;
        ctrl.headerData.title = 'Estimate Input Sheet';
        ctrl.loading_snackbar = ctrl.snackBar.open('Loading sheet');

        ctrl.full_series_list = utils.deepCopy(ctrl.seriesList);
        ctrl.series_list = utils.deepCopy(ctrl.estimateList);
        ctrl.forecast_year = ctrl.forecastYear;
        ctrl.selected_estimate = ctrl.selectedEstimate;
        ctrl.date = new Date();
        ctrl.series_summary = [];
        ctrl.series_parents = [];

        ctrl.events = null;
        ctrl.events_ready = false;
        ctrl.months = [];

        const estimateName = ctrl.selected_estimate.attributes.name;

        ctrl.col_headers = ['Average', 'Max', 'Min', 'Count', 'Sum',
            'Daily', 'Daily ' + estimateName,
            'MTD', 'MTD ' + estimateName, 'MTD Variance',
            'YTD', 'YTD ' + estimateName, 'YTD Variance'];
        ctrl.all_headers = utils.deepCopy(ctrl.col_headers);
        ctrl.minimum_headers = [
            'Daily', 'Daily ' + estimateName,
            'MTD', 'MTD ' + estimateName,
            'YTD', 'YTD ' + estimateName];
        ctrl.col_headers = ctrl.minimum_headers;
        ctrl.show_detail = false;
        ctrl.raw_data_map = {};

        ctrl.dateTimePeriod.dtp_complete.promise.then(() => {
            for (let i = 0; i < 12; i++) {
                const time_stamp = new Date(ctrl.forecast_year, i, 1, ctrl.dateTimePeriod.defaultStartHour + 1);
                ctrl.months.push(time_stamp.toISOString());
            }
            const promises = [];
            ctrl.getSeriesParentAll();
            ctrl.series_list.forEach(item => promises.push(ctrl.getSeriesData(item)));
            Promise.all(promises).then(() => {
                this.changeDetectorRef.markForCheck();
                this.events_ready = true;
                this.loading_snackbar.dismiss();
                ctrl.dataSource = new MatTableDataSource(ctrl.series_list)
            });

            var i, j, series_chunk, chunk = 5;
            for (i=0, j=Object.keys(ctrl.series_parents).length; i<j; i+=chunk) {
                series_chunk = Object.keys(ctrl.series_parents).slice(i,i+chunk);
                ctrl.getSeriesSummary(series_chunk);
            }


        });
    }

    ngOnDestroy(): void {
        if (this.loading_snackbar) {
            this.loading_snackbar.dismiss();
        }
    }

    getSeriesData(item) {
        const ctrl = this;

        return ctrl.api.raw_data.search(ctrl.api.prep_q([{
            name: 'series_id',
            op: 'eq',
            val: item.id
        }, {
            name: 'time_stamp',
            op: 'in',
            val: ctrl.months
        }])).toPromise().then(response => {
            response.data.map(function (row) {
                const iso_date = (new Date(row.attributes.time_stamp)).toISOString();
                ctrl.raw_data_map[item.id + iso_date] = row.id;
                item[iso_date] = ctrl.detectLimit(item, row);
            });

            item = ctrl.fillRow(item);
        });
    }

    getSeriesSummary(series_list) {
        const ctrl = this;
        const vars: {} = {
            series_list: series_list,
            start: ctrl.dateTimePeriod.dtp.start.toISOString(),
            end: ctrl.dateTimePeriod.dtp.end.toISOString(),
            return_type: 'json',
            estimate: ctrl.selected_estimate.attributes.name,
            format: "records",
            deepness: 2,
            columns: ctrl.col_headers
        };
        if (ctrl.process) {
            vars['process'] = ctrl.process.id;
        }
        console.log('Start getSeriesSummary chunk: ', new Date());
        const query = "/GetSeriesSummary" + '?' + utils.httpParamSerializer(vars);
        return ctrl.api.get(query).toPromise().then((stats: any[]) => {
            console.log('End getSeriesSummary chunk: ', new Date());
            // @ts-ignore
            stats.forEach(stat => {
                ctrl.series_list.map(series => {
                    if (stat.Name == ctrl.series_parents[series.relationships.parent.data.id].name) {
                        ctrl.series_summary[series.relationships.parent.data.id] = stat;
                    }
                });
            });
            ctrl.changeDetectorRef.detectChanges()
        });
    }

    getSeriesParentAll() {
        const ctrl = this;
        ctrl.full_series_list.forEach(series => {
            ctrl.series_list.map(item => {
                if (series.id == item.relationships.parent.data.id) {
                    ctrl.series_parents[series.id] = {
                        name: series.attributes.name,
                        description: series.attributes.description,
                        kpi_level: series.attributes.kpi_level
                    };
                }
            })
        })
    }

    fillRow(row) {
        const ctrl = this;
        let i = 0;
        let date_counter = new Date(ctrl.months[i]);

        while (i <= 11) {
            if (!row.hasOwnProperty(date_counter.toISOString())) {
                row[date_counter.toISOString()] = {
                    attributes: {value: null, series: row.id, time_stamp: date_counter.toISOString()},
                    type: 'raw_data'
                }

            } else if (!row[date_counter.toISOString()].hasOwnProperty('type')) {
                row[date_counter.toISOString()] = {
                    attributes: {value: null, series: row.id, time_stamp: date_counter.toISOString()},
                    type: 'raw_data'
                }
            }
            i++;
            date_counter = new Date(ctrl.months[i]);
        }
        return row
    }

    detectLimit(row, raw_data) {
        //reset to enable update
        raw_data.warning = false;
        raw_data.error = false;

        if (raw_data.attributes.value > row.attributes.hi || raw_data.attributes.value < row.attributes.low) {
            raw_data.warning = true;
            raw_data.error = false;
        }

        if (raw_data.attributes.value > row.attributes.hihi || raw_data.attributes.value < row.attributes.lowlow) {
            raw_data.error = true;
            raw_data.warning = false;

        }

        return raw_data
    }

    dataChange(value: string, row: any, time: string) {
        const ctrl = this;
        let raw_data = row[time];
        raw_data = ctrl.detectLimit(row, row[time]);

        raw_data.saving = true;
        const new_raw_data = {id: raw_data.id, type: 'raw_data', attributes: _.cloneDeep(raw_data.attributes)};
        new_raw_data.attributes.value = value;

        if (raw_data.hasOwnProperty('id')) {
            if (isNaN(parseFloat(raw_data.attributes.value))) {
                ctrl.api.raw_data.delete(raw_data.id).then(() => {
                    delete raw_data.id;
                    row[time].saving = false;
                    ctrl.detectLimit(row, row[time]);
                    ctrl.changeDetectorRef.markForCheck();
                });
            } else {
                console.log(new_raw_data);
                ctrl.api.raw_data.patch(new_raw_data).then(result => {
                    // ctrl.api.raw_data.patch(raw_data.id, {data: new_raw_data}).$promise.then(function (result) {
                    raw_data.saving = false;
                    ctrl.detectLimit(row, row[time]);
                    ctrl.changeDetectorRef.markForCheck();
                });
            }
        } else {
            if (isNaN(parseFloat(value))) {
                console.log('Data change: Not a number');
            } else {
                ctrl.api.raw_data.save(new_raw_data).then(result => {
                    row[time] = result.data;
                }, reason => {
                    console.log('Failed to save data change', reason);
                }).finally(() => {
                    raw_data.saving = false;
                    ctrl.detectLimit(row, row[time]);
                    ctrl.changeDetectorRef.markForCheck();
                });
            }
        }
        ctrl.changeDetectorRef.markForCheck();
    }

    getRowHeaders() {
        return ['Series', 'Estimate'].concat(this.col_headers).concat(this.months);
    }

    openEstimateDialog(event, series) {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig<EstimateDialogData>();

        dialogConfig.minWidth = '640px';

        dialogConfig.data = {
            selectedEstimateType: ctrl.selected_estimate.attributes.name,
            seriesList: ctrl.full_series_list,
            estimate: series,
            seriesData: ctrl.seriesData
        };

        let dialogRef: MatDialogRef<EstimateFormComponent, any> = this.dialog.open(EstimateFormComponent, dialogConfig);
        dialogRef.afterClosed().toPromise().then(response => {
            if (response) {
                console.log("%cDialog closed", 'font-size:large', response.data);
                // ctrl.refreshEstimates();
            }
        })

    }

    sortData(event: Sort) {
        const ctrl = this;

        console.log('sort', event);

        this.dataSource.sort = ctrl.sort;

        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case "Series":
                    return data.attributes.name;
                case "Estimate":
                    return data.attributes.description;

            }
        }
    }
}

