<!--<ng-container *ngIf="dataSource">-->
<!--TODO THIS FILTER FUNCTION CAN EASILY BE ADDED BY FIXING THE erroneous manner in which the sessions are being saved when tile changes are made-->
<!--    <input (keyup)="applyFilter($event.target.value)" placeholder="Filter">-->
<!--</ng-container>-->

<table *ngIf="dataSource!==undefined" mat-table [dataSource]="dataSource" class="events-table">

    <ng-container matColumnDef="{{columns[0]}}">
        <th mat-header-cell *matHeaderCellDef >{{columns[0]}}</th>
        <td mat-cell *matCellDef="let event">{{event.attributes.event_type_name}}</td>
    </ng-container>

    <ng-container matColumnDef="{{columns[1]}}">
        <th mat-header-cell *matHeaderCellDef>{{columns[1]}}</th>
        <td mat-cell *matCellDef="let event">{{event.attributes.comment}}</td>
    </ng-container>

    <ng-container matColumnDef="{{columns[2]}}">
        <th mat-header-cell *matHeaderCellDef>{{columns[2]}}</th>
        <td mat-cell *matCellDef="let event">{{event.attributes.factor}}</td>
    </ng-container>

    <ng-container matColumnDef="{{columns[3]}}">
        <th mat-header-cell *matHeaderCellDef>{{columns[3]}}</th>
        <td mat-cell *matCellDef="let event">{{event.attributes.series_names}}</td>
    </ng-container>

    <ng-container matColumnDef="{{columns[4]}}">
        <th mat-header-cell *matHeaderCellDef>{{columns[4]}}</th>
        <td mat-cell *matCellDef="let event">{{event.attributes.component_names}}</td>
    </ng-container>

    <ng-container matColumnDef="{{columns[5]}}">
        <th mat-header-cell *matHeaderCellDef>{{columns[5]}}</th>
        <td mat-cell *matCellDef="let event">{{event.attributes.duration_string}}</td>
    </ng-container>

    <ng-container matColumnDef="{{columns[6]}}">
        <th mat-header-cell *matHeaderCellDef>{{columns[6]}}</th>
        <td mat-cell *matCellDef="let event">{{event.attributes.start | date: 'yy-MM-dd HH:mm'}}</td>
    </ng-container>

    <ng-container matColumnDef="{{columns[7]}}">
        <th mat-header-cell *matHeaderCellDef>{{columns[7]}}</th>
        <td mat-cell *matCellDef="let event">{{event.attributes.end | date: 'yy-MM-dd HH:mm'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>

</table>
