import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ApiService} from "../services/api.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {AppScope} from "../services/app_scope.service";

@Component({
    selector: 'default-chart',
    template: '<generic-chart *ngIf="series_ready" [config]="config" style="height:100%;width:100%"></generic-chart>'
})
export class DefaultChartComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    private _series: Subject<any> = new Subject<any>();

    @Input()
    // @ts-ignore
    set series(series_input: any) {
        if (series_input && series_input.attributes && series_input.attributes.name) {
            // without the timeout, the subject does not seem 1to emit the series
            setTimeout(() => this._series.next(series_input));
        } else {
            this.api.series_light.search().pipe(takeUntil(this.onDestroy)).toPromise().then(response => {
                if (!response) return; // query canceled
                const series_list: any[] = response.data;
                const o = series_list.find(item => item.attributes.name === series_input)
                this._series.next(o);
            });
        }
    }

    // @ts-ignore
    get series(): Subject<any> {
        return this._series;
    }

    @Input() hide_comments: boolean;
    @Input() type: string;

    series_ready: boolean;
    config_series = [];
    labels: { title: string; sub_title?: string; };
    config: any = {};
    series_full: any;
    budget_palette: any;

    constructor(public api: ApiService, private appScope: AppScope) {
    }

    ngOnInit(): void {
        let ctrl = this;
        ctrl.series_ready = false;
        ctrl.config = {};

        if (this.appScope.config_name_map.hasOwnProperty("budget_palette")) {
            this.budget_palette = this.appScope.config_name_map.budget_palette.value;
        } else {
            this.budget_palette = {
                "actual_above": "teal",
                "actual_below": "maroon",
                "actual_cum": "darkturquoise",
                "budget": "orangered",
                "budget_cum": "darkorange",
                "budget_class": "dotted",
                "budget_cum_class": "dashed",
                "actual_cum_type": "line"
            }
        }
        ctrl.getData();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    addSeries(name: string, axis, type, cumulative, colour, tag, line_type?) {
        return {
            name: name,
            axis: axis,
            type: type,
            show_limits: false,
            cumulative: cumulative,
            colour: colour,
            tag: tag,
            line_type: line_type
        };
    }

    getData() {
        const ctrl = this;

        this.series.pipe(takeUntil(this.onDestroy)).subscribe(series => {
            const series_name = series.attributes.name;

            let query = ctrl.api.prep_q([{
                name: 'id',
                op: 'eq',
                val: series.id
            }], {'filter[single]': 1});

            ctrl.api.series.search(query).pipe(takeUntil(this.onDestroy)).subscribe(response => {
                ctrl.series_full = response.data;
                if (ctrl.series_full.attributes.chart_type == 'Budget Bar' || ctrl.type === 'budget-bar-chart') {
                    if (ctrl.series_full.length > 1) {
                    } else {
                        ctrl.config_series.push(ctrl.addSeries(series_name, 'y', 'bar', false, ctrl.budget_palette.actual_above, 'Actual'));
                        ctrl.config_series.push(ctrl.addSeries(series_name, 'y2', ctrl.budget_palette.actual_cum_type ? ctrl.budget_palette.actual_cum_type : 'line', true, ctrl.budget_palette.actual_cum, 'Actual (Cum)'));
                        const estimate_name = (ctrl.series_full.attributes.estimate_names.split(','))[0];
                        if (estimate_name) {
                            ctrl.config_series.push(ctrl.addSeries(estimate_name, 'y', 'line', false, ctrl.budget_palette.budget, 'Budget', ctrl.budget_palette.budget_class));
                            ctrl.config_series.push(ctrl.addSeries(estimate_name, 'y2', 'line', true, ctrl.budget_palette.budget_cum, 'Budget (Cum)', ctrl.budget_palette.budget_cum_class));
                        }
                    }
                } else {
                    //TODO the line chart etc
                    ctrl.config_series = [{
                        name: series_name,
                        axis: 'y',
                        type: 'line',
                        show_limits: true,
                        cumulative: false
                    }];
                }

                if (ctrl.series_full.attributes.description) {
                    ctrl.labels = {title: ctrl.series_full.attributes.description, sub_title: series_name};
                } else {
                    ctrl.labels = {title: series_name};
                }

                ctrl.config = {
                    series_list: ctrl.config_series,
                    labels: ctrl.labels,
                    hide_comments: ctrl.hide_comments,
                    budget_palette: ctrl.budget_palette
                };
                ctrl.series_ready = true;
            }, response => {
                console.error('Series request failed', response);
            });
        });
    }
}
