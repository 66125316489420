/**
 * Created by phillip on 2016/06/18.
 * Updated 2019/01/08
 */

import {Component, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {HeaderDataService} from "../services/header_data.service";

@Component({
    selector: 'eventPivot',
    templateUrl: 'event-pivot.component.html',
})

export class EventPivotComponent implements OnInit {

    pivot_config: any = {
        event_types: "",
        pivot_state: {},
        series_list: []
    };

    constructor(
        private http: HttpClient,
        public dateTimePeriodService: DateTimePeriodService,
        private headerData: HeaderDataService) {
    }

    ngOnInit() {
        this.headerData.title = 'Pivot Tables';

        this.headerData.show_dtp = true;
        this.headerData.add_refresh = true;
    }
}
