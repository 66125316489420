<div class="column">
  <div class="row">
    <mat-form-field (mouseenter)="hint='Section_Title_' + index">
      <mat-label>Title</mat-label>
      <input [(ngModel)]="section.title" [ngModelOptions]="{standalone: true}" matInput>
    </mat-form-field>
    <mat-form-field (mouseenter)="hint='Section_Class_' + index" class="drag-hide">
      <mat-label>Row Height Class</mat-label>
      <mat-select (selectionChange)="headerData.setSectionHeight(section)" [(ngModel)]="section.class"
                  [ngModelOptions]="{standalone: true}">
        <mat-option *ngFor="let class of headerData.section_classes"
                    [value]="class">{{class | titlecase}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="section.class !== 'auto'" [ngStyle]="{'align-self': draggable? 'center' : 'flex-end'}"
         style="padding: 0 20px; align-self: center;">OR
    </div>

    <div *ngIf="section.class !== 'auto'" class="row">
      <div class="column">
        <label>Row Height : {{section.string_height}}<span
            style="font-weight:normal;"> (overrides class)</span></label>
        <input (input)="headerData.getSectionHeight(section)"
               [(ngModel)]="section.height"
               [max]="section.fixed_height? 1000 : 100" [min]="section.fixed_height? 30 : 10" name="section_height"
               step="1"
               style="width:200px;margin-top:5px;" type="range">
      </div>
      <mat-checkbox
          (change)="headerData.getSectionHeight(section, {fixed: {min: 30, max: 1000}, unfixed: {min:10, max:100}})"
          [(ngModel)]="section.fixed_height"
          name="fixed_height"
          style="padding-left:20px;">Fixed height
      </mat-checkbox>
    </div>
    <div class="row" style="align-self: flex-end;">
      <button (click)=resetSection() mat-button matTooltip="Reset Section">
        <i class="fa fa-refresh self-center drag-hide"> </i>
      </button>
      <button (click)=removeRow() mat-button matTooltip="Delete Section">
        <i class="fa fa-trash self-center drag-hide"> </i>
      </button>
      <button (click)="saveSection()" *ngIf="!draggable" mat-button matTooltip="Save Section">
        <i class="fa fa-save self-center drag-hide"></i>
      </button>
    </div>

  </div>
</div>