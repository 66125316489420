import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {SeriesDataService} from '../../services/series_data.service';

@Component({
    selector: 'context-form',
    templateUrl: './context-form.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ContextFormComponent implements OnInit {
    full_series_list: any[];
    units: any[];
    series: any;
    selected_series: any[] = [];
    chart_types: any[] = [
        {name: 'Custom chart', value: 'custom-chart'},
        {name: 'Sparkline', value: 'sparkline'}
    ];
    hint: string;
    _config: any;
    columns: any = null;
    col_dict: any = null;

    series_changed: boolean = false;

    @Input()
    set config(config) {
        this._config = config;
    }

    get config() {
        return this._config;
    }

    constructor(private api: ApiService,
                private seriesData: SeriesDataService) {

    }

    ngOnInit() {
        const ctrl = this;
        if (ctrl.config.selected_series) {
            ctrl.api.series.getById(ctrl.config.selected_series.id).toPromise().then(result => {
                ctrl.series = result.data;
                ctrl.config.series_unit = ctrl.series.attributes.engineering_unit_name;
            })
        }
        //Set value defaults
        if (!ctrl.config.primary_value) {
            ctrl.config.primary_value = 'Value'
        }
        if (!ctrl.config.secondary_val_1) {
            ctrl.config.secondary_val_1 = 'Forecast'
        }
        if (!ctrl.config.secondary_val_2) {
            ctrl.config.secondary_val_2 = 'MTD'
        }

        ctrl.seriesData.$estimate_types.promise.then(response => {
            let estimate_type_names = response.map(est => est.attributes.name);
            ctrl.columns = ctrl.seriesData.fillColumns(estimate_type_names);

            //Remove selected and deprecated columns from the available list
            ctrl.columns.all = ctrl.columns.columns.filter(el => {
                return !el.deprecated === true
            }).map(el => el.name);
            ctrl.col_dict = ctrl.seriesData.column_dict;
        });

        Promise.all([ctrl.api.series_light.search().toPromise(), ctrl.api.engineering_unit.search().toPromise()]).then((response) => {
            ctrl.full_series_list = response[0].data;
            ctrl.units = response[1].data;

        });
        if (!this.config.chart_type) {
            this.config.chart_type = 'sparkline';
        }
        if (this.config.show_header === undefined) {
            this.config.show_header = false;
        }

    }

    updateChartParams() {
        const ctrl = this;
        console.log('ContextFormComponent - updateChartParams: ', ctrl.config.selected_series);
        if (ctrl.config['chart_type'] === 'custom-chart' &&
            (!ctrl.config['chart_config'] || ctrl.config['chart_config'] === {})) {
            ctrl.config['chart_config'] = {
                labels: {
                    title: null,
                    sub_title: null,
                    y_axis: null,
                    y2_axis: null
                },
                hide_comments: false,
                series_list: [{
                    axis: 'y',
                    colour: null,
                    cumulative: null,
                    name: null,
                    show_limits: false,
                    type: 'line'
                }]
            };
            //This will only be done the first time. After that it's up to you.
            if (ctrl.config.selected_series) {
                ctrl.config['chart_config'].series_list[0].name = ctrl.config.selected_series['attributes'].name;
            }
        }
    }

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    };
}
