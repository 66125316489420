/**
 * Created by phillip on 2017/01/05.
 */

import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import * as $ from 'jquery'
import 'jquery-ui-dist/jquery-ui'
import '../../../node_modules/pivottable/dist/pivot'
import '../../../node_modules/pivottable/dist/c3_renderers'
import '../../../node_modules/pivottable/dist/d3_renderers'
import '../../../node_modules/pivottable/dist/export_renderers'
import {HotInstance} from "../services/hot-instance";

@Component({
    selector: 'pivotTable',
    template: '<div class="someunknownclass" id="pivot_out" #pivot_out></div>',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class PivotCtrl implements OnInit, AfterViewInit {
    @ViewChild('pivot_out', {static: false}) el: ElementRef;

    @Input()
    hot: HotInstance;
    titles;
    rows;
    $: any;

    constructor() {
        console.log('pivot constructor')
    }

    ngOnInit(): void {
        console.log('pivot init');
        const ctrl = this;
        ctrl.titles = ctrl.hot.settings.columns.map(function (col) {
            return col.title;
        });
        ctrl.rows = ctrl.hot.instance.getData()
            .map(item => {
                let row = {};
                ctrl.titles.map((title, index) => row[title] = item[index]);
                return row
            });

    }

    ngAfterViewInit() {
        const ctrl = this;
        // @ts-ignore
        $(ctrl.el.nativeElement).pivotUI(
            ctrl.rows, {
                renderers: $.extend(
                    // @ts-ignore
                    $.pivotUtilities.renderers,
                    // @ts-ignore
                    $.pivotUtilities.c3_renderers,
                    // @ts-ignore
                    $.pivotUtilities.d3_renderers,
                    // @ts-ignore
                    $.pivotUtilities.export_renderers
                ),
                hiddenAttributes: [""],
                aggregatorName: 'Count',
                menuLimit: 1000
            }).show();
    }
}
