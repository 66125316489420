<div class="row">
  <select-many-search *ngIf="event_types&&event_types.length>0"
                      [data]="event_types"
                      [fill_stubs]="true"
                      [classes]="'fill'"
                      [(value)]="selected_types"
                      (selectChange)="updateConfig($event, 'event',null);"
                      label="Select Event Type"
                      class="select-search">
  </select-many-search>
</div>
<button (click)="addSeriesToPivotConfig()" mat-button>Add series to pivot table</button>
<div class="column">
  <ng-container *ngFor="let series of config.series_list; let i = index">
    <div class="row">

      <select-search (selectChange)="updateConfig($event, 'series',i);"
                     *ngIf="full_series_list.length>0"
                     [classes]="'fill'"
                     [data]="full_series_list"
                     [value]="getSeriesSelected(series)"
                     class="select-search"
                     label="Select Series">

      </select-search>
      <div>
        <mat-form-field class="custom" matTooltip="Select a custom range for this series if required.">
          <mat-label>Custom Range</mat-label>

          <mat-select (selectionChange)="changeRange($event,i)"
                      [value]="getRange(series,i)">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let range of dateTimePeriodService.ranges"
                        [value]="range.range">{{range.range | removeUnderscore | upperfirst}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="custom" matTooltip="Select custom period for this series if required.">
          <mat-label>Custom Period</mat-label>

          <mat-select (selectionChange)="changePeriod($event, i)" [value]="getPeriod(series,i)">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let period of dateTimePeriodService.sample_periods"
                        [value]="period">{{period.name | removeUnderscore | upperfirst}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label matTooltip="Add a custom label to this data source if required">Custom label</mat-label>

          <input [(ngModel)]="config.series_list[i].data_label" matInput
                 name="data_label"
          >
        </mat-form-field>
      </div>
      <i (click)="deleteSeries(i)" class="fa fa-trash" style="margin-left:5px;"></i>
    </div>

  </ng-container>

</div>


