<style>
  .selected-pink {
    background: #efefef !important;
  }
</style>
<div *ngIf="changes_flag==true" class="calc-warning">Please remember to update calculations.</div>
<ng-container *ngIf="appScope.isNotMobile">
  <table mat-table id="shift_table" class="shift_table table-bordered" cellspacing="0" *ngIf="sheet_ready && times.length > 0 && (!restricted_access ||
  (restricted_access===true && permissions.view_process_data))" [dataSource]="dataSource">

    <ng-container matColumnDef="SeriesGroup" sticky>
      <th mat-header-cell *matHeaderCellDef style="width:30px;">
        <div></div>
      </th>
      <!--<td class="th" [attr.rowspan]="report_groups[series.attributes.report_group].length"-->
      <ng-container *matCellDef="let series;">
        <td mat-cell *ngIf="report_groups[series.attributes.report_group].indexOf(series)===0" style="width:30px;"
            [rowSpan]="report_groups[series.attributes.report_group].length"
            [ngClass]="{'alt': report_groups_names.indexOf(series.attributes.report_group) % 2 == 0}">
          <div>
            <div [ngStyle]="changeWidth(report_groups[series.attributes.report_group].length)">{{series.attributes.report_group}}</div>
          </div>

        </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="SeriesName" sticky>
      <th mat-header-cell *matHeaderCellDef>
        <div>Series</div>
      </th>
      <td mat-cell *matCellDef="let series" class="align-left" [ngClass]="{'bold': hovering_events.includes(series.id)}">
        <div class="row center">
          <i class="edit-inline hide-xs" (click)="editSeries(series)"></i>
          <span *ngIf="series.attributes.description"
                title="{{series.attributes.name}} - {{series.attributes.description}}"
                style="white-space: nowrap;padding:2px 4px 2px 2px;">{{series.attributes.name}}
      </span>
          <span *ngIf="!series.attributes.description"
                title="{{series.attributes.name}}"
                style="padding:2px 4px 2px 2px;">{{ series.attributes.name}}
      </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="SeriesDescription" sticky>
      <th mat-header-cell *matHeaderCellDef>
        <div>Description</div>
      </th>
      <td mat-cell *matCellDef="let series" class="align-left" (focus)="setComment($event, series, null)"
          [ngClass]="{'bold': hovering_events.includes(series.id)}">
        <div *ngIf="series.attributes.description"
             title="{{series.attributes.name}} - {{series.attributes.description}}"
        >{{series.attributes.description}}
        </div>
        <div *ngIf="!series.attributes.description"
             title="{{series.attributes.name}}"
        >{{ series.attributes.name}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="SeriesComment">
      <th mat-header-cell *matHeaderCellDef>
        <div><i class="icon-comment-invert" style="padding-top:4px;"></i></div>
      </th>
      <td mat-cell *matCellDef="let series" class="align-center" (focus)="setComment($event, series, null)">
        <div *ngIf="series_events[series.id]" (mouseenter)="commentIconHover(series_events[series.id])"
             (mouseleave)="tileDataService.commentIconLeave.emit()"
             style="padding:2px 4px 2px 2px;"><i class="icon-inline-comment" (click)="toggleComments(true)"></i>
        </div>
      </td>
    </ng-container>

    <ng-container *ngFor="let column of columns; trackBy: trackByFunction" matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef>
        <div *ngIf="column === 'Value'">Aggregate</div>
        <div *ngIf="column !== 'Value'">{{column}}</div>
      </th>
      <td mat-cell *matCellDef="let series" (focus)="setComment($event, series, null)" tabindex="0">
        <!--[ngClass]="{'warning': series.status==2, 'error': series.status==3}"-->
        <ng-container *ngIf="column === 'Value'">
          <!--<div *ngIf="!(editAggregation && !series.saving && saveAllowed(series, dtp.start.toISOString()))"-->
          <div *ngIf="!editAggregation || series.saving || !saveAllowed(series, dtp.start.toISOString())"
               [ngClass]="{'warning': series.status==2, 'error': series.status==3}">
            <div class="align-right">{{series.total | significantNumbers:significant_numbers}}</div>
          </div>
          <input *ngIf="editAggregation && !series.saving && saveAllowed(series, dtp.start.toISOString())"
                 type="number" step="any"
                 (scroll)="$event.preventDefault();"
                 (change)="aggregationChange(series,series.total);"
                 [(ngModel)]="series.total" style="border:2px; width: 4em;"
                 matTooltip="Aggregation: {{series.attributes.aggregation}}, Sample period:{{series.attributes.sample_period}}">
        </ng-container>
        <ng-container *ngIf="column === 'Status'">
        <span class="fa flag flag--status fa-warning"
              [ngClass]="{'warning-none': series.status==1, 'warning' : series.status==2, 'error': series.status==3}">
        </span>
        </ng-container>
        <ng-container *ngIf="column !== 'Value' && column !=='Status'">
          <div class="align-right">{{series[column] | significantNumbers:significant_numbers}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container *ngFor="let time of times; trackBy: trackByFunction; let c=index;"
                  [matColumnDef]="getColumnNameForTime(time)">
      <th mat-header-cell
          *matHeaderCellDef
          [title]="(time.period_start | date:'MM-dd HH:mm') + ' - ' + ( time.period_end | date:'MM-dd HH:mm')">
        <span [textContent]=getColumnNameForTime(time)></span>
      </th>
      <td (click)="setFocus($event)" (focus)="setComment($event, series, time);"
          *matCellDef="let series; let r=index;" [id]="'td_' + r + '_' + c + '_' + tileDataService.id"
          class="align-right values" mat-cell tabindex="0">
        <input *ngIf="shouldShowInput(series,time)"
               (focus)="setComment($event, series, time)"
               [id]="'inputtd_' + r + '_' + c + '_' + tileDataService.id"
               step="any" tabindex="1"
               type="number"
               (scroll)="$event.preventDefault();"
               (change)="dataChange(series, time, $event)"
               [value]="series[time.period_end].attributes.value"
               [ngClass]="{'warning': series[time.period_end] && series[time.period_end].warning,
                'error': series[time.period_end] && series[time.period_end].error}"
               style="border:0px; width: 4em; "/>
        <div style="display: none;">{{series.attributes.value | significantNumbers:significant_numbers}}</div>

        <div *ngIf="series[time.period_end] && (series[time.period_end].saving || !saveAllowed(series, time.period_end) || editAggregation)">
        <span *ngIf="shouldShowFirst(series, time)">
          {{calc_data[series.attributes.name][time.period_end] | significantNumbers:significant_numbers}}</span>
          <span *ngIf="series.attributes.can_edit && !dtpCoarserGranularity(series) && !series.saving && series[time.period_end]">
          {{series[time.period_end].attributes.value | significantNumbers:significant_numbers}}</span>
          <span *ngIf="series.attributes.can_edit && dtpCoarserGranularity(series) && calc_data[series.attributes.name]">
          {{calc_data[series.attributes.name][time.period_end] | significantNumbers:significant_numbers}}</span>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="getRowHeaders();"></tr>
    <tr *matRowDef="let row; columns: getRowHeaders()" mat-row tabindex="999"></tr>
  </table>
</ng-container>

<ng-container *ngIf="!appScope.isNotMobile" class="mobile-log">
  <div *ngIf="restricted_access===true && !permissions.view_process_data">You do not have permission to
    view this data.
  </div>
  <div style="margin-bottom:30px;" *ngIf="sheet_ready && times.length > 0 && !restricted_access || (restricted_access===true && permissions.view_process_data)">

    <div *ngFor="let series of series_data" class="row" style="align-items:center; margin-top:10px; border-bottom: 1px solid #f1f1f1;">
      <label style="width: 54%;text-align:right;margin-right:10px;margin-left:10px;">
        <span>{{series.attributes | nameOrDescription}}</span>
      </label><br/>
      <div tabindex="0" (focus)="setComment($event, series, null)">
        <input type="number" step="any" [(ngModel)]="series[dtp.end.toISOString()].attributes.value"
               [ngModelOptions]="{updateOn: 'blur'}"
               (change)="dataChange(series, {period_start: dtp.start.toISOString(),period_end: dtp.end.toISOString()},$event);"
               [ngClass]="{warning: series[dtp.end.toISOString()] && series[dtp.end.toISOString()].warning,
               error: series[dtp.end.toISOString()] && series[dtp.end.toISOString()].error}"
               style="text-align:right; margin-bottom:10px; border:1px solid #ccc; width: 8em; height: 3em; padding:4px;cursor:pointer;"
               [ngStyle]="{'background-color': (series[dtp.end.toISOString()] && series[dtp.end.toISOString()].saving || !saveAllowed(series, dtp.end))? '#cccccc' : 'white'}"
               [disabled]="(series[dtp.end.toISOString()] && series[dtp.end.toISOString()].saving) || !saveAllowed(series, dtp.end)"/>

      </div>
      <div *ngIf="series_events[series.id]"
           style="padding:2px 4px 2px 2px;"><i class="icon-inline-comment" (click)="toggleComments(true)"></i>
      </div>
    </div>
  </div>
</ng-container>
