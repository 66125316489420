<style>
  body {
    background: url("static/images/img1-large.jpg") no-repeat center center fixed;
    background-color: #cccccc;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .mat-tab-body {
    padding-bottom: 25px !important;
  }
</style>

<div class="column" style="height:100%">

  <div class="column loginBox md-whiteframe-z1" flex>
    <div class="row " style="justify-content:space-between;min-height:70px;">
      <img class="mms" flex src='static/images/mms-logo-titled-beneath.png'/>
      <img class="wire-img" style="height:48px;" src='static/images/wire-logo.gif'/>
    </div>
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Login">

        <form (ngSubmit)="onSubmitLogin()" [formGroup]="loginForm" class="column">

          <div *ngIf="!require_otp" class="form-group row">
            <label for="username">Username</label>
            <input [ngClass]="{ 'is-invalid': submittedLogin && f.username.errors }" class="form-control"
                   formControlName="username" id="username"
                   type="text"/>
            <div *ngIf="submittedLogin && f['username.errors']" class="invalid-feedback">
              <div *ngIf="f['username.errors'].required">Username is required</div>
            </div>
          </div>
          <div *ngIf="!require_otp" class="form-group row">
            <label for="password">Password</label>
            <input [ngClass]="{ 'is-invalid': submittedLogin && f.password.errors }" class="form-control"
                   formControlName="password" id="password"
                   type="password" appPassword/>
            <div *ngIf="submittedLogin && f['password.errors']" class="invalid-feedback">
              <div *ngIf="f['password.errors'].required">Password is required</div>
            </div>
          </div>
          <div *ngIf="require_otp" class="form-group row">
            <label for="otp">One Time Pass</label>
            <input [ngClass]="{ 'is-invalid': submittedLogin && f.otp.errors }" class="form-control"
                   formControlName="otp" id="otp"
                   placeholder="Please enter your one time password"
                   type="text"/>
            <div *ngIf="submittedLogin && f['otp.errors']" class="invalid-feedback">
              <div *ngIf="f['otp.errors'].required">OTP is required</div>
            </div>
          </div>
          <div class="row buttons" style="margin-top: 18px;">
            <button [disabled]="loading" class="mat-button">Login</button>
          </div>
          <div class="row" style="font-size: smaller;display: flex;justify-content: center;margin-top: 20px;">
            By using WIRE you consent to our
            <a href="https://docs.google.com/document/d/e/2PACX-1vTDJmM83RJ2cUd3LhVeIJhw07ECp6qL_cV5Z0_ctiH7ZeUBTj68XR5jSv0d6zIJC-y3Xa0WqcGGfwCL/pub"
               style="margin: 0 4px;font-weight: 600; color:#ec683b;"
               target="_blank"> terms
              and conditions</a>.
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Forgot Password">
        <form (ngSubmit)="onSubmitReset()" [formGroup]="forgotPasswordForm" class="column">
          <div class="row form-group">
            <label for="email"> Email Address</label>
            <input [ngClass]="{'is-invalid': submittedReset }" class="form-control" formControlName="email" id="email"
                   type="email"/>
          </div>
          <div class="row buttons">
            <button [disabled]="loading" class="mat-button">Send</button>
          </div>
        </form>
      </mat-tab>

      <mat-tab label="Forgot Password Token">
        <form [formGroup]="oneTimeLoginForm" style="height:inherit;overflow:hidden;">
          <div [hidden]="tokenSent" class="column" [ngClass]="{'hide-form':tokenSent , 'show-form':!tokenSent}">
            <div class="row form-group">
              <label for="email_one_time_login">Email Address</label>
              <input [ngClass]="{'is-invalid': submittedReset }" class="form-control" formControlName="email"
                     id="email_one_time_login"
                     type="email"/>
            </div>
            <div class="row buttons">
              <button [disabled]="loading" class="mat-button" (click)="onSubmitOneTime()">Request Token</button>
            </div>
          </div>
          <div [hidden]="!tokenSent" class="column" [ngClass]="{'hide-form':!tokenSent , 'show-form':tokenSent}">
            <div class="row form-group">
              <label> Access Token</label>
              <input formControlName="oneTimeToken"
                     id="one_time_token" />
            </div>

            <div class="row buttons">
              <button [disabled]="loading" class="mat-button" (click)="submitAccessToken()">Login</button>
            </div>
          </div>
        </form>
      </mat-tab>


    </mat-tab-group>
  </div>
</div>