
<mat-select *ngIf="palette == 1 && palette1.length > 0" [(ngModel)]="value" style="background:white;"
            name="color" aria-label="Colour picker"
            (selectionChange)="emitSelect($event)">
  <mat-select-trigger *ngIf="value=='#fff'">
    <div
        style="height:11px;width:11px;margin-left:10px;background-color:#fff;border:1px solid black; display:inline-block;"></div>
  </mat-select-trigger>
  <mat-select-trigger *ngIf="value!=='#fff'">
    <div [style.background-color]="value" class="colour-block"></div>
  </mat-select-trigger>
  <mat-option value="#fff"><span *ngIf="show_text">White </span>
    <div
        style="height:11px;width:11px;margin-left:10px; display:inline-block;background-color: #fff;border: 1px solid black"></div>
  </mat-option>
  <mat-option *ngFor="let colour of palette1" [value]="colour.colour">
    <div [style.background-color]="colour.colour" class="colour-block"></div>
    <span *ngIf="show_text"> {{colour.name}} </span>
  </mat-option>
</mat-select>

<mat-select *ngIf="palette == 2 && palette2.length > 0" [(ngModel)]="value"
            name="color" aria-label="Colour picker"
            (selectionChange)="emitSelect($event)">
  <mat-select-trigger>
    <div [style.background-color]="value" class="colour-block"></div>
  </mat-select-trigger>
  <mat-option value="#fff">
    <div class="colour-block"
         style="background-color: #fff; border: 1px solid black"></div>
    <span *ngIf="show_text">White </span>
  </mat-option>
  <mat-option *ngFor="let colour of palette2" [value]="colour.colour">
    <div [style.background-color]="colour.colour" class="colour-block"></div>
    <span *ngIf="show_text"> {{colour.name}} </span>
  </mat-option>
</mat-select>
