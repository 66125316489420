import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {DateTimePeriodService, SamplePeriod} from "../../services/datetime_period.service";
import {Series} from "../../services/series_data.service";
import {MatSelectChange} from "@angular/material/select";
import {CategoryChartConfiguration} from "./category-chart.configuration";

@Component({
    selector: 'category-chart-form',
    templateUrl: './category-chart-form.component.html',
    styleUrls: ['../forms.less', './category-chart-form.component.less']
})
export class CategoryChartFormComponent implements OnInit {
    @Input()
    config: CategoryChartConfiguration;

    @Output() configChange = new EventEmitter();
    full_series_list: Series[];

    public chart_types: string[] = ['bar', 'line'];

    constructor(private api: ApiService,
                public dateTimePeriodService: DateTimePeriodService,) {
    }

    ngOnInit() {
        const ctrl = this;
        ctrl.api.series_light.search().toPromise().then((result) => {
            ctrl.full_series_list = result.data;
        });
    }

    compareSamplePeriod(a: SamplePeriod, b: SamplePeriod): boolean {
        return a.seconds == b.seconds;
    }

    getSeriesSelected(series_id: string) {
        return this.full_series_list.find(series => series.id == series_id)

    }

    addSeriesToConfig() {
        this.config.series_list.push({
            name: null,
            series_id: null,
            type: "category",
            sample_period: null,
            number_of_periods: null,
            color: null,
            chart_type: 'bar'
        });
    }

    updateConfig(event: MatSelectChange, i: number) {
        this.config.series_list[i].name = event.value.attributes.name;
        this.config.series_list[i].series_id = event.value.id;
    }

    deleteSeries(i: number) {
        this.config.series_list.splice(i, 1);
    }
}
