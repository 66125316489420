<div style="margin-top: 100px;">
  <div class="container form-dialog register-form">
    <div>
      <h1>Register a new WIRE user
        <ng-container *ngIf="active_account">at {{active_account.attributes.name}}</ng-container>
      </h1>
    </div>
    <form [formGroup]="registerForm" class="form-section column space-between">
      <div class="row">
        <mat-form-field>
          <mat-label for="name">Username:</mat-label>
          <input (change)="nameChanged()"
                 [ngClass]="{'is-invalid': submittedRegister && registerForm.get('name').errors}" class="form-control"
                 formControlName="name"
                 id="name"
                 matInput/>
          <mat-hint *ngIf="submittedRegister && registerForm.get('name').invalid">Please enter a valid name.</mat-hint>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label for="email">Email:</mat-label>
          <input [ngClass]="{'is-invalid': submittedRegister && registerForm.get('email').errors}"
                 formControlName="email" id="email"
                 matInput
                 type="email"/>
          <mat-hint *ngIf="submittedRegister && registerForm.get('email').invalid">Please enter a valid email address.
          </mat-hint>
        </mat-form-field>
      </div>

      <div *ngIf="roles" class="row">
        <mat-form-field>
          <mat-label for="roles">Roles</mat-label>
          <mat-select (blur)="rolesLostFocus()" formControlName="roles"
                      id="roles"
                      multiple>
            <mat-option *ngFor="let role of roles"
                        [disabled]="disabled_roles.includes(role.attributes.name)"
                        [value]="role.attributes.name"
                        class="with-account-row">
              <span>{{role.attributes.name}}</span>
              <span *ngIf="role.attributes?.account_name"
                    class="account-name">
                {{role.attributes.account_name.trim()}}
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="row" style="justify-content: space-between;align-items: flex-end;">
      <button (click)="openDialog()" [disabled]="!user" [ngClass]="{'disabled': !user}" class="bordered-button"
              mat-button>Assign user rights
      </button>
      <button (click)="submit()" [disabled]="loading" class="btn" mat-button type='submit'>Submit</button>
    </div>
  </div>
</div>
