/**
 * Created by phillip on 2016/06/17.
 */
import {Component, OnDestroy, OnInit} from "@angular/core";
import {ApiService} from "../services/api.service";
import {AppScope} from "../services/app_scope.service";
import {HeaderDataService} from "../services/header_data.service";
import {ActivatedRoute} from "@angular/router";
import {PlantDataService} from "../services/plant_data.service";
import {EventDataService} from "../services/event_data.service";
import {UserData} from "../services/user_data.service";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'input-data-sheet-focus-view',
    template: '<div class="page traveler">' +
        '<page-tile *ngIf="input_sheet_ready" [dtp]="dateTimePeriod.dtp" [tile]="config" (tileChange)="tileChange($event)" class="column-tile tile-dtp"></page-tile>' +
        '</div>'
})
export class InputDataSheetFocusViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    config: any;
    // refresh: boolean;
    process: any;
    super_user: boolean;
    input_sheet_ready: boolean;
    events_ready: boolean;
    params_subscription: Subscription;

    constructor(private api: ApiService,
                private appScope: AppScope,
                private headerData: HeaderDataService,
                private route: ActivatedRoute,
                private plantData: PlantDataService,
                private eventData: EventDataService,
                private userData: UserData,
                private dateTimePeriod: DateTimePeriodService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
            ctrl.input_sheet_ready = false;
            ctrl.headerData.buttons = [];
            ctrl.config = {};
            // ctrl.refresh = false;

            const promises = [];
            promises.push(ctrl.api.process.getById(params.processID).toPromise().then(response => {
                return ctrl.process = response.data;
            }));
            promises.push(ctrl.dateTimePeriod.dtp_complete.promise);

            Promise.all(promises).then(() => {
                //ctrl.access = ctrl.plantData.getIsolatedPermissions(ctrl.process.id);
                ctrl.plantData.process_focus = ctrl.process;
                ctrl.dateTimePeriod.dtp = ctrl.dateTimePeriod.setDefault(ctrl.dateTimePeriod.dtp);

                //ctrl.config.process = ctrl.process;
                ctrl.config = {
                    parameters: {
                        process: ctrl.process,
                        //title: ctrl.process.attributes.description ? ctrl.process.attributes.description : ctrl.process.attributes.name
                    },
                    content: 'input-sheet',
                    show_dtp: true,
                    hide_edit: true
                };
                ctrl.userRefresh();
                ctrl.eventsRefresh();

                ctrl.buildHeader();

                ctrl.super_user = ctrl.userData.checkSuperUser(ctrl.appScope.current_user);

                ctrl.input_sheet_ready = true;
            });
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    userRefresh() {
        const ctrl = this;
        ctrl.userData.report = "Input" + ctrl.process.id;
        const default_json = {
            series: []
        };
        // userData.getUserSession(default_json).then(function(){
        //     //console.log(data);
        //     console.log(userData.user_session)
        // });
    }

    eventsRefresh() {
        const ctrl = this;
        // TODO not sure where 'series_list' comes from
        // TODO implement this method
        // FIXME this will do nothing as the events are not subscribed to
        ctrl.eventData.getEvents(ctrl.dateTimePeriod.dtp.start, ctrl.dateTimePeriod.dtp.end, null, [ctrl.process]);
        ctrl.events_ready = false;

        setTimeout(() => {
            ctrl.events_ready = true;
        }, 1000)
    }

    refreshDates() {
        const ctrl = this;
        // TODO replace refresh with migrated version of refreshing
        // ctrl.refresh = !ctrl.refresh;
        ctrl.eventsRefresh();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Data Review:' + ctrl.process.attributes.name;
        ctrl.headerData.show_dtp = true;
        ctrl.headerData.add_refresh = true;
        // ctrl.headerData.buttons = ctrl.headerData.buttons.concat([
        //         {name: 'Upload File', func: ctrl.openFileUploadDialog.bind(ctrl), class: 'fa fa-file-text'},
        //         {name: 'Save', func: ctrl.saveAll.bind(ctrl), class: 'icon-save'}
        //     ]);
        //     if(ctrl.permissions.override_calculations){
        //         ctrl.headerData.buttons.push(
        //             {name: 'Update Calculations',func: ctrl.overrideCalculations.bind(ctrl), class: 'fa small fa-calculator hide-xs'})
        //
        //     }
    }

    showEvents() {
        const ctrl = this;
        ctrl.eventsRefresh();
        ctrl.eventData.new_event.relationships.components.data.push(ctrl.process);
        ctrl.eventData.new_event.relationships.event_type.data = ctrl.eventData.commentType;
    }
}
