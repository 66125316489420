import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {HeaderDataService} from "../../services/header_data.service";
import * as c3 from 'c3';
import {ApiService} from "../../services/api.service";
import {MatDialog, MatSnackBar} from "@angular/material";
import {DateTimePeriodService} from "../../services/datetime_period.service";
import * as regression from 'regression';
import * as utils from "../../lib/utils";
import {takeUntil} from "rxjs/operators";
import {RecoveryGradeForecastFormComponent} from "./recovery-grade-forecast-form/recovery-grade-forecast-form.component";
import {Subject} from "rxjs";
import * as _ from 'lodash';
import {ChartConfiguration} from "c3";

@Component({
    selector: 'recovery-grade-forecast',
    templateUrl: './recovery-grade-forecast.component.html',
    encapsulation: ViewEncapsulation.None

})
export class RecoveryGradeForecastComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    full_series_list: any[] = [];
    selected_series: any[] = [];
    series_ids: string[] = [];
    x_axis_series: any;
    y_axis_series: any;
    chartData: any = {};
    x_axis_ticksActual: any[] = [];
    lineEquation: any = '';
    regressionData: any[] = [];
    lineReady: boolean = false;
    regressionResult: any;
    r2Result: string;
    predictedY: any[] = [];
    forecasts: any[];
    grade_forecast: any;
    grade_forecast_json: any = {
        data: {
            data_model: {
                labels: {
                    title: 'Grade recovery model input data',
                },
                type: 'scatter',
                series_list: [{
                    axis: 'x',
                    series_id: null
                }, {
                    axis: 'y',
                    series_id: null
                }],
                tile_config_forecast: {}

            }
        }
    };
    show_model: boolean;
    chart_config: ChartConfiguration;
    slider_value: number = 3.2;

    constructor(private headerData: HeaderDataService,
                private api: ApiService,
                private snackbar: MatSnackBar,
                private dateTimePeriodService: DateTimePeriodService,
                public dialog: MatDialog) {
    }

    ngOnInit() {

        const ctrl = this;
        ctrl.api.series_light.search().toPromise().then((result) => {
            if (!result) return;

            ctrl.full_series_list = result.data;

            ctrl.api.forecast_calculation.search().toPromise().then(response => {
                if (!response) return;
                ctrl.forecasts = response.data;
                // TODO Ivan what was the purpose of this? Is a feature missing?
                if (ctrl.forecasts.length == 0) {
                    ctrl
                }
                ctrl.forecasts.map(forecast => {
                    if (forecast.attributes.name == 'Recovery Grade Forecast') {
                        ctrl.grade_forecast = utils.deepCopy(forecast);
                        if (ctrl.grade_forecast.attributes.json) {
                            ctrl.grade_forecast_json = ctrl.grade_forecast.attributes.json;
                        }

                        if (ctrl.grade_forecast.attributes.json === null) {
                            ctrl.grade_forecast.attributes.json = ctrl.grade_forecast_json;

                        }
                    }
                });
                ctrl.show_model = true;
                let x_name = ctrl.grade_forecast_json.data.data_model.series_list.find(axis => axis.axis == 'x');
                let y_name = ctrl.grade_forecast_json.data.data_model.series_list.find(axis => axis.axis == 'y');
                ctrl.x_axis_series = ctrl.full_series_list.find(series => series.id == x_name.series_id);
                ctrl.y_axis_series = ctrl.full_series_list.find(series => series.id == y_name.series_id);

                this.dateTimePeriodService.dtp_complete.promise.then(() => {
                    ctrl.getData(ctrl.dateTimePeriodService.dtp);
                });

                ctrl.dateTimePeriodService.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
                    this.getData(ctrl.dateTimePeriodService.dtp);
                })

            });
        });

        ctrl.headerData.title = 'Recovery Grade Forecast (beta)';
        ctrl.headerData.show_dtp = true;
        ctrl.headerData.add_refresh = true;

    }

    ngOnDestroy(): void {
        try {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        } catch (e) {
        }
    }

    configureForecast() {
        const ctrl = this;
        let grade_forecast_copy = utils.deepCopy(ctrl.grade_forecast);
        const dialogRef = ctrl.dialog.open(RecoveryGradeForecastFormComponent, {data: {grade_forecast_copy}});
        dialogRef.afterClosed().subscribe(configuration => {
            console.log('returned config', configuration)
        });

    }

    getData(dtp) {
        const ctrl = this;

        ctrl.grade_forecast_json.data.data_model.series_list.forEach(axis => {
            let series = ctrl.full_series_list.find(series => series.id === axis.series_id);
            if (series) {
                ctrl.series_ids.push(series.id)
            }
        });

        ctrl.api.get('/GetData', {
            params: {
                series_list: ctrl.series_ids,
                start: dtp.start.toISOString(),
                end: dtp.end.toISOString(),
                sample_period: dtp.sample_period.wire_sample,
            }
        }).toPromise().then(response => {
            if (!response) return;
            if (ctrl.x_axis_series && ctrl.y_axis_series) {
                let xValues: any[] = Object.values(response.data[ctrl.x_axis_series.attributes.name]);
                let xValuesAndName = utils.deepCopy(xValues);

                xValuesAndName.unshift(ctrl.x_axis_series.attributes.name);
                ctrl.x_axis_ticksActual = utils.gen_xTick_values(xValues, 10);

                let yValues = Object.values(response.data[ctrl.y_axis_series.attributes.name]);
                let yValuesAndName = utils.deepCopy(yValues);

                yValuesAndName.unshift(ctrl.y_axis_series.attributes.name);
                ctrl.chartData['x'] = ctrl.x_axis_series.attributes.name;

                let columns = [];

                columns.push(yValuesAndName, xValuesAndName);

                ctrl.chartData['columns'] = columns;

                ctrl.regressionData = utils.zip(xValues, yValues);
                let cleanedRegressionData = [];

                ctrl.regressionData.forEach((pair, i) => {
                    if (!(pair[0] == 0 || pair[1] == 0)) {
                        if (pair[0] < 120 || pair[1] < 120) {
                            cleanedRegressionData.push(pair)
                        }
                        // ctrl.regressionData.splice(i,1);
                    } else {
                        console.log('removed', pair)

                    }
                });
                ctrl.regressionData = cleanedRegressionData;

                console.log('regression', ctrl.regressionData);
                ctrl.lineOfBestFit();
            }
        })
    }

    lineOfBestFit() {
        const ctrl = this;

        ctrl.lineEquation = '';

        ctrl.regressionResult = regression.power(ctrl.regressionData);
        console.log('regression result', ctrl.regressionResult);

        ctrl.lineEquation = 'Equation:' + ctrl.regressionResult.string;
        ctrl.r2Result = 'R2:' + ctrl.regressionResult.r2;
        ctrl.predictedY = ['regression'];
        ctrl.x_axis_ticksActual.map(x => {
            let p = ctrl.regressionResult.predict(x);
            ctrl.predictedY.push(p[1])
        });

        ctrl.chartData.columns.push(ctrl.predictedY);
        ctrl.chartData['types'] = {
            'regression': 'spline'
        };

        console.log('predictions', ctrl.predictedY);

        ctrl.generateChart();

    }

    generateChart() {
        const ctrl = this;

        ctrl.chartData['type'] = 'scatter';
        ctrl.chart_config = {
            bindto: '#actualDataCurve',
            data: ctrl.chartData,
            zoom: {
                enabled: true
            },
            axis: {
                x: {
                    label: {text: ctrl.x_axis_series.attributes.description, position: 'outer-center'},
                    tick: {values: ctrl.x_axis_ticksActual}
                },
                y: {label: {text: ctrl.y_axis_series.attributes.description, position: 'outer-middle'}}
            },
            legend: {
                show: false
            }
        };
        let actualDataCurve = c3.generate(ctrl.chart_config);
        console.log('chart data', ctrl.chartData);
        ctrl.generateModel()
    }

    generateModel() {
        const ctrl = this;

        let c_range = [];
        c_range = _.range(200, 255, 5);
        let c_div_f = [];
        c_range.map(el => c_div_f.push(el / ctrl.slider_value));
        let y_from_model = [];
        c_div_f.map(el => y_from_model.push(ctrl.regressionResult.predict(el)));
        let x_final = [];
        y_from_model.map((el, i) => {
            x_final.push(
                el * c_div_f[i]
            )
        });

        x_final.unshift('x_axis_model');
        c_range.unshift('y_axis_model');

        let model_columns = [];
        model_columns.push(x_final, c_range);

        ctrl.lineReady = true;
        let modeledDataCurve = c3.generate({
            bindto: '#modeledDataCurve',
            data: {
                columns: model_columns,
                type: 'spline',
                x: 'x_axis_model'
            },
            zoom: {
                enabled: true
            },
            axis: {
                x: {
                    label: {text: ctrl.x_axis_series.attributes.description, position: 'outer-center'},
                    tick: {values: ctrl.x_axis_ticksActual}
                },
                y: {label: {text: ctrl.y_axis_series.attributes.description, position: 'outer-middle'}}
            },
            legend: {
                show: false
            }
        });
    }
}
