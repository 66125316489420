import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {PlantDataService} from "../../services/plant_data.service";
import {MatSnackBar, MatSnackBarRef} from "@angular/material";
import {ApiService} from "../../services/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {ActivatedRoute} from "@angular/router";
import {DateTimePeriodService} from "../../services/datetime_period.service";
import {AppScope} from "../../services/app_scope.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ChartDialog} from '../../charts/chart-dialog.component';
import {asyncScheduler, Observable, Subject, Subscription} from "rxjs";
import {debounce, debounceTime, throttleTime} from "rxjs/operators";
import {MatSnackBarConfig} from "@angular/material/snack-bar/typings/snack-bar-config";

@Component({
    selector: 'flow-chart',
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'flowchart_template.html',
    styleUrls: ['flowchart.less']
})
export class FlowChartComponent implements OnInit, OnDestroy {
    public _object: any = Object;
    connectorSize: number = 10;
    @ViewChild('flow_chart_svg', {static: false}) flowchartSVG: ElementRef;
    @Input()
    public chart: any;
    flowchartReady = false;

    @Output() context_menu = new EventEmitter();

    mouseOver: any = {
        'connector': null,
        'stream': null,
        'process': null,
    };

    dragSelecting: boolean = false;
    dragSelectionRect: any = null;
    dragSelectionStartPoint: any = null;

    dragging: boolean = false;

    dragPoint1: any = null;
    dragPoint2: any = null;
    dragTangent1: any = null;
    dragTangent2: any = null;
    draggingStream: boolean = false;
    draggingBackwards: boolean = false;
    draggingFromConnector: any = null;

    lastMouseCoords: any;

    private snackbarEmitter: EventEmitter<{ message: string, action?: string, config?: MatSnackBarConfig }>;
    private snackbarSubscription: Subscription;

    constructor(private api: ApiService, private headerData: HeaderDataService,
                private seriesData: SeriesDataService, public appScope: AppScope,
                private plantData: PlantDataService, private route: ActivatedRoute,
                private dateTimePeriodService: DateTimePeriodService,
                private snackBar: MatSnackBar,
                public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.snackbarEmitter = new EventEmitter();
        this.snackbarSubscription = this.snackbarEmitter.pipe(throttleTime(1000, asyncScheduler, {
            leading: true,
            trailing: false
        })).subscribe(options => {
            this.snackBar.open(
                options.message,
                options.action,
                options.config
            );
        })
    }

    ngOnDestroy(): void {
        if (this.snackbarSubscription) {
            this.snackbarSubscription.unsubscribe();
        }
    }

    openCustomChartDialog(data: any): void {
        if (this.dragging === true) {
            this.dragging = false;
            return;
        }

        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {config: data, chart_component: 'custom-chart'};
        const dialogRef = this.dialog.open(ChartDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log('FlowChartComponent - result: ', result);
            }
        });
    }

    // Translate the coordinates so they are relative to the svg element.
    translateCoordinates(event) {
        let svg = this.flowchartSVG.nativeElement;
        let pt = svg.createSVGPoint();
        pt.x = event.x;
        pt.y = event.y;
        let matrix = svg.getScreenCTM();
        let pointerOffset = pt.matrixTransform(matrix.inverse());
        return pointerOffset;
    };

    processDown(event, el) {
        this.onDragStarted(el);
        this.lastMouseCoords = this.translateCoordinates(event);
    }

    onDragEnded(event, el) {
        //this.dragging = false;
        //event.stopPropagation(); //TODO how??
    }

    onDragStarted(el) {
        if (!el.selected()) {
            this.chart.deselectAll();
            el.select();
        }
    }

    //For dragging processes, text, series, groups, constants, equipment
    onDragging(event, el, group?) {
        let element = event.source.getRootElement();
        let rotate = '0';
        if (typeof el.rotate === "function" && (!group || group.name === null)) {
            rotate = el.rotate();
        }
        element.style.transform = 'rotate(' + rotate + 'deg)';
        if (this.chart.editmode || this.chart.streammode) {
            this.dragging = true;
            let lastMouseCoords = this.lastMouseCoords;
            let curCoords = this.translateCoordinates(event.event);
            let deltaX = curCoords.x - lastMouseCoords.x;
            let deltaY = curCoords.y - lastMouseCoords.y;
            this.updateElementsLocation(deltaX, deltaY, group);
            this.lastMouseCoords = curCoords;
        } else {
            this.snackbarEmitter.emit({
                message: 'Please use edit mode to drag',
                action: null,
                config: {duration: 3000}
            });
        }
    }

    contextMenu(event, element) {
        this.chart.deselectAll();
        if (element) {
            element.select();
            event.stopPropagation(); //prevent firing on parent when sub-element is clicked
            this.context_menu.emit({'event': event, 'element': element});
        }
    }

    elementClicked(event, element) {
        if (this.dragging === true) {
            this.dragging = false;
            return;
        }

        if (event.shiftKey === false && event.ctrlKey === false) {
            this.chart.deselectAll();
        }
        element.select();
        if (!element.is_parent) {
            event.stopPropagation(); //prevent firing on parent when sub-element is clicked
        }
    }

    startRectangle(event) {
        const ctrl = this;
        ctrl.chart.deselectAll();

        ctrl.dragSelecting = true;
        let startPoint = ctrl.translateCoordinates(event);
        ctrl.dragSelectionStartPoint = startPoint;
        ctrl.dragSelectionRect = {
            x: startPoint.x,
            y: startPoint.y,
            width: 0,
            height: 0,
        };
    }

    drawRectangle(event) {
        if (!this.dragSelecting) {
            return;
        }
        const ctrl = this;
        try {
            let startPoint = ctrl.dragSelectionStartPoint;
            let curPoint = ctrl.translateCoordinates(event);

            ctrl.dragSelectionRect = {
                x: curPoint.x > startPoint.x ? startPoint.x : curPoint.x,
                y: curPoint.y > startPoint.y ? startPoint.y : curPoint.y,
                width: curPoint.x > startPoint.x ? curPoint.x - startPoint.x : startPoint.x - curPoint.x,
                height: curPoint.y > startPoint.y ? curPoint.y - startPoint.y : startPoint.y - curPoint.y,
            };
        } catch (err) {
            this.dragSelecting = false;
            this.endRectangle()
        }
    };

    endRectangle() {
        // Dragging has ended... select all that are within the drag selection rect.
        if (this.dragSelecting) {
            try {
                this.chart.applySelectionRect(this.dragSelectionRect);
            } catch (err) {
                this.dragSelecting = false;
            }
        }
        this.dragSelecting = false;
        this.dragSelectionStartPoint = null;
        this.dragSelectionRect = null;
    }

    updateElementsLocation(deltaX, deltaY, group?) {
        let chart = this.chart;

        chart.updateSelectedPointsLocation(deltaX, deltaY);
        chart.updateSelectedProcessesLocation(deltaX, deltaY);
        chart.updateSelectedTextLocation(deltaX, deltaY);
        chart.updateSelectedSeriesLocation(deltaX, deltaY, group);
        chart.updateSelectedConstantsLocation(deltaX, deltaY);
        chart.updateSelectedEquipmentLocation(deltaX, deltaY);
        chart.updateSelectedCustomChartsLocation(deltaX, deltaY);
        chart.updateSelectedImagesLocation(deltaX, deltaY);
        chart.updateSelectedContextsLocation(deltaX, deltaY);

    };

    modelMouseEnter(evt, model): void {
        if (model.parent_component && model.parent_component.highlight) {
            model.parent_component.highlight();
        }
    };

    modelMouseLeave(evt, model) {
        if (model.parent_component && model.parent_component.unhighlight) {
            model.parent_component.unhighlight();
        }
    };

    //Handle mousedown on a series report_group
    seriesGroupMouseDown(event, group) {
        const ctrl = this;
        group.series.forEach(function (seriesView) {
            seriesView.select();
            ctrl.chart.selectedGroups[group.name] = true;
        });
        this.processDown(event, group.series[0]) //group.series[0] here is just so the seriesMouseDown function knows something is selected
    };

    groupClicked(event) {
        event.stopPropagation();
    }

    // Handle dblclick on (stream) text. //TODO this isn't working
    textDblClick(event, text) {
        if (this.chart.editmode || this.chart.streammode) {
            text.data.rotate += 90;
            if (text.rotate() == 360) {
                text.data.rotate = 0;
            }
            event.srcElement.style.transform = 'rotate(' + text.data.rotate + 'deg)';
        }
    };

    seriesDblClick(event, series) {
        if (this.chart.editmode || this.chart.streammode) {
            let el = event.srcElement.parentElement;
            let i = 0; //prevent some kind of endless loop if something goes wrong
            do {
                i = i + 1;
                el = el.parentElement;
            } while (el.parentElement && el.nodeName !== "foreignObject" && i < 10);

            series.data.attributes.json[series.tree_position]['text'].rotate += 90;
            if (series.rotate() == 360) {
                series.data.attributes.json[series.tree_position]['text'].rotate = 0;
            }

            el.style.transform = 'rotate(' + series.data.attributes.json[series.tree_position]['text'].rotate + 'deg)';
        }
    };

    draggingConnector(event, process, connector) { //cdkDragMoved from onDraggingConnector
        const ctrl = this;
        let element = event.source.getRootElement();
        element.style.transform = "none";
        let lastMouseCoords = this.lastMouseCoords; //connector.data.attributes.json['lastMouseCoords'];

        let curCoords = ctrl.translateCoordinates(event.event);
        let deltaX = curCoords.x - lastMouseCoords.x;
        let deltaY = curCoords.y - lastMouseCoords.y;

        let deltaPX = deltaX * 100 / (process.perimeter());
        let deltaPY = deltaY * 100 / (process.perimeter());

        let deltaP = connector.deltaP(deltaPX, deltaPY);

        ctrl.chart.updateSelectedConnectorsLocation(deltaP);

        this.lastMouseCoords = curCoords;
        //connector.data.attributes.json['lastMouseCoords'] = curCoords;
    }

    draggingConnectorEnded(event, connector) { //cdkDragEnded
        if (!this.chart.streammode) {
            this.lastMouseCoords = null; //.data.attributes.json['lastMouseCoords'] = null;
        } else {
            this.dragoutStreamEnd(connector);
        }
    }

    dragoutStream(event, connector) { //cdkDragMoved from onDraggingConnector
        const ctrl = this;
        if (this.chart.streammode) {
            let element = event.source.getRootElement();
            element.style.transform = "none";
            event.event.preventDefault();

            if (connector.allow_stream() == 'none' || connector.allow_stream() == 'input') {
                return;
            }

            ctrl.draggingStream = true;
            ctrl.dragPoint1 = {
                x: connector.x_abs(),
                y: connector.y_abs()
            };
            ctrl.dragPoint2 = ctrl.translateCoordinates({x: event.pointerPosition.x, y: event.pointerPosition.y});

            ctrl.dragTangent1 = ctrl.computeStreamStartTangent(ctrl.dragPoint1, ctrl.dragPoint2);
            ctrl.dragTangent2 = ctrl.computeStreamEndTangent(ctrl.dragPoint1, ctrl.dragPoint2);
        }
    }

    dragoutStreamEnd(connector) {
        const ctrl = this;
        if (this.chart.streammode) {
            if (ctrl.mouseOver.connector && ctrl.mouseOver.connector !== connector) {

                let mouseProcess = ctrl.mouseOver.connector;

                if (mouseProcess.allow_stream() == 'none' || mouseProcess.allow_stream() == 'output') {
                    ctrl.clearDragStream();
                    return;
                }

                let streamName = prompt("Enter a stream name:", "New Stream");
                if (streamName === null) {
                    ctrl.clearDragStream();
                    return;
                } else {

                    let new_stream_schema = {
                        attributes: {
                            base_type: 'stream', name: streamName,
                            json: {startConnectorID: connector.data.id, endConnectorID: mouseProcess.data.id}
                        }, type: 'stream', relationships: {
                            start: {data: {id: connector.parentProcess().data.id, type: 'process'}},
                            end: {data: {id: mouseProcess.parentProcess().data.id, type: 'process'}},
                            start_connector: {data: {id: connector.data.id, type: 'connector'}},
                            end_connector: {data: {id: mouseProcess.data.id, type: 'connector'}}
                        }
                    };

                    ctrl.api.stream.save(new_stream_schema).then(new_stream => {
                            window.console.log("Stream SAVED");
                            ctrl.chart.createNewStream(new_stream.data, connector, mouseProcess);

                            //Update the connectors for stream input output rules
                            connector.data.relationships.output_stream.data = {id: new_stream.data.id, type: 'stream'};
                            mouseProcess.data.relationships.input_stream.data = {id: new_stream.data.id, type: 'stream'};
                        }
                    );
                }
            }

            ctrl.clearDragStream();
        }
    }

    onDraggingConnector(event, process, connector) { //cdkDragMoved
        if (!this.chart.streammode) {
            //Allow connector to be selected and dragged
            //connector.select();
            this.draggingConnector(event, process, connector)
        } else {
            this.dragoutStream(event, connector);
        }
    }

    onStreamDown(event, stream) { //mousedown on stream
        const ctrl = this;
        let chart = this.chart;

        if (!chart.streammode) {
            //event.preventDefault();
            //elementClicked (event, stream);
        } else {
            ctrl.draggingFromConnector = null;
            this.draggingBackwards = false;
            let start_connector = chart.findConnector(stream.data.relationships.start.data.id, stream.data.relationships.start_connector.data.id);
            let end_connector = chart.findConnector(stream.data.relationships.end.data.id, stream.data.relationships.end_connector.data.id);

            let x1 = start_connector.x_abs();
            let x2 = end_connector.x_abs();
            let y1 = start_connector.y_abs();
            let y2 = end_connector.y_abs();

            let curCoords = ctrl.translateCoordinates(event);
            let d1 = Math.sqrt(Math.pow(x1 - curCoords.x, 2) + Math.pow(y1 - curCoords.y, 2));
            let d2 = Math.sqrt(Math.pow(x2 - curCoords.x, 2) + Math.pow(y2 - curCoords.y, 2));
            let tangent_point = d1 - d2;
            if (tangent_point < 0) {
                //Dragging started near to the start of the stream (output connector)
                ctrl.draggingFromConnector = end_connector;
                ctrl.draggingBackwards = true;
            } else {
                ctrl.draggingFromConnector = start_connector;
            }

        }
    }

    onStreamDragging(event, stream) { //cdkDragMove
        const ctrl = this;
        let element = event.source.getRootElement();
        element.style.transform = "none";
        event.event.preventDefault();

        if (this.chart.streammode) {
            ctrl.draggingStream = true;
            ctrl.dragPoint1 = {
                x: ctrl.draggingFromConnector.x_abs(),
                y: ctrl.draggingFromConnector.y_abs()
            };
            ctrl.dragPoint2 = ctrl.translateCoordinates({x: event.pointerPosition.x, y: event.pointerPosition.y});
            ctrl.dragTangent1 = ctrl.computeStreamStartTangent(ctrl.dragPoint1, ctrl.dragPoint2);
            ctrl.dragTangent2 = ctrl.computeStreamEndTangent(ctrl.dragPoint1, ctrl.dragPoint2);
        }
    }

    onStreamDragEnded(event, stream) {
        const ctrl = this;
        let start_connector = ctrl.chart.findConnector(stream.data.relationships.start.data.id, stream.data.relationships.start_connector.data.id);
        let end_connector = ctrl.chart.findConnector(stream.data.relationships.end.data.id, stream.data.relationships.end_connector.data.id);

        if (ctrl.mouseOver.connector && ctrl.mouseOver.connector !== ctrl.draggingFromConnector && ctrl.mouseOver.connector !== end_connector) {

            // The mouse is over a valid connector... Update the stream.

            let mouseProcess = ctrl.mouseOver.connector;

            if (mouseProcess.allow_stream() == 'none' || (mouseProcess.allow_stream() == 'output' && ctrl.draggingBackwards == false)
                || (mouseProcess.allow_stream() == 'input' && ctrl.draggingBackwards == true) || ctrl.draggingFromConnector.parentProcess().data.id == mouseProcess.parentProcess().data.id) {
                this.clearDragStream();
                return;
            }

            let streamName = prompt("Stream name:", stream.name());
            if (streamName === null) {
                this.clearDragStream();
                return;
            } else {
                //Check which end of the stream was dragged
                let startConnector = null;
                let endConnector = null;
                if (ctrl.draggingBackwards == true) {
                    //swap connector and mouseProcess
                    endConnector = ctrl.draggingFromConnector;
                    startConnector = mouseProcess;
                } else {
                    startConnector = ctrl.draggingFromConnector;
                    endConnector = mouseProcess;
                }
                ctrl.draggingFromConnector = null;

                stream.data.relationships.start.data.id = startConnector.parentProcess().data.id;
                stream.data.relationships.end.data.id = endConnector.parentProcess().data.id;
                stream.data.relationships.start_connector.data.id = startConnector.data.id;
                stream.data.relationships.end_connector.data.id = endConnector.data.id;
                //Save the stream
                ctrl.api.stream.patch(stream.data);

                //Update the viewModel plus all connector colours etc.
                stream.start = startConnector;
                stream.end = endConnector;

                if (startConnector.data.relationships.output_stream.data == null) {
                    startConnector.data.relationships.output_stream.data = {};
                }
                if (endConnector.data.relationships.input_stream.data == null) {
                    endConnector.data.relationships.input_stream.data = {};
                }

                startConnector.data.relationships.output_stream.data = {id: stream.data.id, type: 'stream'};
                startConnector.data.attributes.output_stream_name = stream.data.attributes.name;
                endConnector.data.relationships.input_stream.data = {id: stream.data.id, type: 'stream'};
                endConnector.data.attributes.input_stream_name = stream.data.attributes.name;

                //Update old connectors
                if (ctrl.draggingBackwards == true) {
                    start_connector.data.relationships.output_stream.data = null;
                    start_connector.data.attributes.output_stream_name = null;
                } else {
                    end_connector.data.relationships.input_stream.data = null;
                    end_connector.data.attributes.input_stream_name = null;

                }
                console.log("Stream SAVED");
            }
        }
        this.clearDragStream()
    }

    //
    // Helper function.
    //
    clearDragStream() {
        this.draggingStream = false;
        this.dragPoint1 = null;
        this.dragTangent1 = null;
        this.dragPoint2 = null;
        this.dragTangent2 = null;
    }

    mouseEnter(element) {
        this.mouseOver[element.data.type] = element;
    }

    mouseLeave(type) {
        this.mouseOver[type] = null;
    }

    computeStreamTangentOffset(pt1, pt2) {
        return (pt2.x - pt1.x) / 2;
    };

    // Compute the tangent for the bezier curve.
    computeStreamStartTangentX(pt1, pt2) {
        return pt1.x + this.computeStreamTangentOffset(pt1, pt2);
    };

    // Compute the tangent for the bezier curve.
    computeStreamStartTangentY(pt1, pt2) {
        return pt1.y;
    };

    // Compute the tangent for the bezier curve.
    computeStreamStartTangent(pt1, pt2) {
        return {
            x: this.computeStreamStartTangentX(pt1, pt2),
            y: this.computeStreamStartTangentY(pt1, pt2)
        };
    };

    // Compute the tangent for the bezier curve.
    computeStreamEndTangentX(pt1, pt2) {
        return pt2.x - this.computeStreamTangentOffset(pt1, pt2);
    };

    // Compute the tangent for the bezier curve.
    computeStreamEndTangentY(pt1, pt2) {
        return pt2.y;
    };

    // Compute the tangent for the bezier curve.
    computeStreamEndTangent(pt1, pt2) {
        return {
            x: this.computeStreamEndTangentX(pt1, pt2),
            y: this.computeStreamEndTangentY(pt1, pt2),
        };
    };

    genDraggingPath(dragPoint1, dragTangent1, dragPoint2, dragTangent2) {
        let path = 'M ' + dragPoint1.x + ',' + dragPoint1.y + ' C ' + dragTangent1.x + ',' + dragTangent1.y;
        path += ' ' + dragTangent2.x + "," + dragTangent2.y + ' ' + dragPoint2.x + "," + dragPoint2.y;
        return path
    };

//TODO add interaction
// 	var controller = this;
//
// 	//
// 	// Reference to the document and jQuery, can be overridden for testting.
// 	//
// 	this.document = document;
//
// 	//
// 	// Wrap jQuery so it can easily be  mocked for testing.
// 	//
// 	this.jQuery = function (element) {
// 		return $(element);
// 	};
//
// 	//
// 	// Init data-model variables.
// 	//
// 	$scope.draggingStream = false;
// 	connectorSize: string = 10;
// 	$scope.dragSelecting = false;
//
// 	// $scope.currentHeight = document.body.clientHeight;
// 	// $scope.currentWidth = document.body.clientWidth - $scope.sideNavWidth;
// 	if ($scope.chart) {
// 		$scope.currentHeight = $scope.chart.parent_process.data.attributes.json.windowHeight;
//
// 		$scope.currentWidth =  $scope.chart.parent_process.data.attributes.json.windowWidth;
// 	} else
// 	{
// 		$scope.currentHeight = window.innerHeight;
//
// 		$scope.currentWidth =  window.innerWidth;
//
// 	}
// 	/* Can use this to test the drag selection rect.

//
// 	//
// 	// Reference to the stream, connector,text or process that the mouse is currently over.
// 	//
// 	$scope.mouseOverConnector = null;
// 	$scope.mouseOverStream = null;
// 	$scope.mouseOverProcess = null;
// 	$scope.mouseOverEquipment = null;
// 	$scope.mouseOverProcessText = null;
// 	$scope.mouseOverStreamText = null;
// 	$scope.mouseOverSeriesText = null;
// 	$scope.mouseOverSeries = null;
// 	$scope.mouseOverConstant = null;
//
// 	//
// 	// The class for streams and connectors.
// 	//
// 	this.streamClass = 'stream';
// 	this.connectorClass = 'connector';
// 	this.processClass = 'process';
// 	this.equipmentClass = 'process';
// 	this.processTextClass = 'process-text';
// 	this.streamTextClass = 'stream-name';
// 	this.seriesTextClass = 'series-name';
// 	this.seriesClass = 'series';
// 	this.constantClass = 'constant';
//
// 	//
// 	// Search up the HTML element tree for an element the requested class.
// 	//
// 	this.searchUp = function (element, parentClass) {
//
// 		//
// 		// Reached the root.
// 		//
// 		if (element == null || element.length == 0) {
// 			return null;
// 		}
//
// 		//
// 		// Check if the element has the class that identifies it as a connector.
// 		//
// 		if (svg_utils.hasClassSVG(element, parentClass)) {
// 			//
// 			// Found the connector element.
// 			//
// 			return element;
// 		}
//
// 		//
// 		// Recursively search parent elements.
// 		//
// 		return this.searchUp(element.parent(), parentClass);
// 	};
//
// 	//
// 	// Hit test and retreive process and connector that was hit at the specified coordinates.
// 	//
// 	this.hitTest = function (clientX, clientY) {
//
// 		//
// 		// Retrieve the element the mouse is currently over.
// 		//
// 		return this.document.elementFromPoint(clientX, clientY);
// 	};
//
// 	//
// 	// Hit test and retrieve process and connector that was hit at the specified coordinates.
// 	//
// 	this.checkForHit = function (mouseOverElement, whichClass) {
//
// 		//
// 		// Find the parent element, if any, that is a connector.
// 		//
// 		var hoverElement = this.searchUp(this.jQuery(mouseOverElement), whichClass);
// 		if (!hoverElement) {
// 			return null;
// 		}
//
// 		return hoverElement.scope();
// 	};
//

//
// 	$scope.mouseClick = function (evt, object){
// 		if(object){
// 			$scope.$emit("selected", object, evt.ctrlKey);
// 			evt.stopPropagation();
// 			evt.preventDefault();
// 		}
// 		else {
// 			if(!evt.ctrlKey){
// 				$scope.$emit("selected", $scope.chart.parent_process);
// 			}
// 		}
// 	};
// 	//
// 	// Called on mouse down in the chart. Creates selection rectangle.
// 	//

// 	//
// 	// Called for each mouse move on the svg element.
// 	//
// 	$scope.mouseMove = function (evt) {
// 		//
// 		// Clear out all cached mouse over elements.
// 		//
// 		$scope.mouseOverStream = null;
// 		$scope.mouseOverStreamText = null;
// 		$scope.mouseOverConnector = null;
// 		$scope.mouseOverEquipment = null;
// 		$scope.mouseOverProcess = null;
// 		$scope.mouseOverProcessText = null;
// 		$scope.mouseOverSeriesText = null;
// 		$scope.mouseOverSeries = null;
// 		$scope.mouseOverConstant = null;
//
// 		var mouseOverElement = controller.hitTest(evt.clientX, evt.clientY);
// 		if (mouseOverElement == null) {
// 			// Mouse isn't over anything, just clear all.
// 			return;
// 		}
//
// 		if (!$scope.draggingStream) { // Only allow 'stream mouse over' when not dragging out a stream.
//
// 			// Figure out if the mouse is over a stream.
// 			var scope = controller.checkForHit(mouseOverElement, controller.streamClass);
// 			$scope.mouseOverStream = (scope && scope.stream) ? scope.stream : null;
// 			if ($scope.mouseOverStream) {
// 				// Don't attempt to mouse over anything else.
// 				return;
// 			}
// 		}
//
// 		// Figure out if the mouse is over a connector.
// 		var scope = controller.checkForHit(mouseOverElement, controller.connectorClass);
// 		$scope.mouseOverConnector = (scope && scope.connector) ? scope.connector : null;
// 		if ($scope.mouseOverConnector) {
// 			// Don't attempt to mouse over anything else.
// 			return;
// 		}
//
// 		// Figure out if the mouse is over a series.
// 		var scope = controller.checkForHit(mouseOverElement, controller.seriesClass);
// 		$scope.mouseOverSeries = (scope && scope.series) ? scope.series : null;
// 		if ($scope.mouseOverSeries) {
// 			// Don't attempt to mouse over anything else.
// 			return;
// 		}
// 		// Figure out if the mouse is over a constant.
// 		var scope = controller.checkForHit(mouseOverElement, controller.constantClass);
// 		$scope.mouseOverConstant = (scope && scope.constant) ? scope.constant : null;
// 		if ($scope.mouseOverConstant) {
// 			// Don't attempt to mouse over anything else.
// 			return;
// 		}
//
// 		// Figure out if the mouse is over a process.
// 		var scope = controller.checkForHit(mouseOverElement, controller.processClass);
// 		$scope.mouseOverProcess = (scope && scope.process) ? scope.process : null;
// 	};
//
// 	//Calls update location on all elements

//

//

// 	//Handle mousedown on a stream.
//

}