/**
 * Created by phillip on 2016/07/17.
 */

import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {PlantDataService} from "../services/plant_data.service";
import {ApiService} from "../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {HeaderDataService} from "../services/header_data.service";
import {SeriesDataService} from "../services/series_data.service";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'series-table-focus-view',
    template: '<div class="page traveler">' +
        '<page-tile *ngIf="sheet_ready" [dtp]="dateTimePeriodService.dtp" [tile]="config" (tileChange)="tileChange($event)" class="column-tile"></page-tile>' +
        '</div>',
})
export class SeriesTableFocusViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    config: any;
    sheet_ready: boolean;
    process: any;

    params_subscription: Subscription;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private seriesData: SeriesDataService,
                private plantData: PlantDataService,
                private route: ActivatedRoute,
                private dateTimePeriodService: DateTimePeriodService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.params_subscription = ctrl.route.params.subscribe(params => {
            this.sheet_ready = false;
            let p = ctrl.api.process.getById(params.processID).toPromise().then(response => {
                if (!response) return;
                ctrl.process = response.data
            });

            p.then(() => {
                ctrl.plantData.process_focus = ctrl.process;
                ctrl.buildHeader();
                ctrl.config = {
                    content: 'series-table',
                    hide_edit: true,
                    parameters: {
                        process: ctrl.process,
                        series_list: [],
                        columns: ['Status', 'Name', 'Description', 'Value', 'Forecast', 'MTD', "MTD Forecast"],
                        mobile_column: ['Description', 'Value', 'MTD'],
                        title: ctrl.process.attributes.name
                    }
                };
                ctrl.dateTimePeriodService.dtp_complete.promise.then(() => {
                    this.dateTimePeriodService.dtp = this.dateTimePeriodService.setDefault(this.dateTimePeriodService.dtp);
                });
                this.sheet_ready = true
            });
        });

        ctrl.headerData.downloadDataClicked.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.headerData.openDownloadDialog();
        });
    }

    ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    addSeries() {
        this.seriesData.upsertSeries(this.process).afterClosed().subscribe((series) => {

            this.dateTimePeriodService.dtp_complete.promise.then(() => {
                this.dateTimePeriodService.dtpReset.emit(this.dateTimePeriodService.dtp)
            });
        })
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Series Table: ' + ctrl.process.attributes.name;
        ctrl.headerData.show_dtp = true;
        ctrl.headerData.add_download = true;
        ctrl.headerData.add_refresh = true;

        ctrl.headerData.buttons = [
            {name: "Add", func: this.addSeries.bind(ctrl), params: {ctrl: this}, class: 'icon-add'}
        ];
    };

}

