import flowchart from '../lib/flowchart/flowchart_viewmodel'
import {AfterViewInit, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {PlantDataService} from "../services/plant_data.service";
import {MatSnackBar} from "@angular/material";
import {ApiService} from "../services/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {SeriesDataService} from "../services/series_data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {AppScope} from "../services/app_scope.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import * as utils from "../lib/utils";
import {FlowchartFormComponent} from "./edit_component_form";
import {Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {CustomChartFormDialogComponent} from '../forms/custom-chart-form-dialog/custom-chart-form-dialog.component';
import {ContextFormDialogComponent} from '../forms/context-form-dialog/context-form-dialog.component';

@Component({
    selector: 'flowchart-focus-view',
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'flowchart_focus_view.html'
})
export class flowchartFocusViewCtrl implements OnInit, AfterViewInit {
    formSize: number = 0;
    chartDataModel: any = {};
    chartViewModel: any = {};
    flowchartReady = false;
    parentFlowchartReady = false;
    parentChartViewModel: any;
    process_schema = {
        data: {
            attributes: {
                base_type: 'process',
                name: null,
                description: null,
                code: null
            }, relationships: {
                parent: {
                    data: {
                        id: this.route.snapshot.params.processID,
                        type: 'process'
                    }
                }
            }, type: 'process'
        }
    };
    equipment_schema = {
        data: {
            attributes: {
                base_type: 'equipment',
                name: null,
                icon: null,
            },
            relationships: {
                component: {data: null}
            },
            type: 'equipment'
        }
    };
    image_schema = {
        title: '',
        src: '',
        height: null,
        width: null,
        x: null,
        y: null,
        constrain_proportions: true,
        type: 'image'
    };
    menu: any;
    menuVisible: boolean = false;
    enableContext: boolean = true;
    selected_components: any[] = [];
    expandSize: number = 30;
    dtp_subscription: Subscription;

    params_subscription: Subscription;
    current_user: any;
    listener: any;
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService, private headerData: HeaderDataService,
                private seriesData: SeriesDataService, public appScope: AppScope,
                private plantData: PlantDataService, private route: ActivatedRoute,
                private dateTimePeriodService: DateTimePeriodService,
                private snackBar: MatSnackBar,
                public dialog: MatDialog,
                private router: Router) {
    }

    ngOnInit(): void {
        const ctrl = this;
        ctrl.listener = ctrl.clickListener.bind(ctrl);
        document.addEventListener("click", ctrl.listener);

        this.params_subscription = ctrl.route.params.subscribe(params => {
            this.appScope.auth_complete.promise.then(function () {
                ctrl.headerData.buttons = [];
                // ctrl.dtp = dateTimePeriodService.getDTP();
                ctrl.loadPage();
            });
        });
        this.dtp_subscription = ctrl.dateTimePeriodService.dtpReset.subscribe((dtp) => {
            ctrl.loadPage()
        });
    }

    ngOnDestroy(): void {
        const ctrl = this;
        if (this.params_subscription) {
            this.params_subscription.unsubscribe();
        }
        if (this.dtp_subscription) {
            this.dtp_subscription.unsubscribe();
        }
        document.removeEventListener("click", ctrl.listener);
    }

    loadPage = function () {
        const ctrl = this;
        this.current_user = this.appScope.current_user;
        ctrl.chartDataModel = ctrl.plantData.getFlowSheetData(ctrl.route.snapshot.params.processID);

        ctrl.chartDataModel.allDataFetched.promise.then(function () {
            ctrl.loadChartModel();
        });
    };

    loadChartModel = function () {
        const ctrl = this;
        ctrl.chartDataModel.parent_process = ctrl.chartDataModel.process_focus;
        ctrl.plantData.process_focus = ctrl.chartDataModel.process_focus;
        ctrl.title = ctrl.chartDataModel.parent_process.attributes.name;
        ctrl.buildHeader();
        ctrl.chartViewModel = new flowchart.ChartViewModel(ctrl.chartDataModel, {});
        if (!ctrl.chartDataModel.process_focus.attributes.json.images) {
            ctrl.chartDataModel.process_focus.attributes.json.images = [];
        }
        if (!ctrl.chartViewModel.parent_process.images) {
            ctrl.chartViewModel.parent_process.images = [];
        }

        ctrl.selected_components = [ctrl.chartViewModel.findProcess(ctrl.chartDataModel.process_focus.id)];
        ctrl.flowchartReady = true;

        //TODO reimplement ParentChart
        ctrl.plantData.getSeriesSummary(ctrl.chartDataModel, ctrl.dateTimePeriodService.dtp);
        if (ctrl.chartDataModel.parent_process.relationships.parent.data) {
            let parent_id = ctrl.chartDataModel.parent_process.relationships.parent.data.id;
            //     ctrl.loadParentChartModel((parent_id));
        }
    };

    loadParentChartModel(parent_id) {
        const ctrl = this;
        let chartDataModel = ctrl.plantData.getFlowSheetData(parent_id);
        chartDataModel.allDataFetched.promise.then(function () {
            chartDataModel.parent_process = chartDataModel.process_focus;
            ctrl.parentChartViewModel = new flowchart.ChartViewModel(chartDataModel, {
                scale: 100,
                colspan: 3,
                rowspan: 2
            }, null);
            ctrl.parentFlowchartReady = true;
        });
    };

    clickListener() {
        if (this.menuVisible === true) {
            this.toggleMenu('hide')
        }
    }

    contextMenu(e) { //emitted event
        if (this.enableContext) {
            e.event.preventDefault();
            this.menu = document.querySelector(".flowchart-context-menu");
            this.selected_components = [e.element];
            const origin = {
                left: e.event.pageX,
                top: e.event.pageY - 36
            };
            this.setPosition(origin);
            return false;
        }
    };

    toggleMenu(command) {
        this.menu = document.querySelector(".flowchart-context-menu");
        this.menu.style.display = command === "show" ? "block" : "none";
        this.menuVisible = command === "show" ? true : false;
    };

    setPosition({top, left}) {
        this.menu.style.left = `${left}px`;
        this.menu.style.top = `${top}px`;
        this.toggleMenu("show");
    };

    buildHeader = function () {
        const ctrl = this;
        ctrl.headerData.title = 'Flowchart: ' + ctrl.chartDataModel.parent_process.attributes.name;
        ctrl.headerData.show_dtp = true;
        ctrl.headerData.add_refresh = false;
        ctrl.headerData.add_new = false;

        ctrl.headerData.buttons = [
            {name: 'New Process', func: ctrl.newProcess.bind(ctrl), class: 'icon-new hide-xs'},
            {name: 'Edit Mode', func: ctrl.toggleEditMode.bind(ctrl), class: 'fa fa-arrows-alt hide-xs'},
            {name: 'Stream Mode', func: ctrl.toggleStreamMode.bind(ctrl), class: 'fa fa-external-link hide-xs'},
            // {name: 'Show Side Panel', func: ctrl.showSidePanel.bind(ctrl), class: 'fa fa-columns'},
            {name: 'Save All', func: ctrl.saveAll.bind(ctrl), class: 'icon-save hide-xs'},
            {name: 'Refresh', func: ctrl.loadPage.bind(ctrl), class: 'icon-refresh'}];
    };

    // $scope.$on("selected", function(event, chart_components, ctrl_event){
    //     if(chart_components.length > 1){
    //         ctrl.selected_components = chart_components;
    //     } else {
    //
    //         if (ctrl_event === true) {
    //             if (ctrl.selected_components.indexOf(chart_components) > -1) {
    //                 ctrl.selected_components.splice(ctrl.selected_components.indexOf(chart_components), 1);
    //             } else {
    //                 ctrl.selected_components.push(chart_components);
    //             }
    //         } else {
    //             ctrl.selected_components = [chart_components];
    //         }
    //     }
    // });

    ngAfterViewInit(): void {
    }

    expand() {
        const ctrl = this;
        if (ctrl.expandSize === 70) {
            ctrl.expandSize = 30;
        } else {
            ctrl.expandSize = 70;
        }
        ctrl.formSize = utils.deepCopy(ctrl.expandSize);
    };

    showSidePanel() {
        const ctrl = this;
        console.log(ctrl.formSize);
        if (ctrl.formSize === 0) {
            ctrl.formSize = utils.deepCopy(ctrl.expandSize);
        } else {
            ctrl.formSize = 0;
        }
    };

    openDialog(data: any): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = data;
        const dialogRef = this.dialog.open(FlowchartFormComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                ctrl.saveAll();
            }
        });
    }

    newProcess() { //called in buildHeader
        this.editSelected(null, true)
    }

    editCustomChart(chart: any = null, config: any = null, new_chart: boolean = true): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {'chart_component': 'custom-chart', 'config': config};
        dialogConfig.panelClass = 'chart-dialog';
        const dialogRef = this.dialog.open(CustomChartFormDialogComponent, dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            if (result) {
                let process = ctrl.chartDataModel.process_focus;
                console.log("close", result, process, process.attributes.json);
                if (!process.attributes.json["charts"]) {
                    process.attributes.json["charts"] = [];
                }
                if (new_chart === true) {
                    process.attributes.json["charts"].push(result);
                    this.plantData.saveItem(process);
                    ctrl.chartViewModel.addCustomChart(result, ctrl.chartViewModel.parent_process)
                } else {
                    chart.data = result;
                    process.attributes.json.charts[chart.index] = result;
                    this.plantData.saveItem(process);
                    ctrl.chartViewModel.parent_process.custom_charts[chart.index] = chart;
                }
                console.log("after", process.attributes.json, ctrl.chartDataModel.process_focus.attributes.json);
            }
        });
    }

    editSelectedCustomChart(chart) {
        this.editCustomChart(chart, chart.data, false);
    }

    editImage(image: any = null, config: any = null, new_image: boolean = true, selected_component: any = null): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        if (config === null) {
            config = this.image_schema;
            image = {data: config}
        }
        dialogConfig.data = {component: image, flowchart: ctrl.chartViewModel};
        dialogConfig.panelClass = 'image-dialog';
        const dialogRef = this.dialog.open(FlowchartFormComponent, dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            console.log('flowchartFocusViewCtrl - result: ', result);
            let is_parent = false;
            let component; //Parent of the image
            if (result && result.data) {
                config = result.data;
                if (selected_component) { //New image
                    component = selected_component;
                    component.data.attributes.icon = config.src; //Set for backward compatibility/use elsewhere
                } else { //Editing existing
                    component = image.parent_component;
                }
                console.log("close", utils.deepCopy(config), component);
                is_parent = component.is_parent;

                if (!component.data.attributes.json["images"] && is_parent) {
                    component.data.attributes.json["images"] = [];
                }
                if (new_image === true) {
                    //The parent process can have many images but child components can only have one
                    //The same component will use a different attribute (array vs object) depending on
                    //whether it is currently showing as a parent or child on the flowchart
                    if (is_parent) {
                        component.data.attributes.json["images"].push(config);
                    } else {
                        config.x = component.x() + (component.width() / 2 - 40);
                        config.y = component.y() + (component.height() / 2 - 34);
                        component.data.attributes.json.image = config;
                    }
                    ctrl.chartViewModel.addImage(config, component, is_parent)
                } else {
                    image.data = result.data;
                    console.log('flowchartFocusViewCtrl - image: ', image);
                    if (is_parent) {
                        component.data.attributes.json.images[image.index] = image.data;
                    } else {
                        component.data.attributes.json.image = image.data;
                    }
                }
                this.plantData.saveItem(component.data);
                console.log("after", component.data.attributes.json);
            }
        });
    }

    editSelectedImage(image) {
        this.editImage(image, image.data, false);
    }

    editContext(context: any = null, config: any = null, new_context: boolean = true): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {'title': context ? context.title : '', 'config': config};
        dialogConfig.panelClass = 'context-dialog';
        const dialogRef = this.dialog.open(ContextFormDialogComponent, dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            if (result) {
                let process = ctrl.chartDataModel.process_focus;
                console.log("close", result, process, process.attributes.json);
                if (!process.attributes.json["contexts"]) {
                    process.attributes.json["contexts"] = [];
                }
                if (new_context === true) {
                    process.attributes.json["contexts"].push(result);
                    this.plantData.saveItem(process);
                    ctrl.chartViewModel.addContext(result, ctrl.chartViewModel.parent_process)
                } else {
                    context.data = result;
                    process.attributes.json.contexts[context.index] = result;
                    this.plantData.saveItem(process);
                    ctrl.chartViewModel.parent_process.contexts[context.index] = context;
                }
                console.log("after", process.attributes.json, ctrl.chartDataModel.process_focus.attributes.json);
            }
        });
    }

    editSelectedContext(context) {
        this.editContext(context, context.data, false);
    }

    editSelected(process, new_process: boolean) {
        const ctrl = this;
        let component = {};
        if (process == null) { //parent_process can be passed in via hotkey
            if (new_process === true) {
                ctrl.process_schema.data.relationships.parent.data.id = ctrl.route.snapshot.params.processID;
                component = utils.deepCopy(ctrl.process_schema);
            } else if (ctrl.chartViewModel.getSelectedProcesses().length > 0) {
                component = ctrl.chartViewModel.getSelectedProcesses()[0];
            } else {
                if (ctrl.chartViewModel.getSelectedStreams().length > 0) {
                    component = ctrl.chartViewModel.getSelectedStreams()[0];
                    //return null
                } else if (ctrl.chartViewModel.getSelectedEquipment().length > 0) {
                    component = ctrl.chartViewModel.getSelectedEquipment()[0];
                } else {
                    component = ctrl.chartViewModel.parent_process;
                    //alert('Make sure a process is selected')
                }
            }
        }
        //ctrl.selected_components = [process];
        this.openDialog({component: component, flowchart: ctrl.chartViewModel});

        // When the 'enter' animation finishes...
        // function afterShowAnimation(scope, element, options) {
        //     // post-
        //     if (new_process===false){
        //         process.deselect();
        //     }
        //     ctrl.chartViewModel.deselectAll();
        //     mouseCapture.release();
        // }
    };

    //
    //
    // function editEquipmentForm(equipment) {
    //     if (equipment==null){
    //         if (ctrl.chartViewModel.getSelectedEquipment().length>0){
    //             equipment = ctrl.chartViewModel.getSelectedEquipment()[0]
    //         } else{
    //             alert('Make sure equipment is selected');
    //             return;
    //         }
    //     }
    //     ctrl.selected_components = [equipment];
    //     $mdDialog.show({
    //         templateUrl: 'edit_component_form.html',
    //         controller: componentFormCtrl,
    //         onComplete: afterShowAnimation,
    //         clickOutsideToClose: true,
    //         multiple: true,
    //         locals: { component: equipment, flowchart: ctrl, plantData: plantData}
    //     });
    //     // When the 'enter' animation finishes...
    //     function afterShowAnimation(scope, element, options) {
    //         // post-
    //         ctrl.chartViewModel.deselectAll();
    //     }
    // }
    //
    addNewEquipment() {
        const ctrl = this;
        let equipment = utils.deepCopy(this.equipment_schema);
        let parent_component = null;
        if (ctrl.chartViewModel.getSelectedProcesses().length > 0) {
            parent_component = ctrl.chartViewModel.getSelectedProcesses()[0];
            //component_type = "process";
        } else {
            if (ctrl.chartViewModel.getSelectedStreams().length > 0) {
                parent_component = ctrl.chartViewModel.getSelectedStreams()[0];
                //component_type = "stream";
            } else {
                parent_component = ctrl.chartViewModel.parent_process;
                // alert('Make sure a process or stream is selected')
                // return;
            }
        }
        equipment.data.relationships.component.data = parent_component.data;
        ctrl.selected_components = [equipment];
        this.openDialog({component: equipment, flowchart: ctrl.chartViewModel});
    }

    //
    saveAll() {
        const ctrl = this;
        // if (ctrl.access[0].attributes.flow_chart!=='Edit'){
        //     window.alert("You don't have access to save")
        // }
        //console.log(ctrl.chartDataModel);
        ctrl.chartDataModel.processes.forEach(item => this.plantData.saveItem(item));
        ctrl.chartDataModel.streams.forEach(item => this.plantData.saveItem(item));
        ctrl.chartDataModel.connectors.forEach(item => this.plantData.saveItem(item));
        ctrl.chartDataModel.equipment.forEach(item => this.plantData.saveItem(item));
        ctrl.chartDataModel.series_components.forEach(item => this.plantData.saveItem(item));
        ctrl.chartDataModel.constant_components.forEach(item => this.plantData.saveItem(item));

        this.plantData.saveItem(ctrl.chartDataModel.parent_process);
    };

    //
    viewDetail() {
        let selectedProcesses = this.chartViewModel.getSelectedProcesses();
        this.plantData.saveItem(this.chartDataModel.parent_process);

        this.plantData.previous_process_ids.push(this.plantData.process_focus);

        this.router.navigateByUrl('/view/flowchart/' + selectedProcesses[0].data.id).then(nav => {
            console.log(nav);
        });
    };

    // Add an input connector to selected processes.
    addNewConnector() {
        const ctrl = this;
        if (ctrl.chartViewModel.editmode == false) {
            ctrl.chartViewModel.showconnectors();
        }
        let connectorName = "";
        let selectedProcesses = ctrl.chartViewModel.getSelectedProcesses();
        if (selectedProcesses.length == 0) {
            selectedProcesses = [ctrl.chartViewModel.parent_process];
        }
        for (let i = 0; i < selectedProcesses.length; ++i) {

            let process = selectedProcesses[i];
            let percent = 10;
            if (process.connectors.length > 0) {
                percent = (process.connectors[process.connectors.length - 1].percent + 0.1) * 100;
                if (percent >= 100) {
                    percent = percent - 100;
                }
            }

            //Template for a new connector.
            let new_connector = {
                attributes: {
                    percent: percent,
                    name: connectorName,
                    process_id: process.data.id
                }, type: 'connector'
            };

            ctrl.api.connector.save(new_connector).then(newConnectorDataModel => {
                ctrl.chartViewModel.addConnector(newConnectorDataModel.data, process);
            });

        }
    };

    addPoint(stream) {
        stream.addPoint()
    };

    //Edit mode - show all connectors, points and grid?
    toggleEditMode() {
        const ctrl = this;
        if (ctrl.chartViewModel.editmode == true) {
            ctrl.chartViewModel.editmode = false;
            ctrl.hideEdit();
        } else {
            ctrl.chartViewModel.editmode = true;
            ctrl.showEdit();
        }
    };

    showEdit = function () {
        const ctrl = this;
        ctrl.chartViewModel.showconnectors();
        ctrl.chartViewModel.showpoints();
        ctrl.chartViewModel.showgrid();
    };

    hideEdit = function () {
        const ctrl = this;
        ctrl.chartViewModel.hideconnectors();
        ctrl.chartViewModel.hidepoints();
        ctrl.chartViewModel.hidegrid();
        if (ctrl.chartViewModel.streammode == true) {
            ctrl.chartViewModel.streammode = false;
        }
    };

    toggleStreamMode() {
        const ctrl = this;
        if (ctrl.chartViewModel.streammode == true) {
            ctrl.chartViewModel.streammode = false;
            if (ctrl.chartViewModel.editmode == false) {
                ctrl.hideEdit();
            }
        } else {
            ctrl.showEdit();
            ctrl.chartViewModel.streammode = true;
        }
    };

    toggleConnectors() {
        const ctrl = this;
        ctrl.chartViewModel.toggleConnectors();
        if (ctrl.chartViewModel.connectorsShown() == false) {
            ctrl.chartViewModel.streammode = false;
        }
    };

    toggleGrid() {
        const ctrl = this;
        ctrl.chartViewModel.toggleGrid();
    };

    // Remove selected equipment from parent component
    //
    removeSelected() {
        // const ctrl = this;
        // let removeItem = function (item) {
        //     console.log(item);
        //     item.data.relationships.component.data = null;
        //     let rem_item = api.equipment.patch(item.data.id, {data: item.data});
        //     rem_item.$promise.then(function(resp){
        //             $mdToast.show($mdToast.simple().textContent('Removed ' + item.name()).parent(document.querySelectorAll('#toaster')));
        //
        //
        //         },
        //     function(){
        //         $mdToast.show($mdToast.simple().textContent('Failed to remove ' + item.name() ).parent(document.querySelectorAll('#toaster')));
        //
        //         item.deselect();
        //
        //     });
        //     return rem_item.$promise;
        // };
        // let removed_equipment = ctrl.chartViewModel.getSelectedEquipment().map(removeItem);
        // $q.all(removed_equipment).then(function(){
        //     ctrl.chartViewModel.deleteSelected();
        // })
    };

    //Delete custom_chart json from process
    deleteSelectedCustomChart(chart) {
        const ctrl = this;

        //delete it from the process data
        ctrl.chartViewModel.parent_process.data.attributes.json.charts.splice(chart.index);
        //delete it from the parent process viewModels
        ctrl.chartViewModel.parent_process.custom_charts = ctrl.chartViewModel.parent_process.custom_charts.filter(
            chart_model => chart_model.index !== chart.index
        );
        this.plantData.saveItem(ctrl.chartViewModel.parent_process.data);
    }

    //Delete custom_chart json from process
    deleteSelectedImage(image) {
        const ctrl = this;
        //delete it from the process data
        if (image.parent_component === ctrl.chartViewModel.parent_process) {
            ctrl.chartViewModel.parent_process.data.attributes.json.images.splice(image.index);
            //delete it from the parent process viewModels
            ctrl.chartViewModel.parent_process.images = ctrl.chartViewModel.parent_process.images.filter(
                image_model => image_model.index !== image.index
            );
            this.plantData.saveItem(ctrl.chartViewModel.parent_process.data);
        } else {
            let parent_component = image.parent_component;
            parent_component.data.attributes.json.image = null;
            parent_component.data.attributes.icon = null;
            //delete it from the parent process viewModels
            parent_component.image = null;
            this.plantData.saveItem(parent_component.data);
        }
    }

    //Delete context json from process
    deleteSelectedContext(context) {
        const ctrl = this;

        //delete it from the process data
        ctrl.chartViewModel.parent_process.data.attributes.json.contexts.splice(context.index);
        //delete it from the parent process viewModels
        ctrl.chartViewModel.parent_process.contexts = ctrl.chartViewModel.parent_process.contexts.filter(
            context_model => context_model.index !== context.index
        );
        this.plantData.saveItem(ctrl.chartViewModel.parent_process.data);
    }

    // Delete selected processes and streams.
    deleteSelected() {
        const ctrl = this;
        let deleteItem = function (item) {
            let del_item = ctrl.api[item.data.type].delete(item.data.id);
            del_item.then(function (response) {
            }, function (response) {
                //$mdToast.show($mdToast.simple().textContent('Failed to delete ' + item.name() + ' ,it has relations ').parent(document.querySelectorAll('#toaster')));
                item.deselect();
            });

            return del_item
        };
        let deleted_process = ctrl.chartViewModel.getSelectedProcesses().map(deleteItem);

        let deleted_streams = ctrl.chartViewModel.getSelectedStreams().map(deleteItem);

        let deleted_equipment = ctrl.chartViewModel.getSelectedEquipment().map(deleteItem);

        let deleted_connectors = ctrl.chartViewModel.getSelectedConnectors().map(deleteItem);

        let deleted_series_components = ctrl.chartViewModel.getSelectedSeries().map(deleteItem);

        Promise.all(deleted_series_components.concat(deleted_connectors.concat(deleted_equipment.concat(deleted_streams.concat(deleted_process)))))
            .then(function () {
                ctrl.chartViewModel.deleteSelected();

            })

    };

    //
    // Create the view-model for the chart and attach to the scope.
    //
    //
    //  hotkeys.add({
    //     combo: 'del',
    //     description: 'Delete Selected',
    //     callback: function () {
    //         ctrl.chartViewModel.deleteSelected();
    //     }
    // });
    //
    // hotkeys.add({
    //     combo: 'del',
    //     description: 'Delete Selected',
    //     callback: function () {
    //         ctrl.deleteSelected();
    //     }
    // });
    //
    // hotkeys.add({
    //     combo: 'shift+d',
    //     description: 'Delete Selected',
    //     callback: ctrl.deleteSelected
    // });
    //
    // hotkeys.add({
    //     combo: 'shift+c',
    //     description: 'New Connector',
    //     callback: function () {
    //         ctrl.addNewConnector();
    //     }
    // });
    //
    // hotkeys.add({
    //     combo: 'shift+n',
    //     description: 'New Process',
    //     callback: function () {
    //         ctrl.editProcessForm(null, true);
    //     }
    // });
    // hotkeys.add({
    //     combo: 'shift+p',
    //     description: 'Edit Parent Process',
    //     callback: function () {
    //         ctrl.editProcessForm(ctrl.chartViewModel.parent_process);
    //     }
    // });
    // hotkeys.add({
    //     combo: 'shift+m',
    //     description: 'Stream Mode',
    //     callback: function () {
    //         ctrl.toggleStreamMode();
    //     }
    // });
    // hotkeys.add({
    //     combo: 'shift+s',
    //     description: 'Save All',
    //     callback: function () {
    //         ctrl.saveAll();
    //     }
    // });
    // hotkeys.add({
    //     combo: 'esc',
    //     description: 'Deselect',
    //     callback: function () {
    //         ctrl.chartViewModel.deselectAll();
    //     }
    // });
    // hotkeys.add({
    //     combo: 'shift+e',
    //     description: 'Edit Process',
    //     callback: function () {
    //         ctrl.editProcessForm();
    //         //process.deselect();
    //         ctrl.chartViewModel.deselectAll();
    //     }
    // });
    // hotkeys.add({
    //     combo: 'shift+g',
    //     description: 'Toggle Grid',
    //     callback: function () {
    //         ctrl.toggleGrid();
    //     }
    // });
    // hotkeys.add({
    //     combo: 'shift+t',
    //     description: 'Toggle Edit Mode',
    //     callback: function () {
    //         ctrl.toggleEditMode();
    //     }
    // });
    //
    // ctrl.menuOptions = [
    //     ['Zoom In', function ($itemScope) {
    //         ctrl.viewDetail()
    //     }],
    //     ['Delete', function ($itemScope, $event) {
    //         ctrl.deleteSelected()
    //     }],
    //     ['Save All', function ($itemScope) {
    //         ctrl.saveAll()
    //     }],
    //     ['Edit', function ($itemScope) {
    //         ctrl.editProcessForm();
    //     }],
    //     ['New Process', function ($itemScope) {
    //         ctrl.editProcessForm(null, true);
    //     }],
    //     ['New Connector', function ($itemScope) {
    //         ctrl.addNewConnector()
    //     }],
    //     ['Add Equipment', function ($itemScope) {
    //         addEquipmentForm();
    //     }],
    //     ['Remove Equipment', function ($itemScope) {
    //         ctrl.removeSelected();
    //     }],
    //     ['Toggle Stream Mode', function ($itemScope) {
    //         ctrl.toggleStreamMode()
    //     }],
    //     ['Edit Mode', function ($itemScope) {
    //         ctrl.toggleEditMode();
    //     }]
    //
    // ];

}

