<style>
  .hidden {
    display: none;
  }
</style>
<div style="width:100%; overflow:auto;height: auto;">
  <div #event_anchor *ngIf="hot" id="{{hot.handson_id}}">
  </div>
</div>

<h4 *ngIf="config.event_type_names == null || config.event_type_names.length === 0">
  Please select an event type.
</h4>
