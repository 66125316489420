/**
 * Created by phillip on 2016/06/13.
 * Updated 2019/01/09
 */

import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {AppScope} from "../services/app_scope.service";
import {PlantDataService} from "../services/plant_data.service";
import {ApiService} from "../services/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {FolderEntry, MenuTreeService, StaticEntry, StaticFolderEntry} from "../services/menu-tree.service";
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {map, takeUntil} from "rxjs/operators";
import {combineLatest, Subject} from "rxjs";
import {UserData} from '../services/user_data.service';
import {AccountService} from "../services/account.service";

@Component({
    selector: 'sidenavigation',
    templateUrl: 'side-nav.component.html',
    styleUrls: ['./side-nav.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class SideNavigation implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    isOpen = true;
    events: string[] = [];
    opened: boolean;
    reports: any[];
    plants: any[];
    selected_plant_id: any;
    selected_plant: any;
    user: any;
    restricted: boolean;

    treeControl = new NestedTreeControl((node) => node['contents']);
    dataSource = new MatTreeNestedDataSource();
    menu_groups: { [key: string]: string };
    mobile: boolean = false;
    show_plant: boolean = false;

    hasChild = (_: number, node: FolderEntry) => !!node.contents && node.contents.length > 0;
    selected_account_id: string;

    constructor(
        public plantData: PlantDataService,
        public appScope: AppScope,
        private api: ApiService,
        public headerData: HeaderDataService,
        private menuTreeService: MenuTreeService,
        private accountService: AccountService,
        private userData: UserData) {

    }

    ngOnInit() {
        const ctrl = this;
        this.appScope.auth_complete.promise.then(() => {
            ctrl.restricted = ctrl.appScope.current_user.restricted_access;
            ctrl.user = ctrl.appScope.current_user;
            ctrl.mobile = !ctrl.appScope.isNotMobile;

            //console.log('SideNavigation - current User: ', ctrl.user);
            ctrl.menu_groups = this.headerData.folder_options();

            //Sets appScope.$plants, appScope.plants, appScope.company and gets default plant and company/plant pages
            //This should probably run in appComponent maybe with multicast subscription or check behaviour sub for defaultsSet??

            // ctrl.appScope.defaults_map = map;
            //
            // if (ctrl.appScope.default_plant) {
            //     ctrl.appScope.selected_plant = ctrl.appScope.plants.find(p => p.id === ctrl.appScope.default_plant.id);
            //     //console.log('SideNavigation - selected: ', ctrl.appScope.selected_plant);
            //     //console.log('SideNavigation - selected[0]: ', ctrl.appScope.plants[0]);
            // } else {
            // ctrl.appScope.selected_plant = ctrl.appScope.plants[0];
            // ctrl.appScope.default_plant = utils.deepCopy(ctrl.appScope.selected_plant);
            // // });
            // ctrl.plants = ctrl.appScope.plants;
            // ctrl.selected_plant = ctrl.appScope.selected_plant;
            // const selected_plant_id = ctrl.appScope.selected_plant.id;

            const folders_source = this.menuTreeService.menuTreeChanged;
            const active_account_source = this.accountService.activeAccountChanged;

            combineLatest([folders_source, active_account_source])
                .pipe(
                    takeUntil(this.onDestroy),
                    map(([folders, active_account]) => {
                        return {folders, active_account};
                    }))
                .subscribe(response => {
                    const folders = response.folders;
                    const active_account = response.active_account;

                    if (!active_account) {
                        // the active account has not been determined yet
                        return;
                    }
                    if (ctrl.restricted) {
                        ctrl.buildRestrictedMenu(folders, active_account.account_id);
                    } else {
                        this.buildMenu(folders, active_account.account_id);
                    }
                });

            this.accountService.activeAccountChanged.pipe(takeUntil(this.onDestroy)).subscribe((active_account) => {
                // ctrl.selected_plant = selected_plant;
                ctrl.selected_account_id = active_account.account_id;

                ctrl.plantData.getPlants(ctrl.selected_account_id)
                    .pipe(takeUntil(this.onDestroy))
                    .subscribe(plants => {
                        this.plants = plants;
                        ctrl.appScope.plants = plants;
                        ctrl.appScope.selected_plant = plants[0];
                        // ctrl.appScope.default_plant = utils.deepCopy(ctrl.appScope.selected_plant);
                        ctrl.plants = plants;
                        ctrl.selected_plant = ctrl.appScope.selected_plant;
                    });

                this.api.process.search(this.api.prep_q([{
                    op: 'has',
                    name: 'component_type',
                    val: {name: 'name', val: 'ReportTree', op: 'eq'}
                }, {
                    op: 'eq',
                    name: 'account_id',
                    val: active_account.account_id
                }])).pipe(takeUntil(this.onDestroy)).subscribe(response => {
                    ctrl.reports = response.data;
                    ctrl.reports.map(item => {
                        item.is_report = true
                    });
                    console.log('got report: ', this.reports);
                });

                this.menuTreeService.refresh();
            })
        });
    }

    buildRestrictedMenu(account_folders, active_account_id: string) {
        const ctrl = this;

        if (ctrl.user.json && ctrl.user.json.length > 0) {
            const folder_name: string = ctrl.menu_groups['my_views'] ? ctrl.menu_groups['my_views'] : 'My Views';

            let folder = new StaticFolderEntry(folder_name);
            ctrl.user.json.forEach(item => {
                let static_entry = new StaticEntry(item.name, item.url);
                folder.contents.push(static_entry);
            });
            if (!account_folders[active_account_id]) {
                account_folders[active_account_id] = []
            }

            account_folders[active_account_id].unshift(folder);
        }

        this.dataSource.data = account_folders[active_account_id];
    }

    buildMenu(account_folders, active_account_id: string) {
        const ctrl = this;

        if (!this.restricted) {
            if (!account_folders[active_account_id]) {
                account_folders[active_account_id] = [];
            }
            if (!account_folders[active_account_id]['built']) {
                let folder = new StaticFolderEntry(
                    ctrl.menu_groups['insights'] ? ctrl.menu_groups['insights'] : 'Insights',
                    this.buildStatic('insights'));
                account_folders[active_account_id].push(folder);
                if (!ctrl.mobile) {
                    folder = new StaticFolderEntry(
                        ctrl.menu_groups['settings'] ? ctrl.menu_groups['settings'] : 'Settings',
                        this.buildStatic('settings'));
                    account_folders[active_account_id].push(folder);
                    account_folders[active_account_id]['built'] = true;
                }
            }
        }

        let static_entry = new StaticEntry('All Pages', '/view/page_list_view');
        account_folders[active_account_id].unshift(static_entry);

        this.dataSource.data = account_folders[active_account_id];
    }

    buildStatic(folder) {
        const ctrl = this;
        let contents = [];
        switch (folder) {
            case 'insights' :
                contents.push(new StaticEntry('Quick Charts', '/view/quick_charts/'));
                if (!ctrl.mobile) {
                    contents.push(
                        new StaticEntry('Value Driver Trees', '/view/value_driver_trees/'),
                        new StaticEntry('Pivot Tables', '/view/event_pivot'),
                        new StaticEntry('Water Balance Scenario', '/view/water_balance/'),
                        new StaticEntry('Stockpiles Scenario', '/view/stockpile_sheet/'),
                        new StaticEntry('Recovery Grade Forecast (beta)', '/view/recovery_grade_forecast/'),
                        new StaticEntry('Collection Events', '/view/collection_events/'),
                        new StaticEntry('Calculation Validation', '/view/calculation_check/'),
                        new StaticEntry('Permission Pivot', '/view/audit_pivot/')
                    )
                }
                break;
            case 'settings' :
                contents.push(new StaticEntry('Estimates Input Sheet', '/view/estimate_data_sheet/'),
                    new StaticEntry('Estimates', '/view/estimate_sheet/'),
                    new StaticEntry('Processes', '/view/edit_process/'),
                    new StaticEntry('Streams', '/view/edit_streams/'),
                    new StaticEntry('Series', '/view/edit_series/'),
                    new StaticEntry('Equipment', '/view/equipment_sheet/'),
                    new StaticEntry('Report Configuration', '/view/edit_series_components/'),
                    new StaticEntry('Shifts', '/view/shift_sheet/'),
                    new StaticEntry('Water Bodies', '/view/custom_process_sheet/WaterBody/'),
                    new StaticEntry('Mappers', '/view/collector_mapper_sheet/'),
                    new StaticEntry('Process Access', '/view/process_access_sheet/'),
                    new StaticEntry('Audit History', '/view/audit_history/'),
                    new StaticEntry('All Pages', '/view/page_list_view/'),
                );
                break;
        }

        // ctrl.appScope.mobile_sidenav.push(createMenuItem('Quick Charts', '/view/quick_charts/', '', true))
        return contents;
    }

    //TODO link to app scope to see process focus
    showCurrent() {
        return this.plants.concat(this.reports).map((item) => item.id
        ).indexOf(this.plantData.process_focus.id) === -1
    };

    toggleSideNav() {
        this.appScope.sidenav_open = !this.appScope.sidenav_open;
    }

    closeSidenav() {
        this.appScope.sidenav_open = false;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}

