<div>
  <div *ngIf="ready" class="row">
    <mat-form-field style="flex-grow:5;">
      <mat-label>Sub Title</mat-label>
      <input [(ngModel)]="config.labels.sub_title" matInput>
    </mat-form-field>

    <ng-container *ngIf="chart_type!='donut'">
      <mat-form-field>
        <mat-label>Y Axis Label</mat-label>
        <input [(ngModel)]="config.labels.y_axis" matInput>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Y2 Axis Label</mat-label>
        <input [(ngModel)]="config.labels.y2_axis" matInput>
      </mat-form-field>
    </ng-container>
  </div>

  <div *ngIf="chart_type=='donut'">
    <mat-form-field>
      <mat-label>Chart Title</mat-label>
      <input [(ngModel)]="config.chart_title" matInput>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-checkbox [(ngModel)]="config.hide_legend"
                  [disabled]="config.hide_axes===true"
                  class="self-center">
      Hide axis labels
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="config.hide_tick_labels"
                  [disabled]="config.hide_axes===true"
                  class="self-center">
      Hide value labels
    </mat-checkbox>
    <mat-checkbox (change)="updateAxes($event)"
                  [(ngModel)]="config.hide_axes"
                  class="self-center">
      Hide axes
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="config.hide_comments"
                  class="self-center">
      Hide Comments
    </mat-checkbox>
  </div>

  <div class="row">
    <mat-checkbox [(ngModel)]="config.set_range">Set Chart Range</mat-checkbox>
    <div *ngIf="config.set_range===true" class="row">

      <ng-container *ngIf="chart_type!='gauge'&&chart_type!='donut'&&chart_type!='pie'">
        <mat-form-field>
          <mat-label>Y Min</mat-label>
          <input [(ngModel)]="config.y_min" matInput style="width:120px;">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Y Max</mat-label>
          <input [(ngModel)]="config.y_max" matInput style="width:120px;">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Y2 Min</mat-label>
          <input [(ngModel)]="config.y2_min" matInput style="width:120px;">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Y2 Max</mat-label>
          <input [(ngModel)]="config.y2_max" matInput style="width:120px;">
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="chart_type=='gauge'">
        <mat-form-field>
          <mat-label>Min</mat-label>
          <input [(ngModel)]="config.min" matInput style="width:120px;">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Max</mat-label>
          <input [(ngModel)]="config.max" matInput style="width:120px;">
        </mat-form-field>
      </ng-container>

      <ng-container>
        <mat-form-field *ngIf="chart_type=='gauge'|| chart_type=='donut'">
          <mat-label>Width (px)</mat-label>
          <input [(ngModel)]="config.width" matInput style="width:120px;">
        </mat-form-field>
      </ng-container>

    </div>
  </div>
</div>

<custom-chart-form-series-table
    [(chart_type)]="chart_type"
    [(series_list)]="config.series_list">
</custom-chart-form-series-table>

<div style="height:400px;min-height:400px;">
  <generic-chart [config]="config" style="height: 320px;"></generic-chart>
</div>
<date-period [immutable]="true" [no_url]="true" class="date-period inline align-bottom"></date-period>
