import {Component, Inject, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {MatDialogRef} from "@angular/material";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ApiService} from "../services/api.service";
import {DateTimePeriodService} from "../services/datetime_period.service";

import * as utils from "../lib/utils";
import {Series} from "../services/series_data.service";

@Component({
    selector: 'FileDownloadForm',
    templateUrl: 'file_download_form.component.html',
    encapsulation: ViewEncapsulation.None,  //Global Styles
})

export class DownloadFormDialogComponent implements OnInit {
    public selected_data: any = {process: null};
    public process_list: any[] = [];
    showing_hints: boolean = false;
    hint: string = 'Name';
    full_series_list: Series[] = [];
    @Input()
    selected_series: Series[] = [];

    constructor(
        public api: ApiService,
        public dialogRef: MatDialogRef<DownloadFormDialogComponent>,
        public dateTimePeriodService: DateTimePeriodService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit(): void {
        const ctrl = this;
        this.api.component.search(this.api.prep_q([{name: 'base_type', op: "eq", val: "process"}])).toPromise().then(
            (result) => {
                this.process_list = result.data;
            }
        );

        ctrl.api.series_light.search().toPromise().then((result) => {
            ctrl.full_series_list = result.data.filter(function (series) {
                if (series.type == 'series' || series.type == 'calculation') {
                    return series
                }
            });

        });

        if (this.data) {
            ctrl.selected_series = ctrl.data
        }
    }

    downloadData() {
        let process_id;
        if (this.selected_data && this.selected_data.process && this.selected_data.process.id) {
            process_id = this.selected_data.process.id
        }
        let params = {
            start: this.dateTimePeriodService.dtp.start.toISOString(),
            end: this.dateTimePeriodService.dtp.end.toISOString(),
            sample_period: this.dateTimePeriodService.dtp.sample_period.wire_sample
        };
        if (process_id) {
            params['process'] = process_id
        }
        if (this.selected_series) {
            params['series_list'] = this.selected_series.map(series => {
                return series.id
            })
        }
        window.open("/api/GetExcel" + '?' + utils.httpParamSerializer(params));
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }
}

// export function fileDownloadFormCtrl($scope, $httpParamSerializer, $mdDialog, api, process, dtp) {
//     'ngInject';
//     $scope.selected_process = process;
//
//     $scope.process_data=[];
//     $scope.process_data_ready = false;
//
//     api.component.query().$promise.then(function(component_list) {
//         component_list.map(function(component) {
//             if (component.type=='process') {
//                 $scope.process_data.push(component)}
//             });
//
//         $scope.process_data_ready = true;
//     });
//
//     $scope.closeDialog = function () {
//         $mdDialog.cancel();
//     };
//     $scope.downloadData = function(){
//         window.open("/api/GetExcel"+ '?' + $httpParamSerializer({
//             start: dtp.start,
//             process:$scope.selected_process.id,
//             end: dtp.end,
//             sample_period: dtp.sample_period.wire_sample
//         }));
//         $mdDialog.hide();
//     };
// }
//
// export default fileDownloadFormCtrl
