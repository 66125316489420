<div class="page traveler" style="padding-left:0;padding-right:0;">
  <div>
    <!--<div flex layout="row">-->
    <div *ngIf="flowchartReady" class="flowchart-container">
      <flow-chart (context_menu)="contextMenu($event)" [chart]="chartViewModel"
      >
      </flow-chart>
    </div>
    <!--</div>	-->
  </div>
  <div [hidden]="formSize === 0"
       [ngClass]="{'side-min':formSize==30,'side-max':formSize==70}" class="flowcharts hideonprint side-panel">
    <!--<h5 style="text-transform: uppercase;">{{parentChartViewModel.parent_process.name()}}</h5>-->
    <!--<div class="flowchart-container">-->
    <!--<flow-chart  style="pointer-events:none" *ngIf="parentFlowchartReady" chart="parentChartViewModel">-->
    <!--</flow-chart>-->
    <!--</div>-->
    <div *ngFor="let selected_component of selected_components" class="form-dialog">
      <h5 style="text-transform: uppercase;">
        <i (click)="expand()" [ngClass]="{'fa-arrow-right': expandSize==70, 'fa-arrow-left': expandSize==30}"
           class="fa" style="margin-right: 10px;"></i> Selected: {{ selected_component.data.type }}</h5>
      <!--<flowchart-process-form *ngIf="selected_component.data.type === 'process'"-->
      <!--[flowchart]="chartViewModel"-->
      <!--[component]="selected_component"-->
      <!--(save_event)="save($event)">-->

      <!--</flowchart-process-form>-->
      <!--<edit-stream *ngIf="selected_component.data.type === 'stream'" flowchart="this" component="selected_component" plantData="$ctrl.plantData"></edit-stream>-->
      <!--<edit-equipment *ngIf="selected_component.data.type === 'equipment'" flowchart="this" component="selected_component" plantData="$ctrl.plantData"></edit-equipment>-->
    </div>
  </div>
  <!--TODO:remove this, just a pain to keep adding and removing while I'm working on it on and off!!-->
  <button (click)="enableContext=!enableContext" *ngIf="current_user && current_user.name==='Jules'"
          class="context-button">Context {{enableContext}}</button>

  <div [ngStyle]="{'display':menuVisible? 'block':'none'}" class="flowchart-context-menu">
    <ul class="menu-options">
      <li (click)="editSelected(null, null)"
          *ngIf="selected_components[0]?.data.type==='process' || selected_components[0]?.data.type==='stream'
 		|| selected_components[0]?.data.type==='equipment'"
          class="menu-option">Edit
      </li>
      <li (click)="editSelectedCustomChart(selected_components[0])"
          *ngIf="selected_components[0]?.type==='custom_chart'"
          class="menu-option">Edit
      </li>
      <li (click)="editSelectedImage(selected_components[0])"
          *ngIf="selected_components[0]?.data.type==='image'"
          class="menu-option">Edit
      </li>
      <li (click)="editSelectedContext(selected_components[0])"
          *ngIf="selected_components[0]?.type==='context'"
          class="menu-option">Edit
      </li>
      <li (click)="newProcess()"
          *ngIf="selected_components[0]?.data.type==='process' && selected_components[0]?.is_parent"
          class="menu-option">New Process
      </li>
      <li (click)="editCustomChart(null, null, true)"
          *ngIf="selected_components[0]?.data.type==='process' && selected_components[0]?.is_parent"
          class="menu-option">New Custom Chart
      </li>
      <li (click)="editImage(null, null, true, selected_components[0])"
          *ngIf="selected_components[0]?.data.type==='process'"
          class="menu-option">New Image
      </li>
      <li (click)="editContext(null, null, true)"
          *ngIf="selected_components[0]?.data.type==='process' && selected_components[0]?.is_parent"
          class="menu-option">New Context
      </li>
      <li (click)="addNewConnector()"
          *ngIf="selected_components[0]?.data.type==='process' && selected_components[0]?.is_parent !== true"
          class="menu-option">Add Connector
      </li>
      <li (click)="toggleMenu('hide');addNewEquipment()"
          *ngIf="selected_components[0]?.data.type==='process' || selected_components[0]?.data.type==='stream'"
          class="menu-option">Add Equipment
      </li>
      <li (click)="addPoint(selected_components[0])"
          *ngIf="selected_components[0]?.data.type==='stream'"
          class="menu-option">Add Point
      </li>
      <li (click)="deleteSelected()" *ngIf="selected_components[0]?.type!=='custom_chart' &&
      selected_components[0]?.type!=='context' &&
      selected_components[0]?.data.type!=='image'"
          class="menu-option">Delete
      </li>
      <li (click)="deleteSelectedCustomChart(selected_components[0])"
          *ngIf="selected_components[0]?.type==='custom_chart'"
          class="menu-option">Delete
      </li>
      <li (click)="deleteSelectedContext(selected_components[0])"
          *ngIf="selected_components[0]?.type==='context'"
          class="menu-option">Delete
      </li>
      <li (click)="deleteSelectedImage(selected_components[0])"
          *ngIf="selected_components[0]?.data.type==='image'"
          class="menu-option">Delete
      </li>
      <!--<li class="menu-option"-->
      <!--*ngIf="selected_components[0]?.data.type==='equipment'"-->
      <!--(click)="removeSelected()">Remove-->
      <!--</li>-->
      <li *ngIf="selected_components[0]?.data.type==='process' &&
			selected_components[0]?.is_parent !== true" class="menu-option" style="cursor:default;padding:0;">
        <a (click)="toggleMenu('hide');" routerLink="{{'/view/flowchart/' + selected_components[0].data.id }}">
          Zoom In
        </a>
      </li>
      <li *ngIf="selected_components[0]?.data.type==='process' &&
			selected_components[0]?.is_parent === true && selected_components[0]?.data.relationships.parent.data"
          class="menu-option" style="cursor:default;padding:0;">
        <a (click)="toggleMenu('hide');"
           routerLink="{{'/view/flowchart/' + selected_components[0].data.relationships.parent.data.id }}">
          Zoom Out
        </a>
      </li>
      <!--<li class="menu-option">Inspect</li>-->
    </ul>
  </div>

</div>