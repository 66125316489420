import {
    AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy,
    OnInit, Output, ViewChild, ViewEncapsulation, Renderer2
} from "@angular/core";
import {ApiService} from "../services/api.service";
import {UserData} from "../services/user_data.service";
import {HeaderDataService} from "../services/header_data.service";
import {Tile, TileButton, TileDataService} from "../services/tile_data.service";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {EventDataService} from "../services/event_data.service";
import {TileFormComponent} from "../forms/tile_form.component";
import {DateTimePeriod, DateTimePeriodService} from "../services/datetime_period.service";
import {SeriesStringParser} from "../services/series_string_parser.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import flowchart from '../lib/flowchart/flowchart_viewmodel'
import {PlantDataService} from "../services/plant_data.service";
import {AppScope} from "../services/app_scope.service";
import * as utils from "../lib/utils";
import {ParagraphFormComponent} from '../forms/paragraph-form/paragraph-form.component';

@Component({
    selector: 'page-tile',
    templateUrl: 'page-tile.component.html',
    providers: [TileDataService, EventDataService, DateTimePeriodService],
    encapsulation: ViewEncapsulation.None,
    host: {
        // TODO do we really want this to listen for a click anywhere on the document. Trying to be conservative for the
        //  amount of event listeners for clicks.
        '(document:click)': 'windowClick($event)', //This gets destroyed when component using this is destroyed
    },
})
//TODO:will need to add period and range to tile json properties plus isolated_dtp property
export class PageTileComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    public title: string;
    public sub_title: string;

    @Input() tile: Tile;
    @Input() index: number; //The current index of the tile in its section
    @Input() id: string;
    @Input() gridEditing: boolean = false;
    @Input('dtp') parent_dtp: DateTimePeriod; //The dtp passed in from the parent
    show_date_picker: boolean = false;

    @Output() tileChange = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() save = new EventEmitter();
    save_content: Subject<any> = new Subject();

    buttons: TileButton[];
    // series_list: any;
    // full_series_list: any;
    hasOwnTitle: boolean = false;
    flowchart: any[];
    flowchartReady: boolean = false;
    editing: boolean;
    showing_comments: boolean = false;
    pivotEditing: boolean = false;
    current_user: any;

    menuVisible: boolean = false;
    selected_flowchart_components: any[] = [];
    enableContext: boolean = true; //determines whether user can see flowchart context menu (can be used for permission checking later)

    private context_menu: ElementRef;

    constructor(public api: ApiService,
                public userData: UserData,
                public tileData: TileDataService,
                public plantData: PlantDataService,
                public appscope: AppScope,
                public headerData: HeaderDataService,
                public eventData: EventDataService,
                public dialog: MatDialog,
                public dateTimePeriodService: DateTimePeriodService,
                private seriesStringParser: SeriesStringParser,
                private appScope: AppScope,
                private renderer: Renderer2
    ) {
        this.tileData.editing.pipe(takeUntil(this.onDestroy)).subscribe((newBool: boolean) => {
            this.editing = newBool;
        });

        this.eventData.getShowComments().pipe(takeUntil(this.onDestroy)).subscribe(value => this.showing_comments = value);
    }

    //This gets set when the element is within an *ngIf and ngIf becomes true
    @ViewChild('context_menu', {static: false}) set content(content: ElementRef) {
        this.context_menu = content;
    }

    ngOnInit(): void {
        const ctrl = this;

        this.tileData.id = this.id;
        this.tileData.tile = this.tile;
        this.tileData.tile_index = this.index;

        this.headerData.tileChange.pipe(takeUntil(this.onDestroy)).subscribe(tile => {
            this.tile.parameters = tile.parameters;
            this.emitTileContent(this.tile.parameters);
        });
        if (this.tile.content === 'flowchart') {
            this.buildFlowchart(this.tile.parameters);
        }
        this.current_user = this.appScope.current_user;

        this.headerData.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            if (ctrl.tile.custom_dtp !== true) {
                ctrl.dateTimePeriodService.dtp = dtp;
            }
            //Emit refresh to child components
            this.dateTimePeriodService.dtpReset.emit(this.dateTimePeriodService.dtp);
        });

        this.dateTimePeriodService.dtp_complete.promise.then(function () {
            if (ctrl.tile.custom_dtp === true) {
                ctrl.dateTimePeriodService.dtp = ctrl.setDateTimePeriod(ctrl.tile.dtp);
                ctrl.dateTimePeriodService.dtp.sample_period =
                    utils.deepCopy(ctrl.dateTimePeriodService.sample_dict[ctrl.tile.dtp.sample_period.name]);
            } else {
                ctrl.dateTimePeriodService.dtp = utils.deepCopy(ctrl.parent_dtp);
            }
        })
    }

    windowClick(event) {
        if (this.menuVisible === true) {
            this.menuVisible = false;
        }
    }

    ngAfterViewInit(): void {
        const ctrl = this;
        this.tileData.buttonsChanged.pipe(takeUntil(this.onDestroy)).subscribe(new_buttons => {
            setTimeout(() => this.buttons = new_buttons);
        });

        if ((ctrl.tile.parameters && ctrl.tile.parameters.title) || ctrl.tile.title) {
            if (ctrl.tile.title) {
                setTimeout(() => ctrl.title = ctrl.tile.title);
            } else if (ctrl.tile.parameters.title) {
                setTimeout(() => ctrl.title = ctrl.tile.parameters.title);
            }
            ctrl.hasOwnTitle = true;
        } else {
            this.tileData.tileTitle.pipe(takeUntil(this.onDestroy)).subscribe(tileTitle => {
                setTimeout(() => this.title = tileTitle);
            });
            this.tileData.tileSubtitle.pipe(takeUntil(this.onDestroy)).subscribe(tileSubtitle => {
                setTimeout(() => this.sub_title = tileSubtitle);
            });
        }

    }

    setDateTimePeriod(dtp) {
        const ctrl = this;
        let tmp;
        if (dtp.range === 'custom') {
            tmp = ctrl.dateTimePeriodService.getDTP();
            tmp.range = "custom";
            tmp.start = new Date(dtp.start);
            tmp.end = new Date(tmp.end);
        } else {
            tmp = ctrl.dateTimePeriodService.getDTP(dtp.range);
        }
        tmp.sample_period = utils.deepCopy(ctrl.dateTimePeriodService.sample_dict[dtp.sample_period.name]);
        return ctrl.dateTimePeriodService.validateDTP(tmp);
    }

    selectCustomDtp() {
        const ctrl = this;
        if (ctrl.dateTimePeriodService.dtp.range === 'custom') {
            ctrl.show_date_picker = true;
        } else {
            ctrl.dateTimePeriodService.dtp = ctrl.dateTimePeriodService.getDTP(ctrl.dateTimePeriodService.dtp.range);
        }
    }

    saveCustomDtp() {
        this.show_date_picker = false;
    }

    onCloseClick(): void {
        this.dateTimePeriodService.dtp = this.setDateTimePeriod(this.tile.dtp);
        this.show_date_picker = false;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    allowEdit(tile_content) {
        //Using a switch here because I'm sure we're going to be adding on editing permissions coming up
        switch (tile_content) {
            case 'custom':
                return ['Administrator', 'Super_User'].some(i => this.current_user.role_name.indexOf(i) >= 0);
                break;
            default:
                return true;
        }
    }

    openTileFormDialog(): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            "tile": this.tile,
            "index": this.index,
            title: this.title,
            dtp: this.dateTimePeriodService.dtp
        };
        if (this.tile.custom_dtp === true) {
            dialogConfig.data.dtp = this.tile.dtp;
        }
        const dialogRef = this.dialog.open(TileFormComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.tile = result;
                ctrl.tileChange.emit({'tile': this.tile, 'index': this.index});

            }
        });
    }

    openTextFormDialog(): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            config: this.tile.parameters,
            index: this.index
        };
        const dialogRef = this.dialog.open(ParagraphFormComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.tile.parameters = result;
                this.saveContent(this.tile);

            }
        });
    }

    saveContent(tile) {
        const ctrl = this;
        this.save_content.next(tile.parameters); //- goes to child to update trustedHTML
        ctrl.tileChange.emit({'tile': tile, 'index': ctrl.index}); //goes to parent to save session state
    }

    emitTileContent(event) {
        const ctrl = this;
        ctrl.tile.parameters = event;
        ctrl.tileChange.emit(ctrl.tile)
    }

    deleteTile() {
        //TODO MAKE THIS A DIALOG
        if (confirm('Confirm to delete this tile')) {
            this.delete.emit(this.index);
        }
    }

    buildFlowchart(parameters) {
        const ctrl = this;
        ctrl.flowchartReady = false;

        let chartDataModel = ctrl.plantData.getFlowSheetData(parameters.process.id);
        let permissions = ctrl.plantData.getIsolatedPermissions(parameters.process.id);

        Promise.all([chartDataModel.allDataFetched.promise, permissions.all.promise]).then(function () {
            chartDataModel.parent_process = chartDataModel.process_focus;
            ctrl.flowchart = new flowchart.ChartViewModel(chartDataModel, parameters);
            ctrl.selected_flowchart_components = [ctrl.flowchart['parent_process']];
            ctrl.flowchartReady = true;
            ctrl.plantData.getSeriesSummary(chartDataModel, ctrl.dateTimePeriodService.dtp);
        });
    }

    changeProcess(process) {
        let new_params = utils.deepCopy(this.tile.parameters);
        new_params.process = utils.deepCopy(process.data);
        this.buildFlowchart(new_params);
    }

    openFlowchart(process) {
        this.menuVisible = false;
        window.open('view/flowchart/' + process.id, "_blank");
    }

    contextMenu(e) { //emitted event
        if (this.enableContext && e.element.data.type === 'process') {
            e.event.preventDefault();
            this.selected_flowchart_components = [e.element];
            const origin = {
                left: e.event.pageX - 50,
                top: e.event.pageY - 150
            };
            this.setPosition(origin);
            return false;
        } else {
            this.menuVisible = false;
        }
    }

    setPosition({top, left}) {
        this.renderer.setStyle(this.context_menu.nativeElement, 'left', `${left}px`);
        this.renderer.setStyle(this.context_menu.nativeElement, 'top', `${top}px`);
        this.menuVisible = true;
    }
}
