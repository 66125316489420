/**
 * Created by phillip on 2016/05/26.
 */

import {ApiService} from './api.service';
import {Injectable} from '@angular/core';
import {AppScope} from './app_scope.service';
import * as utils from "../lib/utils";
import {FlatPromise} from "../lib/utils";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class PlantDataService {
    reports: any[];
    public sidenav_open: boolean;
    searchText = '';
    components = [];
    flowSheet = {};
    all_series: any[];
    series_dict = {};
    process_focus: any = {};

    previous_process_ids = [];
    permissions = {};
    //All components ie streams, equipment and process have been fetched
    //Process focus permissions have been fetched
    //All related series have been and have the local attributes report_group and can_edit resolved

    constructor(private appScope: AppScope,
                private api: ApiService,
                private http: HttpClient) {
        appScope.auth_complete.promise.then(() => {
            this.api.series_light.search().toPromise().then(response => this.all_series = response.data);
        });
    }

    getPlants(account_id: string): Observable<any> {
        return this.api.process.search(this.api.prep_q([{
            "and": [{
                op: 'eq',
                name: 'account_id',
                val: account_id
            }, {
                op: 'has',
                name: 'component_type',
                val: {name: 'name', val: 'Plant', op: 'eq'}
            }],
        }])).pipe(map(result => result.data));
    }

    /**
     * Returns a map of account_id -> default_page_id.
     * Used as:
     *  /view/page_view/default_page_map[account_id]
     */
    // getDefaultPageMap(plants) {
    //     let default_page_map = {};
    //     // return this.getPlants(account_id).pipe(tap(plants => {
    //         // console.log('PlantDataService - default_plant: ', this.appScope.default_plant);
    //         plants.forEach(plant => {
    //             if (plant.attributes.json && plant.attributes.json.default_page) {
    //                 default_page_map[plant.id] = plant.attributes.json.default_page;
    //             }
    //         });
    //         //console.log('PlantDataService - map: ', default_page_map);
    //         return default_page_map;
    //     // }));
    // }

    // getUserPlants(){
    //     const ctrl = this;
    //
    //     const parameters = ctrl.api.prep_q([{
    //         and: [
    //             {name: 'user_id', val: ctrl.appScope.current_user.id, op: 'eq'},
    //             {name: 'process_id', op: 'in', val: ctrl.appScope.plants.map(plant=>plant.id)},
    //             {name: 'feature', op: 'has', val: {name: 'name', op: 'eq', val: 'view_process_data'}}
    //         ]
    //     }]);
    //     return ctrl.api.process_access.search(parameters).toPromise();
    // }

    getIsolatedPermissions(process_id, isoPermissions?) {
        // TODO rework this function to use clean promises and wait for appScope.auth_complete
        if (isoPermissions == null) {
            isoPermissions = {}
        }
        const ctrl = this;

        isoPermissions.all = new FlatPromise();

        const parameters = ctrl.api.prep_q([{
            and: [{
                'name': 'user_id', val: ctrl.appScope.current_user.id, op: 'eq'
            }, {
                or: [
                    {'name': 'process_id', val: process_id, op: 'eq'},
                    {
                        'name': 'top_process',
                        op: 'has',
                        val: {name: 'children', op: 'any', val: {name: 'id', op: 'eq', val: process_id}}
                    }]
            }]
        }]);

        isoPermissions.access = ctrl.api.process_access.search(parameters);

        isoPermissions.access.toPromise().then(data => {
            if (!data) {
                // api call was cancelled
                return;
            }
            isoPermissions.permissions = {};
            let index = 0;
            for (index = 0; index < data.data.length; index++) {
                isoPermissions.permissions[data.data[index].attributes.feature_name] = true;
            }
            isoPermissions.all.resolve(isoPermissions);
        });

        return isoPermissions;
    }

    //1.) Check whether process focus ID = the routeParams processID and refresh accordingly
    //2.) Get all child processes then..
    //3.) Get streams for all processes (also get connectors - independent) then...
    //4.) Get equipment (and other) for all streams and processes then...
    //5.) Concat all components (process focus, child processes, streams, equipment) then...
    //6.) Get all series_component objects and all series for all components (7a) then...
    //7.) Add component name as parent_name attribute to series
    //8.) Add series_component attributes to the series objects
    //9.) Check series_light is resolved and resolve all - allDataFetched

    getFlowChartDataImmutable(flowSheet) {
        //console.log(plantData.process_focus.id);
        let ctrl = this;
        let processesPromise = ctrl.api.process.getRelatedMany(flowSheet.process_focus.id, 'children').toPromise();

        const process_ids = flowSheet.process_focus.relationships.children.data.map(item => item.id);
        process_ids.push(flowSheet.process_focus.id);

        let streamsPromise = ctrl.api.stream.search(ctrl.api.prep_q([{
            and: [{'op': 'in', 'name': 'end_id', val: process_ids},
                {'op': 'in', 'name': 'start_id', val: process_ids}
            ]
        }], {})).toPromise();

        flowSheet.connectors = ctrl.api.connector.query(ctrl.api.prep_q([{
            and: [
                {'op': 'in', 'name': 'process_id', val: process_ids}
            ]
        }], {}));

        //Get all streams then get all equipment for processes and streams
        Promise.all([streamsPromise, processesPromise]).then(results => {
            flowSheet.streams = results[0].data;
            flowSheet.processes = results[1].data;

            let component_ids;
            const stream_ids = flowSheet.streams.map(item => item.id);

            flowSheet.equipment = ctrl.api.equipment.query(ctrl.api.prep_q([{
                or: [
                    {'op': 'in', 'name': 'component_id', val: process_ids.concat(stream_ids)}
                ]
            }], {}));

            flowSheet.series_components = [];
            flowSheet.selected_series = [];

            flowSheet.equipment.$promise.then(() => {

                flowSheet.selected_series_ids = [];

                component_ids = process_ids.concat(stream_ids.concat(flowSheet.equipment.map(item => item.id)));
                flowSheet.components = [flowSheet.process_focus].concat(flowSheet.processes).concat(flowSheet.streams).concat(flowSheet.equipment);

                //Fetch all series_components for all components within process_focus
                const series_components = ctrl.api.series_component.query(
                    ctrl.api.prep_q([{op: 'in', name: 'component_id', val: component_ids}], {}));
                //Fetch all constant_components for all components within process_focus
                flowSheet.constant_components = ctrl.api.constant_component.query(
                    ctrl.api.prep_q([{op: 'in', name: 'component_id', val: component_ids}], {}));

                //**Fetch all series for all components within process_focus
                flowSheet.selected_series = ctrl.api.series.query(
                    ctrl.api.prep_q([{
                        name: 'series_components',
                        op: 'any',
                        val: {name: 'component_id', op: 'in', val: component_ids}
                    }]));

                flowSheet.selected_series.$promise.then(() => {
                    flowSheet.selected_series.map(series => {
                        flowSheet.selected_series_ids.push(series.id);
                        const extractSeries = item => {
                            if (item.relationships.series) {
                                item.relationships.series.data.map(
                                    related_series => {
                                        if (related_series.id === series.id) {
                                            series.attributes.parent_name = item.attributes.name;
                                            // series.parent = item;
                                        }
                                    }
                                )
                            }
                        };

                        flowSheet.processes.concat([flowSheet.process_focus]).map(extractSeries);
                        flowSheet.streams.map(extractSeries);
                        flowSheet.equipment.map(extractSeries);

                    });

                    flowSheet.seriesFetched.resolve();
                });

                Promise.all([series_components.$promise, flowSheet.seriesFetched.promise]).then(() => {

                    flowSheet.series_component_map = {};
                    flowSheet.series_components = [];
                    flowSheet.series_components_empty = [];
                    series_components.forEach(item => {
                        if (item.relationships.series.data !== null) {
                            flowSheet.series_component_map[item.relationships.series.data.id] = item;
                        } else {
                            flowSheet.series_components.push(item);
                        }
                    });
                    flowSheet.selected_series.map(series => {
                        if (flowSheet.series_component_map.hasOwnProperty(series.id)) {
                            flowSheet.series_component_map[series.id].relationships.series.data = utils.deepCopy(series);
                            flowSheet.series_component_map[series.id].relationships.series.data.attributes.value = null;
                            series.attributes.report_group = flowSheet.series_component_map[series.id].attributes.report_group;
                            series.attributes.series_order = flowSheet.series_component_map[series.id].attributes.series_order;
                            series.attributes.can_edit = flowSheet.series_component_map[series.id].attributes.can_edit;
                            series.attributes.latest_value = flowSheet.series_component_map[series.id].attributes.latest_value;
                            //series.attributes.view_on_flowchart = plantData.series_component_map[series.id].attributes.view_on_flowchart;
                            flowSheet.series_component_map[series.id].relationships.series.data = series;
                            flowSheet.series_components.push(flowSheet.series_component_map[series.id]);
                        }
                    });

                    //Check that other promises have been resolved
                    Promise.all([ctrl.all_series, flowSheet.connectors.$promise,
                        flowSheet.constant_components.$promise]).then(() => {
                        console.log("Resolved All", flowSheet);
                        flowSheet.stale_data = false;
                        flowSheet.allDataFetched.resolve(flowSheet);
                    });

                });

            }); //End all equipment
        }); //Get all streams, processes and series_light3
        return flowSheet;
    }

    getFlowSheetData(process_id) {
        let plantData = this;
        let flowSheet: { seriesFetched: FlatPromise, allDataFetched: FlatPromise, process_focus, parent_process } = {
            seriesFetched: new FlatPromise(),
            allDataFetched: new FlatPromise(),
            process_focus: null,
            parent_process: null
        };

        let $process_focus = plantData.api.process.getById(process_id).toPromise();
        Promise.all([plantData.appScope.auth_complete.promise, $process_focus]).then(([auth, process]) => {
            flowSheet.process_focus = process.data;
            plantData.getFlowChartDataImmutable(flowSheet);
        });

        return flowSheet
    }

    getSeriesSummary(flowSheet, dtp) {
        const ctrl = this;
        let series_list_ids = flowSheet.series_components.filter(
            function (item) {
                return item.attributes.view_on_flowchart || item.attributes.view_on_parent_flowchart
            }).map(
            function (item) {
                return item.relationships.series.data.id
            }
        );

        if (series_list_ids.length > 0) {
            let summary = ctrl.api.get("/GetSeriesSummary" + '?' + utils.httpParamSerializer({
                series_list: series_list_ids,
                start: (new Date(dtp.start)).toISOString(),
                end: (new Date(dtp.end)).toISOString(),
                return_type: 'json',
                format: "records",
                sample_period: dtp.sample_period.wire_sample,
                deepness: 2,
                columns: ['Value', 'Status']
            }));
            summary.toPromise().then(function (result) {
                flowSheet.series_components.map(function (series_component) {
                    result.forEach(function (data) {
                        if (series_component.relationships.series.data !== null && series_component.relationships.series.data.attributes.name ==
                            data.Name) {
                            series_component.relationships.series.data.attributes.value = data['Value'];
                            series_component.relationships.series.data.attributes.status = data['Status'];
                        }
                    })
                });
            });
            return summary;
        }
    };

    saveItem(item) {
        let plantData = this;
        Object.keys(item.relationships).map(key => {
            if (item.relationships[key].data !== null) {

                if (item.relationships[key].data.constructor === Array) {

                    item.relationships[key].data.forEach(relation => {
                        if (relation.hasOwnProperty('attributes')) {
                            plantData.saveItem(relation); //$http({method:'PATCH',url:item.links.self,data:{data:item}})
                        }
                    });

                }

            }
        });
        plantData.api[item.type].patch(item);
    }
}