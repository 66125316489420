import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as _ from "lodash";
import {ApiService} from "../services/api.service";
import {MatSnackBar} from "@angular/material";
import {User} from "../user_control/user_list.component";
import {UserData} from "../services/user_data.service";
import {forkJoin, Subject} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {AppScope} from '../services/app_scope.service';

export interface DialogData {
    name: string;
}

@Component({
    selector: 'UserForm',
    templateUrl: 'user_form.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class UserFormComponent implements OnInit, OnDestroy {
    private onDestroy: Subject<void> = new Subject();

    pages: any[] = [];
    user: User;
    // show: boolean;
    series_list: any[] = [];
    feature_list: any[] = [];
    roles_list: any[] = [];
    alerts_list: any[] = [];
    process_access: any[] = [];
    process_list: any[] = [];
    process_dict: {} = {};
    role_dict: {} = {};
    process_features: { [key: string]: any[] } = {};
    process_ids = [];
    showing_hints: boolean = false;
    hint: string = 'Name';

    featureprocess_map: {} = {};
    restrictedUserViews: any[] = [];

    restrictedAccess: boolean;
    customRestrictedPages: { name: string, url: any } = {name: null, url: null};
    customRestrictedPagesList: { name: string, url: any }[] = [];
    _selected_features: any = [];
    view_plant_feature: any;

    constructor(public api: ApiService,
                public appScope: AppScope,
                public dialogRef: MatDialogRef<UserFormComponent>,
                private snackbar: MatSnackBar,
                public http: HttpClient,
                @Inject(MAT_DIALOG_DATA) public data: User,
                private userData: UserData) {
        this.user = data;
    }

    ngOnInit(): void {
        const ctrl = this;

        setTimeout(() => {
            const sources = [];

            sources.push(ctrl.userData.getRestrictedDashboards(ctrl.user).pipe(takeUntil(this.onDestroy), tap((response) => {
                ctrl.restrictedUserViews = response.data;
            })));
            if (ctrl.user.attributes.restricted_access === true && ctrl.user.attributes.json) {
                ctrl.customRestrictedPagesList = ctrl.user.attributes.json;
            }
            //full list of dashboards
            sources.push(ctrl.api.session_state.search(ctrl.api.prep_q([{
                op: 'eq',
                name: 'report',
                val: 'Dashboard'
            }])).pipe(takeUntil(this.onDestroy), tap((dashboards) => {
                ctrl.dashBoards(dashboards.data)
            })));

            //full list of series
            sources.push(ctrl.api.series_light.search().pipe(takeUntil(this.onDestroy), tap(response => {
                ctrl.series_list = response.data
            })));

            //full list of roles
            sources.push(ctrl.api.role.search().pipe(takeUntil(this.onDestroy), tap((result) => {
                ctrl.roles_list = result.data;
                ctrl.roles_list.forEach((item) => {
                    ctrl.role_dict[item.id] = item.attributes.name;
                })
            })));

            //full list of alerts
            sources.push(ctrl.api.alerts.search().pipe(takeUntil(this.onDestroy), tap(response => {
                ctrl.alerts_list = response.data;
            })));

            sources.push(ctrl.api.component.search(ctrl.api.prep_q([{
                name: 'base_type',
                op: "eq",
                val: "process"
            }])).pipe(takeUntil(this.onDestroy), tap((result) => {
                ctrl.process_list = result.data;
                ctrl.process_list.forEach((item) => {
                    ctrl.process_dict[item.id] = item.attributes.name + '-' + item.attributes.description;
                    ctrl.process_ids.push(item.id)
                })
            })));

            sources.push(ctrl.api.feature.search(ctrl.api.prep_q([{
                name: 'process_access_feature',
                op: 'eq',
                val: true
            }])).pipe(takeUntil(this.onDestroy), tap(results => {
                ctrl.feature_list = results.data;
                ctrl.feature_list.forEach((item) => {
                    if (item.attributes.name === 'view_process_data') {
                        ctrl.view_plant_feature = item;
                    }
                    ctrl.process_features[item.id] = [];
                })
            })));

            // full list of process_access_features
            sources.push(ctrl.api.process_access.search(ctrl.api.prep_q([{
                name: 'user_id',
                op: 'eq',
                val: ctrl.user.id
            }])).pipe(takeUntil(this.onDestroy), tap(result => {
                ctrl.process_access = result.data;
            })));

            forkJoin(sources).pipe(takeUntil(this.onDestroy)).subscribe(data => {
                ctrl.process_access.forEach((item) => {
                    ctrl.featureprocess_map[item.relationships.feature.data.id + item.relationships.top_process.data.id] = item.id;
                    ctrl.process_features[item.relationships.feature.data.id].push(item.relationships.top_process.data.id)
                });
            })
        });
    }

    processDescription = (obj) => this.process_dict[obj];

    roleFunction = (obj) => {
        if (obj.attributes) {
            return obj.attributes.name;
        } else {
            return this.role_dict[obj.id]
        }
    };

    alertsFunction = (obj) => {
        if (obj.relationships.series && obj.relationships.series.data) {
            let alert_series = this.series_list.find(series => obj.relationships.series.data.id === series.id);
            return alert_series.attributes.description ? alert_series.attributes.description : alert_series.attributes.name;
        } else {
            return 'Empty alert'; //check alerts in admin portal, alerts need to have a series assigned to them
        }
    };

    onCloseClick(): void {
        this.dialogRef.close();
    }

    clear() {
        this.restrictedUserViews = [];
        this.customRestrictedPagesList = []
    }

    removeView(index) {
        this.customRestrictedPagesList.splice(index, 1);
    }

    addPage() {
        const ctrl = this;
        if (!this.user.attributes.restricted_access) {
            ctrl.snackbar.open('Please tick off the "Restricted access" to make the user a restricted access user', 'Hide')
        }

        if (this.user.attributes.restricted_access) {
            if (ctrl.customRestrictedPages.url == null || ctrl.customRestrictedPages.name == null) {
                ctrl.snackbar.open('Please add a page name and URL', 'Hide')
            } else {
                ctrl.customRestrictedPagesList.push(_.cloneDeep(ctrl.customRestrictedPages));
                ctrl.customRestrictedPages.url = null;
                ctrl.customRestrictedPages.name = null;
            }
        }
    }

    dashBoards(dashboards) {
        const ctrl = this;
        dashboards.forEach(dashboard => {
            ctrl.user.relationships.dashboards.data.map(stub => {
                if (stub.id === dashboard.id) {
                    stub.attributes = dashboard.attributes;
                }
            })
        });

        ctrl.pages = dashboards.filter(page => page.attributes.visibility != 'private');

    }

    save() {
        const ctrl = this;
        const dont_delete = [];

        if (ctrl.user.attributes.restricted_access) {
            ctrl.user.relationships.dashboards.data = [];
            ctrl.restrictedUserViews.forEach((page) => {
                    let restricted_page = {type: null, id: null};
                    restricted_page.type = "session_state";
                    restricted_page.id = page.id;
                    ctrl.user.relationships.dashboards.data.push(restricted_page);
                }
            );

            if (ctrl.customRestrictedPagesList.length > 0) {
                ctrl.user.attributes.json = ctrl.customRestrictedPagesList;
            }

        }
        //Save Process Access Features
        delete ctrl.user.relationships.changed_by;
        delete ctrl.user.relationships.created_by;

        ctrl.api.users.patch(ctrl.user).then(() => {
            const save_promises = [];
            ctrl.feature_list.forEach((feature) => {
                ctrl.process_features[feature.id].forEach((pr_id) => {
                    if (ctrl.featureprocess_map.hasOwnProperty(feature.id + pr_id)) {
                        dont_delete.push(feature.id + pr_id)
                    } else {
                        save_promises.push(ctrl.api.process_access.save({
                            user_id: ctrl.user.id,
                            process_id: pr_id,
                            feature_id: feature.id,
                            type: 'process_access'
                        }));
                        dont_delete.push(feature.id + pr_id)
                    }
                });
            });
            Promise.all(save_promises).then(() => {
                const delete_promises = [];
                Object.keys(ctrl.featureprocess_map).forEach((key) => {
                    if (dont_delete.indexOf(key) == -1) {
                        delete_promises.push(ctrl.api.process_access.delete(ctrl.featureprocess_map[key]));
                    }
                });
                Promise.all(delete_promises).then(() => {
                    ctrl.snackbar.open('Saved', null, {duration: 2000});
                    ctrl.onCloseClick();
                })
            })
        });
    }

    setValues(feature) {
        const ctrl = this;
        return this.process_features[feature.id] ? this.process_features[feature.id].map((item) => {
                return ctrl.process_list.find(process => process.id == item)
            }
        ) : [];
    }

    filterProcessList() {
        const ctrl = this;
        return ctrl.process_list.filter(process => ctrl.appScope.plants.map(p => p.id).includes(process.id));
    }

    processFeaturesChanged(event, feature) {
        let processFeaturesIDsList: any[] = event.map(item => item.id);
        this.process_features[feature.id] = processFeaturesIDsList
    }

    extractIds(event, feature) {
        this.process_features[feature.id] = event.map(item => item.id);
    }

    resend_invite() {
        const ctrl = this;
        //https://chemaf.mmswire.com/auth/resend?email=eric.tshinkobo@chemaf.com&name=Eric

        let url = '/auth/resend';
        if (confirm("This action will resend the WIRE invitational email to this user. Would you like to proceed?")) {
            ctrl.http.post(url, {
                name: ctrl.user.attributes.name,
                email: ctrl.user.attributes.email
            }).toPromise().then((response: any) => {
                console.log('response', response);
                if (response['Success']) {
                    ctrl.snackbar.open('The invitational email has been resent', null, {duration: 3000})
                }

            }, error => {
                if (error.status == 404) {
                    ctrl.snackbar.open('The invitational email could not be resent, please check that the user email address is valid', 'Hide')
                }
            })
        }

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
