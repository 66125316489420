<div class="form-dialog">
  <h1>Download
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Basic">
        <form class="form-section column stretch">
          <mat-label>Select Process</mat-label>
          <select-search (selectChange)="selected_data.process=$event.value" *ngIf="process_list.length>0"
                         [(value)]="selected_data.process"
                         [data]="process_list">

          </select-search>
          <div class="select-search" style="flex-grow:2;position: relative;">
            <select-many-search *ngIf="full_series_list.length>0"
                                [(value)]="selected_series"
                                [classes]="'fill'"
                                [data]="full_series_list"
                                label="Select Series">

            </select-many-search>
          </div>
        </form>
      </mat-tab>

    </mat-tab-group>

  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <button (click)="downloadData()" class="btn"><i class="fas fa-long-arrow-alt-down"></i>Download</button>
  </div>
</div>
