<ul class="row" style="flex-wrap:wrap;list-style: none;" *ngIf="constant_components.length > 0">
    <li class="flex-item" *ngFor="let constant_component of constant_components">
        <table>
            <thead>
            <th><label title="id: {{ constant_component.id }}">
                {{ constant_property_dict[constant_component.relationships.constant_property.data.id].attributes.name}}: </label>
            </th>
            <th *ngIf="constant_component.hasOwnProperty('id')">Show</th>
            <th *ngIf="constant_component.hasOwnProperty('id')"></th>
            </thead>
            <tbody>
            <tr>
                <td>
                    <input (change)="addConstantComponent(constant_component)" [ngModelOptions]="{ debounce: 1000 }" [(ngModel)]="constant_component.attributes.value"
                           *ngIf="constant_property_dict[constant_component.relationships.constant_property.data.id].attributes.data_type=='float'"
                           type="number" step="any"/>
                    <input (change)="addConstantComponent(constant_component)" [(ngModel)]="constant_component.attributes.value" [ngModelOptions]="{ debounce: 1000 }"
                           *ngIf="constant_property_dict[constant_component.relationships.constant_property.data.id].attributes.data_type=='string'"
                    />
                    <mat-datepicker (change)="addConstantComponent(constant_component)" no-reset [(ngModel)]="constant_component.attributes.value" [ngModelOptions]="{ debounce: 1000 }"
                                    *ngIf="constant_property_dict[constant_component.relationships.constant_property.data.id].attributes.data_type=='datetime'"
                                    class="min-date"></mat-datepicker ></td>
                <td *ngIf="constant_component.hasOwnProperty('id')">
                    <mat-checkbox *ngIf="constant_component.hasOwnProperty('id') && tree_position == 'parent'" style="width:20px;margin:0;"
                                  [(ngModel)]="constant_component.attributes.view_on_parent_flowchart"
                                  (change)="addConstantComponent(constant_component)" aria-label="Show on flowchart"></mat-checkbox>

                    <mat-checkbox *ngIf="constant_component.hasOwnProperty('id') && tree_position == 'child'" style="width:20px;margin:0;"
                                  [(ngModel)]="constant_component.attributes.view_on_flowchart"
                                  (change)="addConstantComponent(constant_component)" aria-label="Show on flowchart"></mat-checkbox>
                </td>
                <td *ngIf="constant_component.hasOwnProperty('id')">
                    <i class="fa fa-trash hideonprint" *ngIf="constant_component.hasOwnProperty('id')"
                       (change)="deleteConstantComponent(constant_component)" style="font-size:18px;">
                    </i>
                </td>
            </tr>
            </tbody>
        </table>

    </li>
</ul>
<div *ngIf="constant_components.length < 1">No constant components to display.</div>

