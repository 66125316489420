import * as utils from '../lib/utils';
import * as d3 from 'd3';
import * as c3 from 'c3';
import {ChartAPI, ChartConfiguration} from 'c3';
import * as moment_ from 'moment';
import * as _ from "lodash";
import * as regression from 'regression';

import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {EventDataService} from "../services/event_data.service";
import {AppScope} from "../services/app_scope.service";
import {DateTimePeriod, DateTimePeriodService, SamplePeriod} from "../services/datetime_period.service";
import {TileDataService} from "../services/tile_data.service";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material";
import {forkJoin, Observable, of, Subject, Subscription} from "rxjs";
import {debounceTime, takeUntil, tap} from "rxjs/operators";
import {PresentationService} from "../services/presentation.service";
import {GenericChartConfiguration} from "./chart-config/generic-chart.configuration";
import {ChartSeriesConfiguration} from "./chart-config/chart-series.configuration";
import {BudgetPalette} from "./chart-config/budget-palette";

export const moment = moment_["default"];

const colorCache: { [key: string]: string } = {};

@Component({
    selector: 'generic-chart',
    templateUrl: 'generic-chart.component.html',
    encapsulation: ViewEncapsulation.None
})
export class GenericChartComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @ViewChild('chart_anchor', {static: false}) chart_anchor: ElementRef;

    config_ready = false;
    max_ticks: number;
    chart: ChartAPI;
    id_tag: string;
    config_series_list: ChartSeriesConfiguration[];
    rendered: boolean = false;
    public showEvents = true;

    @Input('config')
    tile_config: GenericChartConfiguration;
    //TODO make interface
    chart_config: any | ChartConfiguration = {};
    comment: string;

    series_full: any[];
    series_name_list: any[];
    series_config: any;
    labels: any;
    series_list: any[];
    estimate_config: any[];
    estimate_names: any[];
    series_description_list: any[];
    series_ids: string[];

    range_map: { [key: string]: { series_list: any[], dtp: DateTimePeriod } } = {};
    name_tag_map: any = {};
    tag_type_map: any = {};
    tag_class_map: any = {};

    ///Formatting and config/////////////////
    legend = true;
    show_limits = false;
    hide_axes = false;
    hide_comments: boolean;

    defaultTitle: string;
    defaultSubtitle: any;
    budget_palette: BudgetPalette;
    chart_type: string = '';
    is_budget = false;
    opsdata: boolean;
    multi_dtp: boolean = false;
    ///End Formatting//////////////////

    ///Time////////////////////////
    period: SamplePeriod;
    dtp: DateTimePeriod;
    zoom_start: any;
    zoom_end: any;
    ///End time/////////////////////

    ///Events////////////////////
    eventStart: any;
    eventEnd: any;
    eventSeries: any;
    graph_data_stats: any = {};
    points_selected: any[] = [];
    $events: any;
    //public showEvents = true;
    ///End Events////////////////
    //comment: string;

    ///Comparison chart///////////////////
    time_dict: any = {}; //comparison chart tooltip
    y_axis_series_name: string; //****comparison chart but can't see how it is used
    x_axis_series_name: string;
    statsColumns: string[] = []; //comparison stats table
    regression_result: any; //comparison stats table
    yRegressionData: any[] = []; //comparison stats table
    xIndex: number; //comparison chart labels
    yIndex: number; //comparison chart labels
    ///End Comparison chart///////////////////

    private presenting: boolean = false;
    private presentationSubscription: Subscription;
    data: any = {};
    custom_legend_names: {} = {};
    custom_names_to_series_tag_names: {} = {};

    constructor(private api: ApiService,
                private appScope: AppScope,
                private dateTimePeriodService: DateTimePeriodService,
                private http: HttpClient,
                private tileData: TileDataService,
                private eventData: EventDataService,
                private snackBar: MatSnackBar,
                private renderer: Renderer2,
                private presentationService: PresentationService) {

    }

    // for keeping track what points have been clicked, does not take into account what series was clicked
    points_set: Set<string> = new Set<string>();
    groups: any = {};
    types: any = {};
    axes: any = {};
    show_y2 = false;
    colour_pattern: string[] = [];
    line_list: {
        value: number,
        class: string,
        text: string,
        axis: string
    }[];
    y_max: { y: number, y2: number } = {y: null, y2: null};
    y_min: { y: number, y2: number } = {y: null, y2: null};
    estimates = {};
    range_matrix: { xs: any, data_columns: any };
    tag_key_map: any = {};
    series_key_to_name_map: {} = {};
    columns: any = [];
    time_list: any;
    missing_values: any = {};

    private task_complete: Subject<boolean>;

    ngOnInit(): void {
        const ctrl = this;

        ctrl.id_tag = utils.gen_graph_id(32); //Used by custom legend

        this.addSubscriptions();

        this.tileData.show_comment_dtp = false;
        if (ctrl.appScope.isNotMobile) {
            ctrl.max_ticks = 8;
        } else {
            ctrl.max_ticks = 5;
        }

        //Making default titles for charts
        this.defaultTitle = this.tile_config.labels.title;
        this.defaultSubtitle = this.tile_config.labels.sub_title;

        //Setting/sending the default titles for the charts to tileData
        this.tileData.setDefaultTitle(ctrl.defaultTitle);
        this.tileData.setDefaultSubtitle(ctrl.defaultSubtitle);
        if (this.tile_config.chart_type) {
            this.chart_type = this.tile_config.chart_type;
        }

        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            ctrl.period = utils.deepCopy(ctrl.dateTimePeriodService.defaultPeriod);
            this.dtp = this.dateTimePeriodService.dtp;
            this.buildChart()
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();

        try {
            if (this.chart) {
                this.chart.destroy();
            }
        } catch (e) {

        }
    }

    addSubscriptions() {
        const ctrl = this;
        this.tileData.addCommentClicked.pipe(takeUntil(ctrl.onDestroy)).subscribe(value => this.saveComment(value));

        this.tileData.commentHover.pipe(takeUntil(ctrl.onDestroy), debounceTime(50)).subscribe(value =>
            this.commentHover(value));

        this.tileData.commentLeave.pipe(takeUntil(ctrl.onDestroy), debounceTime(50)).subscribe(value =>
            this.commentLeave(value));

        this.dateTimePeriodService.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dtp = dtp;
            try {

                if (this.chart) {
                    this.chart.destroy();
                }
                this.buildChart();

            } catch (e) {
                console.log('this error was caught', e)
            }
        });
    }

    getDataOptimal() {
        const ctrl = this;
        ctrl.dtp.start = ctrl.zoom_start;
        ctrl.dtp.end = ctrl.zoom_end;
        ctrl.dtp.sample_period = this.dateTimePeriodService.sample_dict.points;
        ctrl.getData()
    }

    buildChart() {
        try {
            this.setUp()
            // this.task_complete.pipe(take(1)).subscribe(() => {
            // });
            // this.task_complete.pipe(skip(1), take(1)).subscribe(() => {
            //
            // });
        } catch (err) {
            console.log('ERROR: ', err);
        }
    }

    setUp() {
        let ctrl = this;
        let series_full_promise;
        ctrl.config_series_list = utils.deepCopy(ctrl.tile_config.series_list);

        if (ctrl.config_series_list === undefined) {
            console.log("ERROR: Incorrect amount of series");
            return;
        }
        if (ctrl.tile_config.chart_type && ctrl.tile_config.chart_type === 'comparison' &&
            (!ctrl.config_series_list[0] || !ctrl.config_series_list[1])) {
            console.log("ERROR: Incorrect number of series for a comparison chart.");
            return;
        }

        ctrl.labels = utils.deepCopy(ctrl.tile_config.labels);
        ctrl.tileData.title = ctrl.labels.title;
        ctrl.tileData.sub_title = ctrl.labels.sub_title;

        ctrl.series_list = [];
        const estimate_ids = [];
        ctrl.estimate_names = [];
        ctrl.series_ids = [];
        ctrl.series_description_list = [];

        ctrl.period = utils.deepCopy(ctrl.dtp.sample_period);

        let series_name_list = ctrl.config_series_list.map(series => series.name);

        const query = ctrl.api.prep_q([{
            name: 'name',
            op: 'in',
            val: series_name_list
        }], {});

        ctrl.api.series.search(query).pipe(
            takeUntil(this.onDestroy)
        ).subscribe(response => {
            ctrl.series_full = response.data;
            ctrl.$events = ctrl.getEvents(); //This needs ctrl.series_full;

            ctrl.config_series_list.forEach(config_item => {
                ctrl.series_full.forEach(series => {
                    if (config_item.name === series.attributes.name) {
                        const full_series = utils.deepCopy(series);
                        full_series['config'] = config_item;
                        if (config_item.tag) {
                            ctrl.is_budget = true;
                        }
                        ctrl.series_list.push(full_series);
                    }
                })
            });
            this.getTimeRanges();

            ctrl.estimate_config = ctrl.series_list.filter(item => item.config.type === 'budget');
            if (ctrl.estimate_config) {
                ctrl.estimate_config.forEach(estimate => {
                    estimate_ids.push(estimate.id);
                })
            }

            ctrl.series_list.forEach(series => {
                ctrl.series_ids.push(series.id);
                // if (series.attributes.description !== null && series.attributes.description !== undefined)
                //     ctrl.series_description_list.push(series.attributes.description);
            });

            // Used for single series and budget charts only
            const setPeriod = (series) => {
                if (series.attributes.sample_period) {
                    if (ctrl.dateTimePeriodService.sample_dict[series.attributes.sample_period].hours > ctrl.period.hours) {
                        ctrl.period = ctrl.dateTimePeriodService.sample_dict[series.attributes.sample_period];
                    }
                }
            };
            ctrl.series_list.forEach(series => {
                if (ctrl.config_series_list.length === 1 || (ctrl.is_budget && series.config.tag === 'Actual')) {
                    setPeriod(series);
                }
            });

            ctrl.getData()
        });
    }

    fillColourPattern(colour_pattern) {
        let default_pattern = ['teal', 'orangered', 'dodgerblue', 'darkslateblue', 'darkorange', 'darkturquoise', 'green', 'olive', 'gray', 'black', 'maroon'];
        if (this.appScope.config_name_map.hasOwnProperty("palette2")) {
            default_pattern = (this.appScope.config_name_map.palette2.value.map(colour => colour.colour)).concat(default_pattern);
        } else if (this.appScope.config_name_map.hasOwnProperty("pallette2")) {
            default_pattern = (this.appScope.config_name_map.pallette2.value.map(colour => colour.name)).concat(default_pattern);
        }

        for (let i = 0; i < colour_pattern.length; ++i) {
            if (colour_pattern[i] === "#111") {
                for (let c = 0; c < default_pattern.length; ++c) {
                    if (colour_pattern.indexOf(default_pattern[c]) < 0) {
                        colour_pattern[i] = default_pattern[c];
                        break;
                    }
                }
            }
        }
    }

    /**
     * Populates the this.range_map.
     *
     */
    getTimeRanges() {
        const ctrl = this;

        ctrl.range_map = {};

        ctrl.series_list.forEach(series => {
            let dtp;
            let series_config = series.config;
            if (series_config.range === undefined || series_config.range === null) {
                dtp = utils.deepCopy(ctrl.dtp);
                series_config.range = dtp.range;
                series_config.sample_period = dtp.sample_period;
            }
            if (series_config.range !== 'custom') {
                //Custom only applies to the page dtp so would have been set already
                dtp = ctrl.dateTimePeriodService.getDTP(series_config.range);
            }
            if (series_config.sample_period && series_config.sample_period.hours >= dtp.sample_period.hours) {
                dtp.sample_period = ctrl.dateTimePeriodService.sample_dict[series_config.sample_period.name];
            }

            let key = series_config.range + ' (' + series_config.sample_period.name + ')';
            if (!ctrl.range_map[key]) {
                ctrl.range_map[key] = {
                    series_list: [series],
                    dtp: dtp
                }
            } else {
                ctrl.range_map[key].series_list.push(series)
            }
        });

        ctrl.multi_dtp = Object.keys(ctrl.range_map).length > 1;
    }

    getData() {
        let ctrl = this;
        const ops_periods = ['points', 'second', 'minute', '5 minute', '10 minute', '15 minute', '30 minute'];
        if (ctrl.period) {
            let $time_series_data = [];
            const is_ops = ops_periods.includes(ctrl.dtp.sample_period.name);
            if (is_ops) {
                const params = {
                    series_list: ctrl.series_ids,
                    start: ctrl.dtp.start.toISOString(),
                    end: ctrl.dtp.end.toISOString(),
                    sample_period: ctrl.dtp.sample_period.name,

                };

                // if (ctrl.dtp.sample_period.name === 'Points') {
                //     params.use_points = 1;
                //     params.sample_period = '2000P';
                // }
                $time_series_data.push(ctrl.api.get('/api/OpsData', {
                        params: params
                    })
                )

            } else {
                Object.values(ctrl.range_map).forEach(function (range: { series_list: any[], dtp: DateTimePeriod }) {
                    let sub = ctrl.api.get('/GetData', {
                        params: {
                            series_list: range.series_list.map(s => s.id),
                            start: range.dtp.start.toISOString(),
                            end: range.dtp.end.toISOString(),
                            sample_period: range.dtp.sample_period.wire_sample,
                        }
                    });
                    $time_series_data.push(sub)
                })
            }

            //TODO get events working here on all iterations
            forkJoin($time_series_data).pipe(takeUntil(this.onDestroy)).subscribe((data) => {
                ctrl.data = data;
                let i = 0;
                data.forEach(range => {
                    ctrl.data[i].range = ctrl.range_map[Object.keys(ctrl.range_map)[i]].dtp.range;
                    ctrl.data[i].sample_period = ctrl.range_map[Object.keys(ctrl.range_map)[i]].dtp.sample_period;
                    ctrl.data[i].series_list = ctrl.range_map[Object.keys(ctrl.range_map)[i]].series_list;
                    i++;
                });

                if (ctrl.chart_type === 'comparison') {
                    ctrl.calculateStats();
                }
                if (this.presentationSubscription) {
                    this.presentationSubscription.unsubscribe();
                    this.presentationSubscription = null;
                }

                this.presentationSubscription = this.presentationService.presentationStateChanged.pipe(takeUntil(this.onDestroy)).subscribe(state => {
                    if (this.chart) {
                        try {
                            this.chart.destroy();
                        } catch (e) {
                            console.log('Can\'t destroy chart, component was likely destroyed with ngIf')
                        }
                        // this.chart = null;
                    }
                    this.presenting = state;

                    this.prepareData();
                    //TODO events needs to be checked but can't get this right in all instances of events response
                    ctrl.$events.pipe(takeUntil(ctrl.onDestroy)).subscribe(() => {
                        this.generateChart();
                        this.renderChart();
                    })
                });
            })
        }

    }

    calculateStats() {
        const ctrl = this;

        let series_name_list = [];
        let dataComparisonSet = ctrl.data[0]['data'];
        let value_arrays = {};
        ctrl.graph_data_stats = {};
        ctrl.graph_data_stats['stats_table'] = [];
        ctrl.statsColumns = [];
        let series_input_names = [];
        ctrl.tile_config.series_list.map(element => {
            series_input_names.push(element.name)
        });
        for (let series in dataComparisonSet) {
            if (series_input_names.includes(series)) {
                series_name_list.push(series);
                let values = [];
                for (let series_data_time in dataComparisonSet[series]) {
                    let value = dataComparisonSet[series][series_data_time];
                    values.push(value)
                }
                value_arrays[series] = values;
                let average = values.reduce((a, b) => a + b, 0) / values.length;
                let std_dev = utils.std_dev(values);
                let stats_object = {name: series, average: average, std_dev: std_dev, values: values};
                ctrl.graph_data_stats['stats_table'].push(stats_object)
            }
        }

        let radicand_numerator = Math.pow(ctrl.graph_data_stats.stats_table[0].std_dev, 2) + Math.pow(ctrl.graph_data_stats.stats_table[1].std_dev, 2);
        let radicand_denominator = value_arrays[series_name_list[0]].length + value_arrays[series_name_list[1]].length;
        let radicand = radicand_numerator / radicand_denominator;

        let pooled_std_dev = Math.sqrt(radicand);

        let pooled_average = ctrl.graph_data_stats.stats_table[0].average * ctrl.graph_data_stats.stats_table[0].values.length / (
            2 * ctrl.graph_data_stats.stats_table[0].values.length - 2);

        ctrl.graph_data_stats['r2'] = utils.pearsonCorrelationCoefficient(value_arrays[series_name_list[0]], value_arrays[series_name_list[1]]);
        let pooled = {};

        pooled['name'] = 'Pooled';
        pooled['average'] = pooled_average;
        pooled['std_dev'] = pooled_std_dev;
        ctrl.graph_data_stats.stats_table.push(pooled);

        ctrl.statsColumns.push('name');

        if (ctrl.tile_config.statistics.averages) {
            ctrl.statsColumns.push('average');
        }
        if (ctrl.tile_config.statistics.std_dev) {
            ctrl.statsColumns.push('std_dev');
        }

        if (ctrl.tile_config.regression_line && ctrl.tile_config.regression_line.toLowerCase() !== 'none') {
            let zip = (x, y) => x.map((x, i) => [x, y[i]]);
            let xValues;
            let yValues;
            ctrl.tile_config.series_list.map(axisObj => {
                if (axisObj['axis'] == 'x') {
                    xValues = value_arrays[axisObj['name']]
                } else if (axisObj['axis'] == 'y') {
                    yValues = value_arrays[axisObj['name']]
                }
            });

            let regressionData = zip(xValues, yValues);
            let result;
            switch (ctrl.tile_config.regression_line.toLowerCase()) {
                case 'linear':
                    result = regression.linear(regressionData);
                    break;
                case 'power':
                    result = regression.power(regressionData);
                    break;
                case 'exponential':
                    result = regression.exponential(regressionData);
                    break;
            }
            ctrl.regression_result = result;
            let yPredictions = [];
            xValues.map(x => {
                yPredictions.push(result.predict(x))
            });

            let yRegression = [];
            yRegression.push('regression');
            yPredictions.map(y => {
                yRegression.push(y[1])
            });

            ctrl.yRegressionData = yRegression;
        }
    }

    prepareComparisonChart() {
        const ctrl = this;
        let time_list = [];
        ctrl.xIndex = this.series_list[0].config.axis === 'x' ? 0 : 1;
        ctrl.yIndex = this.series_list[0].config.axis === 'y' ? 0 : 1;
        let seriesX = ctrl.data[0].data[this.series_list[ctrl.xIndex].attributes.name];
        let seriesY = ctrl.data[0].data[this.series_list[ctrl.yIndex].attributes.name];
        let x = [], y = [];

        for (let time_stamp in seriesX) {
            if (seriesX.hasOwnProperty(time_stamp)) {
                x.push(seriesX[time_stamp]);
                y.push(seriesY[time_stamp]);
                time_list.push(new Date(time_stamp));
                if (!ctrl.time_dict[seriesX[time_stamp] + '_' + seriesY[time_stamp]]) {
                    ctrl.time_dict[seriesX[time_stamp] + '_' + seriesY[time_stamp]] = [new Date(time_stamp)];
                } else {
                    ctrl.time_dict[seriesX[time_stamp] + '_' + seriesY[time_stamp]].push(new Date(time_stamp));
                }
            }
        }
        ctrl.types['comparison'] = 'line';
        ctrl.axes['comparison'] = 'y';
        ctrl.columns.push(['x'].concat(x));
        ctrl.columns.push(['comparison'].concat(y));

        this.legend = false;

        let getLabel = function (i) {
            if (ctrl.series_list[i].attributes.description !== null && ctrl.series_list[i].attributes.description !== undefined) {
                return ctrl.series_list[i].attributes.description;
            } else {
                return ctrl.series_list[i].attributes.name;
            }
        };
        if (this.tile_config.labels.x_axis) {
            ctrl.labels['x_axis'] = ctrl.tile_config.labels.x_axis
        } else {
            ctrl.labels['x_axis'] = getLabel(ctrl.xIndex);
        }
        if (this.tile_config.labels.y_axis) {
            ctrl.labels['y_axis'] = ctrl.tile_config.labels.y_axis
        } else {
            ctrl.labels['y_axis'] = getLabel(ctrl.yIndex);
        }
    }

    prepareData() {
        const ctrl = this;
        ctrl.groups = {};
        ctrl.types = {};
        ctrl.axes = {};
        ctrl.name_tag_map = {};
        ctrl.tag_type_map = {};
        ctrl.tag_class_map = {};
        ctrl.show_y2 = false;
        ctrl.colour_pattern = [];
        ctrl.line_list = [];
        ctrl.y_max = {y: null, y2: null};
        ctrl.y_min = {y: null, y2: null};
        ctrl.estimates = {};

        ctrl.range_matrix = {
            xs: {
                'x1': {
                    label: 'x1',
                    time_column: [],
                    columns: []
                }
            },
            data_columns: []
        };

        for (let i = 0; i < ctrl.data.length; i++) {
            let xi = 'x' + (i + 1);
            ctrl.range_matrix.xs[xi] = {label: xi, time_column: [], columns: []};
            ctrl.range_matrix.xs[xi].columns = ctrl.data[i].series_list.map(s => {
                return {
                    name: s.attributes.name + ctrl.data[i].range,
                    label: s.attributes.name
                }
            });
        }
        if (this.chart_type === 'comparison') {
            this.prepareComparisonChart();
            ctrl.tag_class_map['diagonal'] = 'diagonal';
            ctrl.tag_class_map['regression'] = 'regression';
        } else {
            for (let range_data of ctrl.data) {
                let xi = 'x' + (ctrl.data.indexOf(range_data) + 1);
                let got_time: boolean = false;
                let time_list = [];
                let columns = [];
                range_data.series_list.forEach(series => {
                    let series_key = series.attributes.name + ' ' + range_data.range + ' (' + range_data.sample_period.name + ')';
                    ctrl.custom_legend_names[series_key] = series.config.custom_legend_name ? series.config.custom_legend_name : series.attributes.description ?
                        series.attributes.description : series.attributes.name;

                    if (series.attributes.description !== null && series.attributes.description !== undefined) {
                        var series_tag = series.attributes.description;
                    } else {
                        var series_tag = series.attributes.name;
                    }

                    if (ctrl.series_list.length === 1) {
                        ctrl.labels['y_axis'] = ctrl.labels.title;
                    }
                    if (series.config.cumulative === true) {
                        series_tag = series_tag + ' (Cum)';
                        series_key = series_key + ' (Cum)';
                        ctrl.custom_legend_names[series_key] = series_tag;
                    }
                    if (series.config.tag) {
                        series_tag = series.config.tag;
                        series_key = series.config.tag;
                        ctrl.custom_legend_names[series_key] = series_tag;
                        console.log('GenericChartComponent - series_key: ', series_key);
                        ctrl.is_budget = true;
                    }
                    ctrl.missing_values[series_key] = range_data.missing_values;

                    ctrl.name_tag_map[series_key] = series.attributes.name;
                    ctrl.series_key_to_name_map[series.attributes.name] = series_key;
                    //series key has the custom date string
                    ctrl.tag_key_map[series_key] = series_tag;

                    if (series.config.type === 'budget') {
                        series.config.type = 'bar';

                        let estimate_name = null;
                        (series.attributes.estimate_names.split(',')).forEach(name => {
                            if (ctrl.estimate_names.indexOf(name) > -1) {
                                estimate_name = name;
                            }
                        });
                        ctrl.estimates[series_key] = range_data.data[estimate_name];
                    }

                    ctrl.tag_type_map[series_key] = series.config.type;
                    ctrl.tag_class_map[series_key] = series.config.line_type + ' ' + series.config.line_thickness;

                    ctrl.types[series_key] = series.config.type;
                    ctrl.axes[series_key] = series.config.axis;

                    const current_axis = series.config.axis;
                    if (current_axis === 'y2') {
                        ctrl.show_y2 = true;
                    }

                    let series_data = [];
                    for (let time_stamp in range_data['data'][series.attributes.name]) {
                        if (range_data['data'][series.attributes.name].hasOwnProperty(time_stamp)) {
                            if (!got_time) {
                                time_list.push(new Date(time_stamp))
                            }
                            series_data.push(range_data['data'][series.attributes.name][time_stamp])
                        }
                    }

                    if (series.config.colour) {
                        ctrl.colour_pattern.push(series.config.colour);
                    } else {
                        ctrl.colour_pattern.push('#111');
                    }

                    if (series.config.cumulative === true) {
                        series_data = utils.accumulateData(series_data);
                    }

                    const max_val = series_data.reduce((a, b) => Math.max(a, b));
                    const min_val = series_data.reduce((a, b) => Math.min(a, b));

                    ctrl.y_max[current_axis] = Math.max(max_val, ctrl.y_max[current_axis]);
                    ctrl.y_min[current_axis] = Math.min(min_val, ctrl.y_min[current_axis]);
                    if (series.config.show_limits) {
                        ctrl.show_limits = true;
                        if (series.attributes.hihi !== null) {
                            ctrl.line_list.push({
                                value: utils.scaleLimits(series.attributes.hihi, ctrl.dtp, series),
                                class: 'hihi',
                                text: 'V/HIGH',
                                axis: current_axis
                            });
                        }
                        if (series.attributes.hi !== null) {
                            ctrl.y_max[current_axis] = Math.max(max_val, ctrl.y_max[current_axis], utils.scaleLimits(series.attributes.hi, ctrl.dtp, series));
                            ctrl.line_list.push({
                                value: utils.scaleLimits(series.attributes.hi, ctrl.dtp, series),
                                class: 'hi',
                                text: 'HIGH',
                                axis: current_axis
                            });
                        }
                        if (series.attributes.low !== null) {
                            ctrl.y_min[current_axis] = Math.min(min_val, ctrl.y_min[current_axis], utils.scaleLimits(series.attributes.low, ctrl.dtp, series));

                            ctrl.line_list.push({
                                value: utils.scaleLimits(series.attributes.low, ctrl.dtp, series),
                                class: 'lo',
                                text: 'LOW',
                                axis: current_axis
                            });
                        }
                        if (series.attributes.lowlow !== null) {
                            ctrl.line_list.push({
                                value: utils.scaleLimits(series.attributes.lowlow, ctrl.dtp, series),
                                class: 'lolo',
                                text: 'V/LOW',
                                axis: current_axis
                            });
                        }
                    }

                    if (series.config.group) {
                        if (ctrl.groups[series.config.group]) {
                            ctrl.groups[series.config.group].push(series_key);
                        } else {
                            ctrl.groups[series.config.group] = [series_key];
                        }
                    }
                    ;
                    if (!got_time) {
                        this.range_matrix.xs[xi].time_column = [xi.toString()].concat(time_list);
                        got_time = true;
                    }
                    this.range_matrix.data_columns.push({data: [series_key].concat(series_data), x: xi});
                });

                for (const [key, value] of Object.entries(ctrl.custom_legend_names)) {

                    // @ts-ignore
                    ctrl.custom_names_to_series_tag_names[value] = key

                }

            }

            this.fillColourPattern(ctrl.colour_pattern);

        }
    }

    generateChart() {
        const ctrl = this;
        let xs = {};
        if (ctrl.chart_type !== 'comparison') {

            ctrl.range_matrix.data_columns.forEach(col => {
                xs[col.data[0]] = col.x
            });
            ctrl.columns = (Object.values(ctrl.range_matrix.xs)).map(col => col['time_column']).concat(
                ctrl.range_matrix.data_columns.map(col => col.data));
        }

        ctrl.chart_config = {
            data: {
                xs: xs,
                selection: {
                    enabled: true,
                    draggable: true,
                    multiple: true,
                    //change: this.handleClick()
                },
                onclick: e => {
                    ctrl.getSelected(e);
                },
                names: ctrl.custom_legend_names,
                columns: ctrl.columns,
                classes: {},
                color: function getColor(color, d, e) {
                    const key = typeof d === "string" ? `${color}:${d}` : color;
                    let cachedColor = colorCache[key];
                    if (cachedColor && typeof d !== "object") {
                        return cachedColor;
                    }
                    let rgb_color = d3.rgb(color);
                    let tag = null;
                    let value;
                    if (d.id === 'Actual' && ctrl.name_tag_map['Budget']) {
                        tag = ctrl.name_tag_map['Budget'];
                        value = ctrl.data[0].data[tag][Object.keys(ctrl.data[0].data[tag])[d.index]];
                    } else if (d.id && ctrl.estimates[d.id]) {
                        tag = ctrl.name_tag_map[d.id];
                        value = ctrl.estimates[d.id][Object.keys(ctrl.estimates[d.id])[d.index]];
                    }
                    if (d.id === 'Actual' || (d.id && ctrl.estimates[d.id])) {
                        if (value > (ctrl.data[0].data[ctrl.name_tag_map[d.id]])[Object.keys(ctrl.data[0].data[ctrl.name_tag_map[d.id]])[d.index]]) {
                            // rgb_color.r = 150;
                            // rgb_color.g = 46;
                            // rgb_color.b = 45;
                            rgb_color = d3.rgb(ctrl.budget_palette.actual_below);
                        }
                    }
                    if (ctrl.missing_values[ctrl.name_tag_map[d.id]] !== undefined &&
                        ctrl.missing_values[ctrl.name_tag_map[d.id]][Object.keys(ctrl.missing_values[ctrl.name_tag_map[d.id]])[d.index]]) {
                        if (ctrl.tag_type_map[d.id] === 'bar') {
                            cachedColor = 'rgba(' + d3.rgb(color).r + ', ' + d3.rgb(color).g + ', ' + d3.rgb(color).b + ', 0.3)';
                        } else {
                            cachedColor = 'rgba(' + d3.rgb(color).r + ', ' + d3.rgb(color).g + ', ' + d3.rgb(color).b + ', 0.01)';
                        }
                    } else {
                        cachedColor = 'rgba(' + rgb_color.r + ', ' + rgb_color.g + ', ' + rgb_color.b + ', 1.0)';
                    }
                    colorCache[key] = cachedColor;
                    return cachedColor;
                },
                types: ctrl.types,
                axes: ctrl.axes,
                groups: Object.keys(ctrl.groups).map(key => ctrl.groups[key])
            },
            color: {
                pattern: ctrl.colour_pattern
            },
            zoom: {
                enabled: true,
                rescale: true,
            }
        };

        if (ctrl.chart_type !== 'comparison') {
            ctrl.chart_config.zoom.onzoom = (a, b, c, d) => {
                ctrl.zoom_start = utils.getRoundedDate(60, a[0]);
                ctrl.zoom_end = utils.getRoundedDate(60, a[1]);
            }
        }

        if (this.tile_config.hide_legend === true) {
            ctrl.legend = false;
            ctrl.chart_config.padding = 10;
        }

        if (this.tile_config.hide_axes === true) {
            ctrl.hide_axes = true;
        }

        if (this.tile_config.set_size) {
            ctrl.chart_config.size = {
                height: this.tile_config.set_size.height,
                width: this.tile_config.set_size.width
            }
        }

        /*
         * We have to manually track which legend item have been toggled and their state since we overwrite the legend
         * item onclick event.
         */
        const clicked_legend: { [key: string]: boolean } = {};
        /*
        * Note that c3 makes space for the legend so we leave the legend in and simply hide it, then
        * position the custom legend on top of the hidden legend
        */
        ctrl.chart_config.legend = {
            show: ctrl.legend,
            item: {
                onclick: function (d) {
                    let isClicked = clicked_legend[d];
                    if (isClicked === undefined) {
                        isClicked = true;
                    }
                    clicked_legend[d] = !isClicked;
                    ctrl.chart.show();
                    Object.keys(clicked_legend).forEach(key => {
                        if (!clicked_legend[key]) {
                            ctrl.chart.hide(key);
                        }
                    });
                    ctrl.formatX();
                }
            }
        };

        if (this.presenting) {
            ctrl.chart_config.legend.padding = 35;
        }
        let c3_type = '';
        ctrl.config_series_list.map(config => {
            c3_type = config.type
        });

        let custom_tool_tip = {
            format: {
                title: function (name) {
                    if (c3_type == 'pie' || c3_type == 'gauge' || c3_type == 'donut') {
                        return name
                    } else {
                        return name
                    }
                },
                value: function (value, ratio, id, index) {
                    if (c3_type == 'pie' || c3_type == 'gauge' || c3_type == 'donut') {
                        return utils.significantNumber(ratio * 100).toString() + '%, ' + utils.significantNumber(value).toString()
                    } else {
                        return utils.significantNumber(value)
                    }

                }
            }
        };
        if (c3_type !== 'pie' && c3_type !== 'gauge' && c3_type !== 'donut') {
            custom_tool_tip['contents'] = function (d, titleFormat, valueFormat, color) {
                let text;
                text = "<table class='c3-tooltip c3-tooltip-custom'><tbody><tr><th colspan='2'>" + "</th></tr>";
                d.forEach(s => {
                    let cachedColor = 'rgba(' + d3.rgb(color(s)).r + ', ' + d3.rgb(color(s)).g + ', ' + d3.rgb(color(s)).b + ', 1)';
                    text += "<tr class='c3-tooltip-name'>";
                    text += "<td class='name'><span style='border-color:" + cachedColor + ";background-color:" + cachedColor + ";' class='" + ctrl.tag_type_map[ctrl.custom_names_to_series_tag_names[s.name]] + " " + ctrl.tag_class_map[ctrl.custom_names_to_series_tag_names[s.name]] + "'></span>" + s.name + "</td>";
                    text += "<td class='value'>" + s.value.toFixed(3) + "</td></tr>";
                });
                return text + "</tbody></table>";
            }
        }

        ctrl.chart_config.tooltip = custom_tool_tip;

        switch (c3_type) {
            case 'pie':
                ctrl.chart_config.type = 'pie';
                break;
            case 'donut':
                ctrl.chart_config.type = 'donut';
                ctrl.chart_config['donut'] = {
                    title: ctrl.tile_config.chart_title,
                    width: ctrl.tile_config.width
                };
                break;
            case 'gauge':
                ctrl.chart_config.type = 'gauge';
                ctrl.chart_config['gauge'] = {
                    min: ctrl.tile_config.min,
                    max: ctrl.tile_config.max,
                    width: ctrl.tile_config.width
                };
                break;
        }

        if (ctrl.is_budget) {
            ctrl.budget_palette = ctrl.tile_config.budget_palette;
        }
        if (ctrl.chart_type === 'comparison') {
            delete ctrl.chart_config.data.xs;
            ctrl.chart_config.data['x'] = 'x';
            ctrl.tile_config.series_list.map((series_object) => {
                    if (series_object.axis == 'x') {
                        ctrl.x_axis_series_name = series_object.name;
                    }
                    if (series_object.axis == 'y') {
                        ctrl.y_axis_series_name = series_object.name;
                    }
                }
            );

            ctrl.chart_config.tooltip = {
                show: true,
                contents: function (d) {
                    let arrTimes = ctrl.time_dict[d[0].x + '_' + d[0].value];
                    let text;
                    text = "<table class='chart-tooltip'><tr><th colspan='2'>Time stamp</th></tr>";
                    arrTimes.forEach(time => {
                        text += "<tr><td colspan='2'>" + moment(time).format('YYYY-MM-DD hh:mm') + "</td></tr>";
                    });
                    text += "<tr><td>" + ctrl.labels['x_axis'] + " value" + "</td><td>" + d[0].x.toFixed(3) + "</td></tr>";
                    text += "<tr><td>" + ctrl.labels['y_axis'] + " value" + "</td><td>" + d[0].value.toFixed(3) + "</td></tr>";

                    return text + "</table>";
                }
            };

            if (ctrl.tile_config.diagonal_line) {

                let yValues = utils.deepCopy(ctrl.columns[0]);
                yValues.shift();
                let diagonal_data = ['diagonal'].concat(yValues);
                ctrl.chart_config.data.columns.push(diagonal_data);
                ctrl.chart_config.data.types['diagonal'] = 'line';

            }
            if (ctrl.tile_config.regression_line.toLowerCase() !== 'none') {
                ctrl.chart_config.data.columns.push(ctrl.yRegressionData);
                ctrl.chart_config.data.types['regression'] = 'line';
            }

        }

        ctrl.columns.forEach(col => {
            let data_class = ctrl.tag_class_map[col[0]];
            if (data_class && col[0] !== 'x' && (ctrl.tag_type_map[col[0]] || ctrl.tag_type_map[col[0]] === 'line')
                && !Object.keys(ctrl.chart_config.data.classes).includes(col[0])) {
                ctrl.chart_config.data.classes[col[0]] = data_class;
            }
        });

        ctrl.chart_config.axis = {
            x: {
                show: !ctrl.hide_axes,
                tick: {
                    multiline: true,
                    multilineMax: 2,
                    culling: {max: ctrl.max_ticks}
                },
                label: {},
                labelMaxWidth: 10,
                labelWrap: true
            },
            y: {
                show: !ctrl.hide_axes,
                label: {},
                tick: {
                    culling: {max: ctrl.max_ticks},
                    //format: (v, id, i, j) => utils.significantNumber(v)
                }
            },
            y2: {
                show: ctrl.show_y2 && !ctrl.hide_axes,
                label: {},
                tick: {
                    culling: {max: ctrl.max_ticks},
                    format: (v, id, i, j) => utils.significantNumber(v)
                }
            }
        };
        //TODO I assume this must remove multiple x axis values now?
        let xValues;
        if (ctrl.columns[0]) {
            xValues = utils.deepCopy(ctrl.columns[0]);
            xValues.shift();
        }
        if (ctrl.chart_type !== 'comparison') {
            ctrl.chart_config.axis.x.type = 'timeseries';
            ctrl.chart_config.axis.x.tick.format = function (time) {
                return ctrl.dtp.sample_period.format(time, ctrl.dtp, ctrl.time_list)
            };
        } else {
            ctrl.chart_config.axis.x.tick.values = function () {
                return utils.gen_xTick_values(xValues, ctrl.max_ticks);
            }
        }
        if (ctrl.show_limits === true) {
            ctrl.chart_config.axis.y.max = ctrl.y_max['y'];
            if (ctrl.y_min['y'] !== 0) {
                ctrl.chart_config.axis.y.min = ctrl.y_min['y'];
            }
            if (ctrl.show_y2) {
                ctrl.chart_config.axis.y2.max = ctrl.y_max['y2'];
                if (ctrl.y_min['y'] !== 0) {
                    ctrl.chart_config.axis.y2.min = ctrl.y_min['y2'];
                }
            }
        }

        //Setting y_min and max will override show_limits
        if (ctrl.tile_config.set_range) {
            ctrl.chart_config.axis.y.padding = {top: 0, bottom: 0};
            ctrl.chart_config.axis.y2.padding = {top: 0, bottom: 0};
            if (ctrl.tile_config.y_min) {
                ctrl.chart_config.axis.y.min = parseInt(ctrl.tile_config.y_min);
            }
            if (ctrl.tile_config.y_max) {
                ctrl.chart_config.axis.y.max = parseInt(ctrl.tile_config.y_max);
            }
            if (ctrl.tile_config.y2_min) {
                ctrl.chart_config.axis.y2.min = parseInt(ctrl.tile_config.y2_min);
            }
            if (ctrl.tile_config.y2_max) {
                ctrl.chart_config.axis.y2.max = parseInt(ctrl.tile_config.y2_max);
            }
        }

        ctrl.chart_config.axis.y.label.text = ctrl.labels.y_axis;
        ctrl.chart_config.axis.y.label.position = 'outer-middle';
        ctrl.chart_config.axis.y2.label.text = ctrl.labels.y2_axis;
        ctrl.chart_config.axis.y2.label.position = 'outer-middle';
        if (ctrl.labels['x_axis'] !== '') {
            ctrl.chart_config.axis.x.label.text = ctrl.labels.x_axis;
            ctrl.chart_config.axis.x.label.position = 'outer-center';
        }
        //}
        ctrl.chart_config.grid = {
            y: {
                lines: ctrl.line_list
            }
        };
        if (!['pie', 'donut', 'gauge'].includes(ctrl.chart_config.type)) {
            ctrl.chart_config.grid['x'] = {
                lines: [
                    {
                        value: new Date(),
                        text: 'NOW'
                    }
                ]
            }
        }

        if (ctrl.hide_comments !== true && !['pie', 'donut', 'gauge'].includes(ctrl.chart_config.type)) {
            ctrl.tileData.events.forEach(event => {
                ctrl.chart_config.grid.x.lines.push({
                    value: new Date(event.attributes.start),
                    text: String.fromCharCode(65 + ctrl.tileData.events.indexOf(event)) + " start",
                    class: 'event-start event-' + event.id + '_' + ctrl.tileData.events.indexOf(event)
                });
                ctrl.chart_config.grid.x.lines.push({
                    value: new Date(event.attributes.end),
                    text: String.fromCharCode(65 + ctrl.tileData.events.indexOf(event)) + " end",
                    class: 'event-end event-' + event.id + '_' + ctrl.tileData.events.indexOf(event)
                });
            });
        }

        // TODO progressively disable features of charts as the amount of data points increase
        if (ctrl.columns[0] && ctrl.columns[0].length > 300) {
            ctrl.chart_config.point = {
                show: false
            };

            ctrl.chart_config.axis.x.tick.fit = true;
            ctrl.chart_config.axis.x.tick.count = ctrl.max_ticks + 5;
        }

        if (ctrl.columns[0] && ctrl.columns[0].length > 4000) {
            alert("Your query is too large, please increase the sample period and refresh.");
            return;
        }
        ctrl.config_ready = true;
    };

    renderChart() {
        const ctrl = this;
        ctrl.chart_config.bindto = ctrl.chart_anchor.nativeElement;

        // TODO this selector does not exist anywhere
        let x = 1;
        // if (document.querySelector('.mySelector')) {
        //     console.log('GenericChartComponent - renderChart: 1');
        //     ctrl.chart = c3.generate(ctrl.chart_config);
        //     ctrl.postRenderChart();
        // } else {
        setTimeout(() => {
            ctrl.tile_config.size = {};
            try {
                ctrl.chart = c3.generate(ctrl.chart_config);
            } catch (err) {
                console.log('ERROR: ', err);
            }
            ctrl.postRenderChart();
        }, 100);
        // }
    }

    //********Post chart render functions/////////////////////////////////////
    postRenderChart() {
        const ctrl = this;
        if (ctrl.chart_type === 'comparison') {
            ctrl.setHeight();
            ctrl.chart.transform('scatter');
            if (ctrl.tile_config.diagonal_line) {
                ctrl.chart.transform('line', 'diagonal');
            }
            if (ctrl.tile_config.regression_line.toLowerCase() !== 'none') {
                ctrl.chart.transform('line', 'regression')
            }
        }

        if (ctrl.tile_config.set_size) {
            // @ts-ignore C3 types incorrect
            ctrl.chart.transform('width', ctrl.tile_config.set_size.width);
            // @ts-ignore C3 types incorrect
            ctrl.chart.transform('height', ctrl.tile_config.set_size.height);
        }
        ctrl.formatX();
        if (ctrl.legend) {
            ctrl.addCustomLegend();
        }

        ctrl.rendered = true;
    }

    addCustomLegend() {
        const ctrl = this;
        let c3_type = '';
        ctrl.config_series_list.map(config => {
            c3_type = config.type
        });
        // @ts-ignore
        let legend_bottom = this.chart.element.offsetHeight - this.chart.internal.height;

        if (!['pie', 'gauge', 'donut'].includes(c3_type)) {
            legend_bottom = legend_bottom - 40
        }
        d3.select('.' + this.id_tag + '.chart-tile')
            .insert('div', '.chart')
            .attr('class', 'legend')
            .style('bottom', legend_bottom + 'px')
            .selectAll('span')
            .data(Object.keys(ctrl.name_tag_map))
            .enter().append('div')
            .attr('data-id', function (name) {
                return name;
            })

            .each(function (name) {
                d3.select(this).append('div')
                    .style('background-color', ctrl.chart.color(name))
                    .style('border-color', ctrl.chart.color(name))
                    .attr('class', 'image ' + ctrl.tag_type_map[name] + " " + ctrl.tag_class_map[name]);

            })
            .on('mouseover', function (id) {
                ctrl.chart.focus(id);
            })
            .on('mouseout', function (id) {
                ctrl.chart.revert();
            })
            .on('click', function (id) {
                ctrl.chart.toggle(id);
            })
            .append('div').html(function (name) {
            return ctrl.custom_legend_names[name];
        })
            .attr('class', 'legend-name')

    }

    formatX() {
        let ctrl = this;
        let ticks = d3.selectAll(".c3-axis-x .tick text").call(t => {
            t.each(function (d) {
                let self = d3.select(this);
                let s = self.text().split(' ');
                self.text(null);
                self.append("tspan")
                    .attr("x", 0)
                    .attr("dy", ".8em")
                    .text(s[0]);
                self.append("tspan")
                    .attr("x", 0)
                    .attr("dy", "1em")
                    .text(s[1]);
            })
        });

    }

    setHeight() {
        //TODO is there a less micromanagey way to do this??
        let el = this.chart_anchor.nativeElement;
        let maxHeight = utils.deepCopy(parseFloat(el.style.maxHeight));

        if (!isNaN(maxHeight) && maxHeight > 300 && !this.rendered &&
            (this.tile_config.statistics.averages || this.tile_config.statistics.pooled ||
                this.tile_config.statistics.std_dev)) {
            if (this.tile_config.statistics.correlation_coeff) {
                maxHeight -= 130;
            } else {
                maxHeight -= 170;
            }
        } else if (!isNaN(maxHeight) && maxHeight > 300 && this.tile_config.statistics.correlation_coeff) {
            maxHeight -= 30;
        }
        this.renderer.setStyle(el, 'maxHeight', maxHeight + 'px');
    }

    //////* Comments section *///////////////////////////////////////////////
    getEvents(): Observable<any> {
        const ctrl = this;
        ctrl.tileData.events = [];
        if (ctrl.tile_config.hide_comments === true) {
            ctrl.tileData.events = [];
            return of(ctrl.tileData.events);
        } else {
            return ctrl.eventData.getEvents(ctrl.dtp.start, ctrl.dtp.end, ctrl.series_full).pipe(
                takeUntil(this.onDestroy),
                tap(events => {
                    ctrl.tileData.events = events ? events.data : [];
                })
            );
        }
    }

    getSelected(e: { x: string, id: string }) {
        if (this.points_set.has(e.x)) {
            this.points_set.delete(e.x);
            this.chart.regions([]);
        } else {
            // reset
            if (this.points_set.size >= 2) {
                this.points_set = new Set<string>();
                this.chart.regions([]);
                d3.selectAll('.c3-selected-circles').remove();
            }
            this.points_set.add(e.x);
        }

        let dates = Array.from(this.points_set);
        dates = dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
        // the id is the description of the series
        this.addRegion(dates, this.name_tag_map[e.id]);
    }

    addRegion(dates: string[], series_name: string) {
        let ctrl = this;
        if (dates.length > 0) {
            if (new Date(ctrl.eventStart) !== new Date(dates[0])) {
                // @ts-ignore
                ctrl.chart.xgrids.remove([{class: 'start-line'}]);
            }
            if (new Date(ctrl.eventEnd) !== new Date(dates[dates.length - 1])) {
                // @ts-ignore
                ctrl.chart.xgrids.remove([{class: 'end-line'}]);
            }
            ctrl.eventStart = utils.deepCopy(dates[0]);
            ctrl.eventEnd = utils.deepCopy(dates[dates.length - 1]);

            setTimeout(() => {
                //checking dates.length prevents start and end line being added on top of each other
                if (dates.length === 1) {
                    ctrl.chart.xgrids.add([
                        {value: new Date(ctrl.eventStart), text: 'Event start', class: 'start-line'}
                    ]);
                }
                if (dates.length === 2) {
                    ctrl.chart.xgrids.add([
                        {value: new Date(ctrl.eventStart), text: 'Event start', class: 'start-line'},
                        {value: new Date(ctrl.eventEnd), text: 'Event end', class: 'end-line'}
                    ]);
                    ctrl.chart.regions([{
                        axis: 'x',
                        start: ctrl.eventStart,
                        end: ctrl.eventEnd,
                        class: 'event-region'
                    }]);
                    this.eventData.toggleComments.emit(true);
                    this.eventData.setShowComments(true); //This one is for styling using observable
                }
            }, 400);

            ctrl.eventSeries = [];
            ctrl.series_full.forEach(series => {
                // for now ignoring if multiple series was selected
                if (series.attributes.name == series_name) {
                    if (ctrl.eventSeries.indexOf(series) < 0) {
                        ctrl.eventSeries.push(series);
                    }
                }
            });
        } else {
            //@ts-ignore
            ctrl.chart.xgrids.remove([{class: 'start-line'}]);
        }
    }

    commentHover(input) {
        let event = input.event;
        let index = input.index;
        let lines = document.getElementsByClassName('event-' + event.id + '_' + index);
        if (lines.length > 0) {
            for (let i = 0; i < lines.length; ++i) {
                lines[i].classList.add("event-line-hover");
            }
        }
    }

    commentLeave(input) {
        let event = input.event;
        let index = input.index;
        let lines = document.getElementsByClassName('event-' + event.id + '_' + index);
        if (lines.length > 0) {
            for (let i = 0; i < lines.length; ++i) {
                lines[i].classList.remove("event-line-hover");
            }
        }
    }

    resetCommentDates() {
        const ctrl = this;

        this.points_selected.forEach(point => {
            d3.select(point).classed("_selected_", false);
        });
        d3.selectAll('.c3-selected-circles').remove();

        //setTimeout( () => {ctrl.chart.flush();}, 500);

        // @ts-ignore
        ctrl.chart.xgrids.remove([{class: 'start-line'}]);
        // @ts-ignore
        ctrl.chart.xgrids.remove([{class: 'end-line'}]);
        ctrl.eventStart = null;
        ctrl.eventEnd = null;
        ctrl.chart.regions([]);

        this.points_selected = [];

        this.points_set = new Set<string>();
    }

    saveComment(comment) {
        const ctrl = this;

        ctrl.eventData.addInlineComment(comment, ctrl.eventStart, ctrl.eventEnd, ctrl.eventSeries).then((new_comment) => {
            ctrl.tileData.events.push(new_comment.data);

            ctrl.resetCommentDates();
            setTimeout(() => {
                ctrl.chart.xgrids.add({
                    value: new Date(new_comment.data.attributes.start),
                    text: String.fromCharCode(65 + ctrl.tileData.events.indexOf(new_comment.data)) + " start",
                    class: 'event-start event-' + new_comment.data.id + '_' + ctrl.tileData.events.indexOf(new_comment.data)
                });
                ctrl.chart.xgrids.add({
                    value: new Date(new_comment.data.attributes.end),
                    text: String.fromCharCode(65 + ctrl.tileData.events.indexOf(new_comment.data)) + " end",
                    class: 'event-end event-' + new_comment.data.id + '_' + ctrl.tileData.events.indexOf(new_comment.data)
                });
            }, 500);
        });
    }
}

