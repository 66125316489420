<style>
  .handsontable .relative {
    position: relative;
    text-align: left;
  }

  table.htCore td {
    white-space: nowrap;
  }

  .manualColumnResizer {
    z-index: 5000;
    top: 80px !important;
  }

  @media (min-width: 960px) {
    .btn-icon {
      margin: 10px 0 0 16px !important;
    }
  }
</style>

<div class="page">
  <mat-tab-group disableRipple>
    <mat-tab label="Table" [hidden]="!hot || !hot.ready">
      <!--<ng-template matTabContent>-->
      <div *ngIf="hot && hot.ready && hot.instance" class="row">
        <input *ngIf="hot && hot.ready && hot.instance" [(ngModel)]="search" placeholder="Search" type="text"/>
        <button (click)="hot.filter(search)" *ngIf="hot && hot.ready && hot.instance"> Search</button>
        <button (click)="hot.clear();search=''" *ngIf="hot && hot.ready && hot.instance"> Clear</button>
        <account-filter (accountFilterEmitter)="hot.filter_account($event)" [allow_blank]="true"></account-filter>
      </div>
      <div class="md-padding">
        <div (window:resize)="hot.onResize($event)" [style.height.px]="hot.window_height"
             style="width: 100%; overflow:hidden; border: 1px red">
          <div #hot_anchor [id]="hot.handson_id">
          </div>
        </div>
      </div>
      <!--</ng-template>-->
    </mat-tab>
    <mat-tab *ngIf="hot && hot.ready && hot.instance" label="Pivot">
      <ng-template matTabContent>
        <!--TODO make height dynamic?-->
        <pivotTable [hot]="hot"></pivotTable>
      </ng-template>
    </mat-tab>
    <!--<mat-tab *ngIf="hot && hot.ready && hot.instance"-->
    <mat-tab *ngIf="hot && hot.ready && hot.instance"
             label="History">
      <ng-template matTabContent>
        <div style="width: 100%; overflow:auto;">
          <!--TODO make height dynamic?-->
          <history-table [hot]="hot"></history-table>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
