import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'log-sheet-form',
    templateUrl: 'log-sheet-form.component.html'
})
export class LogSheetFormComponent implements OnInit {

    private _config: any;
    @Input()
    set config(config) {
        this._config = config;
        this.inputUpdated();
    }

    get config() {
        return this._config;
    }

    private _processes: any[];
    @Input()
    set processes(processes: any[]) {
        this._processes = processes;
        if (this._processes !== undefined) {
            // this.processes = response.data;
            this.inputUpdated();
        }
    }

    get processes() {
        return this._processes;
    }

    process: any;
    logsheet_columns: string[] = [];

    constructor() {
    }

    ngOnInit() {
        const ctrl = this;
        // TODO add the full list of columns
        ctrl.logsheet_columns = ['Value', 'Status'];
    }

    private inputUpdated() {
        const ctrl = this;
        if (!ctrl._config.title) {
            ctrl._config.title = '';
        }

        if (ctrl.processes && ctrl._config.process) {
            ctrl.process = ctrl.processes.find(item => item.id === ctrl._config.process.id);
        }
    }

    private slimProcess(process) {
        return {
            id: process.id,
            attributes: {
                name: process.attributes.name
            }
        }
    }

    processChange() {
        this.config.process = this.slimProcess(this.process);
    }
}
