import * as utils from '../lib/utils';
import * as moment_ from 'moment';
import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {AppScope} from './app_scope.service';
import {ActivatedRoute} from '@angular/router';
import {Subject} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";

export const moment = moment_["default"];

export class SamplePeriod {

    public readonly seconds: number;

    constructor(public readonly name: string,
                public readonly hours: number,
                public readonly wire_sample: string,
                public readonly format: (date: Date, dtp: DateTimePeriod, dates?: Date[]) => string,
                public readonly show?: string) {
        this.seconds = hours * 3600
    }
}

export class DateTimePeriod {

    constructor(public  range: string,
                public  start: Date,
                public  end: Date,
                public  sample_period: SamplePeriod) {
    }

    date_string() {
        return moment(this.start).format('YYYY-MM-DD hh:mm') + "  to  " + moment(this.end).format('YYYY-MM-DD hh:mm');
    };

}

let milliseconds_cache = {};
let seconds_cache = {};
let minutes_cache = {};
let hours_cache = {};
let days_cache = {};
let months_cache = {};

@Injectable()
export class DateTimePeriodService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    range_dict: { [key: string]: DateTimePeriod };
    relative_range_dict: { [key: string]: DateTimePeriod };

    // promise that completes when the service has its first dtp value (when required prerequisites have been met)
    readonly dtp_complete: utils.FlatPromise;

    sample_periods: SamplePeriod[];
    ranges: DateTimePeriod[];
    relative_ranges: DateTimePeriod[];
    dtp: DateTimePeriod;
    defaultPeriod: SamplePeriod;
    sample_dict: { [key: string]: SamplePeriod };
    sample_hours_dict: { [key: number]: SamplePeriod };
    defaultStartHour: number = 6;

    // Only every single change
    readonly dtpChanged: EventEmitter<DateTimePeriod>;
    // Only refresh click
    readonly dtpReset: EventEmitter<DateTimePeriod>;
    defaultRange: any;
    defaultShiftLength: any;
    /**
     * Was the dtp read from a URL.
     */
    read_dtp_from_url: boolean;
    private search_dtp: any;

    public show_timespan: boolean = true;
    public show_time: boolean = true;

    constructor(private appScope: AppScope,
                private activatedRoute: ActivatedRoute,
                private snackbar: MatSnackBar) {
        const ctrl = this;
        this.dtp_complete = new utils.FlatPromise();
        this.dtpChanged = new EventEmitter();
        this.dtpReset = new EventEmitter();

        setTimeout(() => {
            this.search_dtp = activatedRoute.snapshot.queryParams['dtp'];
            this.read_dtp_from_url = this.search_dtp != undefined;
            ctrl.appScope.auth_complete.promise.then(function () {
                ctrl.reset();
                ctrl.dtp = ctrl.getDTP(null, true);
                if (!ctrl.dtp) {
                    console.error('Failed to get DTP!');
                }

                ctrl.dtp_complete.resolve(ctrl.dtp);
            });
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    getDTP(range?: string, init?: boolean): DateTimePeriod {
        const DTP = this;
        // TODO this may have to be updated when making dtp immutable???
        // Only headerData dtp component should use url
        if (this.search_dtp && init === true) {
            let url_dtp = utils.deepCopy(DTP.range_dict[this.defaultRange]);
            let dtp = JSON.parse(this.search_dtp);

            if (dtp.range) { // set defaults from range
                if (dtp.range !== 'custom') {
                    url_dtp = utils.deepCopy(DTP.range_dict[dtp.range]);
                }
            }
            if (dtp.start && dtp.end) { // set custom start and end if there **Overrides range
                url_dtp['start'] = new Date(dtp.start);
                url_dtp['end'] = new Date(dtp.end);
                if (dtp.range === 'custom' ||
                    url_dtp.start !== DTP.range_dict[dtp.range].start ||
                    url_dtp.end !== DTP.range_dict[dtp.range].end) {
                    url_dtp.range = 'custom';
                }
            }

            if (dtp.sample_period) {
                url_dtp.sample_period = utils.deepCopy(DTP.sample_dict[dtp.sample_period.name]);
            }
            url_dtp = DTP.validateDTP(url_dtp);
            return url_dtp;
        }

        if (!range) {
            range = this.defaultRange;
        }

        ///TODO immutable
        const tmp_dtp = utils.deepCopy(DTP.range_dict[range]);

        if (!tmp_dtp) {
            console.error('Failed to get DTP for range', range, 'range dict', DTP.range_dict)
        }

        return DTP.validateDTP(tmp_dtp);

    }

    validateDTP(dtp, alert?: boolean) {
        const DTP = this;

        //let total_hours = ((dtp.end - dtp.start) / 3600000);
        if ((dtp.range == 'this month' || dtp.range == 'this full month') && new Date().getDate() == 1) {
            console.log("DateTimePeriodService: Is 1st of this month", new Date().getHours(), dtp.start.getHours());
            if ((new Date().getHours()) <= dtp.start.getHours()) { //if new month hasn't actually started yet
                dtp = utils.deepCopy(DTP.range_dict["last month"]);
                console.log("set to last month");
                DTP.snackbar.open('No full hours completed for this month yet. Range set to "Last month"', 'Hide');
            } else if ((dtp.end.getHours() < new Date().getHours()) && dtp.range == 'this month') {
                console.log("Setting end to current datetime");
                dtp.end = new Date();
            } else if ((dtp.end.getHours() < new Date().getHours()) && dtp.range == 'this full month') {
                console.log("Setting end to of new month");
                dtp.end = dtp.end.setMonth(dtp.start.getMonth() + 1);
            }
        }

        if (new Date(dtp.start).valueOf() >= new Date(dtp.end).valueOf()) {
            console.log('End date is less than or equal to start date');
            dtp.end = utils.deepCopy(dtp.start).addHours(1);
        }
        // if(total_hours >=48 && dtp.sample_period.hours < this.defaultShiftLength ||
        //     total_hours > 60 && dtp.sample_period.hours < 24 ||
        //     total_hours >= 1488 && !(dtp.sample_period.name == 'week' || dtp.sample_period.name == 'month')){
        //     if (alert){
        //         if (!confirm("The currently selected range and sample period may cause your page to load slowly. " +
        //                 "Press OK to continue with this range. Pressing Cancel will set a smaller range.")) {
        //             valid = false;
        //             DTP.dtp = this.getDTP("since yesterday");
        //         } else {
        //             valid = true;
        //         }
        //     } else {
        //         valid = false;
        //         DTP.dtp = this.getDTP("since yesterday");
        //     }
        // }
        return dtp;
    }

    setDefault(dtp) {
        const ctrl = this;
        if (ctrl.read_dtp_from_url !== true) {
            if (this.appScope.config_name_map['date_period'] && this.appScope.config_name_map['date_period'].value.default_range) {
                console.log('Read range from config: ', this.appScope.config_name_map['date_period'].value.default_range);
                dtp = ctrl.getDTP(this.appScope.config_name_map['date_period'].value.default_range);
            } else {
                dtp = ctrl.getDTP('since yesterday');
            }
        }
        return dtp;
    }

    setRanges(base): DateTimePeriod[] {
        const DTP = this;
        let now = new Date();

        base.start.setHours(DTP.defaultStartHour, 0, 0, 0);
        base.end.setHours(DTP.defaultStartHour, 0, 0, 0);

        const yesterday = utils.deepCopy(base);
        yesterday.start.setDate(base.start.getDate() - 1);

        let this_shift = utils.deepCopy(base);
        this_shift.start['addHours'](-DTP.defaultShiftLength);

        while (!(this_shift.start <= now && this_shift.end > now)) {
            this_shift.start['addHours'](DTP.defaultShiftLength);
            this_shift.end['addHours'](DTP.defaultShiftLength);
        }

        const past_two = utils.deepCopy(base);
        past_two.start.setDate(base.start.getDate() - 2);

        const past_seven = utils.deepCopy(base);
        past_seven.start.setDate(base.start.getDate() - 7);

        const past_thirty = utils.deepCopy(base);
        past_thirty.start.setDate(base.start.getDate() - 30);

        const this_week = utils.deepCopy(base);

        this_week.start.setDate(base.start.getDate() - (base.start.getDay() + (7 - parseInt(DTP.appScope.config_name_map.week_start.value) - 1)));

        const this_month_to_date = utils.deepCopy(base);
        this_month_to_date.start.setDate(1);

        const this_full_month = utils.deepCopy(base);
        this_full_month.start.setDate(1);
        this_full_month.end.setMonth(base.start.getMonth() + 1);
        this_full_month.end.setDate(1);

        const this_year = utils.deepCopy(base);
        this_year.start.setMonth(0);
        this_year.start.setDate(1);

        const last_week = utils.deepCopy(base);
        last_week.end.setDate(base.end.getDate() - (base.end.getDay() + (7 - parseInt(DTP.appScope.config_name_map.week_start.value) - 1)));
        last_week.start = utils.deepCopy(last_week.end);
        last_week.start.setDate(last_week.end.getDate() - 7);

        let last_month = utils.deepCopy(base);
        last_month.start.setDate(base.start.getDate() - (1 + base.start.getDate()));
        last_month.start.setDate(1);
        last_month.end.setDate(1);

        return [new DateTimePeriod("today",
            utils.setToHour(new Date(), DTP.defaultStartHour),
            new Date(),
            DTP.sample_dict['hour']
        ), new DateTimePeriod("this shift",
            this_shift.start,
            this_shift.end,
            DTP.sample_dict['hour']
        ), new DateTimePeriod("yesterday",
            yesterday.start,
            yesterday.end,
            DTP.sample_dict['hour']
        ), new DateTimePeriod("yesterday by half shift",
            yesterday.start,
            yesterday.end,
            DTP.sample_hours_dict[DTP.defaultShiftLength / 2]
        ), new DateTimePeriod("since yesterday",
            yesterday.start,
            utils.setToHour(new Date(), (new Date()).getHours()),
            DTP.sample_dict['hour']
        ), new DateTimePeriod("the past two days",
            past_two.start,
            past_two.end,
            DTP.sample_dict['shift']
        ), new DateTimePeriod("the past seven days",
            past_seven.start,
            past_seven.end,
            DTP.sample_dict['day']
        ), new DateTimePeriod("since the past seven days",
            past_seven.start,
            this_shift.end,
            DTP.sample_dict['day']
        ), new DateTimePeriod("this week",
            this_week.start,
            this_week.end,
            DTP.sample_dict['day']
        ), new DateTimePeriod("last week",
            last_week.start,
            last_week.end,
            DTP.sample_dict['day']
        ), new DateTimePeriod("this month",
            this_month_to_date.start,
            this_month_to_date.end,
            DTP.sample_dict['day']
        ), new DateTimePeriod("this full month",
            this_full_month.start,
            this_full_month.end,
            DTP.sample_dict['day']
        ), new DateTimePeriod("the past thirty days",
            past_thirty.start,
            past_thirty.end,
            DTP.sample_dict['day']
        ), new DateTimePeriod("last month",
            last_month.start,
            last_month.end,
            DTP.sample_dict['day']
        ), new DateTimePeriod("this year",
            this_year.start,
            this_year.end,
            DTP.sample_dict['month']
        )];

    }

    getRelativeDatePeriod(dtp, range) {
        const DTP = this;

        const base = {
            start: new Date(dtp.end), //Child dates will be relative to end date of parent
            end: new Date(dtp.end)
        };

        this.relative_ranges = this.setRanges(base);
        this.relative_range_dict = {};
        this.relative_ranges.map(item => {
            this.relative_range_dict[item.range] = item;
        });
        return this.relative_range_dict[range];
    }

    determineStartFromEndAndPeriod(dtp, sample_period: SamplePeriod, number_of_periods: number) {
        const ctrl = this;
        let start = moment(dtp.end).clone()
        let duration = moment.duration(number_of_periods * sample_period.hours, 'hours')
        start = start.subtract(duration)
        if (sample_period.name == 'month') {
            start = moment(dtp.end).clone().subtract(number_of_periods, 'months')
            start = start.startOf("month")
            start = start.set('hour', ctrl.defaultStartHour)
        }

        return start

    }

    private reset() {
        let DTP = this;

        const base = {
            start: new Date(),
            end: new Date()
        };
        const datePeriod = DTP.appScope.config_name_map.date_period.value;

        DTP.defaultRange = datePeriod.default_range;
        DTP.defaultStartHour = datePeriod.default_start_hour - base.start.getTimezoneOffset() / 60;

        DTP.defaultShiftLength = datePeriod.default_shift_length;

        // let group_dates = function(dates){
        //   let dates_map = {};
        //   dates.map(function(timestamp){
        //       if(timestamp && dates_map[new Date(timestamp).toLocaleString('en-us', { month: "short"})]){
        //           //dates_map[timestamp.toLocaleString('en-us', { month: "short"})].push(timestamp);
        //       } else if(timestamp) {
        //           dates_map[new Date(timestamp).toLocaleString('en-us', { month: "short"})] = [timestamp];
        //       }
        //   });
        //   return dates_map;
        // };

        let hour_format = function (d, dtp, dates) {
            let cached_value = hours_cache[d];
            if (!cached_value) {
                let temp_date = new Date(d);
                let month = temp_date.toLocaleString('en-us', {month: "short"});
                let day = temp_date.toLocaleString('en-us', {day: "2-digit"});
                let hour = temp_date.toLocaleString('en-us', {hour: "2-digit", hour12: false});
                // TODO reimplement what this was (need for generic charts at least)
                if ((Math.round(dtp.end - dtp.start)) / (1000 * 60 * 60) >= 24 && dtp.start.getMonth() != dtp.end.getMonth()) {
                    cached_value = month + " " + day + "T" + hour;
                } else {
                    // if (long === true){
                    //     return temp_date.toDateString();
                    // } else {
                    cached_value = day + "T" + hour;
                    hours_cache[d] = cached_value;
                }
            }
            return cached_value
            // }
            //}
            // } else {
            //     return hour;
            // }
        };
        let millisecond_format = function (d, dtp, dates) {
            let cached_value = milliseconds_cache[d];
            if (!cached_value) {
                let temp_date = new Date(d);
                let month = temp_date.toLocaleString('en-us', {month: "short"});
                const milliseconds = d.getMilliseconds();
                const seconds = d.getSeconds();
                const minutes = d.getMinutes();
                let day = temp_date.toLocaleString('en-us', {day: "2-digit"});

                let hour = temp_date.toLocaleString('en-us', {hour: "2-digit", hour12: false});
                //if((Math.round(dtp.end - dtp.start))/(1000*60*60) >= 24) {
                //     if (dtp.start.getMonth() != dtp.end.getMonth()) {
                //         return month + " " + day + "T" + hour;
                //     } else {
                cached_value = day + "T" + hour + ":" + String(minutes).padStart(2, "0") + ":" + String(seconds).padStart(2, "0") + "." + String(milliseconds).padStart(2, "0");
                milliseconds_cache[d] = cached_value;
                //}
                // } else {
                //     return hour;
                // }
            }
            return cached_value
        };
        let second_format = function (d, dtp, dates) {
            let cached_value = seconds_cache[d];
            if (!cached_value) {
                let temp_date = new Date(d);
                let month = temp_date.toLocaleString('en-us', {month: "short"});
                const seconds = d.getSeconds();
                const minutes = d.getMinutes();
                let day = temp_date.toLocaleString('en-us', {day: "2-digit"});

                let hour = temp_date.toLocaleString('en-us', {hour: "2-digit", hour12: false});
                //if((Math.round(dtp.end - dtp.start))/(1000*60*60) >= 24) {
                //     if (dtp.start.getMonth() != dtp.end.getMonth()) {
                //         return month + " " + day + "T" + hour;
                //     } else {
                cached_value = day + "T" + hour + ":" + String(minutes).padStart(2, "0") + ":" + String(seconds).padStart(2, "0");
                seconds_cache[d] = cached_value;
                //}
                // } else {
                //     return hour;
                // }
            }
            return cached_value
        };
        let minute_format = function (d, dtp, dates) {
            let cached_value = minutes_cache[d];
            if (!cached_value) {
                let temp_date = new Date(d);
                let month = temp_date.toLocaleString('en-us', {month: "short"});
                const minutes = d.getMinutes();
                let day = temp_date.toLocaleString('en-us', {day: "2-digit"});

                let hour = temp_date.toLocaleString('en-us', {hour: "2-digit", hour12: false});
                //if((Math.round(dtp.end - dtp.start))/(1000*60*60) >= 24) {
                //     if (dtp.start.getMonth() != dtp.end.getMonth()) {
                //         return month + " " + day + "T" + hour;
                //     } else {
                cached_value = day + "T" + hour + ":" + String(minutes).padStart(2, "0");
                minutes_cache[d] = cached_value;
                //}
                // } else {
                //     return hour;
                // }
            }
            return cached_value
        };

        let day_format = function (d, dtp, dates) {
            let cached_value = days_cache[d];
            if (!cached_value) {
                let temp_date = new Date(d);
                // if(temp_date.getDate() === 2 || temp_date.getTime() === new Date(dates[0]).getTime()){
                //     temp_date['addHours'](parseInt(-1*(defaultStartHour))-1);
                //     return temp_date.toLocaleString('en-us', { month: "short", day: "numeric"});
                // }
                temp_date['addHours'](-1 * (DTP.defaultStartHour) - 1);
                // if (long === true){
                //return temp_date.toDateString();
                // } else {
                cached_value = temp_date.toLocaleString('en-us', {month: "short", day: "2-digit"});
                days_cache[d] = cached_value;
                // }
                //return temp_date.toLocaleString('en-us', {day: "numeric"});
                //if(d && new Date(dates_map[temp_date.toLocaleString('en-us', { month: "short"})]).getTime()=== temp_date.getTime()){

            }
            return cached_value
        };

        let month_format = function (d, dtp) {
            let cached_value = months_cache[d];
            if (!cached_value) {
                let temp_date = new Date(d);
                temp_date['addHours'](-1 * (DTP.defaultStartHour) - 1);
                //return (temp_date.getFullYear()) + "-" + temp_date.getMonth()+1; //('%Y-%m')
                cached_value = temp_date.toLocaleString('en-us', {year: "numeric", month: "short"});
                months_cache[d] = cached_value;
            }
            return cached_value
        };
        //The server looks for the word 'points' to determine if it should return unaggregated data
        DTP.sample_periods = [
            new SamplePeriod('points', 1 / 3600, '1200P', millisecond_format, 'minutes'),
            new SamplePeriod('second', 1 / 3600, '1200P', second_format, 'minutes'),
            new SamplePeriod('minute', 1 / 60, '60s', minute_format, 'minutes'),
            new SamplePeriod('5 minute', 5 / 60, '300s', minute_format, 'minutes'),
            new SamplePeriod('10 minute', 10 / 60, '600s', minute_format, 'minutes'),
            new SamplePeriod('15 minute', 15 / 60, '900s', minute_format, 'minutes'),
            new SamplePeriod('30 minute', 30 / 60, '1800s', minute_format, 'minutes'),
            new SamplePeriod('hour', 1, '3600s', hour_format),
            new SamplePeriod('two_hour', 2, '7200s', hour_format),
            new SamplePeriod('four_hour', 4, '14400s', hour_format),
            new SamplePeriod('six_hour', 6, '21600s', hour_format),
            new SamplePeriod('eight_hour', 8, '28800s', hour_format),
            new SamplePeriod('shift', DTP.defaultShiftLength, DTP.defaultShiftLength * 3600 + 's', hour_format),
            new SamplePeriod('day', 24, 'day', day_format),
            new SamplePeriod('week', 168, 'week', day_format),
            new SamplePeriod('month', 720, 'month', month_format)];

        DTP.sample_dict = {};
        DTP.sample_hours_dict = {};
        DTP.sample_periods.map(item => {
            DTP.sample_dict[item.name] = item;
            if (!DTP.sample_hours_dict[item.hours]) {
                DTP.sample_hours_dict[item.hours] = item; //get sample period by hours number
            }
        });
        DTP.defaultPeriod = DTP.sample_hours_dict[datePeriod.default_period];

        DTP.ranges = DTP.setRanges(base)

        DTP.range_dict = {};
        DTP.ranges.map(item => {
            DTP.range_dict[item.range] = item;
        });
    }

    setPeriod(dtp, period) {
        dtp.sample_period = utils.deepCopy(this.sample_dict[period]);
    }

    setToStart(dtp) {
        //var defaultStartHour = appScope.config_name_map.date_period.value.default_start_hour;
        dtp.end.setHours(this.defaultStartHour);
        dtp.end.setMinutes(0);
        dtp.start.setHours(this.defaultStartHour);
        dtp.start.setMinutes(0);
        return dtp;
    }

}

