<style>
  .c3-axis-y .tick {
    display: none;
  }
</style>

<div class="page">
  <h1>Grade Recovery Curve</h1>

  <button mat-button (click)="configureForecast()">Configure Forecast</button>

  <div class="row">
    <div id="actualDataCurve" style="width: 75%; height:480px">

    </div>

    <div *ngIf="lineReady">
      <h2>Line of best fit</h2>
      {{lineEquation}} <br>
      {{r2Result}}

    </div>

  </div>

  <div class="row">

    <div id="modeledDataCurve" style="width: 75%">

    </div>
  </div>
  <mat-slider min="0" max="5" step="0.1" value="3.2" [(ngModel)]="slider_value" thumbLabel></mat-slider>
</div>

