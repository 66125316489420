import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AppScope} from "../services/app_scope.service";
import {User} from "./user_list.component";
import {ApiService} from "../services/api.service";
import * as utils from "../lib/utils";
import {MatSnackBar} from "@angular/material";
import {HeaderDataService} from "../services/header_data.service";

@Component({
    selector: 'my-security',
    templateUrl: 'my-security.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MySecurityComponent implements OnInit {
    days: number;
    show_otp: boolean;
    auth_token: any;
    errors: any[];
    user_ready: any;
    user: any;

    constructor(private http: HttpClient,
                public appScope: AppScope,
                private headerData: HeaderDataService,
                private api: ApiService,
                private snackbar: MatSnackBar) {
    }

    ngOnInit(): void {
        const ctrl = this;
        this.headerData.title = 'My Security';
        ctrl.appScope.no_header = false;
        ctrl.days = 90;
        ctrl.appScope.auth_complete.promise.then(() => {
            ctrl.api.users.search(ctrl.api.prep_q([
                {name: 'id', op: 'eq', val: ctrl.appScope.current_user.id}
            ], {'filter[single]': 1})).toPromise().then(response => {
                ctrl.user = response.data;
            })
        });
    }

    checkedChanged(event) {
        const ctrl = this;
        event.preventDefault();
        if (ctrl.checkDisableOTP()) {
            return;
        }
        let conf;
        if (ctrl.user.attributes.require_otp === false) {
            conf = confirm("Are you sure you want to enable Two Factor Authentication? " +
                "Only your administrator will be able to undo this change.");
            if (conf) {
                ctrl.user.attributes.require_otp = true;
                ctrl.show_otp = true;
                // this.updateUser();
            }
        } else {
            ctrl.user.attributes.require_otp = false;
            ctrl.show_otp = false;
            this.updateUser();
        }
    }

    /**
     * Should the user be prevented from disabling their OTP
     */
    checkDisableOTP() {
        if (this.user.is_super === true || this.user.attributes.role_names.includes("Administrator") ||
            this.user.attributes.role_names.includes("Super_User")) {
            return false; //enable
        }
        if (this.user.attributes.require_otp === true) {
            return true; //disable
        }
        return false;
    }

    enable_my_2fa() {

        const ctrl = this;
        if (this.user.attributes.require_otp === false) {
            this.http.post('/auth/enable_2fa', {}).toPromise().then(
                response => {
                    ctrl.snackbar.open(response['message'], 'Hide')
                }
            )
        }
    }

    updateUser() {
        const ctrl = this;
        if (ctrl.user.relationships.changed_by) {
            delete ctrl.user.relationships.changed_by;
        }
        if (ctrl.user.relationships.created_by) {
            delete ctrl.user.relationships.created_by;
        }
        ctrl.api.users.patch(ctrl.user).then(() => {
            console.log('Updated user');
            ctrl.appScope.current_user.require_otp = true;
        }, error => {
            console.log('Failed to update user');
            ctrl.user.attributes.require_otp = !ctrl.user.attributes.require_otp;
        })
    }

    resetOTPSeed() {
        const ctrl = this;
        ctrl.show_otp = false;
        ctrl.http.get('/auth/reset_otp_seed').toPromise().then(response => {
            this.show_otp = false;
            console.log('reset_otp success', response)
        }, response => {
            console.log('reset_otp failure', response)
        })
    }

    generateToken() {
        const ctrl = this;
        ctrl.http.get('/auth/token/' + ctrl.appScope.current_user.id + '/' + ctrl.days * 24 * 3600).toPromise().then((response: any) => {
            ctrl.appScope.current_user.auth_token_active = true;
            ctrl.auth_token = response.token
        })
    }

    deactivateToken() {
        const ctrl = this;
        ctrl.http.get('/auth/deactivate_token/' + ctrl.appScope.current_user.id).toPromise().then(response => {
            ctrl.appScope.current_user.auth_token_active = false;
            console.log('deactivate auth token success', response)
        }, response => {
            console.log('deactivate auth token failure', response)
        })
    }
}