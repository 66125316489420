
<div class="row constant-series-table">
    <div style="margin-right:10px;" *ngIf="!no_results" class="column">
        <table id="constant_series_table" *ngIf="series_data && series_data.length > 0"
               class="table table-sortable table-striped table-responsive table-bordered table-hover">
            <thead>
            <tr>
                <th>Time</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of series_data">
                <td style="padding:0px !important;white-space:nowrap;">
                    <input class="datetime" name='start' type='datetime-local' placeholder="Effective Date"
                           [value]="item.attributes.time_stamp | date:'yyyy-MM-ddThh:mm'"
                           (change)="changeDate($event, item)"/>
                </td>
                <td>
                    <input *ngIf="!item.saving && permissions.edit_process_data"  type="number" step="any" [(ngModel)]="item.attributes.value"
                           (change)="item.flag = 'update';" name="value"
                           style="border:0; width: 100%; margin-top:6px; background-color: inherit; text-align:right;" />
                    <div *ngIf="item.saving || !permissions.edit_process_data" style="text-align: right;padding-right:16px;">{{item.attributes.value}}</div>
                </td>
            </tr>
            </tbody>
        </table>
        <div class="row" style="display: flex;align-items: center;justify-content: space-evenly;">
            <button class="btn" (click)="page(-1)" [disabled]="!showPrevious()" matTooltip="Previous">
                <i class="fa fa-chevron-left" ></i>
            </button>
            <span style="color: #999"> {{page_number}} </span>
            <button class="btn" (click)="page(1)" matTooltip="Next">
                <i class="fa fa fa-chevron-right" ></i>
            </button>
        </div>
    </div>
    <div *ngIf="no_results">No more results</div>
    <div class="row inline-button">
        <button class="icon-new" (click)="addRow()" matTooltip="Add new value" [disabled]="!permissions.edit_process_data">

        </button>
        <button class="icon-save" (click)="save()" matTooltip="Save values" [disabled]="!permissions.edit_process_data">

        </button>
    </div>
</div>
