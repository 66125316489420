/**
 * Created by phillip on 2016/06/18.
 */
/**
 * Created by phillip on 2016/06/13.
 */

import * as Handsontable from "handsontable"
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {HeaderDataService} from "../services/header_data.service";
import {ApiService} from "../services/api.service";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {HotInstance} from "../services/hot-instance";

@Component({
    selector: 'collectorMapperSheetView',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class CollectorMapperSheetView implements OnInit {
    private hot_anchor: ElementRef;

    @ViewChild('hot_anchor', {static: false}) set setHotAnchor(content: ElementRef) {
        this.hot_anchor = content;
    }

    hot: HotInstance;
    title: string;
    search: string;
    schema: any;
    column_list: any;

    collectors: any[];
    mappers: any[];
    mapper_aggregation_methods = ['total', 'mean', 'latest_value'];
    series_light: any[];
    users: any[];

    constructor(private headerData: HeaderDataService,
                private api: ApiService,
                private handsontableGenerator: HandsontableGenerator) {
        this.hot = new HotInstance(["attributes.source_name"]);
    }

    ngOnInit(): void {
        const ctrl = this;
        ctrl.title = 'Collector Mappers';

        const promises = [];
        promises.push(ctrl.api.collector.search().toPromise().then(response => ctrl.collectors = response.data));
        promises.push(ctrl.api.mapper.search().toPromise().then(response => ctrl.mappers = response.data));
        promises.push(ctrl.api.series_light.search().toPromise().then(response => ctrl.series_light = response.data));
        promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));

        Promise.all(promises).then(ctrl.genTable.bind(ctrl));

        ctrl.buildHeader();
    }

    genTable() {
        const ctrl = this;

        let userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);
        let seriesLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.series_light);
        let collectorLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.collectors);

        ctrl.schema = {
            id: null,
            type: 'mapper',
            attributes: {
                source_name: null,
                pre_process: null,
                send_alerts: null,
                save_opsdata: null,
                create_events: null,
                latest_date: null,
                invert_signal: null,
                priority: null,
                interpolated: null,
                aggregation_method: null,
                high_frequency_collection: null
            }, relationships: {
                series: {data: {type: 'series', id: null}},
                collector: {data: {type: 'collector', id: null}}
            }
        };

        ctrl.column_list = [{
            data: 'attributes.source_name',
            title: 'Name'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(seriesLookups, 'series'),
            title: 'Series',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesLookups.source,
            allowInvalid: true
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(collectorLookups, 'collector'),
            title: 'Collector',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: collectorLookups.source,
            allowInvalid: true
        }, {
            data: 'attributes.pre_process',
            title: 'PreProcess'
        }, {
            data: 'attributes.latest_date',
            title: 'Latest Date',
            readOnly: true,
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.invert_signal',
            title: 'Invert Signal',
            type: 'checkbox'
        }, {
            data: 'attributes.save_opsdata',
            title: 'Save Operational Data',
            type: 'checkbox'
        }, {
            data: 'attributes.create_events',
            title: 'Create Events',
            type: 'checkbox'
        }, {
            data: 'attributes.send_alerts',
            title: 'Invert Signal',
            type: 'checkbox'
        }, {
            data: 'attributes.priority',
            title: 'Priority'
        }, {
            data: 'attributes.interpolated',
            title: 'Interpolate ',
            type: 'checkbox'
        }, {
            data: 'attributes.aggregation_method',
            title: 'Historian aggregation method ',
            type: 'dropdown',
            source: ctrl.mapper_aggregation_methods,
        }, {
            data: 'attributes.high_frequency_collection',
            title: 'High Frequency Collection',
            type: 'checkbox'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'created_by'),
            readOnly: true,
            title: 'Created By'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'changed_by'),
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];

        ctrl.hot = ctrl.handsontableGenerator.generateTable(ctrl.api.mapper, ctrl.schema, ctrl.column_list, ctrl.hot);
        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.mappers;
        ctrl.hot.settings.rowHeaders = false;
        ctrl.hot.instance = new Handsontable(ctrl.hot_anchor.nativeElement, ctrl.hot.settings);
    }

    save() {
        const ctrl = this;
        let results = ctrl.hot.save();
        ctrl.mappers = results.data;
    }

    download() {
        const ctrl = this;
        ctrl.hot.download();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Mappers';
        ctrl.headerData.buttons = [
            {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save', params: {}},
            {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download', params: {}}
        ];
    }
}