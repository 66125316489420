<div class="comment-button" [ngClass]="{'tile-dtp': button_class === true}">
  <button (click)="show_drawer=!show_drawer; emitToggleComments()"
          [ngClass]="{'show-drawer': show_drawer}"
          class="icon-comment {{button_class}}">
    <span>Comments</span>
  </button>
</div>
<div class="comment-drawer" *ngIf="show_drawer" [ngClass]="{'tile-dtp': button_class === true}">
  <div [ngClass]="{'no-dtp': tileData.show_comment_dtp===false}">
    <div class="row">
      <label>Comment</label>
      <textarea [(ngModel)]="newComment" appMention id="comment_area"
                placeholder="Type to add a comment. Type @ to mention someone." rows="1"
                tabindex="0"></textarea>
      <button class="btn-save-comment" (click)="saveComment()"></button>
    </div>
    <div class="row comment-info">
      <ng-container *ngIf="tileData.comment && tileData.comment.series">
        <label><b>Series:</b> {{tileData.comment.series.attributes | nameOrDescription}}</label>
        <label><b>Time: </b>{{tileData.comment.start | date:'short'}} - {{tileData.comment.end | date:'short'}}</label>
      </ng-container>
    </div>
    <ol style="list-style-type: upper-alpha;">
      <li (mouseenter)="commentHover(event, tileData.events.indexOf(event))"
          (mouseleave)="commentLeave(event, tileData.events.indexOf(event))"
          *ngFor="let event of tileData.events"
          [ngClass]="{'bold': hovering_events.includes(event.id)}"
      >{{event.attributes.comment}} ({{event.attributes.created_by_name}})
      </li>
    </ol>
  </div>
  <date-period *ngIf="tileData.show_comment_dtp !== false" [immutable]="true" [no_url]="true"
               class="date-period inline align-bottom"></date-period>
</div>