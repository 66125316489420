<div class="sidenav">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="menu">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <li *ngIf="node.type === 0 && node.menu_entry_count > 0" class="mat-tree-node folder-node">
        {{node.folder.attributes.name}}
      </li>
      <li *ngIf="node.type === 1 && node.visible === true"
          class="mat-tree-node menu-node"> <!--session state-->
        <a [href]="'view/page_view/' + node.session_state.id" [routerLink]="'view/page_view/' + node.session_state.id"
           (click)="appScope.sidenav_open=true">{{node.session_state.attributes.name}}</a>
        <i *ngIf="node.private === true" class="fas fa-lock" matTooltip="Private"></i>
      </li>
      <li *ngIf="node.type === 3" class="mat-tree-node menu-node"><!--static link-->
        <a [routerLink]="node.link"
           (click)="appScope.sidenav_open=true">{{node.title}}</a>
      </li>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [ngClass]="{'static-folder': node.type === 2}">
      <li>
        <div *ngIf="node.type === 2 || (node.type === 0 && node.menu_entry_count > 0)"
             class="mat-tree-node parent-folder-node" matTreeNodeToggle>
          <button class="folder-toggle" matTreeNodeToggle>
            <!--            <i class="fas" [ngClass]="treeControl.isExpanded(node) ? 'fa-caret-down' : 'fa-caret-right'"></i>-->
            <i [ngClass]="treeControl.isExpanded(node) ? 'chevron-up' : 'chevron-down'"></i>
            <span *ngIf="node.type === 0 && node.menu_entry_count > 0">{{node.folder.attributes.name}}</span>
            <span *ngIf="node.type === 2">{{node.title}}</span>
          </button>
        </div>
        <ul [class.menu-hidden]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>

  <div (click)="show_plant = !show_plant;" *ngIf="appScope.isNotMobile && !restricted"
       class="mat-tree-node parent-folder-node static">
    <button class="folder-toggle static-folder">
      <i [ngClass]="{'chevron-up': show_plant , 'chevron-down' : !show_plant }"
         class="chevron-down" role="button"></i>
      <span>Plant Menu</span>
    </button>
  </div>

  <mat-list *ngIf="show_plant && !restricted" class="sidetree">
    <ng-container *ngIf="!appScope.isNotMobile">
      <mat-list-item *ngFor="let item of appScope.mobile_sidenav" class="mobile_sidenav">
        <a *ngIf="item.angular_page && item.link" [routerLink]="item.link">{{item.name}}</a>
      </mat-list-item>
    </ng-container>

    <ng-container *ngIf="plantData.process_focus && plantData.process_focus.id && showCurrent()">
      <treenode (close_sidenav)="closeSidenav()" [item]="plantData.process_focus"></treenode>
    </ng-container>
    <!--TODO remove-->
    <!--            <ng-container *ngFor="let item of plants">-->
    <ng-container *ngIf="selected_plant">
      <treenode (close_sidenav)="closeSidenav()" [item]="selected_plant"></treenode>
    </ng-container>

    <ng-container *ngIf="reports">
      <ng-container *ngFor="let item of reports">
        <treenode (close_sidenav)="closeSidenav()" [item]="item"></treenode>
      </ng-container>
    </ng-container>
  </mat-list>
</div>
