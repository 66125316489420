import {UserFormComponent} from '../forms/user_form.component';
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core'
import {AppScope} from "../services/app_scope.service";
import {ApiService, Model} from "../services/api.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {HeaderDataService} from "../services/header_data.service";
import {MatSnackBar} from "@angular/material";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";

@Component({
    selector: 'user_list',
    templateUrl: 'user_list_component.html',
    encapsulation: ViewEncapsulation.None
})
export class UserListComponent implements OnInit {
    title_user = 'User list';
    can_edit: boolean;
    can_view: boolean;
    users: User[];
    name: string;
    mms_user: any;
    tabs: string[] = ['Users', 'MMS Users', 'Inactive Users'];

    filterValue: string = "";
    resource_list: Model[] = [this.api.users];
    dataSource: {}; //{'Users': MatTableDataSource<any>, 'MMSUsers': MatTableDataSource<any>};
    dataSourceMMS: MatTableDataSource<any>;
    private sort;

    @ViewChild(MatSort, {static: false}) set content(content: ElementRef) {
        this.sort = content;
        this.tabs.forEach((tab) => {
            if (this.sort && this.dataSource[tab]) {
                this.dataSource[tab].sort = this.sort;
            }
        })
    }

    columns: any[] =
        [{name: 'edit', title: 'edit'}, {name: 'name', title: 'Name'},
            {name: 'email', title: 'Email'}, {name: 'email_disabled', title: 'Email Disabled'},
            {name: 'sms', title: 'Sms'}, {name: 'sms_disabled', title: 'Sms Disabled'},
            {name: 'role_names', title: 'Roles'}, {name: 'restricted_access', title: 'Restricted'},
            {name: 'require_otp', title: '2Fa'}, {name: 'active', title: 'Active'}];
    displayedColumns: string[] = ['edit', 'Name', 'Email', 'Email Disabled', 'Sms', 'Sms Disabled', 'Roles', 'Restricted', '2Fa', 'Active'];

    constructor(private appScope: AppScope,
                private api: ApiService,
                public dialog: MatDialog,
                private headerData: HeaderDataService,
                private snackbar: MatSnackBar) {
    }

    ngOnInit(): void {
        const ctrl = this;
        // TODO remove this .promise when auth_complete is no longer a defer
        ctrl.headerData.show_dtp = true;
        ctrl.appScope.auth_complete.promise.then(() => {
            ctrl.api.users.search().toPromise().then(response => {
                if (!response) return;
                ctrl.users = response.data;
                ctrl.mms_user = ctrl.users.find(user => user.id === ctrl.appScope.current_user.id);
                this.dataSource = {};
                ctrl.dataSource['Users'] = new MatTableDataSource(
                    response.data.filter(row => !ctrl.isMMS(row) && row.attributes.active));
                ctrl.dataSource['MMS Users'] = new MatTableDataSource(response.data.filter(row => ctrl.isMMS(row)));
                ctrl.dataSource['Inactive Users'] = new MatTableDataSource(
                    response.data.filter(row => !ctrl.isMMS(row) && row.attributes.active !== true));

                ctrl.tabs.forEach((tab) => {
                    ctrl.dataSource[tab].filterPredicate = (data, filter) => {
                        if (data.attributes.name.toLowerCase().includes(filter)
                            || data.attributes.email.toLowerCase().includes(filter)
                            || data.attributes.role_names.join().toLowerCase().includes(filter)
                        ) {
                            return true;
                        }
                        return false;
                    };
                    this.dataSource[tab].sortingDataAccessor = (data, sortHeaderId) => {
                        return data.attributes[sortHeaderId];
                    };
                })
            });

            const cur_user = ctrl.appScope.current_user;
            ctrl.can_edit = (cur_user.feature_names.indexOf("edit users") > -1
                || cur_user.role_names.indexOf('Administrator') > -1
                || cur_user.roles.indexOf('Super_User') > -1);
            ctrl.can_view = ctrl.can_edit || cur_user.feature_names.indexOf("view users") > -1;
            this.buildHeader();
        })
    }

    isMMS(row) {
        return /@metalmanagementsolutions.com\s*$/.test(row.attributes.email)
    }

    applyFilter(filterValue: any) {
        this.filterValue = filterValue;
        filterValue = filterValue.trim().toLowerCase();
        this.tabs.forEach((tab) => this.dataSource[tab].filter = filterValue);
    }

    openDialog(user: User): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig<User>();
        dialogConfig.minWidth = '640px';
        dialogConfig.data = user;
        const dialogRef = this.dialog.open(UserFormComponent, dialogConfig);

        // dialogRef.afterClosed().subscribe(result => {
        // });
    }

    onSearchChange(event) {
        event.stopPropagation();
    };

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Manage Users';
    }

    userChanged(user: User) {
        const ctrl = this;
        if (user.relationships.changed_by) {
            delete user.relationships.changed_by;
        }
        if (user.relationships.created_by) {
            delete user.relationships.created_by;
        }
        ctrl.api.users.patch(user).then(() => {
            ctrl.snackbar.open('Updated user', null, {duration: 2000});
        }, error => {
            ctrl.snackbar.open('Failed to update user: ' + error.errors[0].detail, "Hide");
        })
    }
}

export interface User {
    id: string,
    attributes: {
        name: string,
        email: string,
        email_disabled: boolean,
        sms: string,
        sms_disabled: boolean,
        role_names: string,
        restricted_access: boolean,
        json: any,
        require_otp: boolean,
        active: boolean,
        is_super: boolean,
    }
    relationships: {
        dashboards: {
            data: any
        },
        roles: {
            attributes: any,
            data: Role[],

        }
        series: any,
        created_by: any,
        changed_by: any,
        accounts: {
            data: [{
                id: string,
                type: "account"
            }]
        },
        default_account: {
            data: {
                id: string,
                type: "account"
            }
        },
    }
}

export interface Role {
    attributes: {
        name?: string
    }
}

// @ViewChild(MatSort, {static: false}) sort: MatSort;

// ngOnInit(): void {
//     this.dataSource.sort = this.sort;

// //
// function userListViewCtrl(api, $q, appScope,$mdToast, $mdDialog, headerData){
//     var ctrl=this;
//     appScope.page_title = "Manage Users";
//     ctrl.searchTerm = '';
//   	ctrl.clearSearchTerm = function() {
//     	ctrl.searchTerm = '';
//   	};
//
//     ctrl.onSearchChange = function(event) {
//         event.stopPropagation();
//     };
//
//     ctrl.editUser = function(user){
//         $mdToast.show($mdToast.simple().textContent("Saving User..."));
//     		delete user.relationships.changed_by;
//     	    delete user.relationships.created_by;
//
//
//     	api.users.patch( user.id, {data: user});
//     };
//
//     ctrl.openUserDialog = function (user) {
//     	//console.log(user);
//             $mdDialog.show({
//                 controller: userFormCtrl,
//                 templateUrl: 'static/app/forms/user_form.html',
//                 locals: {
//                 	user: user
//                 },
//                 preserveScope: true,
//                 clickOutsideToClose: true,
//                 onComplete: afterShowAnimation
//             }).then(function (answer) {
//                 ctrl.editUser(answer);
//             }, function () {
//                 $mdToast.show($mdToast.simple().textContent("User not saved."));
//             });
//
//             function afterShowAnimation(scope, element, options) {
//                 scope.ready = true;
//             }
//         };
//
//
//     function buildHeader(){
//         headerData.setTitle(appScope.page_title);
//         headerData.dtp = false;
//         headerData.buttons = [];
//     }
//     buildHeader();
// }
//
//
//
// //
// //     controller:userListViewCtrl});
