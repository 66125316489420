/**
 * Created by phillip on 2016/06/18.
 */

import {Component, OnDestroy, OnInit} from "@angular/core";
import {ApiService} from "../services/api.service";
import {PlantDataService} from "../services/plant_data.service";
import {HeaderDataService} from "../services/header_data.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: 'processSheetFocusView',
    templateUrl: 'process_sheet_focus_view.html'
})
export class ProcessSheetFocusViewCtrl implements OnInit ,OnDestroy{
    process: any;
    flowSheet: any;
    children: any;
    sheet_ready: boolean;
    params_subscription: Subscription;

    constructor(private api: ApiService,
                private plantData: PlantDataService,
                private headerData: HeaderDataService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        let ctrl = this;

        ctrl.params_subscription = ctrl.route.params.subscribe(params => {
            this.sheet_ready = false;

            ctrl.process = ctrl.api.process.getById(params.processID).toPromise().then(response => {
                ctrl.process = response.data;
                ctrl.plantData.process_focus = ctrl.process;
                ctrl.flowSheet = ctrl.plantData.getFlowSheetData(params.processID);
                ctrl.flowSheet.allDataFetched.promise.then(ctrl.setupEditSheet.bind(ctrl));
            });
        });
    }

    ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
    }

    setupEditSheet() {
        const ctrl = this;
        ctrl.children = ctrl.flowSheet.processes;
        ctrl.sheet_ready = true;
    }
}
