import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DateTimePeriod, DateTimePeriodService} from "../../services/datetime_period.service";
import {TileDataService} from "../../services/tile_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {ApiService} from "../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SeriesStringParser} from "../../services/series_string_parser.service";
import {takeUntil} from "rxjs/operators";
import * as _ from "lodash";
import * as utils from "../../lib/utils";
import * as QuillNamespace from 'quill';
import {QuillEditorComponent} from "ngx-quill";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

let Quill: any = QuillNamespace;

export interface DialogData {
    form_result: string;
    config: any;
}

@Component({
    selector: 'paragraph-form',
    templateUrl: './paragraph-form.component.html',
    styleUrls: ['./paragraph-form.component.less']
})
export class ParagraphFormComponent implements OnInit {
    paragraph: any;
    @ViewChild('imageUpload', {static: true}) fileUpload: ElementRef<HTMLInputElement>;
    showing_hints: boolean = false;
    private readonly onDestroy = new Subject<void>();
    private quill: QuillEditorComponent;

    constructor(private http: HttpClient,
                public dateTimePeriodService: DateTimePeriodService,
                public tileData: TileDataService,
                private seriesData: SeriesDataService,
                private api: ApiService,
                private snackBar: MatSnackBar,
                private dialogRef: MatDialogRef<ParagraphFormComponent>,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
                private stringParser: SeriesStringParser,
                private renderer: Renderer2) {
        this.paragraph = utils.deepCopy(this.dialogData.config);
    }

    @ViewChild('quillEditorComponent', {static: true}) set setQuill(content: any) {
        if (!content) return;
        this.quill = content;
    }

    ngOnInit() {
        const ctrl = this;
    }

    adjustTooltip(event) {
        let tooltips = document.querySelectorAll('div.ql-tooltip:not(.ql-hidden)');

        if (tooltips && tooltips.length > 0) {
            let t = tooltips[0].getBoundingClientRect();
            let p = event.srcElement.parentElement.getBoundingClientRect();
            // @ts-ignore
            let left = t.x;
            // @ts-ignore
            if ((left + tooltips[0].offsetWidth) > (p.x + p.width) && !tooltips[0].classList.value.includes('ql-editing')) {
                let diff = (left + t.width) - (p.x + p.width);
                diff = p.width - t.width;
                this.renderer.setStyle(tooltips[0], 'left', `${diff}px`);
            } else if (left < p.x || left < 0) {
                let diff = (left - p.x);
                this.renderer.setStyle(tooltips[0], 'left', "-10px");
            }
        }
    }

    customImageHandler: any = () => {
        const quill = this.quill.quillEditor;
        quill.enable(false);
        const range = quill.getSelection();

        this.fileUpload.nativeElement.onchange = () => {
            const files = this.fileUpload.nativeElement.files;
            if (!files || files.length < 1) {
                return;
            }
            const file = files[0];

            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            this.snackBar.open('Uploading image');

            this.http.post('/api/BlobData', formData).subscribe((response: { file_name: string }) => {
                quill.enable(true);
                this.snackBar.open('Image uploaded successfully.', undefined, {duration: 1500});
                quill.insertEmbed(range.index, 'image', 'admin/myfiles/download' + response.file_name, Quill.sources.USER);
            }, error => {
                quill.enable(true);
                console.error('Error uploading collector data', error);
                this.snackBar.open('An error occurred while collecting the file.', "Hide");
            });
        };
        this.fileUpload.nativeElement.click();
    };

    options = {
        clipboard: {
            matchVisual: false
        },
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike"],
                ["blockquote", "code-block"],
                [{"header": 1}, {"header": 2}],
                [{"list": "ordered"}, {"list": "bullet"}],
                [{"script": "sub"}, {"script": "super"}],
                [{"indent": "-1"}, {"indent": "+1"}],
                [{"direction": "rtl"}],
                [{"size": ["small", false, "large", "huge"]}],
                [{"header": [1, 2, 3, 4, 5, 6, false]}],
                [{"color": []}, {"background": []}],
                [{"font": []}], [{"align": []}],
                ["link", "image"],
                ["clean"]
            ],
            handlers: {
                image: this.customImageHandler
            }
        }
    };

    save() {
        this.dialogRef.close(this.paragraph);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
