import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {Subject} from "rxjs";
import {ApiService} from '../../services/api.service';
import {takeUntil} from "rxjs/operators";
import {DateTimePeriod, DateTimePeriodService} from "../../services/datetime_period.service";
import {SeriesDataService} from "../../services/series_data.service";
import * as utils from "../../lib/utils";
import {NameOrDescriptionPipe} from "../../shared/pipes";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ChartDialog} from "../../charts/chart-dialog.component";
import {AppScope} from "../../services/app_scope.service";
import {TileDataService} from '../../services/tile_data.service';
import {CachingService} from '../../services/caching.service';

@Component({
    selector: 'context',
    templateUrl: './context.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ContextComponent implements OnInit {
    private readonly onDestroy = new Subject<void>();

    @Input() config: {
        selected_series: any,
        value_only: boolean,
        chart_type: string,
        primary_value: string,
        secondary_val_1: string,
        secondary_val_2: string,
        chart_config: any,
        show_unit: boolean,
        series_unit: string,
        labels?: { sub_title?: string }
    };
    @Input() show_header: boolean;
    @Input() title: string = '';
    private cached_context: any;
    private from_cache: boolean = false;

    dtp: DateTimePeriod;
    summary: any;

    @ViewChild('context_tile', {static: true}) context_tile: ElementRef;
    @ViewChild('context_content', {static: true}) context_content: ElementRef;
    @ViewChild('generic', {static: false}) generic_chart: ElementRef;
    @ViewChild('primary_value', {static: true}) primary_value: ElementRef;
    viewHeight: number;
    viewWidth: number;
    est_favourability_mapping: {};
    red: string = 'red';
    green: string = 'green';
    category_colour: string = 'default';

    constructor(private api: ApiService,
                private dialog: MatDialog,
                private dateTimePeriodService: DateTimePeriodService,
                private renderer: Renderer2,
                private seriesData: SeriesDataService,
                private AppScope: AppScope,
                private tileData: TileDataService,
                private cache: CachingService) {
    }

    ngOnInit() {
        const ctrl = this;
        if (!this.config.selected_series) {
            return
        }
        this.seriesData.$estimate_types.promise.then(response => {
            ctrl.est_favourability_mapping = ctrl.seriesData.est_favourability_dict;
        });
        this.dateTimePeriodService.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            ctrl.dtp = dtp;
            ctrl.getSeriesSummary();
        });

        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            ctrl.dtp = ctrl.dateTimePeriodService.dtp;
            if (ctrl.cache.vdt_context_cache[ctrl.config.selected_series.id + ctrl.dtp.start + ctrl.dtp.end]) {
                ctrl.getCachedContext(ctrl.config.selected_series.id + ctrl.dtp.start + ctrl.dtp.end);
            } else {
                ctrl.getSeriesSummary();
            }
        });

        if (ctrl.tileData.tile && ctrl.tileData.tile.tile_category) {
            ctrl.category_colour = ctrl.tileData.tile.tile_category.colour
        }
        if (!ctrl.title) {
            ctrl.title = new NameOrDescriptionPipe().transform(ctrl.config.selected_series.attributes);
        }
        let h = 0.5;
        if (this.tileData.tile && this.tileData.tile.show_header) {
            h = 1;
            ctrl.tileData.setDefaultTitle(ctrl.title);
        }
        this.viewHeight = this.context_tile.nativeElement.offsetHeight * h; //(height minus title)
        this.viewWidth = this.context_tile.nativeElement.offsetWidth;
        this.renderer.setStyle(this.context_content.nativeElement, "height", this.viewHeight * .6 + 'px');
        this.renderer.setStyle(this.context_content.nativeElement, "width", this.viewWidth + 'px');

        if (ctrl.config.chart_type === 'custom_chart') {
            ctrl.config.chart_config['set_size'] = {
                height: ctrl.viewHeight,
                width: ctrl.viewWidth
            }
        }

        if (ctrl.AppScope.config_name_map.hasOwnProperty('palette2')) {
            if (ctrl.AppScope.config_name_map.palette2.value.find(colour => colour.name == 'Green')) {
                ctrl.green = ctrl.AppScope.config_name_map.palette2.value.find(colour => colour.name == 'Green').colour
            } else {
                ctrl.green == 'green'
            }
            if (ctrl.AppScope.config_name_map.palette2.value.find(colour => colour.name == 'Red')) {
                ctrl.red = ctrl.AppScope.config_name_map.palette2.value.find(colour => colour.name == 'Red').colour
            } else {
                ctrl.red = 'red'
            }
        }

    }

    getCachedContext(id) {
        this.from_cache = true;
        let cached_context = this.cache.vdt_context_cache[id];
        this.config = this.cache.getCachedContextConfig(id);
        this.summary = this.cache.getCachedContextSummary(id);
        if (cached_context.font_size) {
            this.renderer.setStyle(this.primary_value.nativeElement, "font-size", cached_context.font_size + '%');
        }
    }

    cacheContext() {
        let cached_context_id = this.cache.cacheContext(this.config, this.summary);
        this.cache.vdt_context_cache[cached_context_id].font_size = this.primary_value.nativeElement.style.fontSize;
    }

    getSeriesSummary() {
        const ctrl = this;
        if (!ctrl.config.selected_series) {
            return;
        }
        //Set value defaults
        if (!ctrl.config.primary_value) {
            ctrl.config.primary_value = 'Value'
        }
        if (!ctrl.config.secondary_val_1) {
            ctrl.config.secondary_val_1 = 'Forecast'
        }
        if (!ctrl.config.secondary_val_2) {
            ctrl.config.secondary_val_2 = 'MTD'
        }
        let selected_columns = [ctrl.config.primary_value, ctrl.config.secondary_val_1, ctrl.config.secondary_val_2];

        if (!ctrl.config.chart_type || ctrl.config.chart_type === 'sparkline') {
            selected_columns.push('Sparkline')
        }
        selected_columns.push('Unit');
        this.seriesData.$estimate_types.promise.then(list => {
            let estTypeList = this.seriesData.getEstimateTypesList(selected_columns, list);
            let params = {
                series_list: [ctrl.config.selected_series.id],
                start: (new Date(ctrl.dtp.start)).toISOString(),
                end: (new Date(ctrl.dtp.end)).toISOString(),
                return_type: 'json',
                format: "records",
                sample_period: ctrl.dtp.sample_period.wire_sample,
                deepness: 2,
                estimate: estTypeList,
                columns: selected_columns
            };
            let summary = ctrl.api.get("/GetSeriesSummary" + '?' + utils.httpParamSerializer(params));
            summary.toPromise().then(result => {
                ctrl.summary = result[0];

                //**This logic is being overridden by use of sigNumber pipe so removing unit in length calc for now**
                //**and adding sigNumber calc **
                let unit_len = 0;
                if (ctrl.config.show_unit && ctrl.config.series_unit) {
                    unit_len = ctrl.config.series_unit.length;
                }
                const significantNumber = utils.significantNumber(ctrl.summary[ctrl.config.primary_value], 3);
                if (significantNumber) {
                    let length = significantNumber.toString().length + unit_len;
                    if (length > 3) {
                        let value = 100 - ((length - 2) * 12); //This is pretty rough, for value driver trees
                        if (value < 20) {
                            value = 20;
                        }
                        this.renderer.setStyle(ctrl.primary_value.nativeElement, "font-size", value + '%');
                    }
                }
                if (!ctrl.from_cache) {
                    ctrl.cacheContext();
                }
            });
        });
    };

    openChartDialog(series_name): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = series_name;
        dialogConfig.panelClass = 'chart-dialog';
        const dialogRef = this.dialog.open(ChartDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log('the dialog was closed')
            }
        });
    };

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}