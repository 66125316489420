/**
 * Created by phillip on 2016/06/18.
 */
/**
 * Created by phillip on 2016/06/13.
 */

import * as Handsontable from "handsontable"
import {AfterViewInit, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {AppScope} from "../services/app_scope.service";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {HeaderDataService} from "../services/header_data.service";
import {HotInstance} from "../services/hot-instance";

@Component({
    selector: 'calculationCheckTable',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class EngineeringUnitView implements OnInit,AfterViewInit {
    hot : HotInstance;
    title: string;
    window_height: number;
    engineering_unit_promise: Promise<any>;
    schema: { id: any; type: string; attributes: { name: any; pint_ref: any; base_metric: any; }; };
    column_list: ({ data: string; title: string; type?: undefined; strict?: undefined; allowInvalid?: undefined; trimDropdown?: undefined; source?: undefined; } | { data: string; title: string; type: string; strict: boolean; allowInvalid: boolean; trimDropdown: boolean; source: string[]; })[];
    data :any;
    search :string = '';

    constructor(private api : ApiService, private appScope:AppScope, private hotTableGen: HandsontableGenerator,
                private headerData: HeaderDataService) {
    }


    ngOnInit ( ){
        var ctrl = this;


        ctrl.hot = new HotInstance();
        ctrl.title='Engineering Units';
        ctrl.window_height = this.hotTableGen.window_height();

        ctrl.appScope.page_title = 'Units';

        ctrl.schema = {
            id: null,
            type: 'engineering_unit',
            attributes:{
                name:null,
                pint_ref:null,
                base_metric:null
            }
        };

        ctrl.column_list= [
            {
                data: 'attributes.name',
                title: 'Name'
            },
            {
                data: 'attributes.pint_ref',
                title: 'Pint Ref',
                type:'autocomplete',
                strict: false,
                allowInvalid: true,
                trimDropdown: false,
                source:['A', 'B', 'BTU', 'Ba', 'Bd', 'Bq', 'British_horsepower', 'Btu', 'C', 'C12_faraday', 'Ci', 'Da', 'EC_therm',
                    'E_h', 'F', 'Fr', 'Gy', 'H', 'H2O', 'Hg', 'Hz', 'J', 'K', 'KPH', 'L', 'Langley', 'MPH', 'N', 'N_A',
                    'Oe', 'P', 'PSH', 'Pa', 'P\xcc\xb8', 'Quantity', 'R', 'Rd', 'S', 'St', 'Sv', 'T', 'Tbl', 'Tblsp',
                    'Tbsp', 'UK_bbl', 'UK_bushel', 'UK_cup', 'UK_cwt', 'UK_fluid_ounce', 'UK_gallon', 'UK_gill',
                    'UK_horsepower', 'UK_hundredweight', 'UK_pint', 'UK_pk', 'UK_quart', 'UK_ton', 'US_cwt', 'US_dry_barrel',
                    'US_dry_gallon', 'US_dry_pint', 'US_dry_quart', 'US_fluid_dram', 'US_fluid_ounce', 'US_hundredweight',
                    'US_liquid_cup', 'US_liquid_gallon', 'US_liquid_gill', 'US_liquid_ounce', 'US_liquid_quart', 'US_pint',
                    'US_shot', 'US_therm', 'US_ton', 'V', 'VA', 'W', 'Wb', 'Wh', 'Z_0', 'acre', 'acre_feet', 'acre_foot',
                    'amp', 'ampere', 'ampere_turn', 'amu', 'angstrom', 'angular_degree', 'angular_minute', 'angular_second',
                    'ap_dr', 'ap_lb', 'ap_oz', 'apothecary_dram', 'apothecary_ounce', 'apothecary_pound', 'arc_minute',
                    'arc_second', 'arcdeg', 'arcdegree', 'arcmin', 'arcminute', 'arcsec', 'arcsecond', 'are',
                    'astronomical_unit', 'at', 'atm', 'atmosphere', 'atomic_mass_unit', 'au', 'avdp_dram', 'avdp_ounce',
                    'avdp_pound', 'avogadro_number', 'avoirdupois_dram', 'avoirdupois_ounce', 'avoirdupois_pound', 'b',
                    'bag', 'bar', 'barad', 'barie', 'barn', 'barrel', 'barrie', 'baryd', 'barye', 'baud', 'bbl', 'becquerel',
                    'beer_barrel', 'beer_bbl', 'bit', 'boiler_horsepower', 'boltzmann_constant', 'bps', 'british_thermal_unit',
                    'btu', 'bu', 'bushel', 'byte', 'c', 'cal', 'calorie', 'candela', 'candle', 'carat', 'cc', 'cd', 'celsius',
                    'centimeter', 'centimeter_Hg', 'ch_cwt', 'chain', 'characteristic_impedance_of_vacuum', 'chemical_faraday',
                    'circle', 'circular_mils', 'cmH2O', 'cmHg', 'cm_Hg', 'cmil', 'common_year', 'conventional_mercury',
                    'conventional_water', 'convert', 'coulomb', 'count', 'counts_per_second', 'cp', 'cps', 'cu_ft', 'cu_in',
                    'cu_yd', 'cubic_centimeter', 'cubic_feet', 'cubic_foot', 'cubic_inch', 'cubic_yard', 'cup', 'curie',
                    'cycle', 'dalton', 'darcy', 'day', 'decitex', 'define', 'deg', 'degC', 'degF', 'degK', 'degR', 'degree',
                    'delta_degC', 'delta_degF', 'denier', 'dgal', 'dpi', 'dqt', 'dr', 'drachm', 'dram', 'dry_barrel',
                    'dry_gallon', 'dry_pint', 'dry_quart', 'dtex', 'dwt', 'dyn', 'dyne', 'e', 'eV', 'electric_constant',
                    'electric_horsepower', 'electron_mass', 'electron_volt', 'elementary_charge', 'eon', 'epsilon_0', 'erg',
                    'esu', 'esu_per_second', 'fahrenheit', 'farad', 'faraday', 'fathom', 'feet', 'fine_structure_constant',
                    'fldr', 'floz', 'fluid_dram', 'fluid_ounce', 'fluidram', 'foot', 'footH2O', 'foot_H2O', 'force_gram',
                    'force_kilogram', 'force_ounce', 'force_pound', 'force_ton', 'fortnight', 'franklin', 'ft', 'ftH2O',
                    'furlong', 'g', 'g_0', 'g_n', 'gal', 'gallon', 'gamma', 'gauss', 'get_base_units', 'get_dimensionality',
                    'get_name', 'get_symbol', 'gf', 'gi', 'gilbert', 'gill', 'gr', 'grain', 'gram', 'gram_force', 'gravity',
                    'gray', 'gregorian_year', 'h', 'h2o', 'ha', 'hartree', 'hartree_energy', 'hbar', 'hectare', 'henry',
                    'hertz', 'hogshead', 'horsepower', 'hour', 'hp', 'hr', 'hydraulic_horsepower', 'impedance_of_free_space',
                    'imperial_barrel', 'imperial_bbl', 'imperial_bu', 'imperial_bushel', 'imperial_cp', 'imperial_cup',
                    'imperial_floz', 'imperial_fluid_drachm', 'imperial_fluid_dram', 'imperial_fluid_ounce', 'imperial_gal',
                    'imperial_gallon', 'imperial_gi', 'imperial_gill', 'imperial_peck', 'imperial_pint', 'imperial_pk',
                    'imperial_pt', 'imperial_qt', 'imperial_quart', 'in', 'inHg', 'in_Hg', 'inch', 'inch_H2O_39F', 'inch_H2O_60F',
                    'inch_Hg', 'inch_Hg_32F', 'inch_Hg_60F', 'inches', 'international_feet', 'international_foot',
                    'international_inch', 'international_inches', 'international_knot', 'international_mile',
                    'international_steam_table_calorie', 'international_yard', 'jig', 'joule', 'julian_year', 'k', 'kelvin',
                    'kgf', 'kilogram', 'kilogram_force', 'kilometer', 'kip', 'kip_per_square_inch', 'knot',
                    'knot_international', 'kph', 'ksi', 'kt', 'l', 'langley', 'lb', 'lbf', 'league', 'leap_year',
                    'lg_cwt', 'li', 'light_year', 'lightyear', 'link', 'liquid_cup', 'liquid_gallon', 'liquid_gill',
                    'liquid_pint', 'liquid_quart', 'liter', 'litre', 'lm', 'load_definitions', 'long_hunderweight',
                    'long_ton', 'lumen', 'lunar_month', 'lux', 'lx', 'ly', 'm', 'm_e', 'm_n', 'm_p', 'magnetic_constant',
                    'maxwell', 'mercury', 'mercury_60F', 'meter', 'metre', 'metric_horsepower', 'metric_ton', 'mho', 'mi',
                    'mil', 'mile', 'milenia', 'milenium', 'millenia', 'millenium', 'milligram', 'milliliter', 'millimeter',
                    'millimeter_Hg', 'millimeter_Hg_0C', 'min', 'minim', 'minute', 'mmHg', 'mm_Hg', 'mol',
                    'molar_gas_constant', 'mole', 'month', 'mph', 'mu_0', 'mx', 'nautical_mile', 'nautical_miles_per_hour',
                    'neutron_mass', 'newton', 'newtonian_constant_of_gravitation', 'nmi # exact', 'octet', 'oersted',
                    'ohm', 'oil_barrel', 'oil_bbl', 'ounce', 'ounce_force', 'oz', 'ozf', 'parse_expression', 'parse_units',
                    'parsec', 'pascal', 'pc', 'pdl', 'peak_sun_hour', 'peck', 'pennyweight', 'perch', 'physical_faraday',
                    'pi', 'pi_theorem', 'pica', 'pint', 'pk', 'planck_constant', 'point', 'poise', 'pole', 'pond', 'pound',
                    'pound_force', 'pound_force_per_square_inch', 'poundal', 'pp', 'printers_pica', 'printers_point',
                    'proton_mass', 'psi', 'pt', 'qt', 'quad', 'quadrillion_btu', 'quart', 'quarter', 'rad', 'radian', 'rads',
                    'rankine', 'rd', 'refrigeration_ton', 'rem', 'revolution', 'revolutions_per_minute', 'rhe', 'rod',
                    'roentgen', 'rpm', 'rps', 'rutherford', 'rydberg_constant', 's', 'scruple', 'sec', 'second', 'sft',
                    'shake', 'short_hunderdweight', 'short_ton', 'shot', 'sidereal_day', 'sidereal_hour', 'sidereal_minute',
                    'sidereal_month', 'sidereal_second', 'sidereal_year', 'siemens', 'sievert', 'slm', 'slpm', 'speed_of_light',
                    'sq_ft', 'sq_in', 'sq_mi', 'sq_perch', 'sq_pole', 'sq_rod', 'sq_yd', 'square_feet', 'square_foot',
                    'square_inch', 'square_inches', 'square_mile', 'square_rod', 'square_yard', 'sr', 'stF', 'stH', 'stS',
                    'stV', 'standard_atmosphere', 'standard_gravity', 'standard_liter_per_minute', 'statC', 'statF',
                    'statH', 'statS', 'statV', 'statampere', 'statcoulombs', 'statfarad', 'stathenry', 'statmho', 'statohm',
                    'statvolt', 'stefan_boltzmann_constant', 'steradian', 'stere', 'stokes', 'stone', 'survey_foot',
                    'survey_link', 'survey_mile', 'synodic_month', 't', 'tTNT', 'tablespoon', 'tblsp', 'tbs', 'tbsp',
                    'teaspoon', 'technical_atmosphere', 'tesla', 'tex', 'th', 'therm', 'thermochemical_calorie', 'thm',
                    'thou', 'tlb', 'ton', 'ton_TNT', 'ton_force', 'ton_of_refrigeration', 'tonne', 'torr', 'toz',
                    'tropical_month', 'troy_ounce', 'troy_pound', 'tsp', 'turn', 'u', 'unit_pole', 'us_statute_mile',
                    'vacuum_permeability', 'vacuum_permittivity', 'volt', 'volt_ampere', 'water', 'water_39F', 'water_4C',
                    'water_60F', 'watt', 'watt_hour', 'watthour', 'weber', 'week', 'wien_frequency_displacement_law_constant',
                    'work_month', 'work_year', 'wraps', 'yard', 'yd', 'year', '\xc3\x85', '\xc3\xa5ngstr\xc3\xb6m',
                    '\xc4\xa7', '\xce\x94celsius', '\xce\x94fahrenheit', '\xce\xa9', '\xcf\x80', '\xcf\x83',
                    '\xe2\x84\xab'].sort()
            },
            {
                data: 'attributes.base_metric',
                title: 'Base Metric'
            }
        ];


        ctrl.hot = ctrl.hotTableGen.generateTable(ctrl.api.engineering_unit,ctrl.schema,ctrl.column_list, ctrl.hot);
        ctrl.hot.ready=true;

        ctrl.engineering_unit_promise = ctrl.api.engineering_unit.search().toPromise();
        ctrl.headerData.buttons = [
            {name:'Save', func: ctrl.save, class: 'icon-save',params : {ctrl: ctrl}},
            {name:'Download', func: ctrl.download, class: 'icon-download',params: {ctrl: ctrl}}
        ];

    }

    ngAfterViewInit() {
        var ctrl = this;
        ctrl.engineering_unit_promise.then((response)=>{
            ctrl.hot.settings.data =response.data;
            ctrl.hot.instance = new Handsontable(document.getElementById(this.hot.handson_id), this.hot.settings)});
    }


    save (params){
        params.ctrl.hot.save();
    };
    download (params){
        params.ctrl.hot.download('engineering_units.csv');
    };




}
// function engineeringUnitSheetViewCtrl(api,hotTableGen, appScope, headerData, $timeout) {
//     // Assigned from construction <code>locals</code> options...
//
//     var ctrl = this;
//
//
//     ctrl.save = function(){
//         var results = ctrl.hot.save();
//         ctrl.data = results.data;
//     };
//     ctrl.download = function(){
//         ctrl.hot.download();
//     };
//     ctrl.buildHeader = function(){
//         headerData.buttons = [
//             {name:'Save', func: ctrl.save, class: 'icon-save'},
//             {name:'Download', func: ctrl.download, class: 'icon-download'}
//         ];
//     };
//     ctrl.buildHeader();
// }

