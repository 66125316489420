import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {AppScope} from "../../services/app_scope.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'weather-widget',
    templateUrl: './weather-widget.component.html',
})
export class WeatherWidgetComponent implements AfterViewInit, OnDestroy {
    url;

    constructor(private appScope: AppScope,
                private domSanitizer: DomSanitizer) {
    }

    ngOnDestroy(): void {
        this.url = null;
    }

    ngAfterViewInit(): void {
        const ctrl = this;
        ctrl.appScope.auth_complete.promise.then(() => {
            const site_coords = ctrl.appScope.config_name_map.SITE_COORDS.value.split(",");
            if (site_coords && site_coords.length >= 2 && site_coords[0] && site_coords[1]) {
                const url_untrusted = "https://forecast.io/embed/#lat=" + site_coords[0] + "&lon=" + site_coords[1] +
                    "&title=Weather&color=#f34c2f&textColor=333333&bgColor=transparent&transparency=true&skyColor=undefined&fontFamily=Default&customFont=&units=ca&htColor=#f34c2f&ltColor=C7C7C7&displaySum=yes&displayHeader=yes";
                this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(url_untrusted);
            }
        });
    }
}