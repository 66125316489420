import {Injectable, OnDestroy} from '@angular/core';
import {concatAll, map, tap} from "rxjs/operators";
import {concat, forkJoin, Observable, of, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {DateTimePeriod, DateTimePeriodService} from "./datetime_period.service";
import * as utils from "../lib/utils";
import {AppScope} from "./app_scope.service";
import {PlantDataService} from "./plant_data.service";

Date.prototype['addHours'] = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

@Injectable({
    providedIn: 'root',
})
export class InputDataService implements OnDestroy {
    times: any[];
    shifts: any[];
    raw_data_map: { [series_id: string]: string };
    series_data: any[];
    times_map: any[];
    series_list_map: {};
    sample_hours: number;
    shift_starts: any[];
    shift_spans: any[];
    shift_names: any[];
    shift_length: any;
    show_shifts: boolean;
    process: any;
    access: any;
    flowSheet: any;
    series_list: any;
    series_name_map: any;
    // for storing the changes to be committed
    inserts: any;
    deletes: any;
    updates: any;
    private readonly onDestroy = new Subject<void>();

    constructor(public api: ApiService,
                public plantData: PlantDataService,
                public datetimePeriodService: DateTimePeriodService,
                public appScope: AppScope) {
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    /**
     * Sets the initial state for this service.
     * @param process
     * @param access
     * @param flowSheet
     */
    // TODO this could be organised in a better way.
    //  For now wait in the component and pass through to set the state of the service.
    initialize(process, access, flowSheet,) {
        this.process = process;
        this.access = access;
        this.flowSheet = flowSheet;
        this.series_list = this.flowSheet.selected_series;

        // TODO should these be cleared when changing the dtp?
        this.updates = {};
        this.inserts = {};
        this.deletes = {};
    }

    getRawData(series, start, end): Observable<any> {
        console.log('Calling getRawData');

        const ctrl = this;
        return ctrl.api.raw_data.search(ctrl.api.prep_q([{
            name: 'series_id',
            op: 'eq',
            val: series.id
        }, {
            name: 'time_stamp',
            op: '<=',
            val: end
        }, {
            name: 'time_stamp',
            op: '>=',
            val: start
        }]));
    }

    refresh(dtp: DateTimePeriod) {
        this.shift_length = this.appScope.config_name_map.date_period.value.default_shift_length;

        const sources: Observable<any>[] = [this.refreshShifts(dtp), this.refreshRawData(dtp)];

        this.updates = {};
        this.inserts = {};
        this.deletes = {};

        return forkJoin([concat(sources).pipe(concatAll())]).pipe(map(arr => {
            return arr[0]
        }));

        // return concat(sources);

        // const response = new Subject();1

        // return sources.pipe(concatAll());
        // return sources.pipe(concat((val, index) => {
        //     console.log('fired concatMap with index', index);
        //     return val;
        // }));
        // this.refreshShifts(dtp).subscribe(() => {
        // })

    }

    /**
     * Get the list of existing raw_data entries in a given time range
     * @param series_id
     * @param start
     * @param end
     */
    getRawDataLocal(series_id: string, start: number, end: number): string[] {
        const id_length: number = 36; // length of a GUID string
        return Object.keys(this.raw_data_map).filter(key => {
            const s_id = key.substring(0, id_length);
            const time = Number(key.substring(id_length));
            return s_id == series_id && time > start && time <= end;
        });
    }

    populate_changes(data_row, timestamp, value, dtp: DateTimePeriod) {
        const ctrl = this;
        timestamp = timestamp.substr(0, timestamp.length - '.attributes.value'.length);
        // TODO change to use getTime as key
        const key = data_row.id + timestamp;
        try {

            timestamp = new Date(Number(timestamp)).toISOString();
        } catch (e) {
            console.log('invalid time, ', timestamp);
        }
        // TODO reimplement this using the service
        if (isNaN(parseFloat(value))) {
            // TODO In month view delete does not seem to delete all hours
            const end = new Date(timestamp).getTime();
            const start = new Date(timestamp)['addHours'](-dtp.sample_period.hours).getTime();
            const raw_datas = this.getRawDataLocal(data_row.id, start, end);
            raw_datas.forEach(key => {
                if (this.raw_data_map.hasOwnProperty(key)) {
                    ctrl.deletes[key] = this.raw_data_map[key];
                    delete ctrl.inserts[key];
                    delete ctrl.updates[key];
                    // });
                } else {
                    // deleting an element which never existed
                    delete ctrl.inserts[key];
                    delete ctrl.updates[key];
                    delete ctrl.deletes[key];
                }
            });
        } else {
            if (this.raw_data_map.hasOwnProperty(key)) {
                ctrl.updates[key] = {
                    id: this.raw_data_map[key],
                    type: 'raw_data',
                    attributes: {
                        value: value,
                        time_stamp: timestamp,
                        series: data_row.id
                    }
                };
                delete ctrl.inserts[key];
                delete ctrl.deletes[key];
            } else {
                // adding a new element
                ctrl.inserts[key] = {
                    type: 'raw_data',
                    attributes: {
                        value: value,
                        time_stamp: timestamp,
                        series: data_row.id
                    }
                };
                delete ctrl.updates[key];
                delete ctrl.deletes[key];
            }
        }
    }

    saveAll() {
        // TODO add back CUD operations
        const ctrl = this;
        const all_saves = [];

        console.log('reached saveAll');

        Object.keys(ctrl.inserts).map(key => {
            const obj = ctrl.inserts[key];
            all_saves.push(ctrl.api.raw_data.save(obj).then(item => {
                const timestamp_key = new Date(obj.attributes.time_stamp).getTime();

                ctrl.raw_data_map[obj.attributes.series + timestamp_key] = item.data.id;
                delete ctrl.inserts[key];
            }, item => {
                delete ctrl.inserts[key];
            }));
        });

        Object.keys(ctrl.deletes).map(key => {
            const obj = ctrl.deletes[key];
            all_saves.push(ctrl.api.raw_data.delete(obj).then(item => {
                delete ctrl.raw_data_map[key];
                delete ctrl.deletes[key];
            }, () => {
                delete ctrl.deletes[key];
            }));
        });

        Object.keys(ctrl.updates).map(key => {
            const obj = ctrl.updates[key];
            all_saves.push(ctrl.api.raw_data.patch(obj).then(() => {
                delete ctrl.updates[key];
            }, () => {
                delete ctrl.updates[key];
            }));
        });

        return Promise.all(all_saves).then(() => all_saves.length);

        // Promise.all(all_saves).then(() => {
        //     if (all_saves.length > 0) {
        //         ctrl.hot_ready = true;
        //         ctrl.snackbar.open('All saved', null, {duration: 2000});
        //         // $mdToast.show($mdToast.simple().textContent('All saved').parent(document.querySelectorAll('#toaster')));
        //     }
        // }, reason => ctrl.snackbar.open(reason, 'Hide'))
    }

    getInputData(dtp: DateTimePeriod): Observable<any> {
        const ctrl = this;
        const $raw_datas: Observable<any>[] = [];

        console.log('getInputData');

        ctrl.series_list.forEach(series => {
            // Always showing data, regardless if the series is editable
            // if (series.attributes.can_edit == true) {
            const $raw_data: Observable<any> = ctrl.getRawData(series, dtp.start, dtp.end).pipe(tap((response => {
                console.log('calling raw_data for ' + series.id);
                let data_fetched = response.data;
                data_fetched.map(col => {
                    const timestamp_key = new Date(col.attributes.time_stamp).getTime()
                    ctrl.raw_data_map[series.id + timestamp_key] = col.id;
                    series[timestamp_key] = col;
                    // series[iso_date] = ctrl.detectLimit(item, col.attributes.value, col);
                });
                ctrl.fillRow(series, dtp);
            })));
            $raw_datas.push($raw_data);
            // } else {
            //     console.log("couldn't series series " + series.attributes.name);
            //     ctrl.fillRow(series, dtp);
            //     //summary_series.push(item.id);
            // }
            ctrl.series_data.push(series);
        });

        // console.log('RawData: ', utils.deepCopy(ctrl.series_data));

        // TODO for now just emit subject when finished
        const finishedSubject = new Subject();

        let $GetData: Observable<any> = ctrl.api.get("/GetData" + '?' + utils.httpParamSerializer({
            process: ctrl.process.id,
            deepness: 2,
            start: dtp.start.toISOString(),
            end: dtp.end.toISOString(),
            sample_period: dtp.sample_period.wire_sample
        })).pipe(tap(data => {
            console.log('GetData: ', utils.deepCopy(data));
            const calc_data: {
                [series_name: string]: {
                    [timestamp_iso: string]: number
                }[]
            } = data.data;
            const missing_values: { [series_name: string]: { [isostring: string]: boolean } } = data.missing_values;
            this.series_name_map = {};
            ctrl.series_list.map(series => {
                // TODO why only for sample_hours > 1?
                // if (!series.attributes.can_edit ) {
                if (series.id == '35d974f3-42e8-4ccd-a9f7-7ebfc3d0d50a') {
                    console.log('this.series_name_map[series.attributes.name] 1=', this.series_name_map[series.attributes.name])
                }
                this.series_name_map[series.attributes.name] = series;
                // }
            });

            Object.entries(calc_data).forEach(entry => {
                const series_name = entry[0];
                const timestamp_values = entry[1];
                Object.entries(timestamp_values).forEach(entry => {
                    const iso_string = entry[0];
                    const value = entry[1];
                    const key = new Date(iso_string).getTime();
                    const series = this.series_name_map[series_name];
                    if (series === undefined) {
                        console.log('Ignoring series from GetData', series_name);
                    } else {
                        series[key] = {
                            attributes: {
                                value: value,
                                series: series.id,
                                time_stamp: iso_string
                            }, type: 'raw_data',
                            is_missing: missing_values[series_name][iso_string]
                        };
                    }
                });
            });

            ctrl.series_data.forEach(series => {
                let date_counter = new Date(dtp.start)['addHours'](ctrl.sample_hours);
                let series_name = series.attributes.name;
                // if (series.id == '35d974f3-42e8-4ccd-a9f7-7ebfc3d0d50a' ) {
                //     console.log('this.series_name_map[series.attributes.name] 2=',this.series_name_map[row.attributes.name])
                // }
                while (date_counter <= new Date(dtp.end)) {
                    if (this.series_name_map.hasOwnProperty(series_name)) {
                        ctrl.setCalcData(series, date_counter, calc_data);
                    }
                    if (dtp.sample_period.name == 'month') {
                        date_counter.setMonth(date_counter.getMonth() + 1)
                    } else {
                        date_counter['addHours'](dtp.sample_period.hours)
                    }
                }
            });
            finishedSubject.next();
            finishedSubject.complete();
            finishedSubject.unsubscribe();
        }));

        const $first_order_raw_data: Observable<any> = forkJoin($raw_datas).pipe();

        const sources = [$first_order_raw_data, $GetData];

        return forkJoin([concat(sources).pipe(concatAll())]).pipe(map(arr => {
            return arr[0]
        }));
    }

    getCalculations() {

    }

    /**
     *
     * @param series series object with additional timestamp keys (corresponding to columns in table)
     * @param dtp
     */
    fillRow(series, dtp: DateTimePeriod) {
        const ctrl = this;

        console.log('dtp.sample_period.hours', dtp.sample_period.hours, 'sample_hours', this.sample_hours);
        const date_counter = (new Date(dtp.start))['addHours'](this.sample_hours);
        while (date_counter <= new Date(dtp.end)) {
            if (!series.hasOwnProperty(date_counter.getTime()) || !series[date_counter.getTime()]) {
                series[date_counter.getTime()] = {
                    attributes: {
                        value: null,
                        series: series.id,
                        time_stamp: date_counter.toISOString()
                    }, type: 'raw_data'
                }

            } else if (!series[date_counter.getTime()].hasOwnProperty('type')) {
                series[date_counter.getTime()] = {
                    attributes: {
                        value: null,
                        series: series.id,
                        time_stamp: date_counter.toISOString()
                    }, type: 'raw_data'
                }
            }
            if (dtp.sample_period.name === 'month') {
                date_counter.setMonth(date_counter.getMonth() + 1);
            } else {
                date_counter['addHours'](dtp.sample_period.hours);
            }
        }
        //addTotal(row);
        return series
    }

    updateGetData(series, start, end, dtp) {
        const ctrl = this;
        ctrl.api.get("/GetData" + '?' + utils.httpParamSerializer({
            series_list: series.id,
            deepness: 2,
            start: start.toISOString(),
            end: end.toISOString(),
            sample_period: dtp.sample_period.wire_sample
        })).toPromise().then((data) => {
            const calc_data = data.data;
            let date_counter = utils.deepCopy(start);

            while (date_counter < end) {
                date_counter['addHours'](dtp.sample_period.hours);
                ctrl.setCalcData(series, date_counter, calc_data);
            }
        });
    }

    setCalcData(series, date_counter, calc_data) {
        const ctrl = this;
        let timestamp = date_counter.toISOString();
        let timestamp_key = date_counter.getTime();
        if (calc_data[series.attributes.name][timestamp_key] !== null
            && calc_data[series.attributes.name][timestamp_key] !== undefined &&
            series[timestamp_key] !== calc_data[series.attributes.name][timestamp_key]) {
            // series[timestamp] = calc_data[series.attributes.name][timestamp];
            series[timestamp_key] = {
                attributes: {
                    value: calc_data[series.attributes.name][timestamp_key],
                    series: series.id,
                    time_stamp: timestamp
                }, type: 'raw_data', source: 'get_data'
            };
            // series[timestamp] = ctrl.detectLimit(series, series[timestamp].attributes.value, series[timestamp]);
        }
    }

    detectLimit(series: any, value: string, raw_data) {
        //check for only apply correction factor permission and then popup different box that
        //checks events for this series and user and applies it to whole dtp period
        //reset to enable update
        raw_data.warning = false;
        raw_data.error = false;

        // @ts-ignore
        if (value === "" || value === null || isNaN(value)) {
            // TODO why is warning and error both false for this case
            raw_data.warning = false;
            raw_data.error = false;
        }

        if (value > series.attributes.hi || value < series.attributes.low) {
            raw_data.warning = true;
            raw_data.error = false;
        }

        if (value > series.attributes.hihi || value < series.attributes.lowlow) {
            raw_data.error = true;
            raw_data.warning = false;
        }

        return raw_data
    }

    private refreshShifts(dtp: DateTimePeriod): Observable<any> {
        console.log('Calling refreshShifts');
        const ctrl = this;
        ctrl.times = [];
        ctrl.shifts = [];
        return ctrl.api.shift.search(ctrl.api.prep_q([{
            and: [{
                name: 'end',
                op: 'gt',
                val: dtp.start
            }, {
                name: 'start',
                op: 'lt',
                val: dtp.end
            }]
        }], {
            order_by: [{
                "field": 'start',
                "direction": 'desc'
            }]
        })).pipe(tap(response => {
            ctrl.shifts = response.data;
            if (!ctrl.shifts) {
            }
            if (ctrl.shifts.length > 0) { //1,2,4,(6),(8),(12)
                ctrl.shifts = ctrl.shifts.sort((a, b) => {
                    return new Date(a.attributes.start).valueOf() - new Date(b.attributes.start).valueOf();
                });
            }
            // ctrl.loadTable();
        }));
    }

    private refreshRawData(dtp: DateTimePeriod) {
        const ctrl = this;
        const permissions = ctrl.access.permissions;

        console.log('calling refreshRawData');

        this.series_data = [];
        this.series_list_map = {};
        this.raw_data_map = {};
        this.times = [];
        this.times_map = [];
        // ctrl.permissions = ctrl.access.permissions;
        this.sample_hours = dtp.sample_period.hours;

        this.shift_starts = [];
        this.shift_spans = [];
        this.shift_names = [];

        let date_counter = utils.deepCopy(dtp.start);
        if (dtp.sample_period.name == 'month') {
            date_counter.setMonth(date_counter.getMonth() + 1);
        } else {
            date_counter['addHours'](ctrl.sample_hours);
        }
        let span_counter = 0;
        let period_start;
        let period_end;
        let shift_idx = 0;

        let shift_headers = () => {
            if (ctrl.shifts[shift_idx] !== undefined) {
                let shift_end = new Date(ctrl.shifts[shift_idx].attributes.end);
                let shift_start = new Date(ctrl.shifts[shift_idx].attributes.start)['addHours'](dtp.sample_period.hours);

                if (shift_start < utils.deepCopy(new Date(dtp.start))['addHours'](dtp.sample_period.hours)) {
                    shift_start = (new Date(dtp.start))['addHours'](dtp.sample_period.hours);
                }
                if (shift_end > new Date(dtp.end)) {
                    shift_end = new Date(dtp.end);
                }
                if (shift_end <= date_counter) {
                    let shift_span = (shift_end.getTime() - shift_start.getTime()) / 3600000 / dtp.sample_period.hours;
                    ctrl.shift_spans[new Date(shift_start).toISOString()] = shift_span + 1;
                    ctrl.shift_starts.push(new Date(shift_start).toISOString());
                    ctrl.shift_names[new Date(shift_start).toISOString()] = ctrl.shifts[shift_idx].attributes.description;
                    shift_idx++;
                }
            }
        };

        while (date_counter <= dtp.end) {
            if (dtp.sample_period.name == 'month') {
                date_counter.setMonth(date_counter.getMonth() - 1)
            } else {
                date_counter['addHours'](ctrl.sample_hours * -1)
            }
            period_start = date_counter.toISOString();
            if (dtp.sample_period.name == 'month') {
                date_counter.setMonth(date_counter.getMonth() + 1)
            } else {
                date_counter['addHours'](ctrl.sample_hours)
            }
            period_end = date_counter.toISOString();
            ctrl.times.push({
                period_start: period_start,
                period_end: period_end
            });

            if (dtp.sample_period.name == 'month') {
                date_counter.setMonth(date_counter.getMonth() + 1)
            } else {
                date_counter['addHours'](ctrl.sample_hours)
            }
            if (ctrl.shifts.length > 0 && Number.isInteger(ctrl.shift_length / dtp.sample_period.hours)) { //1,2,4,(6),(8),(12)
                shift_headers();
                ctrl.show_shifts = true;
            } else {
                ctrl.show_shifts = false;
            }
            span_counter++;
        }

        ctrl.times_map = ctrl.times.map(time => time.period_end);

        if (this.appScope.current_user.restricted_access !== true || permissions.view_process_data) {
            return this.getInputData(dtp);
        } else {
            return of()
        }
    }

}
