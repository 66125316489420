import * as Handsontable from "handsontable"
import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {HeaderDataService} from "../services/header_data.service";
import {AppScope} from "../services/app_scope.service";
import {HotInstance} from "../services/hot-instance";

@Component({
    selector: 'resourceSheetView',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class ResourceSheetViewCtrl implements OnInit {
    hot: HotInstance = new HotInstance();
    resources: any[];
    title: string;
    component_types: any[];
    revisions: any[];
    schema: any;
    column_list: any;
    search: string = '';

    users: any[];

    constructor(
        private api: ApiService,
        private headerData: HeaderDataService,
        private appScope: AppScope,
        private handsontableGenerator: HandsontableGenerator) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.title = 'Resources';
        ctrl.headerData.buttons = [];
        ctrl.appScope.page_title = "Resources";

        //Filter for resource types
        const promises = [];

        promises.push(ctrl.api.component_type.search(ctrl.api.prep_q([{
            val: 'resource',
            name: 'base_type',
            op: 'eq'
        }], {})).toPromise().then(response => ctrl.component_types = response.data));

        promises.push(ctrl.api.revision.search().toPromise().then(response => ctrl.revisions = response.data));
        promises.push(ctrl.api.resource.search().toPromise().then(response => ctrl.resources = response.data));
        promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));

        Promise.all(promises).then(ctrl.createTable.bind(ctrl));

        ctrl.buildHeader();
    }

    createTable() {
        const ctrl = this;
        const componentTypeLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.component_types);
        const revisionLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.revisions, function (item) {
            return item.attributes.name
        });
        const userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, function (item) {
            return item.attributes.name
        });
        ctrl.schema = {
            id: null,
            type: 'resource',
            attributes: {
                name: null,
                description: null,
                created_on: null,
                changed_on: null
            },
            relationships: {
                revision: {data: {id: null, type: 'revision'}},
                component_type: {data: {id: null, type: 'component_type'}},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };

        ctrl.column_list = [{
            data: 'attributes.name',
            title: 'Name'
        }, {
            data: 'attributes.description',
            title: 'Description'

        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(revisionLookups, 'revision'),
            title: 'Revision',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: revisionLookups.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(componentTypeLookups, 'component_type'),
            title: 'Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: componentTypeLookups.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'created_by'),
            readOnly: true,
            title: 'Created By'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'changed_by'),
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];

        ctrl.hot = ctrl.handsontableGenerator.generateTable(ctrl.api.resource, ctrl.schema, ctrl.column_list, ctrl.hot);
        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.resources;
        setTimeout(() => ctrl.hot.instance = new Handsontable(document.getElementById(ctrl.hot.handson_id), ctrl.hot.settings), 200)
    }

    save() {
        const ctrl = this;
        const results = ctrl.hot.save();
        ctrl.resources = results.data;
    }

    download() {
        const ctrl = this;
        ctrl.hot.download();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = ctrl.appScope.page_title;
        ctrl.headerData.buttons = [
            {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save'},
            {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download'}
        ];
    }
}
