import * as Handsontable from "handsontable"
import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {HeaderDataService} from "../services/header_data.service";
import {AppScope} from "../services/app_scope.service";
import {HotInstance} from "../services/hot-instance";

@Component({
    selector: 'stockpileSheetView',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class StockpileSheetViewCtrl implements OnInit {
    hot: HotInstance;
    title: string;
    stockpiles: any[];
    event_types: any[];
    schema: any;
    column_list: any;
    search: string;
    users: any[];

    constructor(private api: ApiService,
                private handsontableGenerator: HandsontableGenerator,
                private headerData: HeaderDataService,
                private appScope: AppScope) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.appScope.page_title = "Stockpiles";
        ctrl.hot = new HotInstance();
        ctrl.title = 'Stockpile';

        const promises = [];
        promises.push(ctrl.api.stockpile.search().toPromise().then(response => {
            ctrl.stockpiles = response.data;
            ctrl.validateStockpiles();
        }));
        promises.push(ctrl.api.event_type.search().toPromise().then(response => ctrl.event_types = response.data));
        promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));

        Promise.all(promises).then(ctrl.createTable.bind(ctrl));

        ctrl.buildHeader();
    }


    createTable() {
        const ctrl = this;

        let eventLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.event_types);
        let userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);

        ctrl.schema = {
            id: null,
            type: 'stockpile',
            attributes: {
                name: null,
                description: null,
                opening_date: null,
                closing_date: null,
                input_event_type_id: null,
                output_event_type_id: null,
                created_on: null,
                changed_on: null
            },
            relationships: {
                input_event_type: {data: {id: null, type: 'event_type'}},
                output_event_type: {data: {id: null, type: 'event_type'}},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };

        ctrl.column_list = [{
            data: 'attributes.name',
            type: 'text',
            title: 'Name',
            allowEmpty: false
        }, {
            data: 'attributes.description',
            type: 'text',
            title: 'Description'
        }, {
            data: 'attributes.opening_date',
            title: 'Opening Date',
            type: 'date',
            width: 160,
            //editor: DateTimeEditor,
            dateFormat: 'YYYY-MM-DDTHH:mm:ssZZ',
            correctFormat: true,
            defaultDate: new Date(),
            allowEmpty: false,
            renderer: 'date_formatter'
        }, {
            data: 'attributes.closing_date',
            title: 'Closing Date',
            type: 'date',
            width: 160,
            //editor: DateTimeEditor,
            dateFormat: 'YYYY-MM-DDTHH:mm:ssZZ',
            correctFormat: true,
            defaultDate: new Date(),
            allowEmpty: false,
            renderer: 'date_formatter'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(eventLookups, 'input_event_type'),
            title: 'Input Event Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: eventLookups.source,
            allowInvalid: true,
            allowEmpty: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(eventLookups, 'output_event_type'),
            title: 'Output Event Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: eventLookups.source,
            allowInvalid: true,
            allowEmpty: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'created_by'),
            readOnly: true,
            title: 'Created By'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'changed_by'),
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];
        ctrl.hot = ctrl.handsontableGenerator.generateTable(ctrl.api.stockpile, ctrl.schema, ctrl.column_list);
        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.stockpiles;
        setTimeout(() => ctrl.hot.instance = new Handsontable(document.getElementById(ctrl.hot.handson_id), ctrl.hot.settings), 200)
    }

    save() {
        const ctrl = this;
        let results = ctrl.hot.save();
        ctrl.stockpiles = results.data;
    }

    download() {
        const ctrl = this;
        ctrl.hot.download();
    }

    validateStockpiles(){
        const ctrl = this;

        ctrl.stockpiles.forEach(stockpile => {
            if (stockpile.attributes.stockpile_properties['outputs']){
                console.log("output not empty");
            }
            if (stockpile.attributes.stockpile_properties['inputs']){
                console.log("input not empty");
            }
        })
    }
    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = ctrl.appScope.page_title;
        ctrl.headerData.buttons = [
            {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save', params: {}},
            {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download', params: {}}
        ];
    }
}
