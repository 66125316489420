import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {EventDataService} from "../services/event_data.service";
import {TileDataService} from "../services/tile_data.service";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {AppScope} from '../services/app_scope.service';

@Component({
    selector: 'comment-drawer',
    templateUrl: './comment-drawer.component.html',
    styleUrls: ['./comment-drawer.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [DateTimePeriodService],
})
export class CommentDrawerComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    show_drawer: boolean = false;
    newComment: string = '';
    hovering_events: any[] = [];
    @Input() button_class: boolean = false;

    constructor(public eventData: EventDataService, public tileData: TileDataService,
                private dateTimePeriodService: DateTimePeriodService, private appScope: AppScope) {
        this.eventData.eventAdded.pipe(takeUntil(this.onDestroy)).subscribe(value => this.newComment = '');
        this.eventData.toggleComments.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            if (value === true) {
                this.show_drawer = true
            } else {
                this.show_drawer = !this.show_drawer
            }
        });
        this.dateTimePeriodService.dtpChanged.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            tileData.comment.start = dtp.start;
            tileData.comment.end = dtp.end;
        });
        this.tileData.commentIconHover.pipe(takeUntil(this.onDestroy))
            .subscribe(value => this.commentIconHover(value));
        this.tileData.commentIconLeave.pipe(takeUntil(this.onDestroy)
        ).subscribe(value => this.commentIconLeave());
        this.appScope.mentionSelected.pipe(takeUntil(this.onDestroy)).subscribe(value => {
            this.replaceComment(value);
        })
    }

    replaceComment(value) {
        let pre = this.newComment.substring(0, value.index);
        let sub = this.newComment.substr(value.index, this.newComment.length);
        this.newComment = pre + sub.replace('@' + value.search_string, '@' + value.user.attributes.name)
    }

    emitToggleComments() { //when clicking comment button on this component
        this.eventData.setShowComments(this.show_drawer);
    }

    saveComment() {
        this.tileData.addCommentClicked.emit(this.newComment);
    }

    commentHover(event, index) {
        this.tileData.commentHover.emit({"event": event, "index": index});
    }

    commentLeave(event, index) {
        this.tileData.commentLeave.emit({"event": event, "index": index});
    }

    commentIconHover(value) {
        value.map((event) => {
            this.hovering_events.push(event.id)
        })
    }

    commentIconLeave() {
        this.hovering_events = [];
    }

    ngOnInit() {
        if (this.tileData.comment && this.tileData.comment.start) {
            this.dateTimePeriodService.dtp.start = this.tileData.comment.start;
            this.dateTimePeriodService.dtp.end = this.tileData.comment.end;
        }
        this.dateTimePeriodService.show_timespan = false;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
