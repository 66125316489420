<div>
  <quill-editor *ngIf="!editing && paragraph.display_text" [ngModel]="paragraph.display_text" [readOnly]="true" class="readonly"
                [modules]="{clipboard:{matchVisual:false}}" contenteditable="false" placeholder="Enter custom text">
  </quill-editor>
  <quill-editor *ngIf="!editing && !paragraph.display_text" [ngModel]="place_holder" [readOnly]="true" class="readonly"
                contenteditable="false" placeholder="Enter custom text">
  </quill-editor>

  <div *ngIf="editing && !show_header" style="height: 36px;">
    <!--This is a spacer for the draghandler in edit mode-->
  </div>
</div>
