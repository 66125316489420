// NOTE starting the file with the comment below breaks uglifyjs 2.4.10
/**
 * Created by phillip on 2016/06/11.
 */
import * as d3 from 'd3';
import * as $ from 'jquery';
import {HttpParams} from "@angular/common/http";
import * as _ from "lodash";
import {Model} from "../services/api.service";
import * as Handsontable from "handsontable"
import * as moment from 'moment';
// import * as convert from "convert-units";

Date.prototype['addHours'] = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

export function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function deepCopy(obj: any) {
    return _.cloneDeep(obj); // return unlinked clone.
}

/**
 * Simple Promise that does not force you to resolve inside the function itself.
 */
export class FlatPromise {
    /**
     * Only the promise should be sent to calling code.
     *
     * E.g. a service should return the promise and not the whole FlatPromise.
     */
    promise: Promise<any>;
    resolve: (response?: any) => void;
    reject: (response?: any) => void;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve
        })
    }
}

export function httpParamSerializer(vars: object): HttpParams {
    let params = new HttpParams();
    Object.keys(vars).forEach(key => {
        if (Array.isArray(vars[key])) {

            vars[key].forEach(val => {
                params = params.append(key, String(val))
            })
        } else {

            params = params.set(key, String(vars[key]))
        }
    });

    return params
}

export function setToHour(date: Date, hour: number) {
    date.setHours(hour);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

export function label(input) {
    let label = input.charAt(0).toUpperCase() + input.slice(1);
    label = label.replace(/_/g, " "); //replace all occurences
    return label;
}

export function significantNumber(value, precision?): number | null {
    if (isNaN(parseFloat(value))) {
        return null;
    }
    if (value === 0) {
        return value.toPrecision(precision).includes('e') ? parseFloat(value.toPrecision(precision + 2)) : value.toPrecision(precision);
    }
    if (value && !isNaN(parseFloat(value))) {
        if (precision === null || precision === undefined) {
            precision = 3;
        }
        value = parseFloat(value);
        let significantValue = value.toPrecision(precision).includes('e') ? parseFloat(value.toPrecision(precision + 2)) :
            value.toPrecision(precision);
        //Add thousands comma
        const number_order = String(value).split('.')[0].length;
        if (number_order >= 5) {
            significantValue = parseFloat(value.toPrecision(number_order));
        }
        const decimalDivide = significantValue.toString().split('.');
        decimalDivide[0] = decimalDivide[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return decimalDivide.join(".");
    }
    return value;
}

export function thousandsSeparate(value) {
    const decimalDivide = value.toString().split('.');
    decimalDivide[0] = decimalDivide[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimalDivide.join(".");
}

export function getColorMap(thresholds, colours) {
    const d3_list = colours.map(col => d3.rgb(col));

    return d3.scaleLinear().domain(thresholds)
        .interpolate(d3.interpolate)
        .range(d3_list);
}

export function dayDifference(a, b) {
    return Math.ceil(Math.abs(a.getTime() - b.getTime()) / (1000 * 3600 * 24));
}

//Potentially can use this in the future, unfortunately it's very buggy, units such as tons return as kiloliters.
export function abbreviateNumber(value, unit?) {
    const lazy_abbr = () => {
        let newValue = value;
        const suffixes = ["", " k", " M", " B", " T"];
        let suffixNum = 0;
        while (newValue >= 1000) {
            newValue /= 1000;
            suffixNum++;
        }

        newValue = significantNumber(newValue, 4);
        if ((suffixNum) > suffixes.length) {
            return value
        }
        newValue += suffixes[suffixNum];
        return newValue;
    };
    return lazy_abbr()
    // if (unit) {
    //     if (value == 0) {
    //         return 0.0 + " " + unit
    //     }
    //     let units_list_to_exclude = convert().list().filter(unit_definition => {
    //         if (unit_definition.system === 'imperial' && unit_definition.abbr !== unit) {
    //             if (unit_definition.abbr) {
    //                 return !!unit_definition.abbr
    //             }
    //         }
    //         return false;
    //     }).map(unit_definition => unit_definition.abbr);
    //
    //     if (convert().list().find(unit_definition => {
    //         return unit_definition.abbr == unit
    //     })) {
    //         if (unit == 'm3') {
    //             if (value > 10000000000) {
    //                 let newVal = convert(value).from('m3').to('km3');
    //                 return significantNumber(newVal.val, 4) + ' ' + newVal.unit;
    //             } else {
    //                 return significantNumber(value, 1) +' ' + unit;
    //             }
    //
    //         } else {
    //             let newVal = convert(value).from('m3').toBest({exclude: units_list_to_exclude});
    //             return significantNumber(newVal.val, 4) + ' ' + newVal.unit;
    //         }
    //     } else {
    //         return lazy_abbr()
    //     }
    // } else {
    //     return lazy_abbr()
    // }

}

export function validURL(str) {
    const pattern = new RegExp(
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/);

    return pattern.test(str);
}

export function filterByID(obj) {
    return 'id' in obj && obj.id !== null && obj.id !== '' && obj.id !== undefined;
}

export function getByID(list, id) {
    for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
            return list[i]
        }
    }
}

export function getByName(list, name) {
    for (let i = 0; i < list.length; i++) {
        if (list[i].attributes.name === name) {
            return list[i]
        }
    }
}

export function sortObjectsByRelationshipName(objList, objDict, rel, order) {
    if (order !== false) {
        objList.sort((a, b) => (objDict[a.relationships[rel].data.id].attributes.name < objDict[b.relationships[rel].data.id].attributes.name) ? -1 :
            (objDict[a.relationships[rel].data.id].attributes.name > objDict[b.relationships[rel].data.id].attributes.name) ? 1 : 0)
    } else {
        objList.sort((a, b) => (objDict[a.relationships[rel].data.id].attributes.name > objDict[b.relationships[rel].data.id].attributes.name) ? -1 :
            (objDict[a.relationships[rel].data.id].attributes.name < objDict[b.relationships[rel].data.id].attributes.name) ? 1 : 0)
    }
}

export function sortObjectsByProperty(objList, prop, order?) {
    if (order !== false) {
        objList.sort((a, b) => (a.attributes[prop] < b.attributes[prop]) ? -1 : (a.attributes[prop] > b.attributes[prop]) ? 1 : 0)
    } else {
        objList.sort((a, b) => (a.attributes[prop] > b.attributes[prop]) ? -1 : (a.attributes[prop] < b.attributes[prop]) ? 1 : 0)
    }
}

// Removes all properties of an object that are not in a give template object
export function match_schema(element, template) {
    if (template === undefined) { //if there is an error with the schema then throw the actual error, not template undefined
        return;
    }
    for (let item in element) {
        if (element.hasOwnProperty(item)) {
            if (template[item] !== undefined) {
                if (Object.prototype.toString.call(element[item]) === '[object Array]') {
                    for (let i = 0; i < element[item].length; i++) {

                        match_schema(element[item][i], template[item][i])
                    }
                    element[item] = element[item].filter(filterByID)

                } else {
                    if (typeof (element[item]) === 'object' && element[item] !== null) {
                        match_schema(element[item], template[item]);
                    }

                    if (typeof (template[item]) === 'object' && template[item] !== null && element[item] === null) {
                        element[item] = $.extend({}, template[item]);
                    }

                    if (element[item] == null && template[item] !== null) {
                        element[item] = template[item]
                    }
                }
            } else {
                delete element[item];
            }
        }
    }

    for (var item in template) {
        if (template.hasOwnProperty(item) && template[item] !== null && !element.hasOwnProperty(item)) {
            element[item] = template[item]
        }
    }
}

export function recursively_null(element) {
    let counter = 0;
    let all_null = true;
    const check_null = sub_element => {
        for (let i in sub_element) {
            if (sub_element.hasOwnProperty(i)) {
                const test_item = sub_element[i];
                if (typeof (test_item) !== null) {
                    if (typeof (test_item) === 'object') {
                        if (counter < 50) {
                            check_null(test_item);
                            counter += 1;
                        }
                    } else if (test_item !== '') {
                        all_null = false;
                    }
                }
            }
        }
    };
    check_null(element);
    return all_null
}

export function cum_sum(list) {
    const sum = [];
    let val = 0;
    for (let i = 1; i < list.length; i++) {
        sum.push(val);
        val += list[i];
    }
    sum.push(val);
    return sum
}

export function genCallback(objs, create_name) {

    return (search, options) => {
        const type_map = [];

        objs.forEach(item => {
            type_map.push({value: item.id, name: create_name(item), description: ''})
        });
        return type_map.filter(item => (true));
    }
}

export function gen_xTick_values(xValues, ticks) {
    let max = xValues.reduce((max, num) => {
        return max > num ? max : num
    });

    let min = xValues.reduce((min, num) => {
        return min < num ? min : num
    });

    let range = max - min;
    let step = range / ticks;

    let x_axis_ticksActual = ((min, max) => {
        const arr = [];

        for (let i = 0; i <= ticks; i++) {
            arr.push((i * step + min).toFixed(3))
        }
        return arr;
    })(min, max);
    return x_axis_ticksActual;
}

export function gen_graph_id(len) {
    let text = "";

    const charset = "abcdefghijklmnopqrstuvwxyz";

    for (let i = 0; i < len; i++)
        text += charset.charAt(Math.floor(Math.random() * charset.length));

    return text;
}

export function gen_col_headers(columns, name_mapper) {
    return columns.map(element => {
        const name = element.data;
        if (name in name_mapper) {
            return name_mapper[name];
        } else {
            return name
        }
    })
}

export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this, args = arguments;
        const later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

/**
 * Deletes rows in Handson sheets
 *
 * @param pk
 * @param resource
 * @param snackBar
 * @param hotInstance
 * @param extra
 */
export function gen_row_deletion(pk, resource: any, snackBar, hotInstance, extra?) {
    // Returns a method for deleting a handsontable row from server
    return (key, options) => {
        if (key === 'delete_row') {
            const hot = hotInstance.instance;

            const remove_hot_row = curr_id => {
                hot.alter('remove_row', curr_id);
            };
            options = options[0];
            let i = options.start.row;
            for (i; i <= options.end.row; i++) {
                setTimeout(() => {
                    //timeout is used to make sure the menu collapsed before alert is shown

                    const curr_id = i - 1;

                    const id_data = {};
                    id_data[pk] = curr_id;

                    if (curr_id === undefined || curr_id === null) {
                        remove_hot_row(curr_id);
                    } else {

                        let inner_resource: any | Model = {};

                        if (resource.hasOwnProperty('query') || resource.hasOwnProperty('baseUrl')) {
                            inner_resource = resource;
                        } else {
                            const type_resource = hot.getDataAtRowProp(curr_id, 'type');

                            inner_resource = resource[type_resource];
                        }
                        const actual_id = hot.getDataAtRowProp(curr_id, 'id');

                        console.log(`deleteing row with cur_id ${curr_id} & actualId ${actual_id}`);

                        let delete_item = inner_resource.delete(actual_id);
                        delete_item.then(ret => {
                                // TODO check whether this is the logical or visual id (affected by sort)
                                remove_hot_row(curr_id);
                                snackBar.open('Deleted ' + actual_id + ' row from server', undefined, {duration: 3000});
                            }, ret => {
                                snackBar.open('Failed to delete row from server, check dependent objects', "Hide");

                            }
                        );
                    }
                }, 100);
            }

        }

        if (extra != null) {
            extra(key, options)
        }
    };
}

export function dRenderer(instance, td, row, col, prop, value, cellProperties) {
    let escaped = Handsontable.helper.stringify(value);
    escaped = ((escaped != null) && (escaped != "")) ? moment(escaped).format('DD-MM-YYYY HH:mm') : "";
    arguments[5] = escaped;
    // @ts-ignore
    Handsontable.renderers.DateRenderer.apply(this, arguments);
}

export function match_stubs(object, stubs) {
    stubs.forEach(stub => {
        if (object.relationships[stub.rel] && object.relationships[stub.rel].data && object.relationships[stub.rel].data.id) {
            object.relationships[stub.rel].data = getByID(stub.list, object.relationships[stub.rel].data.id);
        }
    });
    return object;
}

export function fill_relations(relation_stubs, relation_list, relation) {
    relation_stubs.forEach(
        val_series => {
            relation_list.forEach(series => {
                if (val_series.relationships[relation].data !== null && val_series.relationships[relation].data.id === series.id) {
                    val_series.relationships[relation].data = series;
                }
            })
        }
    )
}

export function fill_object_relations(relation_stubs, relation_list, relation) {
    for (let s = 0; s < relation_stubs.length; ++s) {
        const val_series = relation_stubs[s];
        if (val_series.relationships[relation].data !== null) {
            for (let i = 0; i < val_series.relationships[relation].data.length; ++i) {
                const item = val_series.relationships[relation].data[i];
                relation_list.forEach(series => {
                    if (item.id === series.id) {
                        relation_stubs[s].relationships[relation].data[i] = series;
                    }
                })
            }
        }
    }
}

export function zip(x, y) {

    return x.map((item, i) => [item, y[i]])
}

export function accumulateData(data) {
    const data_cum = [];
    data.reduce((a, b, i) => data_cum[i] = a + b, 0);
    return data_cum
}

export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

//* Use this function to sort an array of objects by another array of names
export function mapOrder(array, order, key, excludes?: string[]) {
    let exclude = function (str) {
        if (excludes.length > 0) {
            excludes.forEach(excl => {
                str = str.replace(excl, '');
            });
        }
        return str;
    };
    array.sort((a, b) => {
        let A = a[key], B = b[key];

        if (order.indexOf(exclude(A)) > order.indexOf(exclude(B))) {
            return 1;
        } else {
            return -1;
        }

    });

    return array;
}

export function getRoundedDate(seconds, d = new Date()) {

    let ms = 1000 * 60 * seconds; // convert minutes to ms
    let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

    return roundedDate
}

export function bestDTP(seconds, d = new Date()) {

    let ms = 1000 * 60 * seconds; // convert minutes to ms
    let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

    return roundedDate
}

export function scaleLimits(val, dtp, series) {
    if (series.attributes.aggregation === 'total') {

        return dtp.sample_period.hours * val

    }

    return val;
}

export function removeItem(array, item) {
    const index = array.indexOf(item);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array
}

export function nestedProp(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    const a = s.split('.');
    const n = a.length;
    for (let i = 0; i < n; ++i) {
        const k = a[i];
        if (o !== null) {
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
    }
    return o;
}

export function std_dev(array) {
    var avg = _.sum(array) / array.length;
    return Math.sqrt(_.sum(_.map(array, (i) => Math.pow((i - avg), 2))) / array.length);
}

export function pearsonCorrelationCoefficient(d1, d2) {
    let {min, pow, sqrt} = Math;
    let add = (a, b) => a + b;
    let n = min(d1.length, d2.length);
    if (n === 0) {
        return 0
    }
    [d1, d2] = [d1.slice(0, n), d2.slice(0, n)];
    let [sum1, sum2] = [d1, d2].map(l => l.reduce(add));
    let [pow1, pow2] = [d1, d2].map(l => l.reduce((a, b) => a + pow(b, 2), 0));
    let mulSum = d1.map((n, i) => n * d2[i]).reduce(add);
    let dense = sqrt((pow1 - pow(sum1, 2) / n) * (pow2 - pow(sum2, 2) / n));
    if (dense === 0) {
        return 0
    }
    return (mulSum - (sum1 * sum2 / n)) / dense
}


