<div class="chart-card">

  <!--<i class="fa fa-binoculars hideonprint" *ngIf="opsdata"-->
  <!--(click)="getDataOptimal()">-->
  <!--<mat-tooltip-component>Increase Quality</mat-tooltip-component>-->
  <!--</i>-->

  <div class='md-card-content'>
    <div #chart_anchor class="{{id_tag}} chart-tile"
         [ngClass]="{'hide-tick-labels': tile_config.hide_tick_labels}">

    </div>

    <ng-container *ngIf="chart_type==='comparison' && graph_data_stats.stats_table">
      <ng-container
          *ngIf="tile_config.statistics.averages || tile_config.statistics.pooled || tile_config.statistics.std_dev || tile_config.statistics.correlation_coeff">

        <div class="row statistics">
          <ng-container
              *ngIf="tile_config.statistics.averages || tile_config.statistics.pooled || tile_config.statistics.std_dev">

            <table mat-table class="statistics-table" [dataSource]="graph_data_stats.stats_table">

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef [attr.title]="'Statistics'"></th>
                <td mat-cell *matCellDef="let series"> {{series.name}} </td>
              </ng-container>

              <ng-container matColumnDef="average">
                <th mat-header-cell [attr.title]="'Average'" *matHeaderCellDef>&sigma;</th>
                <td mat-cell matTooltip="Average" *matCellDef="let series"> {{series.average.toFixed(3)}} </td>
              </ng-container>

              <ng-container matColumnDef="std_dev">
                <th mat-header-cell [attr.title]="'Standard deviation'" *matHeaderCellDef>x&#772;</th>
                <td *matCellDef="let series" mat-cell
                    matTooltip="Standard deviation"> {{series.std_dev.toFixed(3)}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="statsColumns"></tr>
              <tr mat-row *matRowDef="let myRowData; columns: statsColumns"></tr>

            </table>

          </ng-container>

          <div>
            <ng-container *ngIf="tile_config.statistics.correlation_coeff&&graph_data_stats.r2">
              <div class="row list">
                <label>Correlation coefficient: </label>
                {{graph_data_stats.r2.toFixed(3)}}
              </div>
            </ng-container>

            <ng-container *ngIf="tile_config.regression_line.toLowerCase() !== 'none'">
              <div class="row list">
                <label>{{tile_config.regression_line| titlecase}} regression:</label>
                {{regression_result.string}}
              </div>
              <div class="row list">
                <label>r<sup>2</sup> :</label>
                {{regression_result.r2}}
              </div>
            </ng-container>
          </div>

        </div>
      </ng-container>

    </ng-container>

  </div>
</div>

