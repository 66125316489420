import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {AppScope} from "../services/app_scope.service";
import {PlantDataService} from "../services/plant_data.service";
import {ApiService} from "../services/api.service";

export interface TreeNodeEntry {
    link: string;
    name: string;
    action: () => void
}

@Component({
    selector: 'treenode',
    templateUrl: 'tree_node.component.html',
    styleUrls: ['./side-nav.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class TreeNodeComponent implements OnInit {
    @Input() item?: any;
    // isOpen = true;
    // events: string[] = [];
    // opened: boolean;
    // reports: any[];
    plants: any[];
    public sidenav_open: boolean;

    @Output() close_sidenav = new EventEmitter();

    menu_entries: TreeNodeEntry[] = [];

    constructor(public plantData: PlantDataService,
                public appScope: AppScope,
                private api: ApiService) {
    }

    ngOnInit() {
        if (this.appScope.isNotMobile) {
            if (this.item) {
                this.menu_entries = [
                    {
                        link: '/view/series_table/' + this.item.id,
                        action: this.closeSidenav.bind(this),
                        name: "Series Table"
                    },
                    {
                        link: '/view/log_sheet/' + this.item.id,
                        action: this.closeSidenav.bind(this),
                        name: "Log Sheet  "
                    },
                    {
                        link: '/view/flowchart/' + this.item.id,
                        action: this.closeSidenav.bind(this),
                        name: "Flowchart  "
                    },
                    {
                        link: '/view/input_data_sheet/' + this.item.id,
                        action: this.closeSidenav.bind(this),
                        name: "Review Data  "
                    },
                    {
                        link: '/view/edit_series_components/' + this.item.id,
                        action: this.closeSidenav.bind(this),
                        name: "Report Configuration  "
                    },
                    {link: '/view/edit_series/' + this.item.id, action: this.closeSidenav.bind(this), name: "Series  "},
                    {
                        link: '/view/edit_sub_process/' + this.item.id,
                        action: this.closeSidenav.bind(this),
                        name: "Sub Processes  "
                    },
                    {
                        link: '/view/edit_stream/' + this.item.id,
                        action: this.closeSidenav.bind(this),
                        name: "Streams  "
                    },
                    {
                        link: '/view/estimate_data_sheet/' + this.item.id,
                        action: this.closeSidenav.bind(this),
                        name: "Estimate Input Sheet"
                    }
                ]
            } else {
                this.menu_entries = []
            }
        } else {
            this.menu_entries = [
                {link: '/view/log_sheet/' + this.item.id, action: this.closeSidenav.bind(this), name: "Log Sheet  "}
            ]
        }
    }

    closeSidenav() {
        this.appScope.sidenav_open = false;
        this.close_sidenav.emit();
    }

    getChildren(process) {
        if (!process.expanded) {
            process.relationships.children.data = this.api.process.get_related_many(process.id, 'children');

            process.relationships.children.data.$promise.then(function () {
                process.relationships.children.data.map(function (item) {
                    item.is_report = process.is_report
                });
                process.expanded = true;
            })
        } else {
            process.expanded = false;
        }
    }
}