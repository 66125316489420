<div class="form-dialog">
  <h1>Estimate*
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true" style="min-width:800px;">
      <mat-tab label="Basic">
        <form class="form-section column">

          <div class="row">
            <div class="select-search">
              <select-search style="flex: 3; width: max-content; column; margin: 0;padding: 0 "
                             (selectChange)="estimateTypeSelected()"
                             [disabled]="estimate && estimate.hasOwnProperty('id')"
                             [(value)]="selectedEstimateType"
                             [data]="estimateTypes"
                             [label]="'Estimate Type'">
              </select-search>

            </div>


            <div class="select-search">
              <select-search style="flex: 3"
                             *ngIf="seriesList.length>0"
                             (selectChange)="seriesSelected()"
                             [(value)]="selectedSeries"
                             [fill_stubs]="true"
                             [disabled]="estimate.hasOwnProperty('id')"
                             [data]="seriesList"
                             [label]="'Series'">
              </select-search>
            </div>
          </div>

          <div class="row">
            <mat-form-field>
              <mat-label>Name*</mat-label>

              <input matInput
                     name="name"
                     [(ngModel)]="estimate.attributes.name"
                     (change)="nameChanged()">
            </mat-form-field>

            <mat-form-field>
              <mat-label>Description</mat-label>

              <input matInput
                     name="description"
                     [(ngModel)]="estimate.attributes.description"
                     (change)="descriptionChanged()"/>

            </mat-form-field>

          </div>

          <div class="row">
            <mat-form-field>
              <mat-label>Fill Method</mat-label>

              <mat-select
                  name="fillMethod"
                  *ngIf="fillMethods.length > 0"
                  [(ngModel)]="estimate.attributes.fill_method"
                  (selectionChange)="fillMethodChange()">
                <mat-option *ngFor="let fillMethod of fillMethods" [value]="fillMethod"
                            [title]="seriesData.fill_methods[fillMethod]"
                >{{fillMethod}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Sample Period</mat-label>

              <mat-select name="samplePeriod"
                          *ngIf="samplePeriods.length > 0"
                          [(ngModel)]="estimate.attributes.sample_period"
                          (selectionChange)="samplePeriodChanged()">
                <mat-option *ngFor="let samplePeriod of samplePeriods"
                            [value]="samplePeriod">{{samplePeriod | titlecase | removeUnderscore}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Aggregation Type</mat-label>
              <mat-select name="aggregationType"
                          *ngIf="aggregationTypes.length > 0"
                          [(ngModel)]="estimate.attributes.aggregation"
                          (selectionChange)="aggregationTypeChanged()">
                <mat-option *ngFor="let aggregationType of aggregationTypes"
                            [title]="seriesData.aggregation_definitions[aggregationType]"
                            [value]="aggregationType">{{aggregationType | titlecase| removeUnderscore}}</mat-option>
              </mat-select>

            </mat-form-field>

          </div>
          <div class="row">

            <mat-form-field style="max-width:150px ">
              <mat-label>Variance favourability</mat-label>

              <mat-select [(value)]="estimate.attributes.positive_variance"
                          title="Defines the favourability of a variance between foreacasted and actual values">
                <mat-option [value]="true"
                            title="A positive difference between actual and forecasted values is favourable">Positive
                  variance
                </mat-option>
                <mat-option [value]="false"
                            title="A negative difference between actual and forecasted values is favourable">Negative
                  variance
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>


        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button class="btn-save" (click)="save()">Save</button>
    <button class="btn-close" (click)="close()">Close</button>
  </div>
</div>
