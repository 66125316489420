import * as utils from '../lib/utils';
import {FlatPromise} from '../lib/utils';
import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {AppScope} from "./app_scope.service";
import {HttpClient} from "@angular/common/http";
import {DateTimePeriodService} from "./datetime_period.service";
import {MatSnackBar} from "@angular/material";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {SeriesFormComponent} from "../forms/series_form.component";
import {EstimateFormComponent} from "../forms/estimate-form.component";

export interface Series {
    attributes: {
        default_value?: any,
        description: string,
        event_type_name?: string,
        kpi_level?: string,
        name: string,
        base_type?: string,
        variables?: string[], // name of series used in a calculation
        fill_method?: any,
        aggregation?: any,
        sample_period?: any,
        sample_offset?: any,
        rolling_average_hours?: number,
        hihi?: any,
        hi?: any,
        lowlow?: any,
        mean?: any,
        std?: any,
        low?: any,
        allow_edit?: any,
        delete_hihilowlow?: any,
        budget?: any,
        default_chart?: any,
        accumulation?: any,
        name_formula?: any,
        collector_names?: any,
        alternate_names?: any,
        specialised_function?: any,
        created_on?: any,
        changed_on?: any,
        extra_arguments_string?: string,
        linked_components?: any,
        json?: any,
        assumptions?: string
    },
    id: string,
    type: string,
    relationships: {
        calculations?: any,
        changed_by?: any,
        created_by?: any,
        engineering_unit?: any,
        estimate_type?: any,
        estimates?: any,
        event_type?: any,
        mappers?: any,
        parent?: any,
        series_components?: any,
        series_type?: any,
        series_users?: any,
        weighted_average_series?: any
    }
}

@Injectable()
export class SeriesDataService {
    // TODO uplift the places where these 5 fields are called to wait for the promise to complete
    engineering_units: any[] = [];
    series_type_list: any[] = [];
    series_list: any[] = [];
    event_type_list: any[] = [];
    estimate_types_list: any[] = [];

    columnsAll: any[];
    readonly base_types: string[] = ['series', 'calculation'];
    readonly chart_types: string[] = ['SPC', 'Budget Bar', 'Line', 'Bar', ''];
    readonly KPI_levels: string[] = ['Level 1', 'Level 2', 'Level 3', 'Level 4', '', null];
    readonly aggregation_types: string[] = ['max', 'min', 'mean', 'mean_nan', 'count', 'total', 'total_with_hourly', 'weighted_average',
        'latest_value', 'calculation'];
    readonly aggregation_definitions = {
        'max': 'Returns the largest series value of the chosen sample period',
        'min': 'Returns the smallest series value of the chosen period',
        'mean': 'Returns the average (arithmetic mean) of the series values of the chosen period',
        'mean_nan': 'Returns the average (arithmetic mean) of the series values of the chosen period and ignores missing values',
        'count': 'Returns the number of series values of the chosen period',
        'total': 'Returns the sum of the series values of the chosen period',
        'total_with_hourly': 'Total with hourly returns the total of the specified sample period divided by the number of hours between the start and the end',
        'weighted_average': 'Returns an average resulting from the multiplication of the specified series and the weighted average series for the chosen period',
        'latest_value': 'Returns the last value of the series for the chosen time period',
        'calculation': 'Returns a re-calculated series value for the chosen time period by inferring the aggregation type from calculation variables in real time.'
    };
    /**
     * Total with hourly returns the total of the specified sample period divided by the number of hours between the start and the end, thus it will display as hourly - but only for visualisation purposes e.g series table
     *
     */
    readonly est_name_abbr_dict: {} = {
        'Forecast': 'FCST'
    };

    readonly sample_period_names: string[] = ['day', 'shift', 'two_hour', 'four_hour', 'hour', 'week', 'month']; //fall back
    readonly fill_methods: object = {
        'Forward Fill': 'Fills missing values by propagating the last valid observation forward in time',
        'Backfill': 'Fills missing values by propagating the last valid observation backward in time',
        'Constant': 'Fills missing values by propagating the last known entry forward and backward,' +
            ' thus generating a constant value. Constant values can change over time by adjusting this constant at ' +
            'the specified time stamp',
        'Average': 'Fills missing values by using the average of the known valid observations in the time period, if no ' +
            'known values are present in the chosen period, fills values with last known value, else 0 if no values exist.',
        'Rolling average': 'Fills missing values by using the average of the known valid observations rolling average' +
            ' window (hours). If no valid data entries are found, this method defaults to backfill',
        'Default value': 'Fills missing values by using a provided default value',
        'Interpolate': 'Fills missing values by using linear interpolation. At the edges of a time period,' +
            ' known values are forward filled and backfilled if required.',
        'Constant Monthly Flatten': 'Flattens all valid entries within a month period',
        'backfill_padded_with_default_value': 'Backfills all known values and forward fills (pads)' +
            ' with the specified default value',
        'interpolated_with_forward_fill_rolling_average': 'Interpolates between all known values' +
            ' up to the last valid known value.' +
            ' Forward fills with a rolling average of the rolling average window.',
        'interpolated_with_calculated_forward_fill_average_by_hours': 'Interpolates between all known values up to the last valid' +
            ' known value. Forward fills with an average value calculated from the average of all known valid values between the ' +
            'timespan of the last valid known value and the specified look back hours as specified in the rolling average window',
        'interpolated_with_calculated_forward_fill_average_by_count': 'Interpolates between all known values up to the last valid' +
            ' known value. Forward fills with an average value calculated from the average of all known valid values between the ' +
            'last valid known value and the specified look back number of values as specified by the average count',
        null: 'No specified fill method'
    };

    readonly fuse_options: object = {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 50,
        maxPatternLength: 32,
        minMatchCharLength: 2,
        keys: [
            "attributes.name",
            "attributes.description"
        ]
    };

    readonly readonly_event_columns = ['Duration', 'Changed On', 'Changed By', 'Inputs To', 'Outputs From'];

    get event_columns(): string[] {
        return ['Type', 'Start', 'End', 'Duration', 'Comment', 'Component', 'Changed On', 'Changed By', 'Inputs To', 'Outputs From'];
    }

    readonly aggregation_descriptions: { [key: string]: string } = {
        'max': 'Maximum',
        'min': 'Minimum',
        'mean': 'Mean',
        'mean_nan': 'Mean nan',
        'count': 'Count',
        'total': 'Total',
        'total_with_hourly': 'Total with hourly',
        'weighted_average': 'Weighted average',
        'latest_value': 'Latest value',
        'calculation': 'Calculation'
    };
    column_dict = {};
    // TODO this should only be the Promise, ie. store the FlatPromise.promise
    $estimate_types: FlatPromise;

    readonly baseColumns: { name: string, contentFilter?: string, description?: string, title: string, deprecated?: boolean, abbr?: string }[] = [
        {
            'name': 'Status',
            'contentFilter': 'status',
            'title': 'Status',
            'description': 'The status of the series: warning, alert or good.'
        },
        {'name': 'Name', 'title': 'Name', 'description': 'The WIRE name of a series'},
        {'name': 'Description', 'title': 'Description', description: 'The WIRE description of a series'},
        {
            'name': 'Average',
            'description': 'The average for the range queried',
            'contentFilter': 'significantNumber',
            'title': 'Average'
        },
        {'name': 'Count', 'description': 'The number of actual data points for the range queried', 'title': 'Count'},
        {
            'name': 'Current Value',
            'contentFilter': 'significantNumber',
            'title': 'Current Value',
            'description': 'The latest actual hourly value of a series'
        },
        {
            'name': 'Value',
            'contentFilter': 'significantNumber',
            'title': 'Value',
            'description': 'The actual value of a series for the chosen time period'
        },
        {
            'name': 'Value Origin',
            'title': 'Value Origin',
            'description': 'This is no longer supported by WIRE.',
            deprecated: true
        },
        {
            'name': 'Previous Value',
            'title': 'Previous Value',
            'description': 'The actual value of the previous sample period'
        },
        {
            'name': 'Daily',
            'contentFilter': 'significantNumber',
            'title': 'Daily',
            'description': 'The actual value of the previous production day relative to the end of the time range specified'
        },
        {
            'name': 'Daily Variance',
            'contentFilter': 'significantNumber',
            'title': 'Daily Variance',
            'description': 'The difference between the Daily actual value and the estimate for the specified series',
            deprecated: true
        },
        {
            'name': 'Daily Variance %',
            'contentFilter': 'percentage',
            'title': 'Daily Variance %',
            'description': 'The difference between the Daily actual value and the estimate for the specified series',
            deprecated: true
        },
        {
            'name': 'Latest Entry Date',
            'contentFilter': 'date',
            'title': 'Latest Entry Date',
            'description': 'The date of the lasted data entry',
            deprecated: false
        },
        {
            'name': 'Latest Entry Origin',
            'title': 'Latest Entry Origin',
            'description': 'The origin of the latest raw data entry',
            deprecated: true
        },
        {
            'name': 'Latest Entry Value',
            'contentFilter': 'significantNumber',
            'title': 'Latest Entry Value',
            'description': 'The value of the lasted raw data entry for the specific series',
            deprecated: true
        },
        {
            'name': 'MTD',
            'contentFilter': 'significantNumber',
            'title': 'MTD',
            'description': 'The actual value of the specified series from the first day of ' +
                'the month until the end of the chosen time range.'
        },
        {
            'name': 'MTD Variance',
            'contentFilter': 'significantNumber',
            'title': 'MTD Variance',
            'description': 'The difference between the MTD actual value and the estimate of the specified series',
            deprecated: true
        },
        {
            'name': 'MTD Variance %',
            'contentFilter': 'percentage',
            'title': 'MTD Variance %',
            'description': 'The % difference between the MTD actual value and the specified series estimate ' +
                'for the chosen time range',
            deprecated: true
        },
        {
            'name': 'Max',
            'contentFilter': 'significantNumber',
            'title': 'Max',
            'description': 'The largest numerical value for the chosen time range'
        },
        {
            'name': 'Min',
            'contentFilter': 'significantNumber',
            'title': 'Min',
            'description': 'The smallest numerical value for the chosen time range'
        },
        {
            'name': 'Median',
            'contentFilter': 'significantNumber',
            'title': 'Median',
            'description': 'The median is the value separating the higher half from the lower ' +
                'half of the ordered data for the specified time range chosen. '
        },
        {
            'name': '75 %',
            'contentFilter': 'percentage',
            'title': '75 %',
            'description': 'The third quartile. 75% of the data set is below the third quartile and 25% of ' +
                'the data set is above the third quartile. The data set is all the data in the chosen time range'
        },
        {
            'name': '25 %', 'contentFilter': 'percentage', 'title': '25 %', 'description': 'The first quartile. 25% ' +
                'of the data set is below quartile 1 and 75% of ' +
                'the data set is above quartile 1. The data set is all the data in the chosen time range'
        },
        {
            'name': 'Shift A',
            'contentFilter': 'significantNumber',
            'title': 'Shift A',
            'description': 'The actual value for the chosen period for shift A'
        },
        {
            'name': 'Shift B',
            'contentFilter': 'significantNumber',
            'title': 'Shift B',
            'description': 'The actual value for the chosen period for shift B'
        },
        {
            'name': 'Shift C',
            'contentFilter': 'significantNumber',
            'title': 'Shift C',
            'description': 'The actual value for the chosen period for shift C'
        },
        {
            'name': 'Shift D',
            'contentFilter': 'significantNumber',
            'title': 'Shift D',
            'description': 'The actual value for the chosen period for shift D'
        },
        {
            'name': 'Sum', 'contentFilter': 'significantNumber', 'title': 'Sum', 'description': 'The numerical' +
                ' total (sum) of all the data points for the chosen period'
        },
        {'name': 'Unit', 'title': 'Unit', 'description': 'The engineering unit specified for a series'},
        {
            'name': 'YTD', 'contentFilter': 'significantNumber', 'title': 'YTD', 'description': 'The Year to Date ' +
                'actual value for the series'
        },
        {
            'name': 'YTD Variance',
            'contentFilter': 'significantNumber',
            'title': 'YTD Variance',
            'description': 'The numerical difference between the actual value of the series and the estimated ' +
                'value of the series year to date',
            deprecated: true

        },
        {
            'name': 'YTD Variance %',
            'contentFilter': 'percentage',
            'title': 'YTD Variance %',
            'description': 'The percentage difference between the actual value and the estimated value for the ' +
                'YTD values the specified series',
            deprecated: true
        },
        {
            'name': 'Sparkline',
            'contentFilter': 'significantNumber',
            'title': 'Sparkline',
            'description': 'A small trend-line like graph indicating the overall trend of the series for the ' +
                'chosen time period'
        }
    ];

    readonly schema: Series = {
        id: null,
        type: 'series',
        attributes: {
            default_value: null,
            name: null,
            description: null,
            fill_method: null,
            rolling_average_hours: null,
            aggregation: null,
            sample_period: null,
            sample_offset: null,
            hihi: null,
            hi: null,
            lowlow: null,
            mean: null,
            std: null,
            low: null,
            allow_edit: null,
            delete_hihilowlow: null,
            budget: null,
            default_chart: null,
            accumulation: null,
            kpi_level: null,
            name_formula: null,
            collector_names: null,
            alternate_names: null,
            specialised_function: null,
            created_on: null,
            changed_on: null,
            extra_arguments_string: '{}',
            linked_components: null,
            json: {comment: null},
            assumptions: null,
        },
        relationships: {
            engineering_unit: {data: {type: 'engineering_unit', id: null}},
            series_type: {data: {type: 'series_type', id: null}},
            event_type: {data: {type: 'event_type', id: null}},
            weighted_average_series: {data: {type: 'series', id: null}},
            created_by: {data: {id: null, type: 'users'}},
            changed_by: {data: {id: null, type: 'users'}}
        }
    };
    sample_periods: string[];
    readonly fore_cast_cols: { name: string, contentFilter?: string, description?: string, title: string, deprecated?: boolean, abbr?: string }[] = [
        {
            name: '@',
            title: '@',
            abbr: '@',
            description: 'The @ (estimate) value for a specific series for a chosen period of time'
        },
        {
            name: '@ Variance',
            title: '@ Variance',
            abbr: '@ Var',
            description: 'The difference between the @ (estimate) and the actual value for a specific series for a chosen period'
        },
        {
            name: '@ Variance %',
            title: '@ Variance %',
            abbr: '@ Var %',
            description: 'The percentage difference between the @ (estimate) and the actual value for a specific series for the chosen period'
        },
        {
            name: 'Daily @',
            title: 'Daily @',
            abbr: 'Daily @',
            description: 'The Daily @ (estimate) for a specific series for the previous production day relative to the end date of the chosen time period'
        },
        {
            name: 'Daily @ Variance',
            title: 'Daily @ Variance',
            abbr: 'Daily @ Var',
            description: 'The difference between the actual value for a series and the @ (estimate) for the previous production day '
        },
        {
            name: 'Daily @ Variance %',
            title: 'Daily @ Variance %',
            abbr: 'Daily @ Var %',
            description: 'The percentage difference between the actual value for a series and the @ (estimate) for the previous production day'
        },
        {
            name: 'MTD @',
            title: 'MTD @',
            abbr: 'MTD @',
            description: 'The value for @ (estimate) for a series for the month to date relative to the end date of the chosen period'
        },
        {
            name: 'MTD @ Variance',
            title: 'MTD @ Variance',
            abbr: 'MTD @ Var',
            description: 'The difference between the actual value for a series month to date and the @ (estimate)'
        },
        {
            name: 'MTD @ Variance %',
            title: 'MTD @ Variance %',
            abbr: 'MTD @ Var %',
            description: 'The percentage difference between the actual value for a series month to date and the @ (estimate)'
        },
        {
            name: 'YTD @',
            title: 'YTD @',
            abbr: 'YTD @',
            description: 'The value for @ (estimate) for a series for the year to date relative to the end date of the chosen period'
        },
        {
            name: 'YTD @ Variance',
            title: 'YTD @ Variance',
            abbr: 'YTD @ Var',
            description: 'The difference between the actual value for a series year to date and the @ (estimate)'
        },
        {
            name: 'YTD @ Variance %',
            title: 'YTD @ Variance %',
            abbr: 'YTD @ Var %',
            description: 'The percentage difference between the actual value for a series month to date and the @ (estimate)'
        }
    ];

    est_favourability_dict = {'Value': 'Favourable Value'};

    //columns = angular.copy(seriesData.baseColumns);
    readonly columnsDefault: string[] = ['Status', 'Name', 'Description', 'Value', 'Forecast', 'MTD', 'MTD Forecast'];
    readonly columnsDetail: string[] = ['Daily Variance', 'MTD Variance', 'Shift A', 'Shift B', 'Count', 'Average', 'Min', 'Max', 'Sum'];
    readonly columnsSmall: string[] = ['Description', 'Value', 'Forecast']; //Default for small screens/contexts

    constructor(private api: ApiService,
                private appScope: AppScope,
                private dateTimePeriodService: DateTimePeriodService,
                private http: HttpClient,
                private snackBar: MatSnackBar,
                private dialog: MatDialog) {

        const ctrl = this;
        this.baseColumns.forEach(bc => {
            this.column_dict[bc.name] = bc;
        });
        // TODO move to the auto_complete section below
        // TODO check which of these parameters are not being used
        // FIXME this could cause an concurrency issue, as the callers of upsertSeries() do not wait for these calls to complete
        api.engineering_unit.search().toPromise().then(response => this.engineering_units = response.data);
        api.series_type.search().toPromise().then(response => this.series_type_list = response.data);
        api.series_light.search().toPromise().then(response => this.series_list = response.data);
        api.event_type.search().toPromise().then(response => this.event_type_list = response.data);
        //    est_favourability_dict = {'Favourable Daily @': '', 'Favourable MTD @': '', 'Favourable YTD @': ''};
        this.$estimate_types = new FlatPromise();
        let $col_dict = new FlatPromise();
        api.estimate_type.search().toPromise().then(response => {
            ctrl.estimate_types_list = response.data;

            ctrl.estimate_types_list.forEach(est => {
                ctrl.fore_cast_cols.forEach(function (col) {
                    let name = col.name.replace('@', est.attributes.name);
                    let description = col.description.replace('@', est.attributes.name);
                    let abbr = ctrl.est_name_abbr_dict[est.attributes.name] ?
                        col.abbr.replace('@', ctrl.est_name_abbr_dict[est.attributes.name]) :
                        col.name.replace('@', est.attributes.name);
                    ctrl.column_dict[name] = {
                        'name': name,
                        'contentFilter': 'significantNumber',
                        'title': name,
                        description: description,
                        abbr: abbr
                    };

                    // Creating a mapping between estimate calcs from GSS and their favourability
                    if (name.includes('Daily')) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'Daily ' + est.attributes.name
                    } else if (name.includes('YTD')) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'YTD ' + est.attributes.name

                    } else if (name.includes('MTD')) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'MTD ' + est.attributes.name

                    } else if (name === est.attributes.name) {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'Value'

                    } else {
                        ctrl.est_favourability_dict[name] = 'Favourable ' + 'Value'
                    }

                });
            });
            ctrl.$estimate_types.resolve(ctrl.estimate_types_list);
            ctrl.getColumnMap().promise.then(() => $col_dict.resolve())
        });

        dateTimePeriodService.dtp_complete.promise.then(() => {
            this.sample_periods = dateTimePeriodService.sample_periods.map(function (item) {
                return item.name
            });
        });

        // this.engineering_units.$promise(()=>{
        //     utils.sortObjectsByName(seriesData.engineering_units);
        // });
        // this.series_type_list.$promise.then(()=>{
        //     utils.sortObjectsByName(seriesData.series_type_list);
        // })

    }

    fillColumns(estimate_type_name: string[]) {
        const ctrl = this;
        if (!estimate_type_name) {

            estimate_type_name = ['Forecast'];
        }
        //for backwards compatibablity
        if (!(Array.isArray(estimate_type_name))) {
            estimate_type_name = [estimate_type_name]
        }

        let columnsDefault = ['Status', 'Name', 'Description', 'Value', 'MTD'];

        let columnsSmall = ['Description', 'Value']; //Default for small screens/contexts

        estimate_type_name.forEach(est_name => {
            columnsDefault.push(est_name);
            columnsDefault.push('MTD ' + est_name);
            columnsSmall.push(est_name);
        });

        let columns = utils.deepCopy(this.baseColumns);

        estimate_type_name.forEach(est_name => {
                ctrl.fore_cast_cols.forEach(col => {
                    let name = col.name.replace('@', est_name);
                    let description = col.description.replace('@', est_name);

                    if (name.includes('%')) {
                        columns.push({
                            'name': name,
                            'contentFilter': 'percentage',
                            'title': name,
                            description: description
                        });
                    } else {
                        columns.push({
                            'name': name,
                            'contentFilter': 'significantNumber',
                            'title': name,
                            description: description
                        });
                    }

                })
            }
        );

        let columnsAll = columns.map(function (column) {
            // @ts-ignore
            return column.name
        });

        //TODO update dashboard to use this return value instead of old seriesData
        return {'all': columnsAll, 'default': columnsDefault, 'small': columnsSmall, 'columns': columns};
    };

    filterColumns(toFilter, remove) {
        if (toFilter) {
            toFilter = toFilter.filter(function (c) {
                return !c.includes(remove);
            });
        } else {
            toFilter = [];
        }
        return toFilter;
    };

    getSeriesPermissions(series_ids): Promise<any> {

        return this.api.get("/auth/get_series_permissions?" + utils.httpParamSerializer({series: series_ids})).toPromise()

    };

    upsertSeries(component?, series?: Series): MatDialogRef<SeriesFormComponent, any> { //currently this is only called from seriesForm and this
        const dialogConfig = new MatDialogConfig();

        if (series == null) {
            series = utils.deepCopy(this.schema)
        }
        console.log("upsert series", utils.deepCopy(this.engineering_units));
        dialogConfig.data = {series: series, component: component, seriesData: this};

        return this.dialog.open(SeriesFormComponent, dialogConfig);

    };

    estimateForm(selected_estimate, series_list): MatDialogRef<EstimateFormComponent, any> {
        const ctrl = this;
        console.log('Opened Estimate Form Dialog add', series_list);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            selectedEstimateType: selected_estimate,
            seriesList: series_list,
            estimate: null,
            seriesData: this
        };

        return this.dialog.open(EstimateFormComponent, dialogConfig);
        // let dialogRef: MatDialogRef<EstimateFormComponent, any> = this.dialog.open(EstimateFormComponent, dialogConfig);
        // dialogRef.afterClosed().toPromise().then(response => {
        //})

    }

    getEstimates(series_id) {
        const ctrl = this;
        let time_stamp = new Date((new Date).getFullYear(), (new Date).getMonth(), 1, 12);
        let raw_data_fetched = [];

        let $series_estimates = new FlatPromise();
        let estimate_series;
        let $estimate_series = ctrl.api.estimate.search(ctrl.api.prep_q([
            {
                name: 'series_id',
                op: 'eq',
                val: series_id
            }

        ])).toPromise().then(estimates => estimate_series = estimates.data);

        Promise.all([$estimate_series, ctrl.estimate_types_list]).then(function () {
            estimate_series.map(function (estimate) {
                let $data_fetched = ctrl.api.raw_data.search(ctrl.api.prep_q([
                    {
                        name: 'series_id',
                        op: 'eq',
                        val: estimate.id
                    },
                    {
                        name: 'time_stamp',
                        op: 'eq',
                        val: time_stamp.toISOString()
                    }
                ], {'filter[single]': 1})).toPromise();

                raw_data_fetched.push($data_fetched);
                $data_fetched.then(function (data) {
                    if (!data) return;

                    estimate.attributes.current_value = data.attributes.value;
                }, (err) => {
                    console.log('No raw data for estimate : ', estimate.attributes.name);
                    estimate.attributes.current_value = null;
                });

                ctrl.estimate_types_list.map(function (type) {
                    if (estimate.relationships.estimate_type.data.id == type.id) {
                        estimate.attributes.estimate_type_name = type.attributes.name;
                    }
                });

            });
            Promise.all(raw_data_fetched).then(
                res => {
                    $series_estimates.resolve(estimate_series);
                }, errors => {
                    $series_estimates.resolve(estimate_series);
                }
            );
        });

        return $series_estimates;
    };

    getColumnMap() {
        const ctrl = this;
        let $p = new FlatPromise();
        ctrl.appScope.auth_complete.promise.then(() => {
            let column_mappings = ctrl.appScope.config_name_map['series_summary_column_map'] ?
                ctrl.appScope.config_name_map['series_summary_column_map'].value : [];
            column_mappings.forEach(col_map => {
                ctrl.column_dict[col_map.name].title = col_map.title;
            });
            $p.resolve();
        });
        return $p;
    }

    getEstimateTypesList(columnList, estimateTypesList) {
        let list = [];
        columnList.forEach(col => {
            estimateTypesList.forEach(est_type => {
                if (col.search(est_type.attributes.name) > -1) {
                    list.push(est_type.attributes.name);
                }
            })
        });
        return list;
    }
}
