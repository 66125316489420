import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'json-table',
    templateUrl: './json-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonTableComponent implements OnInit {

    @Input()
    json_data: { [key: string]: any } = {};

    @Input()
    compare_data: { [key: string]: any } = {};

    data: { key: string, value: any, changed?: boolean }[] = [];

    constructor() {
    }

    ngOnInit() {
        this.data = Object.entries(this.json_data).map((entry: [string, any]) => {
            return {key: entry[0], value: entry[1], changed: this.compare_data.hasOwnProperty(entry[0])}
        })
    }

}
