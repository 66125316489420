<div class="body">
  <ngx-loading-bar [color]="'orange'"></ngx-loading-bar>
  <mat-toolbar [hidden]="!headerData.show_header" class="header-toolbar">
    <div class="brand">
      <button (click)="sidenav.toggle(); appScope.sidenav_open=!appScope.sidenav_open;" *ngIf="headerData.show_header"
              class="sidenav-closed" mat-button>
        <i class="fa fa-bars"></i>
      </button>
      <div>
          <img src="/static/images/wire-logo.gif"/>
      </div>
      <account-picker></account-picker>
      <h1 class="page-title-header">{{headerData.title}}</h1>
      <div class="right-mat-menu">
        <button *ngIf="appScope.current_user" [matMenuTriggerFor]="right_mat_menu"
                mat-button>{{appScope.current_user.name}}</button>
        <mat-menu #right_mat_menu="matMenu" backdropClass="header-menu-backdrop" class="header-menu">
          <ng-container *ngFor="let item of right_menu.items">
            <a *ngIf="item.angular_page && item.link && item.name"
               [routerLink]="item.link" mat-menu-item>{{item.name}}</a>
            <a *ngIf="item.name==='Logout'"
               [href]="item.link" mat-menu-item>{{item.name}}</a>
            <a *ngIf="!item.angular_page && item.link && item.name && item.name !=='Logout'"
               [href]="item.link" mat-menu-item target="_blank">{{item.name}}</a>
            <div *ngIf="!item.link && !item.name"
                 style="pointer-events: none; cursor: default; padding: 0 6px">
              <hr [className]="item.class" style="border: 0; height: 1px; background-color: #dbd3d2"/>
            </div>
          </ng-container>
        </mat-menu>
        <img src='/admin/myfiles/download/right-logo.png' class="client_logo"/>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container" [ngClass]="{'sidenav-open':appScope.sidenav_open}">
    <mat-sidenav #sidenav (opened)="appScope.sidenav_open=true" [(opened)]="appScope.sidenav_open" mode="over"
                 (closed)="appScope.sidenav_open=false">
      <sidenavigation></sidenavigation>
    </mat-sidenav>

    <mat-sidenav-content>
      <header-menu (finishedLoading)="finishedLoading = $event"></header-menu>
      <router-outlet *ngIf="finishedLoading || !appScope.current_user"></router-outlet>
    </mat-sidenav-content>

  </mat-sidenav-container>

</div>

<at-who [ngStyle]="{'display': appScope.at_who===true? 'block': 'none'}" class="at-who-container"
        id="at_who">
</at-who>