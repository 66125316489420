import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {SeriesDataService} from "../services/series_data.service";
import * as utils from '../lib/utils';

@Component({
    selector: 'series-component-table',
    templateUrl: 'series-component-table.component.html',
    styleUrls: ['../forms/forms.less', 'series-component-table.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class SeriesComponentTableComponent implements OnInit {
    @Input() component: any;
    @Input() deleted_component_ids: any;
    @Input() series_properties: any;
    @Input() all_series: any;
    @Input() flowchart: any;
    @Input() showHints: any;

    comp: any;
    tree_position: string = 'child';
    objectKeys = Object.keys;

    constructor(private api: ApiService, private seriesData: SeriesDataService) {
        //this.selectChange = new EventEmitter();
    }

    ngOnInit() {
        const ctrl = this;
        let comp = utils.deepCopy(this.component);
        if (ctrl.component.is_parent) {
            ctrl.tree_position = 'parent';
        } else {
            ctrl.tree_position = 'child';
        }
    }

    showSeriesForm(series_component) {
        const ctrl = this;
        let series = utils.deepCopy(series_component.relationships.series.data);

        let $series_full = this.api[series.type].getById(series.id).toPromise();
        $series_full.then(returned => {
            if (returned) {
                console.log(returned)
                let series_full = returned.data;
                const dialogRef = ctrl.seriesData.upsertSeries(ctrl.component, series_full);
                dialogRef.afterClosed().toPromise().then(response => {
                    if (series) {
                        let updated_series;
                        if (series.series) {
                            updated_series = series.series;
                        } else {
                            updated_series = series;
                        }
                        series_component.relationships.series.data = updated_series;
                        ctrl.updateSeriesList(updated_series);
                    }
                })
            } else {
                //$mdToast.show($mdToast.simple().textContent("No series selected."));
            }
        })
    };

    updateSeriesList(series) {
        const ctrl = this;
        ctrl.all_series.forEach(function (s) {
            if (series.id === s.id) {
                s.attributes = series.attributes;
                s.relationships = series.relationships;
            }
        })
    };

    deleteSeriesComponent(item) {
        const ctrl = this;
        let delete_item = this.api.series_component.delete(item.id);
        delete_item.then(function (ret) {
                ctrl.component.relationships.series_components.data = ctrl.component.relationships.series_components.data.filter(function (deleted_component) {
                    return deleted_component.id != item.id
                });
                ctrl.component.attributes.custom_series = {};
                //ctrl.deleted_component_ids.push(item.id);
                ctrl.flowchart.deleteSeriesComponents([item.id]);

            }, function (ret) {
                console.log('Failed to delete, check dependent objects');
                //$mdToast.show($mdToast.simple().textContent('Failed to delete, check dependent objects'));
            }
        );
    };

    setPropertyCategory(series_component, event) {
        series_component.relationships.series_property.data = event.value;
    }

}