<div class="row">
  <button (click)="addChartSeries()" class="bordered-button self-center" mat-button
          matTooltip="Add Series" style="text-transform: uppercase">Add Series <i class="fa fa-arrow-down"></i>
  </button>
</div>
<div (mouseenter)="hint='series'">
  <ng-container *ngIf="series_list!==undefined">
    <table class="custom-chart-series">
      <tr>
        <th></th>
        <th style="min-width: 260px;height:30px;">Series</th>
        <th>Chart Type</th>
        <th style="width: 50px;">Axis</th>
        <th>Show Limits</th>
      </tr>

      <ng-container *ngFor="let series_config of series_list; let i = index">
        <tr>
          <td (click)="expanded[i]=!expanded[i]" class="caret">
            <i *ngIf="!expanded[i]" [title]="'Click here for more customisation options'" class="fas fa-caret-down"></i>
            <i *ngIf="expanded[i]" [title]="'Click here to close the detail options drawer'"
               class="fas fa-caret-up"></i>
          </td>
          <td>
            <ng-container *ngIf="name_list">
              <select-search (selectChange)="addNameToConfig(series_config, $event)"
                             *ngIf="name_list.length>0"
                             [classes]="'custom-chart-series'"
                             [data]="series_light_full"
                             [fill_stubs]="true"
                             [matTooltipClass]="'custom-chart-series-tooltip'"
                             [matTooltip]="getValue(series_config.name) && getValue(series_config.name).attributes.description?
                        getValue(series_config.name).attributes.description : series_config.name"
                             [value]="getValue(series_config.name)"
                             class="select-search"
              ></select-search>
            </ng-container>
          </td>
          <td style="width:80px;">
            <mat-form-field class="custom">
              <mat-select (selectionChange)="updateChartType($event)" [(value)]="series_config.type">
                <mat-option *ngFor="let type of chart_types" [value]="type">
                  {{type | titlecase}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

          <td style="width:80px;text-align:center;">
            <mat-form-field class="custom">
              <mat-select [(value)]="series_config.axis">
                <mat-option *ngFor="let axis of axes" [value]="axis">
                  {{axis | titlecase}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

          <td style="text-align:center;">
            <mat-checkbox [(ngModel)]="series_config.show_limits" class="custom"></mat-checkbox>
          </td>
          <div style="text-align: center;width: 40px">
            <label></label>
            <i (click)=removeRow(i); (mouseenter)="hint='Remove series from chart'" class="fa fa-trash" id="{{i}}"
               style="font-size:18px;"></i>
          </div>

        </tr>
        <tr [ngStyle]="{'display':expanded[i]===true ? '' : 'none' }" class="expanded">
          <td colspan="5" style="padding-left: 34px;">
            <div class="inner-content">
              <div class="row">
                <!--                <div style="height: 100%; width: 100px; border:1px solid black; font-size:26px;word-wrap: break-word;">-->
                <!--                  {{getValue(series_config.name).attributes.name}}-->
                <!--                </div>-->
                <div>
                  <label>Line Type</label>
                  <mat-form-field class="custom" matTooltip="Line and area charts only.">
                    <mat-select [(value)]="series_config.line_type"
                                [disabled]="series_config.type !== 'line' && series_config.type !== 'area'">
                      <mat-option value="solid">Solid</mat-option>
                      <mat-option value="dotted">Dotted</mat-option>
                      <mat-option value="dashed">Dashed</mat-option>
                      <mat-option value="longdashed">Long dashed</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <label>Custom legend</label>
                  <mat-form-field>
                    <input [(ngModel)]="series_config.custom_legend_name" matInput>
                  </mat-form-field>

                </div>
                <div>
                  <label>Line Thickness</label>
                  <mat-form-field class="custom" matTooltip="Line and area charts only.">
                    <mat-select [(value)]="series_config.line_thickness"
                                [disabled]="series_config.type !== 'line' && series_config.type !== 'area'">
                      <mat-option value="thickness-thin">Thin</mat-option>
                      <mat-option value="normal">Normal</mat-option>
                      <mat-option value="thickness-thick">Thick</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div>
                  <label>Custom Range</label>
                  <mat-form-field class="custom" matTooltip="Line and area and bar charts only.">
                    <mat-select (selectionChange)="changeRange($event, i, series_config)"
                                [(value)]="series_config.range">
                      <mat-option [value]="null"></mat-option>
                      <mat-option *ngFor="let range of ranges"
                                  [value]="range.range">{{range.range | removeUnderscore | upperfirst}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <label>Custom Period</label>
                  <mat-form-field class="custom" matTooltip="Line and area and bar charts only.">
                    <mat-select (selectionChange)="checkPeriod($event, i)" [(value)]="series_config.sample_period"
                                [compareWith]="matSelectCompare">
                      <mat-option *ngFor="let period of sample_periods"
                                  [value]="period">{{period.name | removeUnderscore | upperfirst}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div style="text-align:center;height:50px;">
                  <label>Cummulative</label>
                  <mat-checkbox [(ngModel)]="series_config.cumulative" class="custom"
                                style="padding-top:8px;"></mat-checkbox>
                </div>

                <div style="min-width:80px; min-height:20px;text-align:center;">
                  <label>Colour</label>
                  <colour-palette
                      [(value)]="series_config.colour"
                      class="no-label"
                      palette="2"
                      show_text="false">
                  </colour-palette>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
  </ng-container>
</div>