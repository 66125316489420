import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import * as utils from '../lib/utils'
import {MatSnackBar} from "@angular/material";
import {UserData} from "../services/user_data.service";
import {AppScope} from "../services/app_scope.service";
import {HttpClient} from "@angular/common/http";
import {takeUntil} from "rxjs/internal/operators";
import {DateTimePeriod, DateTimePeriodService} from "../services/datetime_period.service";
import {Session} from "../services/tile_data.service";
import {HeaderDataService} from "../services/header_data.service";
import {ApiService} from "../services/api.service";
import {Subject} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'ore-forecast-view',
    templateUrl: './ore_forecast_view.component.html',
    encapsulation: ViewEncapsulation.None
})
export class OreForecastViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    custom_cols: any[] = [];
    totals_dict: any = {delivered: {}, fed: {}};
    forecast_dict: any = {};
    stockpile_dict: any = {};
    series_list: any[] = [];

    contained: any = {};
    delivered: any = {};
    fed: any = {};
    totals: any = {contained: {}, inputs: {}, outputs: {}};
    stockpiles: any[];
    summary: { [key: string]: any };

    isNotMobile: boolean;
    user_session: Session = null;
    sessionID: any;
    dtp: DateTimePeriod;
    show_delivered: boolean;
    show_contained: boolean;
    show_fed: boolean;

    constructor(private headerData: HeaderDataService, private appScope: AppScope,
                private api: ApiService, private userData: UserData, private http: HttpClient,
                private snackbar: MatSnackBar, private dateTimePeriodService: DateTimePeriodService,
                private route: ActivatedRoute,) {
    }

    ngOnInit() {
        const ctrl = this;
        ctrl.isNotMobile = this.appScope.isNotMobile;
        ctrl.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
            ctrl.dateTimePeriodService.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
                ctrl.dtp = dtp;
                ctrl.getStockpiles();
            });

            Promise.all([this.appScope.auth_complete.promise,
                         ctrl.dateTimePeriodService.dtp_complete.promise]).then(() => {
                ctrl.sessionID = ctrl.appScope.current_user.id;
                ctrl.dtp = ctrl.dateTimePeriodService.dtp;
                ctrl.buildHeader();
                ctrl.getStockpiles();
            });

            // ctrl.api.series_light.search().toPromise().then((result) => {
            //     ctrl.full_series_list = result.data;
            // });
        })
    }

    //delivered, contained, fed per stockpile plus a total
    addStockpile(stockpile) {

    }

    removeStockpile(stockpile) {
        this.stockpiles = this.stockpiles.filter(stock => stock.id !== stockpile.id);
    }

    getSeriesSummary(ids) {
        const ctrl = this;
        return this.http.get("/GetSeriesSummary" + '?' + utils.httpParamSerializer({
            series_list: ids,
            start: ctrl.dtp.start,
            return_type: 'json',
            end: ctrl.dtp.end,
            format: 'records',
            deepness: 2,
            single: true,
            columns: ['Forecast', 'Value', 'MTD', 'MTD Forecast']
        }));
        //return summary;
    }

    getStockpiles() {
        const ctrl = this;
        let date_filter_logic = {
            or: [
                {
                    and: [{name: 'opening_date', op: 'gte', val: ctrl.dtp.start}, {
                        name: 'opening_date',
                        op: 'lt',
                        val: ctrl.dtp.end
                    }]
                },
                {
                    and: [{name: 'closing_date', op: 'gte', val: ctrl.dtp.start}, {
                        name: 'closing_date',
                        op: 'lt',
                        val: ctrl.dtp.end
                    }]
                },
                {
                    and: [{name: 'opening_date', op: 'lt', val: ctrl.dtp.start},
                        {
                            or: [{name: 'closing_date', op: 'is_null'},
                                {and: [{name: 'closing_date', op: 'gt', val: ctrl.dtp.start}]}
                            ]
                        }]
                }
            ]
        };
        ctrl.api.stockpile.search(ctrl.api.prep_q([date_filter_logic])).toPromise().then((result) => {
            if (!result) return;

            ctrl.stockpiles = result['data'];

            let selected_stockpile = ctrl.stockpiles[0];

            ctrl.stockpiles.forEach(function (stock) {
                ctrl.contained[stock.id] = {};
                ctrl.fed[stock.id] = {};
                ctrl.delivered[stock.id] = {};
                ctrl.series_list = ctrl.series_list.concat(stock.attributes.input_series_ids);
                stock.fcast_wmt = 0;
                Object.keys(stock.attributes.stockpile_properties.contained).map(function (col) {
                    if (!ctrl.custom_cols.includes(col)) {
                        ctrl.custom_cols.push(col);
                        ctrl.forecast_dict[col] = 0;
                        ctrl.contained[stock.id][col] = 0;
                        ctrl.fed[stock.id][col] = 0;
                        ctrl.delivered[stock.id][col] = 0;
                        ['contained', 'inputs', 'outputs'].forEach(function (prop) {
                            ctrl.totals[prop][col] = 0;
                        })
                    }
                })
            });

            ctrl.stockpiles.forEach(function (stock) {

                ['DMT', 'WMT', 'Cu MMT', 'Co MMT', 'Mn MMT'].forEach(function (name) {
                    ['contained', 'inputs', 'outputs'].forEach(function (prop) {
                        ctrl.totals[prop][name] += stock.attributes.stockpile_properties[prop][name];
                    });
                    ctrl.delivered[stock.id][name] = stock.attributes.stockpile_properties.inputs[name];
                    ctrl.contained[stock.id][name] = stock.attributes.stockpile_properties.contained[name];
                    ctrl.fed[stock.id][name] = stock.attributes.stockpile_properties.outputs[name];
                });

                ctrl.delivered[stock.id]['Mois%'] = 100 - 100 * ctrl.delivered[stock.id]['DMT'] / ctrl.delivered[stock.id]['WMT'];
                ctrl.delivered[stock.id]['Cu%'] = 100 * ctrl.delivered[stock.id]['Cu MMT'] / ctrl.delivered[stock.id]['DMT'];
                ctrl.delivered[stock.id]['Co%'] = 100 * ctrl.delivered[stock.id]['Co MMT'] / ctrl.delivered[stock.id]['DMT'];
                ctrl.delivered[stock.id]['Mn%'] = 100 * ctrl.delivered[stock.id]['Mn MMT'] / ctrl.delivered[stock.id]['DMT'];

                ctrl.contained[stock.id]['Mois%'] = 100 - 100 * ctrl.contained[stock.id]['DMT'] / ctrl.contained[stock.id]['WMT'];
                ctrl.contained[stock.id]['Cu%'] = 100 * ctrl.contained[stock.id]['Cu MMT'] / ctrl.contained[stock.id]['DMT'];
                ctrl.contained[stock.id]['Co%'] = 100 * ctrl.contained[stock.id]['Co MMT'] / ctrl.contained[stock.id]['DMT'];
                ctrl.contained[stock.id]['Mn%'] = 100 * ctrl.contained[stock.id]['Mn MMT'] / ctrl.contained[stock.id]['DMT'];

                ctrl.fed[stock.id]['Mois%'] = 100 - 100 * ctrl.fed[stock.id]['DMT'] / ctrl.fed[stock.id]['WMT'];
                ctrl.fed[stock.id]['Cu%'] = 100 * ctrl.fed[stock.id]['Cu MMT'] / ctrl.fed[stock.id]['DMT'];
                ctrl.fed[stock.id]['Co%'] = 100 * ctrl.fed[stock.id]['Co MMT'] / ctrl.fed[stock.id]['DMT'];
                ctrl.fed[stock.id]['Mn%'] = 100 * ctrl.fed[stock.id]['Mn MMT'] / ctrl.fed[stock.id]['DMT'];
            });

            ['contained', 'inputs', 'outputs'].forEach(function (prop) {
                ctrl.totals[prop]['Mois%'] = 100 - 100 * ctrl.totals[prop]['DMT'] / ctrl.totals[prop]['WMT'];
                ctrl.totals[prop]['Cu%'] = 100 * ctrl.totals[prop]['Cu MMT'] / ctrl.totals[prop]['DMT'];
                ctrl.totals[prop]['Co%'] = 100 * ctrl.totals[prop]['Co MMT'] / ctrl.totals[prop]['DMT'];
                ctrl.totals[prop]['Mn%'] = 100 * ctrl.totals[prop]['Mn MMT'] / ctrl.totals[prop]['DMT'];
            });

            ctrl.series_list = ctrl.series_list.filter(function (value, index, self) {
                return self.indexOf(value) === index;
            });
            ctrl.getSeriesSummary(ctrl.series_list).toPromise().then(function (summary) {
                ctrl.summary = {};
                summary['data'].map(function (series) {
                    ctrl.summary[series.Type] = series;
                });
            });
        })
    };

    calculate() {
        const ctrl = this;
        ctrl.forecast_dict = {};

        ['DMT', 'WMT', 'Cu MMT', 'Co MMT', 'Mn MMT', 'Mois%', 'Cu%', 'Co%', 'Mn%'].map(name => ctrl.forecast_dict[name] = 0);
        ctrl.stockpiles.map(function (stock) {
            let props = stock.attributes.stockpile_properties.contained;
            ctrl.forecast_dict['WMT'] += stock.fcast_wmt;
            ctrl.forecast_dict['DMT'] += stock.fcast_wmt * (1 - props['Mois%'] / 100);
            ctrl.forecast_dict['Co MMT'] += 0.01 * props['Co%'] * stock.fcast_wmt * (1 - props['Mois%'] / 100);
            ctrl.forecast_dict['Cu MMT'] += 0.01 * props['Cu%'] * stock.fcast_wmt * (1 - props['Mois%'] / 100);
            ctrl.forecast_dict['Mn MMT'] += 0.01 * props['Mn%'] * stock.fcast_wmt * (1 - props['Mois%'] / 100);
        });

        ctrl.forecast_dict['Mois%'] = 100 - 100 * ctrl.forecast_dict['DMT'] / ctrl.forecast_dict['WMT'];
        ctrl.forecast_dict['Cu%'] = 100 * ctrl.forecast_dict['Cu MMT'] / ctrl.forecast_dict['DMT'];
        ctrl.forecast_dict['Co%'] = 100 * ctrl.forecast_dict['Co MMT'] / ctrl.forecast_dict['DMT'];
        ctrl.forecast_dict['Mn%'] = 100 * ctrl.forecast_dict['Mn MMT'] / ctrl.forecast_dict['DMT'];

    };

// just thinking about the forecast saving - what if we save the session as 'OreForecast' and retrieve it by createdOn date
// rather than by user_id. When the page loads we can get the most recent one saved regardless of which user made it and
// then possibly have an explicit save button in order to override it

    userRefresh() {
        const ctrl = this;
        ctrl.userData.report = "OreForecast";
        let user_stockpiles = {};
        ctrl.stockpiles.map(stock => user_stockpiles[stock.id] = {'fcast_wmt': 100});
        let default_json = {
            stockpiles: user_stockpiles,
            dtp: ctrl.dtp
        };

        ctrl.api.session_state.getById(ctrl.sessionID).toPromise().then(session => {
            if (!session) return;
            ctrl.user_session = session['data'];
            if (ctrl.userData.user_session.attributes.json.dtp) {
                ctrl.dtp = ctrl.userData.user_session.attributes.json.dtp;
                ctrl.stockpiles.forEach(function (stock) {
                    if (ctrl.user_session.attributes.json.stockpiles[stock.id]) {
                        stock.fcast_wmt = ctrl.user_session.attributes.json.stockpiles[stock.id].fcast_wmt;
                    }
                })
            }
        });
    };

    buildHeader() {
        this.headerData.title = 'Ore Forecast';
        this.headerData.show_dtp = true;
        this.headerData.add_refresh = true;
    };

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
