import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {LoadingBarService} from "@ngx-loading-bar/core";
import {ActivatedRoute, ChildActivationStart, NavigationEnd, NavigationError, Router} from "@angular/router";
import {HeaderDataService} from "./services/header_data.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ApiService} from "./services/api.service";
import {DateTimePeriodService} from "./services/datetime_period.service";
import {AppScope} from "./services/app_scope.service";
import {MatSnackBar} from "@angular/material";
import {PlantDataService} from './services/plant_data.service';
import {Menu} from "./menu_bars/header.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class AppComponent implements OnDestroy, OnInit {
    private readonly onDestroy = new Subject<void>();

    title = 'wire';
    finishedLoading: boolean;
    right_menu: Menu = {};

    @ViewChild('sidenav', {static: false}) sidenav: ElementRef;

    constructor(public loader: LoadingBarService,
                private router: Router,
                private route: ActivatedRoute,
                public headerData: HeaderDataService,
                dateTimePeriodService: DateTimePeriodService,
                public appScope: AppScope,
                private plantData: PlantDataService,
                private snackBar: MatSnackBar,
                private api: ApiService,
                private renderer: Renderer2) {

        this.appScope.isNotMobile = this.detectMobile();
        if (!this.detectChrome() && this.appScope.isNotMobile) {
            // this.snackBar.open("This application was intended to run in a Chrome browser. " +
            //     "We highly recommend that you download chrome from https://www.google.com/chrome/" , 'Hide');
            console.log("Non Chrome browser detected. detectChrome() ", this.detectChrome());
            console.log("detectMobile() ", this.detectMobile());
        }
        this.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
            console.log('AppComponent - subscription: ', params);
        })
        this.router.events.pipe(takeUntil(this.onDestroy)).subscribe((event) => {
            if (event instanceof ChildActivationStart) {
                headerData.reset();
                dateTimePeriodService.read_dtp_from_url = false;
                dateTimePeriodService.show_timespan = true;
                dateTimePeriodService.show_time = true;
                api.cancelActiveQueries();
                //appScope.sidenav_open = this.sidenav.nativeElement.opened;
            }

            if (event instanceof NavigationEnd) {
                (<any>window).dataLayer.push({
                    event: 'pageview',
                    page: {
                        path: event.url,
                        title: 'Custom pageview event'
                    }
                });
            }

            if (event instanceof NavigationError) {
                console.log('NavigationError', event.error);
            }
        })
    }

    ngOnInit(): void {
        const ctrl = this;
        this.appScope.auth_complete.promise.then(function () {
            if (ctrl.appScope.config_name_map.hasOwnProperty("theme")) {
                let theme = ctrl.appScope.config_name_map.theme.value.name;
                ctrl.renderer.addClass(document.body, theme);
            }

            ctrl.right_menu = ctrl.headerData.buildRightMenu();

        })
    }

    detectChrome() {
        return !!window['chrome'] && (!!window['chrome'].webstore || !!window['chrome'].runtime);
    }

    detectMobile() {
        return !(/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase())
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
