import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppScope } from "./app_scope.service";

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private appScope: AppScope, private router: Router) {
  }

  //TODO: implement this (return boolean obs from check_login?)
  canActivate(route, state){
    console.log('AuthGuardService - canActivate: ', route, state);
    return true;
  }
}