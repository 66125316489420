import * as utils from '../lib/utils';
import {Injectable} from "@angular/core";
import {DateTimePeriod} from "./datetime_period.service";
import {HandleError, HttpErrorHandler} from "./http-error-handler.service";
import {catchError} from "rxjs/operators";
import {ApiService} from "./api.service";
import {SeriesDataService} from './series_data.service';
import * as _ from "lodash";

@Injectable()
export class SeriesStringParser {
    private readonly handleError: HandleError;

    constructor(private api: ApiService,
                private seriesData: SeriesDataService,
                httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('SeriesStringParser');
    }

    parseSeriesString(str, dtp: DateTimePeriod, all_series) {
        //TODO fix possible timing issue with all_series
        //all_series.$promise.then(function() {
        return this.seriesData.$estimate_types.promise.then(estimates_list => {
            let pr = new Promise((resolve, reject) => {
                let full_string = utils.deepCopy(str);
                let seriesList = []; //A list of all series to be queried
                let columnsList = []; //A list of all columns to be queried
                let fullList = [];
                let estTypeList = [];

                if (full_string.includes("[") && full_string.includes("]") && full_string.includes("@")) {
                    do {
                        //Get first instance of '[<series>@<column>]'
                        let unparsed = full_string.slice(full_string.indexOf("[") + 1, full_string.indexOf("]") + 1);
                        //Separate out column, series_name and get full series from series list
                        let column = unparsed.substring(unparsed.indexOf("@") + 1, unparsed.indexOf("]"));
                        let series_name = unparsed.substring(0, unparsed.indexOf("@"));
                        let series_found = (all_series.find(obj => {
                            return obj.attributes.name === series_name;
                        }));
                        //Set full string equal to the remaining string (from the end of the [<series>@<column>] found above)
                        full_string = full_string.substring(full_string.indexOf("]") + 1, full_string.length);
                        if (series_found) {
                            let series_id = series_found.id;
                            seriesList[series_id] = series_name;
                            columnsList.push(column);
                            if (Object.keys(fullList).includes(series_name)) {
                                fullList[series_name].types.push(column);
                            } else {
                                fullList[series_name] = {types: [column]};
                            }

                        }
                    } while (full_string.includes("[") && full_string.includes("]") && full_string.includes("@"));

                    estTypeList = this.seriesData.getEstimateTypesList(columnsList, estimates_list);

                    const vars = {
                        series_list: _.uniq(Object.keys(seriesList)),
                        start: dtp.start.toISOString(),
                        end: dtp.end.toISOString(),
                        return_type: 'json',
                        format: "records",
                        sample_period: dtp.sample_period.wire_sample,
                        deepness: 2,
                        estimate: _.uniq(estTypeList),
                        single: true,
                        columns: _.uniq(columnsList)
                    };
                    const params = utils.httpParamSerializer(vars);

                    this.api.get("/GetSeriesSummary", {params: params})
                        .pipe(catchError(this.handleError('parseSeriesString', [])))
                        .subscribe((data: any[]) => {
                            data.forEach(summary => {
                                fullList[summary.Name].types.forEach(type => {
                                    if (!summary[type] && summary[type] !== 0) {
                                        str = str.replace("[" + summary.Name + "@" + type + "]", '');
                                    } else if (isNaN(summary[type])) {
                                        str = str.replace("[" + summary.Name + "@" + type + "]", summary[type]);
                                    } else {
                                        str = str.replace("[" + summary.Name + "@" + type + "]", utils.significantNumber(summary[type], 3));
                                    }
                                })

                            });
                            resolve(str);
                        });
                } else {
                    resolve(str);
                }
            });

            return pr
        }, error => {
            console.log('Error getting seriesData.$estimate_types in seriesStringParser', error);
        });
    }

    simpleParse(string) {
        let full_string = utils.deepCopy(string);
        if (full_string.includes("[") && full_string.includes("]") && full_string.includes("@")) {
            do {
                let unparsed = full_string.slice(full_string.indexOf("["), full_string.indexOf("]") + 1);
                string = string.replace(unparsed, '...');
                full_string = full_string.substring(full_string.indexOf("]") + 1, full_string.length);
            } while (full_string.includes("[") && full_string.includes("]") && full_string.includes("@"));
        }
        return string;
    }
}


