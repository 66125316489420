import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {MatDialogRef, MatSnackBar} from "@angular/material";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ApiService} from "../services/api.service";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'file-upload',
    templateUrl: 'upload_form.component.html',
    encapsulation: ViewEncapsulation.None,  //Global Styles

})
export class UploadFormDialogComponent implements OnInit {
    public collector_list: any[] = [];
    file: any;
    add_mappers: boolean = false;
    add_series: boolean = false;
    row_major: boolean = false;
    override: boolean = false;
    collector: any;
    fileToUpload: File = null;
    showing_hints: boolean = false;
    hint: string = 'Name';

    constructor(
        public api: ApiService,
        public http: HttpClient,
        public dialogRef: MatDialogRef<UploadFormDialogComponent>,
        public dateTimePeriodService: DateTimePeriodService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.api.collector.search().toPromise().then((result) => this.collector_list = result.data);
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    uploadData() {
        const formData: FormData = new FormData();
        formData.append('file', this.fileToUpload, this.fileToUpload.name);
        formData.append('create_mappers', this.add_mappers.toString());
        formData.append('create_series_list', this.add_series.toString());
        formData.append('row_major', this.row_major.toString());
        formData.append('override', this.override.toString());
        formData.append('name', this.collector.attributes.name);
        formData.append('filename', this.fileToUpload.name);

        this.http.post('/CollectData', formData).toPromise().then((response: any) => {
            if (Array.isArray(response.errors)) {
                this.snackBar.open(response.errors.join(''), 'Hide');
            } else {
                console.log('Successfully uploaded collector data');
                this.snackBar.open('File collected successfully.', undefined, {duration: 1500});
            }
        }, error => {
            console.error('Error uploading collector data', error);
            this.snackBar.open('An error occurred while collecting the file.', "Hide");
        });
        // this.http.post('/CollectData',body).toPromise().

    };

}


