import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {Section} from '../../services/tile_data.service';
import {HeaderDataService} from '../../services/header_data.service';
import * as utils from '../../lib/utils';

@Component({
    selector: 'edit-section',
    templateUrl: './edit-section.component.html',
    styleUrls: ['./edit-section.component.less']
})
export class EditSectionComponent implements OnInit {
    hint: string = '';
    original_section: Section;
    @Input() section: Section;
    @Input() index: number;
    @Input() draggable: boolean = false;

    @Output() sectionSave = new EventEmitter;
    @Output() deleteSection = new EventEmitter;

    constructor(public headerData: HeaderDataService) {

    }

    ngOnInit() {
        this.original_section = utils.deepCopy(this.section);
    }

    resetSection() {
        this.section = utils.deepCopy(this.original_section);
    }

    removeRow() {
        this.deleteSection.emit();
    }

    saveSection() {
        this.sectionSave.emit(this.section);
    }
}
