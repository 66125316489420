import {NgModule} from '@angular/core';
import {AppRoutingModule} from './sub-modules/app-routing.module';
import {AppComponent} from './app.component';
import {AuthGuardService} from "./services/auth-guard.service";
import {HttpClientModule} from "@angular/common/http";
import {SeriesStringParser} from "./services/series_string_parser.service"
import {ApiService} from "./services/api.service"
import {AppScope} from "./services/app_scope.service"
import {HttpErrorHandler} from "./services/http-error-handler.service"
import {UserData} from "./services/user_data.service"
import {PlantDataService} from "./services/plant_data.service";
import {DateTimePeriodService} from "./services/datetime_period.service";
import {LoginComponent} from "./user_control/login.component";
import {SideNavigation} from "./menu_bars/side-nav.component";
import {TreeNodeComponent} from "./menu_bars/tree_node.componen";
import {AuditPivot} from "./reports/audit_pivot.component";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ScrollDirective} from "./shared/scroll.directive"
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {QuillModule} from 'ngx-quill';
import {HandsontableGenerator} from "./services/handsontable-generator.service";
import {CalculationCheckTableComponent} from "./tables/calculation-check-table.component";
import {EngineeringUnitView} from "./handson_sheets/engineering_unit_sheet_view";
import {HeaderDataService} from "./services/header_data.service";
import {UserListComponent} from "./user_control/user_list.component";
import {RegisterUserView} from "./user_control/register.component";
import {SelectManySearchComponent} from "./components/select-many-search.component";
import {SelectSearchComponent} from "./components/select-search.component";
import {UserFormComponent} from "./forms/user_form.component";
import {TileFormComponent} from "./forms/tile_form.component";
import {ChartDialog} from "./charts/chart-dialog.component";
import {PageViewFormComponent} from "./forms/page_view_form.component";
import {CollectorEventsTableComponent} from "./tables/collector-events-table/collector-events-table.component";
import {ValueDriverTreeTableComponent} from "./tables/value-driver-tree-table/value-driver-tree-table.component";
import {PivotCtrl} from "./handson_sheets/pivot_component";
import {DateTimePeriodComponent} from "./date-time/date-period.component";
import {HeaderComponent} from "./menu_bars/header.component";
import {HistoryComponent} from "./handson_sheets/history/history.component";
import {QuickChartsComponent} from "./charts/quick-charts.component";
import {StreamSheetViewCtrl} from "./handson_sheets/stream_sheet_view";
import {StreamSheetFocusViewCtrl} from "./handson_sheets/stream_sheet_focus_view";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {EventDataService} from "./services/event_data.service";
import {GenericChartComponent} from "./charts/generic-chart.component";
import {SortableGridListComponent} from "./page-view/sortable-grid-list.component";
import {CollectorMapperSheetView} from "./handson_sheets/collector_mapper_sheet_view";
import {CustomEquipmentViewCtrl} from "./handson_sheets/custom_equipment_sheet_view";
import {EquipmentSheetViewCtrl} from "./handson_sheets/equipment_sheet_view";
import {EquipmentSheetFocusViewCtrl} from "./handson_sheets/equipment_sheet_focus_view";
import {ShiftSheetViewCtrl} from "./handson_sheets/shift_sheet_view";
import {StockpileSheetViewCtrl} from "./handson_sheets/stockpile_sheet_view";
import {ScheduleSheetViewCtrl} from "./handson_sheets/schedule_sheet_view";
import {ScheduleSheetFocusViewCtrl} from "./handson_sheets/schedule_sheet_focus_view";
import {SpcChartComponent} from "./charts/spc-chart.component";
import {BudgetChartComponent} from "./charts/budget-chart.component";
import {DefaultChartComponent} from "./charts/default-chart.component";
import {ComparisonChartFormComponent} from "./forms/comparison_chart_form.component";
import {PageViewComponent} from "./page-view/page-view.component";
import {DownloadFormDialogComponent} from "./forms/file_download_form.component";
import {PageTileComponent} from "./page-view/page-tile.component";
import {SeriesComponentSheetViewCtrl} from "./handson_sheets/series_component_sheet_view";
import {SeriesComponentSheetFocusViewCtrl} from "./handson_sheets/series_component_sheet_focus_view";
import {SeriesTableComponent} from "./tables/series-focus-table.component";
import {SeriesTableFocusViewComponent} from "./tables/series-table-focus_view.component";
import {SeriesDataService} from "./services/series_data.service";
import {CachingService} from "./services/caching.service";
import {SeriesFormComponent} from "./forms/series_form.component";
import {UploadFormDialogComponent} from "./forms/upload_form.component";
import {CustomProcessViewCtrl} from "./handson_sheets/custom_process_sheet_view";
import {ProcessSheetViewCtrl} from "./handson_sheets/process_sheet_view";
import {ProcessSheetFocusViewCtrl} from "./handson_sheets/process_sheet_focus_view";
import {EstimateSheetViewCtrl} from "./handson_sheets/estimate_sheet_view";
import {LogSheetFocusViewComponent} from "./data-sheets/log-sheet-focus-view.component";
import {LogSheetComponent} from "./data-sheets/log-sheet.component";
import {EventSheetViewComponent} from "./handson_sheets/event-sheet-view.component";
import {ResourceSheetViewCtrl} from "./handson_sheets/resource_sheet_view";
import {ProcessAccessSheetViewCtrl} from "./handson_sheets/process_access_sheet_view";
import {SeriesSheetFocusViewCtrl} from "./handson_sheets/series_sheet_focus_view";
import {SeriesSheetViewCtrl} from "./handson_sheets/series_sheet_view";
import {FlowChartComponent} from "./lib/flowchart/flowchart_directive";
import {flowchartFocusViewCtrl} from "./flowchart/flowchart_focus_view"
import {WaterBalanceComponent} from "./reports/water-balance-view.component";
import {ColourPaletteComponent} from "./components/colour_palette";
import {FlowchartFormComponent} from "./flowchart/edit_component_form";
import {
    FlowchartEquipmentFormComponent,
    FlowchartProcessFormComponent,
    FlowchartStreamFormComponent
} from "./flowchart/edit_component";
import {SeriesComponentTableComponent} from "./components/series-component-table.component"
import {FlowchartSeriesComponentComponent} from "./lib/flowchart/flowchart_series_component.component"
import {InlineStyleMenuComponent} from "./components/inline_style_menu.component"
import {
    NgAggregatePipesModule,
    NgArrayPipesModule,
    NgBooleanPipesModule,
    NgMathPipesModule,
    NgObjectPipesModule,
    NgPipesModule,
    NgStringPipesModule
} from "angular-pipes";
import {EstimateDataSheetComponent} from "./data-sheets/estimate-data-sheet.component";
import {EstimateDataSheetViewComponent} from "./data-sheets/estimate-data-sheet-view.component";
import {EstimateDataSheetFocusViewComponent} from "./data-sheets/estimate-data-sheet-focus-view.component";
import {
    AbbreviateNumber,
    FilterByNamePipe,
    NameAndDescriptionPipe,
    NameOrDescriptionPipe,
    RemoveUnderscorePipe,
    SignificantNumberPipe,
    ThousandsSeparatePipe,
} from "./shared/pipes";
import {EditHistoryComponent} from "./forms/edit-history.component";
import {EstimateFormComponent} from "./forms/estimate-form.component";
import {InputDataSheetFocusViewComponent} from "./data-sheets/input-data-sheet-focus-view.component";
import {InputDataSheetComponent} from "./data-sheets/input-data-sheet.component";
import {CommentDrawerComponent} from './components/comment-drawer.component';
import {EventPivotComponent} from "./events/event-pivot.component";
import {CustomChart} from "./forms/custom-chart-form/custom-chart-form.component";
import {LogSheetFormComponent} from './forms/log-sheet-form/log-sheet-form.component';
import {SeriesTableFormComponent} from './forms/series-table-form.component';
import {WeatherWidgetComponent} from './components/weather-widget/weather-widget.component';
import {CustomEventsFormComponent} from './forms/custom-events-form/custom-events-form.component';
import {MySecurityComponent} from "./user_control/my-security.component";
import {ResetPasswordComponent} from "./user_control/reset-password.component";
import {InputDataSheetFormComponent} from './forms/input-data-sheet-form/input-data-sheet-form.component';
import {ChartProcessDetailComponent} from './flowchart/chart-process-detail.component';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {EventsComponent} from './events/events/events.component';
import {ConstantSeriesDataComponent} from "./components/constant_series_data.component";
import {ConstantComponentTableComponent} from "./components/constant-component-table.component";
import {DetailRowDirective} from './directives/detail-row.directive';
import {WaterBalanceDetailViewComponent} from './reports/water-balance-detail-view/water-balance-detail-view.component';
import {WaterBalanceChartComponent} from './charts/water-balance-chart/water-balance-chart.component';
import {ValueDriverTreeComponent} from './components/value-driver-tree/value-driver-tree.component';
import {ApplyCorrectionComponent} from './components/apply-correction/apply-correction.component';
import {MaterialModule} from "./sub-modules/material.module";
import {VirtualScrollerModule} from "ngx-virtual-scroller";
import {PivotTileComponent} from './components/pivot-tile/pivot-tile.component';
import {PivotTileFormComponent} from './forms/pivot-tile-form/pivot-tile-form.component';
import {SparklineComponent} from './tables/sparkline/sparkline.component';
import {RecoveryGradeForecastComponent} from './forecasts/recovery-grade-forecast/recovery-grade-forecast.component';
import {OreForecastViewComponent} from './forecasts/ore_forecast_view.component';
import {PageListViewComponent} from './page-view/page-list-view/page-list-view.component';
import {PageListComponent} from './components/page-list/page-list.component';
import {AppPasswordDirective} from './directives/app-password.directive';
import {RecoveryGradeForecastFormComponent} from './forecasts/recovery-grade-forecast/recovery-grade-forecast-form/recovery-grade-forecast-form.component';
import {JsonTableComponent} from './components/json-table/json-table.component';
import {AuditHistoryComponent} from './handson_sheets/audit-history/audit-history.component';
import {PresentationService} from "./services/presentation.service";
import {ParagraphComponent} from "./components/paragraph/paragraph.component";
import {CustomComponent} from "./components/custom/custom.component";
import {MenuTreeService} from "./services/menu-tree.service";
import {WelcomeComponent} from "./user_control/welcome.component";
import {ContextComponent} from './components/context/context.component';
import {ContextFormComponent} from './forms/context-form/context-form.component';
import {InputDataService} from "./services/input-data.service";
import {HelpPageComponent} from './components/help-page/help-page.component';
import {MentionDirective, ScrollableDirective} from './directives/mention.directive';
import {AtWhoComponent} from './components/at-who/at-who.component';
import {CustomChartFormDialogComponent} from './forms/custom-chart-form-dialog/custom-chart-form-dialog.component';
import {ContextFormDialogComponent} from './forms/context-form-dialog/context-form-dialog.component';
import {PrintFormatterComponent} from './components/print-formatter/print-formatter.component';
import {AccountPickerComponent} from './components/account-picker/account-picker.component';
import {ParagraphFormComponent} from './forms/paragraph-form/paragraph-form.component';
import {ExceptionOnDemandComponent} from './debugging/exception-on-demand/exception-on-demand.component';
import {FlowchartImageFormComponent} from './flowchart/flowchart-image-form/flowchart-image-form.component';
import {AccountFilterComponent} from "./components/account-filter/account-filter.component";
import {EditSectionComponent} from './page-view/edit-section/edit-section.component';
import {CustomChartFormSeriesTableComponent} from './forms/custom-chart-form/custom-chart-form-series-table/custom-chart-form-series-table.component';
import {CategoryChartComponent} from './charts/category-chart/category-chart.component';
import {CategoryChartFormComponent} from './forms/category-chart-form/category-chart-form.component';
import {AngularSplitModule} from 'angular-split';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        AuditPivot,
        SideNavigation,
        TreeNodeComponent,
        CalculationCheckTableComponent,
        EngineeringUnitView,
        UserListComponent,
        RegisterUserView,
        SelectManySearchComponent,
        UserFormComponent,
        TileFormComponent,
        ChartDialog,
        PageViewFormComponent,
        SeriesFormComponent,
        UserListComponent,
        CollectorEventsTableComponent,
        ValueDriverTreeTableComponent,
        PivotCtrl,
        HistoryComponent,
        DateTimePeriodComponent,
        HeaderComponent,
        ScrollDirective,
        StreamSheetFocusViewCtrl,
        StreamSheetViewCtrl,
        QuickChartsComponent,
        SelectSearchComponent,
        RemoveUnderscorePipe,
        SignificantNumberPipe,
        AbbreviateNumber,
        ThousandsSeparatePipe,
        NameAndDescriptionPipe,
        NameOrDescriptionPipe,
        FilterByNamePipe,
        GenericChartComponent,
        SelectSearchComponent,
        CollectorMapperSheetView,
        EquipmentSheetViewCtrl,
        EquipmentSheetFocusViewCtrl,
        CustomEquipmentViewCtrl,
        ShiftSheetViewCtrl,
        StockpileSheetViewCtrl,
        ScheduleSheetViewCtrl,
        ScheduleSheetFocusViewCtrl,
        SpcChartComponent,
        BudgetChartComponent,
        DefaultChartComponent,
        ComparisonChartFormComponent,
        PageViewComponent,
        PageTileComponent,
        DownloadFormDialogComponent,
        SeriesComponentSheetViewCtrl,
        SeriesComponentSheetFocusViewCtrl,
        SeriesTableComponent,
        SeriesTableFocusViewComponent,
        UploadFormDialogComponent,
        ProcessSheetViewCtrl,
        CustomProcessViewCtrl,
        ProcessSheetFocusViewCtrl,
        EstimateSheetViewCtrl,
        LogSheetFocusViewComponent,
        LogSheetComponent,
        EventSheetViewComponent,
        ResourceSheetViewCtrl,
        ProcessAccessSheetViewCtrl,
        SortableGridListComponent,
        SeriesSheetViewCtrl,
        SeriesSheetFocusViewCtrl,
        SeriesSheetFocusViewCtrl,
        FlowChartComponent,
        flowchartFocusViewCtrl,
        FlowchartSeriesComponentComponent,
        WaterBalanceComponent,
        EstimateDataSheetComponent,
        EstimateDataSheetViewComponent,
        EstimateDataSheetFocusViewComponent,
        EditHistoryComponent,
        EstimateFormComponent,
        InputDataSheetFocusViewComponent,
        InputDataSheetComponent,
        CommentDrawerComponent,
        EventPivotComponent,
        CustomChart,
        LogSheetFormComponent,
        SeriesTableFormComponent,
        WeatherWidgetComponent,
        ColourPaletteComponent,
        InlineStyleMenuComponent,
        CustomEventsFormComponent,
        ColourPaletteComponent,
        MySecurityComponent,
        ResetPasswordComponent,
        InputDataSheetFormComponent,
        FlowchartFormComponent,
        FlowchartProcessFormComponent,
        FlowchartStreamFormComponent,
        FlowchartEquipmentFormComponent,
        ChartProcessDetailComponent,
        SeriesComponentTableComponent,
        ConstantComponentTableComponent,
        ConstantSeriesDataComponent,
        EventsComponent,
        DetailRowDirective,
        WaterBalanceDetailViewComponent,
        WaterBalanceChartComponent,
        ValueDriverTreeComponent,
        ApplyCorrectionComponent,
        PivotTileComponent,
        PivotTileFormComponent,
        SparklineComponent,
        RecoveryGradeForecastComponent,
        OreForecastViewComponent,
        PageListViewComponent,
        PageListComponent,
        AppPasswordDirective,
        RecoveryGradeForecastFormComponent,
        ParagraphComponent,
        JsonTableComponent,
        AuditHistoryComponent,
        CustomComponent,
        WelcomeComponent,
        HelpPageComponent,
        ContextComponent,
        ContextFormComponent,
        MentionDirective,
        ScrollableDirective,
        AtWhoComponent,
        CustomChartFormDialogComponent,
        ContextFormDialogComponent,
        PrintFormatterComponent,
        AccountPickerComponent,
        AccountFilterComponent,
        ParagraphFormComponent,
        ExceptionOnDemandComponent,
        FlowchartImageFormComponent,
        EditSectionComponent,
        CustomChartFormSeriesTableComponent,
        CategoryChartComponent,
        CategoryChartFormComponent,
    ],
    imports: [
        BrowserModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MaterialModule,
        NgPipesModule,
        ReactiveFormsModule,
        DragDropModule,
        QuillModule,
        NgAggregatePipesModule,
        NgArrayPipesModule,
        NgBooleanPipesModule,
        NgMathPipesModule,
        NgObjectPipesModule,
        NgStringPipesModule,
        VirtualScrollerModule,
        AngularSplitModule.forRoot()
    ],
    bootstrap: [AppComponent],
    providers: [
        HttpErrorHandler,
        ApiService,
        AuthGuardService,
        AppScope,
        UserData,
        SeriesStringParser,
        PlantDataService,
        DateTimePeriodService,
        HandsontableGenerator,
        HeaderDataService,
        EventDataService,
        SeriesDataService,
        PresentationService,
        MenuTreeService,
        InputDataService,
        CachingService,
    ],
    entryComponents: [
        DateTimePeriodComponent,
        DownloadFormDialogComponent,
        LogSheetComponent,
        PageViewFormComponent,
        SeriesFormComponent,
        SideNavigation,
        TileFormComponent,
        ParagraphFormComponent,
        ChartDialog,
        TreeNodeComponent,
        FlowChartComponent,
        UploadFormDialogComponent,
        UserFormComponent,
        EstimateDataSheetComponent,
        EditHistoryComponent,
        EstimateFormComponent,
        SeriesTableFormComponent,
        FlowchartFormComponent,
        ValueDriverTreeComponent,
        RecoveryGradeForecastFormComponent,
        CustomChartFormDialogComponent,
        ContextFormDialogComponent
    ],
})
export class AppModule {
    constructor() {
    }
}
