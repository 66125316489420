/**
 * Created by phillip on 2016/06/18.
 */
/**
 * Created by phillip on 2016/06/13.
 */

import * as Handsontable from "handsontable"
import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {AppScope} from "../services/app_scope.service";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {HeaderDataService} from "../services/header_data.service";
import {HotInstance} from "../services/hot-instance";

@Component({
    selector: 'processSheetView',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class ProcessSheetViewCtrl implements OnInit {
    hot: HotInstance;
    @Input()
    parent: any;
    // TODO add setters to these inputs to detect when they have changed
    @Input()
    processes: any;
    @Input()
    allProcesses: any;
    @Input()
    propertyCategories: any;
    title: string;
    revisions: any;
    data: any;
    search: string;

    users: any[];
    series_light: any[];
    component_types: any[];
    schema: any;
    column_list: any[];

    constructor(private api: ApiService,
                private appScope: AppScope,
                private handsontableGenerator: HandsontableGenerator,
                private headerData: HeaderDataService) {
        this.hot = new HotInstance();
    }

    ngOnInit(): void {
        const ctrl = this;
        if (ctrl.parent) {
            ctrl.appScope.page_title = 'Processes: ' + ctrl.parent.attributes.name;
        } else {
            ctrl.appScope.page_title = "Processes";
        }
        ctrl.title = 'Processes';

        const promises = [];
        promises.push(ctrl.api.revision.search().toPromise().then(response => ctrl.revisions = response.data));

        // TODO validate that the processess and allProcesses works over all 4 permutation of data being passed in
        if (ctrl.processes == null) {
            promises.push(ctrl.api.process.search().toPromise().then(response => {
                ctrl.data = response.data;
                ctrl.allProcesses = response.data
            }));
        } else {
            ctrl.data = ctrl.processes;
        }

        if (ctrl.allProcesses == null) {
            ctrl.allProcesses = ctrl.data;
            console.log('ctrl.allProcesses was null, is now ', ctrl.data)
        } else {
            console.log('ctrl.allProcesses was set and is ', ctrl.allProcesses)
        }

        if (ctrl.propertyCategories == null) {
            promises.push(ctrl.api.property_category.search(ctrl.api.prep_q([{
                name: 'name',
                val: ['sdsdfsdf', 'asdasd'],
                op: 'in'
            }], {})).toPromise().then(response => ctrl.propertyCategories = response.data));
        }

        promises.push(ctrl.api.component_type.search(ctrl.api.prep_q([{
            name: 'base_type',
            op: 'eq',
            val: 'process'
        }], {})).toPromise().then(response => ctrl.component_types = response.data));

        promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));
        promises.push(ctrl.api.series_light.search().toPromise().then(response => ctrl.series_light = response.data));

        Promise.all(promises).then(ctrl.createTable.bind(ctrl));

        ctrl.buildHeader();
    }

    createTable() {
        const ctrl = this;

        console.log('creating ProcessSheetViewCtrl table with data', ctrl.data);

        const componentTypeLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.component_types, item => item.attributes.name);
        const revisionLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.revisions, item => item.attributes.name);
        const userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);

        const processLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.allProcesses);
        const seriesLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.series_light);

        ctrl.schema = {
            id: null,
            type: 'process',
            attributes: {
                code: null,
                name: null,
                description: null,
                icon: null,
                created_on: null,
                changed_on: null,
                custom_series: {},
                custom_constants: {}
            },
            relationships: {
                parent: {data: {id: null, type: 'process'}},
                revision: {data: {id: null, type: 'revision'}},
                component_type: {
                    data: {id: null, type: 'component_type'},
                    created_by: {data: {id: null, type: 'users'}},
                    changed_by: {data: {id: null, type: 'users'}}
                }
            }
        };

        if (ctrl.parent) {
            ctrl.schema.relationships.parent.data = {id: ctrl.parent.id, type: 'process'}
        }

        ctrl.column_list = [{
            data: 'attributes.code',
            type: 'text',
            title: 'Code'
        }, {
            data: 'attributes.name',
            type: 'text',
            title: 'Name'
        }, {
            data: 'attributes.description',
            type: 'text',
            title: 'Description'
        }];

        ctrl.propertyCategories.forEach(category => {
            Object.keys(category.attributes.custom_constants).map(item => {
                ctrl.schema.attributes.custom_constants[item] = null;
                ctrl.column_list.push({
                    data: 'attributes.custom_constants.' + item,
                    title: item,
                    type: 'text'
                })
            });

            Object.keys(category.attributes.custom_series).map(item => {
                ctrl.schema.attributes.custom_series[item] = null;
                ctrl.column_list.push({
                    data: ctrl.handsontableGenerator.genLookupCustomDataSource(seriesLookups, item),
                    type: 'autocomplete',
                    title: item,
                    trimDropdown: false,
                    strict: true,
                    source: seriesLookups.source,
                    allowInvalid: true
                })
            })
        });

        if (!ctrl.parent) {
            ctrl.column_list.push({
                data: ctrl.handsontableGenerator.genLookupDataSource(processLookups, 'parent'),
                title: 'Parent',
                type: 'autocomplete',
                trimDropdown: false,
                strict: true,
                source: processLookups.source,
                allowInvalid: false
            })
        }

        ctrl.column_list = ctrl.column_list.concat([{
            data: ctrl.handsontableGenerator.genLookupDataSource(revisionLookups, 'revision'),
            title: 'Revision',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: revisionLookups.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(componentTypeLookups, 'component_type'),
            title: 'Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: componentTypeLookups.source,
            allowInvalid: false
        }, {
            data: 'attributes.icon',
            title: 'Icon',
            renderer: coverRenderer
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'created_by'),
            readOnly: true,
            title: 'Created By'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'changed_by'),
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }]);

        ctrl.hot = ctrl.handsontableGenerator.generateTable(ctrl.api.process, ctrl.schema, ctrl.column_list, ctrl.hot);
        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.data;
        setTimeout(() => ctrl.hot.instance = new Handsontable(document.getElementById(ctrl.hot.handson_id), ctrl.hot.settings), 200)
    }

    save() {
        const ctrl = this;
        const results = ctrl.hot.save();
        ctrl.data = results.data;
    }

    download() {
        const ctrl = this;
        ctrl.hot.download();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = ctrl.appScope.page_title;
        ctrl.headerData.buttons = [
            {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save', params: {}},
            {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download', params: {}}
        ];
    }
}
function coverRenderer(instance, td, row, col, prop, value, cellProperties) {
        let escaped = Handsontable.helper.stringify(value);
        let img;

        if (escaped.indexOf('/') === 0) {
            img = document.createElement('IMG');
            img.src = value;
            img.height = 40;
            img.width = 40;

            // Handsontable.dom.addEvent(img, 'mousedown', function (e) {
            //     //e.preventDefault(); // prevent selection quirk
            // });

            Handsontable.dom.empty(td);
            td.appendChild(img);
        }
        else {
            // render as text
            Handsontable.renderers.TextRenderer.apply(this, arguments);
        }

        return td;
    }