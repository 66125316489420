import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {HeaderDataService} from "../services/header_data.service";
import {HttpClient} from "@angular/common/http";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {takeUntil} from "rxjs/operators";
import {forkJoin, Subject} from "rxjs";
import {ActivatedRoute, Router} from '@angular/router';
import {AppScope} from '../services/app_scope.service';

@Component({
    selector: 'quick-charts',
    templateUrl: 'quick-charts.component.html',
    styleUrls: ['../../../node_modules/c3/c3.css', './spc-chart.component.less',
        '../components/select-search.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class QuickChartsComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    full_series_list: any[] = [];
    chart_type: string = 'Default';
    config = {
        "hide_comments": false,
        "labels": {
            "sub_title": null,
            "title": null,
        },
        "series_list": [
            {
                "axis": "y",
                "colour": null,
                "cumulative": false,
                "name": null,
                "show_limits": false,
                "type": "line"
            }
        ]
    };
    chart_types = [
        'Default',
        'SPC',
        'Budget Bar',
        // 'Operations', TODO ADD THIS CHART TYPE
        'Line',
        'Area',
        'Bar',
        'Pie',
        'Multi Line',
        'Multi Area',
        'Multi Bar'
    ];
    selected_series: any = [];
    forecast: any;
    table_config: any = {};
    configs: any = {};
    chart_config: any;
    ready: boolean = false;
    series_ids: any;

    multi_index: number = 0;

    constructor(private api: ApiService,
                public headerData: HeaderDataService,
                private http: HttpClient,
                private router: Router,
                public dtp: DateTimePeriodService,
                private activatedRoute: ActivatedRoute,
                private appScope: AppScope) {
    }

    ngOnInit(): void {
        const ctrl = this;
        // TODO why is this component initialized twice on page load?
        ctrl.headerData.title = 'Quick Charts';
        ctrl.selected_series = [];
        ctrl.api.series_light.search().pipe(takeUntil(this.onDestroy)).subscribe((result) => {
            ctrl.full_series_list = result.data.filter(series => {
                if (series.type == 'series' || series.type == 'calculation') {
                    return series
                }
            });

            const queryParams = this.activatedRoute.snapshot.queryParams;
            if (queryParams['series_list']) {
                forkJoin([this.appScope.auth_complete.promise, this.dtp.dtp_complete.promise])
                    .pipe(takeUntil(this.onDestroy))
                    .subscribe(() => {
                        this.getConfigFromUrl(queryParams, ctrl.full_series_list);
                        ctrl.ready = true
                    })
            } else {
                ctrl.ready = true;
            }
        });

        ctrl.headerData.downloadDataClicked.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.headerData.openDownloadDialog(ctrl.selected_series);
        });

        ctrl.headerData.show_dtp = true;
        ctrl.headerData.add_refresh = true;
        ctrl.headerData.add_download = true;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    getConfigFromUrl(params: { series_list?, chart_type? }, full_series_list) {
        const ctrl = this;
        let search_series = params['series_list'];
        if (search_series) {
            if (!Array.isArray(search_series)) {
                search_series = [search_series]
            }
            ctrl.selected_series = full_series_list.filter(series => {
                return search_series.includes(series.id)
            });
        }
        let search_chart_type = params['chart_type'];
        if (search_chart_type) {
            ctrl.chart_type = JSON.parse(search_chart_type);
        } else {
            ctrl.chart_type = 'Default'
        }

        ctrl.getConfig();
    }

    getConfig() {
        const ctrl = this;
        if (['Line', 'Bar', 'Area'].includes(ctrl.chart_type)) {

            ctrl.configs = ctrl.selected_series.map(selected_series_element => ({
                series_list: [{
                    name: selected_series_element.attributes.name,
                    axis: 'y',
                    type: ctrl.chart_type.toLocaleLowerCase(),
                    show_limits: false,
                    cumulative: false
                }],
                labels: {
                    title: selected_series_element.attributes.description,
                    subtitle: selected_series_element.attributes.name
                }
            }));
        } else if (['Multi Line', 'Multi Area', 'Multi Bar', 'Pie', 'Gauge', 'Donut'].includes(ctrl.chart_type)) {
            const config_series_list = ctrl.selected_series.map(item => {
                let chart_type = ctrl.chart_type;
                let config_type = '';
                switch (chart_type) {
                    case 'Pie':
                        config_type = 'pie';
                        break;
                    case 'Donut':
                        config_type = 'donut';
                        break;
                    case 'Gauge':
                        config_type = 'gauge';
                        break;
                    default:
                        config_type = ctrl.chart_type.toLocaleLowerCase().substr(6);
                        break
                }
                return {
                    name: item.attributes.name,
                    axis: 'y',
                    type: config_type,
                    show_limits: false,
                    cumulative: false
                }
            });
            ctrl.configs = {
                series_list: config_series_list,
                labels: {
                    title: ctrl.chart_type
                }
            }
        }

    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.selected_series, event.previousIndex, event.currentIndex);
    }

    removeSeries(option) {
        let index = this.selected_series.indexOf(option);
        this.selected_series.splice(index, 1)
    }
}
