<ng-container *ngIf="full_series_list">

  <div class="row">
    <div>
      <mat-form-field class="custom" matTooltip="label for Y axis">
        <mat-label>Y Axis Label</mat-label>
        <input [(ngModel)]="config.labels.y_axis" matInput
        >
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="custom" matTooltip="label for Y axis">
        <mat-label>X Axis Label</mat-label>
        <input [(ngModel)]="config.labels.x_axis" matInput
        >
      </mat-form-field>
    </div>
  </div>

  <button (click)="addSeriesToConfig()" mat-button>Add series</button>
  <div class="column">

    <ng-container *ngFor="let series of config.series_list; let i = index">
      <div class="row">
        <select-search (selectChange)="updateConfig($event,i);"
                       *ngIf="full_series_list?.length>0"
                       [classes]="'fill'"
                       [data]="full_series_list"
                       [value]="getSeriesSelected(series.series_id)"
                       class="select-search"
                       label="Select Series">

        </select-search>

        <div>
          <mat-form-field class="custom" matTooltip="Select a number of periods for this series">
            <mat-label>Number of periods</mat-label>
            <input [(ngModel)]="series.number_of_periods" matInput step="1" type="number">
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="custom" matTooltip="Select custom period for this series">
            <mat-label>Custom Period</mat-label>

            <mat-select [(value)]="series.sample_period" [compareWith]="compareSamplePeriod">
              <mat-option *ngFor="let sample_period of dateTimePeriodService.sample_periods"
                          [value]="sample_period">{{sample_period.name | removeUnderscore | upperfirst}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="custom" matTooltip="Select chart type for this series and period">
            <mat-label>Chart type</mat-label>
            <mat-select [(value)]="series.chart_type">
              <mat-option *ngFor="let chart_type of chart_types"
                          [value]="chart_type">{{chart_type | upperfirst}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div style="min-width:80px; min-height:20px;text-align:center;">
          <label>Colour</label>
          <colour-palette [(value)]="series.color"
                          [palette]="1">
          </colour-palette>
        </div>

        <div>
          <i (click)="deleteSeries(i)" class="fa fa-trash" style="margin-left:5px;"></i>
        </div>
      </div>

    </ng-container>

  </div>

</ng-container>
