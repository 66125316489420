import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DialogData} from "../context-form-dialog/context-form-dialog.component";

export interface DialogData {
    form_result: string;
    config: any;
}

@Component({
    selector: 'src-context-form-dialog',
    templateUrl: './context-form-dialog.component.html',
    styleUrls: ['./context-form-dialog.component.less']
})

export class ContextFormDialogComponent implements OnInit {

    context_config: any;
    title: string = '';
    showing_hints: boolean;
    hint: string;

    constructor(private dialogRef: MatDialogRef<ContextFormDialogComponent>,
                private snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData) {
    }

    ngOnInit() {
        this.context_config = this.dialogData.config;

        if (!this.context_config) {
            this.context_config = {
                title: this.title,
                config: {
                    'selected_series': null,
                    'value_only': false,
                    'series_unit': null,
                    'show_unit': true
                }
            }
        }

        console.log("chart config on init", this.context_config);
    }

    save() {
        if (this.context_config.config.selected_series) {

        } else {
            this.snackBar.open("Please select a series.", "Hide");
            return;
        }

        this.dialogRef.close(this.context_config);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}

