<style>
  .mat-option-disabled {
    color: lightgrey !important;

  }
</style>

<div [ngClass]="{'disabled' : disabled}">
  <div class="row dtp-container">
    <label class="row time-traveler" style="-webkit-align-items: center;">
      <img *ngIf="icons" src='/static/icons/date-period.png' class="date-period-icon"/>
      <span *ngIf="icons">Time traveler</span>
      <i class="chevron"></i>
    </label>

    <ng-container *ngIf="dtp">
      <div class="row range" *ngIf="dateTimePeriodService.show_timespan">
        <label class="row">
          <img *ngIf="icons" src='/static/icons/timespan.png' class="date-period-icon"/>
          <span>Time span</span>
        </label>
        <mat-select [(value)]="dtp.range" placeholder="Select Range" (selectionChange)="changeRange()"
                    [disabled]="disabled" class="range-select">
          <mat-option *ngFor="let range of ranges"
                      [value]="range.range">{{range.range | removeUnderscore | upperfirst}}</mat-option>
          <mat-option value="custom">Custom</mat-option>
        </mat-select>

        <label class="row">
          <span>Period</span>
        </label>
        <ng-container *ngIf="sample_periods">

          <mat-select [(value)]="dtp.sample_period" placeholder="Sample Period" (selectionChange)="changePeriod()"
                      [compareWith]="matSelectCompare" [disabled]="disabled" class="period-select">
            <mat-option *ngFor="let sample_period of sample_periods"
                        [disabled]="!enabled(sample_period)"
                        [value]="sample_period">{{sample_period.name | upperfirst | removeUnderscore}}
            </mat-option>
          </mat-select>
        </ng-container>

      </div>

      <div class="datepicker row">
        <label>Start</label>
        <input *ngIf="dateTimePeriodService.show_time"
               class="datetime"
               name='start'
               type='datetime-local'
               placeholder="Start Date"
               [value]="dtp?.start | date:'yyyy-MM-ddTHH:mm'"
               (change)="changeDate($event)"
               (focusout)="checkDate()"
               [disabled]="disabled"/>
        <input *ngIf="!dateTimePeriodService.show_time"
               class="datetime"
               name='start'
               type='date'
               placeholder="Start Date"
               [value]="dtp?.start | date:'yyyy-MM-dd'"
               (change)="changeDate($event)"
               (focusout)="checkDate()"
               [disabled]="disabled"/>

        <label class="row">End</label>
        <input *ngIf="dateTimePeriodService.show_time"
               class="datetime"
               name='end'
               type='datetime-local'
               placeholder="End Date"
               [value]="dtp?.end | date:'yyyy-MM-ddTHH:mm'"
               (change)="changeDate($event)"
               (focusout)="checkDate()"
               [disabled]="disabled"/>
        <input *ngIf="!dateTimePeriodService.show_time"
               class="datetime"
               name='end'
               type='date'
               placeholder="End Date"
               [value]="dtp?.end | date:'yyyy-MM-dd'"
               (change)="changeDate($event)"
               (focusout)="checkDate()"
               [disabled]="disabled"/>
        <!--        <div *ngIf="!dateTimePeriodService.show_time">-->
        <!--        <input-->
        <!--               class="datetime"-->
        <!--               name='end'-->
        <!--               type='date'-->
        <!--               placeholder="End Date"-->
        <!--               [value]="dtp?.end | date:'yyyy-MM-dd'"-->
        <!--               (change)="changeDate($event)"-->
        <!--               (focusout)="checkDate()"/>-->
        <!--              <span style="position:relative;left:-60px;">{{dtp?.end | date:'HH:mm'}}</span></div>-->

      </div>
      <div class="datetime-pager hide-xs">
        <i class="fa fa-arrow-circle-left" (click)="travel('backwards')"
           matTooltip="Travel one sample period backwards."></i>
        <i class="fa fa-arrow-circle-right" (click)="travel('forward')"
           matTooltip="Travel one sample period forwards."></i>
      </div>
      <div [ngClass]="{'dtp-immutable': immutable}" class="dtp-refresh">
        <i (click)="refreshDTP()" class="fa fa-refresh"></i>
      </div>

    </ng-container>

  </div>
</div>