<mat-dialog-content [ngClass]="{'showing-hints':showing_hints}" class="image-form">
  <mat-tab-group [disableRipple]="true">
    <mat-tab label="Properties">
      <div class="column">
        <div class="row reduce-height">
          <mat-form-field (mouseenter)="hint='Name'" style="flex-basis:45%;flex-grow:0;">
            <mat-label>Title</mat-label>
            <input [(ngModel)]="component_data.title" matInput name="title" placeholder="">
          </mat-form-field>

          <div class="tip-box" style="flex-grow:1;">
            <div [ngClass]="{'showing': hint=='Name'}" class="tip">
              <label>Title</label>
              <div>
                The text that shows when hovering over the image.
              </div>
            </div>
          </div>

        </div>

        <div class="row" style="align-items:center; width: 100%; justify-content:space-between">

          <div class="row" style="margin-top:20px;width:45%;">
            <mat-form-field (mouseenter)="hint='Icon'" style="margin-right:0;">
              <label style="display: flex; align-items: center; margin-bottom: -30px;height: 50px;">Select Icon
                <img *ngIf="component_data.src" height="50px" src="{{ component_data.src }}" width="50px"/></label>
              <!--<label>Select Icon<img src="{{ component_data.attributes.icon }}" width="30px" height="30px"/></label>-->
              <mat-select (selectionChange)="change(component_data.src)" [(ngModel)]="component_data.src"
                          class="image-select" name="process_icon">
                <mat-option *ngFor="let img of images" [value]="img" class="image-options"
                            style="display:flex;align-items: center;">
                  <img height="50px" src="{{ img }}" width="50px"/>
                  <span>{{ img.split("/")[img.split("/").length - 1].split('.')[0] }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="tip-box" style="flex-grow:1;">
              <div [ngClass]="{'showing': hint=='Code'}" class="tip">
                <label>Icon</label>
                <div>
                  Select an image from the available list of previously uploaded images.
                </div>
              </div>
            </div>

          </div>

          <div style="width:10%; text-align:center;margin-top: 40px;font-weight:bold;">OR</div>

          <div style="width:45%;margin-top: 40px;">
            <label class="image-upload-container btn btn-bwm" style="width:100%;">
              <input #imageUpload (change)="processFile()"
                     accept="image/*"
                     style="width:inherit;"
                     type="file">
            </label>
          </div>

        </div>


      </div>
    </mat-tab>
    <mat-tab label="Display">
      <div class="column">

        <div class="row" style="width:300px; margin-top: 20px;">
          <mat-form-field class="inline">
            <label>Width</label>
            <input (change)="getNatural('width')" [(ngModel)]="component_data.width" [max]="1000"
                   [min]="10"
                   [step]="10"
                   matInput
                   name="width"
                   style="width:100px;"
                   type="number">
          </mat-form-field>
          <mat-form-field class="inline">
            <label>Height</label>
            <input (change)="getNatural('height')" [(ngModel)]="component_data.height" [max]="1000"
                   [min]="10"
                   [step]="10"
                   matInput
                   name="height"
                   style="width:100px;"
                   type="number">
          </mat-form-field>

          <mat-checkbox [(ngModel)]="component_data.constrain_proportions">Constrain proportions</mat-checkbox>
        </div>

        <img #selectedImage
             [src]="component_data.src"
             [style.height]="component_data.height + 'px'"
             [style.width]="component_data.width + 'px'"
             style="border: 1px dashed orange; box-sizing:content-box;"
        />
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<div class="action-buttons">
  <button (click)="close()" *ngIf="dialog" class="btn-close">Close</button>
  <button (click)="save()" class="btn-save" style="margin-right:10px;">Save</button>
</div>





