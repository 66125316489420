<div class="reset-password">

  <div class="container form-dialog register-form" style="padding:0 20px;">

    <form class="form-section column space-between" novalidate>
      <div>
        <div>
          <h1>Welcome to WIRE</h1>
          <div>

            You can update your WIRE password here. <br><br>

            If this is your first time logging in to WIRE, please read our password guidelines below

          </div>
        </div>
        <br><br>
        <div class="row reduce-height">

          <mat-form-field>
            <mat-label>Please enter your new password for WIRE</mat-label>

            <input [(ngModel)]="password" class="form-control"
                   id="password"
                   matInput
                   name="password"
                   required
                   type="password"
                   appPassword
            />
          </mat-form-field>
          <!--<div (click)="pass_type==='password'? pass_type='text':pass_type='password'" class="form-field app-password">-->
            <!--<i class='fa fa-eye' [ngClass]="{'fa-eye': pass_type==='password', 'fa-eye-slash':pass_type==='text'}"-->
               <!--matTooltip='Show password'></i>-->
          <!--</div>-->
        </div>

        <div class="row">

          <mat-form-field>
            <mat-label>Please confirm your new password below</mat-label>
            <input matInput
                   id="password_check"
                   class="form-control"
                   [(ngModel)]="password_check"
                   name="password_check"
                   required
                   type="password"
                   appPassword
            />

          </mat-form-field>
        </div>

        <div class="row">

          <button mat-button class="bordered-button" type="submit"
                  (click)="submit()">
            Submit
          </button>
        </div>

        <div><span *ngFor="let error of errors" class="help-inline">{{error}}</span>
        </div>

        <div>
          <h4 class="modal-title"> Password guidelines</h4>
          <h5 class="modal-title"> Your password should:</h5>
          <ul>
            <li>Be a minimum of 8 characters including alphabetical letters (A-z) and numbers (0-9)</li>
            <li>Include upper and lower case characters</li>
            <li>Include at least one of the following special characters:</li>
            # ? ! @ $ % ^ & * -
          </ul>

          <h5>OR</h5>
          <h5 class="modal-title"> Your password should:</h5>
          <ul>
            <li>Be a mininum of 15 characters long</li>
          </ul>

        </div>
      </div>
    </form>
  </div>
</div>



