import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {Location} from '@angular/common';
import {MatSnackBar} from "@angular/material";
import {DateTimePeriod, DateTimePeriodService} from "../services/datetime_period.service";
import * as utils from '../lib/utils'
import {AppScope} from "../services/app_scope.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {HeaderDataService} from '../services/header_data.service';
import {ApiService} from "../services/api.service";

@Component({
    selector: 'date-period',
    templateUrl: 'date-period.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DateTimePeriodComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @Input() disabled: boolean = false;
    sample_periods: any[];
    ranges: any[];
    dtp_ready: boolean;
    dtp: DateTimePeriod;
    @Input() icons: boolean = true;
    @Input() immutable: boolean = false;
    @Input() no_url: boolean = false; //set this to true if a change in date should not trigger a url update

    constructor(public dateTimePeriodService: DateTimePeriodService,
                private snackBar: MatSnackBar,
                private appScope: AppScope,
                private location: Location,
                private headerData: HeaderDataService,
                private api: ApiService) {
    }

    ngOnInit(): void {
        let ctrl = this;
        this.dateTimePeriodService.dtpReset
            .pipe(takeUntil(this.onDestroy))
            .subscribe(dtp => {
                this.dtp = dtp
            });

        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            ctrl.dtp = ctrl.dateTimePeriodService.dtp;

            ctrl.sample_periods = utils.deepCopy(ctrl.dateTimePeriodService.sample_periods);
            ctrl.ranges = utils.deepCopy(ctrl.dateTimePeriodService.ranges);
            ctrl.dtp_ready = true;
        });
    }

    refreshDTP() {
        this.api.cancelActiveQueries();
        //TODO update all subscriptions/naming to avoid confusion between these two
        this.dateTimePeriodService.dtpReset.emit(this.dtp);
        this.headerData.dtpReset.emit(this.dtp);
    }

    changeRange() {
        let ctrl = this;
        if (ctrl.dtp.range !== "custom") {
            ctrl.dtp = ctrl.dateTimePeriodService.getDTP(ctrl.dtp.range);
        }
        ctrl.dtp = ctrl.dateTimePeriodService.validateDTP((ctrl.dtp));
        ctrl.dateTimePeriodService.dtp = ctrl.dtp;
        ctrl.dateTimePeriodService.dtpChanged.emit(ctrl.dtp);
        if (!ctrl.no_url) {
            this.updateURL();
        }
    }

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    };

    checkDate() {
        const ctrl = this;
        if (new Date(ctrl.dtp.start) >= new Date(ctrl.dtp.end)) {
            ctrl.dtp.end = new Date(ctrl.dtp.start)['addHours'](ctrl.dtp.sample_period.hours);
            ctrl.dateTimePeriodService.dtp = ctrl.dtp;
            this.snackBar.open("Warning: start date cannot be greater than end date. End date reset.", "Hide")
        }
    }

    changeDate(evt) {
        const ctrl = this;
        if (evt.target.name === 'start') {
            ctrl.dtp.start = new Date(evt.target.value);
        } else if (evt.target.name === 'end') {
            ctrl.dtp.end = new Date(evt.target.value);
        }

        ctrl.dtp.range = "custom";
        ctrl.dateTimePeriodService.dtp = ctrl.dtp;
        ctrl.dateTimePeriodService.dtpChanged.emit(ctrl.dtp);

        if (!ctrl.no_url) {
            this.updateURL();
        }
    }

    changePeriod() {
        let ctrl = this;
        ctrl.dtp.range = "custom";

        if (ctrl.dtp.sample_period.name == 'day') {
            ctrl.dtp = ctrl.dateTimePeriodService.setToStart(ctrl.dtp);

            if ((Math.round(ctrl.dtp.end.valueOf() - ctrl.dtp.start.valueOf())) / (1000 * 60 * 60 * 24) < 1) {
                ctrl.dtp.start = utils.deepCopy(ctrl.dtp.end);
                ctrl.dtp.start.setDate(ctrl.dtp.start.getDate() - (1));
            }
        }

        if (ctrl.dtp.sample_period.name == 'week') {
            let dayOfMonth = ctrl.dtp.end.getDate();
            const dayOfWeek = ctrl.dtp.end.getDay();
            dayOfMonth = dayOfMonth - (dayOfWeek - ctrl.appScope.config_name_map.week_start.value);
            ctrl.dtp.end.setDate(dayOfMonth);
            ctrl.dtp = ctrl.dateTimePeriodService.setToStart(ctrl.dtp);

            let weeks = (ctrl.dtp.end.valueOf() - ctrl.dtp.start.valueOf()) / (1000 * 60 * 60 * 24 * 7);
            if (!Number.isInteger(weeks)) {
                weeks = Math.floor(weeks) + 1;
            }
            ctrl.dtp.start = utils.deepCopy(ctrl.dtp.end);
            ctrl.dtp.start.setDate(ctrl.dtp.start.getDate() - (7 * weeks))
        }

        if (ctrl.dtp.sample_period.name == 'month') {
            ctrl.dtp.end.setDate(1);

            ctrl.dtp.start.setDate(1);
            ctrl.dtp = ctrl.dateTimePeriodService.setToStart(ctrl.dtp);

            if (ctrl.dtp.start.getMonth() == ctrl.dtp.end.getMonth() && ctrl.dtp.start.getFullYear() == ctrl.dtp.end.getFullYear()) {
                ctrl.dtp.start.setMonth(ctrl.dtp.start.getMonth() - 1)
            }
        }

        ctrl.dateTimePeriodService.dtp = ctrl.dtp;
        ctrl.dateTimePeriodService.dtpChanged.emit(ctrl.dtp);

        if (!ctrl.no_url) {
            this.updateURL();
        }
    }

    travel(direction) {
        const ctrl = this;
        if (direction === 'forward') {
            ctrl.dtp.start = new Date(ctrl.dtp.start)['addHours'](ctrl.dtp.sample_period.hours);
            ctrl.dtp.end = new Date(ctrl.dtp.end)['addHours'](ctrl.dtp.sample_period.hours);
            ctrl.dateTimePeriodService.dtp = ctrl.dtp;
        } else {
            ctrl.dtp.start = new Date(ctrl.dtp.start)['addHours'](-ctrl.dtp.sample_period.hours);
            ctrl.dtp.end = new Date(ctrl.dtp.end)['addHours'](-ctrl.dtp.sample_period.hours);
            ctrl.dateTimePeriodService.dtp = ctrl.dtp;
        }

        ctrl.dateTimePeriodService.dtpChanged.emit(ctrl.dtp);
    }

    showDatePickerOnly() {
        const ctrl = this;
        if (!ctrl.dtp) {
            return false;
        }
        return ctrl.dtp.sample_period.name == 'day' || ctrl.dtp.sample_period.name == 'week' || ctrl.dtp.sample_period.name == 'month';
    }

    dateFilter(date) {
        const ctrl = this;

        if (ctrl.dtp.sample_period.name == 'day') {
            return true;
        }

        if (ctrl.dtp.sample_period.name == 'week') {
            return date.getDay() == ctrl.appScope.config_name_map.week_start.value;
        }

        if (ctrl.dtp.sample_period.name == 'month') {
            return date.getDate() == 1;
        }
    }

    updateURL() {
        const ctrl = this;
        //create link based on current selections
        //console.log(location);
        let search = {
            'start': new Date(ctrl.dtp.start).toISOString(),
            'end': new Date(ctrl.dtp.end).toISOString(),
            'range': ctrl.dtp.range,
            'sample_period': ctrl.dtp.sample_period
        };

        this.location.replaceState(location.pathname + '?dtp=' + JSON.stringify(search));
    }

    enabled(sample_period: any) {
        // TODO disable there options on sites that don't have their own historians
        // let disabled = ['points', 'minute', '5 minute', '10 minute', '30 minute'];
        return true;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}