/**
 * Created by phillip on 2016/05/26.
 */

import * as utils from '../lib/utils';
import {EventEmitter, Injectable, Output} from "@angular/core";
import {ApiService} from "./api.service";
import {AppScope} from "./app_scope.service";
import {MatSnackBar} from "@angular/material";
import {BehaviorSubject, Observable} from "rxjs";
import {FlatPromise} from '../lib/utils';

@Injectable()
export class EventDataService {
    event_type_list: any[] = [];
    eventType: any;
    commentType: any;
    downtime_type_list: any = [];
    current_user: any;
    components: any;
    series_list: any[] = [];
    new_event: any;
    clean_new_event: any;
    modalOpen: boolean = false;

    start: Date;
    end: Date;

    events: any[];
    show_comments: BehaviorSubject<boolean>;

    @Output() eventAdded = new EventEmitter();
    @Output() toggleComments = new EventEmitter();

    constructor(private api: ApiService,
                private snackBar: MatSnackBar,
                private appScope: AppScope) {
        let ctrl = this;
        this.show_comments = new BehaviorSubject<boolean>(false);

        utils.sortObjectsByProperty(ctrl.downtime_type_list, 'name');

        appScope.auth_complete.promise.then(() => {
            api.component.search().subscribe(response => {
                this.components = response.data;
            });

            api.event_type.search().toPromise().then(response => {
                const event_types = response.data;
                event_types.map(type => {
                    if (type.attributes.base_type === "event") {
                        ctrl.event_type_list.push(type);
                        if (type.attributes.name == "Event") {
                            ctrl.eventType = type;
                        }
                    }
                    if (type.attributes.base_type === "comment") {
                        if (type.attributes.name == "Comment" || type.attributes.name == "comment") {
                            ctrl.commentType = type;
                        }
                    } else if (type.attributes.base_type === "downtime") {
                        ctrl.downtime_type_list.push(type)
                    }
                });
            });

            ctrl.current_user = appScope.current_user;
        });

        ctrl.new_event = {
            attributes: {
                start: new Date(),
                end: new Date(),
                base_type: 'event',
                comment: null
            },
            type: 'event',
            relationships: {
                components: {
                    data: []
                },
                series_list: {
                    data: []
                },
                event_type: {
                    data: {
                        id: "",
                        type: "event_type"
                    }
                }
            }
        };

        ctrl.clean_new_event = {...ctrl.new_event};
    }

    public getShowComments(): Observable<boolean> {
        return this.show_comments.asObservable();
    }

    public setShowComments(newValue: boolean): void {
        this.show_comments.next(newValue);
    }

    getEvents(start?: Date, end?: Date, series_list?, components?): Observable<any> {
        const eventData = this;

        if (start != undefined) {
            eventData.start = start;
        }

        if (end != undefined) {
            eventData.end = end;
        }

        const ev_query: any[] = [{
            "or": [{"and": [{name: 'start', op: 'gte', val: start}, {name: 'start', op: 'lte', val: end}]},
                {"and": [{name: 'end', op: 'gte', val: start}, {name: 'end', op: 'lte', val: end}]}]
        }];

        let series_query = {};
        let component_query = {};

        if (series_list != undefined && series_list.length < 100) {
            series_query = {
                name: 'series_list', op: 'any', 'val': {
                    op: 'in', name: 'id', 'val': series_list.map(item => item.id)
                }
            };
            if (components != undefined) {
                component_query = {
                    name: 'components', op: 'any', val: {
                        op: 'in', name: 'id', val: components.map(item => item.id)
                    }
                };
                ev_query.push({"or": [series_query, component_query]})
            } else {
                ev_query.push(series_query);
            }
        } else {
            if (components != undefined) {
                component_query = {
                    name: 'components', op: 'any', val: {
                        op: 'in', name: 'id', val: components.map(item => item.id)
                    }
                };
                ev_query.push(component_query);
            }
        }
        return eventData.api.event.search(eventData.api.prep_q(ev_query, {sort: 'start'}));
    }

    addComment(ev) {
        const ctrl = this;
        ev.relationships.components.data.forEach(item => item.type = 'component');

        ev.relationships.series_list.data.forEach(item => item.type = 'series');

        console.log(ev.type + ", " + ev.attributes.base_type);
        if (ev.type == undefined) {
            ev.type = 'event';
            ev.attributes.base_type = 'event';
            ev.relationships.event_type.data = this.eventType;
        }

        let new_comment = this.api[ev.type].save(ev);

        new_comment.then((item) => {
            ctrl.snackBar.open("Comment saved.", null, {duration: 3000});
            this.eventAdded.emit(item.data)
        });

        return new_comment;
    }

    addInlineComment(comment, start, end, series_list) {
        let eventData = this;
        if (!Array.isArray(series_list)) {
            series_list = [series_list];
        }
        if (!start) {
            eventData.snackBar.open("Please select a time period for this event.", "Hide", {
                panelClass: 'warning'
            });
            return (new FlatPromise).promise;
        } else if (!end) {
            eventData.snackBar.open("Please select a time period for this event.", "Hide", {
                panelClass: 'warning'
            });
            return (new FlatPromise()).promise;
        } else if (!comment) {
            eventData.snackBar.open("Please add a comment.", "Hide", {
                panelClass: 'warning'
            });
            return (new FlatPromise()).promise;
        } else {
            let ev = utils.deepCopy(eventData.clean_new_event);
            ev.attributes.start = start;
            ev.attributes.end = end;
            ev.attributes.comment = comment;
            ev.type = 'comment';
            ev.attributes.base_type = 'event';
            ev.relationships.event_type.data = this.commentType;
            ev.relationships.series_list.data = series_list.map((series) => {
                return {'id': series.id, type: 'series'};
            });
            return eventData.addComment(ev);
        }
    }
}
