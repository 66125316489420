/**
 * Created by phillip on 2016/06/18.
 */

import * as Handsontable from "handsontable"
import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {ApiService} from "../services/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {HotInstance} from "../services/hot-instance";

/**
 * Assigned from construction <code>locals</code> options...
 */
@Component({
    selector: 'streamSheetView',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class StreamSheetViewCtrl implements OnInit {
    hot: HotInstance;
    title: string = 'Streams';
    search: string;
    // TODO add setters and cater for when the inputs change
    @Input()
    processes: any;
    @Input()
    process: any;
    @Input()
    streams: any;

    users: any;
    revisions: any;
    component_types: any;
    property_categories: any;
    series_light: any;

    schema: any = {
        id: null,
        type: 'stream',
        attributes: {
            base_type: 'stream',
            name: null,
            description: null,
            code: null,
            created_on: null,
            changed_on: null,
            custom_series: {},
            custom_constants: {}
        },
        relationships: {
            start: {data: {id: null, type: 'process'}},
            end: {data: {id: null, type: 'process'}},
            created_by: {data: {id: null, type: 'users'}},
            changed_by: {data: {id: null, type: 'users'}},
            component_type: {data: {id: null, type: 'component_type'}}
        }
    };
    column_list: any[] = [{
        data: 'attributes.code',
        title: 'Code'
    }, {
        data: 'attributes.name',
        title: 'Name'
    }, {
        data: 'attributes.description',
        title: 'Description'
    }];

    constructor(private api: ApiService,
                private handsontableGenerator: HandsontableGenerator,
                private headerData: HeaderDataService) {
        this.hot = new HotInstance();
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.headerData.buttons = [];

        const promises = [];

        // TODO test both cases in this if (ie. when ctrl.processes are not data bound from another component)
        if (ctrl.processes == null) {
            promises.push(ctrl.api.process_light.search().toPromise().then(processes => ctrl.processes = processes.data));
        }

        // TODO test both cases in this if (ie. when ctrl.streams are not data bound from another component)
        if (!ctrl.streams) {
            promises.push(ctrl.api.stream.search().toPromise().then(response => ctrl.streams = response.data));
        }

        promises.push(ctrl.api.component_type.search(ctrl.api.prep_q([{
            name: 'base_type',
            op: 'eq',
            val: 'stream'
        }], {})).toPromise().then(response => ctrl.component_types = response.data));

        promises.push(ctrl.api.property_category.search(ctrl.api.prep_q([{
            name: 'name',
            val: ['Solution'],
            op: 'in'
        }], {})).toPromise().then(response => ctrl.property_categories = response.data));

        promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));
        promises.push(ctrl.api.revision.search().toPromise().then(response => ctrl.revisions = response.data));
        promises.push(ctrl.api.series_light.search().toPromise().then(response => ctrl.series_light = response.data));

        Promise.all(promises).then(ctrl.createTable.bind(ctrl));

        ctrl.buildHeader();
    }

    createTable() {
        const ctrl = this;

        let componentTypeLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.component_types, item => item.attributes.name);
        let seriesLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.series_light);
        let revisionLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.revisions, item => item.attributes.name);
        let processLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.processes, item => item.attributes.name + '-' + item.attributes.description);
        let userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);

        ctrl.property_categories.forEach(category => {
            Object.keys(category.attributes.custom_constants).map(item => {
                ctrl.schema.attributes.custom_constants[item] = null;
                ctrl.column_list.push({
                    data: 'attributes.custom_constants.' + item,
                    title: item,
                    type: 'text'
                })
            });
            Object.keys(category.attributes.custom_series).map(item => {
                ctrl.schema.attributes.custom_series[item] = null;
                ctrl.column_list.push({
                    data: ctrl.handsontableGenerator.genLookupCustomDataSource(seriesLookups, item),
                    type: 'autocomplete',
                    title: item,
                    trimDropdown: false,
                    strict: true,
                    source: seriesLookups.source,
                    allowInvalid: true
                })
            })
        });

        ctrl.column_list.push.apply(ctrl.column_list, [{
            data: ctrl.handsontableGenerator.genLookupDataSource(revisionLookups, 'revision'),
            title: 'Revision',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: revisionLookups.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(componentTypeLookups, 'component_type'),
            title: 'Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: componentTypeLookups.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(processLookups, 'start'),
            title: 'Start',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: processLookups.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(processLookups, 'end'),
            title: 'End',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: processLookups.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'created_by'),
            readOnly: true,
            title: 'Created By'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'changed_by'),
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On'
        }]);

        ctrl.hot = ctrl.handsontableGenerator.generateTable(ctrl.api.stream, ctrl.schema, ctrl.column_list);
        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.streams;
        setTimeout(() => ctrl.hot.instance = new Handsontable(document.getElementById(ctrl.hot.handson_id), ctrl.hot.settings), 200)
    }

    save() {
        const ctrl = this;
        let results = ctrl.hot.save();
        ctrl.streams = results.data;
    }

    download() {
        const ctrl = this;
        // TODO what is this download doing without specifying a file to download?
        // @ts-ignore
        ctrl.hot.download();
    }

    buildHeader() {
        const ctrl = this;
        if (ctrl.process) {
            ctrl.headerData.title = 'Streams: ' + ctrl.process.attributes.name;
        } else {
            ctrl.headerData.title = 'Streams';
        }
        ctrl.headerData.buttons = [
            {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save', params: {}},
            {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download', params: {}}
        ];
    }
}
