<table class="table-bordered table-responsive water-balance-table" style="width: 100%;">
  <tr>
    <th>Stream</th>
    <th>Series</th>
    <th>Type</th>
    <th>Flow m3</th>
    <th>F/cast m3/hr</th>
  </tr>
  <tr *ngFor="let stream of row.input_streams.concat(row.output_streams)">
    <ng-container *ngIf="stream.Type !== 'Split'">
      <td style="text-align: left; max-width: 200px; overflow-wrap: break-word;">{{ stream.Name }}</td>
      <td style="text-align: left">{{ stream.SeriesName }}</td>
      <td style="text-align: left">{{ stream.Type }}</td>
      <td style="text-align: right">{{ stream.Value | number:'0.0-0'}}</td>
      <td style="text-align: right">
        <div *ngIf="stream.Edit">
          <!--                  ng-model-options="{ debounce: 1000 }" -->
          <input [disabled]="!stream.Edit"
                 type="number"
                 style="width: 100%;"
                 (ngModelChange)="updateDFs(row, stream)"
                 [(ngModel)]="stream.Estimate"/>
        </div>
        <div *ngIf="!stream.Edit"> {{ stream.Estimate | number: '2.0-0'}}</div>
      </td>
    </ng-container>
    <ng-container *ngIf="stream.splits !== undefined && stream.splits.length">
      <td style="text-align: center">{{ stream.Name }} splits</td>
      <td colspan="4">
        <table class="table-bordered table-responsive water-balance-table">
          <tr>
            <th>Split</th>
            <th>Series</th>
            <th>Split Fraction</th>
            <th>Flow m3</th>
            <th>F/cast m3/hr</th>
          </tr>
          <tr *ngFor="let split of stream.splits">
            <td>{{ split.Name }}</td>
            <td>{{ split.SeriesName }}</td>
            <td style="text-align: right;">
              <div>
                <!--                        ng-model-options="{ debounce: 1000 }" -->
                <input type="number"
                       (ngModelChange)="updateDFs(row, split,stream)"
                       [(ngModel)]="split.SplitFraction"/>
              </div>
            <td style="text-align: right;">{{ split.Value | number:'0.0-0'}}</td>
            <td style="text-align: right;">
              <div *ngIf="split.Edit">
                <!--                        [ngModelOptions]="{ 'debounce': 1000 }" -->
                <input [disabled]="!split.Edit"
                       type="number"
                       style="width: 100%;"
                       (ngModelChange)="updateDFs(row, split, stream)"
                       [(ngModel)]="split.Estimate"/>
              </div>
              <div *ngIf="!split.Edit" [textContent]="split.Estimate | number: '2.2-0'"></div>
            </td>
          </tr>
        </table>
      </td>
    </ng-container>
</table>

<!--&lt;!&ndash;<div style="display: flex">&ndash;&gt;-->
<!--&lt;!&ndash;  <div style="flex: 5;">&ndash;&gt;-->
<!--    <table class="table-bordered table-responsive water-balance-table">-->
<!--      <thead>-->
<!--      <th>Stream</th>-->
<!--      <th>Series</th>-->
<!--      <th>Type</th>-->
<!--      <th>Flow m3</th>-->
<!--      <th>F/cast m3/hr</th>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--&lt;!&ndash;      <tbody *ngFor="let stream of row.input_streams.concat(row.output_streams)">&ndash;&gt;-->
<!--<tr>-->
<!--  <td>name</td>-->
<!--  <td>series name</td>-->
<!--  <td>type</td>-->
<!--  <td>1.15</td>-->
<!--  <td><input type="text"></td>-->
<!--</tr>-->
<!--&lt;!&ndash;      <tr *ngIf="stream.Type !== 'Split'">&ndash;&gt;-->
<!--&lt;!&ndash;        <td>{{ stream.Name }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>{{ stream.SeriesName }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td>{{ stream.Type }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td align="right">{{ stream.Value | number:0}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        <td align="right">&ndash;&gt;-->
<!--&lt;!&ndash;          <div ng-if="stream.Edit"><input ng-disabled="!stream.Edit" type="number"&ndash;&gt;-->
<!--&lt;!&ndash;                                          ng-change="$ctrl.updateDFs(row, stream)"&ndash;&gt;-->
<!--&lt;!&ndash;                                          ng-model-options="{ debounce: 1000 }" ng-model="stream.Estimate"></div>&ndash;&gt;-->

<!--&lt;!&ndash;          <div ng-if="!stream.Edit"> {{ stream.Estimate | number: 2}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;        </td>&ndash;&gt;-->
<!--&lt;!&ndash;      </tr>&ndash;&gt;-->
<!--      </tbody>-->
<!--    </table>-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--&lt;!&ndash;  <div style="flex: 8;">&ndash;&gt;-->
<!--&lt;!&ndash;    <water-balance-chart style="width:30em"&ndash;&gt;-->
<!--&lt;!&ndash;                         [config]="row.chart.config"&ndash;&gt;-->
<!--&lt;!&ndash;                         [columns]="row.chart.columns">&ndash;&gt;-->
<!--&lt;!&ndash;    </water-balance-chart>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->





