/**
 * Created by phillip on 2016/06/17.
 */

import * as Handsontable from "handsontable"
import {contextMenu} from "handsontable"
import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../services/api.service";
import {MatDialog, MatSnackBar} from "@angular/material";
import {HttpClient} from "@angular/common/http";
import {SeriesDataService} from "../services/series_data.service";
import {PlantDataService} from "../services/plant_data.service";
import {DateTimePeriod, DateTimePeriodService} from "../services/datetime_period.service";
import {HeaderDataService} from "../services/header_data.service";
import {stringify} from "querystring";
import * as utils from '../lib/utils';
import {Subject, Subscription} from "rxjs";
import {TileDataService} from "../services/tile_data.service";
import {UserData} from "../services/user_data.service";
import {AppScope} from "../services/app_scope.service";
import {InputDataService} from "../services/input-data.service";
import {takeUntil} from "rxjs/operators";

/**
 * Renderer for value cells in the handsontable.
 * @param instance Reference to the Handsontable instance
 * @param td
 * @param row Row number
 * @param col Column number
 * @param prop <timestamp number>.attributes.value
 * @param value Value of this cell
 * @param cellProperties
 */
function cellRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);

    const logical_id = instance.getDataAtRowProp(row, 'id');
    const time = prop.split('.')[0];
    const series = this.inputDataService.series_list.filter(item => item.id === logical_id)[0];
    const cell_data = series[time];
    if (cell_data !== undefined && series[time].is_missing) {
        td.style.background = '#f5f5f5';
    }
}

Date.prototype['addHours'] = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

@Component({
    selector: 'input-data-sheet',
    templateUrl: 'input-data-sheet.component.html',
    providers: [InputDataService],
    host: {
        '(window:resize)': 'calculateSize($event)', //This gets destroyed when component using this is destroyed
    },
})
export class InputDataSheetComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    private pageRefreshSubscription: Subscription;

    sample_starts: { "day": any[]; "shift": any[]; "hour": any[]; "two_hour": any[]; };
    @ViewChild('input_sheet', {static: false}) input_sheet: ElementRef;

    private _config: { process: any };
    buttons: { name: string; func: any; params: {}; class: string; HoverOverHint: string; }[];
    private permissions: any | {};

    @Input()
    set config(config: any) {
        this._config = config;
    }

    get config() {
        return this._config;
    }

    super_user: any;

    inputSheetSettings: Handsontable.DefaultSettings;
    hotInstance: Handsontable;
    hot_ready: boolean;
    save_allowed: boolean;
    columns: any[];
    window_height: number;
    sheetheight: any;

    dtp: DateTimePeriod;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private dialog: MatDialog,
                private http: HttpClient,
                private snackbar: MatSnackBar,
                private seriesData: SeriesDataService,
                private plantData: PlantDataService,
                private tileData: TileDataService,
                private userData: UserData,
                private appScope: AppScope,
                private inputDataService: InputDataService,
                private dateTimePeriodService: DateTimePeriodService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.dateTimePeriodService.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe(dtp => {
            ctrl.dtp = dtp;
            this.refreshTable(dtp);
        });

        ctrl.sample_starts = {
            "day": [],
            "shift": [],
            "hour": [],
            "two_hour": []
        };

        this.super_user = this.userData.checkSuperUser(this.appScope.current_user);

        const process = this.config.process;
        const access = this.plantData.getIsolatedPermissions(process.id);
        const flowsheet = this.plantData.getFlowSheetData(process.id);

        const promises = [];
        promises.push(access.all.promise);
        promises.push(flowsheet.allDataFetched.promise);
        promises.push(ctrl.dateTimePeriodService.dtp_complete.promise);

        Promise.all(promises).then(() => {
            this.permissions = access.permissions;
            ctrl.dtp = ctrl.dateTimePeriodService.dtp;
            this.inputDataService.initialize(process, access, flowsheet);
            this.refreshTable(ctrl.dtp);

            this.setButtons();
        });

        ctrl.window_height = ctrl.get_height();

        //Setting default title for tile
        ctrl.tileData.setDefaultTitle(ctrl.config.process.attributes.name);
    }

    ngOnDestroy(): void {
        if (this.hotInstance) {
            this.hotInstance.destroy();
            this.hotInstance = null;
        }

        if (this.pageRefreshSubscription) {
            this.pageRefreshSubscription.unsubscribe();
        }
        if (this.onDestroy) {
            this.onDestroy.unsubscribe();
        }
    }

    audit(href: string) {
        console.log(`audit(${href})`);
        window.open(href);
    }

    setButtons() {
        const ctrl = this;

        ctrl.buttons = [{
            name: 'Raw audit data',
            func: () => ctrl.audit('/admin/rawdataaudit'),
            params: {},
            class: 'fa small fa-user',
            HoverOverHint: 'Raw audit data'
        }, {
            name: 'Save',
            func: () => ctrl.saveAndUpdate(),
            params: {},
            class: 'fa small fa-floppy-o',
            HoverOverHint: 'Save'
        }];

        if (ctrl.permissions.override_calculations) {

            ctrl.buttons.unshift({
                name: 'Update Calculations',
                func: () => ctrl.overrideCalculations(),
                params: {},
                class: 'fa small fa-calculator',
                HoverOverHint: 'Update calculations'
            })
        }

        if (ctrl.permissions.apply_correction_factor) {

            ctrl.buttons.unshift({
                name: 'Apply correction factor',
                func: () => ctrl.correctionFactor(),
                params: {},
                class: 'fa small fa-eraser',
                HoverOverHint: 'Apply correction factor'
            })
        }
        ctrl.tileData.buttonsChanged.next(ctrl.buttons);
    }

    calculateSize(event) {
        console.log('InputDataSheetComponent - calculateSize: ', 'calc size');
        const ctrl = this;
        if (ctrl.hot_ready) {
            ctrl.window_height = ctrl.get_height();
            ctrl.hotInstance.render();
            ctrl.hotInstance.render();
        }
    }

    get_height() {
        const ctrl = this;
        if (!ctrl.sheetheight) {
            return window.innerHeight - 350;
        } else {
            console.log("Sheet height " + utils.deepCopy(ctrl.sheetheight));
            return 600;
        }
    }

    $onChanges(changes) {
        const ctrl = this;
        console.log('InputDataSheetComponent.$onChanges fired');
        if (!changes.refresh.isFirstChange()) {
            this.refreshTable(this.dtp);
        }
    }

    ngOnChanges() {
        // TODO implement from $onChanges
    }

    overrideCalculations() {
        console.log('overrideCalculations called');
        const ctrl = this;
        ctrl.getCalculations(null)
    }

    getCalculations(override) {
        const ctrl = this;

        let series_list = ctrl.inputDataService.series_list.map(series => series.id);
        ctrl.headerData.getCalculations(ctrl.dtp, series_list, 'hour', 1).then(data => {
            // TODO this could trigger an inconsistent state if the sample period has changed
            ctrl.inputDataService.getInputData(this.dtp);
            ctrl.snackbar.open('Calculations successfully updated', null, {duration: 2000})
        }).catch(reason => ctrl.snackbar.open('Failed to update calculations ' + reason, 'Hide')
        );
    }

    setUpInput() {
        const ctrl = this;
        console.log('setting up input');

        ctrl.save_allowed = ctrl.saveAllowed();

        // ctrl.inputDataService.series_list.forEach(series => {
        //     ctrl.series_name_map[series.attributes.name] = series;
        // });

        // const $input_promise = ctrl.inputDataService.getInputData(this.dtp);

        const schema = utils.deepCopy(ctrl.seriesData.schema);

        schema.attributes.can_edit = null;
        ctrl.columns = [{
            title: 'Description',
            data: 'attributes.description',
            readOnly: true,
            className: "htLeft",
            width: 200,
        }, {
            title: 'Name',
            data: 'attributes.name',
            readOnly: true,
            className: "htLeft",
            width: 200,
        }, {
            title: 'Component',
            data: 'attributes.parent_name',
            readOnly: true,
            className: "htLeft",
            width: 150

        }, {
            title: 'Group',
            data: 'attributes.report_group',
            readOnly: true,
            className: "htLeft",
            width: 150,
        }, {
            title: 'Type',
            data: 'attributes.base_type',
            readOnly: true,
            className: "htLeft",
            width: 100,
        }, {
            title: 'Sample Period',
            data: 'attributes.sample_period',
            readOnly: true,
            className: "htLeft",
            width: 100,
        }
        ];

        const shifts = ctrl.inputDataService.shifts.sort((a, b) => {
            return new Date(a.attributes.start).getTime() - new Date(b.attributes.start).getTime();
        });

        let day = utils.deepCopy(ctrl.dtp.start);

        while (day < ctrl.dtp.end) {
            ctrl.sample_starts.day.push(day);
            day.setHours(0);
            const newDate = new Date(utils.deepCopy(day));
            newDate.setDate(newDate.getDate() + 1);
            day = newDate;
        }

        const date_counter = new Date(ctrl.dtp.start);

        if (ctrl.dtp.sample_period.name === 'month') {
            date_counter.setMonth(date_counter.getMonth() + 1);
        } else {
            date_counter['addHours'](ctrl.dtp.sample_period.hours);
        }

        let hour_counter = 1;

        let prev_day = null;
        let new_day = null;
        let shift_idx = 0;

        while (date_counter <= ctrl.dtp.end) {

            ctrl.sample_starts.hour.push(new Date(date_counter));

            if (date_counter.getHours() % 2 === 0 || date_counter.getHours() === 0) {
                ctrl.sample_starts.two_hour.push(new Date(date_counter));
            }
            // console.log('setting col', date_counter.getTime() + ".attributes.value");
            ctrl.columns.push({
                title: ctrl.dtp.sample_period.format(date_counter, ctrl.dtp),
                data: date_counter.getTime() + ".attributes.value",
                type: 'numeric',
                width: 60,
                renderer: cellRenderer.bind(this),
                format: '0.00',
                // readOnly: true,
                className: "htRight"
            });
            schema[date_counter.getTime()] = {
                type: 'raw_data',
                attributes: {
                    value: null,
                    time_stamp: null,
                    series: null
                }
            };

            prev_day = utils.deepCopy(date_counter);

            if (ctrl.dtp.sample_period.name !== 'month') {
                date_counter['addHours'](ctrl.dtp.sample_period.hours);
            }
            new_day = utils.deepCopy(date_counter);
            if (ctrl.dtp.sample_period.name === 'month') {
                date_counter.setMonth(date_counter.getMonth() + 1);

            } else if (prev_day.getDate() !== new_day.getDate()) {
                hour_counter = 0;
            }

            if (shifts[shift_idx] !== undefined) {
                let shift_end = new Date(shifts[shift_idx].attributes.end);
                let shift_start = new Date(shifts[shift_idx].attributes.start);

                if (shift_start < new Date(ctrl.dtp.start)) {
                    shift_start = new Date(ctrl.dtp.start)
                }

                if (shift_end > new Date(ctrl.dtp.end)) {
                    shift_end = new Date(ctrl.dtp.end)['addHours'](ctrl.dtp.sample_period.hours)
                }
                if (shift_end <= date_counter) {

                    ctrl.sample_starts.shift.push(new Date(shifts[shift_idx].attributes.start));

                    shift_idx++;
                }
            }
            hour_counter++;
        }

        const total_cols = 5 + ((new Date(ctrl.dtp.end).getTime() - new Date(ctrl.dtp.start).getTime()) / 3600000);

        ctrl.inputSheetSettings = {
            columns: ctrl.columns,
            minSpareRows: 0,
            sortIndicator: true,
            columnSorting: true,
            fixedColumnsLeft: 0,
            selectionMode: 'range',
            selectState: true,
            renderAllRows: false,
            contextMenu: {
                callback: (key: string, options: contextMenu.Options) => {
                    options = options[0];
                    const hot = ctrl.hotInstance;
                    if (key === 'edit_series') {

                        const curr_id = options.start.row;
                        const logical_id = hot.getDataAtRowProp(curr_id, 'id');
                        const series = ctrl.inputDataService.series_list.filter(item => item.id === logical_id)[0];
                        ctrl.editSeries(series);
                    }
                }, items: {

                    "edit_series": {
                        name: 'Edit Series'
                    }
                }
            }, afterChange: (changes: [number, string | number, any, any][], source: string) => {
                if (changes) {
                    if (Object.prototype.toString.call(changes[0]) !== '[object Array]') {
                        // @ts-ignore
                        changes = [changes]
                    }
                    changes.forEach(change => {
                        // row, prop, oldVal, newVal
                        if (!ctrl.hot_ready) {
                            return;
                        }
                        const row = change[0];
                        const timestamp = change[1];
                        const value = change[3];
                        const data_row = ctrl.inputDataService.series_name_map[ctrl.hotInstance.getDataAtRowProp(row, "attributes.name")];
                        ctrl.inputDataService.populate_changes(data_row, timestamp, value, this.dtp);
                    });

                }
            },
            data: ctrl.inputDataService.series_data,
            dataSchema: schema,
            dropdownMenu: true,
            maxRows: ctrl.inputDataService.series_data.length,
            viewportColumnRenderingOffset: total_cols,
            // @ts-ignore
            rowHeaders: index => {
                const instance = ctrl.hotInstance;

                if (instance == null) {
                    return index
                } else {
                    const description = instance.getDataAtRowProp(index, 'attributes.name');
                    if (description) {
                        return description
                    } else {
                        return instance.getDataAtRowProp(index, 'attributes.name')
                    }
                }
            },
            rowHeaderWidth: 180,
            filters: true,
            cells: (row, col, prop) => {
                if (!ctrl.hotInstance) {
                    return null;
                }
                let cellProperties = {readOnly: false};
                let propstring;
                if (typeof prop === 'string') {
                    propstring = prop;
                } else {
                    propstring = stringify(prop);
                }
                if (!["attributes.name",
                    "attributes.description",
                    "attributes.parent_name",
                    "attributes.report_group",
                    "attributes.base_type",
                    "attributes.sample_period"].includes(propstring)) {

                    // var row_series = ctrl.hotInput.getDataAtRowProp(row,attributes.name );
                    const visualRowIndex = ctrl.hotInstance.toVisualRow(row);

                    const row_series = ctrl.inputDataService.series_name_map[ctrl.hotInstance.getDataAtRowProp(visualRowIndex, "attributes.name")];
                    // const row_series = ctrl.series_name_map[ctrl.hotInput.getDataAtRowProp(row, "attributes.name")];
                    if (row_series != null) {

                        // TODO reenable this, as the user did not have permissions to edit at the time
                        // if (ctrl.save_allowed && row_series.attributes.can_edit) {
                        cellProperties.readOnly = false;
                        //   // cellProperties.renderer = cellRenderer;
                        // } else {
                        //    cellProperties.readOnly = true;
                        // }
                    }

                } else {
                    cellProperties.readOnly = true;
                }

                return cellProperties;

            }
        };
        console.log('InputDataSheetComponent - input_sheet.nativeElement: ', ctrl.input_sheet.nativeElement);
        if (ctrl.hotInstance) {
            ctrl.hotInstance.updateSettings(ctrl.inputSheetSettings, false);
            // ctrl.hotInstance.destroy();
            // ctrl.hotInstance = null;
        } else {
            ctrl.hotInstance = new Handsontable(ctrl.input_sheet.nativeElement, ctrl.inputSheetSettings);
        }
        this.inputDataService.updates = {};
        this.inputDataService.inserts = {};
        this.inputDataService.deletes = {};

        this.hot_ready = true;

        //ctrl.hotInput = new Handsontable(document.getElementById('input_sheet'), ctrl.inputSheetSettings);
        // ctrl.hotInput.updateSettings(settings, true);
        // })
    }

    editSeries(series) {
        const ctrl = this;
        let $series_full = this.api[series.type].getById(series.id).toPromise();
        $series_full.then(returned => {
            let series_full = returned.data;

            const dialogRef = ctrl.seriesData.upsertSeries(ctrl.inputDataService.process, series_full);
            dialogRef.afterClosed().toPromise().then(response => {
                if (response) {
                    let updated_series;
                    if (response.series) {
                        updated_series = response.series;
                    } else {
                        updated_series = response;
                    }
                    Object.keys(updated_series.attributes).forEach(attr => {
                        series.attributes[attr] = updated_series.attributes[attr];
                    });
                    series.relationships = updated_series.relationships;
                }
            });
        })
    }

    saveAndUpdate() {
        const ctrl = this;

        ctrl.hot_ready = false;
        this.inputDataService.saveAll().then(amount => {
            if (amount > 0) {
                ctrl.hot_ready = true;
                ctrl.snackbar.open('All saved (' + amount + ' changes)', null, {duration: 2000});
            } else {
                ctrl.snackbar.open('No values changed', null, {duration: 2000});
            }
        }, reason => ctrl.snackbar.open(reason, 'Hide'))
        // ctrl.saveAll();
    }

    // loadTable() {
    //     const ctrl = this;
    //     ctrl.series_data = [];
    //     ctrl.series_list_map = {};
    //     ctrl.series_name_map = {};
    //     ctrl.shiftsPromise = ctrl.api.shift.search(ctrl.api.prep_q([{
    //         and: [{
    //             name: 'end',
    //             op: 'gt',
    //             val: ctrl.dtp.start
    //         }, {
    //             name: 'start',
    //             op: 'lte',
    //             val: ctrl.dtp.end
    //         }]
    //     }], {
    //         order_by: [{
    //             "field": 'start',
    //             "direction": 'desc'
    //         }]
    //     })).toPromise().then(response => ctrl.shifts = response.data);
    //
    //     Promise.all([ctrl.access.$promise, ctrl.flowsheet.allDataFetched.promise]).then(() => {
    //         ctrl.permissions = ctrl.access.permissions;
    //         ctrl.buildHeader();
    //
    //         ctrl.series_list = utils.deepCopy(ctrl.flowsheet.selected_series);
    //         ctrl.setUpInput();
    //
    //         ctrl.setButtons()
    //     });
    // }

    correctionFactor() {
        console.log('correctionFactor called');
        const ctrl = this;
        const dialogRef = ctrl.headerData.correctionFactor();
        dialogRef.afterClosed().toPromise().then(response => {
                console.log('correctionFactor close (1), response:', response);
                ctrl.inputDataService.getInputData(this.dtp);
            },
        );
    }

    // populate_changes(data_row, timestamp, value) {
    //     const ctrl = this;
    //     const key = data_row.id + timestamp;
    //     const raw_data_map = this.inputDataService.raw_data_map;
    //     // TODO reimplement this using the service
    //     if (raw_data_map.hasOwnProperty(key)) {
    //         if (isNaN(parseFloat(value))) {
    //             ctrl.deletes[key] = raw_data_map[key];
    //             delete ctrl.inserts[key];
    //             delete ctrl.updates[key];
    //         } else {
    //             ctrl.updates[key] = {
    //                 id: raw_data_map[key],
    //                 type: 'raw_data',
    //                 attributes: {
    //                     value: value,
    //                     time_stamp: timestamp,
    //                     series: data_row.id
    //                 }
    //             };
    //             delete ctrl.inserts[key];
    //             delete ctrl.deletes[key];
    //         }
    //     } else {
    //         if (isNaN(parseFloat(value))) {
    //             // deleting an element which never existed
    //             delete ctrl.inserts[key];
    //             delete ctrl.updates[key];
    //             delete ctrl.deletes[key];
    //         } else {
    //             // adding a new element
    //             ctrl.inserts[key] = {
    //                 type: 'raw_data',
    //                 attributes: {
    //                     value: value,
    //                     time_stamp: timestamp,
    //                     series: data_row.id
    //                 }
    //             };
    //             delete ctrl.updates[key];
    //             delete ctrl.deletes[key];
    //         }
    //     }
    // }

    isToday(date) {
        const today = new Date();

        if (typeof date == "string") {
            date = new Date(date);
        }

        return date.getFullYear() == today.getFullYear() &&
            date.getMonth() == today.getMonth() &&
            date.getDate() == today.getDate();
    }

    saveAllowed(): boolean {
        const ctrl = this;

        if (ctrl.dtp.sample_period.hours > 1) {
            return false;
        }

        if (ctrl.super_user) {
            return true;
        }

        if (ctrl.permissions.edit_process_data) {
            return true;
        }

        if (ctrl.permissions.edit_todays_data) {
            return ctrl.isToday(ctrl.dtp.start) && ctrl.isToday(ctrl.dtp.end);
        }

        return false;
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.add_upload = true;
        ctrl.headerData.component_buttons_loaded = true;
    }

    private refreshTable(dtp: DateTimePeriod): void {
        if (this.pageRefreshSubscription) {
            this.pageRefreshSubscription.unsubscribe();
            this.pageRefreshSubscription = null;
        }

        this.hot_ready = false;

        if (utils.dayDifference(dtp.end, dtp.start) > 5 &&
            dtp.sample_period.hours < 4) {
            if (!confirm("This could take a long time consider increasing the sample period")) {
                return;
            }
        }
        this.pageRefreshSubscription = this.inputDataService.refresh(this.dtp).subscribe(() => {
            this.setUpInput();
        });
    };
}
