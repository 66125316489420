import {Component, OnDestroy, OnInit} from "@angular/core";
import {PlantDataService} from "../services/plant_data.service";
import {HeaderDataService} from "../services/header_data.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: 'seriesComponentSheetFocusView',
    template: '<seriesComponentSheetView *ngIf="sheet_ready"  ' +
    '[components]="components" ' +
    '[seriescomponents]="series_components" ' +
    '[process]="process"></seriesComponentSheetView>'
})
export class SeriesComponentSheetFocusViewCtrl implements OnInit, OnDestroy {
    process: any;
    components: any[];
    series_components: any;
    sheet_ready: boolean;
    flowsheet: any;
    params_subscription: Subscription;

    constructor(private plantData: PlantDataService,
                private headerData: HeaderDataService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.params_subscription = ctrl.route.params.subscribe(params => {
            ctrl.sheet_ready = false;
            ctrl.flowsheet = ctrl.plantData.getFlowSheetData(params.processID);
            ctrl.flowsheet.allDataFetched.promise.then(ctrl.setupEditSheet.bind(ctrl))

        });
    }

    ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
    }

    setupEditSheet() {
        const ctrl = this;
        ctrl.process = ctrl.flowsheet.process_focus;
        ctrl.components = ctrl.flowsheet.components;
        ctrl.series_components = ctrl.flowsheet.series_components;
        ctrl.sheet_ready = true;
    };

}
