<style>
  .handsontable table thead th {
    white-space: pre-line;
  }

  #input_sheet_div {
    /*height: 500px;*/
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .handsontable .relative {
    position: relative;
    text-align: left;
  }

</style>
<!--This should allow hot to be smaller but not extend passed screen height when more content (as per column tiles)-->
<!--<div id="input_sheet_div" style="width: 100%;height:auto;max-height:72vh;">-->
  <!--<div id="input_sheet" ></div>-->
<!--</div>-->
<div id="input_sheet_div" style="width: 100%" [style.height]="window_height.toString() + 'px'">
  <div #input_sheet></div>
</div>
