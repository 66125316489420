<div class="page">
  <mat-label style="margin: 0 8px 0 0;">Select Audited Models</mat-label>
  <mat-form-field>
    <mat-select
        (selectionChange)="changedSelection()" *ngIf="audited_models.length > 0"
        [(ngModel)]="selected_models"
        [compareWith]="modelCompare"
        multiple
        name="engineering_unit">
      <mat-select-trigger>
        {{selected_models && selected_models.length > 0 ? (selected_models[0].modelName  | removeUnderscore | upperfirst) : ''}}
        <span *ngIf="selected_models.length > 1" class="select-additional-selection">
        (+{{selected_models.length - 1}} {{selected_models.length === 2 ? 'other' : 'others'}})
      </span>
      </mat-select-trigger>
      <mat-option *ngFor="let model of audited_models"
                  [value]="model">{{model.modelName | removeUnderscore | upperfirst}}</mat-option>
    </mat-select>
  </mat-form-field>

  <history-table [resource_list]="selected_models"></history-table>
</div>
