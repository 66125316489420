import {
    Component,
    OnInit,
    EventEmitter,
    HostListener,
    OnDestroy,
    ViewChild,
    ElementRef,
    HostBinding,
    Renderer2,
    Directive,
    ViewChildren,
    QueryList,
    AfterViewInit
} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {User} from "../../user_control/user_list.component";
import {AppScope} from '../../services/app_scope.service';
import * as utils from '../../lib/utils';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ScrollableDirective} from '../../directives/mention.directive';

@Component({
    selector: 'at-who',
    templateUrl: './at-who.component.html',
    styleUrls: ['./at-who.component.less']
})
export class AtWhoComponent implements OnInit, AfterViewInit, OnDestroy {
    users: User[];
    filtered_users: User[];
    searchQuery: string = '';
    list_index: number = 0;
    @ViewChild('user_list', {static: false}) user_list: ElementRef;
    @ViewChild(ScrollableDirective, {static: false}) list: ScrollableDirective;
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                public appScope: AppScope,
                private renderer: Renderer2) {
        this.appScope.mentionEmit.pipe(takeUntil(this.onDestroy)).subscribe((string => {
            this.doSearch(string);
        }))
    }

    ngOnInit() {
        this.getUserList();
    }

    ngAfterViewInit() {
        this.list.scrollTop = 0;
    }

    doSearch(string) {
        const ctrl = this;

        if (string === "select") { //Enter or tab
            this.addWho(this.filtered_users[this.list_index]);
        } else if (string === "ArrowUp") {
            if (this.list_index === 0) {
                this.list_index = this.filtered_users.length - 1;
            } else {
                this.list_index -= 1;
            }
            if (this.list_index === this.filtered_users.length - 1) {
                this.list.scrollTop = (this.filtered_users.length - 4) * 48;
            } else {
                this.list.scrollTop = (this.list_index - 3) * 48
            }
        } else if (string === "ArrowDown") {
            if (this.list_index === this.filtered_users.length - 1) {
                this.list_index = 0;
            } else {
                this.list_index += 1;
            }
            if (this.list_index > 3) {
                this.list.scrollTop = (this.list_index - 3) * 48;
            } else {
                this.list.scrollTop = 0;
            }
        } else if (string === "") { //reset
            this.searchQuery = "";
            this.list_index = 0;
            this.filtered_users = utils.deepCopy(ctrl.users);
        } else {
            this.list_index = 0;
            this.searchQuery = string.substr(1, string.length);
            this.filtered_users = this.users.filter(
                item => item.attributes.name.toLowerCase().startsWith(this.searchQuery.toLowerCase()) === true
            )
        }
    }

    getUserList() {
        const ctrl = this;
        ctrl.api.users.search().toPromise().then(response => {
            if (!response) return;
            ctrl.users = response.data;
            ctrl.filtered_users = utils.deepCopy(ctrl.users);
        })
    }

    addWho(user) {
        this.appScope.mentionSelected.emit({
            user: user,
            search_string: this.searchQuery,
            index: this.appScope.at_who_index
        });
    }

    updateCurrent(user) {
        this.list_index = this.filtered_users.indexOf(user);
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}
