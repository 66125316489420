import {select} from "d3-selection";
import {deepCopy, significantNumber} from "../utils";

//
// Global accessor.
//

var flowchart = {
    'ChartViewModel': null,
    'componentBase': null,
    'computeStreamEndTangent': null,
    'computeStreamEndTangentX': null,
    'computeStreamEndTangentY': null,
    'computeStreamStartTangent': null,
    'computeStreamStartTangentX': null,
    'computeStreamStartTangentY': null,
    'connectorHeight': null,
    'ConnectorViewModel': null,
    'ConstantViewModel': null,
    'defaultIcon': null,
    'defaultPercent': null,
    'defaultProcessHeight': null,
    'defaultProcessWidth': null,
    'defaultX': null,
    'defaultY': null,
    'EquipmentViewModel': null,
    'CustomChartViewModel': null,
    'parsedText': null,
    'PointViewModel': null,
    'processNameHeight': null,
    'ProcessViewModel': null,
    'screen_height': null,
    'screen_width': null,
    'SeriesViewModel': null,
    'StreamViewModel': null,
    'TextViewModel': null,
    'ContextViewModel': null,
    'ImageViewModel': null
};

// Module.
(function () {

    //
    // Width of a process.
    //
    flowchart.defaultProcessWidth = 175;

    //
    // Height of a process.
    //
    flowchart.defaultProcessHeight = 100;
    flowchart.defaultX = 50;
    flowchart.defaultY = 50;
    flowchart.defaultPercent = 0;
    flowchart.defaultIcon = "/static/icons/custom_icons/mining icon pump.svg";

    flowchart.screen_width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    flowchart.screen_height = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
    //
    // Amount of space reserved for displaying the process's name.
    //
    flowchart.processNameHeight = 20;

    //
    // Height of a connector in a process.
    //
    flowchart.connectorHeight = 35;

    //
    // View model for a connector.
    //
    flowchart.ConnectorViewModel = function (connectorDataModel, process, is_parent) {

        this.data = connectorDataModel;
        this._parentProcess = process;
        this.is_parent = is_parent;

        //
        // The parent process that the connector is attached to.
        //
        this.parentProcess = function () {
            return this._parentProcess;
        };

        this.name = function () {
            return this.data.attributes.name;
        };

        this.input_stream = function () {
            if (this.data.relationships.input_stream.data == null) {
                return null
            } else {
                return this.data.relationships.input_stream.data.id
            }
        };

        this.output_stream = function () {
            if (this.data.relationships.output_stream.data == null) {
                return null
            } else {
                return this.data.relationships.output_stream.data.id
            }
        };

        this.input_stream_name = function () {
            return this.data.attributes.input_stream_name
        };
        this.output_stream_name = function () {
            return this.data.attributes.output_stream_name
        };
        this.hover_text = function () {
            var hover = "";
            if (this.input_stream_name()) {
                hover = "Input stream: " + this.input_stream_name();
            }
            if (this.output_stream_name()) {

                hover += "Output stream: " + this.output_stream_name();

            }
            return hover;
        };

        this.allow_stream = function () {
            if (this.output_stream() == null && this.input_stream() == null) {
                return 'all';
            } else if (this.output_stream() !== null && this.input_stream() == null) {
                return 'input';
            } else if (this.output_stream() == null && this.input_stream() !== null) {
                return 'output';
            } else if (this.output_stream() !== null && this.input_stream() !== null) {
                return 'none';
            }
        };

        this.class = function () {
            return 'connector-' + this.allow_stream();
        };

        //
        // X coordinate of the connector.
        //
        this.x = function () {
            if (is_parent) {
                this.percent = this.data.attributes.json.parent.percent / 100;
            } else {
                this.percent = this.data.attributes.percent / 100;
            }
            if (this.percent <= this.parentProcess().width() / this.parentProcess().perimeter()) {
                return this.percent * this.parentProcess().perimeter();
            } else if (this.percent <= ((this.parentProcess().width() + this.parentProcess().height()) / this.parentProcess().perimeter())) {
                return this.parentProcess().width();
            } else if (this.percent <= (this.parentProcess().height() + this.parentProcess().width() * 2) / this.parentProcess().perimeter()) {
                return this.parentProcess().width() - this.parentProcess().perimeter() * (this.percent - (this.parentProcess().width() + this.parentProcess().height()) / this.parentProcess().perimeter());
            } else {
                return 0
            }
        };

        //
        // Y coordinate of the connector.
        //
        this.y = function () {
            if (is_parent) {
                this.percent = this.data.attributes.json.parent.percent / 100;
            } else {
                this.percent = this.data.attributes.percent / 100;
            }
            if (this.percent <= this.parentProcess().width() / this.parentProcess().perimeter()) {
                return 0
            } else if (this.percent <= (this.parentProcess().width() + this.parentProcess().height()) / this.parentProcess().perimeter()) {
                return this.parentProcess().perimeter() * (this.percent - (this.parentProcess().width()) / this.parentProcess().perimeter())
            }
            if (this.percent <= (this.parentProcess().height() + this.parentProcess().width() * 2) / this.parentProcess().perimeter()) {
                return this.parentProcess().height();

            } else {
                return this.parentProcess().height() - this.parentProcess().perimeter() * (this.percent - (this.parentProcess().height() + this.parentProcess().width() * 2) / this.parentProcess().perimeter())
            }
        };

        this.deltaP = function (deltaPX, deltaPY) {
            if (is_parent) {
                this.percent = this.data.attributes.json.parent.percent / 100;
                //this.percent = this.data.attributes.percent/100;
            } else {
                this.percent = this.data.attributes.percent / 100;
            }
            var p = this.percent;
            if (p <= (this.parentProcess().width() / this.parentProcess().perimeter()) && p >= 0) {
                return deltaPX;
            } else if (p > (this.parentProcess().width() / this.parentProcess().perimeter()) && p <= 0.5) {
                return deltaPY;
            } else if (p > 0.5 && p <= ((this.parentProcess().width() * 2 + this.parentProcess().height()) / this.parentProcess().perimeter())) {
                return deltaPX * -1;
            } else if (p > ((this.parentProcess().width() * 2 + this.parentProcess().height()) / this.parentProcess().perimeter()) && p <= (100 - this.parentProcess().height() / this.parentProcess().perimeter()) && p <= 1) {
                return deltaPY * -1;
            } else {
                return 0;
            }
        };

        this.x_abs = function () {
            return this.x() + this.parentProcess().x();
        };

        this.y_abs = function () {
            return this.y() + this.parentProcess().y();
        };

        this.hover_text_x = function (r) {
            r = Number(r);
            if (this.x() < r) {
                return this.x() + 10 + r;
            }
            if (this.x() >= r && this.x() <= process.data.attributes.json.windowWidth * 3 / 4) {
                return this.x() + r;
            }
            if (this.x() > process.data.attributes.json.windowWidth * 3 / 4) {
                return this.x() - 10 - r;
            }
        };
        this.hover_text_y = function (r) {
            r = Number(r);
            if (this.y() >= 0 && this.y() <= r) {
                return this.y() + r;
            }
            if (this.y() > r && this.y() < process.data.attributes.json.windowHeight - r) {
                return this.y() - 10;
            }
            if (this.y() >= process.data.attributes.json.windowHeight - r) {
                return this.y() - r / 2;
            }
        };
        this.hover_text_anchor = function () {

            if (this.x() >= 0 && this.x() <= process.data.attributes.json.windowWidth * 3 / 4) {
                return "start";
            }
            if (this.x() > process.data.attributes.json.windowWidth * 3 / 4) {
                return "end";
            }

        };

        if (this.data.attributes.json == null || this.data.attributes.json == undefined) {
            this.data.attributes.json = {};
        }

        if (is_parent) {
            if (this.data.attributes.json['parent'] == undefined) {
                this.data.attributes.json['parent'] = {"percent": this.data.attributes.percent};
            }
            if (this.data.attributes.json.parent['percent'] == undefined) {
                this.data.attributes.json.parent['percent'] = this.data.attributes.percent;
            }
        }

        if (this.data.attributes.json['text'] == undefined) {
            this.data.attributes.json['text'] = {};
        }
        if (!this.data.attributes.json['text'].x) {
            this.data.attributes.json['text'].x = this.x() + 20;
        }
        if (!this.data.attributes.json['text'].y) {
            this.data.attributes.json['text'].y = this.y();
        }

        this.text = new flowchart.TextViewModel(this.data.attributes.json.text);

        // Select the connector.
        //
        this.select = function () {
            this._selected = true;
        };

        //
        // Deselect the connector.
        //
        this.deselect = function () {
            this._selected = false;
        };

        //
        // Toggle the selection state of the connector.
        //
        this.toggleSelected = function () {
            this._selected = !this._selected;
        };

        //
        // Returns true if the connector is selected.
        //
        this.selected = function () {
            return this._selected;
        };

    };

    //
    // Create view model for a list of data models.
    //
    var createConnectorsViewModel = function (connectorsDataModel, process, is_parent) {
        var viewModels = [];
        if (connectorsDataModel) {
            for (var i = 0; i < connectorsDataModel.length; ++i) {
                for (var c = 0; c < process.data.relationships.connectors.data.length; ++c) {
                    if (connectorsDataModel[i].id === process.data.relationships.connectors.data[c].id) {
                        var connectorViewModel = new flowchart.ConnectorViewModel(connectorsDataModel[i], process, is_parent);
                        viewModels.push(connectorViewModel);
                    }
                }
            }
        }

        return viewModels;
    };

    // View model for a process.
    flowchart.ProcessViewModel = function (processDataModel, is_parent, connectorDataModel) {
        this.data = processDataModel;
        this.is_parent = is_parent;

        if (this.data == null) {
            this.data = {};
        }
        // set the default width value of the process
        if (this.data.attributes == null) {
            this.data.attributes = {};
        }
        if (this.data.attributes.json == null) {
            this.data.attributes.json = {
                x: flowchart.defaultX, y: flowchart.defaultY,
                width: flowchart.defaultProcessWidth, height: flowchart.defaultProcessHeight,
                text: {x: flowchart.defaultProcessWidth / 2, y: 12},
                connectors: [{
                    name: 'Output', id: 0,
                    percent: 100 * (flowchart.defaultProcessWidth + 0.5 * flowchart.defaultProcessHeight) /
                        (2 * flowchart.defaultProcessWidth + 2 * flowchart.defaultProcessHeight)
                }, {
                    name: 'Input', id: 1,
                    percent: 100 * (2 * flowchart.defaultProcessWidth + 1.5 * flowchart.defaultProcessHeight) /
                        (2 * flowchart.defaultProcessWidth + 2 * flowchart.defaultProcessHeight)
                }]
            };
        }
        if (!this.data.attributes.json.width || this.data.attributes.json.width < 0) {
            this.data.attributes.json.width = flowchart.defaultProcessWidth;
        }
        if (!this.data.attributes.json.height || this.data.attributes.json.height < 0) {
            this.data.attributes.json.height = flowchart.defaultProcessHeight;
        }

        if (this.data.attributes.json['text'] == undefined) {
            this.data.attributes.json['text'] = {};
        }
        if (!this.data.attributes.json['text'].x) {
            this.data.attributes.json['text'].x = flowchart.defaultProcessHeight / 2;
        }
        if (!this.data.attributes.json['text'].y) {
            this.data.attributes.json['text'].y = 12;
        }
        this.data.attributes.json['text'].rotate = 0;

        // Set to true when the process is selected.
        this._selected = false;

        //
        // Name of the process.
        //
        this.name = function () {
            return this.data.attributes.name || "";
        };

        //
        // X coordinate of the process.
        //
        this.x = function () {
            if (is_parent === true) {
                return 0
            }
            return this.data.attributes.json.x;
        };

        //
        // Y coordinate of the process.
        //
        this.y = function () {
            if (is_parent === true) {
                return 0
            }
            return this.data.attributes.json.y;
        };

        //
        // Width of the process.
        //
        this.width = function () {

            if (is_parent === true) {
                return parseFloat(this.data.attributes.json.windowWidth)
            }
            return parseFloat(this.data.attributes.json.width);
        };

        //
        // Height of the process.
        //
        this.height = function () {
            if (is_parent === true) {
                return parseFloat(this.data.attributes.json.windowHeight)
            }
            return parseFloat(this.data.attributes.json.height);
        };

        //
        // Fontsize of the process.
        //
        this.fontsize = function () {
            return parseFloat(this.data.attributes.json.fontsize);
        };

        //
        // Width of the process.
        //
        this.perimeter = function () {
            return this.width() * 2 + this.height() * 2;
        };

        this.text = new flowchart.TextViewModel(this.data.attributes.json.text);
        this.connectors = createConnectorsViewModel(connectorDataModel, this, is_parent);

        this.createImageFromIcon = function (parent) {
            let image = {};
            image['width'] = parent.data.attributes.json.pad_image ? 200 : 80;
            image['height'] = parent.data.attributes.json.pad_image ? 200 : 80;
            image['x'] = parent.data.attributes.json.pad_image ? parent.x() + (parent.width() / 2 - 100) : parent.x() + (parent.width() / 2 - 40);
            image['y'] = parent.data.attributes.json.pad_image ? parent.y() + (parent.height() / 2 - 94) : parent.y() + (parent.height() / 2 - 34);
            image['src'] = parent.data.attributes.icon;
            image['constrain_proportions'] = true;
            image['type'] = 'image';
            image['title'] = '';
            return image;
        }

        if (is_parent) {
            if (this.data.attributes.json.charts) {
                this.custom_charts = createCustomChartsViewModels(this.data.attributes.json.charts, this.data);
            } else {
                this.custom_charts = [];
            }
            if (this.data.attributes.json.contexts) {
                this.contexts = createContextViewModels(this.data.attributes.json.contexts, this.data);
            } else {
                this.contexts = [];
            }
            if (this.data.attributes.json.images) {
                this.images = createImageViewModels(this.data.attributes.json.images, this);
            }
        } else {
            if (this.data.attributes.json.image) {
                this.image = new flowchart.ImageViewModel(this.data.attributes.json.image, this);
            } else {
                //Backward compatibility - convert old icons into editable images
                let image = this.createImageFromIcon(this);
                this.data.attributes.json.image = image;
                this.image = new flowchart.ImageViewModel(image, parent);
            }
        }

        // Select the process.
        this.select = function () {
            this._selected = true;
            if (this.image) {
                this.image.select();
            }
        };

        //
        // Deselect the process.
        //
        this.deselect = function () {
            this._selected = false;
        };

        //
        // Toggle the selection state of the process.
        //
        this.toggleSelected = function () {
            this._selected = !this._selected;
        };

        //
        // Returns true if the process is selected.
        //
        this.selected = function () {
            return this._selected;
        };

        //Highlight
        this.highlight = function () {
            this._highlighted = true;
        };

        this.unhighlight = function () {
            this._highlighted = false;
        };

        this.highlighted = function () {
            return this._highlighted;
        };

    };

    // Wrap the processes data-model in a view-model.
    var createProcessesViewModel = function (processesDataModel, connectorDataModel) {
        var processesViewModel = [];
        if (processesDataModel) {
            for (var i = 0; i < processesDataModel.length; ++i) {
                processesViewModel.push(new flowchart.ProcessViewModel(processesDataModel[i], false, connectorDataModel));
            }
        }

        return processesViewModel;
    };

    // View model for process text.
    flowchart.TextViewModel = function (textData) {
        this.data = textData;

        let rotate = this.data.rotate;
        this.rotate = function () {
            rotate = this.data.rotate;
            return this.data.rotate
        };
        //rotate working relative to window..think it has to do with cdkDrag hijacking the transform property
        this.x = function () {
            if (rotate == 90) {
                return this.data.y
            }
            if (rotate == 180) {
                return this.data.x * -1
            }
            if (rotate == 270) {
                return this.data.y * -1
            }
            return this.data.x
        };

        this.y = function () {
            if (rotate == 90) {
                return this.data.x * -1
            }
            if (rotate == 180) {
                return this.data.y * -1
            }
            if (rotate == 270) {
                return this.data.x
            }
            return this.data.y
        };

        this._selected = false;

        this.select = function () {
            this._selected = true;
        };

        //
        // Deselect the text.
        //
        this.deselect = function () {
            this._selected = false;
        };

        //
        // Toggle the selection state of the process.
        //
        this.toggleSelected = function () {
            this._selected = !this._selected;
        };

        //
        // Returns true if the process is selected.
        //
        this.selected = function () {
            return this._selected;
        };

    };

    // View model for process text.
    flowchart.EquipmentViewModel = function (equipmentData, parent_component, component_type) {
        this.data = equipmentData;

        this.name = function () {
            return this.data.attributes.name;
        };

        this.height = function () {
            return 80;
        };

        this.width = function () {
            return 80;
        };

        if (this.data.attributes.json == null) {
            this.data.attributes.json = {};
        }
        if (!this.data.attributes.json['x']) {
            this.data.attributes.json['x'] = window.innerWidth / 2;
        }
        if (!this.data.attributes.json['y']) {
            this.data.attributes.json['y'] = window.innerHeight / 2;
        }

        if (this.data.attributes.json['text'] == undefined) {
            this.data.attributes.json['text'] = {};
        }
        if (!this.data.attributes.json['text'].x) {
            this.data.attributes.json['text'].x = this.width() / 2;
        }
        if (!this.data.attributes.json['text'].y) {
            this.data.attributes.json['text'].y = this.height() - 15;
        }
        if (!this.data.attributes.json['text'].rotate) {
            this.data.attributes.json['text'].rotate = 0;
        }

        this.text = new flowchart.TextViewModel(this.data.attributes.json.text);

        this.x = function () {
            return this.data.attributes.json.x;
        };

        this.y = function () {
            return this.data.attributes.json.y;
        };

        if (this.data.attributes.icon == null) {
            this.data.attributes.icon = flowchart.defaultIcon;
        }

        this.parent_component = parent_component;
        this.parent = function () {
            return component_type + ": " + parent_component.data.attributes.name;
        };

        this._selected = false;

        this.select = function () {
            this._selected = true;
        };

        //
        // Deselect the text.
        //
        this.deselect = function () {
            this._selected = false;
        };

        //
        // Toggle the selection state of the process.
        //
        this.toggleSelected = function () {
            this._selected = !this._selected;
        };

        //
        // Returns true if the process is selected.
        //
        this.selected = function () {
            return this._selected;
        };

        //Highlight
        this.highlight = function () {
            this._highlighted = true;
        };

        this.unhighlight = function () {
            this._highlighted = false;
        };

        this.highlighted = function () {
            return this._highlighted;
        };

    };

    // Create view model for a list of charts.
    var createCustomChartsViewModels = function (customChartsDataModel, process) {
        var viewModels = [];
        if (customChartsDataModel) {
            for (var i = 0; i < customChartsDataModel.length; ++i) {
                var customChartViewModel = new flowchart.CustomChartViewModel(customChartsDataModel[i], process, i);
                viewModels.push(customChartViewModel);
            }
        }

        return viewModels;
    };
    // View model for custom chart **NB DON'T CONFUSE with ChartViewModel which is the whole flowchart.
    flowchart.CustomChartViewModel = function (customChartData, process, index) {
        this.data = customChartData;
        this.type = 'custom_chart';
        this.index = index;

        this.height = function () {
            return 36;
        };
        this.width = function () {
            return 36;
        };

        if (!this.data['x']) {
            this.data['x'] = 200;
        }
        if (!this.data['y']) {
            this.data['y'] = 200;
        }

        this.title = function () {
            if (!this.data.labels.title) {
                return "No title"
            } else {
                return this.data.labels.title;
            }
        };

        this.x = function () {
            return this.data.x;
        };

        this.y = function () {
            return this.data.y;
        };

        this.parent_component = process;

        this._selected = false;

        this.select = function () {
            this._selected = true;
        };

        //
        // Deselect the text.
        //
        this.deselect = function () {
            this._selected = false;
        };

        //
        // Toggle the selection state of the process.
        //
        this.toggleSelected = function () {
            this._selected = !this._selected;
        };

        //
        // Returns true if the process is selected.
        //
        this.selected = function () {
            return this._selected;
        };

        //Highlight
        this.highlight = function () {
            this._highlighted = true;
        };

        this.unhighlight = function () {
            this._highlighted = false;
        };

        this.highlighted = function () {
            return this._highlighted;
        };

    };

    // Create view model for a list of charts.
    var createImageViewModels = function (imagesDataModel, process) {
        var viewModels = [];
        if (imagesDataModel) {
            for (var i = 0; i < imagesDataModel.length; ++i) {
                var imageViewModel = new flowchart.ImageViewModel(imagesDataModel[i], process, i);
                viewModels.push(imageViewModel);
            }
        }

        return viewModels;
    };

    // View model for images that sit on the parent process.
    flowchart.ImageViewModel = function (imageData, process, index: number = null) {
        this.data = imageData;
        this.data.type = 'image';
        this.index = index;

        if (!this.data['height']) {
            this.data.height = null;
        }
        if (!this.data['width']) {
            this.data.width = 80;
        }
        if (!this.data['src']) {
            this.data.src = '';
        }
        if (!this.data['x']) {
            this.data['x'] = 200;
        }
        if (!this.data['y']) {
            this.data['y'] = 200;
        }
        if (!this.data['constrain_proportions']) {
            this.data['constrain_proportions'] = true;
        }
        this.title = function () {
            if (!this.data.title) {
                return "No title"
            } else {
                return this.data.title;
            }
        };

        this.x = function () {
            return this.data.x;
        };

        this.y = function () {
            return this.data.y;
        };

        this.height = function () {
            return this.data.height;
        };

        this.width = function () {
            return this.data.width;
        };
        this.constrain_proportions = function () {
            return this.data.constrain_proportions;
        }
        this.src = function () {
            return this.data.src;
        };

        this.parent_component = process;

        this._selected = false;

        this.select = function () {
            this._selected = true;
        };

        //
        // Deselect the text.
        //
        this.deselect = function () {
            this._selected = false;
        };

        //
        // Toggle the selection state of the process.
        //
        this.toggleSelected = function () {
            this._selected = !this._selected;
        };

        //
        // Returns true if the process is selected.
        //
        this.selected = function () {
            return this._selected;
        };

        //Highlight
        this.highlight = function () {
            this._highlighted = true;
        };

        this.unhighlight = function () {
            this._highlighted = false;
        };

        this.highlighted = function () {
            return this._highlighted;
        };

    };

    // Create view model for a list of context tiles.
    var createContextViewModels = function (contextsDataModel, process) {
        var viewModels = [];
        if (contextsDataModel) {
            for (var i = 0; i < contextsDataModel.length; ++i) {
                var contextViewModel = new flowchart.ContextViewModel(contextsDataModel[i], process, i);
                viewModels.push(contextViewModel);
            }
        }

        return viewModels;
    };
    // View model for custom chart **NB DON'T CONFUSE with ChartViewModel which is the whole flowchart.
    flowchart.ContextViewModel = function (contextData, process, index) {
        this.data = contextData;
        this.type = 'context';
        this.index = index;

        this.height = function () {
            if (this.data.config.value_only === true) {
                return 100;
            } else {
                return 160;
            }
        };
        this.width = function () {
            if (this.data.config.value_only === true) {
                return 200;
            } else {
                return 260;
            }
        };

        if (!this.data['x']) {
            this.data['x'] = 200;
        }
        if (!this.data['y']) {
            this.data['y'] = 200;
        }

        this.title = this.data.title;
        this.config = this.data.config;

        this.x = function () {
            return this.data.x;
        };

        this.y = function () {
            return this.data.y;
        };

        this.parent_component = process;

        this._selected = false;

        this.select = function () {
            this._selected = true;
        };

        //
        // Deselect the text.
        //
        this.deselect = function () {
            this._selected = false;
        };

        //
        // Toggle the selection state of the process.
        //
        this.toggleSelected = function () {
            this._selected = !this._selected;
        };

        //
        // Returns true if the process is selected.
        //
        this.selected = function () {
            return this._selected;
        };

        //Highlight
        this.highlight = function () {
            this._highlighted = true;
        };

        this.unhighlight = function () {
            this._highlighted = false;
        };

        this.highlighted = function () {
            return this._highlighted;
        };

    };

    flowchart.parsedText = function (seriesViewModel) {
        var value = seriesViewModel.value();
        if (!value && value !== 0) {
            value = "";
        }
        var name = seriesViewModel.name();
        if (name) {
            if (name.indexOf('@value') > -1 || name.indexOf('@Value') > -1) {
                name = name.replace('@value', value);
                name = name.replace('@Value', value);
            }
        }
        return name;
    };

    flowchart.SeriesViewModel = function (seriesData, parent_process, parent_component) {
        this.data = seriesData;

        this.has_series = function () {
            if (!this.data.relationships.series.data || !this.data.relationships.series.data.id) {
                return false;
            } else {
                return true;
            }
        };

        this.tree_position = "child";
        this.is_on_parent = false;
        if (this.data.relationships.component.data.id === parent_process.data.id) {
            this.tree_position = "parent";
            this.is_on_parent = true;
        }

        this.parent_component = parent_component;

        this.view_on_flowchart = function () {
            if (((seriesData.attributes.view_on_flowchart && this.tree_position == "child")
                || (seriesData.attributes.view_on_parent_flowchart && this.tree_position == "parent"))
                && this.has_series() === true) {
                return true;
            }
            return false;
        };

        this.height = function () {
            return 10;
        };

        this.width = function () {
            return 200;
        };

        if (this.data.attributes.json == null) {
            this.data.attributes.json = {};
        }
        if (this.data.attributes.json[this.tree_position] == undefined) {
            this.data.attributes.json[this.tree_position] = {};
        }
        if (!this.data.attributes.json[this.tree_position]['x']) {
            if (this.is_on_parent === true) {
                this.data.attributes.json[this.tree_position]['x'] = parent_process.width() / 4;
            } else {
                if (this.parent_component.data.type === 'stream') {
                    var stream = this.parent_component;
                    if (stream.data.attributes.json.points.length > 1) {
                        this.data.attributes.json[this.tree_position]['x'] = (stream.points[0].x() + stream.points[1].x()) / 2;
                    } else {
                        this.data.attributes.json[this.tree_position]['x'] = (stream.startCoordX() + stream.endCoordX()) / 2;
                    }
                    if (this.data.attributes.json[this.tree_position]['x'] >= parent_process.width() - 50) {
                        this.data.attributes.json[this.tree_position]['x'] += -100;
                    }
                } else {
                    this.data.attributes.json[this.tree_position]['x'] = this.parent_component.x() + this.parent_component.width() * 3 / 4;
                }
            }
        }
        if (!this.data.attributes.json[this.tree_position]['y']) {
            if (this.is_on_parent === true) {
                this.data.attributes.json[this.tree_position]['y'] = parent_process.height() / 4;
            } else {
                if (this.parent_component.data.type === 'stream') {
                    var stream = this.parent_component;
                    if (stream.data.attributes.json.points.length > 1) {
                        this.data.attributes.json[this.tree_position]['y'] = (stream.points[0].y() + stream.points[1].y()) / 2;
                    } else {
                        this.data.attributes.json[this.tree_position]['y'] = (stream.startCoordY() + stream.endCoordY()) / 2;
                    }
                    if (this.data.attributes.json[this.tree_position]['y'] >= parent_process.height() - 40) {
                        this.data.attributes.json[this.tree_position]['y'] += -40;
                    }
                } else {
                    this.data.attributes.json[this.tree_position]['y'] = this.parent_component.y() + this.parent_component.height() / 2;
                }
            }

        }

        let rotate = this.data.rotate;
        this.rotate = function () {
            if (!this.data.attributes.json[this.tree_position]['text'].rotate) {
                this.data.attributes.json[this.tree_position]['text'].rotate = 0;
            }
            rotate = this.data.attributes.json[this.tree_position]['text'].rotate;
            return this.data.attributes.json[this.tree_position]['text'].rotate;
        };

        this.x = function () {
            if (this.data.attributes.json[this.tree_position].x < 0) {
                this.data.attributes.json[this.tree_position].x = 50;
            }
            if (rotate == 90) {
                return this.data.attributes.json[this.tree_position].y
            }
            if (rotate == 180) {
                return this.data.attributes.json[this.tree_position].x * -1
            }
            if (rotate == 270) {
                return this.data.attributes.json[this.tree_position].y * -1
            }
            return this.data.attributes.json[this.tree_position].x;
        };

        this.y = function () {
            if (this.data.attributes.json[this.tree_position].y < 0) {
                this.data.attributes.json[this.tree_position].y = 50;
            }
            if (rotate == 90) {
                return this.data.attributes.json[this.tree_position].x * -1
            }
            if (rotate == 180) {
                return this.data.attributes.json[this.tree_position].y * -1
            }
            if (rotate == 270) {
                return this.data.attributes.json[this.tree_position].x
            }
            return this.data.attributes.json[this.tree_position].y;
        };

        if (this.data.attributes.json[this.tree_position]['text'] == undefined || typeof (this.data.attributes.json[this.tree_position]['text']) == 'string') {
            this.data.attributes.json[this.tree_position]['text'] = {};
        }
        if (!this.data.attributes.json[this.tree_position]['text'].x) {
            this.data.attributes.json[this.tree_position]['text'].x = this.width() / 2;
        }
        if (!this.data.attributes.json[this.tree_position]['text'].y) {
            this.data.attributes.json[this.tree_position]['text'].y = 15;
        }

        this.name = function () {
            if (!this.data.attributes.json[this.tree_position].name) {
                if (this.has_series() === false) {
                    this.data.attributes.json[this.tree_position].name = "";
                } else {
                    this.data.attributes.json[this.tree_position].name = this.data.relationships.series.data.attributes.name + " - " + this.data.relationships.series.data.attributes.description;
                }
            }

            return this.data.attributes.json[this.tree_position].name;
        };

        this.unit = function () {
            return this.data.attributes.json[this.tree_position].unit;
        };

        this.value = function () {
            if (this.has_series() === true) {
                return significantNumber(this.data.relationships.series.data.attributes.value);
            } else {
                return "";
            }
        };
        this.status = function () {
            if (this.has_series() === true) {
                return this.data.relationships.series.data.attributes.status;
            } else {
                return null;
            }
        };

        //this.text =  new flowchart.TextViewModel(this.data.attributes.json[this.tree_position].text);

        this.show_status = function () {
            return this.data.attributes.json[this.tree_position].show_status;
        };
        //Display
        this.text_colour = function () {
            if (!this.data.attributes.json[this.tree_position].font_colour) {
                this.data.attributes.json[this.tree_position].font_colour = "#000";
            }
            return this.data.attributes.json[this.tree_position].font_colour;
        };

        this.background = function () {
            if (!this.data.attributes.json[this.tree_position].background) {
                this.data.attributes.json[this.tree_position].background = "#fff";
            }
            return this.data.attributes.json[this.tree_position].background;
        };
        this.borders = function () {
            if (!this.data.attributes.json[this.tree_position].borders) {
                return this.background();
            }
            return "#333333"
        };
        this.padding = function () {
            if (!this.data.attributes.json[this.tree_position].minimise) {
                return '6px';
            }
            return '0px 2px';
        };
        this.font_size = function () {
            if (!this.data.attributes.json[this.tree_position].font_size) {
                this.data.attributes.json[this.tree_position].font_size = 12;
            }
            return this.data.attributes.json[this.tree_position].font_size;
        };

        this.display = function () {
            return flowchart.parsedText(this);
        };
        this._selected = false;

        this.select = function () {
            this._selected = true;
        };

        //
        // Deselect the text.
        //
        this.deselect = function () {
            this._selected = false;
        };

        //
        // Toggle the selection state of the process.
        //
        this.toggleSelected = function () {
            this._selected = !this._selected;
        };

        //
        // Returns true if the process is selected.
        //
        this.selected = function () {
            return this._selected;
        };

    };

    flowchart.componentBase = function () {
        var self = this;
        //this.data = componentData;
        self.tree_position = "child";
        self.is_on_parent = false;
        if (self.data.relationships.component.data.id === self.parent_process.data.id) {
            self.tree_position = "parent";
            self.is_on_parent = true;
        }
    };

    flowchart.ConstantViewModel = function (constantData, parent_process, parent_component) {
        this.data = constantData;
        // var self = this;
        // self.data = constantData;
        // self.parent_process = parent_process;
        // console.log(constantData);
        // flowchart.componentBase.call(self);

        // this.has_series = function(){
        // if(!this.data.relationships.series.data || !this.data.relationships.series.data.id){
        // 	return false;
        // } else {
        // 	return true;
        // }
        // }

        this.tree_position = "child";
        this.is_on_parent = false;
        if (this.data.relationships.component.data.id === parent_process.data.id) {
            this.tree_position = "parent";
            this.is_on_parent = true;
        }

        this.parent_component = parent_component;

        this.view_on_flowchart = function () {
            if (((constantData.attributes.view_on_flowchart && this.tree_position == "child")
                || (constantData.attributes.view_on_parent_flowchart && this.tree_position == "parent"))
            ) {
                return true;
            }
            return false;
        };

        this.height = function () {
            return 10;
        };

        this.width = function () {
            return 200;
        };

        if (this.data.attributes.json == null) {
            this.data.attributes.json = {};
        }
        if (this.data.attributes.json[this.tree_position] == undefined) {
            this.data.attributes.json[this.tree_position] = {};
        }
        if (!this.data.attributes.json[this.tree_position]['x']) {
            if (this.is_on_parent === true) {
                this.data.attributes.json[this.tree_position]['x'] = parent_process.width() / 4;
            } else {
                if (this.parent_component.data.type === 'stream') {
                    var stream = this.parent_component;
                    if (stream.data.attributes.json.points.length > 1) {
                        this.data.attributes.json[this.tree_position]['x'] = (stream.points[0].x() + stream.points[1].x()) / 2;
                    } else {
                        this.data.attributes.json[this.tree_position]['x'] = (stream.startCoordX() + stream.endCoordX()) / 2;
                    }
                    if (this.data.attributes.json[this.tree_position]['x'] >= parent_process.width() - 50) {
                        this.data.attributes.json[this.tree_position]['x'] += -100;
                    }
                } else {
                    this.data.attributes.json[this.tree_position]['x'] = this.parent_component.x() + this.parent_component.width() * 3 / 4;
                }
            }
        }
        if (!this.data.attributes.json[this.tree_position]['y']) {
            if (this.is_on_parent === true) {
                this.data.attributes.json[this.tree_position]['y'] = parent_process.height() / 4;
            } else {
                if (this.parent_component.data.type === 'stream') {
                    var stream = this.parent_component;
                    if (stream.data.attributes.json.points.length > 1) {
                        this.data.attributes.json[this.tree_position]['y'] = (stream.points[0].y() + stream.points[1].y()) / 2;
                    } else {
                        this.data.attributes.json[this.tree_position]['y'] = (stream.startCoordY() + stream.endCoordY()) / 2;
                    }
                    if (this.data.attributes.json[this.tree_position]['y'] >= parent_process.height() - 40) {
                        this.data.attributes.json[this.tree_position]['y'] += -40;
                    }
                } else {
                    this.data.attributes.json[this.tree_position]['y'] = this.parent_component.y() + this.parent_component.height() / 2;
                }
            }

        }

        let rotate = this.data.rotate;
        this.rotate = function () {
            if (!this.data.attributes.json[this.tree_position]['text'].rotate) {
                this.data.attributes.json[this.tree_position]['text'].rotate = 0;
            }
            rotate = this.data.attributes.json[this.tree_position]['text'].rotate;
            return this.data.attributes.json[this.tree_position]['text'].rotate;
        };

        this.x = function () {
            if (this.data.attributes.json[this.tree_position].x < 0) {
                this.data.attributes.json[this.tree_position].x = 50;
            }
            if (rotate == 90) {
                return this.data.attributes.json[this.tree_position].y
            }
            if (rotate == 180) {
                return this.data.attributes.json[this.tree_position].x * -1
            }
            if (rotate == 270) {
                return this.data.attributes.json[this.tree_position].y * -1
            }
            return this.data.attributes.json[this.tree_position].x;
        };

        this.y = function () {
            if (this.data.attributes.json[this.tree_position].y < 0) {
                this.data.attributes.json[this.tree_position].y = 50;
            }
            if (rotate == 90) {
                return this.data.attributes.json[this.tree_position].x * -1
            }
            if (rotate == 180) {
                return this.data.attributes.json[this.tree_position].y * -1
            }
            if (rotate == 270) {
                return this.data.attributes.json[this.tree_position].x
            }
            return this.data.attributes.json[this.tree_position].y;
        };

        if (this.data.attributes.json[this.tree_position]['text'] == undefined || typeof (this.data.attributes.json[this.tree_position]['text']) == 'string') {
            this.data.attributes.json[this.tree_position]['text'] = {};
        }
        if (!this.data.attributes.json[this.tree_position]['text'].x) {
            this.data.attributes.json[this.tree_position]['text'].x = this.width() / 2;
        }
        if (!this.data.attributes.json[this.tree_position]['text'].y) {
            this.data.attributes.json[this.tree_position]['text'].y = 15;
        }

        this.name = function () {
            if (!this.data.attributes.json[this.tree_position].name) {
                return this.parent_component.name() + " custom property";
            }

            return this.data.attributes.json[this.tree_position].name;
        };

        this.value = function () {
            return this.data.attributes.value;
        };

        this.rotate = function () {
            if (!this.data.attributes.json[this.tree_position]['text'].rotate) {
                this.data.attributes.json[this.tree_position]['text'].rotate = 0;
            }
            return this.data.attributes.json[this.tree_position]['text'].rotate;
        };

        //this.text =  new flowchart.TextViewModel(this.data.attributes.json[this.tree_position].text);

        //Display

        this.display = function () {
            return flowchart.parsedText(this);
        };

        this.text_colour = function () {
            if (!this.data.attributes.json[this.tree_position].font_colour) {
                this.data.attributes.json[this.tree_position].font_colour = "#000";
            }
            return this.data.attributes.json[this.tree_position].font_colour;
        };

        this.background = function () {
            if (!this.data.attributes.json[this.tree_position].background) {
                this.data.attributes.json[this.tree_position].background = "#fff";
            }
            return this.data.attributes.json[this.tree_position].background;
        };

        this.borders = function () {
            if (!this.data.attributes.json[this.tree_position].borders) {
                return this.background();
            }
            return "#333333"
        };
        this.padding = function () {
            if (!this.data.attributes.json[this.tree_position].minimise) {
                return '6px';
            }
            return '0px 2px';
        };

        this.font_size = function () {
            if (!this.data.attributes.json[this.tree_position].font_size) {
                this.data.attributes.json[this.tree_position].font_size = 12;
            }
            return this.data.attributes.json[this.tree_position].font_size;
        };

        this._selected = false;

        this.select = function () {
            this._selected = true;
        };

        //
        // Deselect the text.
        //
        this.deselect = function () {
            this._selected = false;
        };

        //
        // Toggle the selection state of the process.
        //
        this.toggleSelected = function () {
            this._selected = !this._selected;
        };

        //
        // Returns true if the process is selected.
        //
        this.selected = function () {
            return this._selected;
        };

    };

    flowchart.PointViewModel = function (pointData) {

        this.data = pointData;
        this.x = function () {
            return this.data.x
        };

        this.y = function () {
            return this.data.y
        };

        this._selected = false;

        this.select = function () {
            this._selected = true;
        };

        //
        // Deselect the point.
        //
        this.deselect = function () {
            this._selected = false;
        };

        //
        // Toggle the selection state of the process.
        //
        this.toggleSelected = function () {
            this._selected = !this._selected;
        };

        //
        // Returns true if the process is selected.
        //
        this.selected = function () {
            return this._selected;
        };

    };

    flowchart.StreamViewModel = function (streamDataModel, startConnector, endConnector) {

        this.data = streamDataModel;
        this.start = startConnector;
        this.end = endConnector;
        // Set to true when the stream is selected.
        this._selected = false;
        if (this.data.attributes == null) {
            this.data.attributes = {};
        }
        if (this.data.attributes.json == null) {
            this.data.attributes.json = {};
        }
        if (startConnector == null) {
            this.data.attributes.json.startConnectorID = null;
            this.data.attributes.json.endConnectorID = null;
        } else {
            this.data.attributes.json.startConnectorID = startConnector.data.id;
            this.data.attributes.json.endConnectorID = endConnector.data.id;
        }

        this.name = function () {
            return this.data.attributes.name || "";
        };

        this.startCoordX = function () {
            return this.start.parentProcess().x() + this.start.x();
        };

        this.startCoordY = function () {
            return this.start.parentProcess().y() + this.start.y();
        };

        this.startCoord = function () {
            return {
                x: this.startCoordX(),
                y: this.startCoordY()
            };
        };

        this.startTangentX = function () {
            return flowchart.computeStreamStartTangentX(this.startCoord(), this.endCoord());
        };

        this.startTangentY = function () {
            return flowchart.computeStreamStartTangentY(this.startCoord(), this.endCoord());
        };

        this.endCoordX = function () {
            return this.end.parentProcess().x() + this.end.x();
        };

        this.endCoordY = function () {
            return this.end.parentProcess().y() + this.end.y();
        };

        this.endCoord = function () {
            return {
                x: this.endCoordX(),
                y: this.endCoordY()
            };
        };

        this.endTangentX = function () {
            return flowchart.computeStreamEndTangentX(this.startCoord(), this.endCoord());
        };

        this.endTangentY = function () {
            return flowchart.computeStreamEndTangentY(this.startCoord(), this.endCoord());
        };

        this.middleX = function (scale) {
            if (typeof (scale) == "undefined")
                scale = 0.5;
            return this.startCoordX() * (1 - scale) + this.endCoordX() * scale;
        };

        this.middleY = function (scale) {
            if (typeof (scale) == "undefined")
                scale = 0.5;
            return this.startCoordY() * (1 - scale) + this.endCoordY() * scale;
        };

        this.addPoint = function () {
            var new_point = {x: this.middleX(), y: this.middleY()};
            this.data.attributes.json.points.push(new_point);
            this.points.push(new flowchart.PointViewModel(new_point))
        };

        this.removePoint = function (point) {
            //Handled by deleteSelected()
        };

        if (this.data.attributes.json.points) {
            this.points = this.data.attributes.json.points.map(function (item) {
                return new flowchart.PointViewModel(item)
            })
        } else {
            if (this.start instanceof flowchart.ConnectorViewModel && this.end instanceof flowchart.ConnectorViewModel) {
                this.data.attributes.json.points = [{x: this.startTangentX(), y: this.startTangentY()},
                    {x: this.endTangentX(), y: this.endTangentY()}];

                this.points = this.data.attributes.json.points.map(function (item) {
                    return new flowchart.PointViewModel(item)
                });

            } else {
                this.data.attributes.json.points = [];
                this.points = [];
            }

        }

        this.genPath = function () {
            let path = 'M' + (this.startCoordX()) + ',' + (this.startCoordY()) + ' L ';
            this.points.forEach(function (point) {
                path = path + point.x() + ',' + point.y() + ' ';
            });
            return path + this.endCoordX() + ',' + this.endCoordY();
        };

        this.colour = function () {
            if (!this.data.attributes.json['colour']) {
                this.data.attributes.json['colour'] = "gray";
            }
            return this.data.attributes.json['colour'];
        };

        if (this.data.attributes.json['text'] == undefined) {
            this.data.attributes.json['text'] = {};
        }

        if (this.data.attributes.json.points.length > 1) {

            if (!this.data.attributes.json['text'].x || this.data.attributes.json['text'].x < 0) {
                this.data.attributes.json['text'].x = (this.points[0].x() + this.points[1].x()) / 2;
            }
            if (!this.data.attributes.json['text'].y || this.data.attributes.json['text'].y < 0) {
                this.data.attributes.json['text'].y = (this.points[0].y() + this.points[1].y()) / 2;
            }
        } else {
            if (!this.data.attributes.json['text'].x || this.data.attributes.json['text'].x < 0) {
                this.data.attributes.json['text'].x = (this.endCoordX() + this.startCoordX()) / 2;
            }
            if (!this.data.attributes.json['text'].y || this.data.attributes.json['text'].y < 0) {
                this.data.attributes.json['text'].y = (this.endCoordY() + this.startCoordY()) / 2;
            }
        }

        if (!this.data.attributes.json['text'].rotate) {
            this.data.attributes.json['text'].rotate = 0;
        }
        this.text = new flowchart.TextViewModel(this.data.attributes.json.text);

        //
        // Select the stream.
        //
        this.select = function () {
            this._selected = true;
        };

        //
        // Deselect the stream.
        //
        this.deselect = function () {
            this._selected = false;
        };

        //
        // Toggle the selection state of the stream.
        //
        this.toggleSelected = function () {
            this._selected = !this._selected;
        };

        //
        // Returns true if the stream is selected.
        //
        this.selected = function () {
            return this._selected;
        };

        //Highlight
        this.highlight = function () {
            this._highlighted = true;
        };

        this.unhighlight = function () {
            this._highlighted = false;
        };

        this.highlighted = function () {
            return this._highlighted;
        };
    };

    // Helper function.
    var computeStreamTangentOffset = function (pt1, pt2) {

        return (pt2.x - pt1.x) / 2;
    };

    // Compute the tangent for the bezier curve.
    flowchart.computeStreamStartTangentX = function (pt1, pt2) {

        return pt1.x + computeStreamTangentOffset(pt1, pt2);
    };

    // Compute the tangent for the bezier curve.
    flowchart.computeStreamStartTangentY = function (pt1, pt2) {
        return pt1.y;
    };

    // Compute the tangent for the bezier curve.
    flowchart.computeStreamStartTangent = function (pt1, pt2) {
        return {
            x: flowchart.computeStreamStartTangentX(pt1, pt2),
            y: flowchart.computeStreamStartTangentY(pt1, pt2)
        };
    };

    // Compute the tangent for the bezier curve.
    flowchart.computeStreamEndTangentX = function (pt1, pt2) {
        return pt2.x - computeStreamTangentOffset(pt1, pt2);
    };

    // Compute the tangent for the bezier curve.
    flowchart.computeStreamEndTangentY = function (pt1, pt2) {
        return pt2.y;
    };

    // Compute the tangent for the bezier curve.
    flowchart.computeStreamEndTangent = function (pt1, pt2) {
        return {
            x: flowchart.computeStreamEndTangentX(pt1, pt2),
            y: flowchart.computeStreamEndTangentY(pt1, pt2),
        };
    };

    // ***************************************************************************************************//
    // View model for the chart.
    // ***************************************************************************************************//
    flowchart.ChartViewModel = function (chartDataModel, config) {
        this.selectedGroups = {};
        this.config = config;
        this.scale = function () {
            if (!this.config.scale) {
                return 1;
            } else {
                if (this.config.scale <= 0 || this.config.scale > 500) {
                    return 1;
                }
                return this.config.scale / 100;
            }
        };
        this.left_offset = function () {
            if (!this.config.left_offset) {
                return 0;
            } else {
                return this.config.left_offset;
            }
        };
        this.top_offset = function () {
            if (!this.config.top_offset) {
                return 0;
            } else {
                return this.config.top_offset;
            }
        };


        this.deleteSeriesComponents = function (deleted_ids) {
            for (var deletedIndex = 0; deletedIndex < deleted_ids.length; ++deletedIndex) {
                var deleted_id = deleted_ids[deletedIndex];
                //Remove from data model
                for (var seriesIndex = 0; seriesIndex < this.data.series_components.length; ++seriesIndex) {
                    if (this.data.series_components[seriesIndex].id == deleted_id) {
                        this.data.series_components.splice(seriesIndex, 1);
                        break;
                    }
                }
                //Remove from view model
                for (var seriesIndex = 0; seriesIndex < this.series.length; ++seriesIndex) {
                    if (this.series[seriesIndex].data.id == deleted_id) {
                        this.series.splice(seriesIndex, 1);
                        break;
                    }
                }
            }
            ;
            this.report_groups = this.createReportGroups();
        };

        this.deleteConstantComponents = function (deleted_ids) {
            for (var deletedIndex = 0; deletedIndex < deleted_ids.length; ++deletedIndex) {
                var deleted_id = deleted_ids[deletedIndex];
                //Remove from data model
                for (var constantIndex = 0; constantIndex < this.data.constant_components.length; ++constantIndex) {

                    if (this.data.constant_components[constantIndex].id == deleted_id) {
                        this.data.constant_components.splice(constantIndex, 1);
                        break;
                    }
                }
                //Remove from view model
                for (var constantIndex = 0; constantIndex < this.constants.length; ++constantIndex) {
                    if (this.constants[constantIndex].data.id == deleted_id) {
                        this.constants.splice(constantIndex, 1);
                        break;
                    }
                }
            }
        };

        //Update stream info on connectorViewModel after deleting a stream
        this.updateStreamConnectors = function (stream) {
            var endConnector = this.findConnector(stream.data.relationships.end.data.id, stream.data.relationships.end_connector.data.id);
            var startConnector = this.findConnector(stream.data.relationships.start.data.id, stream.data.relationships.start_connector.data.id);

            //Update the connectors for stream input output rules
            startConnector.data.relationships.output_stream.data = null;
            endConnector.data.relationships.input_stream.data = null;

        };

        //Update process data model after stream is deleted
        this.updateProcessesStreams = function (stream, newProcesses) {
            for (var processIndex = 0; processIndex < newProcesses.length; ++processIndex) {
                var process = newProcesses[processIndex];
                for (var index = 0; index < process.relationships.output_streams.data.length; ++index) {
                    var processStream = process.relationships.output_streams.data[index];
                    if (stream.data.id == processStream.id) {
                        process.relationships.output_streams.data.splice(index, 1);
                    }
                }
                for (var index = 0; index < process.relationships.input_streams.data.length; ++index) {
                    processStream = process.relationships.input_streams.data[index];
                    if (stream.data.id == processStream.id) {
                        process.relationships.input_streams.data.splice(index, 1);
                    }
                }
            }
        };

        //////////////////FIND OBJECTS ON THE VIEW MODEL SECTION/////////////////////////////////////////////////////////
        //
        // Find a specific process within the chart.
        //
        this.findProcess = function (processID) {

            for (var i = 0; i < this.processes.length; ++i) {
                var process = this.processes[i];
                if (process.data.id == processID) {
                    return process;
                }
            }

            if (this.parent_process.data.id == processID) {
                return this.parent_process
            }

            //throw new Error("Failed to find process " + processID);
        };
        //
        // Find a specific process within the chart.
        //
        this.findStream = function (streamID) {

            for (var i = 0; i < this.streams.length; ++i) {
                var stream = this.streams[i];
                if (stream.data.id == streamID) {
                    return stream;
                }
            }
        };

        //
        // Find a specific input connector within the chart.
        //
        this.findConnector = function (processID, connectorID) {

            var process = this.findProcess(processID);

            for (var i = 0; i < process.connectors.length; ++i) {
                var connector = process.connectors[i];
                if (connector.data.id == connectorID) {
                    return connector;
                }
            }

            return false
        };
        //
        // Find a specific component, parent to series_component, within the chart.
        //
        this.findParentComponent = function (child_component_id, type) {
            let component;
            var process = this.parent_process;
            process.data.relationships[type].data.forEach(function (child_component) {
                if (child_component.id == child_component_id) {
                    component = process;
                }
            });
            for (var i = 0; i < this.processes.length; ++i) {
                var process = this.processes[i];
                process.data.relationships[type].data.forEach(function (child_component) {
                    if (child_component.id == child_component_id) {
                        component = process;
                    }
                })

            }
            for (var i = 0; i < this.streams.length; ++i) {
                var stream = this.streams[i];
                stream.data.relationships[type].data.forEach(function (child_component) {
                    if (child_component.id == child_component_id) {
                        component = stream;
                    }
                })
            }
            for (var i = 0; i < this.equipment.length; ++i) {
                var equipment = this.equipment[i];
                equipment.data.relationships[type].data.forEach(function (child_component) {
                    if (child_component.id == child_component_id) {
                        component = equipment;
                    }
                })
            }
            return component;
        };
        //
        // Find a specific series_component within the chart.
        //
        this.findSeriesComponent = function (series_componentID) {

            for (var i = 0; i < this.series.length; ++i) {
                var series_component = this.series[i];
                if (series_component.data.id == series_componentID) {
                    return series_component;
                }
            }
        };
        //////////////////END FIND OBJECTS ON THE VIEW MODEL////////////////////////////////////////////////////////

        //////////////////CREATE VIEW MODELS SECTION////////////////////////////////////////////////////////
        //
        // Create a view model for stream from the data model.
        //
        this._createStreamViewModel = function (streamDataModel) {
            if (!streamDataModel.relationships.end_connector.data || !streamDataModel.relationships.start_connector.data) {
                window.console.log("Stream has no connectors: " + streamDataModel.attributes.name);
                return false;
            }

            var startConnector = this.findConnector(streamDataModel.relationships.start.data.id, streamDataModel.relationships.start_connector.data.id);
            var endConnector = this.findConnector(streamDataModel.relationships.end.data.id, streamDataModel.relationships.end_connector.data.id);
            if (startConnector == false || endConnector == false) {
                return false
            }

            return new flowchart.StreamViewModel(streamDataModel, startConnector, endConnector);
        };

        //
        // Wrap the streams data-model in a view-model.
        //
        this._createStreamsViewModel = function (streamsDataModel) {

            var streamsViewModel = [];

            if (streamsDataModel) {
                for (var i = 0; i < streamsDataModel.length; ++i) {
                    var new_stream = this._createStreamViewModel(streamsDataModel[i]);
                    if (new_stream) {
                        streamsViewModel.push(new_stream);
                    } else {
                        //window.alert(streamsDataModel[i].attributes.name + ' has a missing connector');*********************************
                        console.log('Stream with missing connector found ' + streamsDataModel[i].id)
                    }

                }
            }

            return streamsViewModel;
        };

        //
        // Create a view model for series from the data model.
        //
        this._createSeriesViewModel = function (seriesDataModel, parent_process, parent_component) {
            return new flowchart.SeriesViewModel(seriesDataModel, parent_process, parent_component);
        };

        // Wrap the series data-model in a view-model.
        this._createSeriesViewModels = function (seriesDataModel) {
            var seriesViewModels = [];
            if (seriesDataModel) {
                for (var i = 0; i < seriesDataModel.length; ++i) {
                    //if(seriesDataModel[i].relationships.series.data){
                    var parent_component = this.findParentComponent(seriesDataModel[i].id, 'series_components');
                    var new_series = this._createSeriesViewModel(seriesDataModel[i], this.parent_process, parent_component);
                    if (new_series) {
                        seriesViewModels.push(new_series);
                    }
                    //}
                }
            }

            return seriesViewModels;
        };

        //
        // Create a view model for equipment from the data model.
        //
        this._createEquipmentViewModel = function (equipmentDataModel, parent_component, component_type) {
            return new flowchart.EquipmentViewModel(equipmentDataModel, parent_component, component_type);
        };

        //
        // Wrap the equipment data-model in a view-model.
        //
        this._createEquipmentViewModels = function (equipmentDataModel) {

            var equipmentViewModels = [];

            if (equipmentDataModel) {
                for (var i = 0; i < equipmentDataModel.length; ++i) {
                    var parent_component;
                    var component_type;
                    parent_component = this.findProcess(equipmentDataModel[i].relationships.component.data.id);
                    if (!parent_component) {
                        parent_component = this.findStream(equipmentDataModel[i].relationships.component.data.id);
                    }
                    // if (equipmentDataModel[i].relationships.component.data.type==='stream'){
                    // 	component_type = "Stream"
                    // 	parent_component = this.findStream(equipmentDataModel[i].relationships.component.data.id)
                    // }
                    // else if (equipmentDataModel[i].relationships.component.data.type==='process'){
                    // 	component_type = "Process";
                    // 	parent_component = this.findProcess(equipmentDataModel[i].relationships.component.data.id)
                    // }

                    var new_equipment = this._createEquipmentViewModel(equipmentDataModel[i], parent_component, component_type);
                    if (new_equipment) {
                        equipmentViewModels.push(new_equipment);
                    }
                }
            }

            return equipmentViewModels;
        };

        // Create a view model for series from the data model.
        this._createConstantViewModel = function (constantDataModel, parent_process, parent_component) {
            return new flowchart.ConstantViewModel(constantDataModel, parent_process, parent_component);
        };

        this._createConstantViewModels = function (constantDataModel) {
            var constantViewModels = [];
            if (constantDataModel) {
                for (var i = 0; i < constantDataModel.length; ++i) {
                    var parent_component = this.findParentComponent(constantDataModel[i].id, 'constant_components');
                    var new_constant = this._createConstantViewModel(constantDataModel[i], this.parent_process, parent_component);
                    if (new_constant) {
                        //new_constant.prototype = Object.create(flowchart.componentBase.prototype);

                        constantViewModels.push(new_constant);
                    }
                }
            }

            return constantViewModels;
        };

        this.createParentProcess = function (parent_process, connectors) {

            var width = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;

            var height = window.innerHeight
                || document.documentElement.clientHeight
                || document.body.clientHeight;

            this.parent_process = new flowchart.ProcessViewModel(parent_process, true, connectors);
            //@ts-ignore
            if (!parent_process.attributes.json.windowWidth > 0.0) {
                parent_process.attributes.json.windowWidth = width - 225;
            }
            //@ts-ignore
            if (!parent_process.attributes.json.windowHeight > 0) {
                parent_process.attributes.json.windowHeight = height - 225;
            }

            this.data.parent_process = parent_process;

        };

        //////////////////END CREATE VIEW MODELS SECTION////////////////////////////////////////////////////////

        // Reference to the underlying data.
        this.data = chartDataModel;

        // Create a view-model for processes.
        if (this.data.processes == null) {
            this.processes = [];
            this.data.processes = [];

        } else {
            this.processes = createProcessesViewModel(this.data.processes, this.data.connectors);
        }

        if (this.data.parent_process == null) {
            this.data.parent_process = this.createParentProcess({})
        } else {
            this.createParentProcess(this.data.parent_process, this.data.connectors);
        }

        // Create a view-model for streams.
        if (this.data.streams == null) {
            this.streams = [];
            this.data.streams = [];
        } else {
            this.streams = this._createStreamsViewModel(this.data.streams);
        }

        // Create a view-model for equipment.
        if (this.data.equipment == null) {
            this.equipment = [];
            this.data.equipment = [];
        } else {
            this.equipment = this._createEquipmentViewModels(this.data.equipment);
        }

        // Create a view-model for series_components.
        if (this.data.series_components == null) {
            this.series = [];
            this.data.series_components = [];
        } else {
            this.series = this._createSeriesViewModels(this.data.series_components);
        }

        // Create a view-model for constant_components.
        if (this.data.constant_components == null) {
            this.constants = [];
            this.data.constant_components = [];
        } else {
            this.constants = this._createConstantViewModels(this.data.constant_components);
        }

        //
        // Update parent_component view model
        //
        this.updateParentComponent = function (component, parent_component_data) {
            var parent_component;
            if (parent_component_data.type == 'process') {
                parent_component = this.findProcess(parent_component_data.id);
            } else {
                parent_component = this.findStream(parent_component_data.id);
            }
            component.parent_component = parent_component;
        };
        //
        //Add Connector to the view model (after api save)
        //
        this.addConnector = function (connector, process) {
            var connectorViewModel = new flowchart.ConnectorViewModel(connector, process);

            // Add to process's view model and data model.
            process.connectors.push(connectorViewModel);
            process.data.relationships.connectors.data.push({id: connector.id, type: 'connector'});

            connector.attributes.percent = connectorViewModel.data.attributes.percent;

            //Add connectors to data model
            this.data.connectors.push(connector);
            connectorViewModel.select();

        };

        //Add Custom chart to the view model (after api save)
        this.addCustomChart = function (chart, parent_process) {
            var customChartViewModel = new flowchart.CustomChartViewModel(
                chart, parent_process, parent_process.data.attributes.json.charts.length - 1);

            // Add to process's view model and data model.
            parent_process.custom_charts.push(customChartViewModel);
        };

        //Add Custom chart to the view model (after api save)
        this.addImage = function (image, component, is_parent) {
            if (is_parent) {
                var imageViewModel = new flowchart.ImageViewModel(
                    image, component, component.data.attributes.json.images.length - 1);

                if (!component.images) {
                    component.images = [];
                }
                // Add to process's view model and data model.
                component.images.push(imageViewModel);
            } else {
                var imageViewModel = new flowchart.ImageViewModel(
                    image, component);

                // Add to component's view model and data model.
                component.image = imageViewModel;
                return component.image;
            }
        };

        //Add Context to the view model (after api save)
        this.addContext = function (context, parent_process) {
            var contextViewModel = new flowchart.ContextViewModel(
                context, parent_process, parent_process.data.attributes.json.contexts.length - 1);

            // Add to process's view model and data model.
            parent_process.contexts.push(contextViewModel);
        };
        //
        // Create a view model for a new stream.
        //
        this.createNewStream = function (stream, startConnector, endConnector) {

            if (!this.data.streams) {
                this.data.streams = [];
            }

            //var startProcess = startConnector.parentProcess();
            //
            //var endProcess = endConnector.parentProcess();
            //
            //if (startProcess == endProcess) {
            //	throw new Error("Failed to create stream. Cannot link a process with itself.")
            //}

            //create the connector
            //create the view model
            var streamViewModel = new flowchart.StreamViewModel(stream, startConnector, endConnector);
            this.streams.push(streamViewModel);
            this.data.streams.push(streamViewModel.data);

        };

        //
        // Add a process to the view model.
        //
        this.addProcess = function (processDataModel) {
            //if (!this.data.processes.data) {
            //	this.data.processes.data = [];
            //}

            //
            // Update the data model.
            //
            this.data.processes.push(processDataModel);

            //
            // Update the view model.
            //
            var new_process = new flowchart.ProcessViewModel(processDataModel);
            this.processes.push(new_process);

            //Update the parent data model
            this.parent_process.data.relationships.children.data.push({id: processDataModel.id, type: 'process'});

            return new_process;
        };

        //
        // Add a equipment to the view model.
        //
        this.addEquipment = function (equipmentDataModel) {
            //
            // Update the data model.
            //
            this.data.equipment.push(equipmentDataModel);
            //
            // Update the view model.
            //
            var component_type;
            var parent_component;
            parent_component = this.findProcess(equipmentDataModel.relationships.component.data.id);
            if (!parent_component) {
                parent_component = this.findStream(equipmentDataModel.relationships.component.data.id);
            }
            parent_component.data.relationships.equipment.data.push({id: equipmentDataModel.id, type: 'equipment'});
            // 			component_type = "Stream"
            // 			parent_component = this.findStream(equipmentDataModel.relationships.component.data.id)
            // }
            // else if (equipmentDataModel.relationships.component.data.type==='process'){
            // 			component_type = "Process";
            // 			parent_component = this.findProcess(equipmentDataModel.relationships.component.data.id);
            // 			console.log("process");
            // }

            var new_equipment = new flowchart.EquipmentViewModel(equipmentDataModel, parent_component, component_type);
            this.equipment.push(new_equipment);

            return new_equipment;

        };

        //
        // Add a series component to the data and view models.
        //
        this.addSeries = function (seriesComponentDataModel, parent_process, parent_component) {
            //var exists = this.findSeriesComponent(seriesComponentDataModel.id);

            //if (exists == null){
            // Update the chart data model.
            this.data.series_components.push(seriesComponentDataModel);

            // Update the chart view model.
            this.series.push(new flowchart.SeriesViewModel(seriesComponentDataModel, parent_process, parent_component));

            this.report_groups = this.createReportGroups();
            //}
        };

        //
        // Add a series component to the data and view models.
        //
        this.addConstantComponent = function (constantComponentDataModel, parent_process, parent_component) {
            var chart_component = this.findParentComponent(constantComponentDataModel.id, 'constant_components');

            // Update the data model.
            this.data.constant_components.push(constantComponentDataModel);

            // Update the view model.
            var temp = this.constants.push(new flowchart.ConstantViewModel(constantComponentDataModel, parent_process, chart_component));

        };

        //
        // Select all processes and streams in the chart.
        //
        this.selectAll = function () {

            var processes = this.processes;
            for (var i = 0; i < processes.length; ++i) {
                var process = processes[i];
                process.select();
            }

            var streams = this.streams;
            for (var i = 0; i < streams.length; ++i) {
                var stream = streams[i];
                stream.select();
                stream.points.map(function (point) {
                    point.selected();
                });
                stream.text.select();
            }
        };

        //
        // Deselect all processes and streams in the chart.
        //
        this.deselectAll = function () {

            this.processes.map(function (process) {
                process.deselect();
                process.text.deselect();
                process.unhighlight();
                if (process.image) {
                    process.image.deselect();
                }
                process.connectors.map(function (connector) {
                    connector.deselect();
                    connector.text.deselect();
                })
            });

            var streams = this.streams;
            for (var i = 0; i < streams.length; ++i) {
                var stream = streams[i];
                stream.deselect();
                stream.text.deselect();
                stream.unhighlight();
                stream.points.map(function (point) {
                    point.deselect()
                })
            }

            var equipments = this.equipment;
            for (var i = 0; i < equipments.length; ++i) {
                var equipment = equipments[i];
                equipment.deselect();
                equipment.text.deselect();
            }

            for (var i = 0; i < this.series.length; ++i) {
                var series = this.series[i];
                series.deselect();
                //series.text.deselect();
            }
            for (var i = 0; i < this.constants.length; ++i) {
                var constant = this.constants[i];
                constant.deselect();
            }

            this.selectedGroups = {};
            this.parent_process.connectors.map(function (connector) {
                connector.deselect();
                connector.text.deselect();
            });
            this.parent_process.custom_charts.map(function (chart) {
                chart.deselect();
            });
            if (this.parent_process.images) {
                this.parent_process.images.map(function (image) {
                    image.deselect();
                });
            }
            this.parent_process.contexts.map(function (context) {
                context.deselect();
            })
        };

        //
        // Toggle the connector circles.
        //
        this.showconnectors = function () {
            this._showconnectors = true;
        };
        this.hideconnectors = function () {
            this._showconnectors = false;
        };
        this.toggleConnectors = function () {
            this._showconnectors = !this._showconnectors;
        };
        this.connectorsShown = function () {
            return this._showconnectors;
        };

        //
        // Toggle the point circles.
        //
        this.showpoints = function () {
            this._showpoints = true;
        };
        this.hidepoints = function () {
            this._showpoints = false;
        };
        this.togglePoints = function () {
            this._showpoints = !this._showpoints;
        };
        this.pointsShown = function () {
            return this._showpoints;
        };

        //
        // Toggle the background grid.
        //
        this.showgrid = function () {
            this._showgrid = true;
        };
        this.hidegrid = function () {
            this._showgrid = false;
        };
        this.toggleGrid = function () {
            this._showgrid = !this._showgrid;
        };
        this.gridShown = function () {
            return this._showgrid;

        };
        this.streammode = false;
        this.editmode = false;

        //MOVING VIEWMODEL OBJECTS AROUND SECTION/////////////////////////////////////////////////////////////////////
        //
        // Update the location of the process and its connectors.
        //
        this.updateSelectedProcessesLocation = function (deltaX, deltaY) {
            let selectedProcesses = this.getSelectedProcesses();
            for (let i = 0; i < selectedProcesses.length; ++i) {
                let process = selectedProcesses[i];
                process.data.attributes.json.x += deltaX;
                process.data.attributes.json.y += deltaY;
            }
        };

        // Update the location of the equipment.
        this.updateSelectedEquipmentLocation = function (deltaX, deltaY) {
            let selectedEquipment = this.getSelectedEquipment();
            for (let i = 0; i < selectedEquipment.length; ++i) {
                let equipment = selectedEquipment[i];
                equipment.data.attributes.json.x += deltaX;
                equipment.data.attributes.json.y += deltaY;
            }
        };

        // Update the location of the custom chart.
        this.updateSelectedCustomChartsLocation = function (deltaX, deltaY) {
            let selectedCustomCharts = this.getSelectedCustomCharts();
            for (let i = 0; i < selectedCustomCharts.length; ++i) {
                let customChart = selectedCustomCharts[i];
                customChart.data.x += deltaX;
                customChart.data.y += deltaY;
            }
        };

        // Update the location of the images.
        this.updateSelectedImagesLocation = function (deltaX, deltaY) {
            let selectedImages = this.getSelectedImages();
            for (let i = 0; i < selectedImages.length; ++i) {
                let image = selectedImages[i];
                image.data.x += deltaX;
                image.data.y += deltaY;
            }
        };

        // Update the location of the context.
        this.updateSelectedContextsLocation = function (deltaX, deltaY) {
            let selectedContexts = this.getSelectedContexts();
            for (let i = 0; i < selectedContexts.length; ++i) {
                let context = selectedContexts[i];
                context.data.x += deltaX;
                context.data.y += deltaY;
            }
        };

        //Update report group table location (uses the top most series)
        //
        //function updateSelectedGroupLocation (group){
        this.updateSelectedGroupLocation = function (group) {
            //Set the starting location of the group based on the highest (lowest x()) series in the group
            //Set the bottom extent of the group
            let x = null;
            let y = null;
            group.show_status = false;
            if (group.series) {
                group.series.forEach(function (series) {
                    if (y === null || series.y() < y) {
                        x = series.x();
                        y = series.y();
                    }
                    if (series.show_status() === true) {
                        group.show_status = true;
                    }
                    if (series.view_on_flowchart() === true) {
                        group.view_on_flowchart = true;
                    }
                });
                group.x = x;
                group.y = y;
                group.xEnd = group.x + group.series[0].width();
                group.yEnd = (group.series[0].height() * group.series.length) + group.y;
            }
        };

        this.createReportGroups = function () {
            const ctrl = this;
            let report_groups = {};
            this.series.forEach(function (item) {
                if (report_groups.hasOwnProperty(item.parent_component.data.id + "_" + item.data.attributes.report_group)) {
                    report_groups[item.parent_component.data.id + "_" + item.data.attributes.report_group].series.push(item)
                } else {
                    if (item.data.attributes.report_group === '') {
                        item.data.attributes.report_group = null;
                    }
                    report_groups[item.parent_component.data.id + "_" + item.data.attributes.report_group] = {
                        "series": [item],
                        "name": item.data.attributes.report_group,
                        "component_id": item.parent_component.data.id,
                        "x": item.x(),
                        "y": item.y()
                    };
                }
            });

            let parent = deepCopy(this);
            Object.keys(report_groups).forEach(function (key) {
                let group = report_groups[key];
                parent.updateSelectedGroupLocation(group);
            });
            return report_groups;
        };

        this.report_groups = this.createReportGroups();

        // Update the location of the process and its connectors.
        this.updateSelectedSeriesLocation = function (deltaX, deltaY, group) {

            let selectedSeries = this.getSelectedSeries();
            for (let i = 0; i < selectedSeries.length; ++i) {
                let series = selectedSeries[i];
                series.data.attributes.json[series.tree_position].x += deltaX;
                series.data.attributes.json[series.tree_position].y += deltaY;
            }
            if (group) {
                this.updateSelectedGroupLocation(group);
            }
        };

        // Update the location of the constant_component.
        this.updateSelectedConstantsLocation = function (deltaX, deltaY) {

            var selectedConstants = this.getSelectedConstants();

            for (var i = 0; i < selectedConstants.length; ++i) {
                var constant = selectedConstants[i];
                constant.data.attributes.json[constant.tree_position].x += deltaX;
                constant.data.attributes.json[constant.tree_position].y += deltaY;
            }
        };

        // Update the location of the process text.
        this.updateSelectedTextLocation = function (deltaX, deltaY) {

            let selectedText = this.getSelectedText();
            for (let i = 0; i < selectedText.length; ++i) {
                let text = selectedText[i];
                text.data.x += deltaX;
                text.data.y += deltaY;
            }
        };

        // Update the location of the points.
        this.updateSelectedPointsLocation = function (deltaX, deltaY) {
            let selectedPoints = this.getSelectedPoints();
            selectedPoints.map(function (point) {
                point.data.x += deltaX;
                point.data.y += deltaY;
            });

        };

        // Update the location of the connectors.
        this.updateSelectedConnectorsLocation = function (deltaP) {

            var selectedConnectors = this.getSelectedConnectors();

            selectedConnectors.map(function (connector) {
                if (connector.is_parent) {
                    connector.data.attributes.json.parent.percent = Number(connector.data.attributes.json.parent.percent) + deltaP;
                } else {
                    connector.data.attributes.percent = Number(connector.data.attributes.percent) + deltaP;
                }
                //to prevent the circle from sticking if slightly overdragged...
                if (connector.data.attributes.percent < 0) {
                    connector.data.attributes.percent = 0;
                }
                if (connector.data.attributes.percent > 100) {
                    connector.data.attributes.percent = 0
                }
                if (connector.is_parent && connector.data.attributes.json.parent.percent < 0) {
                    connector.data.attributes.json.parent.percent = 0;
                }
                if (connector.is_parent && connector.data.attributes.json.parent.percent > 100) {
                    connector.data.attributes.json.parent.percent = 0
                }
            });

        };

        //// END MOVING VIEWMODEL OBJECTS AROUND SECTION/////////////////////////////////////////////////////////////////////

        // //
        // // Handle mouse click on a particular process.
        // //TODO is this used still?
        // this.handleProcessClicked = function (process, ctrlKey) {
        //
        //     if (ctrlKey) {
        //         process.toggleSelected();
        //     } else {
        //         this.deselectAll();
        //         process.select();
        //     }
        //
        //     // Move process to the end of the list so it is rendered after all the other.
        //     // This is the way Z-order is done in SVG.
        //
        //     var processIndex = this.processes.indexOf(process);
        //     if (processIndex == -1) {
        //         throw new Error("Failed to find process in view model!");
        //     }
        //     this.processes.splice(processIndex, 1);
        //     this.processes.push(process);
        // };
        //
        // //
        // // Handle mouse down on a stream.
        // //TODO is this used still?
        // this.handleStreamMouseDown = function (stream, ctrlKey) {
        //
        //     if (ctrlKey) {
        //         stream.toggleSelected();
        //         stream.text.toggleSelected();
        //     } else {
        //         this.deselectAll();
        //         stream.select();
        //         stream.text.select();
        //     }
        // };

        //
        // Delete all processes and streams that are selected.
        //
        this.deleteSelected = function () {
            var newProcessViewModels = [];
            var newProcessDataModels = [];
            var newConnectorDataModels = [];
            var newSeriesComponentDataModel = [];

            var deletedProcessIds = [];
            var deletedSeriesComponentIds = [];
            var deletedConstantComponentIds = [];

            //
            // Sort into:
            //		items to keep and
            //		items to delete.
            //

            //Update series models
            var newSeriesComponentViewModels = [];
            var newSeriesComponentDataModels = [];
            var newConstantComponentViewModels = [];
            var newConstantComponentDataModels = [];

            for (var seriesIndex = 0; seriesIndex < this.series.length; ++seriesIndex) {

                var series = this.series[seriesIndex];

                if (!series.selected()) {

                    // Only retain non-selected series.
                    newSeriesComponentViewModels.push(series);
                    newSeriesComponentDataModels.push(series.data);
                } else {
                    //Delete the relationship off the parent
                    var parent = this.findParentComponent(series.data.id, 'series_components');
                    var scIndex = parent.data.relationships.series_components.data.length;
                    while (scIndex--) {
                        if (parent.data.relationships.series_components.data[scIndex].id == series.data.id) {
                            parent.data.relationships.series_components.data.splice(scIndex, 1);
                        }
                    }
                }
            }
            for (var constantIndex = 0; constantIndex < this.constants.length; ++constantIndex) {

                var constant = this.constants[constantIndex];

                if (!constant.selected()) {

                    // Only retain non-selected series.
                    newConstantComponentViewModels.push(constant);
                    newConstantComponentDataModels.push(constant.data);
                } else {
                    //Delete the relationship off the parent
                    var parent = this.findParentComponent(constant.data.id, 'constant_components');
                    var ccIndex = parent.data.relationships.constant_components.data.length;
                    while (ccIndex--) {
                        if (parent.data.relationships.constant_components.data[ccIndex].id == constant.data.id) {
                            parent.data.relationships.constant_components.data.splice(ccIndex, 1);
                        }
                    }
                }
            }

            //Update process models
            for (var processIndex = 0; processIndex < this.processes.length; ++processIndex) {

                var process = this.processes[processIndex];

                var newProcessConnectorsDataModel = [];
                var newProcessConnectorsViewModel = [];

                if (!process.selected()) {

                    for (var connectorIndex = 0; connectorIndex < process.connectors.length; ++connectorIndex) {
                        var connector = process.connectors[connectorIndex];

                        if (!connector.selected() || connector.data.relationships.input_stream.data !== null || connector.data.relationships.output_stream.data !== null) {
                            newProcessConnectorsViewModel.push(connector);
                            newProcessConnectorsDataModel.push({id: connector.data.id, type: 'connector'});
                            newConnectorDataModels.push(connector.data);
                        }
                    }
                    process.connectors = newProcessConnectorsViewModel;
                    process.data.relationships.connectors.data = newProcessConnectorsDataModel;

                    // Only retain non-selected processes.
                    newProcessViewModels.push(process);
                    newProcessDataModels.push(process.data);
                } else {
                    // Keep track of processes that were deleted, so their streams can also
                    // be deleted.
                    process.data.relationships.series_components.data.map(function (sc) {
                        deletedSeriesComponentIds.push(sc.id);
                    });
                    process.data.relationships.constant_components.data.map(function (cc) {
                        deletedConstantComponentIds.push(cc.id);
                    });
                    deletedProcessIds.push(process.data.id);
                }
            }

            var newProcessConnectorsDataModel = [];
            var newProcessConnectorsViewModel = [];

            for (var connectorIndex = 0; connectorIndex < this.parent_process.connectors.length; ++connectorIndex) {
                var connector = this.parent_process.connectors[connectorIndex];

                if (!connector.selected() || connector.data.relationships.input_stream.data !== null || connector.data.relationships.output_stream.data !== null) {
                    newProcessConnectorsViewModel.push(connector);
                    newProcessConnectorsDataModel.push({id: connector.data.id, type: 'connector'});
                    newConnectorDataModels.push(connector.data);
                }
            }
            this.parent_process.connectors = newProcessConnectorsViewModel;
            this.parent_process.data.relationships.connectors.data = newProcessConnectorsDataModel;

            var newStreamViewModels = [];
            var newStreamDataModels = [];

            //
            // Remove streams that are selected.
            // Also remove streams for processes that have been deleted.
            // Remove selected points (for streams that have more than 2 points?)
            //
            for (var streamIndex = 0; streamIndex < this.streams.length; ++streamIndex) {
                var stream = this.streams[streamIndex];
                var newPointsViewModel = [];
                var newPointsDataModel = [];

                if (!stream.selected() &&
                    deletedProcessIds.indexOf(stream.data.relationships.start.data.id) === -1 &&
                    deletedProcessIds.indexOf(stream.data.relationships.end.data.id) === -1) {

                    for (var pointIndex = 0; pointIndex < stream.points.length; ++pointIndex) {
                        var point = stream.points[pointIndex];
                        if (!point.selected()) {
                            newPointsViewModel.push(point);
                            newPointsDataModel.push(point.data);
                        }
                    }
                    stream.points = newPointsViewModel;
                    stream.data.attributes.json.points = newPointsDataModel;

                    //
                    // The processes this stream is attached to, were not deleted,
                    // so keep the stream.
                    //
                    newStreamViewModels.push(stream);
                    newStreamDataModels.push(stream.data);
                } else {
                    //Add to list of series_components to delete
                    stream.data.relationships.series_components.data.map(function (sc) {
                        deletedSeriesComponentIds.push(sc.id);
                    });
                    stream.data.relationships.constant_components.data.map(function (cc) {
                        deletedConstantComponentIds.push(cc.id);
                    });

                    //Update connectors for input output streams
                    this.updateStreamConnectors(stream);

                    //Update process data model for deleted streams
                    this.updateProcessesStreams(stream, newProcessDataModels);

                }
            }

            //Update equipment models
            var newEquipmentViewModels = [];
            var newEquipmentDataModels = [];

            for (var equipmentIndex = 0; equipmentIndex < this.equipment.length; ++equipmentIndex) {

                var equipment = this.equipment[equipmentIndex];

                if (!equipment.selected()) {

                    // Only retain non-selected equipment.
                    newEquipmentViewModels.push(equipment);
                    newEquipmentDataModels.push(equipment.data);
                } else {
                    //Add to list of series_components to delete
                    equipment.parent_component.data.relationships.equipment.data =
                        equipment.parent_component.data.relationships.equipment.data.filter(function (item) {
                            return item.id != equipment.data.id
                        });
                    equipment.data.relationships.series_components.data.map(function (sc) {
                        deletedSeriesComponentIds.push(sc.id);
                    });
                    equipment.data.relationships.constant_components.data.map(function (cc) {
                        deletedSeriesComponentIds.push(cc.id);
                    });
                }
            }

            //
            //Update parent data model
            //
            if (deletedProcessIds.length > 0) {
                var newProcessChildrenDataModels = [];
                for (var childIndex = 0; childIndex < newProcessDataModels.length; ++childIndex) {
                    var child = {id: newProcessDataModels[childIndex].id, type: "process"};
                    newProcessChildrenDataModels.push(child);
                }
                this.parent_process.data.relationships.children.data = newProcessChildrenDataModels;
            }

            //
            // Update processes, connectors and streams.
            //
            this.data.series_components = newSeriesComponentDataModels;
            this.series = newSeriesComponentViewModels;
            this.deleteSeriesComponents(deletedSeriesComponentIds);
            //this.report_groups = this.createReportGroups(); - called in deleteSeriesComponents

            this.data.constant_components = newConstantComponentDataModels;
            this.constants = newConstantComponentViewModels;
            this.deleteConstantComponents(deletedConstantComponentIds);

            this.data.connectors = newConnectorDataModels;
            this.processes = newProcessViewModels;
            this.data.processes = newProcessDataModels;
            this.streams = newStreamViewModels;
            this.data.streams = newStreamDataModels;
            this.data.equipment = newEquipmentDataModels;
            this.equipment = newEquipmentViewModels;

        };

        //Generic function to replace separate applySelectionRect sections
        this.getSelectedRect = function (selectionRect, parent_array) {
            for (let i = 0; i < parent_array.length; ++i) {
                let viewModel = parent_array[i];
                if (viewModel.x() >= selectionRect.x &&
                    viewModel.y() >= selectionRect.y &&
                    viewModel.x() + viewModel.width() <= selectionRect.x + selectionRect.width &&
                    viewModel.y() + viewModel.height() <= selectionRect.y + selectionRect.height) {
                    // Select processes that are within the selection rect.
                    viewModel.select();
                }
            }
        }
        //
        // Select processes and streams that fall within the selection rect.
        //
        this.applySelectionRect = function (selectionRect) {
            const ctrl = this;

            this.deselectAll();

            for (let i = 0; i < this.processes.length; ++i) {
                let process = this.processes[i];
                if (process.x() >= selectionRect.x &&
                    process.y() >= selectionRect.y &&
                    process.x() + process.width() <= selectionRect.x + selectionRect.width &&
                    process.y() + process.height() <= selectionRect.y + selectionRect.height) {
                    // Select processes that are within the selection rect.
                    process.select();
                }
                if (process.image) {
                    this.getSelectedRect(selectionRect, [process.image]);
                }
            }

            for (let i = 0; i < this.streams.length; ++i) {
                let stream = this.streams[i];
                if (stream.start.parentProcess().selected() &&
                    stream.end.parentProcess().selected()) {
                    // Select the stream if both its parent processes are selected.
                    stream.select();
                    stream.text.select();
                }

                stream.points.map(function (point) {

                    if (point.x() >= selectionRect.x &&
                        point.y() >= selectionRect.y &&
                        point.x() <= selectionRect.x + selectionRect.width &&
                        point.y() <= selectionRect.y + selectionRect.height) {
                        point.select()
                    }

                });
                if (stream.text.x() >= selectionRect.x &&
                    stream.text.y() >= selectionRect.y &&
                    stream.text.x() <= selectionRect.x + selectionRect.width &&
                    stream.text.y() <= selectionRect.y + selectionRect.height) {
                    // Select processes that are within the selection rect.
                    stream.text.select();
                }
            }

            for (let i = 0; i < this.equipment.length; ++i) {
                let equipment = this.equipment[i];
                if (equipment.x() >= selectionRect.x &&
                    equipment.y() >= selectionRect.y &&
                    equipment.x() + equipment.width() <= selectionRect.x + selectionRect.width &&
                    equipment.y() + equipment.height() <= selectionRect.y + selectionRect.height) {
                    // Select processes that are within the selection rect.
                    equipment.select();
                }
            }

            for (let i = 0; i < this.parent_process.custom_charts.length; ++i) {
                let chart = this.parent_process.custom_charts[i];
                if (chart.x() >= selectionRect.x &&
                    chart.y() >= selectionRect.y &&
                    chart.x() + chart.width() <= selectionRect.x + selectionRect.width &&
                    chart.y() + chart.height() <= selectionRect.y + selectionRect.height) {
                    // Select custom_charts that are within the selection rect.
                    chart.select();
                }
            }

            // for (let i = 0; i < this.parent_process.images.length; ++i) {
            //     let image = this.parent_process.images[i];
            //     if (image.x() >= selectionRect.x &&
            //         image.y() >= selectionRect.y &&
            //         image.x() + image.width() <= selectionRect.x + selectionRect.width &&
            //         image.y() + image.height() <= selectionRect.y + selectionRect.height) {
            //         // Select custom_charts that are within the selection rect.
            //         image.select();
            //     }
            // }
            if (this.parent_process.image) {
                this.getSelectedRect(selectionRect, this.parent_process.images);
            }

            for (let i = 0; i < this.parent_process.contexts.length; ++i) {
                let context = this.parent_process.contexts[i];
                if (context.x() >= selectionRect.x &&
                    context.y() >= selectionRect.y &&
                    context.x() + context.width() <= selectionRect.x + selectionRect.width &&
                    context.y() + context.height() <= selectionRect.y + selectionRect.height) {
                    // Select custom_charts that are within the selection rect.
                    context.select();
                }
            }

            for (let i = 0; i < this.constants.length; ++i) {
                let constant = this.constants[i];
                if (constant.x() >= selectionRect.x &&
                    constant.y() >= selectionRect.y &&
                    constant.x() + (constant.width() * 3 / 4) <= selectionRect.x + selectionRect.width &&
                    constant.y() + constant.height() <= selectionRect.y + selectionRect.height) {
                    // Select series that are within the selection rect.
                    constant.select();
                }
            }

            for (let i = 0; i < this.series.length; ++i) {
                let series = this.series[i];
                if (series.x() >= selectionRect.x &&
                    series.y() >= selectionRect.y &&
                    series.x() + (series.width() * 3 / 4) <= selectionRect.x + selectionRect.width &&
                    series.y() + series.height() <= selectionRect.y + selectionRect.height) {
                    // Select series that are within the selection rect.
                    series.select();
                }
            }

            let report_groups = this.report_groups;
            this.selectedGroups = {};
            let selectedGroups = this.selectedGroups;
            Object.keys(report_groups).forEach(function (key) {
                let group = report_groups[key];
                if (group.series.length > 1 && group.name !== null) {
                    //Deselect all individual series that may have fallen into the selection rectangle
                    for (let i = 0; i < group.series.length; ++i) {
                        let series = group.series[i];
                        series.deselect();
                    }
                    if (group.x >= selectionRect.x &&
                        group.y >= selectionRect.y &&
                        group.xEnd <= selectionRect.x + selectionRect.width &&
                        group.yEnd <= selectionRect.y + selectionRect.height) {
                        // Select all series within the group.
                        for (let i = 0; i < group.series.length; ++i) {
                            let series = group.series[i];
                            // Select all series within the group.
                            series.select();
                        }
                        //And indicate the group as selected
                        selectedGroups[group.name] = true;
                    }
                }
            });
            let all = this.getSelected();
            if (all.length < 1) {
                all = [this.parent_process];
            }
            //TODO use EventEmitter
        };

        // Get the array of processes that are currently selected.
        this.getSelectedProcesses = function () {
            var selectedProcesses = [];

            for (var i = 0; i < this.processes.length; ++i) {
                var process = this.processes[i];
                if (process.selected()) {
                    selectedProcesses.push(process);
                }
            }

            return selectedProcesses;
        };

        // Get the array of series that are currently selected.
        this.getSelectedSeries = function () {
            var selectedSeries = [];

            for (var i = 0; i < this.series.length; ++i) {
                var series = this.series[i];
                if (series.selected()) {
                    selectedSeries.push(series);
                }
            }

            return selectedSeries;
        };

        // TODO: Get groups selected
        this.getSelectedGroups = function () {

        };

        // Get the array of series that are currently selected.
        this.getSelectedConstants = function () {
            var selectedConstants = [];

            for (var i = 0; i < this.constants.length; ++i) {
                var constant = this.constants[i];
                if (constant.selected()) {
                    selectedConstants.push(constant);
                }
            }

            return selectedConstants;
        };

        // Get the array of equipment that are currently selected.
        this.getSelectedEquipment = function () {
            var selectedEquipment = [];

            for (var i = 0; i < this.equipment.length; ++i) {
                var equipment = this.equipment[i];
                if (equipment.selected()) {
                    selectedEquipment.push(equipment);
                }
            }

            return selectedEquipment;
        };

        // Get the array of custom charts that are currently selected.
        this.getSelectedCustomCharts = function () {
            var selectedCustomCharts = [];

            for (var i = 0; i < this.parent_process.custom_charts.length; ++i) {
                var chart = this.parent_process.custom_charts[i];
                if (chart.selected()) {
                    selectedCustomCharts.push(chart);
                }
            }
            return selectedCustomCharts;
        };

        // Get the array of custom charts that are currently selected.
        this.getSelectedImages = function () {
            var selectedImages = [];

            for (var i = 0; i < this.parent_process.images.length; ++i) {
                var image = this.parent_process.images[i];
                if (image.selected()) {
                    selectedImages.push(image);
                }
            }
            for (var i = 0; i < this.processes.length; ++i) {
                var process = this.processes[i];
                if (process.image && process.image.selected()) {
                    selectedImages.push(process.image);
                }
            }
            return selectedImages;
        };

        // Get the array of custom charts that are currently selected.
        this.getSelectedContexts = function () {
            var selectedContexts = [];

            for (var i = 0; i < this.parent_process.contexts.length; ++i) {
                var context = this.parent_process.contexts[i];
                if (context.selected()) {
                    selectedContexts.push(context);
                }
            }
            return selectedContexts;
        };

        this.getSelectedText = function () {
            var selectedText = [];

            for (var i = 0; i < this.processes.length; ++i) {
                var process = this.processes[i];
                var processtext = this.processes[i].text;
                if (processtext.selected()) {
                    selectedText.push(processtext);
                }
                for (var c = 0; c < process.connectors.length; ++c) {
                    var connectortext = process.connectors[c].text;
                    if (connectortext.selected()) {
                        selectedText.push(connectortext);
                    }
                }
            }
            for (var i = 0; i < this.streams.length; ++i) {
                var streamtext = this.streams[i].text;
                if (streamtext.selected()) {
                    selectedText.push(streamtext);
                }
            }
            for (var i = 0; i < this.equipment.length; ++i) {
                var equipmenttext = this.equipment[i].text;
                if (equipmenttext.selected()) {
                    selectedText.push(equipmenttext);
                }
            }

            for (var i = 0; i < this.series.length; ++i) {
                // var seriestext = this.series[i].text;
                // if (seriestext.selected()) {
                //     selectedText.push(seriestext);
                // }
            }

            return selectedText;
        };

        // Get the array of points that are currently selected.
        this.getSelectedPoints = function () {
            var selectedPoints = [];

            for (var i = 0; i < this.streams.length; ++i) {
                var stream = this.streams[i];
                stream.points.map(function (point) {

                    if (point.selected()) {
                        selectedPoints.push(point);
                    }
                })
            }
            return selectedPoints;
        };

        //
        // Get the array of connectors that are currently selected.
        //
        this.getSelectedConnectors = function () {
            var selectedConnectors = [];

            for (var i = 0; i < this.processes.length; ++i) {
                var process = this.processes[i];
                process.connectors.map(function (connector) {

                    if (connector.selected()) {
                        selectedConnectors.push(connector);
                    }
                })
            }
            var parent_process = this.parent_process;
            parent_process.connectors.map(function (connector) {
                if (connector.selected()) {
                    selectedConnectors.push(connector);
                }
            });
            return selectedConnectors;
        };

        //
        // Get the array of streams that are currently selected.
        //
        this.getSelectedStreams = function () {
            var selectedStreams = [];

            for (var i = 0; i < this.streams.length; ++i) {
                var stream = this.streams[i];
                if (stream.selected()) {
                    selectedStreams.push(stream);
                }
            }

            return selectedStreams;
        };

        // Generic function got getSelected - should replace std getSelected functions
        this.getSelectedViewModels = function (parent_array) {
            var selectedViewModels = [];

            for (var i = 0; i < parent_array.length; ++i) {
                var viewModel = parent_array[i];
                if (viewModel.selected()) {
                    selectedViewModels.push(viewModel);
                }
            }
            return selectedViewModels;
        }

        this.getSelected = function () {
            var processes = this.getSelectedProcesses();
            var streams = this.getSelectedStreams();
            var equipment = this.getSelectedEquipment();

            //var connectors = ctrl.chartViewModel.getSelectedConnectors().map(deleteItem);
            //var series_components = ctrl.chartViewModel.getSelectedSeries().map(deleteItem);
            return processes.concat(streams.concat(equipment));
        };

        //
        // Get the array of streams that are currently selected. TODO: can this be replaced with stream check on connector??
        //
        this.checkIfConnectorHasStream = function (connector) {

            for (var i = 0; i < this.streams.length; ++i) {
                var stream = this.streams[i];

                if (connector === stream.start) {
                    return stream
                }

                if (connector === stream.end) {
                    return stream
                }
            }

            return true;
        };

    };

})();

export default flowchart;