import {ApiService} from './api.service';
import {Injectable} from '@angular/core';
import {AppScope} from './app_scope.service';
import {FlatPromise} from "../lib/utils";
import {HeaderDataService} from "./header_data.service";
import {Observable} from "rxjs";

@Injectable()
export class UserData {
    all_dashboards: any;

    user_session = null;
    report;
    firstLoad = false;
    dataReadyReject;
    dataReadyResolve;
    dataReady;
    dashboards = [];
    schema;
    my_dashboards: any;
    private_dashboards: any;
    public_dashboards: any;
    report_dashboards: any;
    setting_dashboards: any;
    workflow_dashboards: any;
    other_dashboards: any;
    user_dashboards: any;

    constructor(private appScope: AppScope, private api: ApiService, private headerData: HeaderDataService) {
        const userData = this;
        userData.dataReady = new Promise((reject, resolve) => {
            userData.dataReadyReject = reject;
            userData.dataReadyResolve = resolve;
        });

        userData.schema = {
            attributes: {
                user_id: null,
                report: userData.report,
                name: null,
                is_default: false,
                json: null,
                url: null,
                visibility: 'private'
            },
            type: 'session_state'
        };

    }

    checkSuperUser(user) {
        return user.role_name.indexOf('Super_User') > -1;
    };

    get current_user(): any {
        return this.appScope.current_user;
    }

    getCustomDashboards(user?) {
        let ctrl = this;
        let page_menu_options = this.headerData.folder_options();
        const flatPromise = new FlatPromise();

        // TODO replace query with search and see if all the session_state queries can be replaced with a union of them
        //dashboards: Currently logged in user's dashboards + public dashboards
        const user_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([{
            or: [{
                and: [
                    {'op': 'eq', 'name': 'user_id', val: ctrl.current_user.id},
                    {'op': 'like', 'name': 'report', val: "Dashboard"}
                ]
            }, {'op': 'eq', 'name': 'visibility', val: 'public'}]
        }]));

        user_dashboards.$promise.then(data => {
            ctrl.dashboards = data;
            flatPromise.resolve(ctrl.dashboards);
        }, error => {
            flatPromise.resolve(ctrl.dashboards);
        });

        if (user) {                                                              //user_dashboards: Get the dashboards for a specified user (rather than logged in user)
            ctrl.user_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([{
                and: [
                    {'op': 'eq', 'name': 'user_id', val: user.id},
                    {'op': 'like', 'name': 'report', val: "Dashboard"}]
            }], {sort: 'code'}));
        }

        ctrl.my_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([{
                and: [
                    {'op': 'eq', 'name': 'user_id', val: ctrl.current_user.id},
                    {'op': 'eq', 'name': 'visibility', val: "private"},
                    {'op': 'like', 'name': 'report', val: "Dashboard"}
                ]
            }], {sort: 'code'}
        ));

        ctrl.public_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([
                {'op': 'eq', 'name': 'visibility', val: 'public'}]
            , {sort: 'code'}));

        ctrl.report_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([
            {'op': 'eq', 'name': 'visibility', val: 'report'}
        ], {sort: 'code'}));

        ctrl.all_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([
            {'op': 'in', 'name': 'visibility', val: page_menu_options}
        ], {sort: 'code'}));

        ctrl.other_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([
            {'op': 'eq', 'name': 'visibility', val: 'other'}
        ], {sort: 'code'}));
        return flatPromise;
    }

    getUserDashboards(user?): FlatPromise {
        let ctrl = this;

        const flatPromise = new FlatPromise();

        // TODO replace query with search and see if all the session_state queries can be replaced with a union of them
        //dashboards: Currently logged in user's dashboards + public dashboards
        const user_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([{
            or: [{
                and: [
                    {'op': 'eq', 'name': 'user_id', val: ctrl.current_user.id},
                    {'op': 'like', 'name': 'report', val: "Dashboard"}
                ]
            }, {'op': 'eq', 'name': 'visibility', val: 'public'}]
        }]));

        user_dashboards.$promise.then(data => {
            ctrl.dashboards = data;
            flatPromise.resolve(ctrl.dashboards);
        }, error => {
            flatPromise.resolve(ctrl.dashboards);
        });

        if (user) {                                                              //user_dashboards: Get the dashboards for a specified user (rather than logged in user)
            ctrl.user_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([{
                and: [
                    {'op': 'eq', 'name': 'user_id', val: user.id},
                    {'op': 'like', 'name': 'report', val: "Dashboard"}]
            }], {sort: 'code'}));
        }

        ctrl.my_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([{
                and: [
                    {'op': 'eq', 'name': 'user_id', val: ctrl.current_user.id},
                    {'op': 'eq', 'name': 'visibility', val: "private"},
                    {'op': 'like', 'name': 'report', val: "Dashboard"}
                ]
            }], {sort: 'code'}
        ));

        ctrl.public_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([
                {'op': 'eq', 'name': 'visibility', val: 'public'}]
            , {sort: 'code'}));

        ctrl.report_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([
            {'op': 'eq', 'name': 'visibility', val: 'report'}
        ], {sort: 'code'}));

        ctrl.workflow_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([
            {'op': 'eq', 'name': 'visibility', val: 'workflow'}
        ], {sort: 'code'}));

        ctrl.setting_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([
            {'op': 'eq', 'name': 'visibility', val: 'settings'}
        ], {sort: 'code'}));
        ctrl.other_dashboards = ctrl.api.session_state_light.query(ctrl.api.prep_q([
            {'op': 'eq', 'name': 'visibility', val: 'other'}
        ], {sort: 'code'}));

        return flatPromise;
    };

    getRestrictedDashboards(user?): Observable<any> {
        const ctrl = this;
        let restricted_list;
        if (user) {
            restricted_list = user.relationships.dashboards.data.map(d => d.id);
        } else {
            restricted_list = ctrl.appScope.current_user.dashboards;
        }

        return ctrl.api.session_state_light.search(ctrl.api.prep_q([
            {'op': 'in', 'name': 'id', val: restricted_list}
        ]));
    }

    /**
     * Get the list of restricted dashboard ids for the current or given user.
     * @param session_states
     * @param user
     */
    getRestrictedDashboardsQuick(session_states: any[], user?): string[] {
        const ctrl = this;
        let restricted_list;
        if (user) {
            restricted_list = user.relationships.dashboards.data.map(d => d.id);
        } else {
            restricted_list = ctrl.appScope.current_user.dashboards;
        }

        return session_states.filter(state => !!restricted_list.find(item => item == state.id));
    }

    getPrivateDashboards() {
        let ctrl = this;

        ctrl.private_dashboards = ctrl.api.session_state_light.search(ctrl.api.prep_q([{
            and: [
                {'op': 'eq', 'name': 'visibility', val: "private"},
                {'op': 'like', 'name': 'report', val: "Dashboard"}
            ]
        }], {sort: 'code'})).toPromise().then(result => {
            if (!result) return;

            ctrl.private_dashboards = result.data
        });

    }

    getUserSession(default_json, query?) {
        let userData = this;

        let resolvedef;
        let rejectdef;
        const deferred = new Promise((resolve, reject) => {
            resolvedef = resolve;
            rejectdef = reject

        });

        if (query == null) {
            query = [{
                and: [
                    {'op': 'eq', 'name': 'user_id', val: userData.current_user.id},
                    {'op': 'eq', 'name': 'report', val: userData.report}
                ]
            }]
        }

        const user_data = userData.api.session_state.get_query(userData.api.prep_q(query, {'filter[single]': 1}
        ));

        user_data.$promise.then(data => {
            if (user_data.length < 1) {
                //This shouldn't happen anymore since query change but leave to test
                userData.user_session = userData.schema;
                userData.user_session.attributes.report = userData.report;
                userData.user_session.attributes.user_id = userData.current_user.id;
            } else {
                userData.user_session = data.data;
            }
            userData.setDefaults(default_json);
            resolvedef(userData.user_session);
        }, empty => {
            userData.user_session = userData.schema;
            userData.user_session.attributes.report = userData.report;
            userData.user_session.attributes.user_id = userData.current_user.id;
            userData.setDefaults(default_json);
            resolvedef(userData.user_session);
        });
        userData.user_session = deferred;

        return deferred
    }

    setDefaults(default_json) {

        let userData = this;
        if (userData.user_session == null) {
            userData.user_session = userData.schema;
            userData.user_session.attributes.report = userData.report;
            userData.user_session.attributes.user_id = userData.current_user.id;

        }
        if (userData.user_session.attributes.json == {} || userData.user_session.attributes.json == null) {
            userData.firstLoad = true;
            userData.user_session.attributes.json = default_json;
        } else {
            userData.firstLoad = false;
        }
    }

    saveConfig(answer) {
        let ctrl = this;
        let $promise;

        if (answer.hasOwnProperty('id')) {
            $promise = ctrl.api.session_state.patch(answer);
        } else {
            $promise = ctrl.api.session_state.save(answer).then(result => {
                ctrl.user_session = result.data;
                return ctrl.user_session;
            });
        }
        return $promise;
    }
}
