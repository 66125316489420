<header *ngIf="headerData.show_header">

  <div class="displayonprint report-header">
    <img src="/static/images/wire-logo-white.gif"/>
    <img class="client-logo" src='/static/images/right-logo-full.png'/>
  </div>
  <ng-container *ngIf="headerData.show_dtp ">
    <date-period class="hideonprint date-period"></date-period>
  </ng-container>

  <div [ngClass]="{'no-dtp':!headerData.show_dtp}" class="page-title row">
    <h1>{{headerData.title}}
      <div *ngIf="dateTimePeriodService.dtp"
           class="displayonprint report-dtp">{{dateTimePeriodService.dtp.date_string()}}</div>
    </h1>
    <div class="buttons row">
      <button [matMenuTriggerFor]="pageMenu" class="icon-menu" mat-button matTooltip="Page Menu">
        <i class="fas fa-bars" style="font-size: 20px;"></i>
      </button>
      <ng-container *ngIf="!restricted_access">

        <button (click)="downloadData()" *ngIf="headerData.add_download" class="icon-download hide-xs"
                mat-button matTooltip="Download">
        </button>

        <button (click)="uploadData()" *ngIf="headerData.add_upload" class="icon-file-upload"
                mat-button matTooltip="Upload">
          <mat-icon style="color: white">cloud_upload</mat-icon>
        </button>

      </ng-container>


      <button (click)="refreshDTP()" *ngIf="headerData.add_refresh" class="icon-refresh"
              mat-button matTooltip="Refresh">
      </button>

      <mat-menu #pageMenu="matMenu" [hidden]="headerData.buttons.length <= 0" class="tree-node-menu dark">
        <ng-container *ngFor="let button of headerData.buttons">
          <button (click)="button.func(button.params)" *ngIf="!button.toggle"
                  [textContent]="button.name" mat-menu-item>
          </button>
          <mat-slide-toggle (change)="button.func(button.params)" *ngIf="button.toggle"
                            class="mat-menu-item" labelPosition="before">{{button.name}}</mat-slide-toggle>
        </ng-container>
        <ng-container *ngIf="!restricted_access">

          <button (click)="openEditForm()" *ngIf="headerData.add_edit" class="hide-xs" mat-menu-item>
            Edit History
          </button>
        </ng-container>

        <mat-slide-toggle (change)="togglePresent()" *ngIf="headerData.add_present"
                          class="mat-menu-item" labelPosition="before">Toggle Presentation Mode
        </mat-slide-toggle>

      </mat-menu>
    </div>
  </div>

</header>
<!--<div id="report-print-header" style="display:none;">-->
<!--<div class="report-title">-->
<!--<img ng-src='/static/images/wire-logo.gif'/>-->
<!--<h1>{{$ctrl.headerData.title}}</h1>-->
<!--<img ng-src='/admin/myfiles/download/right-logo-print.jpg' class="client_logo"/>-->
<!--</div>-->
<!--<div class="report-range">-->
<!--<span>{{$ctrl.headerData.dtp.date_string($ctrl.headerData.dtp)}}</span>-->
<!--</div>-->
<!--</div>-->

