import {Pipe, PipeTransform} from "@angular/core";
import * as utils from '../lib/utils'

@Pipe({name: 'removeUnderscore'})
export class RemoveUnderscorePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return value.replace(/_/g, " ");
    }
}

@Pipe({name: 'abbreviateNumber'})
export class AbbreviateNumber implements PipeTransform {
    transform(value: any, unit: any): any {
        return utils.abbreviateNumber(value, unit)
    }
}

@Pipe({name: 'significantNumbers'})
export class SignificantNumberPipe implements PipeTransform {
    transform(value: any, show_sig?: boolean, precision?: any): any {

        if (!show_sig === false) {
            return utils.significantNumber(value, precision)
        }
        return value;
    }
}

@Pipe({name: 'thousandsSeparate'})
export class ThousandsSeparatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return utils.thousandsSeparate(value)
    }
}

@Pipe({name: 'nameOrDescription'})
export class NameOrDescriptionPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return value.description ? value.description : value.name;
    }
}

@Pipe({name: 'nameAndDescription'})
export class NameAndDescriptionPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value.description) {
            return value.description + ' - ' + value.name;
        } else {
            return value.name;
        }
    }
}

@Pipe({
    name: 'FilterByName'
})
export class FilterByNamePipe implements PipeTransform {
    transform(list: any, args?: any): any {
        console.log('FilterByNamePipe - transform: ', list, args);
        if (!args)
            return list;
        return list.filter(
            item => item.attributes.name.toLowerCase().startsWith(args.toLowerCase()) === true
        );
    }
}