
<div class="row">
  <div *ngIf="processes && processes.length>0" class="select-search" style="margin-right:0;"
       (mouseenter)="hint='Process'">
    <div class="select-search" style="flex-grow:12" (mouseenter)="hint='Series'">
      <select-search [(value)]="process"
                     (selectChange)="processChange()"
                     [fill_stubs]="true"
                     [label]="'Select a process'"
                     [classes]="'fill'"
                     [data]="processes">
        >
      </select-search>
    </div>
  </div>
  <div class="tip-box">
    <div class="tip" [ngClass]="{'showing': hint=='Process'}">
      <label>Process</label>
      <div>
        The process for which you'd like to review data.
      </div>
    </div>
    </div>
</div>
