import * as utils from '../lib/utils';
import {
    AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit,
    ViewChild, ViewEncapsulation, Renderer2
} from "@angular/core";
import {AppScope} from "../services/app_scope.service";
import {ApiService} from "../services/api.service";
import {PlantDataService} from "../services/plant_data.service";
import {DateTimePeriod, DateTimePeriodService} from "../services/datetime_period.service";
import {HttpClient} from "@angular/common/http";
import {EventDataService} from "../services/event_data.service";
import {MatSnackBar} from "@angular/material";
import {HeaderDataService} from "../services/header_data.service";
import {TileDataService} from "../services/tile_data.service";
import {SeriesDataService} from "../services/series_data.service";
import * as _ from "lodash";
import {catchError, debounceTime, takeUntil} from "rxjs/operators";
import {HandleError, HttpErrorHandler} from "../services/http-error-handler.service";
import {Subject} from "rxjs";
import {MatTableDataSource} from "@angular/material";
import {SelectionModel} from "@angular/cdk/collections";

Date.prototype['addHours'] = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

@Component({
    selector: 'log-sheet',
    templateUrl: 'log-sheet.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LogSheetComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    shift: any;
    process: any;
    access: { all: { promise: Promise<any> }, permissions: any };
    flowSheet: any;
    editAggregation: boolean = false;
    report_groups: {};
    @Input()
    config: any;
    series_ready: utils.FlatPromise;
    series_list_sorted: any[] = [];
    report_groups_names: string[] = [];
    series_list: any[] = [];
    dtp: DateTimePeriod;
    raw_data_fetched: Promise<any>[];

    series_data: any[] = [];
    dataSource: MatTableDataSource<any>;
    selection: SelectionModel<any>;

    series_list_map: {};
    raw_data_map: {};
    times: { period_start: string, period_end: string }[] = [];
    permissions: any;
    sample_hours: any;
    shift_starts: any[] = [];
    shift_spans: any[] = [];
    shift_names: any[] = [];
    shifts: any;
    show_shifts: boolean;
    restricted_access: boolean;
    sample_period: any;
    shift_length: any;
    columns: string[] = [];
    events: any[];
    super_user: boolean;
    times_map: any[];
    calc_data: any = [];
    missing_values: any = [];
    last_end: any;
    edit_day: any;
    sheet_ready = false;
    value_error: boolean;
    raw_data_error: boolean;
    series_events: {};
    significant_numbers: boolean = true;
    changes_flag: boolean = false;

    timeColumnsCache: { [key: string]: string } = {};

    private readonly handleError: HandleError;
    buttons: { name: string; func: any; params: {}; class: string; HoverOverHint: string; }[];
    applyCorrFac: { name: string; func: () => void; params: {}; class: string; HoverOverHint: string; };
    OverCalcs: { name: string; func: () => void; params: {}; class: string; HoverOverHint: string; };
    hovering_events: any[] = [];

    constructor(public appScope: AppScope,
                public api: ApiService,
                public plantData: PlantDataService,
                public http: HttpClient,
                public eventData: EventDataService,
                public headerData: HeaderDataService,
                public datetimePeriodService: DateTimePeriodService,
                public snackBar: MatSnackBar,
                public tileDataService: TileDataService,
                private seriesDataService: SeriesDataService,
                httpErrorHandler: HttpErrorHandler,
                private renderer: Renderer2) {
        this.handleError = httpErrorHandler.createHandleError('HeroesService');
        this.tileDataService.addCommentClicked
            .pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                this.saveComment(value)
            });
        this.tileDataService.commentHover
            .pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                this.commentHover(value)
            });
        this.tileDataService.commentLeave
            .pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                this.commentLeave(value)
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    ngAfterViewInit(): void {
        const ctrl = this;

        //Sets default title for tile for logsheet
    }

//move this to filter or somewhere auto accessible
    toSignificantNumber(nmbr, precision?) {
        if (nmbr && !isNaN(nmbr)) {
            if (this.significant_numbers) {
                return utils.significantNumber(nmbr, precision);
            } else {
                return utils.thousandsSeparate(nmbr);
            }
        } else {
            return nmbr;
        }
    }

    showDecimals() {
        this.significant_numbers = !this.significant_numbers;
    }

    setButtons() {
        const ctrl = this;

        ctrl.buttons = [
            {
                name: 'Edit aggregation',
                func: () => ctrl.toggleAggregation(),
                params: {},
                class: 'fa small fa-wrench hide-xs',
                HoverOverHint: 'Edit aggregation'
            },
            //TODO come back to this
            {
                name: 'Decimals',
                func: () => ctrl.showDecimals(),
                params: {},
                class: 'fa small fa-percent',
                HoverOverHint: 'Show more decimals'
            }
        ];

        if (ctrl.access.permissions.override_calculations) {
            ctrl.OverCalcs =
                {
                    name: 'Update Calculations',
                    func: () => ctrl.overrideCalculations(),
                    params: {},
                    class: 'fa small fa-calculator hide-xs',
                    HoverOverHint: 'Update calculations'
                };

            ctrl.buttons.unshift(ctrl.OverCalcs)
        }

        if (ctrl.access.permissions.apply_correction_factor) {
            ctrl.applyCorrFac =
                {
                    name: 'Apply correction factor',
                    func: () => ctrl.correctionFactor(),
                    params: {},
                    class: 'fa small fa-eraser hide-xs',
                    HoverOverHint: 'Apply correction factor'
                };

            ctrl.buttons.unshift(ctrl.applyCorrFac)
        }

        ctrl.tileDataService.buttonsChanged.next(ctrl.buttons);
    }

    correctionFactor() {
        console.log('correctionFactor called');
        const ctrl = this;
        const dialogRef = ctrl.headerData.correctionFactor();
        dialogRef.afterClosed().toPromise().then(response => {
                console.log('correctionFactor closed (2), response:', response);
                ctrl.getInputData();
            },
        );
    };

    refreshShifts() {
        const ctrl = this;
        console.log('LogSheetComponent - refreshShifts: ', ctrl.dtp);
        ctrl.sample_period = ctrl.dtp.sample_period;
        ctrl.shifts = [];
        ctrl.api.shift.search(ctrl.api.prep_q([{
                and: [{
                    name: 'end',
                    op: 'gt',
                    val: ctrl.dtp.start
                }, {
                    name: 'start',
                    op: 'lt',
                    val: ctrl.dtp.end
                }]
            }], {sort: 'start'}
        )).pipe(takeUntil(this.onDestroy)).toPromise().then(response => {
            if (!response) return;
            ctrl.shifts = response.data;
            if (!ctrl.shifts) {
            }
            if (ctrl.shifts.length > 0) { //1,2,4,(6),(8),(12)
                ctrl.shifts = ctrl.shifts.sort((a, b) => {
                    return new Date(a.attributes.start).valueOf() - new Date(b.attributes.start).valueOf();
                });
            }

            ctrl.loadTable();
        });
    }

    getColumnNameForTime(time) {
        let value = this.timeColumnsCache[time.period_end];
        if (!value) {
            value = this.dtp.sample_period.format(time.period_end, this.dtp);
            this.timeColumnsCache[time.period_end] = value;
        }
        return value;
    }

    getRowHeaders() {
        const headers = ['SeriesGroup', 'SeriesName', 'SeriesDescription', 'SeriesComment'].concat(this.columns);
        this.times.forEach(time => {
            const column = this.getColumnNameForTime(time);
            headers.push(column);
        });
        if (this.times.length > 0) {
            return headers;
        } else {
            return [];
        }
    }

    trackByFunction(index, item) {
        return index;
    }

    loadTable() {
        const ctrl = this;

        this.series_data = [];
        ctrl.series_list_map = {};
        ctrl.raw_data_map = {};
        ctrl.times = [];
        ctrl.permissions = ctrl.access.permissions;
        ctrl.sample_hours = ctrl.sample_period.hours;

        ctrl.shift_starts = [];
        ctrl.shift_spans = [];
        ctrl.shift_names = [];

        let date_counter = utils.deepCopy(ctrl.dtp.start);
        if (ctrl.dtp.sample_period.name == 'month') {
            date_counter.setMonth(date_counter.getMonth() + 1);
        } else {
            date_counter['addHours'](ctrl.sample_hours);
        }
        let span_counter = 0;
        let period_start;
        let period_end;
        let shift_idx = 0;

        let shift_headers = function () {
            if (ctrl.shifts[shift_idx] !== undefined) {
                let shift_end = new Date(ctrl.shifts[shift_idx].attributes.end);
                let shift_start = new Date(ctrl.shifts[shift_idx].attributes.start)['addHours'](ctrl.dtp.sample_period.hours);

                if (shift_start < utils.deepCopy(new Date(ctrl.dtp.start))['addHours'](ctrl.dtp.sample_period.hours)) {
                    shift_start = (new Date(ctrl.dtp.start))['addHours'](ctrl.dtp.sample_period.hours);
                }
                if (shift_end > new Date(ctrl.dtp.end)) {
                    shift_end = new Date(ctrl.dtp.end);
                }
                if (shift_end <= date_counter) {
                    let shift_span = (shift_end.getTime() - shift_start.getTime()) / 3600000 / ctrl.dtp.sample_period.hours;
                    ctrl.shift_spans[new Date(shift_start).toISOString()] = shift_span + 1;
                    ctrl.shift_starts.push(new Date(shift_start).toISOString());
                    ctrl.shift_names[new Date(shift_start).toISOString()] = ctrl.shifts[shift_idx].attributes.description;
                    shift_idx++;
                }
            }
        };

        while (date_counter <= ctrl.dtp.end) {
            if (ctrl.dtp.sample_period.name == 'month') {
                date_counter.setMonth(date_counter.getMonth() - 1)
            } else {
                date_counter['addHours'](ctrl.sample_hours * -1)
            }
            period_start = date_counter.toISOString();
            if (ctrl.dtp.sample_period.name == 'month') {
                date_counter.setMonth(date_counter.getMonth() + 1)
            } else {
                date_counter['addHours'](ctrl.sample_hours)
            }
            period_end = date_counter.toISOString();
            ctrl.times.push({
                period_start: period_start,
                period_end: period_end
            });

            if (ctrl.dtp.sample_period.name == 'month') {
                date_counter.setMonth(date_counter.getMonth() + 1)
            } else {
                date_counter['addHours'](ctrl.sample_hours)
            }
            if (ctrl.shifts.length > 0 && Number.isInteger(ctrl.shift_length / ctrl.dtp.sample_period.hours)) { //1,2,4,(6),(8),(12)
                shift_headers();
                ctrl.show_shifts = true;
            } else {
                ctrl.show_shifts = false;
            }
            span_counter++;
        }

        if (ctrl.restricted_access !== true || ctrl.permissions.view_process_data) {
            ctrl.getInputData();
        }

        this.getEvents();

        ctrl.times_map = ctrl.times.map(time => time.period_end);
    }

    fillRow(row) {
        const ctrl = this;
        const date_counter = (new Date(ctrl.dtp.start))['addHours'](ctrl.sample_hours);
        while (date_counter <= new Date(ctrl.dtp.end)) {
            if (!row.hasOwnProperty(date_counter.toISOString()) || !row[date_counter.toISOString()]) {
                row[date_counter.toISOString()] = {
                    attributes: {
                        value: null,
                        series: row.id,
                        time_stamp: date_counter.toISOString()
                    }, type: 'raw_data'
                }

            } else if (!row[date_counter.toISOString()].hasOwnProperty('type')) {
                row[date_counter.toISOString()] = {
                    attributes: {
                        value: null,
                        series: row.id,
                        time_stamp: date_counter.toISOString()
                    }, type: 'raw_data'
                }
            }
            if (ctrl.dtp.sample_period.name == 'month') {
                date_counter.setMonth(date_counter.getMonth() + 1)
            } else {
                date_counter['addHours'](ctrl.dtp.sample_period.hours);
            }
        }
        //addTotal(row);
        return row
    }

    getInputData() {
        const ctrl = this;
        ctrl.raw_data_fetched = [];
        let counter = 0;
        const summary_series = [];

        ctrl.series_list.forEach(item => {
            let data_fetched;
            if (item.attributes.can_edit == true) {

                const series_raw_data = ctrl.api.raw_data.search(ctrl.api.prep_q([{
                    name: 'series_id',
                    op: 'eq',
                    val: item.id
                }, {
                    name: 'time_stamp',
                    op: '<=',
                    val: ctrl.dtp.end
                }, {
                    name: 'time_stamp',
                    op: '>=',
                    val: ctrl.dtp.start
                }])).pipe(takeUntil(this.onDestroy)).toPromise();
                ctrl.raw_data_fetched.push(series_raw_data);

                series_raw_data.then(response => {
                    data_fetched = response.data;
                    data_fetched.map(col => {
                        const iso_date = (new Date(col.attributes.time_stamp)).toISOString();
                        ctrl.raw_data_map[item.id + iso_date] = col.id;
                        item[iso_date] = ctrl.detectLimit(item, col.attributes.value, col);
                    });
                    item = ctrl.fillRow(item);
                })
            } else {
                item = ctrl.fillRow(item);
                summary_series.push(item.id);
            }
            ctrl.series_data.push(item);

            ctrl.series_list_map[counter] = item;
            counter++;
        });

        Promise.all(ctrl.raw_data_fetched).then(() => {
            delete ctrl.raw_data_fetched;
            let getsummary = ctrl.getSeriesSummary(ctrl.series_list.map(series => series.id));

            let getdata = ctrl.api.get("/GetData" + '?' + utils.httpParamSerializer({
                process: ctrl.process.id,
                deepness: 2,
                start: ctrl.dtp.start.toISOString(),
                end: ctrl.dtp.end.toISOString(),
                sample_period: ctrl.dtp.sample_period.wire_sample
            })).pipe(takeUntil(this.onDestroy)).toPromise();

            Promise.all([getsummary, getdata]).then(returns => {
                let data = returns[1];
                let summary = returns[0];

                // @ts-ignore
                const calc_data = data.data;
                ctrl.calc_data = calc_data;
                // @ts-ignore
                ctrl.missing_values = data.missing_values;
                const series_name_map = {};
                ctrl.series_list.map(series => {
                    if (!series.attributes.can_edit) {
                        series_name_map[series.attributes.name] = series.id;
                    }
                });

                ctrl.series_data.forEach(row => {
                    let date_counter = new Date(ctrl.dtp.start)['addHours'](ctrl.sample_hours);
                    let series_name = row.attributes.name;
                    while (date_counter <= new Date(ctrl.dtp.end)) {
                        let timestamp = date_counter.toISOString();
                        if (series_name_map.hasOwnProperty(series_name)) {
                            if (calc_data[series_name][timestamp] !== null && calc_data[series_name][timestamp] !== undefined &&
                                row[timestamp] !== calc_data[series_name][timestamp]) {
                                row[timestamp] = calc_data[series_name][timestamp];
                                row[timestamp] = ctrl.detectLimit(row, row[timestamp], {'attributes': {'value': row[timestamp]}});
                            }
                        }
                        if (ctrl.dtp.sample_period.name == 'month') {
                            date_counter.setMonth(date_counter.getMonth() + 1)
                        } else {
                            date_counter['addHours'](ctrl.dtp.sample_period.hours)
                        }
                    }

                    // @ts-ignore
                    summary.forEach(summary => {
                        if (series_name === summary.Name) {
                            if (ctrl.columns) {
                                ctrl.columns.forEach(col => {
                                    row[col] = summary[col];
                                });
                            }
                            row.total = summary.Value;
                            row.status = summary.Status;
                        }
                    });

                    ctrl.sheet_ready = true;
                    this.dataSource = new MatTableDataSource(ctrl.series_data);
                    this.selection = new SelectionModel<any>(false, null);
                });
            });
        });
    }

    setFocus(e) {
        let id = e.target.id.split('_')
        this.setSelection(event, id);
    }

    preventDefaults(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
        }
        if (event.target.tagName === "INPUT") {
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                event.preventDefault();
            }
        }
    }

    @HostListener('keydown', ['$event'])
    onKeydown(event) {

        let id = event.target.id.split('_')
        this.preventDefaults(event);
        let action_keys = ['ArrowDown', 'Enter', 'ArrowUp', 'ArrowLeft', 'Tab', 'ArrowRight']

        if (event.target.tagName === "INPUT" && !action_keys.includes(event.key)) {
            return;
        }
        if (event.key === 'ArrowDown' || event.key === 'Enter') {
            id[1] = parseInt(id[1]) + 1;
        }
        if (event.key === 'ArrowUp') {
            id[1] = parseInt(id[1]) - 1;
        }
        if (event.key === 'ArrowLeft') { // && event.target.tagName !== "INPUT"
            id[2] = parseInt(id[2]) - 1;
        }
        if (event.key === 'Tab') {
            id[2] = parseInt(id[2]) + 1;
        }
        if (event.key === 'ArrowRight') { // && event.target.tagName !== "INPUT"
            id[2] = parseInt(id[2]) + 1;
        }

        this.setSelection(event, id);
    }

    setSelection(event, id) {
        let el = document.getElementById('td_' + id[1] + '_' + id[2] + '_' + this.tileDataService.id);
        if (el) {
            el.focus();
            if (el.firstElementChild.tagName === "INPUT") {
                let e = document.getElementById('inputtd_' + id[1] + '_' + id[2] + '_' + this.tileDataService.id);
                e.focus();
            }
        }

    }

    overrideCalculations() {
        // Appending dialog to document.body to cover sidenav in docs app
        this.getCalculations()

    }

    getCalculations() {
        const ctrl = this;
        let series_list = ctrl.series_list.map((series) => {
            return series.id;
        });
        ctrl.headerData.getCalculations(ctrl.dtp, series_list, 'hour', 1).then(data => {
            ctrl.snackBar.open('Calculations successfully updated', null, {duration: 2000})
            ctrl.refreshShifts();
            ctrl.changes_flag = false;
        }).catch(reason => {
                ctrl.snackBar.open('Failed to update calculations ' + reason, 'Hide');
                console.log('reason for fail', reason)
            }
        );
    }

    getSeriesSummary(ids) {
        const ctrl = this;
        let columns = utils.deepCopy(ctrl.columns);
        if (!columns) {
            columns = ['Value', 'Status'];
        } else {
            if (!columns.includes('Status')) {
                columns.push('Status');
            }
            if (!columns.includes('Value')) {
                columns.push('Value');
            }
        }
        return ctrl.api.get("/GetSeriesSummary" + '?' + utils.httpParamSerializer({
            process: ctrl.config.process.id,
            //series_list: ids,
            start: ctrl.dtp.start.toISOString(),
            return_type: 'json',
            end: ctrl.dtp.end.toISOString(),
            format: 'records',
            deepness: 2,
            single: true,
            columns: columns
        })).pipe(catchError(this.handleError('LogSheetComponent.getSeriesSummary', []))).toPromise();
    }

    addTotal(series) {
        const ctrl = this;
        let summary = this.getSeriesSummary([series.id]);
        summary.then((stats: {}[]) => {
            stats.forEach(summary => {
                if (series.attributes.name === summary['Name']) {
                    if (ctrl.columns) {
                        ctrl.columns.forEach(col => {
                            series[col] = summary[col];
                        });
                    }
                    series.total = summary['Value'];
                    series.status = summary['Status'];
                }
            });
        });
    }

    detectLimit(series: any, value: string, raw_data) {
        //check for only apply correction factor permission and then popup different box that
        //checks events for this series and user and applies it to whole dtp period
        //reset to enable update
        raw_data.warning = false;
        raw_data.error = false;

        // @ts-ignore
        if (value === "" || value === null || isNaN(value)) {
            // TODO why is warning and error both false for this case
            raw_data.warning = false;
            raw_data.error = false;
        }

        if (value > series.attributes.hi || value < series.attributes.low) {
            raw_data.warning = true;
            raw_data.error = false;
        }

        if (value > series.attributes.hihi || value < series.attributes.lowlow) {
            raw_data.error = true;
            raw_data.warning = false;
        }

        return raw_data
    }

    setupCommentTimes(new_comment, time) {
        const ctrl = this;
        new_comment.attributes.start = new Date(time);
        if (ctrl.dtp.sample_period.name == 'month') {
            const temp_date = new Date(time);
            temp_date.setMonth(temp_date.getMonth() + 1);
            new_comment.attributes.end = temp_date;
        } else {
            new_comment.attributes.end = (new Date(time))['addHours'](ctrl.dtp.sample_period.hours);
        }
        new_comment.attributes.end.setTime(new_comment.attributes.end.getTime() + (-1000));
        return new_comment;
    }

    editSeries(series, event?) {
        if (event) {
            event.stopPropagation();
        }
        const ctrl = this;
        let $series_full = this.api[series.type].getById(series.id).toPromise();
        $series_full.then(returned => {
            let series_full = returned.data;
            const dialogRef = ctrl.seriesDataService.upsertSeries(ctrl.process, series_full);
            dialogRef.afterClosed().toPromise().then(response => {
                if (response) {
                    let updated_series;
                    if (response.series) {
                        updated_series = response.series;
                    } else {
                        updated_series = response;
                    }
                    console.log(series, updated_series);
                    Object.keys(series.attributes).forEach(att => {
                        if (updated_series.attributes[att] !== undefined && updated_series.attributes[att] !== null) {
                            series.attributes[att] = updated_series.attributes[att];
                        }
                    });
                    ctrl.report_groups[series.attributes.report_group].forEach(item => {
                        if (item.id === series.id) {
                            item = series;
                        }
                    });
                }
            });
        })
    }

    ngOnInit(): void {
        const ctrl = this;
        ctrl.shift = null;

        const promises = [];

        ctrl.process = ctrl.config.process;
        ctrl.access = this.plantData.getIsolatedPermissions(ctrl.process.id);

        ctrl.series_ready = new utils.FlatPromise();
        ctrl.flowSheet = this.plantData.getFlowSheetData(ctrl.process.id);
        promises.push(ctrl.flowSheet.allDataFetched.promise);

        ctrl.tileDataService.title = ctrl.config.title;

        ctrl.editAggregation = false;

        if (ctrl.config.columns) {
            ctrl.columns = ctrl.config.columns;
        }

        ctrl.report_groups = {};
        ctrl.events = [];

        promises.push(ctrl.datetimePeriodService.dtp_complete.promise);

        Promise.all(promises).then((result) => {
            console.log('LogSheetComponent - dtp results: ', this.datetimePeriodService.read_dtp_from_url);
            ctrl.super_user = ctrl.appScope.current_user.is_super;

            ctrl.shift_length = this.appScope.config_name_map.date_period.value.default_shift_length;

            ctrl.dtp = ctrl.datetimePeriodService.dtp;
            if (!ctrl.appScope.isNotMobile) {
                //this.datetimePeriodService.show_timespan = false;
                this.dtp.start = utils.setToHour(new Date(), new Date().getHours() - 1);
                this.dtp.end = utils.setToHour(new Date(), new Date().getHours());
                this.dtp.range = 'custom';
                this.dtp.sample_period = this.datetimePeriodService.sample_dict['hour'];
                this.datetimePeriodService.dtpChanged.emit(this.dtp);
                console.log('LogSheetComponent - dtp: ', this.dtp);
            }
            ctrl.timeColumnsCache = {};

            ctrl.flowSheet.allDataFetched.promise.then(() => {
                ctrl.series_list = ctrl.flowSheet.selected_series;
                ctrl.series_list.forEach(item => {
                    if (ctrl.report_groups.hasOwnProperty(item.attributes.report_group)) {
                        ctrl.report_groups[item.attributes.report_group].push(item)
                    } else {
                        ctrl.report_groups[item.attributes.report_group] = [item]
                    }
                });
                let is_numeric = true;
                ctrl.report_groups_names = Object.keys(ctrl.report_groups).sort();
                ctrl.report_groups_names.forEach((name) => {
                    if (isNaN(Number(name))) {
                        is_numeric = false;
                        return;
                    }
                });
                if (is_numeric) {
                    ctrl.report_groups_names = Object.keys(ctrl.report_groups).sort((a: any, b: any) => a - b);
                }
                ctrl.series_list_sorted = [];
                ctrl.report_groups_names.forEach((group) => {
                    ctrl.report_groups[group] = ctrl.report_groups[group].sort((a, b) => a.attributes.series_order - b.attributes.series_order);
                    ctrl.series_list_sorted = ctrl.series_list_sorted.concat(ctrl.report_groups[group]);
                });

                ctrl.series_list = ctrl.series_list_sorted;
                ctrl.series_ready.resolve(ctrl.series_list);
            });

            Promise.all([ctrl.access.all.promise, ctrl.series_ready.promise]).then(ctrl.refreshShifts.bind(ctrl));

            ctrl.setButtons();
            if (ctrl.config.process.attributes && ctrl.config.process.attributes.name) {
                ctrl.tileDataService.setDefaultTitle(ctrl.config.process.attributes.name);
            }

        });

        ctrl.datetimePeriodService.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            ctrl.dtp = dtp;
            ctrl.timeColumnsCache = {};
            ctrl.refreshShifts()
        });

    }

    checkUserEvents(series) {
        const ctrl = this;
        let userEvent = false;

        ctrl.events.forEach((event) => {
            //if there is an event for this user
            if (ctrl.appScope.current_user.id === event.relationships.created_by.data.id) {
                event.relationships.series_list.data.forEach(s => {
                    //and this series
                    if (s.id === series.id && s.type === series.type) {
                        //within the dtp period
                        if (new Date(event.attributes.start) >= new Date(ctrl.dtp.start) &&
                            new Date(event.attributes.start) <= new Date(ctrl.dtp.end)) {
                            //get the comment
                            userEvent = event.attributes.comment;
                        }
                    }
                })
            }
        });

        return userEvent;
    }

    /**
     * Fired when an change is made to a cell of a series-time pair
     * @param series The series?
     * @param time
     * @param event
     */
    dataChange(series: any, time: { period_start: string, period_end: string }, event) {
        const ctrl = this;

        const magic_temp = '-'; // FIXME this is used because angular did not detect the updated value;
        const time_period_end = time.period_end;
        let new_value = event.target.value;
        let raw_data = series[time_period_end]; // the cell that was edited
        const original_value = raw_data.attributes.value;

        let cancelled: boolean = false;
        //This guy should supply a reason for changing data regardless of its value
        let autoComment = function (comment) {
            let new_comment = ctrl.setupCommentTimes({attributes: {}}, time_period_end);
            ctrl.eventData.addInlineComment(comment, time_period_end, new_comment.attributes.end, [series]).then((new_comment) => {
                ctrl.eventData.toggleComments.emit(true);
                ctrl.getEvents();
            })
        };
        let p = ctrl.permissions;
        if (p.apply_correction_factor && !(p.edit_process_data || p.edit_todays_data ||
            ctrl.super_user || ctrl.appScope.current_user.role_name.includes('Administrator'))) {

            let old_comment = ctrl.checkUserEvents(series);
            if (old_comment !== false) {
                autoComment(old_comment)
            } else {
                let comment = prompt("Please enter a reason for changing this value.");
                if (!comment) {
                    console.log("cancel");
                    raw_data.attributes.value = magic_temp;
                    setTimeout(() => {
                        raw_data.attributes.value = original_value;
                    });
                    return;
                } else {
                    autoComment(comment);
                }

            }
        } else {
            if ((series.attributes.hihi == null && series.attributes.hi == null) ||
                (series.attributes.lowlow == null && series.attributes.low == null)) {
                if (series['warned'] == null) {
                    series['warned'] = true;
                    alert("Please contact your supervisor to set limits for this series!");
                    ctrl.submitCommentEvent(series, time, "Series limits not set.");
                } else {
                    autoComment("Series limits not set: " + series['warned']);
                }
                raw_data.error = false;
                raw_data.warning = true;
                raw_data.attributes.value = new_value;
            } else if (new_value === null || new_value.trim() === '') { //allow delete
                raw_data.attributes.value = null;
                ctrl.detectLimit(series, null, raw_data);
            } else {
                ctrl.detectLimit(series, new_value, raw_data);

                if (raw_data.error) {
                    // let new_value;

                    new_value = prompt("Limit exceeded please type number again" +
                        "\n{LowLow: " + series.attributes.lowlow + ", Low: " + series.attributes.low + ", Hi: " + series.attributes.hi +
                        ", HiHi: " + series.attributes.hihi + "}");
                    while (new_value !== null && new_value !== '' && isNaN(parseFloat(new_value))) {
                        new_value = prompt("Limit exceeded please type number again" +
                            "\n{LowLow: " + series.attributes.lowlow + ", Low: " + series.attributes.low + ", Hi: " + series.attributes.hi +
                            ", HiHi: " + series.attributes.hihi + "}\nInvalid number, please type a valid number");
                    }

                    if (new_value === null || new_value === '') { //prompt was cancelled
                        // raw_data.attributes.value = original_value;
                        // ctrl.detectLimit(series, original_value, raw_data);
                        cancelled = true;
                    } else {
                        raw_data.attributes.value = Number(new_value);
                        ctrl.detectLimit(series, raw_data.attributes.value, raw_data);
                        if (raw_data.error) {
                            if (!ctrl.submitCommentEvent(series, time, "Validation error.")) {
                                cancelled = true;
                            }
                        }
                    }
                } else {
                    raw_data.attributes.value = new_value;
                }
            }
        }

        raw_data.saving = true;
        const new_raw_data = {id: raw_data.id, type: 'raw_data', attributes: raw_data.attributes};

        if (raw_data.hasOwnProperty('id') && !cancelled) { // value has previously existed
            if (Number.isNaN(parseFloat(raw_data.attributes.value))) {
                ctrl.api.raw_data.delete(raw_data.id).then(() => {
                    delete raw_data.id;
                    delete raw_data.error;
                    delete raw_data.warning;
                    raw_data.saving = false;
                    ctrl.changes_flag = true;
                    ctrl.addTotal(series);
                    // ctrl.detectLimit(series, raw_data.attributes.value, raw_data);
                }, () => {
                    console.log('Failed to delete');
                    ctrl.snackBar.open('Failed to save', 'Hide');
                });
            } else {
                ctrl.api.raw_data.patch(new_raw_data).then((result) => {
                    raw_data.saving = false;
                    ctrl.changes_flag = true;
                    ctrl.detectLimit(series, raw_data.attributes.value, raw_data);
                    ctrl.addTotal(series);
                }, () => {
                    console.log('Failed to update', new_raw_data);
                    ctrl.snackBar.open('Failed to save', 'Hide');
                });
            }
        } else {
            if (Number.isNaN(parseFloat(raw_data.attributes.value)) || cancelled) {
                raw_data.saving = false;
                console.log('No option', new_raw_data);
                raw_data.attributes.value = magic_temp;
                setTimeout(() => {
                    raw_data.attributes.value = original_value;
                    this.detectLimit(series, original_value, raw_data);
                })
            } else {
                ctrl.api.raw_data.save(new_raw_data).then((result) => {
                    raw_data = result.data;
                    series[time_period_end] = raw_data;
                    raw_data.saving = false;
                    ctrl.changes_flag = true;
                    ctrl.addTotal(series);
                    ctrl.detectLimit(series, raw_data.attributes.value, raw_data);
                }, () => {
                    console.log('Failed to save', new_raw_data);
                    ctrl.snackBar.open('Failed to save', 'Hide');
                });
            }
        }
    }

    toggleAggregation() {
        // TODO fix the way this is set for this button (same button which is set in the AfterViewInit)
        const ctrl = this;
        ctrl.editAggregation = !ctrl.editAggregation;
        console.log('toggled aggregation to ', ctrl.editAggregation)
    }

    private getNonEmptyComment(time: string, initMessage?: string): string {
        let comment: string;
        if (!initMessage) {
            initMessage = '';
        } else {
            initMessage += '\n';
        }

        comment = prompt(initMessage + "Please provide a comment");
        while (!comment || !comment.trim()) {
            if (comment == null) {
                this.snackBar.open('Data entry cancelled', undefined, {duration: 3000});
                return undefined;
            } else {
                comment = prompt(initMessage + "Please provide a comment. You have to type a message.");
            }
        }
        return comment.trim();
    }

    dtpCoarserGranularity(series) {
        const ctrl = this;
        return series.attributes.sample_period &&
            (ctrl.datetimePeriodService.sample_dict[series.attributes.sample_period].hours < ctrl.sample_period.hours);
    }

    private saveAllowedSimple(can_edit: boolean, sample_period: string, time: string) {
        const ctrl = this;
        //check for apply correction factor permissions

        ctrl.edit_day = new Date(ctrl.datetimePeriodService.range_dict['since yesterday'].start)['addHours'](ctrl.sample_period.hours);

        let series_time = new Date(time);

        let series_sample_period = sample_period;
        if (!series_sample_period) {
            series_sample_period = ctrl.appScope.config_name_map.default_sample_period.value
        }

        //If sample period doesn't call for data entry at this point
        if (ctrl.datetimePeriodService.sample_dict[series_sample_period].hours < ctrl.sample_period.hours) {
            return false;
        }

        if (series_sample_period == 'week' && series_time.getDay() != ctrl.appScope.config_name_map.week_start.value) {
            return false;
        }

        if (series_sample_period == 'month' && series_time.getDate() != 1) {
            return false;
        }

        if (series_sample_period == 'month') {
            if (series_time.getHours() != ctrl.datetimePeriodService.defaultStartHour) {
                return false;
            }
        } else {

            const samplePeriodInHours = ctrl.datetimePeriodService.sample_dict[series_sample_period].hours;
            const remainder = (series_time.getHours() - ctrl.datetimePeriodService.defaultStartHour) % samplePeriodInHours;
            if (remainder != 0) {
                return false;
            }
        }

        //check permissions
        if (can_edit && series_time <= (new Date())['addHours'](ctrl.dtp.sample_period.hours)) {
            if (ctrl.permissions.edit_process_data || ctrl.permissions.apply_correction_factor || ctrl.super_user) {
                return true;
            }
            return series_time >= new Date(ctrl.edit_day) && ctrl.permissions.edit_todays_data;
        }
        return false;
    }

    resolver = (can_edit, sample_period, time) => {
        return '' +
            (can_edit ? can_edit : 'SUB_KEY:can_edit') + '\n' +
            (sample_period ? sample_period : 'SUB_KEY:sample_period') + '\n' +
            (time ? time : 'SUB_KEY:time');
    }

    private saveAllowedMemoized = _.memoize(this.saveAllowedSimple.bind(this), this.resolver);

    saveAllowed(series, time: string): boolean {
        const can_edit = series.attributes.can_edit;
        const sample_period = series.attributes.sample_period;

        return this.saveAllowedMemoized(can_edit, sample_period, time);
    }

    shouldShowInput(series, time) {
        let a = series && series[time.period_end];
        let b = !(series[time.period_end] && series[time.period_end].saving);
        let c = this.saveAllowed(series, time.period_end);
        let d = !this.editAggregation;
        let r = a && b && c && d;
        return r;
    }

    shouldShowFirst(series, time) {
        let b = !series.attributes.can_edit && this.missing_values[series.attributes.name] &&
            !this.missing_values[series.attributes.name][time.period_end];
        return b;
    }

    saveComment(comment) {
        const ctrl = this;
        ctrl.eventData.addInlineComment(comment, ctrl.tileDataService.comment.start, ctrl.tileDataService.comment.end,
            [ctrl.tileDataService.comment.series]).then((new_comment) => {
            this.eventData.toggleComments.emit(true);
            ctrl.getEvents();
        })
    }

    aggregationChange(series, orig_value) {
        const ctrl = this;
        ctrl.value_error = false;
        ctrl.raw_data_error = false;

        let sample_period = ctrl.datetimePeriodService.sample_dict[ctrl.appScope.config_name_map.default_sample_period.value].hours;

        if (series.attributes.sample_period) {
            sample_period = ctrl.datetimePeriodService.sample_dict[series.attributes.sample_period].hours;
        }
        let agg = series.attributes.aggregation;
        let value = utils.deepCopy(series.total);
        let total_hours = Math.abs(ctrl.dtp.end.valueOf() - ctrl.dtp.start.valueOf()) / 36e5;

        if (agg === 'total') {
            value = value / (total_hours / sample_period);
        }
        let limits = ctrl.detectLimit(series, value, {'attributes': {'value': value}});
        if (series.total === null || series.total === '' || isNaN(series.total)) {
            ctrl.value_error = true;
        } else {
            let promises;
            let date_counter = utils.deepCopy(ctrl.dtp.start)['addHours'](sample_period);
            series.saving = true;
            let end = new Date();
            if (end > ctrl.dtp.end) {
                end = utils.deepCopy(ctrl.dtp.end)
            }
            while (date_counter <= end) {
                let save_data = function () {
                    let raw_data = {
                        id: undefined, type: 'raw_data', attributes: {
                            series: series.id,
                            value: value,
                            time_stamp: utils.deepCopy(date_counter)
                        }
                    };
                    let promise;
                    let updateSeries = function (series, result?) {
                        if (result) {
                            series[raw_data.attributes.time_stamp.toISOString()].id = result.data.id;
                        }
                        series[raw_data.attributes.time_stamp.toISOString()].attributes.value = value;
                        series[raw_data.attributes.time_stamp.toISOString()].warning = limits.warning;
                        series[raw_data.attributes.time_stamp.toISOString()].error = limits.error;
                    };
                    if (series[raw_data.attributes.time_stamp.toISOString()].hasOwnProperty('id')) {
                        raw_data.id = series[raw_data.attributes.time_stamp.toISOString()].id;
                        promise = ctrl.api.raw_data.patch(raw_data).then(() => {
                            updateSeries(series);
                        }, () => {
                            ctrl.raw_data_error = true;
                            console.log("Error: Data not saved for: " + raw_data.attributes.time_stamp);
                        });
                    } else {
                        promise = ctrl.api.raw_data.save(raw_data).then(result => {
                            updateSeries(series, result);
                        }, () => {
                            ctrl.raw_data_error = true;
                            console.log("Error: Data not saved for: " + raw_data.attributes.time_stamp);
                        });
                    }

                    date_counter['addHours'](sample_period);
                    return promise;
                };
                promises = save_data();
            }

            Promise.all([promises]).then(() => {
                series.saving = false;
                if (ctrl.raw_data_error === true) {
                    ctrl.snackBar.open("Some values were not saved, please check the console.", 'Hide');
                } else {
                    ctrl.changes_flag = true;
                }
            });
        }
    }

    setComment(e, series, time) {
        this.tileDataService.comment.series = series;
        if (time) {
            this.tileDataService.comment.start = time.period_start;
            this.tileDataService.comment.end = time.period_end;
        } else {
            this.tileDataService.comment.start = this.dtp.start;
            this.tileDataService.comment.end = this.dtp.end;
        }
    }

    toggleComments(show) {
        this.eventData.toggleComments.emit(show);
        this.eventData.setShowComments(show);
    }

    private submitCommentEvent(series: any, time: { period_start: string, period_end: string }, initMessage?: string): boolean {

        const comment = this.getNonEmptyComment(time.period_end, initMessage);
        if (!comment) return false;
        if (series['warned'] === true) {
            series['warned'] = comment;
        }
        this.eventData.addInlineComment(comment, time.period_start, time.period_end, [series]).then(() => {
            this.eventData.toggleComments.emit(true);
            this.getEvents();
        });
        return true;
    }

    commentIconHover(events) {
        this.tileDataService.commentIconHover.emit(events)
    }

    commentHover(value) {
        value.event.relationships.series_list.data.forEach((series) => {
            this.hovering_events.push(series.id)
        });
    }

    commentLeave(value) {
        this.hovering_events = [];
    }

    private getEvents() {
        const ctrl = this;
        this.series_events = {};
        this.eventData.getEvents(new Date(ctrl.dtp.start), new Date(ctrl.dtp.end), ctrl.series_list, [ctrl.process])
            .pipe(
                takeUntil(this.onDestroy)
            )
            .subscribe(data => {
                ctrl.tileDataService.events = data.data;
                data.data.forEach(event => {
                    event.relationships.series_list.data.forEach(series => {
                        if (ctrl.series_events[series.id]) {
                            ctrl.series_events[series.id].push(event);
                        } else {
                            ctrl.series_events[series.id] = [event];
                        }
                    })
                })
            })
        // ctrl.tileDataService.events = ctrl.eventData.getEvents(ctrl.dtp.start, ctrl.dtp.end, ctrl.series_data);

    }

    changeWidth(rows): any {
        let style = {
            'width': ((rows * 30.4) - 10) + 'px', //no rows * row height - 2*5px padding
            'transform': 'translateX(' + String(25.4 - ((rows - 1) * 15.2)) + 'px)' //row height plus padding - rows above 1 * half row height
        };
        return style;
    }
}
