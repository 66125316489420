<div class="form-dialog">
  <h1>Upload File
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Basic">
        <form class="form-section column">

          <div class="row inline">
            <label for="theFile">Choose File</label>
            <input type="file" name="file" id="theFile" (change)="handleFileInput($event.target.files)">
          </div>

          <div class="row">
            <div class="select-search inline">
              <select-search *ngIf="collector_list.length>0"
                             [label]="'Collector'"
                             [(value)]="collector"
                             (selectChange)="collector"
                             [data]="collector_list">
              </select-search>

            </div>
          </div>

          <div class="row">
            <mat-checkbox [(ngModel)]="override" name="override">Override</mat-checkbox>

            <mat-checkbox [(ngModel)]="add_mappers" name="add_mappers">Add Mappers</mat-checkbox>

            <mat-checkbox [(ngModel)]="add_series" name="add_series">Create Series</mat-checkbox>

            <mat-checkbox [(ngModel)]="row_major" name="row_major">Row Major</mat-checkbox>
          </div>
          <div>
            <button mat-button class="btn-any" (click)="uploadData()" > Upload</button>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content><!--/mat-dialog-content-->
    <div>

      <button class="btn-close" (click)="closeDialog()" > Close</button>
    </div>
</div>
