<div class="form-dialog" style="min-width:890px;">
    <h1>Edit {{title}}
        <div (click)="showing_hints=!showing_hints">
            <ng-container *ngIf="showing_hints">Hide hints</ng-container>
            <ng-container *ngIf="!showing_hints">Show hints</ng-container>
        </div>
    </h1>
    <flowchart-process-form *ngIf="component.data.type === 'process'"
                            [flowchart]="flowchart"
                            [component]="component"
                            [show_hints]="showHints"
                            [dialog]=true
                            (save_event)="save($event)"
                            (close_event)="onCloseClick()">

    </flowchart-process-form>
     <flowchart-stream-form *ngIf="component.data.type === 'stream'"
                            [flowchart]="flowchart"
                            [component]="component"
                            [show_hints]="showHints"
                            [dialog]=true
                            (save_event)="save($event)"
                            (close_event)="onCloseClick()">

    </flowchart-stream-form>
     <flowchart-equipment-form *ngIf="component.data.type === 'equipment'"
                            [flowchart]="flowchart"
                            [component]="component"
                            [show_hints]="showHints"
                            [dialog]=true
                            (save_event)="save($event)"
                            (close_event)="onCloseClick()">

    </flowchart-equipment-form>
  <flowchart-image-form (close_event)="onCloseClick()"
                        (save_event)="save($event)"
                        *ngIf="component.data.type === 'image'"
                        [component]="component"
                        [dialog]=true
                        [flowchart]="flowchart"
                        [show_hints]="showHints">

  </flowchart-image-form>
</div>