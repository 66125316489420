/**
 * Created by phillip on 2016/07/17.
 */

import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import * as utils from '../lib/utils'
import {DateTimePeriod, DateTimePeriodService} from "../services/datetime_period.service";
import {MatDialog, MatDialogConfig, MatSnackBar, Sort} from "@angular/material";
import {TileDataService} from "../services/tile_data.service";
import {SeriesDataService} from "../services/series_data.service";
import {ApiService} from "../services/api.service";
import {ChartDialog} from "../charts/chart-dialog.component";
import * as _ from "lodash";
import {Router} from "@angular/router";
import {PlantDataService} from "../services/plant_data.service";
import {takeUntil} from "rxjs/operators";
import {Subject, Subscription} from "rxjs";
import {HeaderDataService} from "../services/header_data.service";
import {AppScope} from "../services/app_scope.service";
import {EventDataService} from "../services/event_data.service";

@Component({
    selector: 'series-table',
    templateUrl: 'series-focus-table.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SeriesTableComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    series_events: any;
    access: { all: { promise: Promise<any> }, permissions: any };
    seriesPermissions: any;
    override_calc_allowed: boolean = true;
    process_full: any;
    hovering_events: any[] = [];
    series_map: any = {};
    est_favourability_mapping: any = {};

    get config() {
        return this._config;
    }

    statusColumns: string[] = [];
    extraColumns: string[] = [];
    customColumns: string[];

    summary: any[];
    summarySorted: any[];
    significant_numbers: boolean = true;
    removeStatusName = (name) => !['Name', 'Status', 'Description'].includes(name);
    series_map_promise: any;
    refreshSubscription: Subscription;

    editing: any = false;
    col_dict: any;
    private buttons: any[];
    private _config: {
        title: string,
        columns: any[],
        mobile_columns: any[],
        series_list: any[],
        process: any,
        estimate_type?: string[],
        column_text?: any
        show_comments?: boolean
    };

    constructor(private http: HttpClient,
                private dateTimePeriod: DateTimePeriodService,
                private snackbar: MatSnackBar,
                public tileData: TileDataService,
                private seriesData: SeriesDataService,
                private eventData: EventDataService,
                public appScope: AppScope,
                private api: ApiService,
                public dialog: MatDialog,
                private router: Router,
                public plantData: PlantDataService,
                private headerData: HeaderDataService,) {
    }

    @Input()
    set config(config: {
        title: string,
        columns: any[],
        normal_columns?: string[], // legacy column, to be phased out
        detail_columns?: string[], // legacy column, to be phased out
        mobile_columns: any[],
        series_list: any[],
        process: any,
        estimate_type?: string[];
        kpis?: any[]
        column_text?: string;
        show_comments?: boolean;
    }) {
        if (!config.columns) {
            config.columns = [];
            if (config.normal_columns) {
                config.normal_columns.forEach(item => {
                    if (!config.columns.includes(item)) {
                        config.columns.push(item);
                    }
                })
            }
            if (config.detail_columns) {
                config.detail_columns.forEach(item => {
                    if (!config.columns.includes(item)) {
                        config.columns.push(item);
                    }
                })
            }
        }
        if (!config.mobile_columns) {
            config.mobile_columns = this.seriesData.columnsSmall;
        }
        //TODO test that this isn't needed
        config.columns = config.columns.filter((comment) => comment !== 'Comments');
        this.setupColumns(config);
        this._config = config;
    }

    ngAfterViewInit(): void {
        const ctrl = this;

        //Setting default tile for tile
        if (ctrl.config.process) {
            ctrl.tileData.setDefaultTitle(ctrl.config.process.attributes.name)
        }
    }

    ngOnDestroy(): void {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
            this.refreshSubscription = null;
        }

        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    ngOnInit(): void {
        const ctrl = this;
        this.makeMap();
        Promise.all([ctrl.dateTimePeriod.dtp_complete.promise, ctrl.series_map_promise]).then(() => {
            ctrl.refresh(ctrl.dateTimePeriod.dtp)
        });
        this.seriesData.$estimate_types.promise.then(response => {
            ctrl.est_favourability_mapping = ctrl.seriesData.est_favourability_dict;
        });

        this.tileData.addCommentClicked.pipe(takeUntil(this.onDestroy))
            .subscribe(value => this.saveComment(value));
        ctrl.dateTimePeriod.dtpReset.pipe(takeUntil(this.onDestroy))
            .subscribe((dtp) => {
                ctrl.refresh(dtp);
            });
        this.tileData.commentHover.pipe(takeUntil(this.onDestroy))
            .subscribe(value => this.commentHover(value));
        this.tileData.commentLeave.pipe(takeUntil(this.onDestroy))
            .subscribe(value => this.commentLeave(value));
        this.tileData.editing.pipe(takeUntil(this.onDestroy))
            .subscribe((newBool: boolean) => {
                this.editing = newBool;
            });

        if (ctrl.config && ctrl.config.process && ctrl.config.process.id) {

            ctrl.access = ctrl.plantData.getIsolatedPermissions(ctrl.config.process.id);

            ctrl.access.all.promise.then(response => {
                ctrl.access = response;
                ctrl.setButtons()
            })

        } else if (ctrl.config && ctrl.config.series_list && ctrl.config.series_list.length > 0) {
            this.seriesData.getSeriesPermissions(ctrl.config.series_list.map((series) => series.id)).then(
                result => {
                    ctrl.seriesPermissions = result;
                    ctrl.config.series_list.forEach(series => {
                        if (!ctrl.seriesPermissions[series.id] ||
                            (ctrl.seriesPermissions[series.id] && ctrl.seriesPermissions[series.id].includes('override_calculations') === false)) {
                            ctrl.override_calc_allowed = false;
                        }
                    });
                    ctrl.setButtons()
                })
        } else {
            ctrl.setButtons()
        }

    }

    setButtons() {
        const ctrl = this;

        ctrl.buttons = [];
        if (ctrl.config.process) {
            ctrl.buttons.push({
                name: 'Logsheet',
                func: '/view/log_sheet/' + ctrl.config.process.id,
                link: true,
                params: {},
                class: 'fa small fa-flask  hide-xs',
                HoverOverHint: 'Log sheet'
            })
        }

        ctrl.buttons.push({
                name: 'Decimals',
                func: () => ctrl.showDecimals(),
                params: {},
                class: 'fa small fa-percent',
                HoverOverHint: 'Show more decimals'
            }
        );

        if ((ctrl.access && ctrl.access.permissions && ctrl.access.permissions.override_calculations) ||
            (ctrl.seriesPermissions && ctrl.override_calc_allowed)) {

            ctrl.buttons.push({
                name: 'Update calculations',
                func: () => ctrl.overrideCalculations(),
                params: {},
                class: 'fa fa-calculator hide-xs',
                HoverOverHint: 'Update calculations'
            })

        }

        ctrl.tileData.buttonsChanged.next(ctrl.buttons);
    }

    sortData(sort: Sort) {
        const data = this.summary.slice();
        if (!sort.active || sort.direction === '') {
            this.summarySorted = data;
            return;
        }

        this.summarySorted = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            return utils.compare(a[sort.active], b[sort.active], isAsc);
        });
    }

    showDecimals() {
        this.significant_numbers = !this.significant_numbers;
    }

    makeMap() {
        const ctrl = this;
        ctrl.series_map_promise = this.api.series_light.search().toPromise().then(response => {
            if (!response) return;

            response.data.map(series => {
                ctrl.series_map[series.id] = series;
            });
        })
    };

    refresh(dtp: DateTimePeriod) {
        let params = {
            return_type: 'json', format: 'records',
            start: dtp.start.toISOString(),
            end: dtp.end.toISOString(),
        };
        if (this.config.columns) {
            params['columns'] = this.config.columns;
        }

        if (this.config.estimate_type) {
            params['estimate'] = this.config.estimate_type;
        }

        if (this.config.process && this.config.process.id) {
            params['process'] = this.config.process.id;
        }
        if (this.config.series_list && this.config.series_list.length > 0 && this.config.series_list[0].id) {
            console.log('series list for params', this.config.series_list);
            params['series_list'] = this.config.series_list.map((item) => item.id)
        }
        try {
            if (this.refreshSubscription) {
                this.refreshSubscription.unsubscribe();
                this.refreshSubscription = null;
            }
            this.refreshSubscription = this.api.get('/GetSeriesSummary?' + utils.httpParamSerializer(params)).subscribe((response: any[]) => {
                this.summary = response;
                this.summarySorted = response;
                this.summary.map(series => {
                    series.kpi_level = this.series_map[series.ID] ? this.series_map[series.ID].attributes.kpi_level : '';
                });
                this.summary = this.summary.filter(series => {
                    return !this.config.kpis
                        || this.config.kpis.length === 0
                        || this.config.kpis.includes(series.kpi_level);
                });
                this.getEvents();
                // @ts-ignore
                this.sortData({direction: "desc"});
            }, (reject: any) => {
                console.log("Reject", reject);
                if (reject.error.message) {
                    this.snackbar.open(reject.error.message, 'Hide');
                }
            })
        } catch (e) {
        }
    }

    overrideCalculations() {
        const ctrl = this;
        let seriesList: any[]; //array of series id's

        // TODO add dialog confirm
        let doOverride = function () {
            console.log('override calcs called', seriesList);
            ctrl.headerData.getCalculations(ctrl.dateTimePeriod.dtp, seriesList, 'hour', 1).then(response => {
                ctrl.refresh(ctrl.dateTimePeriod.dtp);
                ctrl.snackbar.open('Calculations updated successfully', 'Hide', {duration: 2000})
            }).catch(reason => {
                console.log('failed reasons', reason);
                ctrl.snackbar.open('Update failed ' + reason, 'Hide')
            });
        };
        if (ctrl.config.series_list && ctrl.config.series_list.length > 0 && ctrl.config.series_list[0].id) {
            seriesList = ctrl.config.series_list.map(series => series.id);
            doOverride();
        } else {
            ctrl.api.process.getById(ctrl.config.process.id).toPromise().then(response => {
                if (!response) return;

                ctrl.process_full = response.data;
                seriesList = ctrl.process_full.relationships.series.data.map(series => series.id);
                doOverride();
            })
        }

    }

    editSeries(element) {
        const ctrl = this;

        if (!element || !element.ID) {
            this.snackbar.open("Error opening series of undefined element", "Hide");
            return;
        }

        if (!this.series_map[element.ID]) {
            this.snackbar.open("Could not find series for editing from " + element.ID, "Hide");
            return;
        }

        let $series_full = this.api[this.series_map[element.ID].type].getById(element.ID).toPromise();

        $series_full.then(result => {
            if (!result) return;

            let series_full = result.data;
            ctrl.seriesData.upsertSeries(ctrl.config.process, series_full).afterClosed().subscribe((series) => {
                if (series) {
                    let updated_series;
                    if (series.series) {
                        updated_series = series.series;
                    } else {
                        updated_series = series;
                    }
                    if (!ctrl.series_map[updated_series.id]) {
                        ctrl.series_map[updated_series.id] = updated_series;
                    }
                    ctrl.series_map[updated_series.id].attributes = updated_series.attributes;
                    ctrl.dateTimePeriod.dtpReset.emit(ctrl.dateTimePeriod.dtp);
                }
            })
        })
    }

    openChartDialog(series_name): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = series_name;
        dialogConfig.panelClass = 'chart-dialog';
        const dialogRef = this.dialog.open(ChartDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
    }

    setupColumns(config) {
        const ctrl = this;
        let newColumns, allColumns;
        if (this.appScope.isNotMobile) {
            newColumns = config.columns;
        } else {
            newColumns = config.mobile_columns;
        }
        allColumns = this.seriesData.fillColumns(config.estimate_type).all;
        const statusColumns = ['Name', 'Status', 'Description'];

        this.statusColumns = _.intersection(newColumns, statusColumns);
        this.extraColumns = _.difference(newColumns, statusColumns);
        this.customColumns = _.difference(this.extraColumns, allColumns);

        this.col_dict = this.seriesData.column_dict;

    }

    setComment(e, series, time) {
        console.log('SeriesTableComponent - setComment: ', series, e);
        this.tileData.comment.series = this.series_map[series.ID];
        this.tileData.comment.start = this.dateTimePeriod.dtp.start;
        this.tileData.comment.end = this.dateTimePeriod.dtp.end;
    }

    saveComment(comment) {
        const ctrl = this;
        ctrl.eventData.addInlineComment(comment, ctrl.tileData.comment.start, ctrl.tileData.comment.end,
            [ctrl.tileData.comment.series]).then((new_comment) => {
            ctrl.getEvents();
        })
    }

    commentIconHover(events) {
        this.tileData.commentIconHover.emit(events)
    }

    commentIconLeave() {
        this.tileData.commentIconHover.emit();
    }

    commentHover(value) {
        value.event.relationships.series_list.data.forEach((series) => {
            this.hovering_events.push(series.id)
        });
    }

    commentLeave(value) {
        this.hovering_events = [];
    }

    toggleComments(show) {
        this.eventData.toggleComments.emit(show);
        this.eventData.setShowComments(show);
    }

    private getEvents() {
        const ctrl = this;
        this.series_events = {};
        this.eventData.getEvents(new Date(ctrl.dateTimePeriod.dtp.start), new Date(ctrl.dateTimePeriod.dtp.end),
            ctrl.summary.map((series) => this.series_map[series.ID]), null)
            .pipe(takeUntil(this.onDestroy)).subscribe(data => {
            ctrl.tileData.events = data.data;
            data.data.forEach(event => {
                event.relationships.series_list.data.forEach(series => {
                    if (ctrl.series_events[series.id]) {
                        ctrl.series_events[series.id].push(event);
                    } else {
                        ctrl.series_events[series.id] = [event];
                    }
                })
            })
        })

    }

}


