import {Series} from "../../../services/series_data.service";

export class TreeNode {
    series: Series;
    children?: TreeNode[] = [];
    id?: string;

    get description(): string {
        return this.series.attributes.description;
    }

    get name(): string {
        return this.series.attributes.name
    }
}