import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";

export interface DialogData {
    form_result: string;
    chart_component?: string;
    config?: any;
}

@Component({
    selector: 'chart-dialog',
    templateUrl: 'chart-dialog.component.html',
    styleUrls: ['../forms/forms.less'],
    encapsulation: ViewEncapsulation.None,  //Global Styles
})
export class ChartDialog implements OnInit {
    series_name: any;
    full_series: any;
    chart_component: string = '';
    config: any;

    constructor(private api: ApiService,
                public dialogRef: MatDialogRef<ChartDialog>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        if (this.data.chart_component && this.data.chart_component === 'custom-chart') {
            this.config = this.data.config;
            this.chart_component = this.data.chart_component;
        } else {
            this.series_name = this.data;
        }
        console.log(this.config, this.chart_component, "here")
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.api.series_light.search().toPromise().then((result) => {
            if (!result) return;
            ctrl.full_series = result.data.find((series) => series.attributes.name == ctrl.series_name)
        });

    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}