/**
 * Created by phillip on 2017/01/05.
 */

import {ApiService, Model} from "../../services/api.service";
import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {HeaderDataService} from "../../services/header_data.service";
import {DateTimePeriod, DateTimePeriodService} from "../../services/datetime_period.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HotInstance} from "../../services/hot-instance";

@Component({
    selector: 'history-table',
    templateUrl: 'history.component.html'
})
export class HistoryComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

    private sort;

    @ViewChild(MatSort, {static: false}) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    columns: string[] = ["Datetime", "User", "Name", "Action", "Model", "Changed Data", "Old Data"];
    page_ready: boolean = false;
    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

    @Input()
    hot: HotInstance;

    @Input()
    resource_list: Model[] = [];

    private table_names: string[] = [];
    history: any[];

    dtp: DateTimePeriod;

    constructor(protected api: ApiService,
                private changeDetectorRef: ChangeDetectorRef,
                private headerData: HeaderDataService,
                private dateTimePeriodService: DateTimePeriodService) {
    }

    ngOnInit(): void {
        this.headerData.add_refresh = true;

        setTimeout(() => {
            this.dateTimePeriodService.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe(dtp => {
                this.dtp = dtp;
                this.refreshHistory();
            });

            this.dateTimePeriodService.dtp_complete.promise.then(dtp => {
                this.dtp = dtp;
                this.refreshHistory();
            });
        })
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    refreshHistory() {
        const ctrl = this;
        if (ctrl.hot) {
            if (ctrl.hot.resource.hasOwnProperty('baseUrl')) {
                // @ts-ignore
                ctrl.table_names = [ctrl.hot.resource.modelName];
            } else {
                ctrl.table_names = Object.keys(ctrl.hot.resource);
            }
        } else {
            ctrl.table_names = this.resource_list.map(item => item.modelName);
        }

        this.getHistory().then(() => {
            this.dataSource.data = this.history;

            this.dataSource.filterPredicate = (data, filter) => {
                if (data.attributes.issued_at.toLowerCase().includes(filter) ||
                    data.changed_by.toLowerCase().includes(filter) ||
                    data.attributes.verb.toLowerCase().includes(filter) ||
                    data.attributes.table_name.toLowerCase().includes(filter) ||
                    data.old_json_string.includes(filter) ||
                    data.new_json_string.includes(filter)) {
                    return true;
                }
                return false;
            };

            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
                switch (sortHeaderId) {
                    case "Datetime":
                        return data.attributes.issued_at;
                    case "User":
                        return data.changed_by;
                    case "Action":
                        return data.attributes.verb;
                    case "Model":
                        return data.attributes.table_name;
                    default:
                        return "";
                }
            };

            this.dataSource.paginator = this.paginator;
            this.changeDetectorRef.markForCheck();
        });
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

    private getHistory(): Promise<any> {
        let today = new Date();
        let priorDate = new Date(new Date().setDate(today.getDate() - 30));

        const q: any[] = [{
            name: 'verb',
            op: 'in',
            val: ['insert', 'update', 'delete']
        }];

        q.push({
            name: 'table_name', op: 'in', val: this.table_names
        });

        q.push({
            name: 'issued_at',
            op: 'gt',
            val: this.dtp.start.toISOString()
        });

        q.push({
            name: 'issued_at',
            op: 'lt',
            val: this.dtp.end.toISOString()
        });

        const activityPromise = this.api.activity.search(this.api.prep_q(q, {
            sort: '-issued_at',
            'page[limit]': 5000
        })).toPromise().then(response => this.history = response.data);

        const user_dict = {};
        const userPromise = this.api.users.search().toPromise().then(response => {
            response.data.map(item => {
                user_dict[item.id] = item;
            });
        });
        return Promise.all([activityPromise, userPromise]).then(() => {
            this.history.map((item) => {
                let new_changed_by_fk;
                if (item.attributes.changed_data) {
                    new_changed_by_fk = item.attributes.changed_data.changed_by_fk;
                }
                let old_changed_by_fk;
                if (item.attributes.old_data) {
                    old_changed_by_fk = item.attributes.old_data.changed_by_fk;
                }

                // for searching the events
                item.old_json_string = JSON.stringify(item.attributes.old_data).toLowerCase();
                item.new_json_string = JSON.stringify(item.attributes.changed_data).toLowerCase();

                item.changed_by = new_changed_by_fk ?
                    user_dict[new_changed_by_fk].attributes.name :
                    old_changed_by_fk ? user_dict[old_changed_by_fk].attributes.name : ''
            });
        });
    }
}
