<div class="page">
  <section>
    <div class="row" style="justify-content: space-between">
      <div *ngIf="dataSource" class="table-filter">
        <input (keyup)="applyFilter($event.target.value)" placeholder="Search calculations..."/>
      </div>
      <div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" [pageSize]="page_size"
                       length="{{calculations ? calculations.length : 0}}" showFirstLastButtons></mat-paginator>
      </div>
    </div>

    <div class="table-container">
      <table *ngIf="dataSource!==undefined" [dataSource]="dataSource" [matSortActive]="status"
             class="table calculation-check-table" mat-table matSort matSortDirection="asc">

        <ng-container *ngFor="let column of calcColumns;" [matColumnDef]="column">
          <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell>
            <ng-container>{{column | upperfirst}}</ng-container>
          </th>
          <td *matCellDef="let element;" [ngStyle]="{ 'background-color': getRowColor(element, column) }" mat-cell
              style="text-align: left;border:none;">
            <div *ngIf="column === 'calculation'" class="row center">
              <i (click)="editSeries(element)" class="edit-inline hide-xs"></i>
              <a (click)="openChartDialog(element[column])" [textContent]="element[column]" style="cursor:pointer;"></a>
            </div>
            <div *ngIf="column !== 'calculation'" class="row center">
              <span>{{element[column]}}</span>
            </div>
          </td>
        </ng-container>
        <ng-container>
          <tr *matHeaderRowDef="calcColumns; sticky: true" mat-header-row></tr>
          <tr *matRowDef="let row; columns: calcColumns;" mat-row></tr>
        </ng-container>
      </table>
    </div>
  </section>
</div>