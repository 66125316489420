import * as Handsontable from "handsontable"
import * as moment_ from 'moment';
import DateTimeEditor from '../lib/datetimepicker'
import {
    AfterViewInit, Component, ElementRef, Input, OnInit, OnDestroy, ViewEncapsulation,
    ViewChild
} from "@angular/core";
import {ApiService} from "../services/api.service";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {HeaderDataService} from "../services/header_data.service";
import * as utils from '../lib/utils';
import {HttpClient} from "@angular/common/http";
import {DateTimePeriod, DateTimePeriodService} from "../services/datetime_period.service";
import {SeriesDataService} from "../services/series_data.service";
import {TileDataService} from "../services/tile_data.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs/index";
import {HotInstance} from "../services/hot-instance";

export const moment = moment_["default"];

Date.prototype['addHours'] = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
};

@Component({
    selector: 'event-sheet',
    templateUrl: 'event-sheet-view.component.html',
    styleUrls: ['../../../node_modules/handsontable/dist/handsontable.full.css'],
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class EventSheetViewComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('event_anchor', {static: false}) el: ElementRef;
    @Input()
    config: {
        column_order: string[],
        event_type_name: string,
        event_type_names: string[],
        non_editable_columns: string[],
        hidden_columns?: string[],
        column_widths?: string[]
    };
    allow_edit: boolean;
    column_list: any[];
    constant_properties: any;
    data: any;
    dtp: DateTimePeriod;
    event_prop_dict: { [key: string]: string[] };
    event_series: any;
    event_types: { [key: string]: any };
    events: any;
    hot: HotInstance;
    input_stockpiles: any;
    inputLookups: { source: any; valueLookup: {}; idLookup: {}; };
    non_editable_columns: any[];
    hidden_columns: any[];
    output_stockpile_dict: {};
    output_stockpiles: any;
    outputLookups: { source: any; valueLookup: {}; idLookup: {}; };
    schema: any;
    series_ids: any[];
    series_list: any;
    series_permissions: any;
    seriesList: any;
    single: boolean;
    title: string;
    val_coords: any;
    datePickerConfig: any;
    users: any[];
    event_type: any[];
    buttons: { name: string; func: () => any; params?: {}; class: string; HoverOverHint: string; }[];
    min_spare_rows: number = 3;
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private handsontableGenerator: HandsontableGenerator,
                private http: HttpClient,
                private dateTimePeriod: DateTimePeriodService,
                private seriesData: SeriesDataService,
                private tileData: TileDataService) {
        this.hot = new HotInstance(['attributes.start', 'attributes.end']);
    }

    ngOnInit(): void {
        const ctrl = this;
        ctrl.dateTimePeriod.dtpReset.pipe(
            takeUntil(this.onDestroy)
        ).subscribe((dtp) => {
            ctrl.dtp = dtp;
            ctrl.refresh_events(dtp);
            ctrl.createTable();
            ctrl.insert(ctrl.min_spare_rows);
        });

        ctrl.dateTimePeriod.dtp_complete.promise.then(() => {

            if (ctrl.config.column_widths) {
                ctrl.handsontableGenerator.col_widths = utils.deepCopy(ctrl.config.column_widths);
            }
            ctrl.dtp = ctrl.dateTimePeriod.dtp;

            ctrl.series_list = ctrl.seriesList;
            ctrl.non_editable_columns = ctrl.config.non_editable_columns;
            ctrl.hidden_columns = ctrl.config.hidden_columns;
            ctrl.single = ctrl.config.event_type_names.length === 1;
            ctrl.event_types = {};
            ctrl.title = 'Custom Events';

            ctrl.datePickerConfig = ctrl.setDatePickerConfig(ctrl.dtp);

            const promises = [];

            //Filter for resource types
            //ctrl.component_types = api.component_type.query(api.prep_q([{val:'equipment', name:'base_type', op: 'eq'}],{}));
            const ev_query = [{
                "or": [{
                    "and": [{name: 'start', op: 'gte', val: ctrl.dtp.start}, {
                        name: 'start',
                        op: 'lte',
                        val: ctrl.dtp.end
                    }]
                }, {
                    "and": [{name: 'end', op: 'gte', val: ctrl.dtp.start}, {
                        name: 'end',
                        op: 'lte',
                        val: ctrl.dtp.end
                    }]
                }]
            }, {
                name: 'event_type',
                op: 'has',
                val: {name: 'name', op: 'in', val: ctrl.config.event_type_names}
            }];

            promises.push(ctrl.api.constant_property.search(ctrl.api.prep_q([{
                name: 'event_types',
                op: 'any',
                val: {name: 'name', op: 'in', val: ctrl.config.event_type_names}
            }], {})).toPromise().then(response => ctrl.constant_properties = response.data));

            if (ctrl.events == null) {
                // TOOD ctrl.events are always undefined onInit, so this check is completely pointless
                promises.push(ctrl.api.event.search(ctrl.api.prep_q(ev_query)).toPromise().then(response => {
                    ctrl.data = response.data
                }));
            } else {
                ctrl.data = ctrl.events
            }

            let date_filter_logic = {
                or: [{
                    and: [{name: 'opening_date', op: 'gte', val: ctrl.dtp.start}, {
                        name: 'opening_date',
                        op: 'lt',
                        val: ctrl.dtp.end
                    }]
                }, {
                    and: [{name: 'closing_date', op: 'gte', val: ctrl.dtp.start}, {
                        name: 'closing_date',
                        op: 'lt',
                        val: ctrl.dtp.end
                    }]
                }, {
                    and: [{
                        name: 'opening_date',
                        op: 'lt',
                        val: ctrl.dtp.start
                    }, {
                        or: [{name: 'closing_date', op: 'is_null'},
                            {and: [{name: 'closing_date', op: 'gt', val: ctrl.dtp.start}]}
                        ]
                    }]
                }]
            };

            promises.push(ctrl.api.stockpile.search(
                ctrl.api.prep_q([{
                    name: 'output_event_type',
                    op: 'has',
                    val: {name: 'name', op: 'in', val: ctrl.config.event_type_names}
                }, date_filter_logic])
            ).toPromise().then(response => ctrl.output_stockpiles = response.data));

            promises.push(ctrl.api.stockpile.search(
                ctrl.api.prep_q([{
                    name: 'input_event_type',
                    op: 'has',
                    val: {name: 'name', op: 'in', val: ctrl.config.event_type_names}
                }, date_filter_logic])
            ).toPromise().then(response => ctrl.input_stockpiles = response.data));

            promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));
            promises.push(ctrl.api.event_type.search().toPromise().then(response => {
                ctrl.event_type = response.data;
            }));

            Promise.all(promises).then(() => {
                ctrl.event_types = {};
                ctrl.event_series = {};
                ctrl.series_ids = [];
                ctrl.event_prop_dict = {};

                ctrl.event_type.forEach((item: {
                        attributes: {
                            base_type: string,
                            changed_by_name: string,
                            changed_on: string,
                            created_by_name: string,
                            created_on: string,
                            hide_end: false
                            icon: any,
                            linked_components: string[],
                            name: string,
                            series_property_map: {
                                Drops: string
                            },
                            severity: any,
                            update_function: string
                        },
                        id: string,
                        relationships: any
                    }) => {
                        if (ctrl.config.event_type_names.includes(item.attributes.name)) {
                            ctrl.event_types[item.attributes.name.trim()] = item;
                            ctrl.event_series[item.attributes.name.trim()] = item.relationships.series_list.data.map(series => series.id);
                            item.relationships.series_list.data.map(series => ctrl.series_ids.push(series.id));
                            let prop_ids = [];
                            if (item.relationships.constant_properties) {
                                prop_ids = item.relationships.constant_properties.data.map(prop => prop.id);
                            }
                            ctrl.event_prop_dict[item.attributes.name.trim()] = [];
                            ctrl.constant_properties.forEach(cprop => {
                                if (prop_ids.includes(cprop.id)) {
                                    ctrl.event_prop_dict[item.attributes.name.trim()].push(cprop.attributes.name)
                                }
                            });
                        }
                    }
                );

                ctrl.inputLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.input_stockpiles, null, true);
                ctrl.outputLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.output_stockpiles, null, true);

                ctrl.output_stockpile_dict = {};
                ctrl.output_stockpiles.map(stock => ctrl.output_stockpile_dict[stock.id] = stock);

                if (ctrl.series_ids.length > 0) {
                    ctrl.seriesData.getSeriesPermissions(ctrl.series_ids).then(data => {
                        ctrl.series_permissions = data;
                        ctrl.allow_edit = false;
                        ctrl.series_ids.forEach(id => {
                            if (!ctrl.series_permissions[id]) {
                                ctrl.allow_edit = true;
                            } else if (ctrl.series_permissions[id].includes('edit_process_data') ||
                                ctrl.series_permissions[id].includes('edit_todays_data')) {
                                ctrl.allow_edit = true;
                            }
                        });
                        ctrl.createTable();
                    });
                } else {
                    ctrl.allow_edit = true;
                    ctrl.createTable();
                }

                ctrl.refresh_events(ctrl.dateTimePeriod.dtp);
            });
        })

    }

    ngAfterViewInit() {
        const ctrl = this;
        if (ctrl.config.event_type_names && ctrl.config.event_type_names.length === 1) {
            this.tileData.setDefaultTitle(ctrl.config.event_type_names[0]);
        }
        ctrl.setButtons()
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    setButtons() {
        const ctrl = this;

        ctrl.buttons = [{
            name: 'Save',
            func: () => ctrl.save(),
            class: 'fa fa-save',
            HoverOverHint: 'Save'
        }, {
            name: 'Download',
            func: () => ctrl.download(),
            class: 'fa fa-download',
            HoverOverHint: 'Download'
        }, {
            name: 'Insert',
            func: () => ctrl.insert(),
            class: 'fa fa-plus',
            HoverOverHint: 'Add empty row'
        }];

        ctrl.tileData.buttonsChanged.next(ctrl.buttons);
    }

    refresh_events(dtp: DateTimePeriod) {
        const ctrl = this;
        if (!ctrl.hot) return;
        ctrl.dtp = dtp;
        const ev_query = [{
            "or": [{
                "and": [
                    {name: 'start', op: 'gte', val: dtp.start.toISOString()},
                    {name: 'start', op: 'lte', val: dtp.end.toISOString()}
                ]
            }, {
                "and": [
                    {name: 'end', op: 'gte', val: dtp.start.toISOString()},
                    {name: 'end', op: 'lte', val: dtp.end.toISOString()}
                ]
            }]
        }, {
            name: 'event_type', op: 'has', val: {name: 'name', op: 'in', val: ctrl.config.event_type_names}
        }];

        ctrl.hot.ready = false;

        ctrl.api.event.search(ctrl.api.prep_q(ev_query)).toPromise().then(response => {
            ctrl.data = response.data;
        }).then(() => {
            return ctrl.createTable.bind(ctrl);
        })
    }

    setDatePickerConfig(dtp: DateTimePeriod) {
        return {
            maxDate: dtp.end,
            minDate: dtp.start,
            autoClose: false,
            use24hour: true
        }
    }


    createTable() {
        const ctrl = this;

        const userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);

        ctrl.data.forEach(item => {
            if (item.attributes.start && item.attributes.end) {
                item.attributes.start = moment(item.attributes.start).format('YYYY-MM-DDTHH:mm:ssZZ');
                item.attributes.end = moment(new Date(item.attributes.end)).format('YYYY-MM-DDTHH:mm:ssZZ');
            }
        });

        ctrl.schema = {
            id: null,
            type: 'event', //***
            attributes: {
                comment: null,
                start: null,
                end: null,
                created_on: null,
                changed_on: null,
                event_type_name: null,
                custom_constants: {},
            },
            relationships: {
                series_list: {data: []},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}},
                input_stockpile: {data: {id: null, type: 'stockpile'}},
                output_stockpile: {data: {id: null, type: 'stockpile'}}

            }
        };

        let is_readonly = col => {
            if (ctrl.non_editable_columns) {
                return ctrl.non_editable_columns.includes(col);
            }
            return false; //if list is undefined then allow editing on all columns, but empty means all readOnly
        };

        ctrl.column_list = [
            {
                data: 'attributes.start',
                title: 'Start*',
                type: 'date',
                width: 160,
                datePickerConfig: ctrl.setDatePickerConfig(ctrl.dtp),
                editor: DateTimeEditor,
                dateFormat: 'YYYY-MM-DDTHH:mm:ssZZ',
                correctFormat: true,
                defaultDate: ctrl.dtp.start,
                allowEmpty: false,
                readOnly: is_readonly('Start'),
                renderer: 'date_formatter'
            }
        ];
        if (!ctrl.event_types[ctrl.config.event_type_names[0]].attributes.hide_end) {
            ctrl.column_list.push({
                data: 'attributes.end',
                type: 'date',
                title: 'End*',
                width: 160,
                datePickerConfig: ctrl.setDatePickerConfig(ctrl.dtp),
                editor: DateTimeEditor,
                dateFormat: 'YYYY-MM-DDTHH:mm:ssZZ',
                correctFormat: true,
                defaultDate: ctrl.dtp.end,
                allowEmpty: false,
                readOnly: is_readonly('End'),
                renderer: 'date_formatter'
            });

            ctrl.column_list.push({
                data: (row, value) => {
                    if (row && row['attributes']) {
                        if (row.attributes.start && row.attributes.end) {
                            let rHours, rMin, rSec;
                            if (row.attributes.start < row.attributes.end) {
                                //@ts-ignore
                                const dur_ms = (new Date(row.attributes.end)) - (new Date(row.attributes.start));

                                const hours = Math.floor(dur_ms / 3600000);
                                const minutes = Math.floor(dur_ms % 3600000 / 60000);
                                const seconds = dur_ms / 1000 - hours * 3600 - minutes * 60;
                                rHours = (hours >= 10) ? hours : "0" + hours;
                                rMin = (minutes >= 10) ? minutes : "0" + minutes;
                                rSec = (seconds >= 10) ? seconds : "0" + seconds;
                            } else {
                                rHours = '00';
                                rMin = '00';
                                rSec = '00'
                            }

                            return rHours + ':' + rMin + ':' + rSec;
                        }
                    }
                },
                title: 'Duration',
                readOnly: true
            })
        }

        if (ctrl.single) {
            ctrl.schema.attributes.event_type_name = ctrl.config.event_type_names[0];

        }
        ctrl.column_list.push({
            data: 'attributes.event_type_name',
            title: 'Type*',
            type: 'autocomplete',
            width: ctrl.config.column_widths && ctrl.config.column_widths['Type'] ? ctrl.config.column_widths['Type'] : 120,
            source: ctrl.config.event_type_names,
            strict: true,
            readOnly: is_readonly('EventType')
        });

        ctrl.constant_properties.forEach(item => {
            //TODO add date as well
            ctrl.schema.attributes.custom_constants[item.attributes.name.trim()] = null;
            const data_type_map = {'string': 'text', 'float': 'numeric', 'datetime': 'date'};
            let new_column;
            if (item.attributes.data_type === 'float') {
                new_column = {
                    data: 'attributes.custom_constants.' + item.attributes.name,
                    title: item.attributes.name,
                    type: data_type_map[item.attributes.data_type],
                    format: "0.000"
                }
            } else {
                new_column = {
                    data: 'attributes.custom_constants.' + item.attributes.name,
                    title: item.attributes.name,
                    type: data_type_map[item.attributes.data_type],
                }
            }
            new_column.readOnly = is_readonly(item.attributes.name);
            if (item.attributes.is_drop_down_list) {
                new_column.type = 'autocomplete';
                new_column.source = item.attributes.json;
                new_column.strict = true;
            }
            ctrl.column_list.push(new_column)
        });

        if (ctrl.inputLookups.source.length > 0) {
            ctrl.column_list.push({
                data: ctrl.handsontableGenerator.genLookupDataSource(ctrl.inputLookups, 'input_stockpile'),
                source: ctrl.inputLookups.source,
                title: 'Inputs To',
                type: 'autocomplete',
                trimDropdown: false,
                allowEmpty: false,
            });
            if (!ctrl.hot.required_keys.includes['relationships.input_stockpile.data.id']) {
                ctrl.hot.required_keys.push('relationships.input_stockpile.data.id')
            }
        }

        if (ctrl.outputLookups.source.length > 0) {
            ctrl.column_list.push({
                data: ctrl.handsontableGenerator.genLookupDataSource(ctrl.outputLookups, 'output_stockpile', null, (row, value) => {
                    if (!row.hasOwnProperty('attributes')) {
                        row.attributes = utils.deepCopy(ctrl.schema.attributes);
                    }
                    if (ctrl.single) {
                        row.attributes.event_type_name = ctrl.config.event_type_names[0]
                    }
                    if (row.attributes.event_type_name) {
                        let series_properties = ctrl.event_types[row.attributes.event_type_name].attributes.series_property_map;

                        Object.keys(series_properties).forEach(key => {
                            if (series_properties[key] !== 'total') {
                                row.attributes.custom_constants[key] = ctrl.output_stockpile_dict[row.relationships.output_stockpile.data.id].attributes.stockpile_properties.contained[key];
                            }
                        })
                    }
                }),
                source: ctrl.outputLookups.source,
                title: 'Outputs From',
                type: 'autocomplete',
                trimDropdown: false,
                allowEmpty: false
            });

            if (!ctrl.hot.required_keys.includes['relationships.output_stockpile.data.id']) {
                ctrl.hot.required_keys.push('relationships.output_stockpile.data.id')
            }
        }

        ctrl.column_list = ctrl.column_list.concat([{
            data: row => {
                if (row && row['attributes'] && row.attributes.event_type_name) {
                    return ctrl.event_types[row.attributes.event_type_name].attributes.linked_components
                }
                return [];
            },

            type: 'text',
            title: 'Component',
            readOnly: is_readonly('Component')
        }, {
            data: 'attributes.comment',
            type: 'text',
            title: 'Comment',
            readOnly: is_readonly('Comment'),
            className: "htLeft"
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'changed_by'),
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }
        ]);

        if (ctrl.config.column_widths) {
            ctrl.column_list.forEach(col => {
                let col_name = col.title.replace("*", "");
                if (ctrl.config.column_widths) {
                    col.width = ctrl.config.column_widths[col_name];
                }
            })
        }
        if (ctrl.config.column_order && ctrl.config.column_order.length >= 1) {
            ctrl.column_list = utils.mapOrder(utils.deepCopy(ctrl.column_list), ctrl.config.column_order, 'title', ['*']);
        }
        if (ctrl.hidden_columns) {
            ctrl.column_list = ctrl.column_list.filter(col => !ctrl.hidden_columns.includes(col.title));
        }

        ctrl.hot = ctrl.handsontableGenerator.generateTable(ctrl.api.event, ctrl.schema, ctrl.column_list, ctrl.hot /*should prevent new
         HotInstances from being created the whole time*/);
        ctrl.hot.settings.rowHeaders = false;
        ctrl.hot.settings.columnSorting = true;
        ctrl.hot.settings.readOnly = !ctrl.allow_edit;
        ctrl.hot.settings.minSpareRows = 0;

        ctrl.hot.settings.cells = (row, col, prop) => {
            if (ctrl.hot.instance) {
                if (!ctrl.single && prop.toString().indexOf('custom_constants') > -1) {
                    let cellProperties = {readOnly: true};
                    let custom_constant = prop.toString().replace('attributes.custom_constants.', '');
                    let event_type = ctrl.hot.instance.getDataAtRowProp(row, 'attributes.event_type_name');
                    if (event_type && ctrl.event_prop_dict[event_type].includes(custom_constant) && is_readonly(custom_constant) === false) {
                        cellProperties.readOnly = false;
                    }

                    return cellProperties
                }
            }
        };

        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.data;
        const element = this.el.nativeElement;
        if (ctrl.hot.instance) {
            ctrl.hot.instance.updateSettings(ctrl.hot.settings, false);
        } else {
            ctrl.hot.instance = new Handsontable(element, ctrl.hot.settings);
            ctrl.insert(ctrl.min_spare_rows);
        }
    }

    save() {
        const ctrl = this;
        console.log(this.config.column_widths, this.handsontableGenerator.col_widths);
        if (this.config.column_widths !== this.handsontableGenerator.col_widths) {
            this.config.column_widths = utils.deepCopy(this.handsontableGenerator.col_widths);
            if (this.tileData.tile) {
                this.tileData.tile.parameters.column_widths = this.config.column_widths;
                this.headerData.tileChange.emit(this.tileData.tile);
            }
        }
        ctrl.data.forEach(item => {
            if (item && item['attributes'] && ctrl.event_types[ctrl.config.event_type_names[0]].attributes.hide_end) {

                const end = new Date(item.attributes.start);

                end.setMinutes(0);
                end.setSeconds(0);
                end.setMilliseconds(0);

                end['addHours'](1);

                item.attributes.end = end;
            }

            if (item && item['attributes'] && ctrl.event_types[item.attributes.event_type_name] != null) {
                const event_type = ctrl.event_types[item.attributes.event_type_name];
                if (item.relationships['series_list']) {
                    item.relationships.series_list.data = event_type.relationships.series_list.data.map(item2 => ({
                        'id': item2.id,
                        'type': 'series'
                    }));
                    ctrl.schema.relationships.series_list.data = event_type.relationships.series_list.data.map(item2 => ({
                        'id': null,
                        'type': 'series'
                    }))
                }
            }
        });

        let results = ctrl.hot.save();
        //let promises = results.data.filter(item => item.hasOwnProperty('$promise')).map(item => item.$promise);
        Promise.all(results.deferreds).then(() => {
            ctrl.data = results.data;
            ctrl.updateSeries();
        })
    }

    updateSeries() {
        const ctrl = this;
        const params = {
            start: new Date(ctrl.dtp.start).toISOString(),
            end: new Date(ctrl.dtp.end).toISOString(),
            event_types: ctrl.config.event_type_names
        };

        // ctrl.api.get("/api/collate_events" + '?' + utils.httpParamSerializer(params)).toPromise().then(data => {
        //     ctrl.refresh_events(ctrl.dtp);
        //
        // });
    }

    download() {
        const ctrl = this;
        ctrl.hot.download();
    }

    insert(amount = 1) {
        const ctrl = this;
        if (ctrl.hot.instance) {
            ctrl.hot.instance.alter('insert_row', ctrl.hot.instance.getData().length, amount)
        }
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Custom Events';
        if (ctrl.headerData.component_buttons_loaded === false) {
            ctrl.headerData.buttons = ctrl.headerData.buttons.concat([
                {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save'},
                {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download'}
            ]);
            ctrl.headerData.component_buttons_loaded = true;
        }
    }
}
