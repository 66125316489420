import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DateTimePeriod, DateTimePeriodService} from "../../services/datetime_period.service";
import {TileDataService} from "../../services/tile_data.service";
import {Subject} from "rxjs";
import {SeriesDataService} from "../../services/series_data.service";
import {ApiService} from "../../services/api.service";
import {takeUntil} from "rxjs/operators";
import {DomSanitizer} from "@angular/platform-browser";

/**
 * Similar to the Paragraph component except that it uses raw HTML
 */
@Component({
    selector: 'custom',
    templateUrl: './custom.component.html',
    styleUrls: ["custom.component.less"],
    encapsulation: ViewEncapsulation.None
})
export class CustomComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @Input() save_content: Subject<any>;
    @Input() config: any;
    @Input() show_header: boolean;

    @Output() tileContentChange = new EventEmitter();

    editing: boolean = false;

    display_text: any;
    edit_text: any;

    constructor(private http: HttpClient,
                public dateTimePeriodService: DateTimePeriodService,
                public tileData: TileDataService,
                private seriesData: SeriesDataService,
                private api: ApiService,
                private domSanitizer: DomSanitizer) {
    }

    ngOnInit() {
        const ctrl = this;

        this.tileData.editing.pipe(takeUntil(this.onDestroy)).subscribe((newBool: boolean) => {
            this.editing = newBool;
        });

        this.save_content.subscribe(event => {
            this.setParagraphs(event, this.dateTimePeriodService.dtp);
        });

        this.dateTimePeriodService.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.setParagraphs(ctrl.config, dtp);
        });

        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            if (Object.keys(ctrl.config).length === 0 && ctrl.config.constructor === Object) {
                ctrl.config = "";
            }
            this.setParagraphs(ctrl.config, this.dateTimePeriodService.dtp);
        });
    }

    /**
     * Update the edit_text and display_text properties of the paragraph
     * @param edit_text Unparsed text that will be saved
     * @param dtp
     */
    private setParagraphs(edit_text: any, dtp: DateTimePeriod) {
        const ctrl = this;
        ctrl.edit_text = edit_text;
        this.tileData.trustHTML(ctrl.edit_text, dtp).promise.then((display) => {
            ctrl.display_text = this.getUnsafeHTML(display);
        });
    }

    private getUnsafeHTML(display_text: string) {
        return this.domSanitizer.bypassSecurityTrustHtml(display_text);
    }

    changeContent(event) {
        this.config = event;
        this.tileContentChange.emit(event)
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
        this.save_content.unsubscribe();
    }
}