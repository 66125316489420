import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiService} from "./api.service";
import {Observable, ReplaySubject, Subscription} from "rxjs";
import {AppScope} from "./app_scope.service";
import {tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    /**
     * List of accounts this user has been assigned to.
     */
    public readonly accountsChanged: ReplaySubject<Account[]> = new ReplaySubject<any>(1);
    /**
     * Active account_id assigned to this session.
     */
    public readonly activeAccountChanged: ReplaySubject<{ account_id: string }> = new ReplaySubject<{ account_id: string }>(1);

    // Active account subscription
    private refresh_aa_subscription: Subscription;
    // Accounts subscription
    private refresh_as_subscription: Subscription;

    constructor(private appScope: AppScope,
                private http: HttpClient,
                private api: ApiService) {
        this.refresh();
    }

    /**
     * Refreshes the list of accounts the user has access to as well as what the active account is.
     */
    public refresh() {
        const ctrl = this;
        this.appScope.auth_complete.promise.then(() => {
            if (this.refresh_as_subscription) {
                this.refresh_as_subscription.unsubscribe();
                this.refresh_as_subscription = null;
            }
            if (this.refresh_aa_subscription) {
                this.refresh_aa_subscription.unsubscribe();
                this.refresh_aa_subscription = null;
            }

            this.refresh_aa_subscription = this.http.get('/auth/ActiveAccount').subscribe((response: { account_id: string }) => {
                this.activeAccountChanged.next(response);
            }, error => {
                if (error.status == 404) {
                    try {
                        const default_account_id = this.appScope.current_user_full.relationships.default_account.data.id;
                        this.activeAccountChanged.next({account_id: default_account_id});
                    } catch (e) {
                    }
                } else {
                    console.log("Error determining current active account for session", error);
                }
            });
            const query = this.api.prep_q(null, null, 'order,name');
            this.refresh_as_subscription = this.api.account.search(query).subscribe((response: { data: Account[] }) => {
                let user_linked_accounts;
                try {
                    user_linked_accounts = this.appScope.current_user_full.relationships.accounts.data.map(i => i.id);
                } catch (e) {
                    user_linked_accounts = [];
                }
                try {
                    user_linked_accounts.push(this.appScope.current_user_full.relationships.default_account.data.id);
                } catch (e) {
                }
                const accounts = response.data.filter(account => account.attributes.name != 'WIREBaseConfig');
                accounts.forEach(account => {
                    if (!user_linked_accounts.find(id => id == account.id)) {
                        account.view_only = true;
                    }
                });

                this.accountsChanged.next(accounts);
            });
        })
    }

    /**
     * Sets the active account for this client session.
     * The active account will be used to set the account of created resources. This will be done on the backend,
     * therefore clients should never specify an account when creating or editing resources.
     */
    public setActiveAccount(account_id: string): Observable<any> {
        return this.http.post('/auth/ActiveAccount', {account_id: account_id}).pipe(tap(response => {
            this.activeAccountChanged.next({account_id: account_id});
        }));
    }
}

export class Account {
    id: string;
    attributes: {
        name: string;
        description?: string;
        order: number | null;
    };
    relationships: {
        parent?: {
            data: { id: string }[]
        },
    };
    // client only (though really only super users can see this account without having it assigned to them)
    view_only?: boolean;
}
