import * as Handsontable from "handsontable"
import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {HeaderDataService} from "../services/header_data.service";
import {ApiService} from "../services/api.service";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {HotInstance} from "../services/hot-instance";

@Component({
    selector: 'equipmentSheetView',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class EquipmentSheetViewCtrl implements OnInit {
    hot: any;
    title: string;
    data: any;
    search: string;
    component_types: any;
    revisions: any;
    @Input()
    equipment: any;
    @Input()
    components: any;
    @Input()
    propertyCategories: any;
    property_categories: any;
    schema: any;
    column_list: any[];

    series_light: any[];
    users: any[];

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private handsontableGenerator: HandsontableGenerator) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.hot = new HotInstance();
        ctrl.title = 'Equipment';

        //Filter for resource types
        let promises = [];
        promises.push(ctrl.api.component_type.search(ctrl.api.prep_q([{
            val: 'equipment',
            name: 'base_type',
            op: 'eq'
        }], {})).toPromise().then(response => ctrl.component_types = response.data));

        promises.push(ctrl.api.revision.search().toPromise().then(response => ctrl.revisions = response.data));

        if (ctrl.equipment == null) {
            promises.push(ctrl.api.equipment.search().toPromise().then(response => ctrl.data = response.data));
        } else {
            ctrl.data = ctrl.equipment;
        }

        if (ctrl.components == null) {
            promises.push(ctrl.api.component.search().toPromise().then(response => ctrl.components = response.data));
        }

        if (ctrl.propertyCategories == null) {
            promises.push(ctrl.api.property_category.search(ctrl.api.prep_q([{
                name: 'name',
                val: ['sdsdfsdf', 'asdasd'],
                op: 'in'
            }], {})).toPromise().then(response => ctrl.property_categories = response.data));
        } else {
            ctrl.property_categories = ctrl.propertyCategories;
        }

        promises.push(ctrl.api.series_light.search().toPromise().then(response => ctrl.series_light = response.data));
        promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));

        Promise.all(promises).then(ctrl.createTable.bind(ctrl));
        ctrl.buildHeader();
    }

    createTable() {
        const ctrl = this;
        let componentTypeLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.component_types);
        let revisionLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.revisions, item => item.attributes.name);
        let componentLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.components);
        let seriesLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.series_light);

        let userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);

        ctrl.schema = {
            id: null,
            type: 'equipment',
            attributes: {
                code: null,
                name: null,
                description: null,
                created_on: null,
                changed_on: null,
                icon: null,
                custom_series: {},
                custom_constants: {}
            },
            relationships: {
                revision: {data: {id: null, type: 'revision'}},
                component: {data: {id: null, type: 'process'}},
                component_type: {data: {id: null, type: 'component_type'}},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };

        ctrl.column_list = [{
            data: 'attributes.code',
            type: 'text',
            title: 'Code'
        }, {
            data: 'attributes.name',
            type: 'text',
            title: 'Name'
        }, {
            data: 'attributes.description',
            type: 'text',
            title: 'Description'
        }];

        ctrl.property_categories.forEach(category => {
            //TODO add date as well
            Object.keys(category.attributes.custom_constants).map(item => {
                ctrl.schema.attributes.custom_constants[item] = null;
                ctrl.column_list.push({
                    data: 'attributes.custom_constants.' + item,
                    title: item,
                    type: 'text'
                })
            });

            Object.keys(category.attributes.custom_series).map(item => {
                ctrl.schema.attributes.custom_series[item] = null;
                ctrl.column_list.push({
                    data: ctrl.handsontableGenerator.genLookupCustomDataSource(seriesLookups, item),
                    type: 'autocomplete',
                    title: item,
                    trimDropdown: false,
                    strict: true,
                    source: seriesLookups.source,
                    allowInvalid: true
                })
            })
        });

        ctrl.column_list = ctrl.column_list.concat([{
            data: ctrl.handsontableGenerator.genLookupDataSource(revisionLookups, 'revision'),
            title: 'Revision',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: revisionLookups.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(componentTypeLookups, 'component_type'),
            title: 'Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: componentTypeLookups.source,
            allowInvalid: false
        }, {
            data: 'attributes.icon',
            readOnly: false,
            title: 'Icon'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(componentLookups, 'component'),
            title: 'Component',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: componentLookups.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'created_by'),
            readOnly: true,
            title: 'Created By'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'changed_by'),
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }
        ]);

        ctrl.hot = ctrl.handsontableGenerator.generateTable(ctrl.api.equipment, ctrl.schema, ctrl.column_list);

        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.data;
        setTimeout(() => ctrl.hot.instance = new Handsontable(document.getElementById(ctrl.hot.handson_id), ctrl.hot.settings), 200);
    }

    save() {
        const ctrl = this;
        let results = ctrl.hot.save();
        ctrl.data = results.data;
    }

    download() {
        const ctrl = this;
        ctrl.hot.download();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Equipment';
        ctrl.headerData.buttons = [
            {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save', params: {}},
            {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download', params: {}}
        ];
    }
}
