import * as moment_ from 'moment';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild, ViewEncapsulation
} from "@angular/core";
import {HeaderDataService} from "../../services/header_data.service";
import {ApiService} from "../../services/api.service";
import {DateTimePeriod, DateTimePeriodService} from "../../services/datetime_period.service";
import {Subject, Subscription} from "rxjs";
import {MatPaginator, MatSort, MatTableDataSource} from "@angular/material";
import {takeUntil} from "rxjs/operators";

export const moment = moment_["default"];

@Component({
    selector: 'collector-events-table',
    templateUrl: 'collector-events-table.component.html',
    styleUrls: ['collector-events-table.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,  //Global Styles
})
export class CollectorEventsTableComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    private readonly onDestroy = new Subject<void>();

    private sort;

    @ViewChild(MatSort, {static: false}) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    columns: string[] = ["Collector Name", "Execution Time", "Start Time", "End Time", "Message", "Status", "Errors", "Entries Collected"];
    page_ready: boolean = false;
    buttons = [];

    collectors: {};
    collectorEvents: any[];
    dtp: DateTimePeriod;

    dataSource: MatTableDataSource<any>;

    constructor(private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService,
                private headerData: HeaderDataService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {

        const ctrl = this;
        this.dateTimePeriodService.show_timespan = false;
        this.dateTimePeriodService.show_time = true;
        this.headerData.title = 'Collection Events';
        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            this.dtp = this.dateTimePeriodService.dtp;
            this.headerData.show_dtp = true;
            this.buildHeader();
            this.refreshEvents();
        });

        this.dateTimePeriodService.dtpReset
            .pipe(takeUntil(this.onDestroy))
            .subscribe(dtp => {
                this.dtp = dtp;
                this.refreshEvents();
            });

        ctrl.headerData.downloadDataClicked.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.headerData.openDownloadDialog();
        });

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    refreshEvents() {
        const ctrl = this;
        const collectorsPromise = ctrl.getCollectors();
        const collectorEventsPromise = ctrl.getCollectorEvents();
        Promise.all([collectorsPromise, collectorEventsPromise]).then(() => {
            ctrl.mapCollectorEvents();
        });
    }

    getCollectors() {
        const ctrl = this;
        return ctrl.api.collector.search().toPromise().then(result => {
            ctrl.collectors = {};
            if (!result) return;

            result.data.map((item) => {
                ctrl.collectors[item.id] = item
            });
            return result;
        });
    }

    getCollectorEvents() {
        const ctrl = this;
        return ctrl.api.collector_event.search(ctrl.api.prep_q([
            {name: 'execution_time', op: 'gt', val: ctrl.dtp.start},
            {name: 'execution_time', op: 'lt', val: ctrl.dtp.end}
        ], {})).toPromise().then(response => {
            if (!response) return;

            ctrl.collectorEvents = response.data;
        });
    }

    /**
     * Map the collectors to their collector events
     */
    mapCollectorEvents() {
        const ctrl = this;
        ctrl.collectorEvents.forEach((event) => {
            event.attributes.collector = ctrl.collectors[event.relationships.collector.data.id];
            if (event.attributes.errors) {
            }
        });
        this.dataSource = new MatTableDataSource(this.collectorEvents);
        this.dataSource.filterPredicate = (data, filter) => {
            return (data.attributes.collector.attributes.name && data.attributes.collector.attributes.name.toLowerCase().includes(filter))
                || (data.attributes.execution_time && data.attributes.execution_time.toLowerCase().includes(filter))
                || (data.attributes.min_date_collected && data.attributes.min_date_collected.toLowerCase().includes(filter))
                || (data.attributes.max_date_collected && data.attributes.max_date_collected.toLowerCase().includes(filter))
                || (data.attributes.message && data.attributes.message.toLowerCase().includes(filter))
                || (data.attributes.entries_collected && data.attributes.entries_collected.length > 1 && data.attributes.entries_collected.includes(filter))
                || (data.attributes.status && data.attributes.status.toLowerCase().includes(filter))
                || (data.attributes.errors && data.attributes.errors.toLowerCase().includes(filter));
        };
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case "Collector Name":
                    return data.attributes.collector.attributes.name;
                case "Execution Time":
                    return data.attributes.execution_time;
                case "Start Time":
                    return data.attributes.min_date_collected;
                case "End Time":
                    return data.attributes.max_date_collected;
                case "Message":
                    return data.attributes.message;
                case "Status":
                    return data.attributes.status;
                case "Entries Collected":
                    return data.attributes.entries_collected;
                case "Errors":
                    return data.attributes.errors;
                default:
                    return "";
            }
        };
        this.dataSource.paginator = this.paginator;
        this.changeDetectorRef.markForCheck();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Collection Events';
        ctrl.headerData.add_download = true;
        ctrl.headerData.add_upload = true;
        ctrl.headerData.add_refresh = true;
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }
}