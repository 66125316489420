import {EventEmitter, Injectable, OnInit, Output} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import * as utils from '../lib/utils';
import {MatDialog, MatDialogRef} from "@angular/material";
import {DownloadFormDialogComponent} from "../forms/file_download_form.component";
import {EditHistoryComponent} from "../forms/edit-history.component";
import {AppScope} from './app_scope.service';
import {Subject} from "rxjs";
import {MatDialogConfig} from "@angular/material/dialog/typings/dialog-config";
import {DateTimePeriod} from "./datetime_period.service";
import {Tile} from "./tile_data.service";

@Injectable({
    providedIn: 'root'
})
export class HeaderDataService implements OnInit {
    buttons: { name: string, func: (params?: any) => any, params?: any, 'class': string, 'toggle'?: boolean }[] = [];
    component_buttons_loaded = false;

    private _title: string = 'Wire';
    readonly downloadDataClicked: Subject<void> = new Subject<void>();
    // Only refresh click
    readonly dtpReset: EventEmitter<DateTimePeriod>;
    readonly tileChange: EventEmitter<Tile>;

    add_new_page_form: boolean = false;
    add_download: boolean = false;
    add_refresh: boolean = false;
    add_upload: boolean = false;
    add_edit: boolean = true;
    show_header: boolean = true;
    show_dtp: boolean = false;
    add_present: boolean = true;
    page_menu_options: any = {
        'insights': 'Insights',
        'settings': 'Settings',
        'my_views': 'My Views'
    }; //defaults
    section_height_dict: any = {
        'tiny': {value: '14', fixed: false},
        'small': {value: '24', fixed: false},
        'medium': {value: '42', fixed: false},
        'large': {value: '520', fixed: true},
        'full': {value: '84', fixed: false},
        'auto': {value: 'auto', fixed: null}
    };

    public section_classes = ['tiny', 'small', 'medium', 'large', 'full', 'auto'];

    constructor(private http: HttpClient,
                private titleService: Title,
                public dialog: MatDialog,
                private appScope: AppScope) {
        this.dtpReset = new EventEmitter();
        this.tileChange = new EventEmitter();
    }

    ngOnInit(): void {
    }

    getSectionHeight(section, limits?: { fixed: { min: number, max: number }, unfixed: { min: number, max: number } }) {//returns section height value with unit string

        if (limits && section.height !== 'auto') {
            if (section.fixed_height) {
                if (section.height < limits.fixed.min) {
                    section.height = limits.fixed.min
                }
                if (section.height > limits.fixed.max) {
                    section.height = limits.fixed.max
                }
            }
            if (!section.fixed_height) {
                if (section.height < limits.unfixed.min) {
                    section.height = limits.unfixed.min
                }
                if (section.height > limits.unfixed.max) {
                    section.height = limits.unfixed.max
                }
            }
        }
        if (section.height && section.height !== 'auto') {
            section.string_height = section.height + (section.fixed_height ? 'px' : 'vh');
        } else if (section.height && section.height === 'auto') {
            section.string_height = 'auto';
        } else if (section.class) {
            section.string_height = this.section_height_dict[section.class].value +
                (section.fixed_height || this.section_height_dict[section.class].fixed === true ? 'px' : 'vh');
        } else {
            section.string_height = this.section_height_dict['medium'].value +
                (section.fixed_height || this.section_height_dict['medium'].fixed === true ? 'px' : 'vh');
        }
        return section.string_height;
    }

    setSectionHeight(section) {
        section.height = this.section_height_dict[section.class].value;
        section.height_string = this.getSectionHeight(section);
        section.fixed_height = this.section_height_dict[section.class].fixed
    }

    addSection(id?) {
        const ctrl = this;
        let new_section = {
            id: id ? id : null,
            title: null,
            'class': 'medium',
            height: this.section_height_dict["medium"].value,
            fixed_height: this.section_height_dict["medium"].fixed,
            tiles: []
        };
        return new_section;
    }

    reset() {
        this.buttons = [];
        this.component_buttons_loaded = false;
        this.title = 'Wire';
        this.show_dtp = false;
        this.add_new_page_form = false;
        this.add_download = false;
        this.add_refresh = false;
        this.add_upload = false;
        this.add_edit = false;
        this.show_header = true;
    }

    set title(title: string) {
        this.titleService.setTitle(title);
        this._title = title;
    }

    get title(): string {
        return this._title;
    }

    public folder_options() {
        const ctrl = this;
        if (ctrl.appScope.config_name_map['page_grouping_options']) {
            ctrl.page_menu_options = ctrl.appScope.config_name_map['page_grouping_options'].value;
        }
        return ctrl.page_menu_options;
    }

    // TODO something along these lines depending on how we handle client level components and default pages
    // checkPlant(session_folder) {
    //     let linked_process = null;
    //     let folder = utils.deepCopy(session_folder);
    //     let count = 0;
    //     do {
    //         count++;
    //         console.log('count', count);
    //         if (folder.relationships.linked_process.data) {
    //             linked_process = folder.relationships.linked_process.data;
    //         } else {
    //             folder = folder.parent.folder;
    //         }
    //     } while (linked_process === null || count === 10);
    //     return linked_process;
    // }

    // setPlant(session) {
    //     console.log('folder that session belongs to: ', session.relationships.folders.data);
    //     let exists_in_plant = false;
    //     this.appScope.folders_map[session.relationships.folders.data.id].folder.forEach(folder => {
    //         if (this.checkPlant(folder) !== null) {
    //             exists_in_plant = true;
    //         }
    //     })
    // }

    buildRightMenu() {
        const ctrl = this;
        let menu_items = [];
        let createMenuItem = function (name, link, item_class?, angular_link?) {
            return {
                'name': name,
                'link': link,
                'class': item_class,
                angular_page: angular_link
            }
        };
        menu_items.push(
            createMenuItem('Report Issue',
                'mailto:incoming+mmswire/wire@incoming.gitlab.com?Subject=Wire%20Issue',
                'fa fa-unlink', false));
        menu_items.push(
            createMenuItem('Help',
                '/view/help-page',
                'fa fa-question-circle', true));

        if (ctrl.appScope.current_user.feature_names.includes("launch_admin_gui")) {
            menu_items.push(createMenuItem('Admin Portal', '/admin', 'fa fa-database'));
        }

        if (ctrl.appScope.current_user.role_name.indexOf('Administrator') > -1) {
            menu_items.push(createMenuItem('Manage Users', '/view/user_list', 'fa fa-users', true));
            menu_items.push(createMenuItem('Register User', '/view/register_user', 'fa fa-user', true));
        }

        menu_items.push(createMenuItem('My Security', '/view/my_security',
            'fa fa-users', true));

        menu_items.push(createMenuItem('Logout', '/auth/logout', 'fa fa-sign-out'));

        return {name: ctrl.appScope.current_user.name, items: menu_items};
    }

    isImage(src) {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.onerror = () => reject();
            image.onload = () => resolve();
            image.src = src;
        });
    }

    openDownloadDialog(data?): void {
        const config: MatDialogConfig = {};
        if (data) {
            config.data = data;
        }

        const dialogRef = this.dialog.open(DownloadFormDialogComponent, config)
    }

    openDownloadFileDialog(dtp, process) {

        // TODO implement when MatDialog is added
        // $mdDialog.show({
        //     controller: fileDownloadFormCtrl,
        //     templateUrl: 'static/app/forms/file_download_form.html',
        //     preserveScope: true,
        //     locals: {
        //         dtp: dtp,
        //         process: process
        //     },
        //     clickOutsideToClose: true
        // });
        // return Promise.resolve();
    }

    getCalculations(dtp, series_list, sample_period, extra_hours) {
        const ctrl = this;
        let p = new Promise((resolve, reject) => {
            if (confirm('Selecting to update calculations will delete and replace all existing calculations in the selected range.')) {
                // TODO replace these with a better deep copy method
                //This is needed for the six day moving average
                // let early_start = Object.assign({}, dtp.start);

                let early_start = utils.deepCopy(dtp.start);
                // let early_start = angular.copy(dtp.start);
                early_start.setDate(early_start.getDate() - 6);

                let extra_end = utils.deepCopy(dtp.end)['addHours'](extra_hours);
                // let extra_end = Object.assign({}, dtp.end)['addHours'](extra_hours);

                let params = {
                    series_list: series_list,
                    deepness: 4,
                    insert_from_time: dtp.start.toISOString(),
                    start: early_start.toISOString(),
                    end: extra_end.toISOString(),
                    insert_data: true,
                    deep: true,
                    sample_period: sample_period,
                    run_dependent_calcs: true,
                    override: true

                };

                ctrl.http.get("/api/GetCalcs" + '?' + utils.httpParamSerializer(params)).toPromise().then(data => {
                    ctrl.sendEvent(true, series_list);
                    resolve(data);
                }, error => {
                    ctrl.sendEvent(false, series_list);
                    reject(error);
                });

            } else {
                reject("Not confirmed.")
            }
        });
        return p;
    }

    sendEvent(success: boolean, series_list) {
        (<any>window).dataLayer.push({
            event: 'updatecalculations',
            seriesList: series_list,
            success: success,
            click: 'updated calcs ' + success
        });
        //(<any>window).dataLayer.push({'click': 'updated calcs ' + success});

    }

    // correctionFactor(ev, dtp, series_list)
    correctionFactor(series?): MatDialogRef<EditHistoryComponent> {
        // TODO implement when MatDialog is added
        const ctrl = this;

        return ctrl.dialog.open(EditHistoryComponent, {data: {series: series}});
    }

    updateDayValues(args) {
        let ev = args['ev'];
        let series_list = args['series_list'];
        this.showDayValueForm(ev, series_list);
    }

    showDayValueForm(ev, series_list) {
        // TODO implement when MatDialog is added
        // return $mdDialog.show({
        //     templateUrl: 'static/app/forms/day_value_form.html',
        //     controller: dayValueFormCtrl,
        //     locals: {
        //         series_list: series_lista
        //     },
        //     targetEvent: ev,
        //     clickOutsideToClose: true,
        //     multiple: true
        // })
    }
}
