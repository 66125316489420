import {EventEmitter, Injectable, OnDestroy, Output, ElementRef} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {FlatPromise} from "../lib/utils";
import {forkJoin, Observable, Subject} from "rxjs";
import {catchError, takeUntil, tap} from 'rxjs/operators';
import {User} from "../user_control/user_list.component";

@Injectable({
    providedIn: 'root'
})
export class AppScope implements OnDestroy {
    private readonly onDestroy = new Subject<void>();
    auth_complete: FlatPromise;
    isNotMobile: boolean;
    config_name_map: { [key: string]: { id: string, value: any } };
    features: any[];
    current_user: {
        auth_token_active?: boolean,
        dashboards?: any[],
        default_dashboard?: string,
        feature_names?: string[],
        forgot_password?: boolean,
        id?: string,
        is_super?: boolean,
        json?: any,
        name?: string,
        require_otp?: boolean,
        restricted_access?: boolean,
        role_name?: string[],
        role_names?: string[],
        roles?: string[]
    };
    current_user_full: User;
    page_title: string;
    sidenav_open: boolean;
    mobile_sidenav: any[] = [];
    no_header: boolean;
    errors: string[];
    plants: any[]; //list of top level plants for the site/client
    // $plants: any;
    selected_plant: any;
    company: any;
    user_plants: any[];

    folders_map: any;

    at_who: boolean;
    at_who_coords: { top: number, left: number } = {top: 0, left: 0};
    mentionEmit: EventEmitter<string> = new EventEmitter<string>();
    mentionSelected: EventEmitter<any> = new EventEmitter<any>();
    at_who_index: number;

    @Output() plantChanged = new EventEmitter();

    constructor(private api: ApiService,
                private http: HttpClient,
                private router: Router) {
        const appScope = this;

        appScope.auth_complete = new FlatPromise();

        appScope.features = [];
        this.check_login().pipe(takeUntil(this.onDestroy)).subscribe();
    }

    check_login(): Observable<any> {
        const ctrl = this;
        return ctrl.http.get('/auth/check_login').pipe(
            tap((data: { id: string, feature_names: [], default_dashboard: string }) => {
                ctrl.current_user = data;
                ctrl.features = data.feature_names;

                const sources: Observable<any>[] = [];

                sources.push(this.http.get('/api/users/' + data.id).pipe(tap((response: { data: User }) => {
                    this.current_user_full = response.data;
                    console.log('Loaded current full user', this.current_user_full);
                })));

                let config_name_map = {};
                sources.push(this.api.configuration.search().pipe(tap(response => {
                    const config_data = response.data;
                    config_data.map(item => {
                        config_name_map[item.attributes.name] = {id: item.id, value: item.attributes.value};
                    });
                    ctrl.config_name_map = config_name_map;
                })));

                forkJoin(sources).subscribe(() => {
                    ctrl.auth_complete.resolve(ctrl.current_user);
                    (<any>window).gtag('set', {'userId': ctrl.current_user.id});
                });

                if (ctrl.router.url === '/') {
                    if (data.default_dashboard) {
                        ctrl.router.navigateByUrl(data.default_dashboard);
                    } else {
                        ctrl.router.navigateByUrl('view/page_list_view');
                    }

                }
                // FIXME this navigates even when the user is currently logged in
                //  triggered when this component is injected into another component
                // this.router.navigateByUrl(data.default_dashboard)

                // this.features = api.feature.query(api.prep_q())
            }), catchError((err: any, caught: any) => {
                this.current_user = null;
                // TODO redirect login
                this.router.navigate(['view', 'login']);
                console.log('Rejected')
                return err;
            }));
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}


