import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    ViewChild
} from '@angular/core';
import * as c3 from 'c3';
import {ChartAPI, ChartConfiguration} from 'c3';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ChartDialog} from "../../charts/chart-dialog.component";

@Component({
    selector: 'sparkline',
    templateUrl: './sparkline.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SparklineComponent implements AfterViewInit, OnDestroy {
    @ViewChild('sparkanchor', {static: false}) el: ElementRef;

    private _data_points: number[];

    @Input()
    set data_points(data_points: number[]) {
        this._data_points = data_points;
        this.newChartData();
        this.changeDetectorRef.markForCheck();
    }

    get data_points(): number[] {
        return this._data_points
    }

    @Input()
    series_name?: string;

    @Input()
    height: number = 30;

    chart: ChartAPI;

    constructor(private changeDetectorRef: ChangeDetectorRef, private dialog: MatDialog) {
    }

    // ngOninit()
    ngAfterViewInit(): void {
        this.generateSparkline()
    }

    generateSparkline() {
        if (!this.data_points) {
            console.error('Sparkline did not have any data points');
            return;
        }
        const width = 100;

        const config: ChartConfiguration = {
            bindto: this.el.nativeElement,
            padding: {
                top: -5,
                right: 0,
                bottom: -5,
                left: 0,
            },
            data: {
                columns: [
                    ['data1', ...this.data_points],
                ],
                type: 'spline'
            },
            point: {
                show: false
            },
            onmouseover: undefined,
            axis: {
                x: {show: false},
                y: {
                    show: false,
                    max: Math.max(...this.data_points),
                    min: Math.min(...this.data_points)
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                show: false,
            },
            interaction: {
                enabled: false
            }
        };

        // this is a hack to allow the sparkline to working in both the context tile and when used as is in tables.
        if (this.height === 30) {
            config.size = {height: this.height}
        }

        this.chart = c3.generate(config);
        console.log(' the sparkline was re redenerderd');
        this.changeDetectorRef.markForCheck();
    }

    newChartData() {
        const ctrl = this;
        if (this.chart) {
            this.chart.load({
                unload: true,
                columns: [['data1', ...ctrl.data_points]]
            });
        }

    }

    openChartDialog(series_name): void {
        if (!series_name) {
            return
        }
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = series_name;
        dialogConfig.panelClass = 'chart-dialog';
        const dialogRef = this.dialog.open(ChartDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.destroy();
        }
    }
}
