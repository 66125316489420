/**
 * Created by phillip on 2016/07/17.
 */

import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../services/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {PlantDataService} from "../services/plant_data.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'streamSheetFocusView',
    templateUrl: 'stream_sheet_focus_view.html'
})
export class StreamSheetFocusViewCtrl implements OnInit ,OnDestroy{
    sheet_ready: boolean = false;
    flowSheet: any;
    streams: any;
    processes: any;

    process: any;
    params_subscription: Subscription;

    constructor(private api: ApiService,
                private plantData: PlantDataService,
                private headerData: HeaderDataService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        let ctrl = this;

        ctrl.params_subscription = ctrl.route.params.subscribe(params => {
            ctrl.sheet_ready = false;
            ctrl.headerData.buttons = [];
            let processId = params.processID;
            ctrl.api.process.getById(processId).toPromise().then(result => {
                ctrl.process = result.data;
                ctrl.plantData.process_focus = ctrl.process;
                ctrl.flowSheet = ctrl.plantData.getFlowSheetData(processId);
                ctrl.buildHeader();
                ctrl.flowSheet.allDataFetched.promise.then(ctrl.setupEditSheet.bind(ctrl))
            });
        });
    }

    ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Streams: ' + ctrl.process.attributes.name;
    };

    setupEditSheet() {
        console.log('setting up the sheet');
        const ctrl = this;
        ctrl.streams = ctrl.flowSheet.streams;
        console.log('ctrl.streams', ctrl.streams);
        // @ts-ignore
        ctrl.processes = [ctrl.flowSheet.process_focus].concat(ctrl.plantData.flowSheet.ctrl);
        ctrl.sheet_ready = true;
    };
}
