import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ApiService} from "../../services/api.service";
import {DateTimePeriodService} from "../../services/datetime_period.service";
import {Subject} from "rxjs";
import {GenericChartConfiguration} from "../../charts/chart-config/generic-chart.configuration";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'custom-chart',
    templateUrl: 'custom-chart-form.component.html'
})
export class CustomChart implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    public empty_row = {
        name: null,
        type: 'line',
        line_type: 'solid',
        line_thickness: 'normal',
        axis: 'y',
        cumulative: false,
        colour: null,
        show_limits: false,
        range: null,
        sample_period: null
    };
    public ready: boolean = false;

    @Input()
    config: GenericChartConfiguration;
    chart_type: string;

    constructor(
        public api: ApiService,
        public dateTimePeriodService: DateTimePeriodService
    ) {
    }

    ngOnInit(): void {
        const ctrl = this;

        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            if (ctrl.config.labels === undefined) {
                ctrl.config = ctrl.getEmptyConfig();
            } else {
                if (ctrl.config && ctrl.config.series_list) {
                    //ctrl.checkChartType()
                }
            }

            ctrl.ready = true;
        });
    }

    updateAxes(event) {
        if (event.checked === true) {
            this.config.hide_legend = true;
            this.config.hide_tick_labels = true;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    // TODO this seems to no longer be used
    // checkChartType() {
    //     //Checks whether any of current series chart types is set to gauge, donut or pie, calls update
    //     const ctrl = this;
    //     ctrl.chart_type = 'line';
    //     ctrl.config.series_list.map(config => {
    //         if (['gauge', 'donut', 'pie'].includes(config.type)) {
    //             ctrl.updateChartType({value: config.type});
    //         }
    //     })
    // }

    private getEmptyConfig(): GenericChartConfiguration {
        return {
            labels: {
                title: null,
                sub_title: null
            },
            hide_comments: true,
            hide_legend: false,
            hide_tick_labels: false,
            hide_axes: false,
            set_range: false,
            y_min: null,
            y_max: null,
            y2_min: null,
            y2_max: null,
            min: null,
            max: null,
            width: null,
            chart_title: null,
            series_list: [{
                axis: 'y',
                colour: null,
                cumulative: false,
                name: null,
                show_limits: false,
                type: 'line',
                line_type: null,
                line_thickness: null,
                range: null,
                sample_period: null,
                custom_legend_name: null
            }]
        };
    }
}

