import * as utils from '../lib/utils';
import * as moment_ from 'moment';
import {Component, OnDestroy, OnInit} from "@angular/core";
import {HeaderDataService} from "../services/header_data.service";
import {ApiService} from "../services/api.service";
import {HttpClient} from "@angular/common/http";
import {AppScope} from "../services/app_scope.service";
import {forkJoin, Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatSnackBar, MatTableDataSource} from "@angular/material";
import {DateTimePeriod, DateTimePeriodService} from "../services/datetime_period.service";

export const moment = moment_["default"];

const sumReduce = (sum, value) => sum + value;

export interface WaterBodiesData {
    table_data: any[],
    save_series: string[],
    mdf: any,
    edf: any,
    df: any
}

export interface WaterBody {
    Name: string;
    input_streams: WaterBodyStream[];
    output_streams: WaterBodyStream[];
    chart: WaterBodyChart;
}

export interface WaterBodyStream {
    Type: string;
    Name: string;
    SeriesName: string;
    Value: any;
    Edit: boolean;
    Estimate: any;
    splits: any[];
    MFSeriesName: string;

}

export interface WaterBodyChart {
    config: {
        title: string,
        unit: string
    },
    columns: string[]
}

export interface RowConfig {
    column: string,
    row_key: string,
    title?: string,
    number_format?: string
}

@Component({
    selector: 'water-balance',
    templateUrl: 'water-balance-view.component.html',
    styleUrls: [
        'water-balance-view.component.less'
    ],
    animations: [
        trigger('detailExpand', [
            state('void', style({height: '0px', minHeight: '0', visibility: 'hidden'})),
            state('*', style({height: 'auto', visibility: 'visible'})),
            transition('void <=> *', animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class WaterBalanceComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    columns: string[];
    row_configs: RowConfig[];
    // titles: string[];
    // row_key: string[];
    // number_format: string[];

    forecasts: any[];
    forecast: any;

    // TODO replace stand and end date with dtp
    // start: Date;
    // end: Date;
    private dtp: DateTimePeriod;

    totals: {};
    process_data: any[] = [];

    update_model_auth = false;
    data_ready: boolean = false;
    charts_ready: boolean = false;

    series_to_graph: string[] = ['level', 'in_flow', 'out_flow'];
    values_to_graph: string[] = ['actual', 'mfcast'];

    water_bodies: WaterBody[] = [];
    sample_period: number = 24;
    msg: string = "";
    update_model: any;
    process_data_ready: boolean = false;
    timestamps_actual: string[];
    zero_df: any;
    timestamps_forecast: string[];
    len_time: number;
    graph_time_maps: { actual: string[]; mfcast: string[]; };
    df: any;
    mdf: any;
    water_body_map: any;
    level_series: any;
    level_series_name_map: {};
    data: any;

    dataSource: MatTableDataSource<any> = new MatTableDataSource();

    waterBodyLevelsSubscription: Subscription;
    selected_process: any;

    constructor(private headerData: HeaderDataService,
                private api: ApiService,
                private http: HttpClient,
                public appScope: AppScope,
                private dateTimePeriodService: DateTimePeriodService,
                private snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
        const ctrl = this;
        this.dateTimePeriodService.show_time = false;
        this.dateTimePeriodService.show_timespan = false;
        ctrl.headerData.show_dtp = true;

        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            this.dtp = this.dateTimePeriodService.dtp;
            this.dtp.start = utils.setToHour(moment().add(-3, 'days').toDate(), 6);
            this.dtp.end = utils.setToHour(moment().add(5, 'days').toDate(), 6);

            this.headerData.show_dtp = true;
            this.buildHeader();
        });

        this.dateTimePeriodService.dtpReset.pipe(
            takeUntil(this.onDestroy)
        ).subscribe(dtp => {
            this.dtp = dtp;
            this.refreshPage(true);
        });

        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            if (ctrl.appScope.current_user.feature_names !== null) {
                ctrl.update_model = ctrl.appScope.current_user.feature_names.includes("update_model");
            }

            const component$ = ctrl.api.component.search(ctrl.api.prep_q([{name: 'base_type', op: 'eq', val: 'process',}])).pipe(
                tap(response => {
                    ctrl.process_data = response.data.filter(item => item.type === 'process');
                })
            );

            const forecasts$ = ctrl.api.forecast_calculation.search().pipe(
                tap(response => {
                    ctrl.forecasts = response.data;
                })
            );

            forkJoin([component$, forecasts$]).pipe(takeUntil(this.onDestroy)).subscribe(data => {
                console.log('Got forecasts', ctrl.forecasts);
                ctrl.forecast = ctrl.forecasts.find(item => item.attributes.is_primary == true && item.attributes.forecast === 'water_balance');
                console.log('Process data ', ctrl.process_data, '\nforecast', ctrl.forecast);
                this.process_data_ready = true;

                this.buildHeader();

                ctrl.refreshPage(ctrl.forecast);
            }, err => {
                console.error("Error loading water balance", err);
            });
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    setProperties() {
        this.http.get('/api/set_water_body_properties').toPromise()
    }

    setObjValToZero(obj, val?) {
        if (val == undefined) {
            val = 0
        }

        Object.keys(obj).forEach(key => {
            obj[key] = val;
        });

        return obj
    }

    getTimestampLists() {
        const ctrl = this;
        ctrl.timestamps_actual = Object.keys(ctrl.df[Object.keys(ctrl.df)[0]]);
        ctrl.zero_df = ctrl.setObjValToZero(ctrl.df[Object.keys(ctrl.df)[0]]);
        ctrl.timestamps_forecast = Object.keys(ctrl.mdf[Object.keys(ctrl.mdf)[0]]);
        ctrl.len_time = ctrl.timestamps_forecast.length;

        ctrl.graph_time_maps = {
            actual: ctrl.timestamps_actual,
            mfcast: ctrl.timestamps_actual
        };
    }

    reduceStreams(row, streams, df, namekey, totalnamekey) {
        const ctrl = this;
        return streams.reduce((sum, value) => {
            ctrl.timestamps_forecast.forEach(key => {
                sum[key] += df[value[namekey]][key]
            });
            return sum
        }, ctrl.setObjValToZero(df[row[totalnamekey]]));
    }

    calculateOnDF(func, df, variables, start) {
        const ctrl = this;
        ctrl.timestamps_forecast.forEach(key => {
            const df_row = {};

            Object.keys(variables).map(var_key => {
                    df_row[var_key] = df[variables[var_key]][key]
                }
            );

            start[key] = func(df_row)
        });

        return start
    }

    calculateMultipleOnDF(func, df, vars, old_volume, editable_streams) {
        const ctrl = this;

        ctrl.timestamps_forecast.forEach(key => {
            const df_row = {};

            Object.keys(vars).map(var_key => {
                    if (var_key === 'input_streams' || var_key === 'output_streams') {
                        df_row[var_key] = vars[var_key].map(input => df[input][key])
                    } else {
                        df_row[var_key] = df[vars[var_key]][key]
                    }
                }
            );

            const new_row = func(df_row, old_volume, editable_streams);
            old_volume = new_row.volume;

            let i = 0;
            vars.output_streams.map(series_name => {

                df[series_name][key] = new_row.output_streams[i];
                i++;
            });

            Object.keys(vars).map(var_key => {
                if (!(var_key === 'input_streams' || var_key === 'output_streams')) {
                    df[vars[var_key]][key] = new_row[var_key]
                }
            });
        });
    }

    // ctrl.level_colour = getColorMap([0, 10, 90, 100], ["#ffffff", "#ffffff", "#ffffff", "#ffffff"]);
    // ctrl.text_level_colour = getColorMap([0, 0.1, 99.9, 100], ["#000000", "#000000", "#000000", "#000000"]);

    overFlowCalculation(row, old_volume, editable_outputs) {
        const ctrl = this;

        //Input max_volume
        //Output, out_flow, volume, overflow
        row.in = row.input_streams.reduce(sumReduce, 0);
        row.out = row.output_streams.reduce(sumReduce, 0);
        row.change = row.in - row.out;

        if ((old_volume + row.change) > row.max_volume) {
            var left_over = (old_volume + row.change) - row.max_volume;
            var actual_change = row.change - left_over;
            row.overflow = left_over;
            row.volume = row.max_volume;
            row.change = actual_change;

            row.out = row.out + row.overflow;
            //TODO this change needs to propogate to the next step, this function needs to take into account accumulation

        } else if ((old_volume + row.change) < 0) {
            // window.alert('Your Damns are empty');
            var left_over: number = (old_volume + row.change);
            var actual_change = row.change - left_over;
            row.overflow = 0.0;
            row.volume = 0.0;
            row.change = -old_volume;
            // var num_editable = editable_outputs.reduce(function(a,b){
            //     if(a){
            //     return b+1
            // } else {
            //     return b
            // }},0);
            //
            // for(var i =0; i<row.output_streams.length;i++ ){
            //     if (editable_outputs[i]){
            //         row.output_streams[i] += left_over/num_editable
            //     }
            // }

            row.out = row.output_streams.reduce(sumReduce, 0);

        } else {
            row.overflow = 0.0;
            row.change = row.in - row.out;
        }
        row.volume = old_volume + row.change;
        row.level = 100 * row.volume / row.max_volume;

        return row;
    }

    accumulateOnSeries(series, accumulate) {
        const ctrl = this;

        ctrl.timestamps_forecast.forEach(key => {

            accumulate += series[key];
            series[key] = accumulate;

        });

        return series;
    }

    reduceSeries(df, namekey) {
        const ctrl = this;
        return ctrl.timestamps_forecast.map(key => df[namekey][key]).reduce((sum2, value2) => sum2 + value2);
    }

    rowCalculations(row) {
        const ctrl = this;

        ctrl.mdf[row.MFTotalVolumeSeries] = ctrl.setObjValToZero(ctrl.mdf[row.MFTotalVolumeSeries], row.TotalVolume);

        const vars = {
            max_volume: row.MFTotalVolumeSeries,
            volume: row.MFVolumeSeries,
            overflow: row.MFOverFlowSeries,
            out: row.MFTotalVolumeOutSeries,
            change: row.MFChangeSeries,
            level: row.MFCalculatedLevelSeries,
            in: row.MFTotalVolumeInSeries,
            input_streams: [],
            output_streams: []
        };

        vars.input_streams = row.input_streams.map(item => item.MFSeriesName);
        vars.output_streams = [];
        const editable_streams = [];
        row.output_streams.forEach(item => {
            if (!(item.MFSeriesName === vars.overflow)) {
                vars.output_streams.push(item.MFSeriesName);
                editable_streams.push(item.Edit)
            }
        });

        // vars.output_streams = vars.output_streams.filter(function(name){return name==row.MFOverflowSeries});
        ctrl.calculateMultipleOnDF(ctrl.overFlowCalculation, ctrl.mdf, vars, row.Volume, editable_streams)
    }

    checkChangedStreams(changed_row, changed_stream, checked_row_stream_combos) {
        const ctrl = this;
        ctrl.water_bodies.forEach(row => {
            row.input_streams.concat(row.output_streams).forEach(stream => {
                if (((stream.MFSeriesName === changed_stream.MFSeriesName && row.Name !== changed_row.Name)
                    || changed_row.MFOverFlowSeries === stream.MFSeriesName || (changed_stream.splits !== undefined))
                    && checked_row_stream_combos.indexOf(row.Name + stream.MFSeriesName) === -1) {
//
                    //Update underlying df of water bodies that are linked to changed stream
                    checked_row_stream_combos.push(row.Name + stream.MFSeriesName);

                    stream.Estimate = ctrl.reduceSeries(ctrl.mdf, stream.MFSeriesName) / ctrl.len_time / ctrl.sample_period;

                    ctrl.rowCalculations(row);

                    ctrl.checkChangedStreams(row, stream, checked_row_stream_combos);

                }
            });
        });
    }

    getStreamByName(name) {
        const ctrl = this;
        let found_stream = null;
        ctrl.water_bodies.forEach(row => {
            row.input_streams.concat(row.output_streams).forEach(stream => {
                if (stream.Name === name) {
                    found_stream = stream
                }
            });
        });
        return found_stream
    }

    updateDFs(changed_row, changed_stream, parent_stream?) {
        //Update changed stream
        const ctrl = this;

        ctrl.timestamps_forecast.forEach(key => {
            ctrl.mdf[changed_stream.MFSeriesName][key] = changed_stream.Estimate * ctrl.sample_period
        });

        ctrl.rowCalculations(changed_row);

        ctrl.checkChangedStreams(changed_row, changed_stream, []);
        ctrl.checkChangedStreams(changed_row, changed_stream, []);

        // TODO find why this is not really

        ctrl.updateSummary();
    }

    setupChartColumns(config) {
        const ctrl = this;
        // {
        //     actual: ctrl.data.df[row['LevelSeries']],
        //         mfcast: ctrl.data.mdf[row['MFLevelSeries']]
        // }
        const columns: any[] = [];
        const time_list: any[] = [];
        ctrl.series_to_graph.forEach(series => {
            ctrl.values_to_graph.forEach(item => {
                for (let time in config[series][item]) {
                    if (time_list.indexOf(time) === -1) {
                        time_list.push(time)
                    }
                }
            })
        });

        ctrl.series_to_graph.forEach(series => {
            ctrl.values_to_graph.forEach(item => {
                const data_list = [];
                time_list.forEach(time => {
                    if (config[series][item][time] === undefined) {
                        data_list.push(null)
                    } else {
                        data_list.push(config[series][item][time])
                    }
                });
                const col_name = [series + '_' + item];
                const inner_col: any[] = col_name.concat(data_list);
                columns.push(inner_col)
            });
        });

        time_list.sort();
        //@ts-ignore
        columns.push(['time'].concat(time_list.map(time => new Date(time))));
        return columns
    }

    updateChart(row) {
        const ctrl = this;
        const row_data = {
            level: {
                actual: ctrl.df[row['CalculatedLevelSeries']],
                mfcast: ctrl.mdf[row['MFCalculatedLevelSeries']]
            },
            in_flow: {
                actual: ctrl.df[row['TotalVolumeInSeries']],
                mfcast: ctrl.mdf[row['MFTotalVolumeInSeries']]
            },
            out_flow: {
                actual: ctrl.df[row['TotalVolumeOutSeries']],
                mfcast: ctrl.mdf[row['MFTotalVolumeOutSeries']]
            }
        };
        if (row.chart.config.chart !== undefined) {
            row.chart.config.chart.load({
                columns: ctrl.setupChartColumns(row_data)
            })
        }
    }

    rowSummary(row) {
        const ctrl = this;
        row['In Fcast'] = ctrl.reduceSeries(ctrl.mdf, row.MFTotalVolumeInSeries);

        row['Out Fcast'] = ctrl.reduceSeries(ctrl.mdf, row.MFTotalVolumeOutSeries);
        row['Overflow FCast'] = ctrl.reduceSeries(ctrl.mdf, row.MFOverFlowSeries);

        row['Change Fcast'] = row['In Fcast'] - row['Out Fcast'];

        row['Lvl-Fcast'] = 100 * (row['Volume'] + row['Change Fcast']) / row['TotalVolume'];

        row['Lvl-XFcast'] = 100 * (row['Volume'] + row['Change Fcast'] + row['ExtremeExtra'] + row['OverflowExtra']) / row['TotalVolume'];
        row['Flood'] = false;

        if (row['Lvl-XFcast'] >= 100) {
            // console.log(row['Name'], "Overflow into: ", row['OverFlowSeries']);
            row.output_streams.forEach(stream => {
                if (stream['Name'] === row['OverFlowStream']) {
                    if (ctrl.water_body_map.hasOwnProperty(stream['Destination'])) {
                        ctrl.water_body_map[stream['Destination']]['OverflowExtra'] = ((row['Lvl-XFcast'] - 100) / 100) * row['TotalVolume'];
                        // console.log(row['Name'], ctrl.water_body_map[stream['Destination']], "Over to other thing. Is fine.");
                        row['Lvl-XFcast'] = 100;
                        ctrl.rowSummary(ctrl.water_body_map[stream['Destination']])
                    } else {
                        row['Flood'] = true;

                        // console.log(stream['Destination'], "This is not a bod.");
                        // console.log(row['Name'], "FLOOOOOOD!!!!")
                    }
                }
            });
        }

        if (ctrl.charts_ready) {
            ctrl.updateChart(row);
        }

        return row;
    }

    updateSummary() {
        const ctrl = this;
        ctrl.water_bodies = ctrl.water_bodies.map(row => ctrl.rowSummary(row))
    }

    updateAllCharts() {
        const ctrl = this;
        // TODO move these to the separate components of each chart
        ctrl.water_bodies.forEach(row => {
            ctrl.updateChart(row);
        })
    }

    getLimits() {
        const ctrl = this;
        const level_series_names = [];
        ctrl.water_bodies.forEach(body => {
            level_series_names.push(body['LevelSeries'])
        });
        ctrl.api.series.search(ctrl.api.prep_q([{name: 'name', op: 'in', val: level_series_names}], {})).toPromise().then(response => {
            ctrl.level_series = response.data;

            ctrl.level_series_name_map = {};
            ctrl.level_series.forEach(series => {
                ctrl.level_series_name_map[series.attributes.name] = series[series.attributes.name] = series
            });
            // console.log(ctrl.level_series_name_map);
            ctrl.water_bodies.map(body => {
                const hihi = ctrl.level_series_name_map[body['LevelSeries']].attributes.hihi;
                const hi = ctrl.level_series_name_map[body['LevelSeries']].attributes.hi;
                const low = ctrl.level_series_name_map[body['LevelSeries']].attributes.low;
                const lowlow = ctrl.level_series_name_map[body['LevelSeries']].attributes.lowlow;

                body['LevelColorMap'] = utils.getColorMap([lowlow, low, hi, hihi], ["#1457f4", "#ffffff", "#ffffff", "#c00005"]);
                body['TextColorMap'] = utils.getColorMap([lowlow, low, hi, hihi], ["#ffffff", "#000000", "#000000", "#ffffff"]);
            });
            ctrl.data_ready = true;
        });
    }

    createCharts() {
        const ctrl = this;
        ctrl.water_bodies.forEach(row => {
            const row_data = {
                level: {
                    actual: ctrl.df[row['CalculatedLevelSeries']],
                    mfcast: ctrl.mdf[row['MFCalculatedLevelSeries']]
                },
                in_flow: {
                    actual: ctrl.df[row['TotalVolumeInSeries']],
                    mfcast: ctrl.mdf[row['MFTotalVolumeInSeries']]
                },
                out_flow: {
                    actual: ctrl.df[row['TotalVolumeOutSeries']],
                    mfcast: ctrl.mdf[row['MFTotalVolumeOutSeries']]
                }
            };
            row.chart = {
                config: {
                    title: row["Name"],
                    unit: "Volume"
                },
                columns: ctrl.setupChartColumns(row_data)
            }
        });
    }

    refreshPage(use_forecast?) {
        const ctrl = this;

        ctrl.data_ready = false;

        if (!ctrl.forecast) {
            this.snackBar.open('Could not find a forecast for the water balance. Has one been configured?', "Hide");
            return;
        }

        if (use_forecast === undefined) {
            use_forecast = true
        }
        const day_range = utils.dayDifference(ctrl.dtp.start, ctrl.dtp.end);

        if (day_range > 70) {
            ctrl.sample_period = 24 * 7

        } else if (day_range > 4) {
            ctrl.sample_period = 24
        } else {
            ctrl.sample_period = 1
        }

        this.setupColumns();

        const params: { [key: string]: string } = {
            start: ctrl.dtp.start.toISOString(),
            end: ctrl.dtp.end.toISOString(),
            sample_period: (ctrl.sample_period * 3600).toString(),
            use_forecast: use_forecast
        };
        if (this.selected_process) {
            params.process_id = this.selected_process.id;
        }

        if (ctrl.waterBodyLevelsSubscription) {
            ctrl.waterBodyLevelsSubscription.unsubscribe();
            ctrl.waterBodyLevelsSubscription = null;
        }

        ctrl.waterBodyLevelsSubscription = ctrl.api.get('/api/waterBodyLevels?' + utils.httpParamSerializer(params)).pipe(
            takeUntil(this.onDestroy)
        ).subscribe((data: any) => {
            console.log('water body levels data', data);
            ctrl.data = data;
            ctrl.df = data.df;
            ctrl.mdf = data.mdf;

            ctrl.getTimestampLists();
            ctrl.water_bodies = data.table_data;

            // ctrl.updateSummary();
            ctrl.createCharts();
            ctrl.charts_ready = true;

            ctrl.water_body_map = {};
            if (ctrl.forecast.attributes.json == null) {
                ctrl.forecast.attributes.json = {}
            }
            ctrl.water_bodies.map(row => {
                row.input_streams.concat(row.output_streams).map(stream => {
                    if (ctrl.forecast.attributes.json.hasOwnProperty(stream.MFSeriesName)) {
                        stream.Estimate = ctrl.forecast.attributes.json[stream.MFSeriesName];
                        ctrl.updateDFs(row, stream)
                    }
                })
            });
            ctrl.getLimits();

            ctrl.updateAllCharts();

            ctrl.water_bodies.forEach(row => {
                ctrl.rowCalculations(row);

                ctrl.water_body_map[row['Name']] = row
            });

            ctrl.updateSummary();

            ctrl.dataSource.data = ctrl.water_bodies;
        });
    }

    private setupColumns() {

        // TODO hide non-mobile columns
        this.columns = [
            'Dam/Pond',
            'Level (%)',
            'Calc Level (%)',
            'Volume (m3)',
            'In (m3)',
            'Out (m3)',
            'Change (m3)',
            'In Fcast (m3)',
            'Out Fcast (m3)',
            'Overflow FCast (m3)',
            'Change FCast (m3)',
            'Lvl-Fcast (%)',
            'Lvl-XFcast (%)'
        ];

    }

    saveForecasts() {
        const ctrl = this;

        ctrl.forecast.attributes.json = {};

        ctrl.water_bodies.map(row => {
            row.input_streams.concat(row.output_streams).map(stream => {
                if (stream.Edit) {
                    ctrl.forecast.attributes.json[stream.MFSeriesName] = stream.Estimate
                }
            })
        });

        ctrl.api.forecast_calculation.patch(ctrl.forecast).then(() => {
            console.log('save successful')
        })
    }

    // ctrl.forecast.$promise.then(ctrl.loadPage);

    runCalcs() {
        const ctrl = this;

        ctrl.data_ready = false;

        // 2019-05-07T00:00:00.000Z
        // target format: 2019-05-14T04:00:00.000Z
        const params: { start: string, end: string, override: string } = {
            start: ctrl.dtp.start.toISOString(),
            end: ctrl.dtp.end.toISOString(),
            override: 'true'
        };

        ctrl.http.get('/api/run_water_calcs', {params: params}).pipe(
            takeUntil(this.onDestroy)
        ).toPromise().then(data => {
            ctrl.refreshPage(true);
            ctrl.data_ready = true;
        });
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Water Balance';
        ctrl.headerData.add_refresh = true;
        ctrl.headerData.buttons = [
            {name: 'Save Forecasts', func: ctrl.saveForecasts.bind(ctrl), class: 'icon-save'},
            {name: 'Run Calculations', func: ctrl.runCalcs.bind(ctrl), class: 'fa fa-calculator'}
        ]
    }
}