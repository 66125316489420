"use strict";

import {PageViewFormComponent} from '../forms/page_view_form.component';
import {Component, HostListener, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {UserData} from "../services/user_data.service";
import {HeaderDataService} from "../services/header_data.service";
import {AppScope} from "../services/app_scope.service";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Section, Session} from "../services/tile_data.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {TileFormComponent} from "../forms/tile_form.component";
import {forkJoin, Subject} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
// import * as utils from '../lib/utils';
import {MenuTreeService} from "../services/menu-tree.service";
import {deepCopy} from "../lib/utils";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'page-view',
    templateUrl: 'page-view.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PageViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    session: Session = null;

    editing: boolean = false;
    printing_sections: boolean = false; //This is the mode for viewing the print section buttons
    printing: boolean = false; //This is used to assign a temporary class to each section to show/hide during print mode

    @HostListener('window:afterprint', ['$event'])
    onAfterPrint(event) {
        const ctrl = this;
        ctrl.printing = false;
        ctrl.sections.forEach(section => {
            section['printing'] = false;
        })

    }

    constructor(public api: ApiService, public userData: UserData,
                private route: ActivatedRoute,
                public headerData: HeaderDataService,
                public appScope: AppScope,
                public dateTimePeriodService: DateTimePeriodService,
                public dialog: MatDialog,
                private router: Router,
                private menuTreeService: MenuTreeService,
                private snackbar: MatSnackBar) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.dateTimePeriodService.dtp_complete.promise.then(() => {
            ctrl.headerData.add_new_page_form = true;
            ctrl.dateTimePeriodService.dtpChanged.pipe(takeUntil(ctrl.onDestroy))
                .subscribe(dtp => this.dateTimePeriodService.dtp = dtp)
            ctrl.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
                // this is repeated, cause otherwise the array is empty when going to another page
                //console.log('loading session state: ', params);
                ctrl.headerData.add_new_page_form = true;
                ctrl.headerData.show_dtp = true;
                ctrl.headerData.add_refresh = true;
                ctrl.headerData.add_download = true;
                ctrl.headerData.add_present = true;

                const $session_state = ctrl.api.session_state.getById(params.sessionID).pipe(
                    takeUntil(this.onDestroy),
                    tap(response => {
                        ctrl.session = response.data;

                        //Don't let whole page break if there is some problem with the json
                        if (!this.session.attributes.json.sections || !Array.isArray(this.session.attributes.json.sections)) {
                            this.session.attributes.json = {"sections": [{"tiles": []}]};
                        }
                        ctrl.setupSections();
                        // ctrl.json = ctrl.session.attributes.json;
                        // ctrl.sections = ctrl.json.sections;
                    })
                );

                forkJoin([$session_state]).pipe(takeUntil(this.onDestroy)).subscribe(() => {
                    if (this.dateTimePeriodService.read_dtp_from_url === true) {
                        // Make the next page_view use their respective default dtp settings
                        this.dateTimePeriodService.read_dtp_from_url = null;
                    } else {
                        if (ctrl.session.attributes.range) {
                            ctrl.dateTimePeriodService.dtp = ctrl.dateTimePeriodService.getDTP(ctrl.session.attributes.range);
                        } else {
                            ctrl.dateTimePeriodService.dtp = ctrl.dateTimePeriodService.setDefault(ctrl.dateTimePeriodService.dtp);
                        }

                        if (ctrl.session.attributes.json.sample_period) {
                            ctrl.dateTimePeriodService.dtp.sample_period = deepCopy(ctrl.dateTimePeriodService.sample_dict[ctrl.session.attributes.json.sample_period]);
                        } else if (this.appScope.config_name_map['default_sample_period']) {
                            const default_period = this.appScope.config_name_map['date_period'].value.default_period;
                            ctrl.dateTimePeriodService.dtp.sample_period = deepCopy(ctrl.dateTimePeriodService.sample_hours_dict[default_period]);
                        } else {
                            ctrl.dateTimePeriodService.dtp.sample_period = deepCopy(ctrl.dateTimePeriodService.sample_dict['hour']);
                        }
                        ctrl.dateTimePeriodService.dtpReset.emit(ctrl.dateTimePeriodService.dtp);
                    }

                    ctrl.buildHeader();
                });
            });
        });
        ctrl.headerData.downloadDataClicked.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.headerData.openDownloadDialog();
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    get json() {
        if (this.session) {
            return this.session.attributes.json;
        }
    }

    set json(value: any) {
        this.session.attributes.json = value;
    }

    get sections(): Section[] { //this function runs 1000 times, find better way?
        if (this.session) {
            return this.session.attributes.json.sections;
        } else {
            return [];
        }
    }

    save_sections(event) {
        this.session.attributes.json.sections = event;
        this.userData.saveConfig(this.session).then(result => {
            this.snackbar.open("Sections Saved. ", null, {duration: 3000});
        })
    }

    save_tile(event?, section_index?: number, tile_index?: number) {
        const ctrl = this;
        if (event) {
            ctrl.session.attributes.json.sections[section_index].tiles[tile_index] = event.tile
        }
        this.userData.saveConfig(this.session);
    }

    /*
     * this function removes tiles from a session state and saves the session
     * @param index
     * @param section
     */
    delete_tile(index, section) {
        section.tiles.splice(index, 1);

        this.save_tile();
    }

    openPageViewDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = this.session;

        const dialogRef = this.dialog.open(PageViewFormComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            console.log('return result of saving', result);
            if (result) {
                this.session.attributes = result.attributes;
                this.session.relationships.folders = result.relationships.folders;
                //this.session.attributes.visibility = result.visibility;
                this.userData.saveConfig(this.session).then(() => {
                    this.menuTreeService.refresh();
                });
            }
        });
    }

    openTileDialog(): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {"tile": null, "index": null, "dtp": this.dateTimePeriodService.dtp};
        const dialogRef = this.dialog.open(TileFormComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('result from saving tile openTileDialog', result);
            if (result) {
                ctrl.session.attributes.json.sections[0].tiles.push(result);
                ctrl.userData.saveConfig(ctrl.session).then(() => {
                    this.menuTreeService.refresh();
                });
            }
        });
    }

    setupSections() {
        const ctrl = this;
        this.session.attributes.json.sections = this.session.attributes.json.sections.map(section => {
            section.id = ctrl.session.attributes.json.sections.indexOf(section);
            section.tiles.map(tile => {
                tile.id = section.id + '_' + section.tiles.indexOf(tile);
                return tile;
            });
            return section;
        });

    }

    printToPdf(button) {
        setTimeout(() => window.print());
    }

    togglePrintSectionToPdf() {
        this.printing_sections = !this.printing_sections;
    }

    toggleEditMode() {
        this.editing = !this.editing;
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = this.session.attributes.name;
        //if(this.showIfOwner() && this.appScope.isNotMobile){

        if (!ctrl.appScope.current_user.restricted_access) {
            ctrl.headerData.buttons.unshift({
                name: 'Add Tile',
                func: ctrl.openTileDialog.bind(ctrl),
                params: {},
                class: 'icon-add hide-xs'
            })
        }
        ctrl.headerData.buttons.push(...[

            {
                name: 'Edit Page',
                func: ctrl.openPageViewDialog.bind(ctrl),
                params: 'current',
                class: 'icon-edit hide-xs'
            }])

        if (this.headerData.add_new_page_form) {
            ctrl.headerData.buttons.push(...[

                {
                    name: 'New Page',
                    func: ctrl.openNewPageForm.bind(ctrl),
                    params: 'current',
                    class: 'icon-edit hide-xs'
                }])
        }

        ctrl.headerData.buttons.push(...[
            {
                name: 'Print to PDF',
                func: ctrl.printToPdf.bind(ctrl),
                params: 'current',
                class: ''
            },
            {
                name: 'Toggle Edit Mode',
                func: ctrl.toggleEditMode.bind(ctrl),
                params: 'current',
                class: '',
                toggle: true
            },
            {
                name: 'Toggle Print Section to PDF',
                func: ctrl.togglePrintSectionToPdf.bind(ctrl),
                params: 'current',
                class: '',
                toggle: true
            }

        ]);

    }

    openNewPageForm() {
        const emptyPageJson = this.emptyPageJson();
        const ctrl = this;
        const dialogRef = this.dialog.open(PageViewFormComponent, {data: emptyPageJson});

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                result.attributes.user_id = ctrl.userData.current_user.id;
                console.log('Original user session', ctrl.userData.user_session);
                console.log('Result from saving the openNewPageForm - user_session.attributes.json.sample_period', result);
                this.userData.saveConfig({
                    attributes: result.attributes,
                    relationships: result.relationships,
                    type: "session_state",
                }).then(result => {
                    this.menuTreeService.refresh();
                    if (result && result.id) {
                        this.router.navigateByUrl('/view/page_view/' + result.id);
                    }
                }, reject => {
                    console.log('PageViewComponent - Error saving new dashboard');
                });
            }
        });

    }

    private emptyPageJson() {
        return {
            id: null,
            relationships: {folders: {data: []}},
            attributes: {
                json: {
                    auto_refresh: false,
                    sample_period: 'hour',
                    layout: "tile",
                    range: 'yesterday',
                    refresh_time: 60,
                    sections: [{
                        "id": 0,
                        "class": "medium",
                        "tiles": []
                    }]
                },
                visibility: 'private',
                range: 'yesterday',
                report: 'Dashboard',
                download_url: '',
                name: null
            }
        }
    }

    printSection(section) {
        const ctrl = this;
        section.printing = true;
        this.printing = true;
        setTimeout(() => window.print());
    }

    isEmpty() {
        let bln = false;
        if (this.sections && this.sections.length > 0) {
            bln = true;
            this.sections.forEach(section => {
                if (section.tiles && section.tiles.length > 0) {
                    bln = false;
                }
            });
            return bln;
        }
        return false;
    }

}
