import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../services/api.service";
import {HttpClient} from "@angular/common/http";
import {HeaderDataService} from "../services/header_data.service";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {ChartDialog} from "../charts/chart-dialog.component";
import {SeriesDataService} from "../services/series_data.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";

@Component({
    selector: 'calculationCheckTable',
    templateUrl: 'calculation-check-table.component.html',
})
export class CalculationCheckTableComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    private readonly onDestroy = new Subject<void>();

    public calculations: any = [];
    public calcColumns: string[];
    dataSource: MatTableDataSource<any>;
    private sort;
    page_size: number = 10;
    status: any;

    @ViewChild(MatSort, {static: false}) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
        this.getCurrent();
    }

    constructor(private api: ApiService, private http: HttpClient, public headerData: HeaderDataService,
                public dialog: MatDialog, private seriesData: SeriesDataService,
                private snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
        this.headerData.title = 'Calculation Validation';
        this.calcColumns = ['calculation', 'description', 'formula', 'status', 'message'];
        this.buildHeader();
        this.getCalcs();
    }

    getCurrent() {
        if (this.dataSource) {
            const skip = this.paginator.pageSize * this.paginator.pageIndex;
            const paged = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort).filter((u, i) => i >= skip)
                .filter((u, i) => i < this.paginator.pageSize);

            this.getChecks(paged.map(page => page.id));
        }
    }

    getCalcs() {
        const ctrl = this;

        ctrl.api.calculation.search().toPromise().then(response => {
            ctrl.calculations = response.data;
            ctrl.calculations.map((calc) => {
                calc['calculation'] = calc.attributes.name;
                calc['description'] = calc.attributes.description;
                calc['formula'] = calc.attributes.name_formula;
                calc['status'] = '';
            });
            this.dataSource = new MatTableDataSource(ctrl.calculations);
            this.dataSource.filterPredicate = (data, filter) => {
                if (data.calculation.toLowerCase().includes(filter)
                    || data.description.toLowerCase().includes(filter)
                    || data.formula.toLowerCase().includes(filter)
                    || data.status.toLowerCase().includes(filter)
                ) {
                    return true;
                }
                return false;
            };
            this.dataSource.paginator = this.paginator;
        });
    }

    getChecks(paged) {
        const ctrl = this;
        let $promises = [];
        ctrl.calculations.forEach(function (calc) {
            if (paged.includes(calc.id) && calc.status === '') {
                var engineering_unit_id = null;

                if (calc.relationships.engineering_unit.data != null) {
                    engineering_unit_id = calc.relationships.engineering_unit.data.id;
                }
                // TODO rework this request to use the utils.httpParamSerializer
                $promises.push(ctrl.http.get(
                    '/api/CheckCalc?name_formula=' + encodeURIComponent(calc.attributes.name_formula) + '&engineering_unit_id='
                    + engineering_unit_id + '&series_id=' + calc.id
                    ).toPromise().then(function (response: { status: boolean | null, msg: null }) {
                        if (response.status === true) {
                            calc.status = "Valid";
                            calc.message = response.msg;
                        } else if (response.status === false) {
                            calc.status = "Invalid";
                            calc.message = response.msg;
                        }
                    }, reject => {
                        calc.status = "Invalid";
                        calc.message = reject.error.msg;
                        console.log('Calculation invalid (400) error handled.');
                        return reject;
                    }).finally(() => {
                        ctrl.dataSource.data = ctrl.calculations;
                    })
                )
            }
        })

        Promise.all($promises).then(() => {
            //TODO get initial data sort working
            //this.dataSource.sort = this.sort;
        })
    };

    getRowColor(calc: { status: string | null, message: string | null }, col) {
        if (col === 'message') {
            if (calc.status === "Invalid") {
                return '#f4bebc'
            } else {
                if (calc.message !== undefined) {
                    if (calc.message.includes("WARNING")) {
                        return '#ffe4bb'
                    } else {
                        return '#a9c6a6'
                    }
                }
            }
        }
    };

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

    openChartDialog(series_name): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = series_name;
        dialogConfig.panelClass = 'chart-dialog';
        const dialogRef = this.dialog.open(ChartDialog, dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            if (result) {
            }
        });
    }

    editSeries(element) {
        const ctrl = this;
        let $series_full = this.api[element.type].getById(element.id).toPromise();
        $series_full.then(returned => {
            let series_full = returned.data;
            ctrl.seriesData.upsertSeries(null, series_full).afterClosed().pipe(takeUntil(this.onDestroy)).subscribe((series) => {
                if (series) {
                    let updated_series;
                    if (series.series) {
                        updated_series = series.series;
                    } else {
                        updated_series = series;
                    }
                    element['calculation'] = updated_series.attributes.name;
                    element['description'] = updated_series.attributes.description;
                    element['formula'] = updated_series.attributes.name_formula;
                    ctrl.snackBar.open("Series saved. Click refresh to run validation again", 'Hide');
                }
            })
        })
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Validate Calculations';
        ctrl.headerData.buttons = [
            {name: 'Refresh', func: ctrl.getCalcs.bind(ctrl), class: 'icon-refresh', params: {}}
        ];
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}