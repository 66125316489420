<style>
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
</style>

<div class="page">
  <ng-container *ngIf="can_view">
    <mat-tab-group disableRipple>
      <ng-container *ngFor="let tab of tabs">
        <mat-tab [label]="tab">
          <section class="section-padding">
            <div *ngIf="dataSource && dataSource[tab]" class="row table-filter">
              <input (keyup)="applyFilter($event.target.value)" [value]="filterValue" placeholder="Search users"/>
            </div>

            <div class="table-container">
              <table *ngIf="dataSource && dataSource[tab]!==undefined" [dataSource]="dataSource[tab]"
                     [id]="tab"
                     [matSortActive]="name" class="table user-table" mat-table matSort matSortDirection="asc">

                <ng-container *ngFor="let column of columns;" [matColumnDef]="column.title">
                  <th *matHeaderCellDef [mat-sort-header]="column.name" mat-header-cell>
                    <ng-container *ngIf="column.title === 'edit'"></ng-container>
                    <ng-container *ngIf="column.title !== 'edit'">{{column.title}}</ng-container>
                  </th>
                  <td *matCellDef="let user;" mat-cell style="text-align: left;border:none;">
                    <ng-container *ngIf="column.title === 'edit'">
                      <i (click)="openDialog(user)" *ngIf="can_edit" class="fa fa-edit" style="cursor:pointer;"></i>
                    </ng-container>
                  <ng-container *ngIf="['restricted_access','active','require_otp','sms_disabled','email_disabled'].includes(column.name)">
                    <mat-checkbox [(ngModel)]="user.attributes[column.name]" (change)="userChanged(user)"
                                  [disabled]="!can_edit || column.name === 'require_otp'"></mat-checkbox>
                  </ng-container>
                  <ng-container *ngIf="!['restricted_access','active','require_otp','sms_disabled','email_disabled','edit'].includes(column.name)">
                    <ng-container>{{user.attributes[column.name]}}</ng-container>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container>
                <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
              </ng-container>
            </table>
          </div>

        </section>
      </mat-tab>

      </ng-container>

      <mat-tab label="Audit History">
        <ng-template matTabContent>
          <history-table [resource_list]="resource_list"></history-table>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-container *ngIf="!can_view">
    You do not have permission to view this page.
  </ng-container>
</div>