<div class="form-dialog">
  <h1>Edit {{title}}
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group (selectedTabChange)="tabChange($event)" [disableRipple]="true">
      <mat-tab label="Friendly">
        <form class="form-section column space-between">
          <div class="row">
            <mat-form-field (mouseenter)="hint='Tile_Title'">
              <mat-label>Title</mat-label>
              <input [(ngModel)]="json.name" matInput name="title">
            </mat-form-field>
            <mat-form-field (mouseenter)="hint='Layout'">
              <mat-label>Layout</mat-label>
              <mat-select [(ngModel)]="json.json.layout" name="layout">
                <mat-option *ngFor="let layout of layouts"
                            [value]="layout">{{layout | titlecase }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field (mouseenter)="hint='Layout'">
              <mat-label>Visibility</mat-label>
              <mat-select [(ngModel)]="json.visibility" name="selected_visibility"
                          [disabled]="appScope.current_user.restricted_access"
                          title="{{ appScope.current_user.restricted_access ?  'You do not have rights to change the visibility of this page.': 'This option determines where this page is viewed' }}"

              >
                <mat-option *ngFor="let item of visibility_options"
                            [value]="item">{{item | titlecase}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field (mouseenter)="hint='Layout'">
              <mat-label>Folders</mat-label>
              <mat-select [(ngModel)]="data.relationships.folders.data" name="selected_folders" multiple
                          (selectionChange)="updateFolder($event.value)" [compareWith]="matSelectCompare">
                <mat-option *ngFor="let folder of folder_options"
                            [value]="folder.folder">{{folder.name}} ({{folder.parent}})
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>

          <!--<form class="form-section column space-between">-->

          <div class="row">
            <mat-form-field (mouseenter)="hint='Range'">
              <mat-label>Range</mat-label>
              <mat-select [(ngModel)]="json.range" name="range">
                <mat-option *ngFor="let range of ranges" [value]="range.range">
                  {{range.range | upperfirst }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field (mouseenter)="hint='Sample period'">
              <mat-label>Default sample period</mat-label>
              <mat-select [(ngModel)]="json.json.sample_period" name="sample_period">
                <mat-option *ngFor="let sample of samplePeriods" [value]="sample.name">
                  {{sample.name | removeUnderscore | titlecase}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-checkbox (mouseenter)="hint='Auto Refresh'" [(ngModel)]="json.json.auto_refresh"
                          class="self-center"
                          name="auto_refresh" style="flex-grow:2;">Auto Refresh
            </mat-checkbox>

            <mat-form-field (mouseenter)="hint='Refresh_Interval'" style="display: flex">
              <mat-label>Refresh Interval</mat-label>

              <input [(ngModel)]="json.json.refresh_time" matInput max="720" min="30" name="refresh_interval"
                     style="width: fit-content"
                     type="number">
              <div style="display:inline-flex;position:relative;top:20px;padding-left:10px;">Minutes</div>

            </mat-form-field>

            <div class="tip-box">
              <div [ngClass]="{'showing': hint=='Auto_Refresh'}" class="tip">
                <label>Auto Refresh</label>
                <div>
                  The date range for data on this dashboard.
                </div>
              </div>
              <div [ngClass]="{'showing': hint=='Refresh_Interval'}" class="tip">
                <label>Refresh Interval</label>
                <div>
                  The date range for data on this dashboard.
                </div>
              </div>
              <div [ngClass]="{'showing': hint=='Range'}" class="tip">
                <label>Range</label>
                <div>
                  The date range for data on this dashboard.
                </div>
              </div>
            </div>
          </div>
          <!--</form>-->
          <div class="row">
            <mat-form-field (mouseenter)="hint='DownloadUrl'">
              <label>Download URL</label>
              <input [(ngModel)]="json.url" matInput name="download_url">
            </mat-form-field>
            <div class="tip-box">
              <div [ngClass]="{'showing': hint=='DownloadUrl'}" class="tip">
                <label>Hide Comments</label>
                <div>
                  The download url of the excel version of this report. (Only Admin can add files)
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div>
              <button (click)="addSection()" class="bordered-button" mat-button>Add Section</button>
            </div>
          </div>
          <div (cdkDropListDropped)="drop($event)" cdkDropList class="section-drag-list">
            <div *ngFor="let section of json.json.sections; let i=index;" cdkDrag>
              <label cdkDragHandle class="inline" style="cursor:move;">
                <i class="fas fa-grip-vertical"></i>Section {{i}}:
              </label>
              <edit-section (deleteSection)="removeRow(i)" [draggable]="true" [index]="i"
                            [section]="section"></edit-section>

              <!--//TODO redo hints?? Hint format needs work-->

            </div>
          </div>

        </form>
      </mat-tab>

      <mat-tab *ngIf="json_ready" label="Advanced">
        <ng-template matTabContent>
          <form class="form-section row ">
            <div class="row stretch">
              <p *ngIf="json_stringify_error === true">JSON was not ready for parsing. Please close this dialog,
                wait for the page to finish loading and open this dialog again.</p>
              <textarea *ngIf="json_stringify_error === false"
                        [(ngModel)]="json_string"
                        style="width:500px;height:300px;"
                        class="full" name="jsontext">
                    </textarea>
            </div>
          </form>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <!--<button class="btn btn-success" (click)="consoleLog(user)">Console log changes</button>-->
    <button (click)="save()" class="btn-save">Save</button>
  </div>
</div>

<!--<md-input-container class="md-block" style="margin-top:20px;" flex="30">-->
<!--<label>Visibility</label>-->
<!--<md-select ng-model="visibility">-->
<!--<md-option value="private">Private</md-option>-->
<!--<md-option value="public">Public Dashboard</md-option>-->
<!--<md-option value="report">Report</md-option>-->
<!--<md-option value="workflow">WorkFlow</md-option>-->
<!--<md-option value="other">Other</md-option>-->
<!--<md-option value="settings">Settings</md-option>-->
<!--</md-select>-->
<!--<div class="hint" ng-if="showHints">-->
<!--Select who will see this dashboard. If Report is selected the dashboard will be added to the Report tab. -->
<!--</div>-->
<!--</md-input-container>-->

<!--<md-input-container class="md-block" style="margin-top:20px;" flex="30">-->
<!--<label>Range</label>-->
<!--<md-select ng-model="range" >-->
<!--<md-option ng-repeat="range in ranges" ng-value="range.name">-->
<!--{{range.name | label}}-->
<!--</md-option>-->
<!--</md-select>-->
<!--<div class="hint" ng-if="showHints">-->
<!--The date range for data on this dashboard. -->
<!--</div>-->
<!--</md-input-container>-->
<!--</div>-->

<!--<div layout="row" layout-padding>-->
<!--<md-input-container flex>-->
<!--<label>Download URL</label>-->
<!--<input ng-model="download_url">-->
<!--</input>-->

<!--<div class="hint" ng-if="showHints">-->
<!--The download url of the excel version of this report. (Only Admin can add files)-->
<!--</div>-->
<!--</md-input-container>-->
<!--</div>-->

<!--<div layout="row" layout-padding style="height:auto;" ng-show="!showAdvanced">-->
<!--&lt;!&ndash;<md-input-container class="md-block" flex="25">&ndash;&gt;-->
<!--&lt;!&ndash;<label>Print Size</label>&ndash;&gt;-->
<!--&lt;!&ndash;<md-select ng-model="print_size" ng-disabled="print_layout==='auto'">&ndash;&gt;-->
<!--&lt;!&ndash;<md-option ng-repeat="size in print_sizes" value="{{size}}">&ndash;&gt;-->
<!--&lt;!&ndash;{{size}}&ndash;&gt;-->
<!--&lt;!&ndash;</md-option>&ndash;&gt;-->
<!--&lt;!&ndash;</md-select>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="hint" ng-if="showHints">&ndash;&gt;-->
<!--&lt;!&ndash;Select the default page size for printing this dashboard.&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</md-input-container>&ndash;&gt;-->
<!--&lt;!&ndash;<md-input-container class="md-block" flex="25">&ndash;&gt;-->
<!--&lt;!&ndash;<label>Print Layout</label>&ndash;&gt;-->
<!--&lt;!&ndash;<md-select ng-model="print_layout" >&ndash;&gt;-->
<!--&lt;!&ndash;<md-option value="portrait">Portrait</md-option>&ndash;&gt;-->
<!--&lt;!&ndash;<md-option value="landscape">Landscape</md-option>&ndash;&gt;-->
<!--&lt;!&ndash;<md-option value="auto">Auto</md-option>&ndash;&gt;-->
<!--&lt;!&ndash;</md-select>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="hint" ng-if="showHints">&ndash;&gt;-->
<!--&lt;!&ndash;The default orientation for printing this dashboard.&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</md-input-container>&ndash;&gt;-->

<!--<md-input-container class="md-block" flex="25">-->
<!--<label>Layout</label>-->
<!--<md-select ng-model="layout">-->
<!--<md-option value="tile">Tile</md-option>-->
<!--<md-option value="column">Column</md-option>-->
<!--<md-option value="print">Print</md-option>-->
<!--</md-select>-->
<!--<div class="hint" ng-if="showHints">-->
<!--Select the layout option that best suits the components on the dashboard and use to which it will be put.-->
<!--</div>-->
<!--</md-input-container>-->
<!--<md-input-container class="md-block" flex="25">-->
<!--<label>Sample Period</label>-->
<!--<md-select ng-model="sample_period" >-->
<!--<md-option ng-repeat="period in sample_periods track by period.name" ng-value="period.name">-->
<!--{{period.name | label}}-->
<!--</md-option>-->
<!--</md-select>-->
<!--<div class="hint" ng-if="showHints">-->
<!--The date range for data on this dashboard.-->
<!--</div>-->
<!--</md-input-container>-->

<!--<md-input-container class="md-block" flex="15">-->
<!--<md-checkbox ng-model="auto_refresh" >Auto Refresh-->
<!--</md-checkbox>-->
<!--<div class="hint" ng-if="showHints">-->
<!--The date range for data on this dashboard.-->
<!--</div>-->
<!--</md-input-container>-->
<!--<md-input-container class="md-block" flex="30" ng-show="auto_refresh">-->
<!--Every <input type="number" step="1" min="15"  ng-model="refresh_time"/> minutes-->
<!--</md-input-container>-->
<!--</div>-->

<!--<div layout="row" layout-padding ng-if="mode==='current'" ng-show="!showAdvanced" class="draggable-chips-list">-->
<!--<md-input-group>-->
<!--<label>Tile order</label>-->
<!--<md-chips ng-model="tiles" placeholder="Tile order" md-removable="false" md-autocomplete-snap md-require-match="true">-->
<!--<md-chip-template>-->
<!--<span >-->
<!--<strong md-chip-draggable>{{$chip.content}}</strong>-->
<!--<em ng-if="$chip.title">({{$chip.title}})</em>-->
<!--<em ng-if="!$chip.title && $chip.content==='default-chart'">({{$chip.parameters.series}})</em>-->
<!--</span>-->
<!--</md-chip-template>-->
<!--</md-chips>-->
<!--</md-input-group>-->
<!--</div>-->

<!--<div layout="row" layout-align="center center" layout-padding ng-click="showAdvanced=!showAdvanced; reloadJson();">-->
<!--{{showAdvanced ? "Hide" : "Show"}} Advanced-->
<!--<i class="fa hideonprint fa-angle-down" style="border:none; margin-left:12px;" ng-class="{'fa-angle-up': showAdvanced, 'fa-angle-down': !showAdvanced}" role="button">-->
<!--</i>-->
<!--</div>-->

<!--<div layout="row" layout-padding ng-if="showAdvanced">-->
<!--<md-input-container flex>-->
<!--These settings will override changes you have made above.-->
<!--<textarea class="pretty-json" ng-model="$parent.jsontext">-->
<!---->
<!--</textarea>-->

<!--<div class="hint" ng-if="showHints">-->
<!--This is the JSON configuration for all the tiles in this dashboard. Custom adjustments can be made to any tiles on the dashboard. However, it is recommended that the Edit Tile form is used wherever possible.-->
<!--This can also be copied to duplicate a dashboard that you want to edit. -->
<!--</div>-->
<!--</md-input-container>-->
<!--</div>-->

<!--<div layout="column" layout-padding layout-align="end end">-->
<!--<div flex>-->
<!--<label>User: {{user.name}}</label>-->
<!--</div>-->
<!--<div class="hint" ng-if="showHints" layout-padding>-->
<!--You are currently logged in as {{user.name}}. All dashboards you create will be under this name.-->
<!--</div>-->

<!--</div>-->
<!--</md-content>-->
<!--</md-dialog-content>-->

<!--<md-dialog-actions>-->
<!--<md-button ng-if="showSave()" ng-click="save()" class="md-primary">-->
<!--Save-->
<!--</md-button>-->
<!--<md-button ng-click="closeDialog()" class="md-primary">-->
<!--Close-->
<!--</md-button>-->

<!--</md-dialog-actions>-->

<!--</md-dialog>-->