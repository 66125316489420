/**
 * Created by phillip on 2016/06/18.
 */

import * as Handsontable from "handsontable"
import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {ApiService} from "../services/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {HotInstance} from "../services/hot-instance";

@Component({
    selector: 'processAccessSheetView',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class ProcessAccessSheetViewCtrl implements OnInit {
    hot: HotInstance;
    title: string;
    process_accesses: any[];
    processes: any[];
    features: any[];
    schema: any;
    column_list: any;
    search: string = '';
    users: any[];

    constructor(
        private api: ApiService,
        private headerData: HeaderDataService,
        private handsontableGenerator: HandsontableGenerator) {
        this.hot = new HotInstance();
    }

    ngOnInit(): void {
        const ctrl = this;
        ctrl.headerData.buttons = [];
        ctrl.title = 'Process Access';

        const promises = [];
        promises.push(ctrl.api.process_light.search().toPromise().then(response => ctrl.processes = response.data));
        promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));

        const parameters = ctrl.api.prep_q([
            {'name': 'process_access_feature', val: true, op: 'eq'}
        ]);

        promises.push(ctrl.api.feature.search(parameters).toPromise().then(response => ctrl.features = response.data));
        promises.push(ctrl.api.process_access.search().toPromise().then(response => ctrl.process_accesses = response.data));
        // ctrl.roles = api.role.list;

        Promise.all(promises).then(ctrl.createTable.bind(ctrl));

        ctrl.buildHeader();
    }

    createTable() {
        const ctrl = this;

        const userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);
        const processLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.processes, item => item.attributes.name);
        const featureLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.features, item => item.attributes.name);

        ctrl.schema = {
            id: null,
            type: 'process_access',
            attributes: {
                created_on: null,
                changed_on: null
            },
            relationships: {
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}},
                top_process: {data: {id: null, type: 'process'}},
                user: {data: {id: null, type: 'users'}},
                feature: {data: {id: null, type: 'feature'}}
            }
        };

        ctrl.column_list = [{
            data: ctrl.handsontableGenerator.genLookupDataSource(processLookup, 'top_process'),
            title: 'Process',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: processLookup.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'user'),
            title: 'User',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: userLookup.source,
            allowInvalid: false
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(featureLookup, 'feature'),
            title: 'Feature',
            type: 'dropdown',
            trimDropdown: false,
            strict: true,
            source: featureLookup.source,
            allowInvalid: false
        }];

        ctrl.hot = ctrl.handsontableGenerator.generateTable(ctrl.api.process_access, ctrl.schema, ctrl.column_list, ctrl.hot);
        ctrl.hot.settings.data = ctrl.process_accesses;
        ctrl.hot.ready = true;
        setTimeout(() => ctrl.hot.instance = new Handsontable(document.getElementById(ctrl.hot.handson_id), ctrl.hot.settings), 200)
    }

    save() {
        const ctrl = this;
        const results = ctrl.hot.save();
        ctrl.process_accesses = results.data;
    }

    download() {
        const ctrl = this;
        ctrl.hot.download();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Access';
        ctrl.headerData.buttons = [
            {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save'},
            {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download'}
        ];
    }
}
