<div class="form-dialog">
  <h1>Edit Text
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">

      <mat-tab label="Edit Paragraph">
        <div class="form-section column space-between">
          {{paragraph.edit_text}}
          <input #imageUpload accept="image/*" style="display:none" type="file">
          <quill-editor #my_quill #quillEditorComponent (click)="adjustTooltip($event)"
                        [(ngModel)]="paragraph"
                        [customOptions]="" [modules]="options" class="text-editing">
          </quill-editor>

        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="action-buttons">
    <button (click)="onCloseClick()" class="btn-close">Close</button>
    <!--<button class="btn btn-success" (click)="consoleLog(user)">Console log changes</button>-->
    <button (click)="save()" class="btn-save">Save</button>
  </div>
</div>