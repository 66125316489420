import {Injectable} from '@angular/core';
import {DateTimePeriodService, DateTimePeriod} from "./datetime_period.service";

@Injectable({
    providedIn: 'root'
})
export class CachingService {
    vdt_context_cache: any = {};
    dtp: DateTimePeriod;

    constructor(private dateTimePeriodService: DateTimePeriodService) {
        this.dtp = dateTimePeriodService.dtp;
    }

    getCachedContextConfig(id) {
        let config: any = {};
        let cached_context = this.vdt_context_cache[id];
        config.selected_series = cached_context.selected_series;
        config.value_only = cached_context.value_only;
        config.series_unit = cached_context.series_unit;
        config.show_unit = cached_context.show_unit;
        config.primary_value = cached_context.primary_value_name;
        return config;
    }

    getCachedContextSummary(id) {
        let summary: any = {};
        let cached_context = this.vdt_context_cache[id];
        summary = {
            Sparkline: cached_context.sparkline
        };
        summary[cached_context.primary_value_name] = cached_context.primary_value_value;
        return summary;
    }

    cacheContext(config, summary) {
        const ctrl = this;
        let cached_context: any = {};
        cached_context.selected_series = config.selected_series;
        cached_context.value_only = config.value_only;
        cached_context.series_unit = config.series_unit;
        cached_context.show_unit = config.show_unit;
        cached_context.primary_value_name = config.primary_value;
        cached_context.primary_value_value = summary[config.primary_value];
        cached_context.sparkline = summary['Sparkline'];
        cached_context.id = config.selected_series.id + ctrl.dtp.start + ctrl.dtp.end;
        ctrl.vdt_context_cache[cached_context.id] = cached_context;
        console.log('CachingService - cacheContext: ', ctrl.vdt_context_cache);
        return cached_context.id;
    }
}
