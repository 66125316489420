<div class="form-dialog column">
  <div class="chart-dialog">
    <ng-container *ngIf="full_series&&full_series.attributes">
      <h1>{{full_series.attributes | nameAndDescription}}</h1>
    </ng-container>
    <mat-dialog-content style="background:whitesmoke;height:300px;">
      <default-chart *ngIf="chart_component!=='custom-chart'" [series]="series_name"></default-chart>

      <generic-chart *ngIf="chart_component==='custom-chart'" [config]="config"
                     class="chart">
      </generic-chart>
      <comment-drawer></comment-drawer>
    </mat-dialog-content>
    <div class="action-buttons">
      <button class="btn-close" (click)="onCloseClick()">Close</button>
    </div>

  </div>
</div>
