/**
 * Created by phillip on 2016/06/18.
 */
import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'customProcessView',
    templateUrl: 'custom_process_sheet_view.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class CustomProcessViewCtrl implements OnInit {
    property_categories: any;
    allProcesses: any[];
    processes: any;
    parent: boolean;
    data: any;
    sheet_ready: boolean;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        const ctrl = this;
        ctrl.headerData.buttons = [];

        const allProcessesPromise = ctrl.api.process.search().toPromise().then(response => ctrl.allProcesses = response.data);

        ctrl.route.params.subscribe(params => {
            const category = params.category;

            const promises = [];
            promises.push(allProcessesPromise);
            promises.push(ctrl.api.process.search(ctrl.api.prep_q([
                {
                    name: 'component_type',
                    val: {
                        name: 'property_categories',
                        op: 'any', val: {name: 'name', val: category, op: 'eq'}
                    }, op: 'has'
                }])).toPromise().then(response => ctrl.processes = response.data));
            promises.push(ctrl.api.property_category.search(ctrl.api.prep_q([{
                name: 'name',
                val: [category],
                op: 'in'
            }], {})).toPromise().then(response => ctrl.property_categories = response.data));

            Promise.all(promises).then(ctrl.setupEditSheet.bind(ctrl));
        });
    }

    setupEditSheet() {
        const ctrl = this;
        ctrl.parent = false;
        ctrl.sheet_ready = true;

    };


}
