<div class="column context-tile-form">
  <div class="row">
    <div (mouseenter)="hint='Series'" *ngIf="full_series_list?.length>0" class="select-search" style="flex-grow:5;">
      <select-search (selectChange)="series_changed=true;config.selected_series = $event.value;"
                     [(value)]="config.selected_series"
                     [data]="full_series_list"
                     [fill_stubs]="true"
                     [label]="'Select a series'">
      </select-search>
      <div *ngIf="config.chart_type==='custom-chart' && series_changed===true" class="reminder">Please remember to
        update series in the chart configuration section.
      </div>
    </div>
    <mat-checkbox [(ngModel)]="config.show_unit" name="show_header"
                  style="width:130px;align-self:center;">Show unit ({{config.series_unit}})
    </mat-checkbox>
  </div>

  <div class="row">
    <mat-form-field style="flex-grow:5;">
      <mat-label>Chart type</mat-label>
      <mat-select (selectionChange)="updateChartParams()"
                  [(value)]="config.chart_type">
        <mat-option *ngFor="let chart of chart_types"
                    [value]="chart.value">
          {{chart.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="!!columns">
      <mat-label class="primary-value">Primary Value</mat-label>
      <mat-select [(value)]="config.primary_value">
        <mat-option *ngFor="let column of columns.all"
                    [matTooltip]="col_dict[column] ? col_dict[column].description : ''"
                    [value]="column">
          {{col_dict[column].title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="columns && col_dict" class="row">
    <mat-checkbox [(ngModel)]="config.value_only" name="value_only"
                  style="width:130px;align-self:center;">Show only primary value
    </mat-checkbox>
    <mat-form-field *ngIf="columns">
      <mat-label>Value 2</mat-label>
      <mat-select [(value)]="config.secondary_val_1">
        <mat-option *ngFor="let column of columns.all"
                    [matTooltip]="col_dict[column] ? col_dict[column].description : ''"
                    [value]="column">
          {{col_dict[column].title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="columns">
      <mat-label>Value 3</mat-label>
      <mat-select [(value)]="config.secondary_val_2">
        <mat-option *ngFor="let column of columns.all"
                    [matTooltip]="col_dict[column] ? col_dict[column].description : ''"
                    [value]="column">
          {{col_dict[column].title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container *ngIf="config.chart_type==='custom-chart' && config.chart_config">
    <div class="chart-config-border">
      <h4>Custom chart configuration</h4>
      <custom-chart [config]="config.chart_config"></custom-chart>
    </div>
  </ng-container>

</div>