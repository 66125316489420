
import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {MatMenuTrigger} from "@angular/material";

@Component({
    selector: 'inline-style-menu',
    templateUrl: 'inline_style_menu.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../forms/forms.less']
})

export class InlineStyleMenuComponent implements OnInit {
    @Input() value_component: any;
    @Input() tree_position: any;
    @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;


    constructor(private api: ApiService) {
    }

    closeMenu() {
        this.api[this.value_component.type].patch(this.value_component);
        this.trigger.closeMenu();
    }

    ngOnInit(): void {
console.log(this.value_component.attributes.json[this.tree_position].name)
    }
}