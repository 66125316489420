import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'chart-process-detail',
  templateUrl: './chart-process-detail.component.html'
})
export class ChartProcessDetailComponent implements OnInit {

  @Input() process: any;
  constructor() {

  }

  ngOnInit() {
  }

}
