import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

export interface DialogData {
        flowchart:any,
        component:any
}

@Component({
    selector: 'FlowchartForm',
    templateUrl: 'edit_component_form.html',
    encapsulation: ViewEncapsulation.None
})

export class FlowchartFormComponent implements OnInit {

    flowchart:any;
    component:any;
    showHints:boolean = false;
    title: string;
    showing_hints: boolean;


    constructor(public dialogRef: MatDialogRef<FlowchartFormComponent>,
                @Inject(MAT_DIALOG_DATA)
                private dialogData: DialogData) {

    }
    ngOnInit(): void {
        this.flowchart = this.dialogData.flowchart;
        this.component = this.dialogData.component;
        console.log('FlowchartFormComponent - ngOnInit: ', this.component);
    }

    save(event) {
        this.dialogRef.close(this.component);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }


}
