import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {catchError, publishLast, refCount, takeUntil, tap, timeout} from 'rxjs/operators';
import {HandleError, HttpErrorHandler} from './http-error-handler.service';
import * as utils from "../lib/utils";
import * as _ from "lodash";
import {Router} from "@angular/router";

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
    })
};

class CacheEntry {
    response: Observable<any>;
    lastUpdated: number = -1;
    timeout: number;
}

class CacheManager {

    cache: { [key: string]: CacheEntry } = {};

    // TODO timeout per cache entry
    constructor() {
    }

    getCacheEntry(cacheName: string): Observable<any> {
        const now = performance.now();
        const entry = this.cache[cacheName];
        if (entry) {
            if (entry.lastUpdated > 0 && now - entry.lastUpdated <= entry.timeout) {
                return entry.response;
            } else {
                // TODO cleanup? cannot unsubscribe since publish last is used
                return null;
            }
        } else {
            return null;
        }
    }

    setCacheEntry(cacheName: string, response: Observable<any>, timeout: number): void {
        const now = performance.now();
        this.cache[cacheName] = {response: response, lastUpdated: now, timeout: timeout};
    }

    /**
     * Clears a specified cache
     * @param cacheName
     */
    invalidateCache(cacheName: string) {
        // TODO add relations map to recursively clear caches of relationship as well
        const entry = this.cache[cacheName];
        if (entry) {
            entry.lastUpdated = -1;
            entry.response = null;
        }
    }

    /**
     * Clears all caches
     */
    clear() {
        this.cache = {}
    }
}

export class Model {
    private readonly handleError: HandleError;
    private readonly onQueryCancel = new Subject<void>();

    /**
     *
     * @param http
     * @param httpErrorHandler
     * @param baseUrl
     * @param modelName
     * @param cacheManager
     * @param cacheName
     * @param shouldCache
     * @param cacheTimeout Time until an cache entry is invalidated (will be lazy updated). Default 12 hours.
     */
    constructor(private http: HttpClient,
                httpErrorHandler: HttpErrorHandler,
                private baseUrl: string,
                public modelName: string,
                private cacheManager: CacheManager,
                private cacheName: string,
                private shouldCache: boolean = false,
                private cacheTimeout: number = 43200000) {
        this.handleError = httpErrorHandler.createHandleError('ApiService');
    }

    cancelActiveQueries() {
        this.onQueryCancel.next();
    }

    getById(id: string): Observable<any> {
        return this.http.get<Observable<any>>(this.baseUrl + '/' + id)
            .pipe(
                takeUntil(this.onQueryCancel),
                catchError(this.handleError('get ' + this.modelName, []))
            );
    }

    /**
     * @deprecated Use getById() instead
     * @param id
     */
    get(id: string): any {
        let resource = this.http.get<any>(this.baseUrl + '/' + id)
            .pipe(
                catchError(this.handleError('get ' + this.modelName, []))
            );

        let resource_promise = resource.toPromise();
        let ngresource = {$promise: null};

        ngresource.$promise = resource_promise;

        resource_promise.then(function (data) {
            Object.keys(data.data).forEach(function (item) {
                ngresource[item] = data.data[item]
            })
        });

        return ngresource
    }

    /**
     * @deprecated use search() instead
     * @param vars
     */
    get_query(vars: {}): any {
        let resource = this.http.get<any>(this.baseUrl, {params: utils.httpParamSerializer(vars)})
            .pipe(
                catchError(this.handleError<any>('search ' + this.modelName, []))
            );

        let resource_promise = resource.toPromise();
        let ngresource = {$promise: null};

        ngresource.$promise = resource_promise;

        resource_promise.then(function (data) {
            if (data.hasOwnProperty('data')) {
                Object.keys(data.data).forEach(function (item) {
                    ngresource[item] = data.data[item]
                })

            }
        });

        return ngresource
    }

    get_related(id: string, relationship: string) {
        let resource = this.http.get<any>(this.baseUrl + '/' + id + '/' + relationship)
            .pipe(
                catchError(this.handleError('get ' + this.modelName, []))
            );

        let resource_promise = resource.toPromise();
        let ngresource = {$promise: null};

        ngresource.$promise = resource_promise;

        resource_promise.then(function (data) {
            Object.keys(data.data).forEach(function (item) {
                ngresource[item] = data.data[item]
            })

        });

        return ngresource
    }

    search(vars: {} = {}): Observable<any> {
        // TODO only cache if there was no error
        // TODO add support for caching when passing in vars
        // TODO unsubscribe from cache
        if (this.shouldCache && _.isEmpty(vars)) {
            if (this.modelName == 'process') {
                console.log('doing cache for process model');
            }
            let cachedResponse = this.cacheManager.getCacheEntry(this.cacheName);
            if (!cachedResponse) {
                // no entry was found or was outdated, make a new request
                cachedResponse = this.http.get<Observable<any>>(this.baseUrl, {params: utils.httpParamSerializer(vars)})
                    .pipe(
                        timeout(this.cacheTimeout),
                        catchError(this.handleError<any[]>('search ' + this.modelName, [])),
                        publishLast(),
                        refCount()
                    );
                this.cacheManager.setCacheEntry(this.cacheName, cachedResponse, this.cacheTimeout);
            }
            return cachedResponse;
        } else {
            return this.http.get<Observable<any>>(this.baseUrl, {params: utils.httpParamSerializer(vars)})
                .pipe(
                    takeUntil(this.onQueryCancel),
                    catchError(this.handleError<any[]>('search ' + this.modelName, []))
                )
        }
    }

    /**
     * @deprecated Use the search() method instead and use the response.data
     * @param vars
     */
    query(vars?: {}): any {

        // Add safe, URL encoded search parameter if there is a search term
        if (vars == null) {
            vars = {};
        }
        let resource = this.http.get<any[]>(this.baseUrl, {params: utils.httpParamSerializer(vars)});
        // .pipe(
        //     catchError(this.handleError<any[]>('search ' + this.modelName, []))
        // );

        let resource_promise = resource.toPromise();
        let ngresource: any = [];

        ngresource.$promise = resource_promise;

        resource_promise.then(function (response) {
            // @ts-ignore
            response.data.forEach(function (item) {
                ngresource.push(item)
            })
        }, response => {
            console.error('Failed to fetch query', response);
        });

        return ngresource
    }

    /**
     * @deprecated Use the search() method instead and do the mapping in the response
     * @param vars
     */
    search_dict(vars?: {}) {
        return this.search().toPromise().then(list => {
            let dict = {};
            list.data.forEach(item => {
                dict[item.id] = item
            });
            return dict;
        });
    }

    getRelatedMany(id: string, relationship: string): Observable<any> {
        return this.http.get<Observable<any[]>>(this.baseUrl + '/' + id + '/' + relationship)
            .pipe(catchError(this.handleError('get ' + this.modelName, [])));
    }

    /**
     * @deprecated Use getRelatedMany instead
     * @param id
     * @param relationship
     */
    get_related_many(id: string, relationship: string) {
        let resource = this.http.get<any[]>(this.baseUrl + '/' + id + '/' + relationship)
            .pipe(
                catchError(this.handleError('get ' + this.modelName, []))
            );

        let resource_promise = resource.toPromise();
        let ngresource = [];

        // @ts-ignore
        ngresource.$promise = resource_promise;

        resource_promise.then(function (data) {
            // @ts-ignore
            data.data.forEach(function (item) {
                ngresource.push(item)
            })
        });

        return ngresource
    }

    //////// Save methods //////////

    // TODO remmove the toPromise from these methods
    save(item: any) {
        delete item.id;
        return this.http.post<any>(this.baseUrl, {data: item}, httpOptions)
            .pipe(catchError(this.handleError('add ' + this.modelName)),
                tap(() => this.cacheManager.invalidateCache(this.cacheName)))
            .toPromise();
    }

    delete(id: string): Promise<any> {
        const url = `${this.baseUrl}/${id}`; // DELETE api/heroes/42
        return this.http.delete(url, httpOptions)
            .pipe(catchError(this.handleError('delete ' + this.modelName)),
                tap(() => this.cacheManager.invalidateCache(this.cacheName)))
            .toPromise();
    }

    patch(item: any): Promise<any> {
        return this.http.patch<any>(this.baseUrl + '/' + item.id, {data: item}, httpOptions)
            .pipe(
                catchError(this.handleError('update ' + this.modelName)),
                tap(() => this.cacheManager.invalidateCache(this.cacheName))
            ).toPromise();
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApiService implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    account: Model;
    activity: Model;
    alerts: Model;
    audited_raw_data: Model;
    calculation: Model;
    collector: Model;
    collector_event: Model;
    comment: Model;
    component: Model;
    component_type: Model;
    configuration: Model;
    connector: Model;
    constant_component: Model;
    constant_property: Model;
    correction_factor: Model;
    downtime: Model;
    engineering_unit: Model;
    equipment: Model;
    estimate: Model;
    estimate_type: Model;
    event: Model;
    event_type: Model;
    feature: Model;
    folder: Model;
    forecast_calculation: Model;
    mapper: Model;
    process: Model;
    process_light: Model;
    process_access: Model;
    property_category: Model;
    raw_data: Model;
    resource: Model;
    revision: Model;
    role: Model;
    schedule: Model;
    series: Model;
    series_component: Model;
    series_light: Model;
    series_property: Model;
    series_type: Model;
    session_state: Model;
    session_state_light: Model;
    shift: Model;
    stockpile: Model;
    stream: Model;
    transport: Model;
    users: Model;

    mapApis: { name: string, url: string, cache: boolean, maintain_list: boolean, cache_name?: string, mapper_name?: string, cache_timeout?: number, audited?: boolean }[] = [
        {name: 'account', url: '/api/account', cache: true, cache_name: 'account', maintain_list: false},
        {name: 'activity', url: '/api/activity', cache: false, cache_name: 'activity', maintain_list: false},
        {name: 'alerts', url: '/api/alerts', cache: false, cache_name: 'alerts', maintain_list: true},
        {
            name: 'audited_raw_data',
            url: '/api/audited_raw_data',
            cache: false,
            cache_name: 'raw_data',
            maintain_list: false
        },
        {
            name: 'calculation',
            url: '/api/calculation',
            cache: true,
            cache_name: 'series',
            maintain_list: false,
            audited: true
        },
        {
            name: 'collector',
            url: '/api/collector',
            cache: false,
            cache_name: 'collector',
            maintain_list: false,
            audited: true
        },
        {
            name: 'collector_event',
            url: '/api/collector_event',
            cache: false,
            cache_name: 'collector_event',
            maintain_list: false
        },
        {name: 'comment', url: '/api/comment', cache: true, cache_name: 'comment', maintain_list: false},
        {name: 'component', url: '/api/component', cache: true, cache_name: 'component', maintain_list: false},
        {
            name: 'component_type',
            url: '/api/component_type',
            cache: true,
            cache_name: 'component_type',
            maintain_list: true,
            audited: true
        },
        {
            name: 'configuration',
            url: '/api/configuration',
            cache: true,
            cache_name: 'configuration',
            maintain_list: true
        },
        {name: 'connector', url: '/api/connector', cache: true, cache_name: 'connector', maintain_list: false},
        {
            name: 'constant_component',
            url: '/api/constant_component',
            cache: false,
            cache_name: 'constant_component',
            maintain_list: false,
            audited: true
        },
        {
            name: 'constant_property',
            url: '/api/constant_property',
            cache: true,
            cache_name: 'constant_property',
            maintain_list: true,
            audited: true
        },
        {
            name: 'correction_factor',
            url: '/api/correction_factor',
            cache: false,
            cache_name: 'correction_factor',
            maintain_list: false
        },
        {name: 'downtime', url: '/api/downtime', cache: true, cache_name: 'downtime', maintain_list: false},
        {
            name: 'engineering_unit',
            url: '/api/engineering_unit',
            cache: true,
            cache_name: 'engineering_unit',
            maintain_list: true
        },
        {
            name: 'equipment',
            url: '/api/equipment',
            cache: false,
            cache_name: 'equipment',
            maintain_list: false,
            audited: true
        },
        {
            name: 'estimate',
            url: '/api/estimate',
            cache: false,
            cache_name: 'estimate',
            maintain_list: false,
            audited: true
        },
        {
            name: 'estimate_type',
            url: '/api/estimate_type',
            cache: true,
            cache_name: 'estimate_type',
            maintain_list: true
        },
        {name: 'event', url: '/api/event', cache: true, cache_name: 'event', maintain_list: false},
        {
            name: 'event_type',
            url: '/api/event_type',
            cache: true,
            mapper_name: 'name',
            cache_name: 'event_type',
            maintain_list: true
        },
        {name: 'feature', url: '/api/feature', cache: true, cache_name: 'feature', maintain_list: true},
        {
            name: 'folder',
            url: '/api/folder',
            cache: true,
            cache_name: 'folder',
            maintain_list: true,
        },
        {
            name: 'forecast_calculation',
            url: '/api/forecast_calculation',
            cache: false,
            cache_name: 'forecast_calculation',
            maintain_list: false
        },
        {name: 'mapper', url: '/api/mapper', cache: false, cache_name: 'mapper', maintain_list: false, audited: true},
        {
            name: 'process',
            url: '/api/process',
            cache: true,
            cache_name: 'process',
            maintain_list: false,
            audited: true
        },
        {
            name: 'process_light',
            url: '/api_min/process_light',
            cache: true,
            cache_name: 'process_light',
            maintain_list: false,
            audited: true
        },
        {
            name: 'process_access',
            url: '/api/process_access',
            cache: false,
            cache_name: 'process_access',
            maintain_list: false,
            audited: true
        },
        {
            name: 'property_category',
            url: '/api/property_category',
            cache: true,
            cache_name: 'property_category',
            maintain_list: true,
            audited: true
        },
        {name: 'raw_data', url: '/api/raw_data', cache: false, cache_name: 'raw_data', maintain_list: false},
        {
            name: 'resource',
            url: '/api/resource',
            cache: true,
            cache_name: 'component',
            maintain_list: false,
            audited: true
        },
        {
            name: 'revision',
            url: '/api/revision',
            cache: true,
            cache_name: 'revision',
            maintain_list: true,
            audited: true
        },
        {name: 'role', url: '/api/role', cache: true, mapper_name: 'name', cache_name: 'role', maintain_list: true},
        {name: 'schedule', url: 'api/schedule', cache: true, cache_name: 'schedule', maintain_list: false},
        {name: 'series', url: '/api/series', cache: false, cache_name: 'series', maintain_list: false, audited: true},
        {
            name: 'series_component',
            url: '/api/series_component',
            cache: false,
            cache_name: 'series_component',
            maintain_list: false,
            audited: true
        },
        {
            name: 'series_light',
            url: '/api_min/series_light',
            cache: true,
            cache_name: 'series_light',
            maintain_list: true
        },
        {
            name: 'series_property',
            url: '/api/series_property',
            cache: true,
            cache_name: 'series_property',
            maintain_list: true,
            audited: true
        },
        {name: 'series_type', url: '/api/series_type', cache: true, cache_name: 'series_type', maintain_list: true},
        {
            name: 'session_state',
            url: '/api/session_state',
            cache: true,
            mapper_name: 'report',
            cache_name: 'session_state',
            maintain_list: false,
            audited: true
        },
        {
            name: 'session_state_light',
            url: '/api_min/session_state_light',
            cache: true,
            cache_name: 'session_state_light',
            maintain_list: true
        },
        {name: 'shift', url: '/api/shift', cache: true, cache_name: 'shift', maintain_list: false},
        {
            name: 'stockpile',
            url: '/api/stockpile',
            cache: false,
            cache_name: 'stockpile',
            maintain_list: false,
            audited: true
        },
        {
            name: 'stream',
            url: '/api/stream',
            cache: false,
            cache_name: 'component',
            maintain_list: false,
            audited: true
        },
        {name: 'transport', url: '/api/transport', cache: false, cache_name: 'transport', maintain_list: false},
        {name: 'users', url: '/api/users', cache: true, cache_name: 'users', maintain_list: true, audited: true},
    ];

    /**
     * Prepares a search queries for the REST API
     *
     * https://github.com/jfinkels/flask-restless/tree/master/docs
     * JSON spec defined at: https://jsonapi.org/format
     * */
    prep_q(filters?, extra?, sort?: string | null) {
        if (extra == null) {
            extra = {}
        }

        if (filters != null) {
            extra['filter[objects]'] = JSON.stringify(filters);
        }
        if (sort != null) {
            extra['sort'] = sort;
        }
        return extra
    };

    private onCancelActiveQueries = new Subject<void>();

    constructor(private http: HttpClient,
                private router: Router,
                httpErrorHandler: HttpErrorHandler) {
        let cls = this;
        const cacheManager = new CacheManager();
        this.mapApis.forEach(mapapi => {
            const model = new Model(http, httpErrorHandler, mapapi.url, mapapi.name, cacheManager, mapapi.cache_name, mapapi.cache,
                mapapi.cache_timeout);
            cls[mapapi.name] = model;
            this.onCancelActiveQueries.pipe(takeUntil(this.onDestroy)).subscribe(() => {
                model.cancelActiveQueries();
            })
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
        this.onCancelActiveQueries.unsubscribe();
    }

    cancelActiveQueries() {
        this.onCancelActiveQueries.next();
    }

    /**
     * Wrapper for the HttpClient get. This is used so requests can be cancelled from a single place on page navigation.
     * */
    get(url, options?): Observable<any> {
        if (options) {
            return this.http.get(url, options).pipe(takeUntil(this.onCancelActiveQueries))
        }
        return this.http.get(url).pipe(takeUntil(this.onCancelActiveQueries))
    }
}
