<div class="form-dialog">
  <h1>Add Component {{title| upperfirst}}
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true" (selectedTabChange)="tabChanged($event)">

      <mat-tab label="Friendly">
        <form class="form-section column space-between">
          <div class="row">
            <mat-form-field (mouseenter)="hint='Tile_Type'">
              <mat-label>Type</mat-label>
              <mat-select (selectionChange)="contentChange();updateJson($event);" [(value)]="json.content"
                          name="tile_content"
                          [disabled]="tile.id">
                <mat-option *ngFor="let content of tileData.content_types" [value]="content.value">
                  {{content.name| upperfirst}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field (mouseenter)="hint='Tile_Title'">
              <mat-label>Title</mat-label>
              <input [(ngModel)]="json.title"
                     matInput
                     name="tile_title"/>
            </mat-form-field>

            <div class="tip-box">
              <div class="tip" [ngClass]="{'showing': hint=='Tile_Type'}">
                <label>Type</label>
                <div>Identifies this section according to the order it appears on the page.</div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='Tile_Title'}">
                <label>Title</label>
                <div>This is the main header for the section.
                  Leave this blank if you do not want to see a header.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <mat-form-field (mouseenter)="hint='Tile_Category'">
              <mat-label>Category</mat-label>
              <mat-select [(ngModel)]="json.tile_category"
                          [compareWith]="matSelectCompare"
                          name="category">
                <mat-option *ngFor="let category of tile_categories" [value]="category">
                  {{category.name| upperfirst}}
                </mat-option>
                <mat-option value="">Default</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field (mouseenter)="hint='Tile_Class'">
              <mat-label>Tile Width</mat-label>
              <mat-select [(ngModel)]="json.class" (ngModelChange)="updateJson($event)" name="tile_class">
                <mat-option *ngFor="let tile_class of tileData.tile_classes"
                            [value]="tile_class">{{tile_class | titlecase}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-checkbox [(ngModel)]="json.new_line" class="self-center" name="new_line">New line on print
            </mat-checkbox>
            <div class="tip-box">
              <div class="tip" [ngClass]="{'showing': hint=='Tile_Category'}">
                <label>Tile Category</label>
                <div>
                </div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='Tile_Class'}">
                <label>Tile Width</label>
                <div>The width of the tile in relation to the page.
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="json.content==='default-chart'||json.content==='budget-bar-chart'">
            <div class="select-search" style="flex-grow:12" (mouseenter)="hint='Series'">
              <select-search *ngIf="seriesData.series_list.length>0"
                             [label]="'Series'"
                             [(value)]="json.parameters.series"
                             [data]="seriesData.series_list"
                             [fill_stubs]="true"
                             [classes]="'fill'"
              ></select-search>
            </div>

            <mat-checkbox class="self-center" (mouseenter)="hint='Hide_Comments'"
                          style="min-width:100px;text-align:right;"
                          [(ngModel)]="json.parameters.hide_comments" name="hide_comments">Hide comments
            </mat-checkbox>

            <div class="tip-box">
              <div class="tip" [ngClass]="{'showing': hint=='Series'}">
                <label>Series</label>
                <div>
                  The series for which you want to view this chart. The type of chart you
                  see will depend on your selected value for the 'default_chart' property
                  for the series.
                </div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='Hide_Comments'}">
                <label>Hide Comments</label>
                <div>
                  Chose to hide the comment lines shown on the chart.
                </div>
              </div>
            </div>
          </div>

          <!-------Flowchart---------------------------------------------------------------------------------------------->
          <ng-container *ngIf="json.content==='flowchart'">
            <div class="row">
              <div class="select-search" style="flex-grow:2" (mouseenter)="hint='Process'">
                <select-search *ngIf="processes"
                               [label]="'Process'"
                               [(value)]="json.parameters.process"
                               [data]="processes"
                               [fill_stubs]="true"
                               [classes]="'fill'"
                ></select-search>
              </div>

              <div class="tip-box">
                <div class="tip" [ngClass]="{'showing': hint=='Process'}">
                  <label>Process</label>
                  <div>
                    The series for which you want to view this chart. The type of chart you
                    see will depend on your selected value for the 'default_chart' property
                    for the series.
                  </div>
                </div>
                <div class="tip" [ngClass]="{'showing': hint=='Hide_Comments'}">
                  <label>Hide Comments</label>
                  <div>
                    Chose to hide the comment lines shown on the chart.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <mat-form-field>
                <mat-label>Scale (%)</mat-label>
                <input matInput type="number" step="1" min="10" max="500" [(ngModel)]="json.parameters.scale"
                       name="scale">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Left Offset (px)</mat-label>
                <input matInput type="number" min="0" max="1000" [(ngModel)]="json.parameters.left_offset"
                       name="left_offset">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Top Offset (px)</mat-label>
                <input matInput type="number" min="0" max="1000" [(ngModel)]="json.parameters.top_offset"
                       name="top_offset">
              </mat-form-field>
              <div class="tip-box">
                <div class="tip" [ngClass]="{'showing': hint=='Scale'}">
                  <label>Scale</label>
                  <div>
                    Adjust the zoom level for the view of the flowchart. This works together with the rowspan and
                    colspan to determine the size and proportion of the flowchart that will be seen on the dashboard.
                    (A scale of 100 will show the full flowchart in the dashboard tile created.)
                  </div>
                </div>
                <div class="tip" [ngClass]="{'showing': hint=='LeftOffset'}">
                  <label>Left Offset</label>
                  <div>
                    Adjust the left hand starting point when viewing a zoomed in proportion of a flowchart.
                  </div>
                </div>
                <div class="tip" [ngClass]="{'showing': hint=='TopOffset'}">
                  <label>Top Offset</label>
                  <div>
                    Adjust the top starting point when viewing a zoomed in proportion of a flowchart.
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <!----------Paragraph---------------------------------------------------------------------------------------------->
          <div class="row" *ngIf="json.content==='paragraph'">

            <mat-checkbox [(ngModel)]="json.show_header" (mouseenter)="hint='Show_Header'"
                          name="show_header">Show header bar
            </mat-checkbox>

            <div class="tip-box">
              <div class="tip" [ngClass]="{'showing': hint=='Show_Header'}">
                <label>Show Header</label>
                <div>
                  Tile headers are hidden by default for paragraph tiles. Check this box if you'd like
                  to show the header and bounding box.
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="json.content==='custom'" class="row">
            <mat-checkbox (mouseenter)="hint='Show_Header'" [(ngModel)]="json.show_header"
                          name="show_header">Show header bar
            </mat-checkbox>

            <div class="tip-box">
              <div [ngClass]="{'showing': hint=='Show_Header'}" class="tip">
                <label>Show Header</label>
                <div>
                  Tile headers are hidden by default for paragraph tiles. Check this box if you'd like
                  to show the header and bounding box.
                </div>
              </div>
            </div>
          </div>

          <!----------Context Component---------------------------------------------------------------------------------------------->
          <div class="column" *ngIf="json.content==='context' && json.parameters">
            <div class="row">
              <mat-checkbox [(ngModel)]="json.show_header" (mouseenter)="hint='Show_Header'"
                            name="show_header">Show header bar
              </mat-checkbox>

              <div class="tip-box">
                <div class="tip" [ngClass]="{'showing': hint=='Show_Header'}">
                  <label>Show Header</label>
                  <div>
                    Tile headers are hidden by default for paragraph tiles. Check this box if you'd like
                    to show the header and bounding box.
                  </div>
                </div>
              </div>
            </div>
            <context-form [config]="json.parameters"></context-form>
          </div>

          <!----------Series Table---------------------------------------------------------------------------------------------->
          <div class="column" *ngIf="json.content==='series-table' && json.parameters">
            <series-table-form [config]="json.parameters" [processes]="processes"></series-table-form>
          </div>

          <!----------Log Sheet---------------------------------------------------------------------------------------------->
          <div class="column" *ngIf="json.content==='log-sheet' && json.parameters && processes">
            <log-sheet-form [config]="json.parameters" [processes]="processes"></log-sheet-form>
          </div>

          <!----------Input Data Sheet---------------------------------------------------------------------------------------------->
          <div *ngIf="json.content==='input-sheet' && json.parameters && processes">
            <input-data-sheet-form [config]="json.parameters" [processes]="processes"></input-data-sheet-form>
          </div>

          <!--Custom Chart----------------------------------------------------------------------------------------------------->

          <div class="row" *ngIf="json.content==='custom-chart'">
            <ng-container *ngIf="json.parameters">
              <custom-chart [config]="json.parameters"></custom-chart>
            </ng-container>
          </div>

          <!--Comparison Chart----------------------------------------------------------------------------------------------------->

          <div class="row" *ngIf="json.content==='comparison-chart'">
            <ng-container *ngIf="json.parameters">
              <comparison-chart-form style="width:100%" [config]="json.parameters"></comparison-chart-form>
            </ng-container>
          </div>

          <!--Custom events----------------------------------------------------------------------------------------------------->
          <div *ngIf="json.content==='custom-events' || json.content==='stockpile-events'">
            <ng-container *ngIf="event_types && event_types.length>0">
              <custom-events-form [config]="json.parameters" [event_types]="event_types" [content_type]="json.content">

              </custom-events-form>
            </ng-container>
          </div>
          <!--Category chart------------------------------------------------------------------------------------------------------>
          <div *ngIf="json.content==='category-chart'">
            <ng-container *ngIf="true">
              <category-chart-form [config]="json.parameters"></category-chart-form>
            </ng-container>
          </div>
          <!--Pivot table tile----------------------------------------------------------------------------------------------------->
          <div *ngIf="json.content==='pivot-tile'">
            <div class="row">
              <mat-checkbox (mouseenter)="hint='Show_Header'" [(ngModel)]="json.show_header"
                            name="show_header">Show header bar
              </mat-checkbox>

              <div class="tip-box">
                <div [ngClass]="{'showing': hint=='Show_Header'}" class="tip">
                  <label>Show Header</label>
                  <div>
                    Tile headers are hidden by default for paragraph tiles. Check this box if you'd like
                    to show the header and bounding box.
                  </div>
                </div>
              </div>
            </div>
            <ng-container>
              <pivot-tile-form [config]="json.parameters" [event_types]="event_types">

              </pivot-tile-form>
            </ng-container>
          </div>

          <!-- TODO move to the custom chart form component -->
          <mat-checkbox (mouseenter)="hint='custom_dtp'" [(ngModel)]="json.custom_dtp"
                        name="custom_dtp" style="margin-bottom:20px;">Custom date period
          </mat-checkbox>
          <date-period [disabled]="!json.custom_dtp && json.content!=='custom-chart'" [immutable]="true" [no_url]="true"
                       class="date-period inline align-bottom"></date-period>
        </form>
      </mat-tab>

      <mat-tab label="Advanced">
        <form class="form-section row ">
          <div class="row stretch">
            <textarea (ngModelChange)="updateJson($event)" *ngIf="json" [ngModel]="json | json"
                      class="full"
                      name="jsontext" style="width:500px;height:300px;">
            </textarea>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>

  </mat-dialog-content>
  <div class="action-buttons">
    <button class="btn-close" (click)="onCloseClick()">Close</button>
    <!--<button class="btn btn-success" (click)="consoleLog(user)">Console log changes</button>-->
    <button class="btn-save" (click)="save()">Save</button>
  </div>
</div>

