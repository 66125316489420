import {ApiService} from "../services/api.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {Series, SeriesDataService} from "../services/series_data.service";
import * as utils from "../lib/utils"
import {FlatPromise} from "../lib/utils"
import {HttpClient} from "@angular/common/http";
import {MatSnackBar, MatTabChangeEvent} from "@angular/material";
import {DateTimePeriod, DateTimePeriodService} from "../services/datetime_period.service";
import {HeaderDataService} from "../services/header_data.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

export interface SeriesDialogData {
    series: Series;
    component: any;
    seriesData: SeriesDataService;
}

@Component({
    selector: 'series-form',
    templateUrl: 'series_form.component.html',
    providers: [DateTimePeriodService]
})
export class SeriesFormComponent implements OnInit, OnDestroy {

    series: Series;
    component: any;
    seriesData: SeriesDataService;
    showing_hints: boolean = false;
    hint: string = 'Name';
    disabled: boolean = false;
    checkCalc: string = "none";
    checkCalcMessage: string = "";
    formula_series: any;
    current_tab: number = 0;
    dtp: DateTimePeriod;
    permissions: any = {};
    estimates_data: any;
    fill_methods = [];
    loading: boolean = true;
    applying: boolean = false;
    series_components: any[];
    processes: any[];
    applied_series: Series;
    private readonly onDestroy = new Subject<void>();

    constructor(public api: ApiService,
                public dialogRef: MatDialogRef<SeriesFormComponent>,
                public http: HttpClient,
                @Inject(MAT_DIALOG_DATA) public data: SeriesDialogData,
                private snackbar: MatSnackBar,
                private dateTimePeriodService: DateTimePeriodService,
                private headerData: HeaderDataService) {
    }

    ngOnInit(): void {

        setTimeout(() => {
            const ctrl = this;
            this.series = ctrl.data.series;
            this.component = ctrl.data.component;
            this.seriesData = ctrl.data.seriesData;
            ctrl.fill_methods = Object.keys(this.seriesData.fill_methods);

            this.loading = false;

            //TODO what is the correct way to get this from the parent?
            this.dateTimePeriodService.dtp_complete.promise.then(() => {
                this.dtp = utils.deepCopy(this.dateTimePeriodService.dtp);
            });

            if (this.series.id) {
                ctrl.seriesData.getSeriesPermissions([this.series.id]).then(permissions => {
                    ctrl.permissions = permissions;
                });

                const $series_components = ctrl.api.series_component.search(
                    ctrl.api.prep_q([{
                        op: 'in', name: 'series_id', val:
                            [ctrl.series.id]
                    }], {})).toPromise().then(result => {
                    ctrl.series_components = result.data;
                    if (ctrl.series_components) {
                        ctrl.api.process.search(ctrl.api.prep_q([
                            {
                                name: 'series_components', op: 'any', val: {
                                    name: 'id', op: 'in',
                                    val: ctrl.series_components.map(sc => sc.id)
                                }
                            }])).toPromise()
                            .then(components => {
                                utils.fill_relations(ctrl.series_components, components.data, 'component')
                            });
                    }
                });

                const $estimates = ctrl.seriesData.getEstimates(this.series.id).promise.then((result) => {
                    console.log('SeriesFormComponent - estimates: ', result);
                    ctrl.estimates_data = result;
                }).catch((errors) => {
                    console.log('SeriesFormComponent - errors: ', errors);
                });
            }
        });
        this.dateTimePeriodService.dtpChanged.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dtp = dtp;
        });

    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
        //    here is some changes
    }

    updateEstimates() {
        const ctrl = this;
        this.seriesData.estimateForm('Forecast', [this.series])
            .afterClosed()
            .toPromise().then(response => {
            if (response) {
                let estimate = response.data;

                ctrl.seriesData.estimate_types_list.map(function (type) {
                    if (estimate.relationships.estimate_type.data.id == type.id) {
                        estimate.attributes.estimate_type_name = type.attributes.name;
                    }

                });
                ctrl.estimates_data.push(response.data);
            }
        })
    }

    saveSeriesComponent(sc) {
        delete sc['result'];
        this.api.series_component.patch(sc).then(result => {
            sc['result'] = 'updated';
        }, result => {
            sc['result'] = 'error updating'
        })
    }

    save() {
        const p = this.apply();
        p.promise.then(result => {
            console.log('SeriesFormComponent - result: ', result);
            this.dialogRef.close(result);
        }, reason => {
            if (reason && reason.error && reason.error.errors && reason.error.errors.length > 0 && reason.error.errors[0].detail) {
                this.snackbar.open(reason.error.errors[0].detail, 'Hide');
            } else {
                this.snackbar.open('Failed to save the series.', 'Hide');
            }

            console.log('Reason for failing to save:', reason)
        })
    }

    saveOrApply(function_name) {
        const ctrl = this;

        if (['Default value'].includes(ctrl.series.attributes.fill_method)) {
            if (ctrl.series.attributes.default_value === undefined || null) {
                ctrl.snackbar.open('Please add a default value to this series fill method before saving', 'Hide');
                return;
            }
        }
        if (['Rolling average'].includes(ctrl.series.attributes.fill_method)) {
            if (ctrl.series.attributes.rolling_average_hours === undefined || null) {
                ctrl.snackbar.open('Please add a rolling average window period in hours to this series fill method before saving', 'Hide');
                return;
            }
        }

        if (this.series.type === 'calculation') {
            let $promise = ctrl.calcValidator();
            $promise.then(result => {
                eval('ctrl.' + function_name + "()");
            }, reject => {
                console.log('Incorrect formula: ', reject);
                ctrl.snackbar.open(' Please correct your formula before saving.', 'Hide');
                return;
            })
        } else {
            eval('ctrl.' + function_name + "()");
        }

    }

    apply() {
        const p = new FlatPromise();
        this.applying = true;

        this.dialogRef.disableClose = this.applying;
        if (!this.series.id) {
            // FIXME dialog closes even when the series was not successfully added (say with response 400)
            this.api[this.series.type].save(this.series).then((result) => {
                // result seems to have a data field if the response was successful
                if (result.data) {
                    this.series = result.data;
                    if (this.component) {
                        this.api.series_component.save({
                            relationships: {
                                component: {
                                    data: {
                                        id: this.component.id,
                                        type: 'component'
                                    }
                                }, series: {data: {id: result.data.id, type: 'series'}}
                            }, type: 'series_component'
                        }).then((response) => {
                            p.resolve({series_component: response.data, series: result.data});
                        }, reason => {
                            p.reject(reason);
                        }).finally(() => this.applying = false)
                    } else {
                        this.snackbar.open('The series is not associated with a process - please assign to process', 'Hide')
                        p.resolve({series_component: null, series: result.data});
                        this.applying = false;
                    }
                } else {
                    p.resolve();
                    this.applying = false;
                }
            }, reason => {
                // this will likely never be called
                p.reject(reason);
                console.log('Failed to save', reason)

            }).finally(() => this.applying = false)
        } else {
            this.api[this.series.type].patch(this.series).then((result) => {
                console.log('series patched succesfully', result);
                p.resolve({series: this.series});
            }, reason => {
                console.log('Failed to save', reason);
                p.reject(reason);
            }).finally(() => this.applying = false)
        }
        p.promise.finally(() => {
            this.applied_series = utils.deepCopy(this.series)
            this.applying = false;
            this.dialogRef.disableClose = this.applying
        });
        return p;
    }

    addToFormula(formula_series) {
        this.checkCalcMessage = "";
        this.checkCalc = "";
        if (formula_series) {
            if (this.series.attributes.name_formula == null) {
                this.series.attributes.name_formula = "[" + formula_series.attributes.name + "]";
            } else {
                this.series.attributes.name_formula += " [" + formula_series.attributes.name + "]";
            }
        }
    };

    calcValidator = function (): Promise<any> {
        const ctrl = this;
        const $promise = new FlatPromise();
        let value = utils.deepCopy(ctrl.series.attributes.name_formula);
        if (value == undefined) {
            ctrl.snackbar.open('Please add a formula to the series before saving', 'Hide');
            return $promise.promise
        }
        //DO NOT USE THE HTTP PARAMSERIALISER ON THIS! IT FAILS FOR THE CASE OF [name]+[name] WITH THE PLUS!
        let get_url = '/api/CheckCalc?name_formula=' + encodeURIComponent(ctrl.series.attributes.name_formula);
        if (ctrl.series.id) {
            get_url = get_url + '&series_id=' + ctrl.series.id;
        }
        if (ctrl.series.relationships.engineering_unit.data && ctrl.series.relationships.engineering_unit.data.id) {
            get_url = get_url + '&engineering_unit_id=' + ctrl.series.relationships.engineering_unit.data.id;
        }

        ctrl.http.get(get_url).toPromise().then(function (response) {
                ctrl.checkCalcMessage = response.msg;
                if (response.msg.indexOf("WARNING") > -1) {
                    ctrl.checkCalc = "warning";
                } else {
                    ctrl.checkCalc = "ok";
                }
                $promise.resolve(response);
            }, function (reject) {
                ctrl.checkCalcMessage = reject.error.msg;
                ctrl.checkCalc = "error";
                $promise.reject(reject);
            }
        );
        return $promise.promise;

    };

    overrideCalculations() {
        const ctrl = this;
        ctrl.getCalculations();
    }

    getCalculations() {
        const ctrl = this;
        ctrl.headerData.getCalculations(ctrl.dtp, [this.series.id], 'hour', 1).then(data => {
            ctrl.snackbar.open('Calculations successfully updated', null, {duration: 2000})
        }).catch(reason => ctrl.snackbar.open('Failed to update calculations ' + reason, 'Hide')
        );
    };

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.id === value.id;
        }
    };

    onTabClick(event: MatTabChangeEvent) {
        this.current_tab = event.index;
    }

    onCloseClick(): void {
        this.dialogRef.close(this.applied_series);
    }
}
