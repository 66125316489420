<div *ngIf="accounts" class="account-select">
  <mat-select #select_handle (selectionChange)="changeAccount($event.value)"
              [(value)]="selected_account_id" [placeholder]="placeholder"
              disabled="false">
    <mat-option *ngFor="let account of accounts"
                [disabled]="account.view_only"
                [matTooltipDisabled]="!account.view_only"
                [style.color]="'lightgray' ? account.view_only : ''"
                [value]="account.id"
                matTooltip="You don't have access to this account">
      <span class="account-picker-row">
        <div>{{account.attributes.name}}</div>
        <div *ngIf="select_handle.panelOpen && selected_account_id == account.id"
             class="active-account-indicator">
          Active
        </div>
      </span>
    </mat-option>
  </mat-select>
</div>