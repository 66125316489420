import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EditFlowchartComponent} from "../edit_component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import * as utils from "../../lib/utils";

@Component({
    selector: 'flowchart-image-form',
    templateUrl: './flowchart-image-form.component.html'
})
export class FlowchartImageFormComponent implements OnInit {

    @Input() flowchart: any;
    @Input() component: any;
    @Input() show_hints: boolean;
    @Input() dialog: boolean;

    @Output() save_event = new EventEmitter();
    @Output() close_event = new EventEmitter();

    @ViewChild('imageUpload', {static: true}) fileUpload: ElementRef<HTMLInputElement>;
    @ViewChild('selectedImage', {static: false}) selectedImage: ElementRef;

    hint: string;
    showing_hints: any;
    component_data: any;
    images: any[];
    ratio: number = 1;

    constructor(private snackBar: MatSnackBar,
                private http: HttpClient) {
        const ctrl = this;
        this.http.get("/api/get_icon_list").toPromise().then(function (images: any) {
            ctrl.images = images;
        });
    }

    ngOnInit() {
        this.component_data = this.component.data;
        if (!this.component_data.width) {
            this.component_data.width = 200;
        }
    }


    change(obj) {
        console.log(obj)
    }

    // processFile(imageInput: any) {
    //    const file: File = imageInput.files[0];
    //    const reader = new FileReader();
    //
    //    reader.addEventListener('load', (event: any) => {
    //
    //      this.selectedFile = new ImageSnippet(event.target.result, file);
    //
    //      this.imageService.uploadImage(this.selectedFile.file).subscribe(
    //        (res) => {
    //
    //        },
    //        (err) => {
    //
    //        })
    //    });
    //
    //    reader.readAsDataURL(file);
    //  }

    processFile() {
        const files = this.fileUpload.nativeElement.files;
        if (!files || files.length < 1) {
            return;
        }
        const file = files[0];

        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        this.snackBar.open('Uploading image');

        this.http.post('/api/BlobData', formData).subscribe((response: { file_name: string }) => {
            console.log(response)
            this.snackBar.open('Image uploaded successfully.', undefined, {duration: 1500});
            this.component_data.src = '/api/s3_files' + response.file_name
            this.component_data.src = '/admin/myfiles/download' + response.file_name
        }, error => {
            console.error('Error uploading collector data', error);
            this.snackBar.open('An error occurred while collecting the file.', "Hide");
        });
    };

    getNatural(direction) {
        let img = this.selectedImage.nativeElement;
        this.ratio = img.naturalWidth / img.naturalHeight;
        if (!this.component_data.constrain_proportions) {
            return;
        }
        if (direction === 'width') {
            this.component_data.height = img.width * this.ratio;
        } else {
            this.component_data.width = img.height * this.ratio;
        }
    }

    save() {
        console.log('- save: ', utils.deepCopy(this.component_data));
        if (this.component_data.constrain_proportions) {
            this.getNatural('width')
            console.log('FlowchartImageFormComponent - save: ', this.component_data.height);
        }
        this.save_event.emit(this.component_data);
    }

    close(): void {
        this.close_event.emit();
    }
}
