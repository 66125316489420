import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import * as utils from '../../lib/utils';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {ApiService} from "../../services/api.service";
import {AppScope} from "../../services/app_scope.service";
import {HeaderDataService} from "../../services/header_data.service";
import * as  Fuse from "fuse.js";

@Component({
    selector: 'page-list',
    templateUrl: './page-list.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PageListComponent implements OnInit, OnChanges {

    sort_direction: boolean = true;
    search_field: string[] = ['attributes.name'];

    fuse: any;
    search_options: any = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 3,
        keys: [
            "attributes.name",
            "attributes.description",
        ]
    };

    @Input() list: any[];
    @Input() user_dict: any;
    @Input() header: string;
    component_ready: boolean = false;

    filter: string = "";
    filtered_list: any[] = [];

    user: any;

    constructor(private api: ApiService, private appScope: AppScope, private headerData: HeaderDataService) {
    }

    ngOnInit() {
        this.user = this.appScope.current_user;

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.list && this.list.length > 0) {
            this.filtered_list = utils.deepCopy(this.list);
            this.fuse = new Fuse(this.filtered_list, this.search_options);
            this.component_ready = true;
        }
    }

    allowUser(roles, allow_if_owner, allow_if_restricted, page, role_includes, owner_includes) {
        if (!allow_if_restricted && this.user.restricted_access) {
            return false;
        }
        if (this.user.is_super || this.user.role_name.includes('Super_User')
            || this.user.role_name.includes('Administrator')) {
            return true;
        }
        if (allow_if_owner && page.relationships.user.data.id === this.user.id
            && (owner_includes.includes(page.attributes.visibility) || owner_includes.length === 0)) {
            return true;
        }
        roles.forEach(role => {
            if ((this.user.role_name.includes(role) || roles.length === 0)
                && (role_includes.includes(page.attributes.visibility) || role_includes.length === 0)) {
                return true;
            }
        });
        return false;
    }

    sortListByRelationship(list, dict, rel, order) {
        utils.sortObjectsByRelationshipName(list, dict, rel, order);
        this.sort_direction = !this.sort_direction;

    }

    sortList(list, prop, order) {
        utils.sortObjectsByProperty(list, prop, order);
        this.sort_direction = !this.sort_direction
    }

    doFilter() {
        const ctrl = this;
        this.fuse.options.keys = this.search_field;
        if (ctrl.filter === "") {
            ctrl.filtered_list = utils.deepCopy(ctrl.list);
        } else {
            ctrl.filtered_list = this.fuse.search(ctrl.filter);
        }
    }

    doDelete(dashboard) {
        const ctrl = this;
        if (confirm("Are you sure you want to delete this dashboard?")) {
            ctrl.api.session_state.delete(dashboard.id).then(result => {
                ctrl.list = ctrl.list.filter(item => {
                    return item.id !== dashboard.id;
                });
                ctrl.filtered_list = utils.deepCopy(ctrl.list);
                this.fuse = new Fuse(this.filtered_list, this.search_options);
                ctrl.doFilter();
            })
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }

    }
}
