import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {DateTimePeriod, DateTimePeriodService} from "../../services/datetime_period.service";
import {HttpClient} from "@angular/common/http";
import * as utils from "../../lib/utils";
import {FlatPromise} from "../../lib/utils";
import {HeaderDataService} from "../../services/header_data.service";
import {SeriesDataService} from "../../services/series_data.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {RegisterUserView} from "../../user_control/register.component";

@Component({
    selector: 'apply-correction',
    templateUrl: './apply-correction.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ApplyCorrectionComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();
    public series_list: any[] = [];
    public correctionMethods: string[] = ['Apply constant value', 'Apply correction factor'];
    public selected_correction_method: string = 'Apply constant value';
    public comment: string = null;
    public calculation: any;
    public series: any;
    public events: any[];
    public use_offset: boolean = false;
    public target: number;
    public correction_factor_required: number;
    public correction_message: string;
    public offset: number;
    public factor: number;
    public value: number;
    public showing_hints: boolean = false;
    public hint: string = 'series';
    public apply_hour: boolean = false;
    public response: any;
    permissions: any = {};
    dtp: DateTimePeriod;
    any: any;
    data_map: any = {};
    raw_data: any;
    working: boolean = false;
    initialise_factor: number = undefined;
    calculation_list: any[] = [];
    base_types: string[] = ['series', 'calculation'];
    selected_base_type: string = 'series';
    base_type_series_series_list: any[] = [];
    display_value: number = null;
    start_time: any;
    end_time: any;
    // _ready:boolean;
    //
    // @Input() set correction_ready (value:boolean){
    //     this._ready = value;
    // }
    // get correction_ready(){
    //     return this._ready;
    // }

    constructor(
        public api: ApiService,
        public dialogRef: MatDialogRef<any>,
        public dateTimePeriodService: DateTimePeriodService,
        public snackBar: MatSnackBar, public http: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public seriesDataService: SeriesDataService,
        private headerData: HeaderDataService
    ) {
    }

    ngOnInit() {
        const ctrl = this;
        this.api.series_light.search().toPromise().then((response) => {
            this.series_list = response.data;
            this.calculation_list = response.data.filter(series => series.type == 'calculation');
            this.base_type_series_series_list = response.data.filter(series => series.type == 'series');
            console.log(ctrl.calculation_list)
        });
        if (this.data && this.data.hasOwnProperty('series') && this.data['series'] && this.data['series'].type) {
            if (this.data['series'].type == 'series') {
                this.series = this.data['series'];
                this.selected_base_type = 'series';
            } else if (this.data['series'].type == 'calculation') {
                this.calculation = this.data['series'];
                this.selected_base_type = 'calculation';
            }

        }
        // this.correctionMethods = ;
        this.dtp = this.dateTimePeriodService.dtp;

        if (ctrl.series && ctrl.series.id) {
            ctrl.seriesDataService.getSeriesPermissions([this.series.id]).then(permissions => {
                ctrl.permissions = permissions;
            });
        }
        this.dateTimePeriodService.dtpChanged.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dtp = dtp;
            ctrl.display_value = null;
        });
        this.dateTimePeriodService.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.dtp = dtp;
            if (ctrl.selected_correction_method === 'Apply correction factor') {
                ctrl.getValue()
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    getResponse() {
        const response = {};

        const ctrl = this;
        response['start'] = ctrl.dateTimePeriodService.dtp.start.toISOString();
        response['end'] = ctrl.dateTimePeriodService.dtp.end.toISOString();
        if (ctrl.series.id) response['series'] = ctrl.series.id;
        if (ctrl.factor) response['factor'] = ctrl.factor;
        if (ctrl.offset) response['offset'] = ctrl.offset;
        if (ctrl.initialise_factor) response['initialise_factor'] = ctrl.initialise_factor;
        if (ctrl.apply_hour) response['apply_hour'] = ctrl.apply_hour;
        if (ctrl.value) response['value'] = ctrl.value;
        if (ctrl.use_offset) response['use_offset'] = ctrl.use_offset;
        if (ctrl.comment) response['comment'] = ctrl.comment;
        if (ctrl.target) response['target'] = ctrl.target;
        if (ctrl.calculation) response['calculation'] = ctrl.calculation.id;

        return response
    }

    getCorrectionHistory() {
        const ctrl = this;

        ctrl.api.event.search(ctrl.api.prep_q([{
            or: [{
                and: [
                    {name: 'start', op: 'gte', val: ctrl.dateTimePeriodService.dtp.start},
                    {name: 'start', op: 'lte', val: ctrl.dateTimePeriodService.dtp.end}
                ]
            }, {
                and: [
                    {name: 'end', op: 'gte', val: ctrl.dateTimePeriodService.dtp.start},
                    {name: 'end', op: 'lte', val: ctrl.dateTimePeriodService.dtp.end}
                ]
            }]
        },
            {name: 'series_list', op: 'any', 'val': {op: 'in', name: 'id', 'val': [ctrl.series.id]}},
            {name: 'base_type', op: 'eq', val: 'correction_factor'}
        ])).toPromise().then((response) => {
            console.log('response from get correction history', response);
            ctrl.events = response.data;
        });

    } ;

    clearVariables() {
        this.use_offset = null;
        this.value = null;
        this.factor = null;
        this.offset = null;
        this.target = null;
    }

    applyCorrectionFactor() {
        const ctrl = this;

        ctrl.correction_factor_required = ctrl.factor;

        if (this.selected_correction_method === this.correctionMethods[0]) {
            ctrl.apply_hour = true;
        } else {
            ctrl.apply_hour = false;
        }

        if (!(ctrl.value) && ctrl.selected_correction_method == ctrl.correctionMethods[0]) {
            ctrl.snackBar.open('Please enter a value to apply to correction', 'Hide');
            return
        }

        if (ctrl.comment) {

            if (ctrl.correction_factor_required || ctrl.correction_factor_required === 0 || ctrl.use_offset) {

                this.http.put('/api/applyCorrectionFactor' + '?' + utils.httpParamSerializer(
                    ctrl.getResponse()
                ), null).toPromise().then((data) => {
                    this.snackBar.open(data['message'] + '\n' + data['total_changed'] + ' raw data entries adjusted', 'Hide');
                    this.getCorrectionHistory();
                    ctrl.getValue()

                }, function (error) {
                    ctrl.snackBar.open('Could not apply correction - please check correction methodology ' + error, 'Hide')
                });
            } else {
                ctrl.snackBar.open('Please add a correction factor or use an offset before applying', 'Hide');
                return
            }

        } else {
            ctrl.snackBar.open('Please add a comment before applying a correction factor', 'Hide');
            return
        }
    }

    getCorrectionFactor() {
        const ctrl = this;
        if (!ctrl.series) {
            ctrl.snackBar.open('Please select a series to determine a correction factor for', 'Hide');
            return;
        }

        // if (!ctrl.calculation){
        //     ctrl.snackBar.open('Please select a calculation and the required target for this calculation', 'Hide');
        //     return;
        // }

        if (ctrl.target) {
            ctrl.api.get("/api/findCorrectionFactor" + '?' + utils.httpParamSerializer(
                ctrl.getResponse()
            )).toPromise().then(function (data: any) {
                ctrl.correction_factor_required = data.correction_factor_required;
                ctrl.correction_message = data.message;
                ctrl.getValue();
                console.log('response from finding factor:', data);
                if (ctrl.use_offset) {
                    ctrl.offset = ctrl.correction_factor_required;
                } else {
                    ctrl.factor = ctrl.correction_factor_required;
                }
                ctrl.snackBar.open(data.message, 'Hide')
            })
        } else {
            let message;

            if (ctrl.selected_base_type == 'series') {
                if (ctrl.series) {
                    message = 'Please provide a target value for "' + ctrl.series.attributes.description + '"'
                } else if (!ctrl.series) {
                    message = 'Please select a series to correct and provide a target value for this series'
                }
            } else if (ctrl.selected_base_type == 'calculation') {
                if (ctrl.calculation) {
                    if (ctrl.series) {
                        message = 'Please provide a target value for "' + ctrl.calculation.attributes.description +
                            '" to determine the correction factor required for "' + ctrl.series.attributes.description + '"'
                    } else {
                        message = 'Please provide a target value for this calculation.' +
                            ' Please also select a series to apply the correction to for this calculation'
                    }
                } else if (!ctrl.calculation) {
                    message = 'Please select a calculation to correct and provide a target value for this calculation. ' +
                        'Please also select a series to apply the correction to for this calculation'
                }

            }
            ctrl.snackBar.open(message, 'Hide')
        }

    };

    overrideCalculations() {
        const ctrl = this;
        ctrl.getCalculations();
    }

    getCalculations() {
        const ctrl = this;
        console.log('this is happening', this.series.id)
        console.log("sending dtp", this.dtp);
        ctrl.headerData.getCalculations(ctrl.dtp, ctrl.series.id, 'hour', 1).then(data => {
            ctrl.snackBar.open('Calculations successfully updated', null, {duration: 2000})
        }).catch(reason => {
                ctrl.snackBar.open('Failed to update calculations ' + reason, 'Hide');
                console.log('reason for fail', reason)
            }
        );
    };

    checkCorrectionReady(): boolean {
        if (this.selected_correction_method && this.series && this.calculation) {
            return true;
        }

    }

    checkDayValueReady(): boolean {
        if (this.series && this.permissions[this.series.id] && this.permissions[this.series.id].includes('edit_process_data')) {
            return true;
        }
    }

    fetchRawData() {
        const ctrl = this;
        ctrl.raw_data = new FlatPromise();
        ctrl.data_map[ctrl.series.id] = {};

        let date_counter = utils.deepCopy(ctrl.dateTimePeriodService.dtp.start);
        while (date_counter <= ctrl.dateTimePeriodService.dtp.end) {
            ctrl.data_map[ctrl.series.id][date_counter.toISOString()] = {};
            date_counter['addHours'](1);
        }
        let data_fetched = ctrl.api.raw_data.search(ctrl.api.prep_q([
            {
                name: 'series_id',
                op: 'eq',
                val: ctrl.series.id
            },
            {
                name: 'time_stamp',
                op: '<=',
                val: ctrl.dateTimePeriodService.dtp.end
            },
            {
                name: 'time_stamp',
                op: '>=',
                val: ctrl.dateTimePeriodService.dtp.start
            }
        ])).toPromise();

        data_fetched.then(function (data) {

                data.data.map(function (col) {
                    let iso_date = (new Date(col.attributes.time_stamp)).toISOString();
                    ctrl.data_map[ctrl.series.id][iso_date] = col;
                });
                ctrl.raw_data.resolve();
            }, rejected => {
                ctrl.raw_data.reject(rejected);
            }
        );
        return ctrl.raw_data
    };

    getValue() {
        const ctrl = this;
        let value_series;
        if (ctrl.selected_base_type == 'series') {
            value_series = ctrl.series
        } else if (ctrl.selected_base_type == 'calculation') {
            value_series = ctrl.calculation
        }
        ctrl.api.get('/GetSeriesSummary?', {
            params: {
                series_list: [value_series.id],
                start: ctrl.dateTimePeriodService.dtp.start.toISOString(),
                end: ctrl.dateTimePeriodService.dtp.end.toISOString(),
                // sample_period: ctrl.dateTimePeriodService.dtp.sample_period.wire_sample,
                columns: 'Value',
                return_type: 'json',
                format: 'records',
            }
        }).toPromise().then(data => {
            ctrl.display_value = data[0]['Value'];
        });

    }

    applyDayValue() {

        if (!this.value && this.value !== 0) {
            this.snackBar.open('Please enter an hourly value to apply', 'Hide');
            return
        }

        const ctrl = this;
        let raw_data_error = false;
        let saved: number = 0;
        let updated: number = 0;

        if (ctrl.value === null || isNaN(ctrl.value)) {
            ctrl.snackBar.open('Value is null or not a number.', 'Hide');
        } else if (ctrl.series == null) {
            ctrl.snackBar.open('No series selected', 'Hide');
        } else {
            ctrl.snackBar.open('Working...', null, {duration: 2000});
            ctrl.working = true;

            ctrl.fetchRawData();

            ctrl.raw_data.promise.then(function () {
                let date_counter = utils.deepCopy(ctrl.dateTimePeriodService.dtp.start);

                let promises = [];
                while (date_counter <= ctrl.dateTimePeriodService.dtp.end) {
                    let raw_data = {
                        id: undefined, type: 'raw_data', attributes: {
                            series: ctrl.series.id,
                            value: ctrl.value,
                            time_stamp: utils.deepCopy(date_counter)
                        }
                    };
                    if (ctrl.data_map[ctrl.series.id][raw_data.attributes.time_stamp.toISOString()].hasOwnProperty('id')) {
                        raw_data.id = ctrl.data_map[ctrl.series.id][raw_data.attributes.time_stamp.toISOString()].id;
                        promises.push(ctrl.api.raw_data.patch(raw_data).then(function (result) {
                            updated += 1;
                        }, function () {
                            raw_data_error = true;
                            console.log("Error: Data not saved for: " + raw_data.attributes.time_stamp);
                        }));
                    } else {
                        promises.push(ctrl.api.raw_data.save(raw_data).then(function (result) {
                            saved += 1;
                        }, function () {
                            raw_data_error = true;
                            console.log("Error: Data not saved for: " + raw_data.attributes.time_stamp);
                        }));
                    }
                    date_counter['addHours'](1);
                }

                Promise.all([promises]).then(function () {
                    setTimeout(() => {
                        ctrl.working = false;
                        if (raw_data_error == true) {
                            ctrl.snackBar.open('Some values were not saved, please check the console.', 'Hide');
                        } else {
                            ctrl.snackBar.open(saved + ' new values saved. ' + updated + ' values updated.', null, {duration: 3000});
                            ctrl.getCalculations()
                            //  there probably should be a discussion about user permissions around the get calcs, but I think if you can upload data, you should be allowed to update relevant calcs.
                        }
                    }, 2000);
                });
            });
        }
    };

    getPermissions() {
        const ctrl = this;
        if (ctrl.series.id) {
            ctrl.seriesDataService.getSeriesPermissions([this.series.id]).then(permissions => {
                ctrl.permissions = permissions;
            });
        }
    }

    configure(series) {
        console.log(series);
        const ctrl = this;
        let m = '';
        let desc_or_name = (series.value.attributes.description ? series.value.attributes.description :
            series.value.attributes.name);
        if (series.value.type == 'calculation') {
            if (confirm('The series you selected: "' + desc_or_name + '" is a calculation.' +
                ' Would you like to apply a correction factor to this calculation instead?')) {
                ctrl.calculation = undefined;
                ctrl.calculation = series.value;
                m = ' Updated the calculation to : "' + series.value.attributes.description + '"';
            }
            this.series = undefined;
            this.snackBar.open('You selected a calculation ' + desc_or_name + m + ' ' +
                ' Please re-select a series to apply a correction factor to', 'Hide')
        } else if (series.value.type == 'series') {
            this.series = series.value
        }
    }

}
