<!--Please use  tileData.editingSubject.value for merge conflict-->
<div class="dashboard-tile"
     [ngClass]="{'text-editing': tileData.editingSubject.value && (tile.content==='paragraph' || tile.content==='custom' ),
     'showing-comments': showing_comments}"
     [ngStyle]="{'border-color':tile.tile_category ? tile.tile_category.colour : '' }">
  <div class="row tile-header" [ngStyle]="{'background-color':tile.tile_category ? tile.tile_category.colour : '' }">
    <ng-container>
      <div *ngIf="gridEditing"
           style="display: flex; align-items: center; justify-content: center; width: 36px; height: 36px">
      </div>

      <h2>{{title}}
        <span>{{sub_title}}</span>
      </h2>

      <h2>
        <ng-container *ngIf="tile.content !== 'pivot-tile'">
          <ng-container *ngFor="let button of buttons">
            <a *ngIf="button.link===true" [routerLink]="button.func">
              <i [class]="button.class +' hideonprint'" [matTooltip]=button.HoverOverHint></i>
            </a>
            <a (click)="button.func()" *ngIf="!button.link">
              <i [class]="button.class +' hideonprint'" [matTooltip]=button.HoverOverHint></i>
            </a>
          </ng-container>
        </ng-container>
      </h2>
    </ng-container>
    <div *ngIf="!tile.hide_edit" [class.hidden-header]="tile.show_header===false" class="tile-header-right">
      <div *ngIf="dateTimePeriodService.dtp"
           class="date-period-range-select">
        <mat-select (selectionChange)="selectCustomDtp()" [(value)]="dateTimePeriodService.dtp.range"
                    [disabled]="tile.custom_dtp!==true" [matTooltipDisabled]="tile.custom_dtp!==true"
                    class="range-select" matTooltip="Select tile date range."
                    placeholder="Select Range">
          <mat-option *ngFor="let range of dateTimePeriodService.ranges"
                      [value]="range.range">{{range.range | removeUnderscore | upperfirst}}</mat-option>
          <mat-option (click)="selectCustomDtp()" value="custom">Custom</mat-option>
        </mat-select>
      </div>

      <ng-container *ngIf="!tile.hide_edit && allowEdit(tile.content)">
        <span [matMenuTriggerFor]="menu" class="hide-xs"><i class="edit-no-text"></i></span>
        <mat-menu #menu="matMenu" class="tile-menu">
          <button
              (click)="openTextFormDialog()"
              *ngIf="(['paragraph'].includes(tile.content)) && !editing && allowEdit(tile.content)"
              mat-menu-item>Edit text<i class="fa fa-paragraph"></i></button>
          <button
              (click)="tileData.setEditing(true);"
              *ngIf="(['custom', 'series-table'].includes(tile.content)) && !editing && allowEdit(tile.content)"
              mat-menu-item>Edit text<i class="fa fa-paragraph"></i></button>
          <button (click)="buttons[0].func();pivotEditing=!pivotEditing"
                  *ngIf="(tile.content==='pivot-tile' && !pivotEditing)"
                  mat-menu-item>Edit pivot<i class="fa fa-edit"></i></button>
          <button (click)="buttons[1].func();pivotEditing=!pivotEditing"
                  *ngIf="(tile.content==='pivot-tile' && pivotEditing)"
                  mat-menu-item>Save pivot<i class="fa fa-save"></i></button>

          <button (click)="tileData.setEditing(false);saveContent(tile);"
                  *ngIf="(['paragraph','custom','series-table'].includes(tile.content)) && editing"
                  mat-menu-item>Save text<i class="fa fa-save"></i></button>
          <button
              (click)="tileData.setEditing(false);"
              *ngIf="(tile.content==='paragraph' || tile.content==='custom'|| tile.content==='series-table') && editing"
              mat-menu-item>Cancel<i class="fa fa-ban"></i></button>
          <button (click)="openTileFormDialog();" [disabled]="appscope.current_user.restricted_access" mat-menu-item
                  title="{{appscope.current_user.restricted_access? 'You do not have rights to edit this tile':'Edit tile'}}"
          >Edit tile<i class="fa fa-edit"></i></button>
          <button (click)="deleteTile()" [disabled]="appscope.current_user.restricted_access"
                  mat-menu-item
                  title="{{appscope.current_user.restricted_access? 'You do not have rights to edit this tile':'Delete tile'}}"
          >Delete tile<i class="fa fa-trash"></i></button>
        </mat-menu>
      </ng-container>
    </div>

    <div #dtp_picker *ngIf="show_date_picker" class="dtp_picker form-dialog">
      <date-period [disabled]="!tile.custom_dtp" [immutable]="true" [no_url]="true"
                   class="date-period inline"></date-period>
      <div class="action-buttons">
        <button (click)="onCloseClick()" class="btn-close">Close</button>
        <button (click)="saveCustomDtp()" class="btn-save">Save</button>
      </div>
    </div>
  </div>

  <div class="tile-content">
    <date-period *ngIf="tile.parameters && tile.parameters.show_dtp"
                 class="date-period inline align-bottom"></date-period>

    <div style="height: 100%; width: 100%; min-height:50px;" *ngIf="tile.content=='paragraph'">
      <paragraph [show_header]="tile.show_header"
                 [config]="tile.parameters"
                 [save_content]="save_content">
      </paragraph>
    </div>

    <custom (tileContentChange)="tile.parameters = $event" *ngIf="tile.content==='custom'"
            [config]="tile.parameters"
            [save_content]="save_content"
            [show_header]="tile.show_header"></custom>

    <flow-chart title="{{tile.parameters.process.attributes.name}}" *ngIf="tile.content==='flowchart' && flowchartReady"
                (context_menu)="contextMenu($event)" [chart]="flowchart" class="flowchart-tile">
    </flow-chart>
    <div #context_menu *ngIf="tile.content==='flowchart' && flowchartReady"
         [ngStyle]="{'display':menuVisible? 'block':'none'}"
         class="menu flowchart-context-menu">
      <ul class="menu-options">
        <li *ngIf="selected_flowchart_components[0]?.data.type==='process' &&
			selected_flowchart_components[0]?.is_parent !== true" class="menu-option" style="cursor:default;padding:0;">
          <a (click)="changeProcess(selected_flowchart_components[0])">
            Zoom In
          </a>
        </li>
        <li *ngIf="selected_flowchart_components[0]?.data.type==='process' &&
			selected_flowchart_components[0]?.is_parent === true && selected_flowchart_components[0]?.data.relationships.parent.data"
            class="menu-option" style="cursor:default;padding:0;">
          <a (click)="changeProcess(selected_flowchart_components[0]?.data.relationships.parent)">
            Zoom Out
          </a>
        </li>
        <li (click)="menuVisible=false;" *ngIf="selected_flowchart_components[0]?.data.type==='process'"
            class="menu-option" style="cursor:default;padding:0;">
          <a (click)="openFlowchart(selected_flowchart_components[0].data)">
            Open flowchart
          </a>
        </li>
      </ul>
    </div>
    <generic-chart *ngIf="tile.content==='custom-chart' || tile.content==='comparison-chart'" class="chart"
                   [config]="tile.parameters">
    </generic-chart>

    <div style="height:200px;width:100%;" *ngIf="tile.content==='context'">
      <context *ngIf="tile.content==='context'" [config]="tile.parameters"
               [show_header]="tile.show_header" [title]="tile.title">
      </context>
    </div>

    <log-sheet *ngIf="tile.content==='log-sheet'"
               [config]="tile.parameters">
    </log-sheet>

    <event-sheet *ngIf="tile.content==='custom-events' || tile.content==='stockpile-events'" [config]="tile.parameters">
    </event-sheet>

    <default-chart style="width: 90%; height: 90%;"
                   *ngIf="tile.content=='default-chart' || tile.content=='budget-bar-chart'
|| tile.content=='spc-chart'" class="chart"
                   [series]="tile.parameters.series" [type]="tile.content"
                   [hide_comments]="tile.parameters.hide_comments">
    </default-chart>

    <!--<budget-chart style="width: 100%; height: 100%;" *ngIf="tile.content=='budget-bar-chart'" class="chart"-->
    <!--[series]="tile.parameters.series" [hide_comments]="tile.parameters.hide_comments">-->
    <!--</budget-chart>-->

    <series-table *ngIf="tile.content==='series-table'" [config]="tile.parameters"></series-table>

    <weather-widget *ngIf="tile.content==='weather-widget'"></weather-widget>

    <input-data-sheet *ngIf="tile.content==='input-sheet'" [config]="tile.parameters"></input-data-sheet>

    <events *ngIf="tile.content==='events'" [config]="tile.parameters"></events>

    <pivot-tile *ngIf="tile.content==='pivot-tile'" [config]="tile.parameters" [showUI]="false"
                (tileContentChange)="emitTileContent($event)"></pivot-tile>

    <!--//TODO allow comments on series-table and input-sheet??-->
    <comment-drawer
        *ngIf="tile.show_header!==false && !['weather-widget', 'input-sheet','paragraph','custom', 'pivot-tile'].includes(tile.content)"
        [button_class]="tile.content === 'series-table' ||
            tile.content === 'log-sheet' ||
            tile.content === 'input-sheet'">

    </comment-drawer>

    <category-chart *ngIf="tile.content==='category-chart'" [config]="tile.parameters"></category-chart>

  </div><!--end tile-content-->
</div>
