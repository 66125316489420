import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AppScope} from "../services/app_scope.service";
import {MatSelectChange} from "@angular/material";
import {HeaderDataService} from "../services/header_data.service";

//Fallback page for general errors  - no default pages set per site, permissions errors etc
//Why were you sent to this page
export enum RedirectType {
    FORBIDDEN,
    DEFAULT,
    ERROR
}

@Component({
    selector: 'welcome',
    templateUrl: 'welcome.component.html',
    encapsulation: ViewEncapsulation.None
})

export class WelcomeComponent implements OnInit {

    constructor(private appScope: AppScope,
                private headerData: HeaderDataService) {

    }

    ngOnInit() {
        const ctrl = this;
        this.headerData.title = 'Welcome to WIRE!';
        this.headerData.add_new_page_form = true;

    }
}
