
<mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">
        <mat-tab label="Properties">
            <form class="form-section column space-between">
                <div class="column">
                    <div class="row" style="flex-wrap:wrap;" >
                        <mat-form-field (mouseenter)="hint='Name'"  style="flex-basis:50%;flex-grow:0;">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="" [(ngModel)]="efc.component_data.attributes.name" name="name">
                        </mat-form-field>

                        <mat-form-field (mouseenter)="hint='Description'"  style="flex-basis:50%;flex-grow:0;">
                            <mat-label>Description</mat-label>
                            <input matInput placeholder="" [(ngModel)]="efc.component_data.attributes.description" name="description">
                        </mat-form-field>

                        <div class="tip-box" style="flex-grow:1;">
                            <div class="tip" [ngClass]="{'showing': hint=='Name'}">
                                <label>Name</label>
                                <div>
                                    The name of the process or plant section.
                                </div>
                            </div>
                            <div class="tip" [ngClass]="{'showing': hint=='Description'}">
                                <label>Description</label>
                                <div>
                                    A more lengthy description of the process.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row reduce-height">
                        <mat-form-field  (mouseenter)="hint='Code'">
                            <mat-label>Code</mat-label>
                            <input matInput placeholder="" [(ngModel)]="efc.component_data.attributes.code" name="code">
                        </mat-form-field>

                        <div class="tip-box" style="flex-grow:1;">
                            <div class="tip" [ngClass]="{'showing': hint=='Code'}">
                                <label>Code</label>
                                <div>
                                    An alpha-numerical code that sets the sort order of a group of processes.
                                </div>
                            </div>
                        </div>
                    </div>



                    <!--&lt;!&ndash; <md-button class="md-icon-button" ng-click='addConnector()'> <md-icon class="material-icons">add</md-icon></md-button> &ndash;&gt;-->
                    <!--<div ng-repeat="connector in chart_component.connectors" style="height: 50px" >-->
                    <!--&lt;!&ndash; <md-button class="md-icon-button" ng-click='removeConnector(connector)'> <md-icon class="material-icons">close</md-icon></md-button>  &ndash;&gt;-->
                    <!--<md-input-container>-->
                    <!--<label>Name</label>-->
                    <!--<input ng-model="connector.data.attributes.name">-->
                    <!--<div class="hint" ng-if="$parent.$parent.showHints">-->
                    <!--Add a name for this connector if this will further help to identify the attached stream.-->
                    <!--</div>-->
                    <!--</md-input-container>-->
                    <!--&lt;!&ndash; <input type="range" max="100" min="0" ng-model="connector.data.attributes.percent"> &ndash;&gt;-->


                </div>
            </form>
        </mat-tab>
        <mat-tab label="Display" *ngIf="efc.component_data.hasOwnProperty('id')">
            <form class="form-section column space-between">
                <div class="column">
                    <div class="row" >
                        <mat-form-field  (mouseenter)="hint='Icon'" >
                            <mat-label style="display:flex;align-items:center;margin-top:-6px;">Select Icon <img src="{{ efc.component_data.attributes.icon }}" width="30px" height="30px"/></mat-label>
                            <!--<label>Select Icon<img src="{{ efc.component_data.attributes.icon }}" width="30px" height="30px"/></label>-->
                            <mat-select [(ngModel)]="efc.component_data.attributes.icon" name="process_icon">
                                <mat-option *ngFor="let img of efc.images" [value]="img" class="image-options">
                                    <img width="30px" height="30px" src="{{ img }}"/><span>{{ img.split("/")[img.split("/").length-1].split('.')[0] }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-checkbox style="width: 80px;align-self: center;min-width: 70px;flex-grow: 0"
                                      [(ngModel)]="efc.component_data.attributes.json.pad_image"
                                      name="pad_image" >Pad Image</mat-checkbox>

                        <mat-form-field (mouseenter)="hint='FontSize'" >
                            <mat-label>Font Size</mat-label>
                            <input matInput type="number" max="32" [(ngModel)]="efc.component_data.attributes.json.fontsize" name="font_size">
                        </mat-form-field>

                        <div class="tip-box" style="flex-grow:1;">
                            <div class="tip" [ngClass]="{'showing': hint=='Icon'}">
                                <label>Icon</label>
                                <div>
                                    The icon that will be shown with this process in the flowcharts and in the application header.
                                </div>
                            </div>
                            <div class="tip" [ngClass]="{'showing': hint=='FontSize'}">
                                <label>Font Size</label>
                                <div>
                                    The size of the font displayed for this process in the flowchart. (A maximum of 32px is allowed.)
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="efc.is_parent==true" >
                        <mat-form-field>
                        <mat-label>Screen Width ({{efc.component_data.attributes.json.windowWidth}})</mat-label>
                        <input matInput type="number"
                               [max]="5000"
                               [step]="10"
                               [min]="100" name="window_width"
                               [(ngModel)]="efc.component_data.attributes.json.windowWidth">
                        <!--<div class="hint" ng-if="$parent.$parent.showHints">-->
                        <!--The outer width of the entire flowchart on screen (shown by the orange box). This option is only available when editing the flowchart's parent process.-->
                        <!--</div>-->
                        </mat-form-field>
                        <mat-form-field>
                        <mat-label>Screen Height ({{efc.component_data.attributes.json.windowHeight}})</mat-label>
                        <input matInput type="number"
                               [max]="5000"
                               [step]="10"
                               [min]="100" name="window_height"
                               [(ngModel)]="efc.component_data.attributes.json.windowHeight">
                            </mat-form-field>
                        <!--<div class="hint" ng-if="$parent.$parent.showHints">-->
                        <!--The outer height of the entire flowchart on screen (shown by the orange box). This option is only available when editing the flowchart's parent process.-->
                        <!--</div>-->
                    </div>
                    <div *ngIf="efc.is_parent==true" >

                        <label>Select standard page size:</label>
                        <div class="row">
                            <mat-form-field name="page_size">
                                <mat-label>Page Size</mat-label>
                                <mat-select [(ngModel)]="efc.page_size" (selectionChange)="efc.setPageSize()" name="page_size">
                                    <mat-option [value]="'A4'">A4</mat-option>
                                    <mat-option [value]="'A3'">A3</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field name="page_layout">
                                <mat-label>Page Size</mat-label>
                                <mat-select [(ngModel)]="efc.page_layout" (selectionChange)="efc.setPageSize()" name="page_layout">
                                    <mat-option [value]="'portrait'">Portrait</mat-option>
                                    <mat-option [value]="'landscape'">Landscape</mat-option>
                                    <mat-option [value]="'auto'">Auto</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="efc.is_parent==false">
                        <!--//TODO mat-slider not sliding properly-->
                        <mat-form-field>
                            <mat-label>Width</mat-label>
                            <input matInput type="number" style="width:300px"
                                   [max]="2000"
                                   [min]="10"
                                   [step]="10"
                                   [(ngModel)]="efc.component_data.attributes.json.width"
                                   name="width">
                            <!--<div class="hint" *ngIf="$parent.$parent.showHints">-->
                            <!--The width of the process rectangle that appears on the flowchart.-->
                            <!--</div>-->
                        </mat-form-field>
                        <mat-form-field >
                            <mat-label>Height</mat-label>
                            <input matInput type="number"
                                   [max]="1000"
                                   [min]="10"
                                   [step]="10"
                                   name="height"
                                   [(ngModel)]="efc.component_data.attributes.json.height">
                            <!--<div class="hint" *ngIf="$parent.$parent.showHints">-->
                            <!--The height of the process rectangle that appears on the flowchart.-->
                            <!--</div>-->
                        </mat-form-field>
                        <div>
                            <chart-process-detail
                                    style="min-height:200px;
                                              margin: 0px 10px -120px 10px;
                                              padding:12px;" [process]="efc.component_view">
                            </chart-process-detail>
                        </div>

                    </div>
                </div>
            </form>
        </mat-tab>

        <mat-tab label="Relationships" *ngIf="efc.component_data.hasOwnProperty('id')">
            <form class="form-section column space-between">
                <div class="column">
                    <div class="row">
                        <mat-form-field  (mouseenter)="hint='ProcessType'"  style="flex-basis:50%;flex-grow:0;">
                            <mat-label>Process Type</mat-label>
                            <mat-select [(ngModel)]="efc.component_data.relationships.component_type.data" name="process_type"
                                        (selectionChange)="efc.fillConstantTemplate()">
                                <mat-option *ngFor="let component_type of efc.component_types" [value]="component_type" >
                                    {{component_type.attributes.name}} - {{component_type.attributes.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class="btn" (mouseenter)="hint='FillSeriesTemplate'" (click)="efc.fillSeriesTemplate()"
                                [hidden]="!efc.component_data.relationships.component_type.data" style="justify-self: center;align-self:center;">Fill Template</button>

                        <div class="tip-box" style="flex-grow:1;">
                            <div class="tip" [ngClass]="{'showing': hint=='ProcessType'}">
                                <label>Process Type</label>
                                <div>
                                    This will be used to identify the template of series types that are applicable for this process.
                                    After creating the process, the 'Fill series template' button can be used to add these series types to this process.
                                </div>
                            </div>

                            <div class="tip" [ngClass]="{'showing': hint=='FillSeriesTemplate'}">
                                <label>Fill Series Template</label>
                                <div>
                                    This will be used to identify the template of series types that are applicable for this process.
                                    After creating the process, the 'Fill series template' button can be used to add these series types to this process.
                                </div>
                            </div>
                        </div>
                    </div>

                    <h4 *ngIf="efc.constant_properties_ready === true">Custom Properties</h4>
                    <constant-component-table class="row" *ngIf="efc.constant_properties_ready === true"
                                              [component] = "efc.component_data"
                                              [constant_property_dict] = "efc.constant_property_dict"
                                              [constant_components] = "efc.constant_components"
                                              [flowchart] = "efc.chart">
                    </constant-component-table>

                    <h4>Series</h4>
                    <div class="row">
                        <div class="select-search-many" style="flex-grow:2" (mouseenter)="hint='select_series'">
                            <select-many-search [label]="'Select a series'"
                                                [(value)]="efc.selected_series"
                                                [data]= "efc.plantData.all_series"
                                                [classes]="'fill'"
                                                [fill_stubs]="true"

                            >
                            </select-many-search>
                            <!--(selectChange)="selected_series=$event;"-->
                        </div>
                        <div style="align-self:center;display:flex;">
                            <button class="icon" (click)='efc.addSeries()' matTooltip="Add to process">
                                <i class="fa fa-arrow-down" ></i></button>
                            <button class="icon-new" (click)="efc.showSeriesForm()" matTooltip="Add new series">
                            </button>
                            <!--<button class="btn" (click)="efc.setTemplate()" matTooltip="Set Template"><i class="fa fa-tasks" ></i></button>-->
                        </div>
                        <!--<div class="hint">-->
                        <!--Select a series and use the Add to process button to add it to this process. The Add new series button can be used to create a new series which will be added to this process.-->
                        <!--</div>-->
                    </div>

                    <series-component-table class="row" *ngIf="efc.series_properties"
                                            [component] = "efc.component_data"
                                            [deleted_component_ids] = "efc.deleted_component_ids"
                                            [series_properties] = "efc.series_property_dict"
                                            [all_series] = "efc.plantData.all_series"
                                            [flowchart] = "efc.chart">
                    </series-component-table>

                    <h4 *ngIf="efc.series_permissions" style="margin-top:20px;">Constant Series Data </h4>
                    <!--<div class="hint" ng-if="$parent.$parent.showHints && show_constants">-->
                    <!--Manage constant values for a series in the form(s) below. Use the 'Add new' button to add a new constant value for a series. To remove an entry, delete the value at the required date-time. Use the 'Save' button to save all changes.-->
                    <!--</div>-->

                    <ng-container *ngIf="efc.series_permissions">
                        <div *ngFor="let series_component of efc.component_data.relationships.series_components.data;"
                             style="min-width:650px;">
                            <ng-container *ngIf="series_component.relationships.series.data && (
                                series_component.relationships.series.data.attributes.fill_method=='Constant'
                                || series_component.relationships.series.data.attributes.fill_method=='Constant Monthly Flatten')
                                && efc.series_permissions[series_component.relationships.series.data.id]
                                && efc.series_permissions[series_component.relationships.series.data.id].indexOf('view_process_data') > -1">

                                <div class="row" >
                                    <label (click)="series_component.relationships.series.data.hide_constants=!series_component.relationships.series.data.hide_constants"
                                           style="color:gray; text-decoration: underline;padding-top:20px;cursor:pointer;">
                                        {{series_component.relationships.series.data.attributes.name}}
                                        <i class="fa fa-angle-up" style="border:none; margin-left:12px;"
                                           [ngClass]="{'fa-angle-up': !series_component.relationships.series.data.hide_constants, 'fa-angle-down': series_component.relationships.series.data.hide_constants}"
                                           aria-label="Hide data values" matTtooltip="Hide data values" role="button" tabindex="0">
                                        </i></label>
                                </div>
                                <constant-series-data *ngIf="!series_component.relationships.series.data.hide_constants"
                                                      [series]="series_component.relationships.series.data"
                                                      [series_permissions]="efc.series_permissions">
                                </constant-series-data>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </form>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<div class="action-buttons">
    <button class="btn-close" *ngIf="dialog" (click)="close()">Close</button>
    <button class="btn-save" (click)="save()" style="margin-right:10px;">Save</button>
</div>