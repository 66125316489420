import * as utils from '../lib/utils';
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {MatSnackBar} from "@angular/material";
import {PlantDataService} from "../services/plant_data.service";
import {ApiService} from "../services/api.service";
import {HttpClient} from "@angular/common/http";
import {SeriesDataService} from "../services/series_data.service";

//All the common properties and methods used by various element types on the flowchart (process/stream/equipment)
export class EditFlowchartComponent implements OnInit {
    chart: any;
    component_view: any;
    component_data: any;

    existing_component: any = {};
    is_parent: boolean;
    page_size: string = 'A4';
    page_layout: string = 'landscape';

    all_series: any[];
    component_types: any[];
    component_types_promise: any;
    series_properties: any[];
    series_property_dict: any = {};
    constant_properties: any[];
    constant_properties_promise: any;
    constant_property_dict: any = {};
    constant_properties_ready: boolean = false;
    deleted_component_ids: any = [];
    constant_components: any[];
    series_components: any[];

    show_series: boolean = false;

    images: any[];
    avail_equipment: any[];
    parent_list: any[];
    selected_parent: any;
    selected_series: any[] = [];
    series_permissions: any;
    addEquipmentType: string = 'new';

    constructor(public flowchart: any,
                public component: any,
                public plantData: PlantDataService,
                private seriesData: SeriesDataService,
                private api: ApiService,
                private snackBar: MatSnackBar,
                private http: HttpClient) {
        this.chart = flowchart;
        this.component_view = component; //view model for the component
        this.component_data = component.data; //data for the component
        this.all_series = plantData.all_series;
    }

    ngOnInit(): void {
    }

    loadForm() {
        const ctrl = this;
        console.log("Edit component Form", this.component_data);
        if (ctrl.component_data.hasOwnProperty('id') && ctrl.component_data.id == ctrl.chart.parent_process.data.id) {
            ctrl.is_parent = true;
            ctrl.component_data.is_parent = true;
        } else {
            ctrl.is_parent = false;
        }
        ctrl.http.get("/api/get_icon_list").toPromise().then(function (images: any) {
            ctrl.images = images;
        });
        ctrl.component_types_promise = ctrl.api.component_type.search(ctrl.api.prep_q([{
            name: 'base_type',
            val: ctrl.component_data.type,
            op: 'eq'
        }], {})).toPromise().then(result => {
            if (!result) return;
            ctrl.component_types = result.data;
            ctrl.matchStubs();
        });

        ctrl.api.series_property.search().toPromise().then(result => {
            if (!result) return;
            ctrl.series_properties = result.data;
            ctrl.series_property_dict = {};
            ctrl.series_properties.forEach(function (item) {
                ctrl.series_property_dict[item.id] = item
            });
        });

        ctrl.constant_properties_promise = ctrl.api.constant_property.search().toPromise().then(result => {
            if (!result) return;
            ctrl.constant_properties = result.data;
            ctrl.constant_property_dict = {};
            ctrl.constant_properties.forEach(function (item) {
                ctrl.constant_property_dict[item.id] = item;
            });
        });

        if (ctrl.component_data.type == 'equipment') {
            ctrl.selected_parent = ctrl.component_data.relationships.component.data;
            ctrl.parent_list = ctrl.chart.data.processes.concat(
                ctrl.chart.data.streams.concat([ctrl.chart.data.process_focus]));

            ctrl.avail_equipment = [];
            ctrl.api.equipment.search(ctrl.api.prep_q([
                {'op': 'is_null', 'name': 'component_id'}
            ], {sort: 'name'})).toPromise().then(result => {
                if (!result) return;
                ctrl.avail_equipment = result.data
            });
        }

        if (ctrl.component_data.relationships.series && ctrl.component_data.relationships.series.data && ctrl.component_data.relationships.series.data.length > 0) {
            let permissions_promise = ctrl.seriesData.getSeriesPermissions(ctrl.component_data.relationships.series.data.map(function (series) {
                return series.id
            }));
            permissions_promise.then(function (permissions) {
                ctrl.series_permissions = permissions;
                console.log("permissions", ctrl.series_permissions);
            });
        }

        ctrl.mapRelationships();

        if (ctrl.component_data.hasOwnProperty('id')) {
            ctrl.fillConstantTemplate();
        }

    }

    matchStubs() {
        const ctrl = this;
        let stubs = [
            {rel: 'component_type', list: this.component_types}
        ];
        // if (ctrl.component_data.type === 'equipment' && ctrl.addEquipmentType === 'existing'){
        //     stubs.push ({rel: ''})
        // }

        let full_objects = utils.match_stubs(ctrl.component_data, stubs);
    }

    selectExisting() {
        this.component_data = utils.deepCopy(this.existing_component);
        this.mapRelationships();
        console.log(this.component_data);
    };

    mapRelationships() {
        const ctrl = this;
        if (ctrl.component_data.hasOwnProperty('id')) {
            ctrl.component_data.relationships.series_components.data = [];
            ctrl.chart.data.series_components.forEach(function (series_component) {
                if (series_component.relationships.component.data.id == ctrl.component_data.id) {
                    ctrl.component_data.relationships.series_components.data.push(series_component);
                }
            });
            ctrl.component_data.relationships.constant_components.data = [];
            ctrl.chart.data.constant_components.forEach(function (constant_component) {
                if (constant_component.relationships.component.data.id == ctrl.component_data.id) {
                    ctrl.component_data.relationships.constant_components.data.push(constant_component);
                }
            });
        }
    };

    getSeriesComponents() {
        const ctrl = this;
        let series_component_ids = ctrl.component_data.relationships.series_components.data.map(item => {
            return item.id
        });
        let series_ids = ctrl.component_data.relationships.series.data.map(item => {
            return item.id
        });

        let series_components;
        let series;
        if (series_component_ids.length > 0) {
            let series_components_promise = ctrl.api.series_component.search(ctrl.api.prep_q([
                {op: 'in', name: 'id', val: series_component_ids}], {})).toPromise().then(result => {
                if (!result) return;
                series_components = result.data;
            });
            let series_promise = ctrl.api.series.search(ctrl.api.prep_q([
                {op: 'in', name: 'id', val: series_ids}], {})).toPromise().then(result => {
                if (!result) return;
                series = result.data;
            });
            Promise.all([series_components_promise, series_promise]).then(function () {
                utils.fill_relations(series_components.data, series.data, 'series');
                ctrl.component_data.relationships.series_components.data = series_components;
                series_components.forEach(function (sc) {
                    ctrl.chart.addSeries(sc, ctrl.chart.parent_process, ctrl.component_view);

                })
            })
        }
    };

    fillSeriesTemplate() {
        const ctrl = this;
        let component_type = utils.getByID(ctrl.component_types, ctrl.component_data.relationships.component_type.data.id);  //selected component
        //TO CHECK: Why is this returning duplicates?

        let template = component_type.attributes.all_series_property_ids.map(function (ids) {
            return ctrl.series_property_dict[ids]
        }); //all needed series properties

        if (template.length > 0) {
            ctrl.show_series = true;
        } else {

            ctrl.snackBar.open("No series properties to fill", undefined, {duration: 3000});
        }
        let series_component_properties = ctrl.component_data.relationships.series_components.data.map(function (series_component) { //all current series properties
            if (series_component.relationships.series_property.data) {
                return series_component.relationships.series_property.data.id;
            }
        });
        let series_property_ids = [];
        template.forEach(function (series_property) {
            if (series_component_properties.indexOf(series_property.id) < 0 && series_property_ids.indexOf(series_property.id) < 0) {
                //Add it
                series_property_ids.push(series_property.id);
                ctrl.selected_series = undefined;
                ctrl.addSeries(series_property.id);
            }

        });
    };

    constant_component_schema(constant_property) {
        const ctrl = this;
        return {
            attributes: {
                value: null,
                json: {}
            },
            relationships: {
                component: {data: {type: 'component', id: ctrl.component_data.id}},
                constant_property: {data: {type: 'constant_property', id: constant_property.id}}
            },
            type: 'constant_component'
        }
    };

    fillConstantTemplate() {
        const ctrl = this;
        if (ctrl.component_data.hasOwnProperty('id')) {
            ctrl.constant_components = utils.deepCopy(ctrl.component_data.relationships.constant_components.data);
            if (ctrl.component_data.relationships.component_type.data) {
                console.log("step 1");
                Promise.all([ctrl.component_types_promise, ctrl.constant_properties_promise]).then(function () {
                    console.log(ctrl.component_types);
                    console.log(ctrl.constant_properties);
                    let component_type = utils.getByID(ctrl.component_types, ctrl.component_data.relationships.component_type.data.id);  //selected component
                    console.log(component_type)
                    let template = component_type.attributes.all_constant_properties.map(function (ids) {
                        return ctrl.constant_property_dict[ids]
                    }); //all needed series properties
                    console.log(template)
                    let constant_property_ids = ctrl.constant_components.map(function (item) {
                        console.log(item);
                        return item.relationships.constant_property.data.id
                    });
                    template.forEach(function (constant_property) {
                        if (constant_property_ids.indexOf(constant_property.id) < 0) {
                            constant_property_ids.push(constant_property.id);
                            ctrl.constant_components.push(ctrl.constant_component_schema(constant_property));
                        }
                    });
                    console.log(constant_property_ids)
                    console.log(ctrl.constant_components)
                    ctrl.constant_properties_ready = true;
                });
            } else {
                ctrl.constant_properties_ready = true;

            }
        }
    };

    moveEquipment(type) {
        const ctrl = this;
        //json circular structure error - will come back to this after float/string components.
        // ctrl.component_data.relationships[type].data = null; //delete old relationship in case it's a different type (stream vs process)
        // ctrl.component_data.relationships[ctrl.selected_parent.type].data = ctrl.selected_parent;
        // ctrl.chart.updateParentComponent(ctrl.component_data, ctrl.selected_parent);
    };

    showSeriesForm(series_component) { //adding new series
        const ctrl = this;
        let series_edit = ctrl.seriesData.upsertSeries(ctrl.component_data);
        series_edit.afterClosed().subscribe(result => {
            let series = result.series;
            let series_component = result.series_component;
            series_component.relationships.series.data = utils.deepCopy(series);
            series.relationships.series_components.data.push(series_component);

            ctrl.component_data.relationships.series_components.data.push(series_component);
            ctrl.component_data.relationships.series.data.push(series);
            //let parentComponentView = ctrl.chart.findParentComponent(series_component.id, 'series_components');
            ctrl.chart.addSeries(series_component, ctrl.chart.parent_process, ctrl.component_view);
            //ctrl.chart.addSeries (series_component, ctrl.chart.parent_process, parentComponentView) ;
            //ctrl.updateSeriesList(answer);

            ctrl.all_series.push(series);

        })
    };

    addSeries(series_property_id) {
        const ctrl = this;
        if (ctrl.selected_series === undefined) {
            ctrl.selected_series = [{id: null, type: 'series'}];
        }
        let promises = [];
        ctrl.selected_series.forEach(function (selected) {

            let comp_data = {
                type: 'series_component', relationships: {
                    component: {data: {id: ctrl.component_data.id, type: 'component'}},
                    series: {data: {id: selected.id, type: 'series'}}
                }
            };
            if (series_property_id) {
                comp_data.relationships['series_property'] = {data: {id: series_property_id, type: 'series_property'}};
            }

            let new_series_component = ctrl.api.series_component.save(comp_data);
            promises.push(new_series_component);
            new_series_component.then(function (new_sc) {
                new_sc.data.relationships.series.data = selected;
                if (series_property_id) {
                    new_sc.data.relationships.series.data.type = 'series';
                }
                ctrl.component_data.relationships.series_components.data.push(new_sc.data);
                //Add to the independant series_component viewmodel and datamodel lists;
                ctrl.chart.addSeries(new_sc.data, ctrl.chart.parent_process, ctrl.component_view); //chart_process here = the viewmodel of the process

            });
        });
        Promise.all(promises).then(result => {
            ctrl.selected_series = []
        });
    };

    addNewComponent() {
        const ctrl = this;
        let promise = this.api[ctrl.component_data.type].save(ctrl.component_data);
        promise.then(result => {
            let new_component = result.data;
            this.component_data = new_component;
            console.log(this.component_data);
            if (this.component_data.type == "equipment") {
                ctrl.component_view = ctrl.chart.addEquipment(new_component);
                ctrl.fillConstantTemplate();
            } else {
                ctrl.component_view = ctrl.chart.addProcess(new_component);
            }
            ctrl.mapRelationships();
        }, {
            //$mdToast.show($mdToast.simple().textContent('Item not added.'));
        });
        return promise;
    };

    save(): boolean {
        const ctrl = this;
        try {
            console.log(ctrl.component_data);
            if (ctrl.component_data.relationships.component_type && ctrl.component_data.relationships.component_type.data) {
                ctrl.component_data.relationships.component_type.data.type = 'component_type';
            }
            if (ctrl.component_data.type == 'equipment') {
                ctrl.component_data.relationships.component.data = {
                    id: ctrl.selected_parent.id,
                    type: 'component'
                };
            }
            if (!ctrl.component_data.hasOwnProperty('id')) {
                let promise = ctrl.addNewComponent();
                promise.then(result => {
                    console.log(result);
                    return true;
                })
            } else {
                if (ctrl.component_data.attributes.icon && !ctrl.component_view.is_parent) {
                    if (ctrl.component_view.image) {
                        ctrl.component_data.attributes.json.image.src = ctrl.component_data.attributes.icon;
                        ctrl.component_view.data.attributes.json.image.src = ctrl.component_data.attributes.icon;
                    }
                }
                if (ctrl.component_data.type == 'equipment' && ctrl.addEquipmentType == 'existing') {
                    ctrl.component_view = ctrl.chart.addEquipment(ctrl.component_data);
                    ctrl.getSeriesComponents();
                }
                // if (ctrl.component_data.attributes.custom_series){
                //     ctrl.component_data.attributes.custom_series = {};
                // }
                // if (ctrl.component_data.attributes.custom_constants){
                //     ctrl.component_data.attributes.custom_constants = {};
                // }
                ctrl.chart.report_groups = ctrl.chart.createReportGroups();

                this.plantData.saveItem(ctrl.component_data);

                ctrl.chart.data.connectors.forEach(connector => this.plantData.saveItem(connector));
                // if (ctrl.addEquipment === 'existing') {
                //     ctrl.addEquipment = null;
                // } else {
                //     //ctrl.closeDialog();
                // }
                return true;
            }
        } catch (e) {
            console.log("Error saving " + ctrl.component_data.type, e);
            return false;
        }
    };

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.id === value.id;
        }
    };

    setPageSize() {
        const ctrl = this;
        if (ctrl.page_size === 'A4') {
            if (ctrl.page_layout === 'landscape') {
                ctrl.component_data.attributes.json.windowWidth = 842;
                ctrl.component_data.attributes.json.windowHeight = 595;
            } else {
                ctrl.component_data.attributes.json.windowWidth = 595;
                ctrl.component_data.attributes.json.windowHeight = 842;
            }
        } else {
            if (ctrl.page_layout === 'landscape') {
                ctrl.component_data.attributes.json.windowWidth = 1191;
                ctrl.component_data.attributes.json.windowHeight = 842;
            } else {
                ctrl.component_data.attributes.json.windowWidth = 842;
                ctrl.component_data.attributes.json.windowHeight = 1191;
            }
        }
    };

}

@Component({
    selector: 'flowchart-process-form',
    templateUrl: 'edit_process_form.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../forms/forms.less']
})

export class FlowchartProcessFormComponent {

    @Input() flowchart: any;
    @Input() component: any;
    @Input() show_hints: boolean;
    @Input() dialog: boolean;

    @Output() save_event = new EventEmitter();
    @Output() close_event = new EventEmitter();

    efc: EditFlowchartComponent;
    hint: string;
    showing_hints: any;

    constructor(private plantData: PlantDataService,
                private seriesData: SeriesDataService, private api: ApiService, private snackBar: MatSnackBar,
                private http: HttpClient) {
    }

    ngOnInit(): void {
        this.efc = new EditFlowchartComponent(this.flowchart, this.component, this.plantData, this.seriesData, this.api, this.snackBar,
            this.http);
        this.efc.loadForm();
    }

    save() {
        if (this.efc.save()) {
            this.save_event.emit(this.efc.component_view);
        }
    }

    close(): void {
        this.close_event.emit();
    }
}

@Component({
    selector: 'flowchart-stream-form',
    templateUrl: 'edit_stream_form.html',
    encapsulation: ViewEncapsulation.None,
})

export class FlowchartStreamFormComponent {

    @Input() flowchart: any;
    @Input() component: any;
    @Input() show_hints: boolean;
    @Input() dialog: boolean;

    @Output() save_event = new EventEmitter();
    @Output() close_event = new EventEmitter();

    efc: EditFlowchartComponent;
    hint: string;
    showing_hints: any;

    constructor(private plantData: PlantDataService,
                private seriesData: SeriesDataService, private api: ApiService, private snackBar: MatSnackBar,
                private http: HttpClient) {
    }

    ngOnInit(): void {
        this.efc = new EditFlowchartComponent(this.flowchart, this.component, this.plantData, this.seriesData, this.api, this.snackBar,
            this.http);
        this.efc.loadForm();
    }

    save() {
        if (this.efc.save()) {
            this.save_event.emit(this.efc.component_view);
        }
    }

    close(): void {
        this.close_event.emit();
    }
}

@Component({
    selector: 'flowchart-equipment-form',
    templateUrl: 'edit_equipment_form.html',
    encapsulation: ViewEncapsulation.None,
})

export class FlowchartEquipmentFormComponent {

    @Input() flowchart: any;
    @Input() component: any;
    @Input() show_hints: boolean;
    @Input() dialog: boolean;

    @Output() save_event = new EventEmitter();
    @Output() close_event = new EventEmitter();

    efc: EditFlowchartComponent;
    hint: string;
    showing_hints: any;

    constructor(private plantData: PlantDataService,
                private seriesData: SeriesDataService, private api: ApiService, private snackBar: MatSnackBar,
                private http: HttpClient) {
    }

    ngOnInit(): void {
        this.efc = new EditFlowchartComponent(this.flowchart, this.component, this.plantData, this.seriesData, this.api, this.snackBar,
            this.http);
        this.efc.loadForm();
    }

    save() {
        if (this.efc.save()) {
            this.save_event.emit(this.efc.component_view);
        }
    }

    close(): void {
        this.close_event.emit();
    }
}

//     $scope.selectedItem = null;
//
//     $scope.setTemplate = function () {
//         console.log(angular.copy($scope.component.relationships.series_components));
//         $http.get('/api/apply_template/' + $scope.component.id).then(function (data){
//             console.log(data);
//             var ret = data.data;
//             if(ret.series_components && ret.series_list){
//                 utils.fill_relations(ret.series_components.map(function(sc){
//                     return sc.data;
//                 }), ret.series_list.map(function(series){
//                     return series.data;
//                 }), 'series');
//                 $scope.component.relationships.series_components.data = ret.series_components.map(function(sc){
//                     return sc.data;
//                 });
//                 //Add to the independant series_component viewmodel and datamodel lists;
//                 console.log($scope.component.relationships.series_components);
//                 ret.series_components.forEach(function(sc){
//                     var exists = $scope.chart.findSeriesComponent(sc.data.id);
//                     if(exists){
//                         exists.data = sc.data;
//                     } else {
//                         $scope.chart.addSeries(sc.data, $scope.chart.parent_process, $scope.chart_component); //chart_process here = the viewmodel of the process
//
//                     }
//
//                 })
//             }
//         }, function(error){
//             console.log(error);
//         })
//     };
//
//
//     // $scope.$on("selection_changed", function (event, chart_component) {
//     //     console.log("changed");
//     //     $scope.chart_component = chart_component;
//     //     $scope.component = $scope.chart_component.data;
//     //     $scope.load();
//     // });
// }
