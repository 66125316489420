/**
 * Created by phillip on 2016/06/18.
 */

import * as utils from '../lib/utils';
import * as Handsontable from "handsontable";
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {SeriesDataService} from "../services/series_data.service";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material";
import {HotInstance} from "../services/hot-instance";

@Component({
    selector: 'seriesSheetView',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class SeriesSheetViewCtrl implements OnInit {
    private hot_anchor: ElementRef;

    @ViewChild('hot_anchor', {static: false}) set setHotAnchor(content: ElementRef) {
        this.hot_anchor = content;
    }

    hot: HotInstance;
    @Input()
    series: any;
    @Input()
    process: any;
    val_coords: any;
    schema: any;
    column_list: any[];
    data: any;
    title: string;
    search: string = '';

    engineering_unit: any[];
    event_type: any[];
    series_type: any[];
    users: any[];
    series_light: any[];

    @Output() refreshInner = new EventEmitter();

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private handsontableGenerator: HandsontableGenerator,
                private seriesData: SeriesDataService,
                private http: HttpClient,
                private snackBar: MatSnackBar) {
        this.hot = new HotInstance();
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.headerData.add_edit = true;

        ctrl.title = 'Series';

        const promises = [];
        if (ctrl.series == null) {
            promises.push(ctrl.api.series.search().toPromise().then(response => {
                ctrl.data = response.data;
            }));
        } else {
            ctrl.data = ctrl.series;
        }
        promises.push(ctrl.api.engineering_unit.search().toPromise().then(response => ctrl.engineering_unit = response.data));
        promises.push(ctrl.api.event_type.search().toPromise().then(response => ctrl.event_type = response.data));
        promises.push(ctrl.api.series_type.search().toPromise().then(response => ctrl.series_type = response.data));
        promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));
        promises.push(ctrl.api.series_light.search().toPromise().then(response => ctrl.series_light = response.data));

        Promise.all(promises).then(ctrl.createTable.bind(ctrl));

        ctrl.buildHeader();
    }

    createTable() {
        const ctrl = this;
        const engUnitsLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.engineering_unit);
        const seriesTypeLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.series_type);
        const seriesLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.series_light);
        const eventTypeLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.event_type);
        const userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);

        ctrl.val_coords = null;
        let validateCallback = () => {
            ctrl.val_coords = ctrl.hot.instance.getSelected()
        };
        //@ts-ignore
        Handsontable.hooks.add('beforeValidate', validateCallback, ctrl.hot.instance);

        const engUnitDataSource = ctrl.handsontableGenerator.genLookupDataSource(engUnitsLookups, 'engineering_unit');

        const calcValidator = (value, callback) => {
            callback(true);
            if (ctrl.val_coords != undefined && ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords[0], 'type') === 'calculation') {
                if (value == undefined) {
                    value = '';
                }
                const series_id = ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords[0], 'id');
                // @ts-ignore
                const engineering_unit_id = engUnitsLookups.valueLookup[ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords[0], engUnitDataSource)];
                ctrl.CheckCalc(encodeURIComponent(value), engineering_unit_id, series_id).then(response => {
                    ctrl.snackBar.open(response.data.msg, undefined, {duration: 3000});
                }, response => {
                    // TODO add onfailure message
                    // $mdToast.show($mdToast.simple()
                    //     .textContent(response.data.msg)
                    //     .hideDelay(0)
                    //     .action('Hide')
                    //     .highlightAction(true)
                    //     .highlightClass('md-warn'));
                });
            }

        };

        const unitValidator = (value, callback) => {
            callback(true);
            if (ctrl.val_coords != undefined) {
                if (ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords[0], 'type') == 'calculation') {
                    if (ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords[0], 'attributes.name_formula') != undefined) {
                        ctrl.CheckCalc(ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords[0], 'attributes.name_formula'),
                            engUnitsLookups.valueLookup[value],
                            ctrl.hot.instance.getDataAtRowProp(ctrl.val_coords[0], 'id')).then(response => {
                            if (response.data.status) {
                                console.log(response.data.msg);
                                ctrl.snackBar.open(response.data.msg, "Hide");
                            } else {
                                console.log(response.data.msg);
                                ctrl.snackBar.open(response.data.msg, "Hide");
                            }
                        })
                    }
                }
            }
        };

        ctrl.schema = ctrl.seriesData.schema;
        ctrl.column_list = [{
            data: 'attributes.name',
            type: 'text',
            title: 'Name'
        }, {
            type: 'dropdown',
            data: 'type',
            title: 'BaseType',
            source: ['series', 'calculation', 'estimate'],
            renderer: baseTypeRenderer
        }, {
            data: 'attributes.description',
            type: 'text',
            title: 'Description',
            width: 300,
        }, {
            data: 'attributes.name_formula',
            type: 'text',
            title: 'Formula',
            validator: calcValidator,
            allowInvalid: true,
            renderer: formulaRenderer,
            width: 300
        }, {
            data: 'attributes.collector_names',
            type: 'text',
            title: 'Collector Names',
            readOnly: true,
            width: 150,
        }, {
            data: 'attributes.alternate_names',
            type: 'text',
            title: 'Alternate Names',
            readOnly: true,
            width: 150,
        }, {
            data: 'attributes.linked_components',
            type: 'text',
            title: 'Linked Components',
            readOnly: true,
            width: 150,
        }, {
            data: 'attributes.specialised_function',
            title: 'Custom Function',
            type: 'checkbox',
        }, {
            data: 'attributes.assumptions',
            title: 'Assumptions',
            type: 'text',
            renderer: formulaRenderer,
        }, {
            data: 'attributes.extra_arguments_string',
            title: 'Extra Arguments',
            width: 200,
        }, {
            data: 'attributes.accumulation',
            title: 'Accumulation',
            type: 'checkbox',
        }, {
            type: 'dropdown',
            data: 'attributes.default_chart',
            source: ctrl.seriesData.chart_types,
            title: 'Chart Type',
        }, {
            type: 'dropdown',
            data: 'attributes.kpi_level',
            source: ctrl.seriesData.KPI_levels,
            title: 'KPI Level',
        }, {
            type: 'dropdown',
            data: 'attributes.aggregation',
            source: ctrl.seriesData.aggregation_types,
            title: 'Aggregation',
        }, {
            type: 'dropdown',
            data: 'attributes.sample_period',
            title: 'Sample Period',
            source: ctrl.seriesData.sample_periods,
        }, {
            data: 'attributes.sample_offset',
            title: 'Sample Offset',
            type: 'numeric',
            format: '0',
        }, {
            data: 'attributes.fill_method',
            title: 'Fill Method',
            type: 'dropdown',
            source: Object.keys(ctrl.seriesData.fill_methods),
        }, {
            data: 'attributes.delete_hihilowlow',
            title: 'Delete Out of Limits',
            type: 'checkbox',
        }, {
            data: engUnitDataSource,
            title: 'Unit',
            type: 'autocomplete',
            trimDropdown: false,
            validator: unitValidator,
            strict: true,
            source: engUnitsLookups.source,
            allowInvalid: true,
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(seriesTypeLookups, 'series_type'),
            title: 'Series Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesTypeLookups.source,
            allowInvalid: true,
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(eventTypeLookups, 'event_type'),
            title: 'Event Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: eventTypeLookups.source,
            allowInvalid: true,
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(seriesLookups, 'weighted_average_series'),
            title: 'Weighted Average',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesLookups.source,
            allowInvalid: true,
        }, {
            data: 'attributes.hihi',
            title: 'Hi Hi',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.hi',
            title: 'Hi',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.lowlow',
            title: 'Low Low',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.low',
            title: 'Low',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.mean',
            title: 'Mean',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.default_value',
            title: 'Default',
            type: 'numeric',
            format: '0.00',
        }, {
            data: 'attributes.budget',
            title: 'Budget',
            type: 'numeric',
            format: '0.00',
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'created_by'),
            readOnly: true,
            title: 'Created By',
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'changed_by'),
            readOnly: true,
            title: 'Changed By',
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }
        ];

        const extraAfterChange = (changes: [number, string | number, any, any][], source: string) => {
            // This will fire after every afterChange event, if there is a noticeable performance hit, it can be limited with
            //      if (!['edit', 'CopyPaste.paste'].includes(source)) return;
            // though a few cases might be missing.
            // ctrl.refreshTable();
        };

        ctrl.hot = ctrl.handsontableGenerator.generateTable({
            'series': ctrl.api.series,
            'calculation': ctrl.api.calculation,
            'estimate': ctrl.api.estimate
        }, ctrl.schema, ctrl.column_list, ctrl.hot, extraAfterChange.bind(ctrl));

        ctrl.hot.settings.contextMenu = {
            callback: utils.gen_row_deletion('id', {
                'series': ctrl.api.series,
                'calculation': ctrl.api.calculation,
                'estimate': ctrl.api.estimate
            }, ctrl.snackBar, ctrl.hot, (key, options) => {
                // let curr_id;
                const hot = ctrl.hot.instance;

                if (!options || options.length === 0) {
                    this.snackBar.open('Error: No option was selected. Please select a row.', 'Hide')
                    return;
                }
                options = options[0];
                if (key === 'delete_row_all') {
                    //TODO MAKE DIALOG
                    if (confirm('Confirm to delete')) {
                        setTimeout(() => {
                            //timeout is used to make sure the menu collapsed before alert is shown
                            const remove_hot_row = curr_id => {
                                hot.alter('remove_row', curr_id);
                                ctrl.refreshTable();
                                // ctrl.hot.instance.render();
                            };
                            let deleted_items = [];
                            const id_map = {};
                            let i = options.start.row;
                            const to_remove_rows = [];
                            for (i; i <= options.end.row; i++) {
                                const curr_id = i;
                                // @ts-ignore
                                const logical_id = hot.getDataAtRowProp(curr_id, 'id');
                                const series = ctrl.data.filter(item => item.id === logical_id)[0];
                                const actual_id = series.id;
                                id_map[actual_id] = curr_id;

                                // @ts-ignore
                                if (curr_id === undefined || curr_id === null || series.id === null) {
                                    remove_hot_row(curr_id);
                                } else {
                                    deleted_items.push(ctrl.http.get('/api/utils/full_series_delete/' + actual_id).toPromise().then(response => {
                                        // @ts-ignore
                                        remove_hot_row(id_map[response.id]);
                                        ctrl.snackBar.open('Deleted ' + actual_id + ' row from server', undefined, {duration: 3000});
                                    }, ret => {
                                        ctrl.snackBar.open('Failed to delete row from server, check dependent objects:' + ret.message, "Hide");
                                        return ret;
                                    }));
                                }
                            }
                            Promise.all(deleted_items).then(responses => {
                                responses.forEach(response => {
                                    if (response && response.error) {
                                        ctrl.snackBar.open('Series not deleted: ' + response.error.message);
                                    } else {
                                        ctrl.snackBar.open('Deleted', undefined, {duration: 3000});
                                    }
                                })
                            })

                        }, 100);
                    }
                }

                if (key == 'edit_series') {
                    const curr_id = options.start.row;
                    const logical_id = hot.getDataAtRowProp(curr_id, 'id');
                    const series = ctrl.data.filter(item => item.id === logical_id)[0];

                    let $series_full = this.api[series.type].getById(series.id).toPromise();
                    $series_full.then(returned => {
                        let series_full = returned.data;
                        const dialogRef = ctrl.seriesData.upsertSeries(ctrl.process, series_full);
                        dialogRef.afterClosed().toPromise().then(response => {
                            console.log('edit_series context menu dialog closed, response = ', response);
                            if (response) {
                                let updated_series;
                                if (response.series) {
                                    updated_series = response.series;
                                } else {
                                    updated_series = response;
                                }

                                Object.keys(series_full).forEach(key => {
                                    series[key] = updated_series[key];
                                });
                                //ctrl.refreshTable()
                                //Prevent error on second edit
                                ctrl.refreshInner.emit();
                            }
                        });
                    })
                }

                if (key == 'upgrade_to_calculation') {
                    //TODO MAKE DIALOG
                    if (confirm('Confirm to upgrade to calculation')) {
                        const curr_id = options.start.row;
                        const logical_id = hot.getDataAtRowProp(curr_id, 'id');
                        const series = ctrl.data.filter(item => item.id === logical_id)[0];
                        console.log('upgrade_to_calculation - series', series);

                        // @ts-ignore
                        ctrl.http.get('/api/utils/upgrade_series_to_calc/' + series.id).toPromise().then(ret => {
                                hot.setDataAtRowProp(curr_id, 'type', 'calculation');
                                hot.setDataAtRowProp(curr_id, 'attributes.name_formula', '0');
                                // @ts-ignore
                                ctrl.snackBar.open('Upgraded ' + series.attributes.name, undefined, {duration: 3000});
                            }, ret => {
                                ctrl.snackBar.open('Failed to upgrade' + ret.message, 'Hide');
                            }
                        );
                    }
                }
                if (key == 'downgrade_to_series') {
                    //    TODO MAKE DIALOG

                    if (confirm('Confirm to downgrade to series')) {
                        const curr_id = options.start.row;
                        const logical_id = hot.getDataAtRowProp(curr_id, 'id');
                        const series = ctrl.data.filter(item => item.id === logical_id)[0];
                        console.log('series at downgrade_to_series', series);

                        ctrl.http.get('/api/utils/downgrade_calc_to_series/' + series.id).toPromise().then(ret => {
                            hot.setDataAtRowProp(curr_id, 'type', 'series');
                            hot.setDataAtRowProp(curr_id, 'attributes.name_formula', '');

                            ctrl.snackBar.open('Downgraded ' + series.attributes.name, undefined, {duration: 3000});
                        }, ret => {
                            ctrl.snackBar.open('Failed to downgrade' + ret.message, "Hide")
                        });
                    }
                }
            }),
            items: {
                "delete_row_all": {
                    name: 'Delete Row and Dependencies Permanently'
                },
                "edit_series": {
                    name: 'Edit Series'
                },
                "upgrade_to_calculation": {
                    name: 'Upgrade to calculation'
                },
                "downgrade_to_series": {
                    name: 'Downgrade to series'
                }
            }
        };
        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.data;

        ctrl.hot.instance = new Handsontable(ctrl.hot_anchor.nativeElement, ctrl.hot.settings);
    };

    /**
     * NOTE: this method not only renders the table, it was an attempt to remove the empty rows that are added at the bottom of HOT after interaction.
     * Removes the extra empty rows from the table (apart from the default 3)
     */
    refreshTable() {
        const ctrl = this;
        ctrl.hot.instance.render();
        ctrl.hot.instance.render();
    }

    CheckCalc(name_formula: string, engineering_unit_id: string, series_id: string): Promise<any> {
        const ctrl = this;
        return ctrl.http.get(
            `/api/CheckCalc?name_formula=${name_formula}&engineering_unit_id=${engineering_unit_id}&series_id=${series_id}`
        ).toPromise()
    }

    save() {
        console.log("saving");
        const ctrl = this;
        let results = ctrl.hot.save();
        Promise.all(results.deferreds).then(() => {

            results.savePromises.forEach(promise => {
                promise.then(response => {
                    if (ctrl.process) this.api.series_component.save({
                        relationships: {
                            component: {
                                data: {
                                    id: ctrl.process.id,
                                    type: 'component'
                                }
                            }, series: {data: {id: response.data.id, type: 'series'}}
                        }, type: 'series_component'
                    }).then(response => {
                        console.log('Saved the series', response);
                        ctrl.snackBar.open("Successfully saved the series", undefined, {duration: 2000});
                    }, reason => {
                        if (reason && reason.errors && reason.errors.length > 0 && reason.errors[0].detail) {
                            ctrl.snackBar.open(reason.errors[0].detail, "Hide")
                        }
                        console.log('Failed to save series', reason)
                    });
                })
            });
            ctrl.data = results.data;
            ctrl.refreshTable();
        })
    };

    /** Opens a dialog for adding a new series to the table */
    newSeries() {
        const ctrl = this;
        ctrl.seriesData.upsertSeries(ctrl.process).afterClosed().toPromise().then(response => {
            // user closed the dialog box.
            if (response) {
                let updated_series;
                if (response.series) {
                    updated_series = response.series;
                } else {
                    updated_series = response;
                }
                ctrl.data.push(updated_series);
                ctrl.refreshTable();
            } else {
                return;
            }
        }, response => {
            this.snackBar.open("Series not saved: " + response, "Hide");
        });
    };

    download() {
        const ctrl = this;
        ctrl.hot.download();
    };

    buildHeader() {
        const ctrl = this;
        if (ctrl.process) {
            ctrl.headerData.title = 'Series List: ' + ctrl.process.attributes.name;
        } else {
            ctrl.headerData.title = 'Series List';
        }
        let buttons = [
            {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save'},
            {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download'},
            {name: 'Add Series', func: ctrl.newSeries.bind(ctrl), class: 'icon-add'}
        ];
        if (ctrl.series && ctrl.headerData.component_buttons_loaded === false) {
            ctrl.headerData.buttons = ctrl.headerData.buttons.concat(buttons);
            ctrl.headerData.component_buttons_loaded = true;
        } else {
            ctrl.headerData.buttons = buttons;
        }
    };

}

function formulaRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    if (instance.getDataAtRowProp(row, 'attributes.name')) {
        if (instance.getDataAtRowProp(row, 'type') == 'estimate' ||
            instance.getDataAtRowProp(row, 'type') == 'series' ||
            instance.getDataAtRowProp(row, 'type') == null) {
            td.style.background = '#EEE';
            cellProperties.readOnly = true;
        } else {
            cellProperties.readOnly = false;
        }
    } else {
        td.style.background = '#EEE';
        cellProperties.readOnly = true;
    }
}

function baseTypeRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    if (instance.getDataAtRowProp(row, 'attributes.name')) {
        cellProperties.readOnly = false;
    } else {
        td.style.background = '#EEE';
        cellProperties.readOnly = true;
    }
}
