import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "../user_control/login.component";
import {AuditPivot} from "../reports/audit_pivot.component";
import {CalculationCheckTableComponent} from "../tables/calculation-check-table.component";
import {UserListComponent} from "../user_control/user_list.component";
import {EngineeringUnitView} from "../handson_sheets/engineering_unit_sheet_view";
import {RegisterUserView} from "../user_control/register.component";
import {CollectorEventsTableComponent} from "../tables/collector-events-table/collector-events-table.component";
import {QuickChartsComponent} from "../charts/quick-charts.component";
import {StreamSheetFocusViewCtrl} from "../handson_sheets/stream_sheet_focus_view";
import {CollectorMapperSheetView} from "../handson_sheets/collector_mapper_sheet_view";
import {EquipmentSheetFocusViewCtrl} from "../handson_sheets/equipment_sheet_focus_view";
import {PageViewComponent} from "../page-view/page-view.component";
import {ShiftSheetViewCtrl} from "../handson_sheets/shift_sheet_view";
import {StockpileSheetViewCtrl} from "../handson_sheets/stockpile_sheet_view";
import {SeriesComponentSheetFocusViewCtrl} from "../handson_sheets/series_component_sheet_focus_view";
import {SeriesTableFocusViewComponent} from "../tables/series-table-focus_view.component";
import {CustomProcessViewCtrl} from "../handson_sheets/custom_process_sheet_view";
import {ProcessSheetFocusViewCtrl} from "../handson_sheets/process_sheet_focus_view";
import {EstimateSheetViewCtrl} from "../handson_sheets/estimate_sheet_view";
import {LogSheetFocusViewComponent} from "../data-sheets/log-sheet-focus-view.component";
import {ResourceSheetViewCtrl} from "../handson_sheets/resource_sheet_view";
import {ProcessAccessSheetViewCtrl} from "../handson_sheets/process_access_sheet_view";
import {SeriesSheetFocusViewCtrl} from "../handson_sheets/series_sheet_focus_view";
import {SeriesSheetViewCtrl} from "../handson_sheets/series_sheet_view";
import {flowchartFocusViewCtrl} from "../flowchart/flowchart_focus_view";
import {WaterBalanceComponent} from "../reports/water-balance-view.component";
import {EstimateDataSheetViewComponent} from "../data-sheets/estimate-data-sheet-view.component";
import {WelcomeComponent} from "../user_control/welcome.component";
import {InputDataSheetFocusViewComponent} from "../data-sheets/input-data-sheet-focus-view.component";
import {ProcessSheetViewCtrl} from "../handson_sheets/process_sheet_view";
import {SeriesComponentSheetViewCtrl} from "../handson_sheets/series_component_sheet_view";
import {EventPivotComponent} from "../events/event-pivot.component";
import {EquipmentSheetViewCtrl} from "../handson_sheets/equipment_sheet_view";
import {ResetPasswordComponent} from "../user_control/reset-password.component";
import {MySecurityComponent} from "../user_control/my-security.component";
import {StreamSheetViewCtrl} from "../handson_sheets/stream_sheet_view";
import {EstimateDataSheetFocusViewComponent} from "../data-sheets/estimate-data-sheet-focus-view.component";
import {ValueDriverTreeComponent} from "../components/value-driver-tree/value-driver-tree.component";
import {RecoveryGradeForecastComponent} from "../forecasts/recovery-grade-forecast/recovery-grade-forecast.component";
import {OreForecastViewComponent} from "../forecasts/ore_forecast_view.component";
import {PageListViewComponent} from "../page-view/page-list-view/page-list-view.component";
import {AuditHistoryComponent} from "../handson_sheets/audit-history/audit-history.component";
import {HelpPageComponent} from "../components/help-page/help-page.component";
import {ExceptionOnDemandComponent} from "../debugging/exception-on-demand/exception-on-demand.component";

const routes: Routes = [
    {path: 'view/welcome', pathMatch: 'full', component: WelcomeComponent},
    {path: 'view/login', pathMatch: 'full', component: LoginComponent},
    {path: 'view/audit_pivot', pathMatch: 'full', component: AuditPivot},
    {path: 'view/calculation_check', pathMatch: 'full', component: CalculationCheckTableComponent},
    {path: 'view/engineering_unit', pathMatch: 'full', component: EngineeringUnitView},
    {path: 'view/user_list', pathMatch: 'full', component: UserListComponent},
    {path: 'view/register_user', pathMatch: 'full', component: RegisterUserView},
    {path: 'view/quick_charts', pathMatch: 'full', component: QuickChartsComponent},
    {path: 'view/collection_events', pathMatch: 'full', component: CollectorEventsTableComponent},
    {path: 'view/collector_mapper_sheet', pathMatch: 'full', component: CollectorMapperSheetView},
    {path: 'view/shift_sheet', pathMatch: 'full', component: ShiftSheetViewCtrl},
    {path: 'view/stockpile_sheet', pathMatch: 'full', component: StockpileSheetViewCtrl},
    {path: 'view/edit_series_components', pathMatch: 'full', component: SeriesComponentSheetViewCtrl},
    {path: 'view/event_pivot', pathMatch: 'full', component: EventPivotComponent},
    {path: 'view/edit_process', pathMatch: 'full', component: ProcessSheetViewCtrl},
    {path: 'view/estimate_sheet', pathMatch: 'full', component: EstimateSheetViewCtrl},
    {path: 'view/resource_sheet', pathMatch: 'full', component: ResourceSheetViewCtrl},
    {path: 'view/process_access_sheet', pathMatch: 'full', component: ProcessAccessSheetViewCtrl},
    {path: 'view/edit_series', pathMatch: 'full', component: SeriesSheetViewCtrl},
    {path: 'view/water_balance', pathMatch: 'full', component: WaterBalanceComponent},
    {path: 'view/estimate_data_sheet', pathMatch: 'full', component: EstimateDataSheetViewComponent},
    {path: 'view/equipment_sheet', pathMatch: 'full', component: EquipmentSheetViewCtrl},
    {path: 'view/my_security', pathMatch: 'full', component: MySecurityComponent},
    {path: 'view/reset_password', pathMatch: 'full', component: ResetPasswordComponent},
    {path: 'view/edit_streams', pathMatch: 'full', component: StreamSheetViewCtrl},
    {path: 'view/value_driver_trees', pathMatch: 'full', component: ValueDriverTreeComponent},
    {path: 'view/recovery_grade_forecast', pathMatch: 'full', component: RecoveryGradeForecastComponent},
    {path: 'view/audit_history', pathMatch: 'full', component: AuditHistoryComponent},
    {path: 'view/help-page', pathMatch: 'full', component: HelpPageComponent},
    // {path: 'view/calculation_trees', pathMatch: 'full', component: CalculationTreeComponent},

    {path: 'view/page_view/:sessionID', pathMatch: 'full', component: PageViewComponent},
    {path: 'view/page_list_view', pathMatch: 'full', component: PageListViewComponent},
    {path: 'view/ore_forecast', pathMatch: 'full', component: OreForecastViewComponent},

    //TODO this is not referenced
    // {path: 'view/custom_equipment_sheet/:category', pathMatch: 'full', component: CustomEquipmentViewCtrl},
    {path: 'view/custom_process_sheet/:category', pathMatch: 'full', component: CustomProcessViewCtrl},

    {path: 'view/series_table/:processID', pathMatch: 'full', component: SeriesTableFocusViewComponent},
    {path: 'view/log_sheet/:processID', pathMatch: 'full', component: LogSheetFocusViewComponent},
    {path: 'view/flowchart/:processID', pathMatch: 'full', component: flowchartFocusViewCtrl},
    {path: 'view/input_data_sheet/:processID', pathMatch: 'full', component: InputDataSheetFocusViewComponent},
    {path: 'view/edit_series_components/:processID', pathMatch: 'full', component: SeriesComponentSheetFocusViewCtrl},
    {path: 'view/edit_series/:processID', pathMatch: 'full', component: SeriesSheetFocusViewCtrl},
    {path: 'view/edit_sub_process/:processID', pathMatch: 'full', component: ProcessSheetFocusViewCtrl},
    {path: 'view/edit_stream/:processID', pathMatch: 'full', component: StreamSheetFocusViewCtrl},
    {path: 'view/estimate_data_sheet/:processID', pathMatch: 'full', component: EstimateDataSheetFocusViewComponent},

    //TODO this is not referenced
    // {path: 'view/edit_schedule/:processID', pathMatch: 'full', component: ScheduleSheetFocusViewCtrl },
    {path: 'view/equipment_sheet/:processID', pathMatch: 'full', component: EquipmentSheetFocusViewCtrl},
    {path: 'view/on_demand_exception', pathMatch: 'full', component: ExceptionOnDemandComponent}
];

const routerOptions: ExtraOptions = {
    onSameUrlNavigation: "reload"
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
