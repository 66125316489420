import * as utils from '../lib/utils';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit,
    ViewEncapsulation
} from "@angular/core";
import {ApiService} from "../services/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {PlantDataService} from "../services/plant_data.service";
import {AppScope} from "../services/app_scope.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {MatDialog, MatDialogConfig, MatDialogRef, MatSnackBar, MatSnackBarRef, SimpleSnackBar} from "@angular/material";
import {EstimateDialogData, EstimateFormComponent} from "../forms/estimate-form.component";
import {SeriesDataService} from "../services/series_data.service";

// TODO finish migration of this component or remove it
@Component({
    selector: 'estimate-data-sheet-focus-view',
    templateUrl: 'estimate-data-sheet-focus-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstimateDataSheetFocusViewComponent implements OnInit, OnDestroy {
    input_sheet_ready: boolean;
    estimate_series: { attributes: any, id: string, relationships: any, type: string }[];
    estimate_types_list: { attributes: any, id: string, relationships: any, type: string }[];
    forecast_year: number;
    kpis: string[];
    start: Date;
    end: Date;
    sample_period: number;
    series_list: {
        attributes: {
            description: string,
            event_type_name?: string,
            kpi_level: string,
            name: string
        }, id: string, type: string
    }[];
    permissions: any;
    selected_estimate: { attributes: any, id: string, relationships: any, type: string };
    selected_kpis: string[];
    flowsheet: any;
    process: any;
    params_subscription: Subscription;
    private loading_snackbar: MatSnackBarRef<SimpleSnackBar>;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private plantData: PlantDataService,
                private appScope: AppScope,
                private route: ActivatedRoute,
                private snackBar: MatSnackBar,
                private dialog: MatDialog,
                private changeDetectorRef: ChangeDetectorRef,
                private seriesData: SeriesDataService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        this.params_subscription = ctrl.route.params.subscribe(params => {
            if (this.loading_snackbar) {
                this.loading_snackbar.dismiss();
                this.loading_snackbar = null;
            }
            this.loading_snackbar = ctrl.snackBar.open('Loading estimates');
            ctrl.headerData.title = 'Estimates Input';
            ctrl.changeDetectorRef.markForCheck();

            const processID = params.processID;

            ctrl.forecast_year = new Date().getFullYear();
            ctrl.kpis = ['Level 1', 'Level 2', 'Level 3', 'Level 4', '', null];
            ctrl.selected_kpis = ['Level 1', 'Level 2'];

            ctrl.start = new Date();
            ctrl.start = utils.setToHour(ctrl.start, 0);
            ctrl.end = new Date();
            ctrl.end = utils.setToHour(ctrl.end, 23);
            ctrl.sample_period = 2;

            Promise.all([ctrl.appScope.auth_complete.promise,]).then(() => {
                const isoPermissions = ctrl.plantData.getIsolatedPermissions(processID);
                ctrl.flowsheet = ctrl.plantData.getFlowSheetData(processID);
                const promises = [];
                promises.push(ctrl.api.estimate_type.search().toPromise().then(response => {
                    if (!response) return;
                    ctrl.estimate_types_list = response.data
                }));
                // promises.push(isoPermissions.access.$promise);
                promises.push(ctrl.flowsheet.allDataFetched.promise);
                promises.push(isoPermissions.all.promise);

                ctrl.flowsheet.allDataFetched.promise.then(() => {
                    ctrl.process = ctrl.flowsheet.process_focus;
                    ctrl.buildHeader();
                });

                Promise.all(promises).then(() => {

                    // FIXME get request failure, using hack for now
                    ctrl.permissions = isoPermissions.permissions;
                    // ctrl.permissions = {edit_process_data:'yup'};
                    ctrl.series_list = ctrl.flowsheet.selected_series;
                    ctrl.estimate_types_list.forEach(function (estimate) {
                        if (estimate.attributes.name == "Forecast") {
                            ctrl.selected_estimate = estimate;
                        }
                    });
                    ctrl.refreshEstimates();
                })
            });
        });
    }

    ngOnDestroy(): void {
        if (this.params_subscription) {
            this.params_subscription.unsubscribe();
        }
        if (this.loading_snackbar) {
            this.loading_snackbar.dismiss();
        }
    }

    refreshEstimates() {
        const ctrl = this;
        ctrl.input_sheet_ready = false;
        ctrl.changeDetectorRef.markForCheck();

        ctrl.api.estimate.search(ctrl.api.prep_q([{
            and: [{
                name: 'series_id',
                op: 'in',
                val: ctrl.flowsheet.selected_series_ids
            }, {
                name: 'estimate_type_id',
                op: 'eq',
                val: ctrl.selected_estimate.id
            }]
        }])).toPromise().then(response => {
            this.loading_snackbar.dismiss();
            if (!response) return;
            ctrl.estimate_series = response.data;
            if (ctrl.estimate_series.length === 0) {
                ctrl.snackBar.open('No estimates found for process', 'Hide');
                ctrl.changeDetectorRef.markForCheck();
            } else {
                ctrl.refreshDates();

            }
        });
    }

    showEstimateForm() {
        console.log('Opened Estimate Form Dialog here');
        const ctrl = this;
        const dialogConfig = new MatDialogConfig<EstimateDialogData>();

        dialogConfig.minWidth = '640px';

        dialogConfig.data = {
            //estimate_types: ctrl.estimate_types_list,
            selectedEstimateType: ctrl.selected_estimate.attributes.name,
            seriesList: ctrl.series_list,
            estimate: null,
            seriesData: ctrl.seriesData
        };

        let dialogRef: MatDialogRef<EstimateFormComponent, any> = this.dialog.open(EstimateFormComponent, dialogConfig);
        dialogRef.afterClosed().toPromise().then(response => {
            if (response) {
                console.log("%cDialog closed", 'font-size:large', response.data);
                // ctrl.refreshEstimates();
            }
        })
    }

    refreshDates() {
        const ctrl = this;
        ctrl.input_sheet_ready = true;
        ctrl.changeDetectorRef.markForCheck();
        // setTimeout(() => ctrl.input_sheet_ready = true, 300)
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Estimates: ' + ctrl.process.attributes.name;
        ctrl.headerData.buttons = [
            {name: 'Refresh', func: ctrl.refreshEstimates.bind(ctrl), class: 'icon-refresh', params: {}},
            {name: 'Add Estimate', func: ctrl.showEstimateForm.bind(ctrl), class: 'icon-add', params: {}}
        ];
    };

}
