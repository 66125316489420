import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WaterBody} from "../water-balance-view.component";

@Component({
    selector: 'water-balance-detail-view',
    templateUrl: './water-balance-detail-view.component.html',
    styleUrls: ['./water-balance-detail-view.component.less', '../water-balance-view.component.less']
})
export class WaterBalanceDetailViewComponent implements OnInit, AfterViewInit {
    private _row;

    @Input()
    set row(row: WaterBody) {
        this._row = row;
    }

    get row(): WaterBody {
        return this._row;
    }

    @Output()
    DFsUpdated: EventEmitter<{ changed_row, changed_stream, parent_stream? }> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
    }

    updateDFs(changed_row, changed_stream, parent_stream?) {
        // TODO call from the service
    }
}
