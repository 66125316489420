<table (matSortChange)="sortData($event)" *ngIf="summarySorted && summary && summary.length > 0 && statusColumns &&
extraColumns" [dataSource]="summarySorted" class="series-table" mat-table matSort>
  <ng-container *ngFor="let column of statusColumns;" [matColumnDef]="column" sticky>
    <th *matHeaderCellDef [mat-sort-header]="column" class="freeze-pane" mat-header-cell>
      <ng-container *ngIf="column !== 'Status'">{{col_dict[column].title}}</ng-container>
      <ng-container *ngIf="column === 'Status'"><i class="icon-status"></i></ng-container>
    </th>
    <td *matCellDef="let element;" [ngClass]="{'bold': hovering_events.includes(element.ID)}" mat-cell
        style="alignment: left; text-align: left">
      <ng-container *ngIf="column === 'Status'">
        <span class="fa flag flag--status fa-warning"
              [ngClass]="{'warning-none': !element[column] || element[column]==1, 'warning' : element[column]==2, 'error': element[column]==3}">
        </span>
        <!--<i [ngClass]="{'status-warn': element[column] === 2, 'status-action': element[column] === 3}"></i>-->
      </ng-container>
      <div *ngIf="column === 'Name'" class="row center">
        <i (click)="editSeries(element)" class="edit-inline hide-xs"></i>
        <a (click)="openChartDialog(element[column])" [textContent]="element[column]" style="cursor:pointer;"></a>
        <span *ngIf="element['kpi_level'] === 'Level 1'"
              class="kpi">KPI {{(element['kpi_level']).replace('Level', '')}}</span>
      </div>
      <div *ngIf="column === 'Description'" [ngClass]="{'editable':!statusColumns.includes('Name') } "
           class="row center">
        <i (click)="editSeries(element)" *ngIf="!statusColumns.includes('Name')" class="edit-inline hide-xs"></i>
        <a (click)="openChartDialog(element['Name'])" [textContent]="element[column]" style="cursor:pointer;"></a>
        <span *ngIf="!statusColumns.includes('Name') && element['kpi_level'] === 'Level 1'"
              class="kpi">KPI {{(element['kpi_level']).replace('Level', '')}}</span>
      </div>
    </td>
  </ng-container>

    <ng-container *ngFor="let column of extraColumns;" [matColumnDef]="column">
      <ng-container *ngIf="column !== 'Sparkline'">
        <ng-container *ngIf="!customColumns.includes(column)">
          <th *matHeaderCellDef [mat-sort-header]="column" [textContent]="col_dict[column].title"
              [title]="col_dict[column]?.description?col_dict[column]?.description:''" mat-header-cell></th>
        </ng-container>
        <ng-container *ngIf="customColumns.includes(column)">
          <th *matHeaderCellDef [mat-sort-header]="column" [textContent]="column" mat-header-cell></th>
        </ng-container>

        <td *matCellDef="let element;" [ngClass]="{'favourable': element[est_favourability_mapping[column]]===true,
                'unfavourable': element[est_favourability_mapping[column]]===false}" mat-cell
            style="text-align: right; alignment: right;">
          <ng-container *ngIf="!customColumns.includes(column)">
            <ng-container
                *ngIf="column.toString().includes('%')">{{element[column] | percent:'1.2-2' | significantNumbers:significant_numbers}}</ng-container>
            <ng-container
                *ngIf="!column.toString().includes('%')">{{element[column] | significantNumbers:significant_numbers}}</ng-container>
          </ng-container>
          <ng-container *ngIf="customColumns.includes(column)">
            <input (click)="$event.stopPropagation();" *ngIf="editing"
                   [(ngModel)]="config.column_text[column][element['Name']]">
            <div *ngIf="!editing">
              {{config.column_text[column][element['Name']]}}
            </div>
          </ng-container>
        </td>
      </ng-container>

      <ng-container *ngIf="column == 'Sparkline'">
        <th *matHeaderCellDef [mat-sort-header]="column" [textContent]="col_dict[column].title" mat-header-cell></th>
        <td *matCellDef="let element;" mat-cell>
          <sparkline [data_points]="element.Sparkline" [series_name]="element[column]"></sparkline>
        </td>
      </ng-container>

    </ng-container>

  <ng-container matColumnDef="Comments">
    <th *matHeaderCellDef mat-header-cell>
      <div><i class="icon-comment-invert" style="padding-top:4px;"></i></div>
    </th>
    <td *matCellDef="let element;" mat-cell style="text-align: center;">
      <div (mouseenter)="commentIconHover(series_events[element.ID])" (mouseleave)="tileData.commentIconLeave.emit()"
           *ngIf="series_events[element.ID]" style="padding:2px 4px 2px 2px;">
        <i (click)="toggleComments(true)" class="icon-inline-comment"></i>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="star" stickyEnd>
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let element;" mat-cell>
      <mat-icon>more_vert</mat-icon>
    </td>
  </ng-container>

  <ng-container *ngIf="appScope.isNotMobile">
    <ng-container *ngIf="config.show_comments===true">
      <tr *matHeaderRowDef="config.columns.concat('Comments'); sticky: true" mat-header-row></tr>
      <tr (focus)="setComment($event, row, null)" *matRowDef="let row; columns: config.columns.concat('Comments');"
          mat-row
          tabindex="0"></tr>
    </ng-container>
    <ng-container *ngIf="config.show_comments!==true">
      <tr *matHeaderRowDef="config.columns; sticky: true" mat-header-row></tr>
      <tr (focus)="setComment($event, row, null)" *matRowDef="let row; columns: config.columns;" mat-row
          tabindex="0"></tr>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!appScope.isNotMobile">
    <tr *matHeaderRowDef="config.mobile_columns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: config.mobile_columns;" mat-row></tr>
  </ng-container>
</table>
<div *ngIf="!config.columns || config.columns.length <= 0">
  <!--TODO find another solution to providing feedback to user-->
  No columns have been set
</div>
