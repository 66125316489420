import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {ApiService} from "../services/api.service";
import {TileDataService} from "../services/tile_data.service";
import * as utils from "../lib/utils"
import {HeaderDataService} from "../services/header_data.service";
import {SeriesDataService} from "../services/series_data.service";
import {MatTabChangeEvent} from "@angular/material";
import {AppScope} from "../services/app_scope.service";
import {MenuTreeService} from "../services/menu-tree.service";
import {DateTimePeriod, DateTimePeriodService} from "../services/datetime_period.service";

export interface DialogData {
    form_result: string;
    name: string;
    title: string;
    tile: any;
    dtp: DateTimePeriod;
}

@Component({
    selector: 'TileForm',
    templateUrl: 'tile_form.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [DateTimePeriodService]
})

export class TileFormComponent implements OnInit {
    public tile: any;
    public show: boolean;
    public showing_hints: boolean = false;
    public hint: string = 'Name';
    public json: any;
    public title: string;

    public name_description_map: any = {};
    public selected_series: any[] = [];

    descriptionFunc = (name) => this.name_description_map[name];
    name_list: any = [];
    processes: any;
    normalCols: any = this.seriesData.columnsDetail;

    selected_process: any = [];
    event_types: any;
    tile_categories: any[];

    constructor(private api: ApiService,
                protected seriesData: SeriesDataService,
                private dialogRef: MatDialogRef<TileFormComponent>,
                private headerData: HeaderDataService,
                @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
                public tileData: TileDataService,
                private menuService: MenuTreeService,
                public appScope: AppScope,
                private dateTimePeriodService: DateTimePeriodService,) {
    }

    ngOnInit(): void {
        const ctrl = this;

        this.tile = this.dialogData.tile;
        if (this.tile) {
            this.tile = utils.deepCopy(this.tile);
        }
        this.show = false;
        this.title = this.dialogData.title;
        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            ctrl.dateTimePeriodService.dtp = utils.deepCopy(ctrl.dialogData.dtp);
        });

        this.appScope.auth_complete.promise.then(() => {
            ctrl.tile_categories = ctrl.appScope.config_name_map['tile_categories'] ? ctrl.appScope.config_name_map['tile_categories'].value : [];
        });
        if (!this.tile) {
            this.tile = ctrl.tileData.getEmptyTile();
        }
        ctrl.json = utils.deepCopy(this.tile);

        ctrl.api.series_light.search().toPromise().then((response) => {
            const full_series_list = response.data;
            full_series_list.map((series) => {
                ctrl.name_list.push(series.attributes.name);
                ctrl.name_description_map[series.attributes.name] = series.attributes.name + '-' + series.attributes.description

            })
        });

        ctrl.api.event_type.search().toPromise().then(response => ctrl.event_types = response.data);

        if (ctrl.processes == null) {
            ctrl.api.process_light.search().toPromise().then(response => ctrl.processes = response.data);
        }

        if (ctrl.json.content === 'series-table') {
            ctrl.selected_process = ctrl.json.parameters.process;
            ctrl.selected_series = ctrl.json.parameters.series_list;
        }
    }

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    };

    updateJson(event) {
        const ctrl = this;
        try {
            this.json = JSON.parse(event);
            // this.tile = utils.deepCopy(this.json); why are we deep copying this.json to this.tile during an update to the json?

        } catch (e) {
        }
        // this.json = JSON.parse(event);
    }

    contentChange() {
        const ctrl = this;
        ctrl.json.parameters = {};
        switch (ctrl.json.content) {
            case 'budget-bar-chart':
                ctrl.json.parameters = {
                    // "estimate_type": null,
                    // "series": null
                };
                ctrl.json.class = 'third';
                break;
            case 'category-chart':
                ctrl.json.parameters = {
                    labels: {
                        title: '',
                        sub_title: '',
                        y_axis: '',
                        x_axis: '',
                    },
                    series_list: [{
                        name: null,
                        series_id: null,
                        type: 'category',
                        sample_period: null,
                        number_of_periods: null,
                    }]
                };
                ctrl.json.class = 'third';
                break;
            case 'custom-chart':
                ctrl.json.parameters = {
                    labels: {
                        title: null,
                        sub_title: null,
                        y_axis: null,
                        y2_axis: null

                    },
                    hide_comments: false,
                    series_list: [{
                        axis: 'y',
                        colour: null,
                        cumulative: null,
                        name: null,
                        show_limits: false,
                        type: 'line',
                        range: null,
                        custom_legend_name: undefined
                    }]
                };
                ctrl.json.class = 'third';
                break;
            case 'comparison-chart':
                ctrl.json.parameters = {
                    labels: {
                        title: null,
                        sub_title: null
                    },
                    hide_comments: true,
                    colour: null,
                    type: 'scatter',
                    chart_type: 'comparison',
                    series_list: [{
                        axis: 'x',
                        name: null
                    }, {
                        axis: 'y',
                        name: null
                    }],
                    statistics: {
                        averages: true,
                        std_dev: true,
                        pooled: true,
                        correlation_coeff: true
                    },
                    diagonal_line: true,
                    regression_line: 'linear'
                };
                break;
            case 'custom-events':
                ctrl.json.parameters = {
                    estimate_type: "Forecast",
                    non_editable_columns: [],
                    column_order: this.seriesData.event_columns,
                    event_type_names: null
                };
                ctrl.json.class = 'full';
                break;
            case 'default-chart':
                ctrl.json.parameters = {};
                ctrl.json.class = 'third';
                break;
            case 'events':
                ctrl.json.parameters = {
                    estimate_type: "Forecast",
                    title: "Events"
                };
                break;
            case 'input-sheet':
                ctrl.json.parameters = {
                    "process": null
                };
                ctrl.json.class = 'full';
                break;
            case 'log-sheet':
                ctrl.json.class = 'full';
                break;
            case 'paragraph':
                ctrl.json.show_header = false;
                ctrl.json.parameters = {};
                ctrl.json.class = 'third';
                break;
            case 'pivot-tile':
                ctrl.json.parameters = {
                    event_types: "",
                    pivot_state: {},
                    series_list: [],
                };
                ctrl.json.class = 'full';
                break;
            case 'series-table':
                ctrl.json.parameters = {
                    series_list: [{
                        attributes: {
                            description: null,
                            event_type_name: null,
                            kpi_level: null,
                            name: null
                        },
                        "id": null,
                        "type": null
                    }],

                    process: {
                        attributes: {
                            description: "",
                            name: ""
                        },
                        id: "",
                        type: "process"
                    },

                    kpis: [],
                    normal_columns: ctrl.seriesData.columnsDefault,

                    detail_columns: [],

                    mobile_columns: ctrl.seriesData.columnsSmall,

                    column_text: {},
                    estimate_type: "Forecast",
                };
                ctrl.json.class = 'full';
                break;
            case 'weather-widget':
                ctrl.json.parameters = {
                    title: 'Weather',
                };
                ctrl.json.class = 'third';
                break;
            default:
                ctrl.json.parameters = this.tileData.getEmptyTile().parameters;
                ctrl.json.class = 'third';
        }
    }

    save() {
        this.json.dtp = this.dateTimePeriodService.dtp;
        this.dialogRef.close(this.json);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    tabChanged(event: MatTabChangeEvent) {
        switch (event.index) {
            case 0:
                //  try {
                //     JSON.parse(this.json);
                //  } catch (e) {
                //  }
                // this.tile = utils.deepCopy(this.json);
                break;
            case 1:
                this.json = utils.deepCopy(this.json);
                break;
            default:
                break;
        }
    }
}
