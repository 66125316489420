import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as utils from "../../../lib/utils";
import {DateTimePeriodService} from "../../../services/datetime_period.service";
import {ApiService} from "../../../services/api.service";
import {ChartSeriesConfiguration} from "../../../charts/chart-config/chart-series.configuration";

@Component({
    selector: 'custom-chart-form-series-table',
    templateUrl: './custom-chart-form-series-table.component.html',
    styleUrls: ['./custom-chart-form-series-table.component.less']
})
export class CustomChartFormSeriesTableComponent implements OnInit {

    @Input() series_list: ChartSeriesConfiguration[];
    @Output() series_listChange = new EventEmitter<ChartSeriesConfiguration[]>();

    @Input() chart_type: string;
    @Output() chart_typeChange = new EventEmitter<string>();

    expanded: Array<boolean>;

    sample_periods: any[];
    ranges: any[];
    public hint: string;

    public readonly axes = ['y', 'y2'];
    public readonly chart_types = ['bar', 'line', 'area', 'budget', 'pie', 'gauge', 'donut'];

    series_light_full: any = [];
    public name_description_map: any = {};
    public name_list: any[] = [];

    constructor(private api: ApiService,
                private dateTimePeriodService: DateTimePeriodService) {
    }

    matSelectCompare(option, value): boolean {
        if (value) {
            return option.name === value.name;
        }
    };

    descriptionFunc = (name) => {
        return this.name_description_map[name];
    };

    ngOnInit() {
        const ctrl = this;

        this.expanded = new Array(50).fill(false);

        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            ctrl.sample_periods = utils.deepCopy(ctrl.dateTimePeriodService.sample_periods);
            ctrl.sample_periods = ctrl.sample_periods.filter(period => {
                return ["day", "month", "hour", "week"].includes(period.name)
            });

            ctrl.ranges = utils.deepCopy(ctrl.dateTimePeriodService.ranges);
        });

        ctrl.api.series_light.search().toPromise().then((response) => {

            ctrl.series_light_full = response.data;

            response.data.map((series) => {
                ctrl.name_list.push(series.attributes.name);

                ctrl.name_description_map[series.attributes.name] = series.attributes.name + '-' + series.attributes.description

            })
        });
    }

    getValue(name: string) {
        return this.series_light_full.find(series => series.attributes.name == name)
    }

    addNameToConfig(series_config, event) {
        series_config.name = event.value.attributes.name
    }

    removeRow(index) {
        const ctrl = this;
        let removed = ctrl.series_list.splice(index, 1);

        ctrl.series_listChange.emit(ctrl.series_list)
    }

    changeRange(event, config_i, series_config) {
        let ctrl = this;
        console.log('CustomChart - changeRange [before]: ', event);
        ctrl.series_list[config_i].sample_period = utils.deepCopy(ctrl.dateTimePeriodService.range_dict[event.value].sample_period);
        series_config.sample_period = utils.deepCopy(ctrl.dateTimePeriodService.range_dict[event.value].sample_period);
        console.log('CustomChart - changeRange [after]: ', ctrl.series_list[config_i]);

        ctrl.series_listChange.emit(ctrl.series_list)
    }

    checkPeriod(event, config_i) {
        console.warn("checkPeriod not implemented");
        console.log('CustomChart - checkPeriod: ', event, config_i);
    }

    addChartSeries() {
        const ctrl = this;

        const add_config: ChartSeriesConfiguration = {
            axis: "y",
            colour: null,
            cumulative: false,
            name: null,
            show_limits: false,
            type: "line",
            line_type: 'solid',
            line_thickness: 'normal',
            range: null,
            sample_period: null,
        };

        ctrl.series_list.push(add_config);

        ctrl.series_listChange.emit(ctrl.series_list)
    }

    updateChartType(event) {
        //Updates all series chart types if event shows one was set to gauge, donut or pie
        const ctrl = this;
        ctrl.chart_type = 'line';
        if (['gauge', 'donut', 'pie'].includes(event.value)) {
            ctrl.series_list.map(series => {
                series.type = event.value;
            });
            ctrl.chart_type = event.value;
        }
        console.log('CustomChart - checkChartType: ', event);

        ctrl.series_listChange.emit(ctrl.series_list)
    }
}
