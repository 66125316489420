import {Component, OnDestroy, OnInit} from '@angular/core';
import {Account, AccountService} from "../../services/account.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import * as _ from 'lodash';

@Component({
    selector: 'account-picker',
    templateUrl: './account-picker.component.html',
    styleUrls: ['./account-picker.component.less']
})
export class AccountPickerComponent implements OnInit, OnDestroy {
    public accounts: Account[] = [];
    public selected_account_id: string;
    placeholder: string = 'Loading accounts...';
    private onDestroy: Subject<void> = new Subject();

    constructor(private accountPickerService: AccountService,
                private snackBar: MatSnackBar,
                private api: ApiService,
                private router: Router) {
    }

    ngOnInit() {
        this.accountPickerService.accountsChanged
            .pipe(takeUntil(this.onDestroy))
            .subscribe(response => {
                this.placeholder = 'Select an account';
                let accounts = response;
                const account_parts = _.partition(accounts, a => !a.view_only);
                accounts = account_parts[0].concat(account_parts[1]);

                this.accounts = accounts;
            });
        this.accountPickerService.activeAccountChanged
            .pipe(takeUntil(this.onDestroy))
            .subscribe(response => {
                this.selected_account_id = response.account_id;
            })
    }

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }

    changeAccount(account_id: string) {
        const ctrl = this;

        this.accountPickerService.setActiveAccount(account_id).pipe(takeUntil(this.onDestroy)).subscribe(response => {
            this.snackBar.open(response.message, 'Hide');

            // This link the changing of active account to also switch to the default dashboard. We might want to
            // decouple this in the future to be two separate user actions.
            this.api.session_state_light.search().subscribe(response => {
                const session_states = response.data;
                const default_session_state = session_states.find(s => {
                    return s.relationships.account.data.id == account_id && !!s.attributes.is_default;
                });
                if (default_session_state) {
                    ctrl.router.navigateByUrl('view/page_view/' + default_session_state.id);
                } else {
                    ctrl.router.navigateByUrl('view/welcome');
                }
            });

        }, error => {
            if (error && error.error && error.error.message) {
                this.snackBar.open(error.error.message, 'Hide');
            } else {
                console.log('Error selecting account', error);
                this.snackBar.open('Error selecting account', 'Hide');

            }
        })
    }
}
