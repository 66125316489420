<div class="page traveler">

  <div class="row justify-center center quick-charts" style="padding:0 4%;">

    <mat-form-field *ngIf="ready" style="width:200px;">
      <mat-label>Chart Type</mat-label>
      <ng-container>
        <mat-select [(ngModel)]="chart_type" (selectionChange)="getConfig()">
          <mat-option *ngFor="let type of chart_types" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </ng-container>
    </mat-form-field>

    <div class="select-search" style="flex-grow:2;margin-left:20px;margin-right: 20px;position: relative;">
      <select-many-search *ngIf="full_series_list.length>0"
                          [(value)]="selected_series"
                          [data]="full_series_list"
                          [classes]="'fill'"
                          (selectChange)="getConfig()"
                          label="Select Series">

      </select-many-search>
    </div>

    <div (cdkDropListDropped)="drop($event)" *ngIf="ready" cdkDropList class="drop-list">
      <ng-container *ngIf="selected_series">
        <div class="drop-box" *ngFor="let option of selected_series, let i = index"
             cdkDrag>{{option.attributes|nameOrDescription}}
          <i class="fa fa-times" (click)="removeSeries(option)" style="cursor: pointer"></i>
        </div>
      </ng-container>
    </div>

  </div>


  <div class="column">


    <ng-container *ngIf="chart_type==='Default'">
      <div *ngFor="let series of selected_series; let j = index;" style="height:320px;">
        <page-tile
            [dtp]="dtp.dtp"
            [id]="'page-tile0' + '.' + j"
            [tile]="{content:'default-chart', parameters: {series:series}}"
            class="page-tile no-format">
        </page-tile>
      </div>
    </ng-container>


    <ng-container *ngIf="chart_type==='SPC'">

      <div *ngFor="let series of selected_series; let j = index;">
        <page-tile
            [dtp]="dtp.dtp"
            [id]="'page-tile0' + '.' + j"
            [tile]="{content:'spc-chart', parameters: {series:series}}"
            class="page-tile no-format">
        </page-tile>
      </div>

    </ng-container>

    <ng-container *ngIf="chart_type==='Budget Bar'">

      <div *ngFor="let series of selected_series; let j = index;">
        <h2>{{series.attributes|nameOrDescription}}</h2>
        <page-tile
            [dtp]="dtp.dtp"
            [id]="'page-tile0' + '.' + j"
            [tile]="{content:'budget-bar-chart', parameters: {series:series}}"
            class="page-tile no-format">
        </page-tile>
      </div>
    </ng-container>

    <ng-container *ngIf="['Line','Bar','Area'].includes(chart_type)">
      <div *ngFor="let config of configs; let j = index;">
        <page-tile
            [dtp]="dtp.dtp"
            [id]="'page-tile0' + '.' + j"
            [tile]="{content:'custom-chart', parameters: config}"
            class="page-tile no-format">
        </page-tile>
      </div>
    </ng-container>
    <ng-container *ngIf="['Multi Line','Multi Area','Multi Bar', 'Pie'].includes(chart_type)">
      <div *ngFor="let config of [configs];"><!--to force refresh-->
        <page-tile
            [dtp]="dtp.dtp"
            [id]="'page-tile0.0'"
            [tile]="{content:'custom-chart', parameters: config}"
            class="page-tile no-format">
        </page-tile>
      </div>
    </ng-container>

  </div>
</div>