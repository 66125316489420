import * as Handsontable from "handsontable"
import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {HeaderDataService} from "../services/header_data.service";
import {HotInstance} from "../services/hot-instance";

@Component({
    selector: 'shiftSheetView',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class ShiftSheetViewCtrl implements OnInit {
    hot: HotInstance;
    title: string;
    schema: any;
    search: string;
    shifts: any[];
    users: any[];

    process: any;
    column_list: any[];

    constructor(private api: ApiService,
                private handsontableGenerator: HandsontableGenerator,
                private headerData: HeaderDataService) {
    }

    ngOnInit(): void {
        const ctrl = this;
        ctrl.hot = new HotInstance();
        ctrl.title = 'Shift';

        const promises = [];
        //Filter for resource types
        promises.push(ctrl.api.shift.search().toPromise().then(response => ctrl.shifts = response.data));
        promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));

        Promise.all(promises).then(ctrl.createTable.bind(ctrl));

        ctrl.buildHeader();
    }

    createTable() {
        const ctrl = this;

        let userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);

        ctrl.schema = {
            id: null,
            type: 'shift',
            attributes: {
                start: null,
                end: null,
                created_on: null,
                changed_on: null,
                name: null,
                description: null
            },
            relationships: {
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };

        if (ctrl.process !== undefined) {
            ctrl.schema.relationships.process.id = ctrl.process.id;
        }

        ctrl.column_list = [            {
                data: 'attributes.name',
                title: 'Name'
            },            {
                data: 'attributes.description',
                title: 'Description'
            },            {
                data: 'attributes.start',
                title: 'Start'
            },            {
                data: 'attributes.end',
                title: 'End'
            },            {
                data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'created_by'),
                readOnly: true,
                title: 'Created By'
            },            {
                data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'changed_by'),
                readOnly: true,
                title: 'Changed By'
            },            {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];

        ctrl.hot = ctrl.handsontableGenerator.generateTable(ctrl.api.shift, ctrl.schema, ctrl.column_list);
        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.shifts;
        setTimeout(() => ctrl.hot.instance = new Handsontable(document.getElementById(ctrl.hot.handson_id), ctrl.hot.settings), 200);
    }

    save() {
        const ctrl = this;
        let results = ctrl.hot.save();
        ctrl.shifts = results.data;
    };

    download() {
        const ctrl = this;
        ctrl.hot.download();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Shifts';
        ctrl.headerData.buttons = [
            {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save', params: {}},
            {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download', params: {}},
        ];
    }
}
