import {Component, EventEmitter, Input, Output, ViewChild, HostListener} from '@angular/core';
import {
    CdkDrag,
    CdkDragDrop,
    CdkDropList,
    CdkDropListContainer,
    CdkDropListGroup,
    moveItemInArray,
    transferArrayItem
} from "@angular/cdk/drag-drop";
import {Section} from "../services/tile_data.service";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {HeaderDataService} from "../services/header_data.service";
import * as utils from "../lib/utils"

@Component({
    selector: 'src-sortable-grid-list',
    templateUrl: './sortable-grid-list.component.html',
})
export class SortableGridListComponent {
    _sections: Section[];

    public editing_section: boolean[] = [];

    get sections(): Section[] {
        return this._sections
    }

    @ViewChild(CdkDropListGroup, {static: false}) listGroup: CdkDropListGroup<CdkDropList>;
    @ViewChild(CdkDropList, {static: false}) placeholder: CdkDropList;

    @Output() sectionsChange = new EventEmitter();
    @Output() printingSection = new EventEmitter();

    public target: CdkDropList;
    public targetIndex: number;
    public source: CdkDropListContainer;
    public sourceIndex: number;

    constructor(public dateTimePeriodService: DateTimePeriodService,
                public headerData: HeaderDataService) {
        this.target = null;
        this.source = null;
    }

    @Input() editing: boolean;
    @Input() printing_sections: boolean;
    @Input() printing: boolean;

    @Input()
    set sections(sections: Section[]) {
        if (Array.isArray(sections)) {
            this._sections = sections;
            this.initialiseSections();
        }
    }

    printSection(section) {
        this.printingSection.emit(section);
    }

    removeRow(index) {
        const ctrl = this;
        //TODO make this confirm a dialog
        if (confirm("Please confirm before removing this section")) {
            this.editing_section[index] = false;
            this.sections.splice(index, 1);
            this.emitSectionChange(this.sections);
        }
    }

    initialiseSections() {
        this.editing_section = new Array(this.sections.length);
        //Done once instead of during change detection
        this.sections.forEach(section => {
            section.string_height = this.headerData.getSectionHeight(section);
        })
    }

    insertSection(i, position) {
        let new_section = this.headerData.addSection()
        if (position === 'before') {
            this.sections.splice(i, 0, new_section);
        } else {
            this.sections.splice((i + 1), 0, new_section);
            this.editing_section[i] = false;
            this.editing_section[i + 1] = true;
        }

        //Reformat section ids
        this.sections.forEach((sec, index) => {
            sec.id = utils.deepCopy(index);
        })
        this.emitSectionChange(this.sections);
    }

    emitSectionChange(event) {
        this.sectionsChange.emit(this.sections);
    }

    tileChange(event, section) {
        section.tiles[event.index] = event.tile;
        this.sectionsChange.emit(this.sections);
    }

    deleteTile(index, section) {
        section.tiles.splice(index, 1);
        this.sectionsChange.emit(this.sections);
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
        this.sectionsChange.emit(this.sections);
    }

    enter(drag: CdkDrag, drop: CdkDropList) {
        if (drop == this.placeholder)
            return true;

        let phElement = this.placeholder.element.nativeElement;
        let dropElement = drop.element.nativeElement;

        let dragIndex = __indexOf(dropElement.parentNode.children, drag.dropContainer.element.nativeElement);
        let dropIndex = __indexOf(dropElement.parentNode.children, dropElement);

        if (!this.source) {
            this.sourceIndex = dragIndex;
            this.source = drag.dropContainer;

            let sourceElement = this.source.element.nativeElement;
            phElement.style.width = sourceElement.clientWidth + 'px';
            phElement.style.height = sourceElement.clientHeight + 'px';

            sourceElement.parentNode.removeChild(sourceElement);
        }

        this.targetIndex = dropIndex;
        this.target = drop;

        phElement.style.display = '';
        dropElement.parentNode.insertBefore(phElement, (dragIndex < dropIndex)
            ? dropElement.nextSibling : dropElement);

        this.source.start();
        this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);

        return false;
    }
}

function __indexOf(collection, node) {
    return Array.prototype.indexOf.call(collection, node);
}
