import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {SeriesDataService} from "../../services/series_data.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'custom-events-form',
    templateUrl: './custom-events-form.component.html',
})
export class CustomEventsFormComponent implements OnInit, OnChanges, OnDestroy {
    hidden_column_options: string[];

    private _config: any;

    constant_properties: any;
    @Input() content_type: string;

    @Input()
    set config(config: any) {
        this._config = config;
        this.inputChange();
    }

    get config(): any {
        return this._config
    }

    private readonly onDestroy = new Subject<void>();

    private _event_types;
    @Input()
    set event_types(event_types: any[]) {
        this._event_types = event_types;
        const ctrl = this;
        ctrl.inputChange()
    }

    get event_types() {
        return this._event_types;
    }

    selected_types: any[] = [];

    constructor(private api: ApiService,
                protected seriesData: SeriesDataService) {
    }

    ngOnInit() {
        this.hidden_column_options = this.getBaseHiddenColumns();

        this.updateConfig();
    }

    ngOnChanges(changes: SimpleChanges) {
        const ctrl = this;
        if (ctrl.event_types.length > 0) {
            if (ctrl.content_type === 'stockpile-events') {
                ctrl.event_types = ctrl.event_types.filter(event_type => {
                    return event_type.attributes.behaviour === 'stockpile';
                })
            } else {
                ctrl.event_types = ctrl.event_types.filter(event_type => {
                    return event_type.attributes.behaviour !== 'stockpile';
                })
            }
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    updateSelected(single_selected?) {
        const ctrl = this;
        if (single_selected) {
            ctrl.selected_types = [single_selected];
        }
        ctrl.config.event_type_names = ctrl.selected_types.map((type) => {
            return type.attributes.name
        });
    }

    updateConfig() {
        const ctrl = this;

        ctrl.constant_properties = [];

        ctrl.config.column_order = ctrl.seriesData.event_columns;

        if (ctrl.config.event_type_names) {
            ctrl.api.constant_property.search(ctrl.api.prep_q([{
                name: 'event_types',
                op: 'any',
                val: {name: 'name', op: 'in', val: ctrl.config.event_type_names}
            }], {})).pipe(takeUntil(this.onDestroy)).subscribe((response) => {
                ctrl.hidden_column_options = ctrl.getBaseHiddenColumns();

                if (response.data.length >= 0) {
                    ctrl.constant_properties = response.data.map((elem) =>
                        elem.attributes.name
                    );

                    if (ctrl.config.event_type_names.length > 0 && ctrl.constant_properties.length > 0) {
                        ctrl.constant_properties.forEach((const_prop) => {
                            if (!ctrl.config.column_order.includes(const_prop)) {
                                ctrl.config.column_order.push(const_prop)
                            }
                            if (!ctrl.hidden_column_options.includes(const_prop)) {
                                ctrl.hidden_column_options.push(const_prop)
                            }
                        });
                    }
                } else {
                    ctrl.constant_properties = [];
                }

                ctrl.config.hidden_columns = ctrl.config.hidden_columns.filter(col => {
                    return ctrl.hidden_column_options.includes(col);
                });

            });
        }
    }

    inputChange() {
        const ctrl = this;
        if (ctrl.event_types && ctrl._config.event_type_names) {
            ctrl.selected_types = ctrl.event_types.filter(item => ctrl._config.event_type_names.includes(item.attributes.name));
        }
        if (ctrl._config.hidden_columns == null) {
            ctrl._config.hidden_columns = [];
        }
    }

    reOrderList(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.config.column_order, event.previousIndex, event.currentIndex);
    }

    private getBaseHiddenColumns() {
        return ['Changed By', 'Created By', 'Component'].concat(this.seriesData.event_columns);
    }
}
