import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DateTimePeriodService} from "../../services/datetime_period.service";
import {TileDataService} from "../../services/tile_data.service";
import {Subject} from "rxjs";
import {SeriesDataService} from "../../services/series_data.service";
import {ApiService} from "../../services/api.service";
import {takeUntil} from "rxjs/operators";
import * as utils from "../../lib/utils"
import * as _ from "lodash";

import * as QuillNamespace from 'quill';
import {QuillEditorComponent} from "ngx-quill";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SeriesStringParser} from "../../services/series_string_parser.service";


@Component({
    selector: 'paragraph',
    templateUrl: './paragraph.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ParagraphComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @Input() save_content: Subject<any>;
    paragraph: any = {};

    @Input() config: any;
    component_ready: boolean = false;
    place_holder: string = '';
    @Input() show_header: boolean;

    editing: boolean;

    constructor(private http: HttpClient,
                public dateTimePeriodService: DateTimePeriodService,
                public tileData: TileDataService,
                private seriesData: SeriesDataService,
                private api: ApiService,
                private snackBar: MatSnackBar,
                private stringParser: SeriesStringParser) {
        this.tileData.editing.pipe(takeUntil(this.onDestroy)).subscribe((newBool: boolean) => {
            this.editing = newBool;
        });
    }


    ngOnInit() {
        const ctrl = this;
        this.save_content.pipe(takeUntil(this.onDestroy)).subscribe(event => {
            this.setText(event, this.dateTimePeriodService.dtp);
            ctrl.paragraph.edit_text = event;
        });
        this.dateTimePeriodService.dtpReset.pipe(takeUntil(this.onDestroy)).subscribe((dtp) => {
            this.setText(ctrl.paragraph.edit_text, dtp);
        });

        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            ctrl.paragraph.edit_text = ctrl.config;
            this.setText(ctrl.paragraph.edit_text, this.dateTimePeriodService.dtp);
        });
    }

    setText(text, dtp){
        const ctrl = this;
        ctrl.place_holder = '';
        ctrl.paragraph.display_text = null;
        if(text && !_.isEmpty(text)){
            ctrl.place_holder = ctrl.stringParser.simpleParse(utils.deepCopy(text));
        }
        this.tileData.trustHTML(text, dtp).promise.then((display) => {
            ctrl.paragraph.display_text = display;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
        this.save_content.unsubscribe();
    }
}