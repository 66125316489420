<div class="form-dialog">
  <h1><span [textContent]="loading ? 'loading...': (series && series.id ? 'Edit series: ' + series.attributes.name :
  'Add series')"></span>
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true" (selectedTabChange)="onTabClick($event)">
      <mat-tab label="Basic">
        <form *ngIf="series && seriesData" class="form-section column space-between">
          <div class="row">
            <mat-radio-group [(ngModel)]="series.type" name="base_type" [disabled]="series.id"
                             style="flex-basis:50%;flex-grow:0;" class="inline"
                             (mouseenter)="hint='Base_Type'">
              <label>Type</label>
              <mat-radio-button *ngFor="let base of seriesData.base_types" [value]="base">{{ base }}</mat-radio-button>
            </mat-radio-group>

            <div class="tip-box" style="flex-grow:1;">
              <div class="tip" [ngClass]="{'showing': hint=='Base_Type'}">
                <label>Type</label>
                <div>Explain the the base type here.</div>
              </div>
            </div>
          </div>
          <div class="row reduce-height">
            <mat-form-field class="inline" (mouseenter)="hint='Name'" style="flex-basis:50%;flex-grow:0;">
              <label>Name</label>
              <input matInput placeholder="" [(ngModel)]="series.attributes.name" name="name">
            </mat-form-field>

            <div class="tip-box" style="flex-grow:1;">
              <div class="tip" [ngClass]="{'showing': hint=='Name'}">
                <label>Name</label>
                <div>
                  The shorthand code name for the series. Recommended naming convention:
                  [Three Letter Area Code]_[Three digit sequence number'] + 3 X _[Three digit acronym]
                  Example: CCD_021_PLS_FEE_CU
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <mat-form-field class="inline" (mouseenter)="hint='Description'" style="flex-basis:50%;flex-grow:0;">
              <label>Description</label>
              <input matInput [(ngModel)]="series.attributes.description" name="description">
            </mat-form-field>

            <div class="tip-box" style="flex-grow:1;">
              <div class="tip" [ngClass]="{'showing': hint=='Description'}">
                <label>Description</label>
                <div>Explain the description here.</div>
              </div>
            </div>
          </div>
        </form>
      </mat-tab>

      <mat-tab *ngIf="series" label="Details">
        <form class="form-section column space-between">
          <ng-container *ngIf="series.type == 'calculation' ">
            <h3>Formula</h3>
            <div class="row center">
              <div class="select-search" style="flex-grow:2" (mouseenter)="hint='formula_series'">
                <select-search [label]="'Select a series'"
                               [(value)]="formula_series"
                               [data]="seriesData.series_list"
                               [classes]="'fill'"
                               (selectChange)="formula_series;"
                >
                </select-search>
              </div>
              <button (click)="addToFormula(formula_series)" title="Add to formula" class="btn-addtotext">
              </button>
            </div>

            <div class="row">
              <textarea [(ngModel)]="series.attributes.name_formula" name="name_formula" rows="2" [disabled]="disabled"
                        style="flex-grow:2;margin-right:20px;"></textarea>

              <button class="btn-save-comment" (click)="calcValidator()" title="Check formula"></button>
            </div>
            <div class="row" *ngIf="checkCalcMessage">{{checkCalcMessage}}</div>

            <label>Assumptions</label>
            <div class="row">
              <textarea [(ngModel)]="series.attributes.assumptions" [disabled]="disabled" name="assumptions" rows="2"
                        style="flex-grow:2;margin-right:20px;"></textarea>
            </div>
            <!--<div class="hint" ng-if="showHints">-->
            <!--{{series_.attributes.description_formula}}-->
            <!--<h3 *ngIf="series.attributes.specialised_function" >Python code for the specialised function</h3>-->
            <!--<div *ngIf="series.attributes.specialised_function">-->

            <!--<div  ng-bind-html="html_formula" ></div>-->

            <!--</div>-->

            <!--<div layout="row" layout-pading>-->
            <!--<label>{{checkCalcMessage}}</label>-->
            <!--</div>-->
            <!--</div>-->
          </ng-container>

          <div class="row">
            <mat-checkbox (mouseenter)="hint='accumulation'" [(ngModel)]="series.attributes.accumulation"
                          name="accumulation"
                          [disabled]="disabled" [labelPosition]="'before'">
              Accumulation
            </mat-checkbox>
            <mat-checkbox *ngIf="series.type==='calculation'" (mouseenter)="hint='delete_limits'"
                          [labelPosition]="'before'"
                          [(ngModel)]="series.attributes.delete_hihilowlow"
                          name="delete_limits" [disabled]="disabled"
                          [labelPosition]="'before'">
              Delete Out of Limits
            </mat-checkbox>

            <mat-checkbox *ngIf="series.type==='calculation'" (mouseenter)="hint='specialised_function'"
                          [(ngModel)]="series.attributes.specialised_function"
                          name="specialised_function"
                          [disabled]="disabled" [labelPosition]="'before'">
              Custom Function
            </mat-checkbox>
            <div class="tip-box">
              <div class="tip" [ngClass]="{'showing': hint=='accumulation'}">
                <label>Accumulation</label>
                <div>Explain the the Accumulation type here.</div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='delete_limits'}">
                <label>Delete Limits</label>
                <div>If calculated value outside of the hihi and lowlow limits should be deleted and not save in the
                  database.
                </div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='specialised_function'}">
                <label>Custom Function</label>
                <div>
                  If the formula includes a custom function written in python.
                </div>
              </div>
            </div>
          </div><!--/row-->

          <div class="row" *ngIf="series.type=='calculation'">
            <mat-form-field (mouseenter)="hint='extra_arguments'">
              <mat-label>Extra Arguments</mat-label>
              <input matInput placeholder="" [(ngModel)]="series.attributes.extra_arguments_string"
                     name="extra_arguments" [disabled]="disabled">
            </mat-form-field>
            <div class="tip-box">
              <div class="tip" [ngClass]="{'showing': hint=='extra_arguments'}">
                <label>Extra Arguments</label>
                <div>Extra arguments to pass into the function</div>
              </div>
            </div>
          </div><!--/row-->

          <div class="row">
            <!--<div flex *ngIf="!ready==true">Loading engineering units...</div>-->
            <mat-form-field (mouseenter)="hint='engineering_unit'">
              <mat-label>Engineering Unit</mat-label>
              <mat-select [(ngModel)]="series.relationships.engineering_unit.data" name="engineering_unit"
                          [disabled]="disabled" [compareWith]="matSelectCompare">
                <mat-option *ngFor="let unit of seriesData.engineering_units"
                            [value]="unit">{{ unit.attributes.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field (mouseenter)="hint='sample_period'">
              <mat-label>Sample Period</mat-label>
              <mat-select [(ngModel)]="series.attributes.sample_period"
                          mat-selected-text="series.attributes.sample_period" name="sample_period"
                          [disabled]="disabled">
                <mat-option *ngFor="let period of seriesData.sample_periods"
                            [value]="period">{{ period | upperfirst | removeUnderscore}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field (mouseenter)="hint='aggregation'">
              <mat-label>Aggregation Type</mat-label>
              <mat-select [(ngModel)]="series.attributes.aggregation" mat-selected-text="series.attributes.aggregation"
                          name="aggregation" [disabled]="disabled">
                <mat-option
                    *ngFor="let atype of seriesData.aggregation_types"
                    title="{{seriesData.aggregation_definitions[atype]}}"
                    [value]="atype">{{atype| upperfirst | removeUnderscore}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="tip-box">
              <div class="tip" [ngClass]="{'showing': hint=='engineering_unit'}">
                <label>Engineering Unit</label>
                <div>The engineering unit of the series in question.</div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='sample_period'}">
                <label>Sample Period</label>
                <div>The sample period that the value is normally viewed in.</div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='aggregation'}">
                <label>Aggregation Type</label>
                <div>How the value should be aggregated. Flow rates and mostly total, grade values should be weighted.
                  Level and quantities should be latest.
                </div>
              </div>
            </div>
          </div><!--/row-->

          <div class="row">
            <mat-form-field (mouseenter)="hint='Fill_Method'">
              <mat-label>Fill Method</mat-label>
              <mat-select [(ngModel)]="series.attributes.fill_method" mat-selected-text="series.attributes.fill_method"
                          name="fill_method" [disabled]="disabled">
                <mat-option *ngFor="let fill of fill_methods"
                            [title]="seriesData.fill_methods[fill]"
                            [value]="fill">{{ fill ? (fill | upperfirst | removeUnderscore) : fill}}</mat-option>
              </mat-select>
            </mat-form-field>
            <ng-container
                *ngIf="series.attributes.fill_method==='Rolling average'||
                series.attributes.fill_method === 'interpolated_with_forward_fill_rolling_average' ||
                series.attributes.fill_method === 'interpolated_with_calculated_forward_fill_average_by_hours' ||
                series.attributes.fill_method === 'interpolated_with_calculated_forward_fill_average_by_count'">

              <mat-form-field>
                <ng-container
                    *ngIf="series.attributes.fill_method === 'Rolling average'|| series.attributes.fill_method === 'interpolated_with_forward_fill_rolling_average' ">
                  <mat-label>Rolling average window (hours)</mat-label>
                </ng-container>

                <ng-container
                    *ngIf="series.attributes.fill_method === 'interpolated_with_calculated_forward_fill_average_by_hours'">

                  <mat-label>Calculated rolling average window (hours)</mat-label>

                </ng-container>

                <ng-container
                    *ngIf="series.attributes.fill_method === 'interpolated_with_calculated_forward_fill_average_by_count'">
                  <mat-label>Average count (valid entries)</mat-label>
                </ng-container>

                <input matInput step="any" [(ngModel)]="series.attributes.rolling_average_hours"
                       name="rolling_average_hours"
                       [disabled]="disabled">

              </mat-form-field>

            </ng-container>

            <ng-container
                *ngIf="series.attributes.fill_method==='Default value' || series.attributes.fill_method ==='backfill_padded_with_default_value'">

              <mat-form-field>
                <mat-label>Default value</mat-label>

                <input matInput type="number" step="any" [(ngModel)]="series.attributes.default_value"
                       name="default_value"
                       [disabled]="disabled">
              </mat-form-field>

            </ng-container>

            <mat-form-field (mouseenter)="hint='Default_Chart_Type'">
              <mat-label>Default Chart Type</mat-label>
              <mat-select [(ngModel)]="series.attributes.default_chart"
                          mat-selected-text="series.attributes.default_chart" name="default_chart"
                          [disabled]="disabled">
                <mat-option *ngFor="let ctype of seriesData.chart_types"
                            [value]="ctype">{{ ctype | upperfirst | removeUnderscore}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field (mouseenter)="hint='KPI_Level'">
              <mat-label>KPI Level</mat-label>
              <mat-select [(ngModel)]="series.attributes.kpi_level" mat-selected-text="series.attributes.kpi_level"
                          name="kpi_level" [disabled]="disabled">
                <mat-option *ngFor="let level of seriesData.KPI_levels"
                            [value]="level">{{ level ? (level | upperfirst | removeUnderscore) : level}}</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="tip-box">
              <div class="tip" [ngClass]="{'showing': hint=='Fill_Method'}">
                <label>Fill Method</label>
                <div> How missing values should be handled. For example if grade reading is captured
                  every four should it be assumed that the next four hours or previous four hours
                  are that value as well.
                </div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='Default_Chart_Type'}">
                <label>Default Chart Type</label>
                <div>When a series is shown in default charts or in a series table
                  this is how the chart is viewed.
                </div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='KPI_Level'}">
                <label>KPI Level</label>
                <div>On a scale of 1 to 4 how important is this series.
                  KPI level receives special user interface
                  treatment and other KPI levels can be used to filter out unimportant series.
                </div>
              </div>
            </div>
          </div><!--/row-->

          <div class="row">
            <mat-form-field style="flex-grow:5.7" (mouseenter)="hint='Series_Type'"
                            *ngIf="seriesData&&seriesData.series_type_list">
              <mat-label>Series Type</mat-label>
              <mat-select [(ngModel)]="series.relationships.series_type.data" name="series_type"
                          [disabled]="disabled" [compareWith]="matSelectCompare">
                <mat-option *ngFor="let stype of seriesData.series_type_list" [value]="stype">
                  {{stype.attributes.name}} - {{stype.attributes.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field (mouseenter)="hint='Event_Type'">
              <mat-label>Event Type</mat-label>
              <!--https://github.com/angular/angular/issues/7697-->
              <mat-select [(ngModel)]="series.relationships.event_type.data" name="event_type"
                          [disabled]="disabled" [compareWith]="matSelectCompare">
                <mat-option *ngFor="let etype of seriesData.event_type_list" [value]="etype">
                  {{ etype.attributes.name }} - {{ etype.attributes.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="tip-box">
              <div class="tip" [ngClass]="{'showing': hint=='Series_Type'}">
                <label>Series Type</label>
                <div>What is the series type. Series types can be set up by an administrator.</div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='Event_Type'}">
                <label>Event Type</label>
                <div>What event type is associated with the series. Eg, Acid deliveries, Bundle counts, downtime events
                  etc.
                </div>
              </div>
            </div>
          </div><!--/row-->

          <div class="row">
            <div class="select-search" style="flex-grow:2" (mouseenter)="hint='weighted_average_series'">
              <select-search *ngIf="seriesData.series_list.length>0"
                             [label]="'Weighted Average Series'"
                             [(value)]="series.relationships.weighted_average_series.data"
                             [data]="seriesData.series_list"
                             [classes]="'fill'"
                             [fill_stubs]="true"
                             (selectChange)="series.relationships.weighted_average_series.data=$event.value">
              </select-search>
            </div>
            <div class="tip-box">
              <div class="tip" [ngClass]="{'showing': hint=='weighted_average_series'}">
                <label>Weighted Average Series</label>
                <div></div>
              </div>

            </div>
          </div>

          <h3>
            Limits and stats for the series.
          </h3>
          <p>Please note that limits are hourly limits, regardless of the series sample period. Please specify
            accordingly</p>
          <div class="row">
            <mat-form-field class="md-block">
              <mat-label>HiHi</mat-label>
              <input matInput type="number" step="any" [(ngModel)]="series.attributes.hihi" name="hihi"
                     [disabled]="disabled">
            </mat-form-field>
            <mat-form-field class="md-block">
              <mat-label>Hi</mat-label>
              <input matInput type="number" step="any" [(ngModel)]="series.attributes.hi" name="hi"
                     [disabled]="disabled">
            </mat-form-field>
            <mat-form-field class="md-block">
              <mat-label>LowLow</mat-label>
              <input matInput type="number" step="any" [(ngModel)]="series.attributes.lowlow" name="lowlow"
                     [disabled]="disabled">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Low</mat-label>
              <input matInput type="number" step="any" [(ngModel)]="series.attributes.low" name="low"
                     [disabled]="disabled">
            </mat-form-field>
          </div><!--/row-->

          <div class="row">
            <mat-form-field>
              <mat-label>Mean</mat-label>
              <input matInput type="number" step="any" [(ngModel)]="series.attributes.mean" name="mean"
                     [disabled]="disabled">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Std</mat-label>
              <input matInput type="number" step="any" [(ngModel)]="series.attributes.std" name="std"
                     [disabled]="disabled">
            </mat-form-field>

            <div class="tip-box">
              <div class="tip" [ngClass]="{'showing': hint=='engineering_unit'}">
                <label>Engineering Unit</label>
                <div>The engineering unit of the series in question.</div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='sample_period'}">
                <label>Sample Period</label>
                <div>The sample period that the value is normally viewed in.</div>
              </div>
              <div class="tip" [ngClass]="{'showing': hint=='aggregation'}">
                <label>Aggregation Type</label>
                <div>How the value should be aggregated. Flow rates and mostly total, grade values should be weighted.
                  Level and quantities should be latest.
                </div>
              </div>
            </div>

          </div><!--/row-->

        </form>

      </mat-tab>

      <mat-tab *ngIf="series && series.id" label="Chart">
        <form class="form-section column" style="height:340px;">
          <button (click)="overrideCalculations()"
                  *ngIf="permissions[series.id] && permissions[series.id].includes('override_calculations')"
                  class="bordered-button" mat-button style="max-width: 150px !important;">Update calculations
          </button>

          <default-chart [series]="series" *ngIf="current_tab===2" style="max-height:280px;"></default-chart>
          <date-period [no_url]="true" [immutable]="true" class="date-period inline align-bottom"></date-period>
        </form>
      </mat-tab>

      <mat-tab *ngIf="series && series.id" label="Links">
        <form class="form-section column">
          <div class="column">
            <ng-container *ngIf="series_components">
              <h3>Allow editing series for these processes</h3>
              <ng-container *ngFor="let sc of series_components">
                <div class="row list" style="justify-content: flex-start"
                     *ngIf="sc.relationships.component.data && sc.relationships.component.data.attributes">
                  <mat-checkbox [(ngModel)]="sc.attributes.can_edit"
                                (change)="saveSeriesComponent(sc)"
                                [ngModelOptions]="{standalone:true}"
                                labelPosition="after"> {{sc.relationships.component.data.attributes | nameOrDescription}}</mat-checkbox>
                  <div style="color:#666;margin-left:20px;" *ngIf="sc.result">{{sc.result}}</div>
                  <div style="margin-left:20px;">
                    <a [routerLink]="'/view/edit_series_components/' + sc.relationships.component.data.id"
                       style="color:darkorange;text-decoration:none;"
                       title="Report Configuration" (click)="onCloseClick()">
                      Report Configuration<i class="fa fa-arrow-right"></i></a>
                  </div>
                  <div style="margin-left:20px;">
                    <a [routerLink]="'/view/log_sheet/' + sc.relationships.component.data.id"
                       style="color:darkorange;text-decoration:none;"
                       title="Log Sheet" (click)="onCloseClick()">
                      Log Sheet<i class="fa fa-arrow-right"></i></a>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div *ngIf="permissions[series.id] && permissions[series.id].includes('view_process_data')">
              <h3>Estimates
                <button mat-button
                        [disabled]="permissions[series.id] &&  !permissions[series.id].includes('edit_process_data')"
                        (click)="updateEstimates()" style="margin-left:20px;">
                  <i class="fa fa-plus" matTooltip="Add estimate"></i>
                </button>
              </h3>

              <table *ngIf="estimates_data && estimates_data.length>0"
                     id="estimates_table"
                     style="width:100%;margin-top:10px;">
                <thead>
                <tr>
                  <th style="width:15%;">Type</th>
                  <th style="width:25%;">Name</th>
                  <th style="width:30%;">Series</th>
                  <th style="width:20%;">Current Value</th>
                  <th class="action">Go to Estimates</th>
                </tr>
                </thead>
                <tbody style="text-align: center;">
                <tr *ngFor="let estimate of estimates_data">
                  <td>{{estimate.attributes.estimate_type_name}} </td>
                  <td>{{estimate.attributes.name}} </td>
                  <td title="{{estimate.attributes.name}}"><span>
                          {{estimate.attributes | nameOrDescription}}
                        </span>
                  </td>
                  <td>{{estimate.attributes.current_value}} </td>
                  <td class="action">
                        <span>
                          <a *ngIf="processes && processes.length > 0"
                             href="/view/estimate_data_sheet/{{processes[0].id}}" title="Estimate Input Sheet"
                             target="_blank">
                            <i class="fa fa-edit"></i></a>
                          <a *ngIf="!processes || !processes.length > 0" href="/view/estimate_data_sheet/"
                             title="Estimate Input Sheet">
                            <i class="fa fa-edit"></i></a>
                        </span>
                  </td>
                </tr>
                </tbody>
              </table>
              <div *ngIf="(estimates_data && !estimates_data.length>0)">No estimates</div>
            </div>

            <h3>Other properties</h3>
            <div class="row list">
              <label>Linked Components:</label>
              {{series.attributes.linked_components| upperfirst}}
            </div>
            <div class="row list">
              <label>Alternate Names:</label>
              {{series.attributes.alternate_names| upperfirst}}
            </div>
            <div class="row list">
              <label>Estimate Names:</label>
              {{series.attributes.estimate_names| upperfirst}}
            </div>

            <div class="row list">
              <label>Collector Names:</label>
              {{series.attributes.collector_names| upperfirst}}
            </div>
          </div>

        </form>
      </mat-tab>

      <mat-tab *ngIf="series && series.id" label="Correction">
        <date-period [no_url]="true" [immutable]="true" class="date-period inline align-top"
                     style="top:0;"></date-period>
        <div style="margin-top:28px;"></div>
        <apply-correction></apply-correction>

      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content><!--/mat-dialog-content-->
  <div class="action-buttons">
    <button class="btn-close" [disabled]="applying" (click)="onCloseClick()">Close</button>
    <button class="btn-save" [disabled]="applying" (click)="saveOrApply('save')">Save</button>
    <button class="btn-save" [disabled]="applying" (click)="saveOrApply('apply')">Apply</button>
  </div>
</div>

