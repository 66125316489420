import * as utils from '../lib/utils';
import {match_schema, recursively_null} from '../lib/utils';
import * as moment_ from 'moment';
import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material";
import {Model} from './api.service';
import * as _ from "lodash";
import {HotInstance} from "./hot-instance";

// TODO what is this used for? If it is for setting a type of Global variable,
export const moment = moment_["default"];

//TODO make work with auth complete
@Injectable()
export class HandsontableGenerator {
    col_widths: any = {};

    constructor(private snackBar: MatSnackBar) {
    }

    window_height() {
        return window.innerHeight - 240;
    }

    gen_lookups(resource_list: any[], nameFunction?, not_null?: boolean) {
        if (nameFunction == null) {
            nameFunction = item => {
                if (!item.hasOwnProperty('attributes')) {
                    return 'Unknown'
                }

                if (item.attributes.name == null) {
                    return 'No Name'
                }
                if (item.attributes.description == null) {
                    return String(item.attributes.name).trim();
                }
                return item.attributes.name.trim() + '-' + item.attributes.description.trim();
            }
        }
        const valueLookup = {};
        const idLookup = {};
        const sourceList = resource_list.map(item => {
            const str_item = String(nameFunction(item)).trim();
            valueLookup[str_item] = item.id;
            idLookup[item.id] = str_item;
            return str_item
        });
        if (!not_null) {
            sourceList.push(' ');
            // @ts-ignore
            valueLookup[null] = '';
            idLookup[''] = null;
        }
        sourceList.sort();
        return {
            source: sourceList,
            valueLookup: valueLookup,
            idLookup: idLookup
        }
    }

    genLookupDataSource(lookUps, relationship, index?, extra_function?) {
        return (row, value) => {

            if (row == null) {
                return null
            }
            if (row.relationships == null) {
                row.relationships = {};
            }
            if (row.relationships[relationship] == null) {
                row.relationships[relationship] = {}
            }
            if (row.relationships[relationship].data == null) {
                row.relationships[relationship].data = {id: ''};
            }

            if (value === undefined) {
                return lookUps.idLookup[row.relationships[relationship].data.id]
            } else {
                row.relationships[relationship].data.id = lookUps.valueLookup[value];
            }

            if (extra_function) {

                extra_function(row, value)
            }

            return value
        }
    }

    genLookupCustomDataSource(lookUps, relationship) {
        return (row, value) => {

            if (row == null) {
                return null
            }
            if (row.attributes == null) {
                row.attributes = {}
            }
            if (row.attributes.custom_series == null) {
                row.attributes.custom_series = {};
            }
            if (row.attributes.custom_series[relationship] == null) {
                row.attributes.custom_series[relationship] = null
            }

            if (value === undefined) {

                return lookUps.idLookup[row.attributes.custom_series[relationship]]

            } else {

                row.attributes.custom_series[relationship] = lookUps.valueLookup[value];

            }

            return value
        }
    }

    generateTable(resource, schema, column_list, hotInstance?: HotInstance, extraAfterChange?) {
        const ctrl = this;

        if (hotInstance == null) {
            hotInstance = new HotInstance();
        }

        const cols_hide_dropdown = [];
        column_list.map(element => {
            if (typeof element.data == 'function') {
                cols_hide_dropdown.push(column_list.indexOf(element));
            }
            // if (element['hideMenu']) {
            //     cols_hide_dropdown.push(column_list.indexOf(element));
            // }
        });

        hotInstance.column_list = column_list;

        hotInstance.resource = resource;

        hotInstance.change_ids = [];
        hotInstance['new_rows'] = [];

        hotInstance.save = () => {
            console.log("Change IDs ", hotInstance.change_ids);
            ctrl.snackBar.open('Updating ' + hotInstance.change_ids.length +
                ' items, and saving ' + hotInstance['new_rows'].length + ' new items.', undefined,
                {duration: 3000});

            const sourceData = hotInstance.instance.getSourceData();
            const result = this.setup_save(sourceData, resource, hotInstance.change_ids, schema,
                hotInstance.required_keys, hotInstance['new_rows']);
            let errorCount = 0;
            let errorStr = '';
            const promises = result.deferreds;

            promises.forEach(promise => {
                promise.then(response => {
                    _.remove(hotInstance.change_ids, item => item === response.id);

                    this.snackBar.open('Saved: ' + response.data.id, undefined, {duration: 2000});
                    return response;
                }).catch(reason => {
                    errorCount += 1;
                    //errorStr += errorCount + ": " + reason.data +  ": " + reason.error.errors[0].detail + ' \n';
                    errorStr += errorCount + ": " + reason.error.errors[0].title + ' \n';
                    console.log("errorStr", errorStr);
                });

            });

            Promise.all(result.deferreds).then(responses => {
                responses.forEach(response => {
                    // TODO validate in which case there will be a data field and which not.
                    //  Potentially when there was an HTTP error.
                    hotInstance.change_ids = utils.removeItem(hotInstance.change_ids, response.data.id);
                    hotInstance['new_rows'] = utils.removeItem(hotInstance['new_rows'], responses.indexOf(response));
                    console.log(hotInstance.change_ids)
                })
            });

            //For catching all errors
            //This will wait until all promises are resolved/rejected and not just complete on first fail
            Promise.all(result.deferreds.map((promise) => {
                    return promise.catch(error => {
                        return error
                    })
                })
            ).then(() => {
                if (errorStr) {
                    ctrl.snackBar.open('Could not save some items: check unsaved items ' + ' \n' + errorStr, "Hide");
                }
            });

            return result
        };

        hotInstance.settings = {
            dataSchema: schema,
            colHeaders: utils.gen_col_headers(hotInstance.column_list, {}),
            minSpareRows: 3,
            selectState: true,
            rowHeaders: true,
            renderAllRows: false,
            rowHeights: 30,
            // startRows: 1,
            dropdownMenu: true,
            filters: true,
            sortIndicator: true,
            selectionMode: 'range',
            manualColumnResize: true,
            maxCols: hotInstance.column_list.length,
            columns: hotInstance.column_list,
            columnSorting: true,
            contextMenu: {
                callback: utils.gen_row_deletion('id', resource, this.snackBar, hotInstance),
                items: {
                    "delete_row": {
                        name: 'Delete Row Permanently'
                    }
                }
            },
            afterColumnResize: (col_index, width) => {
                let title = hotInstance.instance.getColHeader(col_index);
                title = title.toString().replace("*", "");
                this.col_widths[title] = width;
                this.snackBar.open("Please click save to store new column width.", undefined, {duration: 3000})

            },
            afterChange: (changes: [number, string | number, any, any][], source: string) => {
                if (changes && ['edit', 'CopyPaste.paste', 'Autofill.fill'].includes(source)) {
                    let hot = hotInstance.instance;
                    for (const change in changes) {
                        let row = changes[change][0];
                        // TODO the wrong id is potentially retrieved here
                        let id = hot.getDataAtRowProp(row, 'id');

                        if (id !== '' && id && !(hotInstance.change_ids.indexOf(id) >= 0)) {
                            hotInstance.change_ids.push(id);
                        } else {
                            if (hotInstance['new_rows'].indexOf(changes[change][0]) < 0) {
                                hotInstance['new_rows'].push(changes[change][0])
                            }
                        }
                    }
                }
                if (extraAfterChange) {
                    extraAfterChange(changes, source);
                }
            }
        };

        hotInstance.settings.rowHeaders = index => {
            if (hotInstance.instance == null) {
                return index
            } else {
                const description = hotInstance.instance.getDataAtRowProp(index, 'attributes.description');
                const id_ = hotInstance.instance.getDataAtRowProp(index, 'id');

                let prefix = '';
                if (hotInstance.change_ids.indexOf(id_) >= 0) {
                    prefix = '(unsaved) '
                }

                if (description) {
                    return prefix + description
                } else {
                    return prefix + hotInstance.instance.getDataAtRowProp(index, 'attributes.name')
                }
            }
        };
        hotInstance.settings.rowHeaderWidth = 180;
        hotInstance.ready = true;
        hotInstance.never_searched = true;

        return hotInstance
    }

    /**
     * Saves or patches rows in Handson sheets
     *
     * @param hot_data
     * @param resource
     * @param change_ids
     * @param schema
     * @param required_keys
     */
    private setup_save(hot_data, resource, change_ids, schema, required_keys: string[], new_rows?: number[]) {
        const ctrl = this;

        const deferreds: Promise<any>[] = [];
        const savePromises: Promise<any>[] = [];
        const errors: any[] = [];

        hot_data = hot_data.map(item => {
            let inner_resource: Model = resource.hasOwnProperty('baseUrl') ? resource : resource[item.type];
            const index = hot_data.indexOf(item);
            if (!recursively_null(item)) {
                match_schema(item, schema);

                let had_required_keys: boolean = null;
                for (let i = 0; i < required_keys.length; i++) {
                    const keys = required_keys[i].split('.');
                    let required_item = item;
                    let key;
                    do {
                        key = keys.shift();
                        required_item = required_item[key];
                    } while (keys.length > 0);
                    if (required_item) {
                        had_required_keys = true;
                    } else {
                        had_required_keys = false;
                        break;
                    }
                }

                if (!item['id'] && had_required_keys === true) { //new item with required keys
                    let promise = inner_resource.save(item);
                    promise.then(result => {
                        item.id = result.data.id;
                    });
                    deferreds.push(promise);
                    savePromises.push(promise)
                } else if (!item['id'] && had_required_keys === false) {
                    if (Array.isArray(new_rows)) {
                        if (new_rows.includes(index)) {
                            ctrl.snackBar.open("Required data missing at row " + (parseInt(index) + 1) + ", index: " + index, 'Hide');
                            errors.push({
                                message: "Required data missing at row " + (parseInt(index) + 1),
                                index: index
                            });
                            //removeItem(new_rows, index);
                        }
                    }
                } else {
                    if (Array.isArray(change_ids)) { //changed item
                        if (change_ids.includes(item['id'])) {
                            try {
                                delete item.attributes.changed_on;
                                delete item.attributes.created_on;
                                delete item.relationships.created_by;
                                delete item.relationships.changed_by;
                            } catch {
                            }
                            deferreds.push(inner_resource.patch(item))
                        }
                    }
                }
            }
            return item
        });

        return {deferreds: deferreds, data: hot_data, savePromises, errors: errors};
    }
}
