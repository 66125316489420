import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'exception-on-demand',
    templateUrl: './exception-on-demand.component.html',
    styleUrls: ['./exception-on-demand.component.less']
})
export class ExceptionOnDemandComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
        this.foo();
    }

    private foo() {
        this.bar();
    }

    private bar() {
        this.baz();
    }

    private baz() {
        throw new Error("This error was successfully thrown.");
    }

}
