/**
 * Created by phillip on 2016/06/18.
 */

import * as Handsontable from "handsontable"
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {HeaderDataService} from "../services/header_data.service";
import {SeriesDataService} from "../services/series_data.service";
import {HandsontableGenerator} from "../services/handsontable-generator.service";
import {HotInstance} from "../services/hot-instance";

@Component({
    selector: 'estimateSheetView',
    templateUrl: 'handson_sheet.html',
    encapsulation: ViewEncapsulation.None //Global Styles
})
export class EstimateSheetViewCtrl implements OnInit {
    private hot_anchor: ElementRef;

    @ViewChild('hot_anchor', {static: false}) set setHotAnchor(content: ElementRef) {
        this.hot_anchor = content;
    }

    hot: HotInstance;
    title: string;
    schema: any;
    column_list: any[];
    search: string;

    estimates: any[];
    users: any[];
    estimate_types: any[];
    series_light: any[];

    constructor(
        private api: ApiService,
        private headerData: HeaderDataService,
        private seriesData: SeriesDataService,
        private handsontableGenerator: HandsontableGenerator) {
        this.hot = new HotInstance();
    }

    ngOnInit(): void {
        const ctrl = this;
        console.log('ngOnInit, EstimateSheetViewCtrl');
        ctrl.title = 'Estimates';

        const promises = [];
        promises.push(ctrl.api.estimate.search().toPromise().then(response => ctrl.estimates = response.data));
        promises.push(ctrl.api.users.search().toPromise().then(response => ctrl.users = response.data));
        promises.push(ctrl.api.estimate_type.search().toPromise().then(response => ctrl.estimate_types = response.data));
        promises.push(ctrl.api.series_light.search().toPromise().then(response => ctrl.series_light = response.data));

        Promise.all(promises).then(ctrl.createTable.bind(ctrl));

        ctrl.buildHeader();
    }

    createTable() {
        console.log('createTable, EstimateSheetViewCtrl');

        const ctrl = this;

        const estimateTypeLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.estimate_types);
        const seriesLookups = ctrl.handsontableGenerator.gen_lookups(ctrl.series_light);
        const userLookup = ctrl.handsontableGenerator.gen_lookups(ctrl.users, item => item.attributes.name);

        ctrl.schema = {
            id: null,
            type: 'estimate',
            attributes: {
                name: null,
                description: null,
                fill_method: null,
                aggregation: null,
                sample_period: null,
                sample_offset: null,
                created_on: null,
                changed_on: null,
                default_chart: null,
                accumulation: null
            },
            relationships: {
                parent: {data: {type: 'series', id: null}},
                estimate_type: {data: {type: 'estimate_type', id: null}},
                created_by: {data: {id: null, type: 'users'}},
                changed_by: {data: {id: null, type: 'users'}}
            }
        };
        ctrl.column_list = [{
            data: 'attributes.name',
            type: 'text',
            title: 'Name'
        }, {
            data: 'attributes.description',
            type: 'text',
            title: 'Description'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(seriesLookups, 'parent'),
            title: 'Series',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: seriesLookups.source,
            allowInvalid: true
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(estimateTypeLookups, 'estimate_type'),
            title: 'Estimate Type',
            type: 'autocomplete',
            trimDropdown: false,
            strict: true,
            source: estimateTypeLookups.source,
            allowInvalid: true
        }, {
            type: 'dropdown',
            data: 'attributes.aggregation',
            source: ctrl.seriesData.aggregation_types,
            title: 'Aggregation'
        }, {
            type: 'dropdown',
            data: 'attributes.default_chart',
            source: ctrl.seriesData.chart_types,
            title: 'Chart Type'
        }, {
            type: 'dropdown',
            data: 'attributes.sample_period',
            title: 'Sample Period',
            source: ctrl.seriesData.sample_periods
        }, {
            data: 'attributes.sample_offset',
            title: 'Sample Offset',
            type: 'numeric',
            format: '0'
        }, {
            data: 'attributes.fill_method',
            title: 'Fill Method',
            type: 'dropdown',
            source: Object.keys(ctrl.seriesData.fill_methods)
        }, {
            data: 'attributes.accumulation',
            title: 'Accumulation',
            type: 'checkbox'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'created_by'),
            readOnly: true,
            title: 'Created By'
        }, {
            data: ctrl.handsontableGenerator.genLookupDataSource(userLookup, 'changed_by'),
            readOnly: true,
            title: 'Changed By'
        }, {
            data: 'attributes.changed_on',
            readOnly: true,
            title: 'Changed On',
            type: 'date',
            renderer: 'date_formatter'
        }, {
            data: 'attributes.created_on',
            readOnly: true,
            title: 'Created On',
            type: 'date',
            renderer: 'date_formatter'
        }];

        ctrl.hot = ctrl.handsontableGenerator.generateTable(ctrl.api.estimate, ctrl.schema, ctrl.column_list, ctrl.hot);
        ctrl.hot.ready = true;
        ctrl.hot.settings.data = ctrl.estimates;
        ctrl.hot.instance = new Handsontable(ctrl.hot_anchor.nativeElement, ctrl.hot.settings)
    }

    save() {
        const ctrl = this;
        const results = ctrl.hot.save();
        ctrl.estimates = results.data;
    }

    download() {
        const ctrl = this;
        ctrl.hot.download();
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Estimates';
        ctrl.headerData.buttons = [
            {name: 'Save', func: ctrl.save.bind(ctrl), class: 'icon-save', params: {}},
            {name: 'Download', func: ctrl.download.bind(ctrl), class: 'icon-download', params: {}}
        ];
    }
}