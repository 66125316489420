/**
 * Created by nic on 2017/03/08.
 */

import {Component, OnDestroy, OnInit} from "@angular/core";
import {PlantDataService} from "../services/plant_data.service";
import {ApiService} from "../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {HeaderDataService} from "../services/header_data.service";
import {SeriesDataService} from "../services/series_data.service";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'log-sheet-focus-view',
    template: '<div class="page traveler">' +
        '<page-tile *ngIf="sheet_ready" [dtp]="dateTimePeriodService.dtp" [tile]="config" (tileChange)="tileChange($event)" class="column-tile tile-dtp"></page-tile>' +
        '</div>',
})
export class LogSheetFocusViewComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    config: any;
    sheet_ready: boolean;
    process: any;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private seriesData: SeriesDataService,
                private plantData: PlantDataService,
                private route: ActivatedRoute,
                private dateTimePeriodService: DateTimePeriodService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
            this.sheet_ready = false;
            ctrl.api.process.getById(params.processID).toPromise().then(response => {
                ctrl.process = response.data;
                ctrl.plantData.process_focus = ctrl.process;
                ctrl.buildHeader();
                ctrl.config = {
                    parameters: {
                        process: {id: ctrl.process.id}, series_list: [],
                        columns: ['Value', 'Forecast'],
                        mobile_column: ['Description', 'Value', 'MTD'],
                        title: ctrl.process.attributes.name
                    },
                    content: 'log-sheet',
                    show_dtp: true,
                    hide_edit: true
                };
                ctrl.dateTimePeriodService.dtp_complete.promise.then(() => {
                    this.dateTimePeriodService.dtp = this.dateTimePeriodService.setDefault(this.dateTimePeriodService.dtp);
                });
                this.sheet_ready = true;
            });
        });

        ctrl.headerData.downloadDataClicked.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.headerData.openDownloadDialog();
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    addSeries() {
        this.seriesData.upsertSeries(this.process).afterClosed().subscribe((series) => {
            this.dateTimePeriodService.dtpReset.emit(this.dateTimePeriodService.dtp)
        })
    }

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Log Sheet: ' + ctrl.process.attributes.name;
        ctrl.headerData.show_dtp = true;
        ctrl.headerData.add_download = true;
        ctrl.headerData.add_refresh = true;

        ctrl.headerData.buttons = [{name: "Add", func: this.addSeries.bind(ctrl), params: {ctrl: this}, class: 'icon-add'}];
    }
}