/**
 * Created by phillip on 2016/06/13.
 */

import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AppScope} from "../services/app_scope.service";
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from "@angular/material";
import {HeaderDataService} from "../services/header_data.service";

@Component({
    selector: 'login',
    templateUrl: 'login.component.html',
    encapsulation: ViewEncapsulation.None,

})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    forgotPasswordForm: FormGroup;
    loading = false;
    submittedLogin = false;
    submittedReset = false;

    returnUrl: string;
    require_otp: boolean;
    oneTimeLoginForm: FormGroup;
    oneTimeToken: string;
    tokenSent: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar,
        private headerData: HeaderDataService,
        private router: Router, private http: HttpClient, private appScope: AppScope
    ) {

    }

    ngOnInit() {
        this.appScope.no_header = true;

        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            otp: ['']
        });

        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', Validators.required],
        });

        this.oneTimeLoginForm = this.formBuilder.group({
            email: ['', Validators.required],
            oneTimeToken: ['']
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.headerData.show_header = false;

    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    onSubmitLogin() {
        console.log("calling onSubmitLogin");
        const ctrl = this;
        this.submittedLogin = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            console.log('Could not logon');
            return;
        }

        const vars = {
            email: ctrl.loginForm.controls.username.value,
            password: ctrl.loginForm.controls.password.value,
            token: ctrl.loginForm.controls.otp.value
        };

        this.http.post('/auth/login', vars).toPromise().then((response: any) => {
            console.log('login success', response);
            if (!response) return;

            ctrl.appScope.no_header = true;
            ctrl.appScope.current_user = response;
            this.headerData.show_header = true;

            // TODO make work without reloading the page (aka. use Angular Router), should reload the failed unauthorized api calls
            window.location.href = response.default_dashboard;

        }, result => {
            console.log('login failed', result);
            if (result.error.error == 'OTP required') {
                ctrl.require_otp = true;
                console.log('OTP required', result.error);
                this.snackBar.open('Please enter your OTP', undefined, {duration: 3000});
            } else if (result.status == 429) {
                this.snackBar.open("Please wait a few minutes before retrying.", "Hide");
            } else {
                this.snackBar.open('Error in logging in: ' + result.error.error, "Hide");
                console.log(result.error)
            }
        })
    }

    onSubmitReset() {
        const ctrl = this;
        this.submittedReset = true;

        if (this.forgotPasswordForm.invalid) {
            return;
        }

        this.http.post('/auth/forgot_password', {
            email: ctrl.forgotPasswordForm.controls.email.value
        }).toPromise().then((response: { errors: null }) => {
            console.log('onSubmitReset success', response);
            if (!response) return;

            this.snackBar.open(response.errors, 'Hide')
        }, response => {
            console.log('onSubmitReset failure', response);
            ctrl.appScope.errors = ['Too many requests']
        })
    }

    onSubmitOneTime() {
        const ctrl = this;

        console.log('one time login', ctrl.oneTimeLoginForm.controls.email.value);

        this.http.post('/auth/request_token_login', {
            email: ctrl.oneTimeLoginForm.controls.email.value
        }).toPromise().then((response: { errors: null }) => {
            ctrl.tokenSent = true;
            console.log('One time login success', response);

            if (!response) return;

            this.snackBar.open(response.errors, 'Hide')
        }, response => {
            console.log('One time login success failure', response);
            ctrl.appScope.errors = ['Too many requests']
        })
    }

    submitAccessToken() {
        const ctrl = this;

        this.http.post('/auth/token_login', {
            token: ctrl.oneTimeLoginForm.controls.oneTimeToken.value
        }).toPromise().then((response: any) => {
            console.log('One time login success', response);

            if (!response) return;

            ctrl.appScope.no_header = true;
            ctrl.appScope.current_user = response;
            this.headerData.show_header = true;

            window.location.href = 'view/reset_password';

        }, response => {

            console.log('response', response);

            ctrl.snackBar.open('Invalid token', 'Hide');

        })
    }
}
