import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from "../services/api.service";
import {DateTimePeriod, DateTimePeriodService} from "../services/datetime_period.service";
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {HttpClient} from "@angular/common/http";
import * as utils from "../lib/utils"

@Component({
    selector: 'src-edit-history',
    templateUrl: 'edit-history.component.html',
    providers: [DateTimePeriodService],
    encapsulation: ViewEncapsulation.None  //Global Styles
})
export class EditHistoryComponent implements OnInit {
    public showing_hints: boolean = false;
    public hint: string = 'Name';
    dtp: DateTimePeriod;

    constructor(
        public api: ApiService,
        public dialogRef: MatDialogRef<any>,
        public dateTimePeriodService: DateTimePeriodService,
        public snackBar: MatSnackBar, public http: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        //TODO what is the correct way to get this from the parent?
        this.dateTimePeriodService.dtp_complete.promise.then(() => {
            this.dtp = utils.deepCopy(this.dateTimePeriodService.dtp);
            console.log("edit history dtp", this.dtp);
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

    // checkCorrectionReady():boolean{
    //     // if(this.selected_correction_method && this.series && this.calculation){
    //     //     return true;
    //     // }
    //
    // }
}
