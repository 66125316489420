import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {AppScope} from "./app_scope.service";

@Injectable({
    providedIn: 'root'
})
export class PresentationService {

    private body = document.body;
    private presenting: boolean = false;

    readonly presentationStateChanged: Subject<boolean>;

    constructor(private appscope: AppScope) {
        const ctrl = this;
        this.presentationStateChanged = new BehaviorSubject(this.presenting);
        this.appscope.auth_complete.promise.then(() => {
            if (ctrl.appscope.config_name_map.hasOwnProperty("PRESENTATION_MODE")) {
                if (ctrl.appscope.config_name_map.PRESENTATION_MODE.value == 'true' && !ctrl.presenting) {
                    ctrl.togglePresent()
                }
            }
        });
    }

    public togglePresent() {

        if (this.presenting) {
            this.body.classList.remove('large-font');
        } else {
            this.body.classList.add('large-font');
        }
        this.presenting = !this.presenting;

        this.presentationStateChanged.next(this.presenting);
    }
}
