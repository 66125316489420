<div #listGroup [class.printing-mode]="printing"
     cdkDropListGroup style="position:relative !important;float: none !important;display:block !important;">

  <div *ngFor="let section of sections; let i = index"
       [ngClass]="[section.page_break ? section.page_break : '',
       section.hide_on_print ? 'hideonprint' :'', section.printing ? 'printing' : 'not-printing']">
    <print-formatter (printingSection)="printSection(section)" (sectionsChange)="emitSectionChange(section)"
                     *ngIf="editing || printing_sections"
                     [config]="{type:'section'}" [print_object]="section"
                     [printing]="printing"
                     [printing_sections]="printing_sections"></print-formatter>

    <div
        [ngClass]="{'hideonprint' : !section.title}"
        [ngStyle]="{'font-weight':!section.title? 'normal': null, display: !editing && !section.title ? 'none': 'flex'}"
        class="section-title">
      {{section.title ? section.title : '(Section ' + i + ')' }}
      <button (click)="editing_section[i] = !editing_section[i]" *ngIf="editing"
              [ngStyle]="{'background-color': editing_section[i]? 'orange' : ''}"
              mat-button
              matTooltip="Edit section" matTooltipClass="print-section-tooltip"
              matTooltipPosition="above" style="margin-left:20px;">
        <i class="fa fa-edit"></i>
      </button>
      <button *ngIf="editing" [matMenuTriggerFor]="insertSectionMenu" mat-button
              matTooltip="Insert new section" matTooltipPosition="above" style="margin-left:10px;">
        <i class="fa fa-plus"></i>
      </button>
      <mat-menu #insertSectionMenu="matMenu" class="tree-node-menu dark">
        <div style="padding:10px; color:white;">Insert section...</div>
        <button (click)="insertSection(i, 'before')" mat-menu-item>Before</button>
        <button (click)="insertSection(i, 'after')" mat-menu-item>After</button>
      </mat-menu>
    </div>

    <edit-section (deleteSection)="removeRow(i)" (sectionSave)="section=$event;emitSectionChange($event)"
                  *ngIf="editing && editing_section[i]"
                  [index]="i" [section]="section" class="hideonprint"></edit-section>
    <mat-radio-group [(ngModel)]="section.drag_orientation" class="drag_orientation hideonprint">
      <label>Drag Orientation: </label>
      <mat-radio-button value="horizontal">Horizontal</mat-radio-button>
      <mat-radio-button value="vertical">Vertical</mat-radio-button>
    </mat-radio-group>

    <div [cdkDropListOrientation]="section.drag_orientation" [ngClass]="section.class? section.class:'medium'"
         cdkDropList
         class="section"

         (cdkDropListDropped)="drop($event)" [cdkDropListData]="section.tiles">
      <ng-container *ngFor="let tile of section.tiles; let j = index;">
        <div *ngIf="tile.new_line" class="new-line"></div>
        <div [ngClass]="[tile.class? tile.class:'third']" [style.height]="section.string_height" cdkDrag>
          <div [cdkDragHandleDisabled]="!editing" [style.display]="editing ? 'flex' : 'none'"
               cdkDragHandle
               [style.color]="(!tile.show_header && tile.editing) ? 'black' : 'inherit'"
               style="align-items: center; justify-content: center; width: 36px; height: 36px; position: absolute;">
            <i class="fas fa-grip-vertical"></i>
          </div>
          <page-tile (delete)="deleteTile(j, section)"
                     (tileChange)="tileChange($event, section)"
                     [gridEditing]="editing"
                     [dtp]="dateTimePeriodService.dtp"
                     [index]="j"
                     [id]="'page-tile' + i + '.' + j"
                     [ngClass]="{'tile-dtp':tile.parameters && tile.parameters.show_dtp, 'no-format': tile.show_header===false}"
                     [tile]="tile"
                     class="page-tile"
          >
          </page-tile>
        </div>
      </ng-container>
    </div>
  </div>
</div>
