<!--    <ng-container *ngIf="dataSource">-->
<!--      <input (keyup)="applyFilter($event.target.value)" placeholder="Search events"/>-->
<!--    </ng-container>-->

<!--    &lt;!&ndash;TODO style the paginator to match the site&ndash;&gt;-->
<!--    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" [pageSize]="10"-->
<!--                   length="{{tableData ? tableData.length : 0}}" showFirstLastButtons></mat-paginator>-->
<style>
  .highlighted, .highlighted td {
    background: orange !important;
  }
</style>
<div class="table-container" style="overflow:unset;">
  <table *ngIf="dataSource!==undefined" [dataSource]="dataSource" [matSortActive]="columns[0]"
         class="value-driver-tree-table" mat-table
         matSort matSortDirection="asc">

    <ng-container *ngFor="let column of columns;" [matColumnDef]="column">
      <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell>
        <ng-container>{{column | upperfirst}}</ng-container>
      </th>
      <td *matCellDef="let element;" [ngClass]="{'highlighted': element['index']==highlight_index}" mat-cell>
        <div *ngIf="column === 'name'" class="row center">
          <i (click)="editSeries(element)" class="edit-inline hide-xs"></i>
          <a (click)="openChartDialog(element[column])" [textContent]="element[column]" style="cursor:pointer;"></a>
        </div>
        <div *ngIf="!['name', 'parent'].includes(column)" [ngClass]="{'justify-center': column === 'depth'}"
             class="row center">
          <span>{{element[column]}}</span>
        </div>
        <div (mouseenter)="highlight(element);" (mouseleave)="highlight_index = null;"
             *ngIf="column === 'parent'"
             class="row center">
          <span>{{element[column]}}</span>
        </div>
      </td>
    </ng-container>


    <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columns;" mat-row></tr>
  </table>
</div>
