/**
 * Created by phillip on 2016/07/17.
 */

import {Component, OnDestroy, OnInit} from "@angular/core";
import {PlantDataService} from "../services/plant_data.service";
import {ApiService} from "../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {HeaderDataService} from "../services/header_data.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'equipmentSheetFocusView',
    templateUrl: 'equipment_sheet_focus_view.html',

})
export class EquipmentSheetFocusViewCtrl implements OnInit, OnDestroy {
    process: any;
    flowSheet: any;
    components: any[];
    equipment: any;
    sheet_ready: boolean;
    params_subscription: Subscription;

    constructor(private api: ApiService,
                private headerData: HeaderDataService,
                private plantData: PlantDataService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        const ctrl = this;

        ctrl.params_subscription = ctrl.route.params.subscribe(params => {
            this.sheet_ready = false;

            ctrl.process = ctrl.api.process.get(params.processID);
            ctrl.plantData.process_focus = ctrl.process;

            ctrl.process.$promise.then(() => {
                ctrl.buildHeader();
                ctrl.flowSheet = ctrl.plantData.getFlowSheetData(params.processID);
                Promise.all([ctrl.flowSheet.allDataFetched.promise]).then(ctrl.setupEditSheet.bind(this))
            });
        });
    }

    ngOnDestroy(): void {
        this.params_subscription.unsubscribe();
    }

    setupEditSheet() {
        const ctrl = this;
        ctrl.components = [ctrl.plantData.process_focus].concat(ctrl.flowSheet.processes).concat(ctrl.flowSheet.streams);
        ctrl.equipment = ctrl.flowSheet.equipment;
        ctrl.sheet_ready = true;
    };

    buildHeader() {
        const ctrl = this;
        ctrl.headerData.title = 'Equipment: ' + ctrl.process.attributes.name;
    };
}
