<div class="my-security form-dialog">
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true" >
      <mat-tab label="Reset Password">
        <form class="form-section column space-between">
          <reset-password></reset-password>
        </form>
      </mat-tab>

      <mat-tab *ngIf="appScope.current_user" label="2F Authentication">
        <form class="form-section column space-between">
          <h3>Two Factor Authentication Setup</h3>
          <div class="row center">
            <button mat-button class="bordered-button" type="submit" (click)="show_otp=!show_otp">Show OTP</button>
            <button mat-button class="bordered-button" type="submit" (click)="resetOTPSeed()">Reset OTP</button>
            <mat-checkbox *ngIf="user"
                          (click)="enable_my_2fa();checkedChanged($event)"
                          [ngModel]="user.attributes.require_otp"
                          name="require_otp" [disabled]="checkDisableOTP()"
                          matTooltip="You are not allowed to disable OTP. Please contact your system administrator."
                          [matTooltipDisabled]="!checkDisableOTP()" matTooltipPosition="above"> Enabled
            </mat-checkbox>
          </div>
          <div class="row ">
            <p *ngIf="user.attributes.require_otp">
              Your 2FA is enabled.
              Pleased click Reset OTP and Show OTP to re-scan the QR Code if required.
            </p>
            <p *ngIf="!user.attributes.require_otp">
              Your 2FA is not enabled. Please tick the checkbox to enable 2FA, click on the show OTP button and scan the
              QR
              code with the
              FreeOTP/Google Authenticator App on your smartphone and to enable and utilise 2FA.
            </p>
          </div>
          <p>I'm done, take me to the <a routerLink="/">Login</a> page!</p>
          <p *ngIf="show_otp"><img id="qrcode" src="/auth/qrcode" alt=""/></p>
        </form>
      </mat-tab>

      <mat-tab *ngIf="appScope.current_user" label="Auth Token Setup">
        <form class="form-section column space-between">
          <div class="row">
            <mat-form-field>
              <mat-label>Days before expiring</mat-label>
              <input matInput type="number" step="any" id="days_before_expiry" [(ngModel)]="days" name="days"/>
            </mat-form-field>

            <!--FIXME this checkbox is not reflecting the specified value-->
            <mat-checkbox *ngIf="appScope.current_user" type="checkbox" id="auth_token" class="self-center"
                          [value]="appScope.current_user.auth_token_active"
                          [disabled]="false">Auth Token active
            </mat-checkbox>

          </div>
          <div class="row">
            <button mat-button (click)="generateToken()" class="bordered-button">Create</button>
            <button mat-button (click)="deactivateToken()" class="bordered-button">Remove</button>
          </div>
          <div class="row">
            <label>Auth Token: Please copy this, Wire does not store a copy of this token</label>
          </div>
          <div class="row">
            <div style="word-break: break-all;" >{{auth_token}}  </div>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
</div>


