<div class="form-dialog">
  <h1>Correction
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content class="mat-dialog-content" [ngClass]="{'showing-hints':showing_hints}">
    <mat-tab-group [disableRipple]="true">
      <mat-tab label="Basic">
        <date-period [no_url]="true" [immutable]="true" class="date-period inline align-top"></date-period>
        <div style="margin:10px 0;">Select the time period here for which to apply a correction to</div>

        <apply-correction></apply-correction>

      </mat-tab>

    </mat-tab-group>

  </mat-dialog-content>

  <div class="action-buttons">
    <button class="btn-close" (click)="closeDialog()">Close</button>
  </div>
</div>

<!--<div class="form-dialog">-->
<!--<h1>Correction-->
<!--<div (click)="showing_hints=!showing_hints">-->
<!--<ng-container *ngIf="showing_hints">Hide hints</ng-container>-->
<!--<ng-container *ngIf="!showing_hints">Show hints</ng-container>-->
<!--</div>-->
<!--</h1>-->
<!--<form class="form-section column stretch">-->
<!--<div mat-dialog-content style="background:white;">-->
<!--&lt;!&ndash;TODO improve user flow and apply the hints that have been provided here&ndash;&gt;-->

<!--<mat-label class="row">Series</mat-label>-->
<!--<select-search *ngIf="series_list.length>0"-->
<!--(selectChange)="series=$event;getCorrectionHistory()"-->
<!--[data]="series_list"-->
<!--&gt;</select-search>-->
<!--<mat-label class="row">Timespan</mat-label>-->

<!--<ng-container>-->
<!--<date-period [icons]="false" class="min datetime"></date-period>-->
<!--</ng-container>-->

<!--<mat-form-field class="row">-->
<!--<mat-label class="row">Comment</mat-label>-->
<!--<textarea matInput placeholder="Provide a reason here for editing historical data"-->
<!--[(ngModel)]="comment"></textarea>-->
<!--</mat-form-field>-->

<!--<mat-label class="row"> Correction method</mat-label>-->
<!--<mat-select placeholder="Select correction method here" [(value)]="selected_correction_method"-->
<!--style="display: inline-block" (selectionChange)="clearVariables()">-->
<!--<mat-option *ngFor="let item of correctionMethods" [value]="item">-->
<!--{{item}}-->
<!--</mat-option>-->
<!--</mat-select>-->

<!--&lt;!&ndash;<div *ngIf="selected_correction_method=='Apply constant value'">&ndash;&gt;-->
<!--&lt;!&ndash;This value overrides the current values in WIRE for the *selected series* over the *selected timespan* and *sample period* and replaces it with the constant value you have provided.&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->

<!--<ng-container *ngIf="series_list.length>0 && selected_correction_method==correctionMethods[1]">-->
<!--<mat-label class="row">Select calculation series</mat-label>-->

<!--<select-search [data]="series_list" [(value)]="calculation"-->
<!--(selectChange)="calculation=$event;"></select-search>-->

<!--<div>-->
<!--For the selected calcuation series: <br>-->
<!--<div *ngIf="calculation ">-->
<!--{{calculation.attributes.name + ' - ' + calculation.attributes.description }}-->
<!--</div>-->
<!--<div *ngIf="!calculation ">-->
<!--<br>-->
<!--</div>-->
<!--Please enter the target corrected total for the selected time period:-->
<!--</div>-->

<!--<div style="display:flex; width:100%" *ngIf="selected_correction_method==correctionMethods[1]">-->
<!--<div class="row" style="flex: 1">-->
<!--<mat-label>Target</mat-label>-->
<!--<input style="flex:1; margin-left: 10px" matInput type="number" [(ngModel)]="target"-->
<!--placeholder="Expected corrected total" name="target">-->
<!--</div>-->
<!--<div class="row" style="flex: 1">-->
<!--<mat-label>Use offset</mat-label>-->
<!--<mat-checkbox [(ngModel)]="use_offset"></mat-checkbox>-->
<!--</div>-->
<!--</div>-->

<!--<div>-->
<!--<button mat-button *ngIf="selected_correction_method==correctionMethods[1]"-->
<!--(click)="getCorrectionFactor()">-->
<!--Determine Correction-->
<!--</button>-->

<!--<span> {{correction_message}}</span>-->
<!--</div>-->
<!--</ng-container>-->

<!--<div class="row" *ngIf="selected_correction_method==correctionMethods[0]">-->

<!--<mat-form-field>-->
<!--<mat-label class="row">Value</mat-label>-->
<!--<input matInput type="number" [(ngModel)]="value" placeholder="Apply constant value" name="value">-->

<!--</mat-form-field>-->

<!--</div>-->

<!--<div class="row">-->

<!--<mat-form-field-->
<!--*ngIf="selected_correction_method==correctionMethods[1]">-->
<!--<mat-label class="row">Offset value</mat-label>-->
<!--<input matInput type="number" [(ngModel)]="offset" placeholder="Adjusts values by constant offset"-->
<!--name="offset">-->
<!--&lt;!&ndash;This offset moves the current values in WIRE that you have selected along the vertical axis&ndash;&gt;-->

<!--</mat-form-field>-->

<!--<ng-container *ngIf="selected_correction_method==correctionMethods[1]">-->
<!--<mat-form-field>-->
<!--<mat-label class="row">Correction factor</mat-label>-->
<!--<input matInput type="number" [(ngModel)]="factor" placeholder="Enter a value OR calculate it"-->
<!--name="factor">-->
<!--</mat-form-field>-->
<!--</ng-container>-->

<!--</div>-->

<!--&lt;!&ndash;This method of WIRE data correction can be applied in two ways.-->
<!--1. By *series* selection-->
<!--Provide a value a total that this series should be for a given time period (correction total). This then allows WIRE to calculate and apply-->
<!--a correction factor to the series for the selected time period.-->

<!--Alternatively, you can proved a correction factor that you have pre-determined (in the correction total input section) and WIRE will apply-->
<!--the correction factor to the selected time period.-->

<!--2. By *calculation* selection-->

<!--If the series that you wish to correct is a calculation, and you know the total that this calculation should be, you can use this method.-->
<!--You can select one of the series that this calculation depends on <here> that you wish to correct for the selected time period and WIRE will change-->
<!--the selected series to achieve the correct calculation total that you provided. &ndash;&gt;-->

<!--<div>-->

<!--<button mat-button *ngIf="selected_correction_method" (click)="applyCorrectionFactor()">Apply</button>-->
<!--<button mat-button (click)="closeDialog()">Close</button>-->
<!--</div>-->
<!--</div>-->
<!--</form>-->

