<mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
  <mat-tab-group [disableRipple]="true">
    <mat-tab label="Properties">
      <form class="form-section column space-between">
        <div class="column">
          <div *ngIf="!efc.component_data.hasOwnProperty('id') || efc.addEquipmentType=='existing'" class="row">
            <mat-form-field>
              <mat-label>Select to add new or existing equipment</mat-label>
              <mat-select [(ngModel)]="efc.addEquipmentType" name="add_equipment_type"
                          placeholder="New or existing equipment">
                <mat-option value="new">New</mat-option>
                <mat-option value="existing">Existing</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
                (mouseenter)="hint='Icon'"
                *ngIf="(component.hasOwnProperty('id') && efc.addEquipmentType!=='existing') || efc.addEquipmentType=='new'">
              <mat-label style="display:flex;align-items:center;">
                Select an Icon <img height="30px" src="{{ efc.component_data.attributes.icon }}" width="30px"/>
              </mat-label>
              <!--<label>Select Icon<img src="{{ efc.component_data.attributes.icon }}" width="30px" height="30px"/></label>-->
              <mat-select [(ngModel)]="efc.component_data.attributes.icon" name="equipment_icon">
                <mat-option *ngFor="let img of efc.images" [value]="img" class="image-options">
                  <img height="30px" src="{{ img }}" width="30px"/><span>{{ img.split("/")[4].split('.')[0] }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="efc.addEquipmentType=='existing'">
              <mat-label *ngIf="efc.avail_equipment.length > 0">Select equipment</mat-label>
              <mat-label *ngIf="efc.avail_equipment.length <= 0">Loading equipment...</mat-label>
              <mat-select (selectionChange)="efc.selectExisting()" [(ngModel)]="efc.existing_component"
                          name="existing_equipment">
                <!--<mat-select-label> {{efc.component_data.attributes.name}}</mat-select-label>-->
                <mat-option *ngFor="let equipment of efc.avail_equipment" [value]="equipment">
                  {{equipment.attributes.name}} - {{equipment.attributes.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="(efc.component_data.hasOwnProperty('id') && efc.addEquipmentType!=='existing')
                     || efc.addEquipmentType=='new'" class="row">
            <mat-form-field (mouseenter)="hint='Name'" style="flex-basis:50%;flex-grow:0;">
              <label>Name</label>
              <input [(ngModel)]="efc.component_data.attributes.name" matInput name="name" placeholder="">
            </mat-form-field>

            <mat-form-field (mouseenter)="hint='Description'" style="flex-basis:50%;flex-grow:0;">
              <label>Description</label>
              <input [(ngModel)]="efc.component_data.attributes.description" matInput name="description" placeholder="">
            </mat-form-field>

            <div class="tip-box" style="flex-grow:1;">
              <div [ngClass]="{'showing': hint=='Name'}" class="tip">
                <label>Name</label>
                <div>
                  The name of the equipment.
                </div>
              </div>
              <div [ngClass]="{'showing': hint=='Description'}" class="tip">
                <label>Description</label>
                <div>
                  A more lengthy description of the equipment.
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </mat-tab>

    <mat-tab *ngIf="efc.component_data.hasOwnProperty('id')" label="Relationships">
      <form class="form-section column space-between">
        <div class="column">
          <div class="row">
            <mat-form-field>
              <mat-label>Parent component</mat-label>
              <mat-select (selectionChange)="efc.moveEquipment(chart_component.parent_component.data.type);"
                          [(ngModel)]="efc.selected_parent" [compareWith]="efc.matSelectCompare"
                          [disabled]="true"
                          name="selected_parent">
                <mat-option *ngFor="let parent of efc.parent_list"
                            [value]="parent">{{parent.attributes.name}} - {{parent.attributes.description}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field (mouseenter)="hint='EquipmentType'" style="flex-basis:50%;flex-grow:0;">
              <mat-label>Equipment Type</mat-label>
              <mat-select (selectionChange)="efc.fillConstantTemplate()"
                          [(ngModel)]="efc.component_data.relationships.component_type.data"
                          [compareWith]="efc.matSelectCompare"
                          name="equipment_type">
                <mat-option *ngFor="let component_type of efc.component_types" [value]="component_type">
                  {{component_type.attributes.name}} - {{component_type.attributes.description}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button (click)="efc.fillSeriesTemplate()" (mouseenter)="hint='FillSeriesTemplate'"
                    [hidden]="!efc.component_data.relationships.component_type.data"
                    class="btn"
                    style="justify-self: center;align-self:center;">Fill Template
            </button>

            <div class="tip-box" style="flex-grow:1;">
              <div [ngClass]="{'showing': hint=='EquipmentType'}" class="tip">
                <label>Equipment Type</label>
                <div>
                  This will be used to identify the template of series types that are applicable for this equipment.
                  After creating the equipment, the 'Fill series template' button can be used to
                  add these series types to this equipment.
                </div>
              </div>

              <div [ngClass]="{'showing': hint=='FillSeriesTemplate'}" class="tip">
                <label>Fill Series Template</label>
                <div>
                  This will be used to identify the template of series types that are applicable for this equipment.
                  After creating the equipment, the 'Fill series template' button can be used to
                  add these series types to this equipment.
                </div>
              </div>
            </div>
          </div>

          <h4 *ngIf="efc.constant_properties_ready === true">Custom Properties</h4>
          <constant-component-table *ngIf="efc.constant_properties_ready === true" [component]="efc.component_data"
                                    [constant_components]="efc.constant_components"
                                    [constant_property_dict]="efc.constant_property_dict"
                                    [flowchart]="efc.chart"
                                    class="row">
          </constant-component-table>

          <h4>Series</h4>
          <div class="row">
            <div (mouseenter)="hint='select_series'" class="select-search-many" style="flex-grow:2">
              <select-many-search [(value)]="efc.selected_series"
                                  [classes]="'fill'"
                                  [data]="efc.plantData.all_series"
                                  [fill_stubs]="true"
                                  [label]="'Select a series'"

              >
              </select-many-search>
              <!--(selectChange)="selected_series=$event;"-->
            </div>
            <div style="align-self:center;display:flex;">
              <button (click)='efc.addSeries()' class="icon" matTooltip="Add to process">
                <i class="fa fa-arrow-down"></i></button>
              <button (click)="efc.showSeriesForm()" class="icon-new" matTooltip="Add new series">
              </button>
              <!--<button class="btn" (click)="efc.setTemplate()" matTooltip="Set Template"><i class="fa fa-tasks" ></i></button>-->
            </div>
            <!--<div class="hint">-->
            <!--Select a series and use the Add to process button to add it to this process. The Add new series button can be used to create a new series which will be added to this process.-->
            <!--</div>-->
          </div>

          <series-component-table *ngIf="efc.series_properties" [all_series]="efc.plantData.all_series"
                                  [component]="efc.component_data"
                                  [deleted_component_ids]="efc.deleted_component_ids"
                                  [flowchart]="efc.chart"
                                  [series_properties]="efc.series_property_dict"
                                  class="row">
          </series-component-table>

          <h4 *ngIf="efc.series_permissions" style="margin-top:20px;">Constant Series Data </h4>
          <!--<div class="hint" ng-if="$parent.$parent.showHints && show_constants">-->
          <!--Manage constant values for a series in the form(s) below. Use the 'Add new' button to add a new constant value for a series. To remove an entry, delete the value at the required date-time. Use the 'Save' button to save all changes.-->
          <!--</div>-->

          <ng-container *ngIf="efc.series_permissions">
            <div *ngFor="let series_component of efc.component_data.relationships.series_components.data;"
                 style="min-width:650px;">
              <ng-container *ngIf="series_component.relationships.series.data && (
                                series_component.relationships.series.data.attributes.fill_method=='Constant'
                                || series_component.relationships.series.data.attributes.fill_method=='Constant Monthly Flatten')
                                && efc.series_permissions[series_component.relationships.series.data.id]
                                && efc.series_permissions[series_component.relationships.series.data.id].indexOf('view_process_data') > -1">

                <div class="row">
                  <label
                      (click)="series_component.relationships.series.data.hide_constants=!series_component.relationships.series.data.hide_constants"
                      style="color:gray; text-decoration: underline;padding-top:20px;cursor:pointer;">
                    {{series_component.relationships.series.data.attributes.name}}
                    <i [ngClass]="{'fa-angle-up': !series_component.relationships.series.data.hide_constants, 'fa-angle-down': series_component.relationships.series.data.hide_constants}"
                       aria-label="Hide data values"
                       class="fa fa-angle-up"
                       matTtooltip="Hide data values" role="button" style="border:none; margin-left:12px;" tabindex="0">
                    </i></label>
                </div>
                <constant-series-data *ngIf="!series_component.relationships.series.data.hide_constants"
                                      [series]="series_component.relationships.series.data"
                                      [series_permissions]="efc.series_permissions">
                </constant-series-data>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </form>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<div class="action-buttons">
  <button (click)="close()" *ngIf="dialog" class="btn-close">Close</button>
  <button (click)="save()" class="btn-save" style="margin-right:10px;">Save</button>
</div>

<!--<md-content layout-padding layout="column">-->

<!--<div layout="row">-->
<!--<md-input-container flex="40">-->
<!--<label>Parent component</label>-->
<!--<md-select ng-disabled="true" ng-model="selectedParent" ng-change="moveEquipment(chart_component.parent_component.data.type);"  ng-model-options="{trackBy: '$value.id'}">-->
<!--<md-option ng-repeat="parent in parent_list" ng-value="parent">{{parent.attributes.name}} - {{parent.attributes.description}}</md-option>-->
<!--</md-select>-->
<!--</md-input-container>-->
<!--<md-input-container  flex="50">-->
<!--<label>Equipment Type</label>-->
<!--<md-select ng-model="component.relationships.component_type.data" ng-model-options="{trackBy: '$value.id'}" ng-change="fillConstantTemplate()" >-->
<!--<md-option ng-repeat="component_type in component_types" ng-value="component_type">{{component_type.attributes.name}} - {{component_type.attributes.description}}</md-option>-->
<!--</md-select>-->
<!--</md-input-container>-->
<!--<md-button class="md-fab md-mini md-raised md-mini md-primary" ng-click="fillSeriesTemplate()" ng-if="component.relationships.component_type.data"><i class="fa fa-arrow-down" aria-label="Fill series template" ></i><md-tooltip>Fill series template</md-tooltip></md-button>-->
<!--</div>-->

<!--<div layout="column" ng-show="component.hasOwnProperty('id') && addEquipment !=='existing'">-->
<!--<div layout="row" ng-show="constant_properties_ready === true" class="section" ng-click="show_constant_properties=!show_constant_properties">-->
<!--<h4>Custom Properties-->
<!--<i class="fa hideonprint fa-angle-down" ng-class="{'fa-angle-up': show_constant_properties, 'fa-angle-down': !show_constant_properties}" aria-label="Show series" role="button"></i>-->
<!--</h4>-->
<!--</div>-->

<!--<script type="text/ng-template" id="tile_selection.html">-->
<!--<div style="padding:5px;" class="grid" layout-fill>-->
<!--<label title="id: {{ constant_component.id }}">{{ constant_property_dict[constant_component.relationships.constant_property.data.id].attributes.name}}: </label>-->

<!--<nobr>-->
<!--<input ng-change="addConstantComponent(constant_component)" ng-model-options="{ debounce: 1000 }" ng-model="constant_component.attributes.value"-->
<!--ng-if="constant_property_dict[constant_component.relationships.constant_property.data.id].attributes.data_type=='float'"-->
<!--type="number" step="any"/>-->
<!--<input ng-change="addConstantComponent(constant_component)" ng-model="constant_component.attributes.value" ng-model-options="{ debounce: 1000 }"-->
<!--ng-if="constant_property_dict[constant_component.relationships.constant_property.data.id].attributes.data_type=='string'"-->
<!--type="text"/>-->
<!--<md-datepicker ng-change="addConstantComponent(constant_component)" no-reset ng-model="constant_component.attributes.value" ng-model-options="{ debounce: 1000 }"-->
<!--ng-if="constant_property_dict[constant_component.relationships.constant_property.data.id].attributes.data_type=='datetime'"-->
<!--class="min-date"></md-datepicker >-->
<!--<i class="fa fa-trash hideonprint" ng-if="constant_component.hasOwnProperty('id')" ng-click="deleteConstantComponent(constant_component)" style="margin-top:10px; margin-left: 5px;"></i>-->
<!--</nobr>-->
<!--</div>-->
<!--</script>-->
<!--<constant-component-table layout="row" ng-if="show_constant_properties" flex="100"-->
<!--component = "component"-->
<!--constant-property-dict = "constant_property_dict"-->
<!--constant-components = "constant_components"-->
<!--flowchart = "chart">-->

<!--</constant-component-table>-->
<!--<div layout="row" class="section" ng-click="show_series=!show_series">-->
<!--<h4>Series-->
<!--<i class="fa hideonprint fa-angle-down" ng-class="{'fa-angle-up': show_series, 'fa-angle-down': !show_series}" aria-label="Show series" role="button"></i>-->
<!--</h4>-->
<!--</div>-->

<!--<div layout="row" ng-if="show_series" ng-cloak>-->
<!--<div flex="80">-->
<!--<series-list-component-->
<!--series-list = "plantData.all_series"-->
<!--model = "$parent.selected_series"-->
<!--options-model = "'series'"-->
<!--width = "'100%'"-->
<!--disabled = "disabled">-->
<!--</series-list-component>-->
<!--</div>-->
<!--<div flex="20" layout-align="end center">-->
<!--<md-button class="md-fab md-mini md-raised md-mini md-primary" ng-click='addSeries()'><i class="fa fa-arrow-down" ng-disabled="disabled"></i><md-tooltip>Add to equipment</md-tooltip></md-button>-->
<!--<md-button class="md-fab md-mini md-raised md-mini md-primary" ng-click="showSeriesForm()" aria-label="Add New Series"><i class="fa fa-plus" ></i><md-tooltip>Add new equipment</md-tooltip></md-button>-->
<!--</div>-->
<!--<div class="hint" ng-if="$parent.$parent.showHints">-->
<!--Select a series and use the Add to equipment button to add it to this equipment. The Add new series button can be used to create a new series which will be added to this equipment.-->
<!--</div>-->
<!--</div>-->

<!--<series-component-table layout="row" ng-if="series_properties.$promise && show_series" flex="100"-->
<!--component = "component"-->
<!--deleted-component-ids = "deleted_component_ids"-->
<!--series-properties = "series_property_dict"-->
<!--series-list = "plantData.all_series"-->
<!--flowchart = "chart">-->
<!--</series-component-table>-->

<!--<div layout="row" class="section" ng-click="show_constants=!show_constants">-->
<!--<h4>Constant Series Data-->
<!--<i class="fa hideonprint fa-angle-down" style="border:none; margin-left:12px;" ng-class="{'fa-angle-up': show_constants, 'fa-angle-down': !show_constants}" aria-label="Show constant series data values" md-labeled-by-tooltip="md-tooltip-130" role="button" tabindex="0">-->
<!--</i></h4>-->
<!--</div>-->
<!--<div class="hint" ng-if="$parent.$parent.showHints && show_constants">-->
<!--Manage constant values for a series in the form(s) below. Use the 'Add new' button to add a new constant value for a series. To remove an entry, delete the value at the required date-time. Use the 'Save' button to save all changes.-->
<!--</div>-->

<!--<div ng-if="show_constants" ng-repeat="series_component in component.relationships.series_components.data" style="min-width:650px;">-->
<!--<div ng-repeat="series in series_component.relationships.series" ng-if="(series.attributes.fill_method=='Constant' || series.attributes.fill_method=='Constant Monthly Flatten') && seriesPermissions[series.id].indexOf('view_process_data') > -1">-->
<!--<div>-->
<!--<div layout="row" >-->
<!--<label ng-click="series.hide_constants=!series.hide_constants" style="color:gray; text-decoration: underline;padding-top:20px;">{{series.attributes.name}} <i class="fa fa-angle-up" style="border:none; margin-left:12px;" ng-class="{'fa-angle-up': !series.hide_constants, 'fa-angle-down': series.hide_constants}" aria-label="Hide data values" md-labeled-by-tooltip="md-tooltip-130" role="button" tabindex="0">-->
<!--</i></label>-->
<!--</div>-->
<!--<constant-series-data ng-if="!series.hide_constants"-->
<!--series="series"-->
<!--series-permissions="seriesPermissions">-->
<!--</constant-series-data>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->

<!--</div>-->

