<div [class.editing-mode]="editing" [class.printing-mode]="printing" [class.printing-sections-mode]="printing_sections"
     class="page traveler">
  <ng-container *ngIf="json?.layout=='column'">
    <div *ngFor="let section of sections; let i = index"
         [ngClass]="[section.page_break ? section.page_break : '', section.hide_on_print ? 'hideonprint' :'',
         section.printing ? 'printing' : 'not-printing']">
      <print-formatter (printingSection)="printSection($event)" (sectionsChange)="save_sections(sections)"
                       *ngIf="editing || printing_sections"
                       [config]="{'type':'section'}"
                       [print_object]="section" [printing]="printing"
                       [printing_sections]="printing_sections"></print-formatter>
      <div *ngIf="section.title" class="section-title">{{section.title}}</div>
      <div *ngFor="let tile of section.tiles; let j = index" [ngClass]="[tile.page_break ? tile.page_break : '', tile.hide_on_print ? 'hideonprint' :'',
           tile.printing ? 'printing' : 'not-printing']"
           class="section">
        <page-tile [tile]="tile"
                   [index]="j"
                   [id]="'page-tile' + i + '.' + j"
                   [dtp]="dateTimePeriodService.dtp"
                   [ngClass]="{'tile-dtp':tile.parameters && tile.parameters.show_dtp,'no-format': tile.show_header===false}"
                   (tileChange)="save_tile($event, i, j)" class="column-tile"
                   (delete)="delete_tile(j, section)">
        </page-tile>
        <print-formatter (printingSection)="printSection(section)" (sectionsChange)="save_sections(sections)"
                         *ngIf="editing || printing_sections"
                         [config]="{'type':'tile'}" [print_object]="tile"
                         [printing]="printing" [printing_sections]="printing_sections"
                         style="width:100%;"></print-formatter>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(json&& json.layout=='tile') || (json && !json.layout)">
    <src-sortable-grid-list (sectionsChange)="save_sections($event)" [editing]="editing"
                            (printingSection)="printSection($event)" [printing]="printing"
                            [printing_sections]="printing_sections"
                            [sections]="sections"></src-sortable-grid-list>
  </ng-container>
  <ng-container *ngIf="isEmpty() === true">
    <div class="empty-dashboard">
      <h1>This page is a blank canvas for you to build out.</h1>

      <p>
        <b>Try adding graphs or charts, paragraph tiles, log sheets, series tables or flowcharts to help you better
          understand your operations.</b>
      </p>
      <p>
        A <b>graph</b> is a <b>diagram</b> of a mathematical function.<br/>
        A <b>chart</b> is a graphic representation of data, where a line <b>chart</b> is one form.
      </p>
      <p>
        For further assistance visit our help centre or email
        <a href="mailto:info@metalmanagementsolutions.com" target="_blank">info@metalmanagementsolutions.com</a> for
        guidance.
      </p>
    </div>
  </ng-container>

</div>

<div class="print-footer displayonprint">Powered by MMS WIRE. https://www.metalmanagementsolutions.com</div>