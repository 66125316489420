import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Account, AccountService} from "../../services/account.service";
import {Subject} from "rxjs";
import {ApiService} from "../../services/api.service";
import {Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import * as _ from "lodash";

@Component({
    selector: 'account-filter',
    templateUrl: './account-filter.component.html',
    styleUrls: ['../account-picker/account-picker.component.less', './account-filter.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class AccountFilterComponent implements OnInit, OnDestroy {
    public accounts: Account[] = [];
    public selected_account_id: string;
    placeholder: string = 'Loading accounts...';
    @Input() allow_blank: boolean = false;
    @Output() accountFilterEmitter: EventEmitter<any> = new EventEmitter();
    private onDestroy: Subject<void> = new Subject();

    constructor(private accountService: AccountService,
                private api: ApiService,
                private router: Router) {
    }

    ngOnInit() {
        this.accountService.accountsChanged
            .pipe(takeUntil(this.onDestroy))
            .subscribe(response => {
                this.placeholder = 'Filter by account';
                let accounts = response;
                const account_parts = _.partition(accounts, a => !a.view_only);
                accounts = account_parts[0].concat(account_parts[1]);

                this.accounts = accounts;
            });
        this.accountService.activeAccountChanged
            .pipe(takeUntil(this.onDestroy))
            .subscribe(response => {
                this.selected_account_id = response.account_id;
            });
        if (this.allow_blank === true) {
            this.selected_account_id = null;
        }
    }

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }

    changeAccount(account_id: string) {
        const ctrl = this;
        ctrl.accountFilterEmitter.emit(ctrl.accounts.find(acc => acc.id === account_id));

    }
}
