<div #context_tile [ngStyle]="{'border-color':category_colour}" class="context-tile">
  <ng-container *ngIf="!config.selected_series">
    Please select a series for this tile.
  </ng-container>
  <ng-container *ngIf="show_header !==true">
    <div *ngIf="title" class="title" style="cursor:pointer;"><span
        (click)="openChartDialog(config.selected_series.attributes.name)">{{title}}</span></div>
    <div *ngIf="!title && config.selected_series" class="title"
         title="{{config.selected_series.attributes | nameOrDescription}}">{{config.selected_series.attributes | nameOrDescription}}</div>
    <div *ngIf="config.labels?.sub_title" class="sub-title">{{config.labels?.sub_title}}</div>
  </ng-container>
  <div [ngClass]="{'full': !config.value_only}" class="context-layout">
    <div
        [ngStyle]="{'margin-top': show_header ===true ? '10px' : '0','margin-bottom': show_header===true ? '-10px' : '0'}"
        class="primary-value primary-tile">
      <span *ngIf="summary">{{summary[config.primary_value]|significantNumbers:true}}</span>
      <span *ngIf="config.show_unit && config.series_unit">{{config.series_unit}}</span>
    </div>
    <div #context_content class="row context-content">
      <generic-chart #generic *ngIf="config.chart_type==='custom-chart'" [config]="config.chart_config"
                     class="chart">
      </generic-chart>
      <sparkline *ngIf="(!config.chart_type || config.chart_type==='sparkline') &&  summary"
                 [data_points]="summary.Sparkline"
                 [series_name]="config.selected_series.attributes.name"
                 [height]="500"
                 class="chart"></sparkline>
      <div class="primary-vdt">
        <div #primary_value class="primary-value">
          <span *ngIf="summary"
                [ngClass]="{'favourable': summary[est_favourability_mapping[config.primary_value]]===true,
                'unfavourable': !summary[est_favourability_mapping[config.primary_value]]}">
            {{summary[config.primary_value]|significantNumbers:true}}</span>
          <span *ngIf="config.show_unit && config.series_unit">{{config.series_unit}}</span>

        </div>
      </div>
      <div *ngIf="summary && !config.value_only" class="secondary-values column">
        <div>{{seriesData.column_dict[config.secondary_val_1]?.abbr ?
            seriesData.column_dict[config.secondary_val_1].abbr : config.secondary_val_1 | upperfirst}}:
          <span
              [ngClass]="{'favourable': summary[est_favourability_mapping[config.secondary_val_1]]===true,
                'unfavourable': !summary[est_favourability_mapping[config.secondary_val_1]]}">{{summary[config.secondary_val_1] |significantNumbers:true}}</span>

        </div>
        <div>{{seriesData.column_dict[config.secondary_val_2]?.abbr ?
            seriesData.column_dict[config.secondary_val_2].abbr : config.secondary_val_2 | upperfirst}}:
          <span
              [ngClass]="{'favourable': summary[est_favourability_mapping[config.secondary_val_2]]===true,
                'unfavourable': !summary[est_favourability_mapping[config.secondary_val_2]]}">{{summary[config.secondary_val_2] |significantNumbers:true}}</span>

        </div>
      </div>
    </div>
  </div>
</div>