import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {MatDialog, MatSnackBar} from "@angular/material";
import {SeriesDataService} from "../../services/series_data.service";
import {PlantDataService} from "../../services/plant_data.service";
import {AppScope} from "../../services/app_scope.service";

@Component({
    selector: 'flowchart-series-component',
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'flowchart_series_component.component.html',
    styleUrls: ['flowchart.less']
})

export class FlowchartSeriesComponentComponent implements OnInit {
    @Input() group: any;
    @Input() groups_selected: any = {};
    @Input() series: any;

    constructor(private seriesData: SeriesDataService, public appScope: AppScope,
                private plantData: PlantDataService, private snackBar: MatSnackBar,
                public dialog: MatDialog) {

    }

    ngOnInit(): void {
    }
}