import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import 'core-js/es7/reflect'
import 'zone.js/dist/zone'
import "jquery";
import 'jquery-ui-dist/jquery-ui'
import './../node_modules/pivottable/dist/pivot'
import './../node_modules/pivottable/dist/c3_renderers'
import './../node_modules/pivottable/dist/d3_renderers'
import './../node_modules/pivottable/dist/export_renderers'

import {environment} from './app/environments/environment';
import {AppModule} from './app/app.module';

// platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
    // bootstrap angular1
}).catch(err => console.error(err));
//
//



