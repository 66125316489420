import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserData} from "../../services/user_data.service";
import {AppScope} from "../../services/app_scope.service";
import {ApiService} from "../../services/api.service";
import {User} from "../../user_control/user_list.component";
import {HeaderDataService} from "../../services/header_data.service";

@Component({
    selector: 'page-list-view',
    templateUrl: './page-list-view.component.html',
    styleUrls: ['./page-list-view.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class PageListViewComponent implements OnInit {
    user: any;
    restricted_access: boolean;
    actual_restricted_dashboards: any;
    all_dashboards: any[];
    users: User[];
    user_dict: any = {};
    users_ready: boolean;
    // ready:any={
    //     public_ready:false,
    //     workshops_ready:false,
    //     reports_ready:false,
    //     my_ready:false,
    //     private_ready:false,
    //     restricted_ready:false
    // }

    constructor(public userData: UserData,
                public appScope: AppScope,
                private api: ApiService,
                private headerData: HeaderDataService) {
    }

    ngOnInit() {
        this.headerData.title = 'All Pages';
        this.headerData.add_new_page_form = true;

        const ctrl = this;
        Promise.all([ctrl.appScope.auth_complete.promise,]).then(() => {
            ctrl.loadDashboards();
        })
    }

    loadDashboards() {
        const ctrl = this;
        let promises: any[] = [];

        promises.push(ctrl.api.session_state_light.search().toPromise().then((dashboards) => {
            ctrl.all_dashboards = dashboards.data.filter(item => item.attributes.report == 'Dashboard')
        }));
        // promises.push(ctrl.api.session_state_light.search(ctrl.api.prep_q([{
        //     'op': 'like', 'name': 'report', val: "Dashboard"
        // }])).toPromise().then((dashboards) => ctrl.all_dashboards = dashboards.data));
        ctrl.restricted_access = ctrl.appScope.current_user.restricted_access;

        let $users = ctrl.api.users.search().toPromise().then(response => {
            ctrl.users = response.data;
            ctrl.users.forEach(user => {
                ctrl.user_dict[user.id] = user;
            });
            ctrl.users_ready = true;
        });
        promises.push($users);
        if (ctrl.restricted_access) {
            let $restricted_dashboards = ctrl.userData.getRestrictedDashboards().toPromise().then((response) => {
                ctrl.actual_restricted_dashboards = response.data;
            });
            promises.push($restricted_dashboards);
        }
        Promise.all(promises).then(() => {
            console.log("Page list view: All Dashboards", ctrl.all_dashboards, ctrl.users_ready)
        })
    }
}
