import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import * as utils from "../../../lib/utils"
import {DialogData} from "../../../forms/tile_form.component";
import {MatSnackBar} from "@angular/material";

@Component({
    selector: 'src-recovery-grade-forecast-form',
    templateUrl: './recovery-grade-forecast-form.component.html',
    styleUrls: ['./recovery-grade-forecast-form.component.less', '../../../forms/forms.less'],
    encapsulation: ViewEncapsulation.None

})

export class RecoveryGradeForecastFormComponent implements OnInit {
    full_series_list: any[] = [];
    yAxis: any;
    xAxis: any;
    forecasts: any;
    grade_forecast: any;
    grade_forecast_json: any;

    constructor(private api: ApiService, private dialogRef: MatDialogRef<RecoveryGradeForecastFormComponent>,
                @Inject(MAT_DIALOG_DATA) private data: DialogData, private snackBar: MatSnackBar) {
    }

    ngOnInit() {
        const ctrl = this;
        ctrl.grade_forecast = utils.deepCopy(this.data['grade_forecast_copy']);
        console.log('input to form:', ctrl.grade_forecast, this.data);
        if (ctrl.grade_forecast) {
            ctrl.grade_forecast_json = ctrl.grade_forecast.attributes.json;
            console.log('input data into form,', ctrl.grade_forecast);
        }
        ctrl.api.series_light.search().toPromise().then((result) => {
            if (!result) return;
            ctrl.full_series_list = result.data;
        })

    }

    onSave() {
        this.dialogRef.close(this.grade_forecast);

        const ctrl = this;
        ctrl.grade_forecast.attributes.json = ctrl.grade_forecast_json;
        ctrl.api.forecast_calculation.patch(ctrl.grade_forecast).then((response) => {
            console.log('save successful', response);
            if (response.status_code == 200) {
                ctrl.snackBar.open('Successfully saved', undefined, {duration: 3000})

            }
        });
    }

    onClose() {
        this.dialogRef.close();
    }

    mapTo(value, axis_name) {
        const ctrl = this;
        ctrl.grade_forecast_json.data.data_model.series_list.forEach((axis, index) => {
            if (axis.axis == axis_name) {
                ctrl.grade_forecast_json.data.data_model.series_list[index].series_id = value.id;
            }
        })
    }

    getValue(axis_name) {
        const ctrl = this;
        let series_id;
        if (ctrl.grade_forecast_json && ctrl.grade_forecast_json.data && ctrl.grade_forecast_json.data.data_model) {
            ctrl.grade_forecast_json.data.data_model.series_list.forEach((axis, index) => {
                if (axis.axis == axis_name) {
                    series_id = axis.series_id;
                }
            });
        }

        if (series_id == undefined || series_id == null) {
            return null
        }

        return this.full_series_list.find(series => series.id == series_id)
    }
}
