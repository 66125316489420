import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import * as $ from 'jquery'
import * as d3 from 'd3';
import {HttpClient} from "@angular/common/http";
import {DateTimePeriod} from "../../services/datetime_period.service";
import {DateTimePeriodService} from "../../services/datetime_period.service";
import {HeaderDataService} from "../../services/header_data.service";
import {TileDataService} from "../../services/tile_data.service";
import {forkJoin, Subject, Subscription} from "rxjs";
import {SeriesDataService} from "../../services/series_data.service";
import {ApiService} from "../../services/api.service";
import {AppScope} from "../../services/app_scope.service";
import {takeUntil} from "rxjs/operators";
import {deepCopy, guid, httpParamSerializer} from "../../lib/utils";

@Component({
    selector: 'pivot-tile',
    templateUrl: './pivot-tile.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PivotTileComponent implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @ViewChild('pivotAnchor', {read: ElementRef, static: true}) pivotAnchor: ElementRef;
    range_map: { [key: string]: { series_list: any[], dtp: DateTimePeriod } } = {};
    $: any;
    buttons: { name: string; func: () => any; params: {}; class: string; HoverOverHint: string; }[];
    stateData: any = {};
    pivotID: string;
    pivotParameters: any;
    pivotData: any;
    private dtpSubscription: Subscription;
    colour_pattern: string[];

    private _config: any = {
        event_types: "",
        pivot_state: {},
        series_list: []
    };

    @Input()
    set config(config: any) {
        this._config = config;
    }

    get config(): any {
        return this._config;
    }

    @Output()
    tileContentChange = new EventEmitter();

    @Input()
    event_types: any;

    @Input()
    showUI: boolean = false;

    ngOnInit() {
        const ctrl = this;

        this.headerData.show_dtp = true;
        this.headerData.add_refresh = true;

        ctrl.buttons = [
            {
                name: 'Edit',
                func: () => {
                    ctrl.showUI = !ctrl.showUI;
                    ctrl.buildPivot()
                },
                params: {},
                class: 'fa small fa-edit hide-xs',
                HoverOverHint: 'Edit Pivot Table '
            },
            {
                name: 'Save',
                func: () => {
                    ctrl.savePivotState()
                },
                params: {},
                class: 'fa small fa-save hide-xs',
                HoverOverHint: 'Save Pivot Table'
            }
        ];

        ctrl.tileData.buttonsChanged.next(ctrl.buttons);

        Promise.all([this.dateTimePeriodService.dtp_complete.promise,
            this.appScope.auth_complete.promise]).then(() => {
            ctrl.colourPattern();
            ctrl.getData();
        });

        this.dateTimePeriodService.dtpReset
            .pipe(takeUntil(this.onDestroy))
            .subscribe((dtp: DateTimePeriod) => {
                this.getData()
            });
    }

    colourPattern() {
        const ctrl = this;
        let default_pattern = ['teal', 'orangered', 'dodgerblue', 'darkslateblue', 'darkorange', 'darkturquoise', 'green', 'olive', 'gray', 'black', 'maroon'];

        if (this.appScope.config_name_map.hasOwnProperty("palette2")) {
            ctrl.colour_pattern = (this.appScope.config_name_map.palette2.value.map(colour => colour.colour)).concat(default_pattern);
        } else {
            ctrl.colour_pattern = default_pattern;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    constructor(
        private http: HttpClient,
        public dateTimePeriodService: DateTimePeriodService,
        private headerData: HeaderDataService,
        public tileData: TileDataService,
        private seriesData: SeriesDataService,
        private api: ApiService,
        private appScope: AppScope) {
        this.pivotID = guid();
    }

    pivotParams() {
        const ctrl = this;
        // @ts-ignore
        let dateFormat = $.pivotUtilities.derivers.dateFormat;
        // @ts-ignore
        let sortAs = $.pivotUtilities.sortAs;

        let pivotParams = ctrl.config.pivot_state;

        // @ts-ignore
        let derivers = $.pivotUtilities.derivers;

        // @ts-ignore
        let inspector = $.pivotUtilities;

        // @ts-ignore
        pivotParams.renderers = $.extend(
            // @ts-ignore
            $.pivotUtilities.renderers,

            // @ts-ignore
            $.pivotUtilities.c3_renderers,
            // @ts-ignore
            $.pivotUtilities.plotly_renderers,
            // @ts-ignore
            $.pivotUtilities.d3_renderers,
            // @ts-ignore
            $.pivotUtilities.export_renderers
        )
        ;

        pivotParams.menuLimit = 1000;
        pivotParams.showUI = ctrl.showUI;
        pivotParams.onRefresh = function (config) {
            let state = JSON.parse(JSON.stringify(config));

            //delete some values which are functions
            delete state["aggregators"];
            delete state["renderers"];
            //delete some bulky default values
            delete state["rendererOptions"];
            //delete state["localeStrings"];

            ctrl.stateData = state;
        };
        let element = $(this.pivotAnchor.nativeElement);

        if (ctrl.config.event_types.length <= 0) {
            pivotParams.derivedAttributes = {
                "Month": dateFormat("timestamp", "%n", true),
                "Day": dateFormat("timestamp", "%w", true),
                "Hour": dateFormat("timestamp", "%H", true),
                "Short Date": dateFormat("timestamp", "%y-%m-%d %H:%M")
            };
        } else {
            pivotParams.derivedAttributes = {
                "Month": dateFormat("Start", "%n", true),
                "Day": dateFormat("Start", "%w", true),
                "Hour": dateFormat("Start", "%H", true),
                "Short Start": dateFormat("Start", "%y-%m-%d %H:%M"),
                "Short End": dateFormat("End", "%y-%m-%d %H:%M")
            };
        }

        pivotParams.sorters = {
            "Month": sortAs(["Jan", "Feb", "Mar", "Apr", "May",
                "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]),
            "Day": sortAs(["Mon", "Tue", "Wed", "Thu", "Fri",
                "Sat", "Sun"])
        };
        pivotParams.rendererOptions = {
            c3: {
                size: {
                    height: element.height(),
                    width: element.width()
                },
                color: {
                    pattern: ctrl.colour_pattern
                }

            },
            heatmap: {
                colorScaleGenerator: function (values) {

                    // @ts-ignore
                    return d3.scaleLinear().domain([d3.min(values), d3.max(values)])
                        .range(['white', '#a10c2f']);
                }
            }
        };

        ctrl.pivotParameters = pivotParams;

    }

    /**
     * Populates the this.range_map
     */
    getTimeRanges() {
        // const ctrl = this;
        // ctrl.range_map = {};
        //
        // ctrl.config.series_list.forEach(series => {
        //     let dtp = deepCopy(ctrl.dateTimePeriodService.dtp);
        //     let series_config = series;
        //     if (series_config.range === undefined || series_config.range === null) {
        //         dtp = deepCopy(ctrl.dateTimePeriodService.dtp);
        //         series_config.range = dtp.range;
        //         series_config.sample_period = dtp.sample_period;
        //     }
        //     if (series_config.range !== 'custom') {
        //         //Custom only applies to the page dtp so would have been set already
        //         dtp = ctrl.dateTimePeriodService.getDTP(series_config.range);
        //     }
        //     if (series_config.sample_period && series_config.sample_period.hours >= dtp.sample_period.hours) {
        //         dtp.sample_period = ctrl.dateTimePeriodService.sample_dict[series_config.sample_period.name];
        //     }
        //     console.log('dtp: ', dtp, series_config.range, series_config.sample_period);
        //     let key = series_config.range + ' (' + series_config.sample_period.name + ')';
        //     if (!ctrl.range_map[key]) {
        //         ctrl.range_map[key] = {
        //             series_list: [series],
        //             dtp: dtp
        //         }
        //     } else {
        //         ctrl.range_map[key].series_list.push(series)
        //     }
        //
        // });
        // console.log('PivotTileComponent - getTimeRanges: ', ctrl.range_map);
    }

    getData() {
        const ctrl = this;

        if (ctrl.config.event_types.length > 0) {
            this.api.get('/api/data/pivot?' + httpParamSerializer({
                start: this.dateTimePeriodService.dtp.start.toISOString(),
                end: this.dateTimePeriodService.dtp.end.toISOString(),
                event_types: ctrl.config.event_types
            })).toPromise().then(data => {

                ctrl.pivotData = data;
                ctrl.formatDateTime();
                ctrl.buildPivot();
            }, error => {
                console.log("error getting pivot data", error);
            })
        } else if (ctrl.config.series_list.length > 0) {
            // ctrl.getTimeRanges();
            let series_list = ctrl.config.series_list.map((series) => {
                return series.id
            });

            // let seriesTimeData = ctrl.api.get('/GetData', {
            //     params: {
            //         series_list: series_list,
            //         start: ctrl.dateTimePeriodService.dtp.start.toISOString(),
            //         end: ctrl.dateTimePeriodService.dtp.end.toISOString(),
            //         sample_period: ctrl.dateTimePeriodService.dtp.sample_period.wire_sample,
            //         pivot: true
            //     }
            // });

            let $time_series_data = [];
            ctrl.config.series_list.forEach(series => {
                let wire_sample_period = null;
                let start = null;

                if (series.range) {
                    start = ctrl.dateTimePeriodService.getRelativeDatePeriod(ctrl.dateTimePeriodService.dtp, series.range).start
                }
                if (series.sample_period && series.sample_period.wire_sample) {
                    wire_sample_period = series.sample_period.wire_sample
                }

                let data_set = ctrl.api.get('/GetData', {
                    params: {
                        series_list: [series.id],
                        start: start ? start.toISOString() : ctrl.dateTimePeriodService.dtp.start.toISOString(),
                        end: ctrl.dateTimePeriodService.dtp.end.toISOString(),
                        sample_period: wire_sample_period ? wire_sample_period : ctrl.dateTimePeriodService.dtp.sample_period.wire_sample,
                        pivot: true
                    }
                });
                $time_series_data.push(data_set)
            });
            //the fork join operator returns the data from get data in order after all the calls have been returned
            // successfully, so we can simply add the custom labels to the returned data in order of the requests made
            forkJoin($time_series_data).pipe(takeUntil(this.onDestroy)).subscribe((data: any[]) => {
                let i = 0;
                console.log('data received:', data)
                ctrl.pivotData = [];
                data.forEach(series_get_data_data => {
                    let pivotData = series_get_data_data.data;
                    let missing_values_array = [];
                    let series_names = Object.keys(series_get_data_data['missing_values']);

                    series_names.map(name => {
                        let times = Object.keys(series_get_data_data['missing_values'][name]);
                        times.map(time => {
                                let pivot_object = {};
                                pivot_object['timestamp'] = time;
                                pivot_object['series'] = name;
                                pivot_object['missing_values'] = series_get_data_data['missing_values'][name][time];
                                missing_values_array.push(pivot_object)
                            }
                        )
                    });
                    missing_values_array.map(el => {
                            if (el.missing_values == false) {
                                el.missing_values = 'Existing'
                            } else {
                                el.missing_values = 'Missing'
                            }
                        }
                    );

                    pivotData = pivotData.concat(missing_values_array);
                    pivotData = pivotData.map(item => {
                        item['Label'] = ctrl.config.series_list[i].data_label
                        return item
                    })
                    ctrl.pivotData = ctrl.pivotData.concat(pivotData);
                    i++;
                });

                // ctrl.pivotData = data[0].data;
                // let missing_values_array = [];
                // let series_names = Object.keys(data[0].missing_values);
                // series_names.map(name => {
                //     let times = Object.keys(data[0].missing_values[name]);
                //     times.map(time => {
                //             let pivot_object = {};
                //             pivot_object['timestamp'] = time;
                //             pivot_object['series'] = name;
                //             pivot_object['missing_values'] = data[0].missing_values[name][time];
                //             missing_values_array.push(pivot_object)
                //         }
                //     )
                // });
                // missing_values_array.map(el => {
                //         if (el.missing_values == false) {
                //             el.missing_values = 'Existing'
                //         } else {
                //             el.missing_values = 'Missing'
                //         }
                //     }
                // );
                // ctrl.pivotData = ctrl.pivotData.concat(missing_values_array);
                console.log('pivotData - : ', ctrl.pivotData);
                ctrl.formatDateTime();
                ctrl.buildPivot();
            });
        }

    }

    formatDateTime() {
        const ctrl = this;
        ctrl.pivotData.map((item) => {
            Object.keys(item).forEach((prop) => {
                //console.log('Type: ', prop, isNaN(item[prop]) && !isNaN(new Date(item[prop]).getDate()));
                if (prop === 'Start' || prop === 'End') {
                    item[prop + '_Time'] = ctrl.dateTimePeriodService.dtp.sample_period.format(
                        deepCopy(item[prop]), ctrl.dateTimePeriodService.dtp);
                }
                if (prop === 'timestamp') {
                    item['Timestamp'] = ctrl.dateTimePeriodService.dtp.sample_period.format(
                        deepCopy(item[prop]), ctrl.dateTimePeriodService.dtp);
                }
            })
        })
    }

    buildPivot() {
        const ctrl = this;
        ctrl.pivotParams();

        // @ts-ignore
        $(ctrl.pivotAnchor.nativeElement).pivotUI(
            ctrl.pivotData, ctrl.pivotParameters, true
        ).show();

    }

    savePivotState() {
        const ctrl = this;

        ctrl.config.pivot_state = ctrl.stateData;
        ctrl.tileContentChange.emit(ctrl.config);
        ctrl.showUI = false;
        ctrl.buildPivot()

    }

}
