import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AppScope} from "../services/app_scope.service";
import {MatSelectChange} from "@angular/material";

@Component({
    selector: 'colour-palette',
    templateUrl: 'colour_palette.html',
    styleUrls: ['colour_palette.less'],
    encapsulation: ViewEncapsulation.None
})
export class ColourPaletteComponent implements OnInit {
    palette1 = [];
    palette2 = [];

    @Input() model: any;

    @Input() show_text: boolean;

    @Input() palette: any;

    emitSelect(newSelection: MatSelectChange) {
        this.valueChange.emit(newSelection.value);
        this.selectChange.emit(newSelection);
    }

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    selectChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

    // private _value: any;

    @Input() value: any;
    // set colour_value(newValue: any) {
    //     this._value = newValue;
    // }
    //
    // get colour_value(): any {
    //     return this._value;
    // }

    constructor(private appScope: AppScope) {

    }

    ngOnInit() {
        const ctrl = this;
        ctrl.appScope.auth_complete.promise.then(() => {
            //TODO: remove old misspelled palette
            if (ctrl.appScope.config_name_map.hasOwnProperty("palette1")) {
                ctrl.palette1 = ctrl.appScope.config_name_map.palette1.value;
            } else if (ctrl.appScope.config_name_map.hasOwnProperty("pallette1")) {
                ctrl.palette1 = ctrl.appScope.config_name_map.pallette1.value;
            } else {
                ctrl.palette1 = [{"colour": "#000", "name": "Black"}, {
                    "colour": "#333", "name": "Dark Gray"
                }, {"colour": "#ff5722", "name": "Orange"}, {"colour": "#4286f4", "name": "Blue"}, {
                    "colour": "#54bf7d", "name": "Green"
                }];
            }
            if (ctrl.appScope.config_name_map.hasOwnProperty("palette2")) {
                ctrl.palette2 = ctrl.appScope.config_name_map.palette2.value;
            } else if (ctrl.appScope.config_name_map.hasOwnProperty("pallette2")) {
                ctrl.palette2 = ctrl.appScope.config_name_map.pallette2.value;
            } else {
                ctrl.palette2 = [
                    {"colour": "teal", "name": "Teal"},
                    {"colour": "dodgerblue", "name": "Dodger Blue"},
                    {"colour": "darkslateblue", "name": "Dark Slate Blue"},
                    {"colour": "darkturquoise", "name": "Dark Turquoise"},
                    {"colour": "orangered", "name": "Orange Red"},
                    {"colour": "darkorange", "name": "Dark Orange"},
                    {"colour": "orange", "name": "Orange"},
                    {"colour": "yellow", "name": "Yellow"},
                    {"colour": "green", "name": "Green"},
                    {"colour": "olive", "name": "Olive"},
                    {"colour": "black", "name": "Black"}
                ];
            }
        });
    }
}
