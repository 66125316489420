<div>

  <div class="row">
  <div *ngIf="processes && processes.length>0" class="select-search">
    <select-search
            [(value)]="process"
            (selectChange)="processChange()"
            [data]="processes"
            [fill_stubs]="true"
            [label]="'Select a process'"
            >
    </select-search>
  </div>

  <div *ngIf="logsheet_columns && logsheet_columns.length>0">
    <mat-form-field>
      <mat-label>Extra Columns</mat-label>
      <mat-select multiple
                  [(value)]="config.columns">
        <mat-option *ngFor="let column of logsheet_columns" [value]="column">
          {{column| upperfirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
    </div>

</div>