<div [ngClass]="config.type + '-area'" class="hideonprint">
  <div *ngIf="config.type==='section'" class="section-area-title"><span>Section {{print_object.title}}</span></div>

  <button (click)="printSection(print_object, $event)" *ngIf="config.type==='section'" mat-button
          matTooltip="Print section"
          matTooltipClass="print-section-tooltip">
    <i class="fa fa-print"></i>
  </button>
  <ng-container *ngIf="printing_sections !== true">
    <button (click)="pageBreak(print_object, $event)" *ngIf="!print_object.page_break"
            mat-button matTooltip="Insert page break">
      <i class="fa fa-plus"></i>
    </button>
    <button (click)="pageBreakRemove(print_object, $event)" *ngIf="print_object.page_break"
            mat-button matTooltip="Remove page break">
      <i class="fa fa-minus"></i>
    </button>
    <mat-checkbox (change)="hideOnPrint(print_object)" [(ngModel)]="print_object.hide_on_print">Hide {{config.type}} on
      print
    </mat-checkbox>
  </ng-container>
</div>
