import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as utils from "../lib/utils"
import {HeaderDataService} from "../services/header_data.service";
import {UserData} from "../services/user_data.service";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {JsonPipe} from "@angular/common";
import {MatTabChangeEvent} from "@angular/material";
import {AppScope} from '../services/app_scope.service';
import {MenuTreeService} from "../services/menu-tree.service";
import {ActivatedRoute} from "@angular/router";
import {ApiService} from '../services/api.service';
import {combineLatest, Subject} from "rxjs";
import {map, takeUntil} from "rxjs/operators";
import {AccountService} from "../services/account.service";

export interface DialogData {
    id: any,
    relationships: { folders: { data: any[] } },
    attributes: {
        json: {
            form_result?: string,
            name?: string,
            auto_refresh?: boolean,
            layout: string,
            refresh_time: number,
            sample_period: string,
            sections: any[],
        },
        visibility: string,
        is_editing?: boolean

    }
}

@Component({
    selector: 'PageViewForm',
    templateUrl: 'page_view_form.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PageViewFormComponent implements OnInit, OnDestroy {
    private onDestroy: Subject<void> = new Subject();

    show: boolean;
    json: any;
    json_string: string;
    title: string;
    showing_hints: boolean = false;
    hint: string = 'Name';
    selected_visibility: any;
    is_editing: boolean;

    public ranges: any[];
    public layouts = ['column', 'tile', 'print'];
    visibility_options: string[];
    samplePeriods: any[];
    json_ready: boolean = false;
    json_stringify_error: boolean = null;
    folder_options: any[] = [];
    selected_folders: any[];
    session_id: any;

    constructor(public dialogRef: MatDialogRef<PageViewFormComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private headerData: HeaderDataService,
                private userData: UserData,
                private dateTimeService: DateTimePeriodService,
                public appScope: AppScope,
                private menuTreeService: MenuTreeService,
                private route: ActivatedRoute,
                private accountService: AccountService,
                private api: ApiService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        this.json = utils.deepCopy(ctrl.data.attributes);
        ctrl.session_id = ctrl.data.id;
        this.show = false;
        this.title = this.is_editing ? this.headerData.title : "";
        Promise.all([this.dateTimeService.dtp_complete.promise, this.appScope.auth_complete.promise])
            .then(() => {
                this.ranges = this.dateTimeService.ranges;
                this.samplePeriods = this.dateTimeService.sample_periods;
                this.visibility_options = ['public', 'private'];
            });
        this.json_ready = true;

        ctrl.folder_options = [];
        const folders_source = this.menuTreeService.menuTreeChangedFull;
        const active_account_source = this.accountService.activeAccountChanged;

        combineLatest([folders_source, active_account_source])
            .pipe(
                takeUntil(this.onDestroy),
                map(([folders, active_account]) => {
                    return ({folders, active_account});
                }))
            .subscribe(response => {
                const folders = response.folders;
                const active_account = response.active_account;
                ctrl.flatten(folders[active_account.account_id], this);
                if (!(this.data.relationships.folders.data && this.data.relationships.folders.data.length > 0 && this.folder_options.length > 0)) {
                    this.data.relationships.folders.data = [{id: this.folder_options[0].folder.id, type: "folder"}]
                }
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    /**
     * Flattens the folder tree for display in the page creation dialog.
     * @param contents
     * @param ctrl
     */
    flatten(contents, ctrl) {
        if (contents && contents.length > 0) {
            contents.forEach(folder => {
                if (folder.type === 0) {
                    ctrl.folder_options.push({
                        name: folder.folder.attributes.name, folder: folder.folder,
                        parent: folder.parent ? folder.parent.folder.attributes.name : 'Root'
                    });
                    ctrl.flatten(folder.contents, ctrl);
                }
            })
        }
    }

    tabChange(event: MatTabChangeEvent) {
        console.log('switched to tab: ', event.tab.textLabel);
        if (event.tab.textLabel === 'Advanced') {
            // const temp_json = this.stringifyJson(this.json)
            try {
                this.json_string = new JsonPipe().transform(this.json);
                this.json_stringify_error = false;
            } catch (e) {
                this.json_stringify_error = true;
            }
        } else {
            this.updateJson(this.json_string)
        }
    }

    save() {
        const ctrl = this;
        console.log("PageViewForm: save", ctrl.data);
        ctrl.data.attributes = ctrl.json;
        this.dialogRef.close(
            ctrl.data
        );
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    matSelectCompare = function (option, value): boolean {
        if (value) {
            return option.id === value.id;
        }
    };

    updateFolder(event) {
        const ctrl = this;
        console.log('PageViewFormComponent - updateFolder: ', event, ctrl.selected_folders);

        // ctrl.folder_options.forEach(folder=>{
        //     //event.forEach(selected=>{
        //     if(event.includes(folder.folder)){
        //         if(!folder.folder.relationships.session_states.data.find(item=> item.id==ctrl.session_id)){
        //             folder.folder.relationships.session_states.data.push({'id': ctrl.session_id, 'type': 'session_state'})
        //         } else {
        //             folder.folder.relationships.session_states.data = folder.folder.relationships.session_states.data
        //                 .filter(item=>item.id !=ctrl.session_id)
        //         }
        //     }
        //     console.log('PageViewFormComponent - folder: ', folder);
        //     ctrl.api.folder.save(folder.folder);
        //     //})
        // })
    }

    addSection() {
        const ctrl = this;
        let new_section = this.headerData.addSection(this.json.json.sections.length)
        this.json.json.sections.push(new_section);
    }

    removeRow(index) {
        const ctrl = this;
        //TODO make this confirm a dialog
        if (confirm("Please confirm before removing this section")) {
            let removed = ctrl.json.json.sections.splice(index, 1);
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.json.json.sections, event.previousIndex, event.currentIndex);
    }

    private updateJson(json_string) {
        this.json = JSON.parse(json_string);
    }

    private stringifyJson(json) {
        // Demo: Circular reference
        // Note: cache should not be re-used by repeated calls to JSON.stringify.
        var cache = [];
        const response = JSON.stringify(json, function (key, value) {
            if (typeof value === 'object' && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    // Duplicate reference found, discard key
                    return;
                }
                // Store value in our collection
                cache.push(value);
            }
            return value;
        });
        cache = null; // Enable garbage collection
        return JSON.parse(response);
    }
}
