import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {DateTimePeriodService} from "../../services/datetime_period.service";
import {MatSelectChange} from "@angular/material/select";

@Component({
    selector: 'pivot-tile-form',
    templateUrl: './pivot-tile-form.component.html'
})
export class PivotTileFormComponent implements OnInit {

    @Input()
    event_types: any = [];

    @Input()
    config: any;

    @Output() configChange = new EventEmitter();

    selected_types: any = [];
    full_series_list: any = [];
    selected_series: any = [];

    constructor(private api: ApiService,
                public dateTimePeriodService: DateTimePeriodService,
    ) {
    }

    ngOnInit() {
        const ctrl = this;
        ctrl.api.series_light.search().toPromise().then((result) => {
            ctrl.full_series_list = result.data;
        });
        if (!ctrl.event_types || ctrl.event_types.length == 0) {
            ctrl.api.event_type.search().toPromise().then(response => {
                ctrl.event_types = response.data;
                if (ctrl.config.event_types && ctrl.config.event_types.length > 0) {
                    ctrl.selected_types = ctrl.event_types.filter((type) => {
                        return ctrl.config.event_types.includes(type.attributes.name);
                    })
                }
            })
        }
        if (this.config.series_list && this.config.series_list.length > 0) {
            console.log('this line of code is not really needed')
        }
        if (this.config.series_list && this.config.series_list.length == 0) {
            this.config.series_list = []
        }

    }

    updateConfig(event, type, i) {
        const ctrl = this;
        if (type === 'event') {
            ctrl.config.event_types = event.map((type) => {
                return type.attributes.name
            });
            ctrl.config.series_list = [];
        }
        if (type === 'series') {
            ctrl.config.series_list[i].name = event.value.attributes.name
            ctrl.config.series_list[i].id = event.value.id
            this.selected_types = [];
            ctrl.config.event_types = [];
        }
        this.configChange.emit(this.config);

    }

    changeRange(event: MatSelectChange, i) {
        if (this.config.series_list.length > 0) {
            this.config.series_list[i].range = event.value

        }
    }

    changePeriod($event: MatSelectChange, i: any) {
        if (this.config.series_list.length > 0) {
            this.config.series_list[i].sample_period = $event.value
        }

    }

    getRange(series: any, i) {

        const ctrl = this;
        if (this.config.series_list.length > 0) {

            let item = ctrl.config.series_list[i]

            return item.range
        } else {
            return null
        }
    }

    getPeriod(series_selected: any, i: number) {
        if (this.config.series_list.length > 0) {

            let item = this.config.series_list[i]
            return item.sample_period
        } else {
            return null
        }
    }

    addSeriesToPivotConfig() {

        this.config.series_list.push({
            'name': null,
            'id': null,
            'range': null,
            'sample_period': null
        })

    }

    getSeriesSelected(series_to_find: any) {
        return this.full_series_list.find(series => series.id == series_to_find.id)

    }

    deleteSeries(i: number) {

        this.config.series_list.splice(i, 1)

    }
}
