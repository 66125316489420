<style>
  .node {
    cursor: pointer;
  }

  .node circle {
    fill: #fff;
    stroke: steelblue;
    stroke-width: 3px;
  }

  .node text {
    font: 12px sans-serif;
  }

  .link {
    fill: none;
    stroke: #ccc;
    stroke-width: 2px;
  }

</style>

<div class="page traveler">
  <div class="row justify-center center" style="padding:0 4%; ">
    <select-search (selectChange)="removeTree();determineTree($event)"
                   *ngIf="calculations_full.length>0"
                   [(value)]="selected_calculation"
                   [classes]="'fill'"
                   [data]="calculations_full"
                   class="select-search"
                   label="Select key value driver"
                   style="max-width:460px;">
    </select-search>
    <mat-slide-toggle [(ngModel)]="formula_view">Switch
      to {{formula_view ? 'Value view' : 'Formula view'}}</mat-slide-toggle>
  </div>

  <!--    <div #value_driver_tree id="value_driver_tree" >-->
  <!--    </div>-->
  <div class="split-example ex-percent" style="height:80vh;border:2px dashed #ccc;">

    <as-split #split="asSplit" (dragEnd)="dragEnd('percent', $event)" [direction]="direction" unit="percent">
      <as-split-area #area1="asSplitArea" size="75">
        <div (mousewheel)="scrollHandler($event)"
             style="width: 100%"
             *ngIf="selected_calculation" [id]="selected_calculation?.attributes.name">
          <svg [attr.height]="height" [attr.viewBox]="'-10 -250 ' + vb_width + ' ' + vb_height" [attr.width]="width"
               class="value-driver-tree" id="static_svg"
               style="fill:pink"
               x="0" xmlns:xhtml="http://www.w3.org/1999/xhtml"
               y="0">

            <g cdkDrag id="static_svg_g">
              <rect [attr.height]="height" fill="transparent" style="cursor:move;" width="100%" x="0" y="-250"></rect>
              <!--This enables cdkDrag and zooming on the whole box rather than just the nodes-->

              <ng-container *ngIf="selected_calculation">
                <g (mouseenter)="mouseEnter(node)"
                   (mouseleave)="mouseLeave(node)"
                   *ngFor="let node of treeNodes"
                   [attr.transform]="'translate(' + node.y + ',' + node.x + ')'"
                   class="node">
                  <rect [ngClass]="{'rect-node-children':node._children, 'rect-node':!node._children}" height="80"
                        rx="5"
                        ry="5"
                        width="180">
                  </rect>
                  <!---->
                  <foreignObject
                      (click)="toggle(node)"
                      [attr.height]="box_height"
                      [attr.width]="box_width"
                  >

                    <xhtml:div (contextmenu)="contextMenu($event, node.data.series, node)" *ngIf="formula_view === true"
                               [ngStyle]="{height:box_height + 'px', width:box_width + 'px'}">
                      <xhtml:div class="vdt-formula-title">
                        {{node.data.series.attributes | nameOrDescription}}
                      </xhtml:div>
                      <xhtml:div class="vdt-formula">
                        {{node.data.series.attributes.name_formula}}
                      </xhtml:div>
                    </xhtml:div>

                    <xhtml:div *ngIf="formula_view === false;"
                               [ngStyle]="{height:box_height + 'px', width:box_width + 'px'}">
                      <xhtml:context (contextmenu)="contextMenu($event, node.data.series, node)"
                                     [config]="{
                                               'selected_series':node.data.series,
                                               'value_only':true,
                                               'series_unit':node.data.series.attributes.engineering_unit_name,
                                               'show_unit':true}"
                                     style="height:100%;width:100%;">
                      </xhtml:context>
                    </xhtml:div>
                  </foreignObject>
                </g>

                <path *ngFor="let link of links"
                      [attr.d]="link.path"
                      class="link"
                      marker-end='url(#head)'
                      marker-start='url(#origin)'>
                </path>
              </ng-container>
            </g>

          </svg>
        </div>
      </as-split-area>

      <as-split-area #area2="asSplitArea" class="background:white;" size="25">
        <value-driver-tree-table *ngIf="selected_calculation"
                                 [mouseoverNode]="mouseovernode"
                                 [tableData]="table_data">
        </value-driver-tree-table>
      </as-split-area>

    </as-split>
  </div>

  <div #context_menu [ngStyle]="{'display':menuVisible? 'block':'none'}" class="menu flowchart-context-menu">
    <ul class="menu-options">
      <li (click)="openChartDialog(selected_series.attributes.name)" class="menu-option">Show Chart</li>
      <li (click)="editSeries(selected_series)" class="menu-option">Edit Series</li>
    </ul>
  </div>
</div>


