import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../services/api.service";
import {SeriesDataService} from "../services/series_data.service";
import {FormControl} from "@angular/forms";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import * as utils from '../lib/utils';
import * as _ from "lodash";

@Component({
    selector: 'series-table-form',
    templateUrl: './series-table-form.component.html'
})
export class SeriesTableFormComponent implements OnInit {
    full_series_list: any[];
    selected_series: any[] = [];
    columnsNormal = new FormControl();
    selected_process: any;
    hint: string;
    estimate_types: any[];

    private _config: {
        series_list?: [{
            attributes: {
                description: string,
                event_type_name: any,
                kpi_level: any,
                name: string
            },
            "id": any,
            "type": any
        }],
        process?: {
            attributes: {
                description: string,
                name: string
            },
            id: any,
            type: string
        },
        kpis?: any[],
        columns: string[],
        normal_columns?: string[], // legacy column, to be phased out
        detail_columns?: string[], // legacy column, to be phased out
        mobile_columns?: string[],
        column_text?: {},
        estimate_type?: any
        show_comments?:boolean
    };
    private _processes: any[];
    private colFilter: any;
    all_columns: any;

    @Input()
    set config(config) {
        this._config = config;
        //Legacy code
        if (!this._config.columns) {
            console.log("Series-table-form Input config, No columns yet");
            this._config.columns = [];
            if (this._config.normal_columns) {
                this._config.normal_columns.forEach(item => {
                    if (!this._config.columns.includes(item)) {
                        this._config.columns.push(item);
                    }
                })
            }
            if (this._config.detail_columns) {
                this._config.detail_columns.forEach(item => {
                    if (!this._config.columns.includes(item)) {
                        this._config.columns.push(item);
                    }
                })
            }
        }
        //Also legacy
        if (this._config.estimate_type && !Array.isArray(this._config.estimate_type)) {
            this._config.estimate_type = [this._config.estimate_type];
        }
        this.updateSeriesTableJson();
    }

    get config() {
        return this._config;
    }

    @Input()
    set processes(processes: any[]) {
        this._processes = processes;
        if (this._processes !== undefined) {
            this.updateSeriesTableJson();
        }
    }

    get processes() {
        return this._processes
    }

    col_dict: any;
    constructor(public api: ApiService,
                public seriesData: SeriesDataService) {
    }

    ngOnInit() {
        const ctrl = this;
        this.seriesData.$estimate_types.promise.then(response => {
            console.log('SeriesTableFormComponent - response: ', response);
            this.estimate_types = response;
            if (!ctrl.config.estimate_type) {
                ctrl.config.estimate_type = ['Forecast'];
            }
            ctrl.colFilter = utils.deepCopy(ctrl._config.estimate_type);
            ctrl.updateColumns();
            //ctrl.log_columns = ctrl.seriesData.columnsAll; //?filter
            ctrl.col_dict = ctrl.seriesData.column_dict;
        })

    }

    updateSeriesTableJson() {
        const ctrl = this;
        if (ctrl.full_series_list === undefined) {
            // TODO take this as an input instead, as this causes a duplicate request
            ctrl.api.series_light.search().toPromise().then((response) => {
                ctrl.full_series_list = response.data;
                if (ctrl.config.series_list) {
                    ctrl.selected_series = ctrl.full_series_list.filter(series =>
                        ctrl.config.series_list.map(item => item.id).includes(series.id));
                }
            });
        }

        if (ctrl.full_series_list && ctrl.config.series_list) {
            ctrl.selected_series = ctrl.full_series_list.filter(series =>
                ctrl.config.series_list.map(item => item.id).includes(series.id));
        }

        if (ctrl._processes && ctrl.config.process) {
            ctrl.selected_process = ctrl._processes.find(process => process.id === ctrl.config.process.id);
        }
    }

    selectionChange(event, type) {
        if(type==='process'){
            if (this.selected_process) {
                this.config.process.id = this.selected_process.id;
                this.config.process.attributes.description = this.selected_process.attributes.description;
                this.config.process.attributes.name = this.selected_process.attributes.name;
                this.config.process.type = this.selected_process.type;

                this.config.series_list = [{
                    attributes: {
                        description: null,
                        event_type_name: null,
                        kpi_level: null,
                        name: null
                    },
                    "id": null,
                    "type": null
                }];

                this.selected_series = [];
            }

        }
        if(type==='series') {
            if (this.config.series_list) {
                // @ts-ignore
                this.config.series_list = this.selected_series.map(
                    (series) => {
                        return {
                            attributes: {
                                description: series.attributes.description,
                                event_type_name: series.attributes.event_type_name,
                                kpi_level: series.attributes.kpi_level,
                                name: series.attributes.name
                            },
                            id: series.id,
                            type: series.type
                        }
                    }
                );
            }
            this.config.process.id = "";
            this.selected_process = utils.deepCopy(this.config.process);
        }
    }

    updateColumns() {
        const ctrl = this;
        let filter_out = null;
        ctrl.all_columns = ctrl.seriesData.fillColumns(ctrl.config.estimate_type);

        //Remove selected and deprecated columns from the available list
        ctrl.all_columns.all = ctrl.all_columns.columns.filter(el => {
            return !ctrl.config.columns.includes(el.name) && !el.deprecated === true
        }).map(el => el.name);

        ctrl.all_columns.mobile = utils.deepCopy(ctrl.all_columns.all).filter((el) => {
            return !ctrl.config.mobile_columns.includes(el)
        });

        //Remove the old estimate_type ones from the selected_list
        if (_.difference(ctrl.colFilter, ctrl.config.estimate_type).length > 0) {
            //One has been removed
            filter_out = _.difference(ctrl.colFilter, ctrl.config.estimate_type)
        }

        if (filter_out) {
            ctrl.config.columns = ctrl.seriesData.filterColumns(ctrl.config.columns, filter_out);
            ctrl.config.mobile_columns = ctrl.seriesData.filterColumns(ctrl.config.mobile_columns, filter_out);
            //ctrl._config.detail_columns = ctrl.seriesData.filterColumns(ctrl._config.detail_columns, ctrl.colFilter);
        }
        ctrl.colFilter = utils.deepCopy(ctrl.config.estimate_type);
    };

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }

    }

}
