import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AppScope} from "../services/app_scope.service";
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from "@angular/material";
import {ApiService} from "../services/api.service";
import {map, takeUntil} from "rxjs/operators";
import {HandleError, HttpErrorHandler} from "../services/http-error-handler.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {UserFormComponent} from "../forms/user_form.component";
import {User} from "./user_list.component";
import {HeaderDataService} from "../services/header_data.service";
import {combineLatest, Subject} from "rxjs";
import {Account, AccountService} from "../services/account.service";

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
    })
};

@Component({
    selector: 'register',
    templateUrl: 'register.component.html',
    encapsulation: ViewEncapsulation.None
})
export class RegisterUserView implements OnInit, OnDestroy {
    active_account: Account;

    private handleError: HandleError;
    registerForm: FormGroup;
    submittedRegister: boolean = false;
    user: any;
    loading: boolean = false;
    roles: {
        attributes?: {
            account_name?: string,
            name?: string
        }
    }[];
    disabled_roles: string[] = ['User'];
    private readonly onDestroy = new Subject<void>();

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private snackBar: MatSnackBar,
                private router: Router,
                private api: ApiService,
                private http: HttpClient,
                private appScope: AppScope,
                private httpErrorHandler: HttpErrorHandler,
                public dialog: MatDialog,
                private accountService: AccountService,
                private headerData: HeaderDataService) {
    }

    ngOnInit() {
        this.handleError = this.httpErrorHandler.createHandleError('HeroesService');
        this.registerForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', Validators.required],
            roles: [[], Validators.required]
        });
        this.headerData.title = 'Register new user';
        this.appScope.auth_complete.promise.then(() => {
            this.api.role.search().pipe(map(item => item.data)).toPromise().then(result => {
                this.roles = result.sort((a, b) => (a.attributes.account_name > b.attributes.account_name) ? 1 : -1);//result.map(item => item.attributes.name);
                let selected_roles = [];
                if (this.roles.map(item => item['attributes'].name).includes('User')) {
                    selected_roles.push('User')
                }
                this.registerForm.get('roles').setValue(selected_roles);
            })
        });

        const accounts_source = this.accountService.accountsChanged;
        const active_account_source = this.accountService.activeAccountChanged;

        combineLatest([accounts_source, active_account_source])
            .pipe(
                takeUntil(this.onDestroy),
                map(([accounts, active_account]) => {
                    return ({accounts, active_account});
                }))
            .subscribe(response => {
                const accounts = response.accounts;
                const active_account = response.active_account;
                this.active_account = accounts.find(account => account.id == active_account.account_id);
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

    get f() {
        return this.registerForm.controls;
    }

    rolechange(result) {
        console.log(result);
        // this.registerForm.controls.roles.setValue(result)
    }

    validate() {
        let valid = true;
        this.registerForm.controls['name'].setErrors(null);
        this.registerForm.controls['email'].setErrors(null);

        if (!this.registerForm.get('name').value) {
            this.registerForm.controls['name'].setErrors({'invalid': true});
            valid = false;
        }
        if (!this.registerForm.get('email').value) {
            this.registerForm.controls['email'].setErrors({'invalid': true});
            valid = false;
        }
        if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.registerForm.controls['email'].value))) {
            this.registerForm.controls['email'].setErrors({'invalid': true});
            valid = false;
        }
        return valid;
    }

    submit() {
        // this.loading = true;
        const ctrl = this;
        ctrl.submittedRegister = true;
        const vars = {
            name: this.registerForm.get('name').value,
            email: this.registerForm.get('email').value,
            roles: this.registerForm.get('roles').value
        };

        if (this.validate() === false) {
            this.snackBar.open('Your form is invalid. Please check your info and try again.', 'Hide');
            return;
        }

        // TODO log if issue (such as conflict)
        this.http.post('/auth/register', vars, httpOptions).toPromise().then(data => {
            ctrl.loading = false;
            ctrl.api.users.getById(data['user_id']).toPromise().then(results => {
                ctrl.user = results.data;
            });
            this.snackBar.open('User successfully added', undefined, {duration: 3000});
        }, result => {
            ctrl.loading = false;
            console.log('Error posting user', result);
            this.snackBar.open(result.error, 'Hide');
        })
    }

    openDialog(): void {
        const ctrl = this;
        if (ctrl.user) {
            const dialogConfig = new MatDialogConfig<User>();
            dialogConfig.minWidth = '640px';
            dialogConfig.data = ctrl.user;
            const dialogRef = this.dialog.open(UserFormComponent, dialogConfig);
        }
    }

//    TODO
    // resend (){
    //     this.http.post('/auth/resend',
    //         data: $scope.userData}).then(function(data){
    //
    //             if (data.data.hasOwnProperty('errors')){
    //                 $scope.errors = [data.data.errors]
    //             } else{
    //                 $scope.errors = ['Confirmation Email resent'];
    //                 //appScope.currentUser =  data.data;
    //                 //$location.path( '/user_page/' + appScope.currentUser.id );
    //             }
    //
    //         }
    //     )

    rolesLostFocus() {
        console.log('roles closed')
    }

    nameChanged() {
        console.log('nameChanged', this.registerForm.get('name'));
    }
}