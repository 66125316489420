<div class="form-dialog user">
  <h1>User: {{user.attributes.name}}
    <div (click)="showing_hints=!showing_hints">
      <ng-container *ngIf="showing_hints">Hide hints</ng-container>
      <ng-container *ngIf="!showing_hints">Show hints</ng-container>
    </div>
  </h1>
  <mat-dialog-content>
    <mat-tab-group [disableRipple]="true" style="min-width:800px;">
      <mat-tab label="Basic">
        <form class="form-section column">
          <div *ngIf="user.attributes.restricted_access===true" class="warning"><b>This user has restricted access.</b>
          </div>
          <div class="row">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="user.attributes.name" name="name">
            </mat-form-field>
            <mat-form-field class="email">
              <mat-label>Email</mat-label>
              <i class="fa fa-envelope" (click)="resend_invite()"></i>
              <input matInput [(ngModel)]="user.attributes.email" name="email">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Sms</mat-label>
              <input matInput [(ngModel)]="user.attributes.sms" name="sms">
            </mat-form-field>
          </div>

          <div class="row" style="margin-top: 20px">
            <mat-checkbox [(ngModel)]="user.attributes.email_disabled" name="email_disabled">Email disabled
            </mat-checkbox>

            <mat-checkbox [(ngModel)]="user.attributes.sms_disabled" name="sms_disabled">Sms disabled
            </mat-checkbox>

            <mat-checkbox [(ngModel)]="user.attributes.require_otp" name="require_otp"
                          [disabled]="true">Require OTP
            </mat-checkbox>

            <mat-checkbox [(ngModel)]="user.attributes.active" name="active">Active</mat-checkbox>
          </div>

          <div class="row">
            <div class="select-search" *ngIf="appScope.plants && process_ids && process_ids.length>0
                      && process_features && view_plant_feature && process_features[view_plant_feature.id].length>=0">
              <select-many-search [label]="'Plants'"
                                  [value]="setValues(view_plant_feature)"
                                  (valueChange)="extractIds($event, view_plant_feature)"
                                  (selectChange)="processFeaturesChanged($event, view_plant_feature)"
                                  [data]="filterProcessList()"
              >
              </select-many-search>
            </div>
            <div *ngIf="roles_list && roles_list.length>0 && user.relationships.roles.data" class="select-search"
                 (mouseenter)="hint='method'">
              <select-many-search [label]="'Roles'"
                                  [(value)]="user.relationships.roles.data"
                                  [fill_stubs]="true"
                                  [stringFunction]="roleFunction"
                                  [data]="roles_list">
              </select-many-search>
              <!--<div *ngFor="let role of user.relationships.roles.data"> {{ role.attributes?.name}}</div>-->

            </div>
          </div>

          <div class="row">
            <div *ngIf="alerts_list && alerts_list.length>0 && series_list && series_list.length>0"
                 class="select-search" (mouseenter)="hint='method'">
              <select-many-search [label]="'Alert Series'"
                                  [fill_stubs]="true"
                                  [(value)]="user.relationships.alerts['data']"
                                  [data]="alerts_list"
                                  [stringFunction]="alertsFunction">
              </select-many-search>
            </div>

            <div *ngIf="series_list && series_list.length>0" class="select-search" (mouseenter)="hint='method'">
              <select-many-search [label]="'SMS Series'"
                                  [fill_stubs]="true"
                                  [(value)]="user.relationships.series.data"
                                  [data]="series_list">
              </select-many-search>
            </div>
          </div>

          <h4>Process Access Features</h4>
          <div class="row" style="flex-wrap:wrap;">
            <div *ngFor="let feature of (feature_list)">
              <mat-label>{{feature.attributes.name | removeUnderscore | titlecase}}</mat-label>

              <ng-container
                  *ngIf="process_ids && process_ids.length>0
                      && process_features && process_features[feature.id].length>=0
                      && process_list && process_list.length>=0">
                <div class="select-search" (mouseenter)="hint='process_access'">
                  <select-many-search [label]="'Process'"
                                      [value]="setValues(feature)"
                                      (valueChange)="extractIds($event, feature)"
                                      (selectChange)="processFeaturesChanged($event, feature)"
                                      [data]="process_list"
                  >
                  </select-many-search>

                  <!--<div *ngFor="let p_f of process_features[feature.id]"-->
                  <!--[innerHTML]="processDescription(p_f)"></div>-->
                </div>
              </ng-container>
            </div>
          </div>

        </form>
      </mat-tab>
      <mat-tab label="Restricted">
        <form class="form-section column">
          <h4>Restricted access users views</h4>
          <div class="row">
            <mat-checkbox [(ngModel)]="user.attributes.restricted_access" name="restricted_access">Restricted access
            </mat-checkbox>
          </div>

          <mat-label matTooltip="Private pages cannot be shared">Pages shared with this user</mat-label>
          <div class="select-search">
            <select-many-search *ngIf="pages && pages.length>0"
                                [(value)]="restrictedUserViews"
                                [data]="pages"
                                [fill_stubs]="true"
                                [classes]="'fill'"
                                label="Select pages">
            </select-many-search>
          </div>

          <div>
            <h4>Custom pages</h4>
          </div>

          <div class="row">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="customRestrictedPages.name"
                     [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field>
              <mat-label>URL</mat-label>
              <input matInput [(ngModel)]="customRestrictedPages.url" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <button (click)="addPage()" class="bordered-button" mat-button style="align-self:center"> Add to user
              profile
              <i class="fa fa-arrow-down"></i>
            </button>

          </div>

          <div class="row" style="justify-content: space-between;">
            <mat-label>Accessible pages</mat-label>
            <button mat-button class="bordered-button" (click)="clear()">Clear all
              <i class="fa fa-minus" style="margin-left:20px;"></i>
            </button>
          </div>

          <div>
            <ng-container *ngIf="user.attributes.restricted_access">
              <!--this is now redundant-->
              <!--              <div class="row" *ngFor="let view of restrictedUserViews, let i = index">-->
              <!--                <input matInput readonly value="{{view.attributes.name}}">-->
              <!--                <i class="fa fa-trash" (click)="removeView(i, restrictedUserViews)" style="padding:0 20px;font-size:14px;"></i>-->
              <!--              </div>-->

              <div class="row" *ngFor="let view of customRestrictedPagesList, let i = index">
                <input matInput readonly value="{{view.name+' - '+view.url}}">
                <i class="fa fa-trash" (click)="removeView(i)" style="padding:0 20px;font-size:14px;"></i>
              </div>

            </ng-container>
            <ng-container *ngIf="customRestrictedPagesList.length===0">
              No custom views selected.
            </ng-container>
            <ng-container *ngIf="!user.attributes.restricted_access">

             <span>
              <h4>Please select the "Restricted Access" tick box above to make the selected user a restricted access user, and select views for the user</h4>
             </span>

            </ng-container>
          </div>

        </form>

      </mat-tab>
    </mat-tab-group>

  </mat-dialog-content>
  <div class="action-buttons">
    <button class="btn-close" (click)="onCloseClick()">Close</button>
    <button class="btn-save" (click)="save()">Save</button>
  </div>
</div>
