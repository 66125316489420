import {Component, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from "@angular/material";
import * as utils from '../lib/utils';
import {SeriesDataService} from "../services/series_data.service";

export interface EstimateDialogData {
    estimate: any;
    //estimateTypes: any;
    selectedEstimateType: string;
    seriesList: any;
    seriesData:SeriesDataService;
}

@Component({
    selector: 'EstimateForm',
    templateUrl: 'estimate-form.component.html',
    encapsulation: ViewEncapsulation.None,  //Global Styles
})
export class EstimateFormComponent implements OnInit {
    fillMethods: any;
    samplePeriods: any;
    aggregationTypes: any;
    estimateTypes: any;
    seriesData: SeriesDataService;
    seriesList: any;
    estimate: any;
    estimateTypeName: any;
    selectedEstimateType: any;
    selectedSeries: any;
    searchTerm: string = '';
    showing_hints: boolean = false;
    hint: string = 'Name';

    constructor(private api: ApiService,
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                public dialogRef: MatDialogRef<EstimateFormComponent>,
                @Inject(MAT_DIALOG_DATA) public data: EstimateDialogData) {
    }

    ngOnInit(): void {
        // TODO deepCopy some of these fields to prevent date from being displayed on table before it is saved.

        console.log('estimate input sheet form');

        const ctrl = this;
        this.seriesData = ctrl.data.seriesData;

        ctrl.fillMethods = Object.keys(ctrl.seriesData.fill_methods);
        ctrl.samplePeriods = ctrl.seriesData.sample_period_names;
        ctrl.aggregationTypes = ctrl.seriesData.aggregation_types;
        ctrl.estimateTypes = ctrl.seriesData.estimate_types_list;

        ctrl.seriesList = ctrl.data.seriesList;
        ctrl.estimate = ctrl.data.estimate;
        ctrl.selectedEstimateType = ctrl.estimateTypes.find(item => item.attributes.name == ctrl.data.selectedEstimateType);
        ctrl.estimateTypeName = ctrl.selectedEstimateType.attributes.name;
        ctrl.searchTerm = '';

        const estimate_schema = {
            "attributes": {
                "base_type": "estimate",
                "description": "",
                "name": "",
                "fill_method": "Constant",
                "sample_period": "month",
                "aggregation": null,
                "allow_edit": null,
                "positive_variance": true
            },
            "relationships": {
                "estimate_type": {
                    "data": {
                        "id": ctrl.selectedEstimateType.id,
                        "type": "estimate_type"
                    }
                },
                "parent": {
                    "data": {
                        "id": null,
                        "type": "series"
                    }
                },
            },
            "type": "estimate"
        };

        if (ctrl.seriesList.length == 1) {
            ctrl.selectedSeries = ctrl.seriesList[0];
        }

        if (!ctrl.estimate) {
            ctrl.estimate = utils.deepCopy(estimate_schema);
            //ctrl.selectedSeries = ctrl.estimate;
        } else {
            ctrl.estimate = utils.deepCopy(ctrl.estimate);
            ctrl.selectedSeries = ctrl.estimate.relationships.parent.data;
        }


        //ctrl.selectedSeries = ctrl.seriesList.find(item => item.id == ctrl.selectedSeries.id);
    }

    onSearchChange(event) {
        event.stopPropagation();
    };

    /**
     * Called when the selected Series is changed.
     * // TODO confirm the following statement
     * As attributes for the current series might have changed, the changes are copied over to the new selected series.
     */
    seriesSelected() {
        const ctrl = this;
        ctrl.estimate.attributes.name = ctrl.selectedSeries.attributes.name + "_F";
        if (ctrl.selectedSeries.attributes.description) {
            ctrl.estimate.attributes.description = ctrl.selectedSeries.attributes.description + " " +
                ctrl.selectedEstimateType.attributes.name;
        } else {
            ctrl.estimate.attributes.description = ctrl.selectedSeries.attributes.name + " " + ctrl.selectedEstimateType.attributes.name;
        }
    }

    /**
     * Called when the selected Estimate Type is changed
     */
    estimateTypeSelected() {
        const ctrl = this;
        if (ctrl.estimate.attributes.descript1ion) {
            ctrl.estimate.attributes.description = ctrl.estimate.attributes.description.replace(ctrl.estimateTypeName,
                ctrl.selectedEstimateType.attributes.name);
            ctrl.estimateTypeName = ctrl.selectedEstimateType.attributes.name
        }
    }

    save() {
        const ctrl = this;
        let new_estimate: Promise<any>;
        if (ctrl.estimate.hasOwnProperty('id')) {
            new_estimate = ctrl.api.estimate.patch(ctrl.estimate);
        } else {
            ctrl.estimate.relationships.estimate_type.data.id = ctrl.selectedEstimateType.id;
            ctrl.estimate.relationships.parent.data.id = ctrl.selectedSeries.id;
            //console.log("Estimate: " + JSON.stringify(ctrl.estimate));

            new_estimate = ctrl.api.estimate.save(ctrl.estimate);
        }
        new_estimate.then(response => {
            ctrl.snackbar.open("Estimate Saved.", null, {duration: 2000});
            ctrl.close(response);
        }, response => {
            ctrl.snackbar.open("Estimate Not Saved: " + response.message, 'Hide');
        });


    };

    close(newEstimate?: any) {
        this.dialogRef.close(newEstimate);
    };

    fillMethodChange() {
    }

    samplePeriodChanged() {
    }

    aggregationTypeChanged() {
    }

    positiveVarianceChanged() {
    }

    nameChanged() {
    }

    descriptionChanged() {
    }
}
