import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Section, Tile} from '../../services/tile_data.service';

@Component({
    selector: 'print-formatter',
    templateUrl: './print-formatter.component.html',
    styleUrls: ['./print-formatter.component.less']
})
export class PrintFormatterComponent implements OnInit {

    editing: boolean = true;
    @Input() printing: boolean;
    @Input() printing_sections: boolean;
    @Input() print_object: Section | Tile;
    @Input() config: {
        'type': string
    }

    @Output() sectionsChange = new EventEmitter();
    @Output() printingSection = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        console.log('PrintFormatterComponent - ngOnInit: ', this.printing_sections);
    }

    //Section in code below can be either a Section (usually) or a tile in Column view
    printSection(section, event) {
        this.printingSection.emit(section);
    }

    pageBreak(section, event) {
        if (this.config.type === 'tile') {
            section.page_break = "break-after";
        } else {
            section.page_break = "break-before";
        }
        this.sectionsChange.emit(section);
    }

    pageBreakRemove(section, event) {
        section.page_break = null;
        this.sectionsChange.emit(section);
    }

    hideOnPrint(section) {
        this.sectionsChange.emit(section);
    }

}
