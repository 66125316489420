import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";
import {SeriesDataService} from "../services/series_data.service";

@Component({
    selector: 'constant-series-data',
    templateUrl: 'constant_series_data.component.html',
    styleUrls: ['../forms/forms.less', 'series-component-table.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class ConstantSeriesDataComponent implements OnInit {
    @Input() series: any;
    @Input() series_permissions: any;

    disabled: boolean = true;
    page_number: number = 1;
    page_size: number = 5;
    permissions: any = {};
    series_data: any;

    no_results: boolean = false;

    constructor(private api: ApiService, private seriesData: SeriesDataService) {
    }

    ngOnInit() {
        const ctrl = this;
        ctrl.series_permissions[ctrl.series.id].map(function (permission) {
            ctrl.permissions[permission] = true;
        });

        this.fetchData();
    }

    fetchData() {
        const ctrl = this;
        ctrl.no_results = false;
        let promise = ctrl.api.raw_data.search(ctrl.api.prep_q([
                {
                    name: 'series_id',
                    op: 'eq',
                    val: ctrl.series.id
                }
            ],
            {
                sort: '-time_stamp',
                'page[size]': ctrl.page_size,
                'page[number]': ctrl.page_number
            }
        )).toPromise();
        promise.then(function (result) {
            if (!result) return;

            if (result.data.length == 0) {
                ctrl.no_results = true;
            } else {
                ctrl.series_data = result.data;
            }
        });
    }

    page(dir) {
        const ctrl = this;
        ctrl.page_number = ctrl.page_number + (dir * 1);
        ctrl.fetchData();
    }

    showPrevious() {
        return this.page_number == 1 ? false : true;
    }

    changeDate(event, item) {
        item.flag = 'update';
    }

    addRow() {
        const ctrl = this;
        let time = new Date();
        time.setMinutes(0);
        time.toISOString();

        let new_raw_data = {type: 'raw_data', attributes: {series: ctrl.series.id, time_stamp: time, value: null}};
        ctrl.series_data.unshift(new_raw_data);
        ctrl.no_results = false;
    }

    dataChange(raw_data) {
        const ctrl = this;
        raw_data.saving = true;
        var new_raw_data = {id: raw_data.id, type: 'raw_data', attributes: raw_data.attributes};

        if (raw_data.id && Number.isNaN(parseFloat(raw_data.attributes.value))) {
            let promise = ctrl.api.raw_data.delete(raw_data.id).then(function () {
                //delete raw_data.id;
                raw_data.flag = 'deleted';
                raw_data.saving = false;
                //$mdToast.show($mdToast.simple().textContent('Series value deleted.'));

            }, function () {
                console.log('Failed to delete')
                //$mdToast.show($mdToast.simple().textContent('Failed to save'));

            });
            return promise;

        } else if (raw_data.id) {

            let promise = ctrl.api.raw_data.patch(new_raw_data).then(function (result) {

                //$mdToast.show($mdToast.simple().textContent('Series value updated.'));
                raw_data.flag = null;
                raw_data.saving = false;

            }, function () {
                console.log('Failed to update', new_raw_data);
                //$mdToast.show($mdToast.simple().textContent('Failed to save'));

            });
            return promise;
        } else if (!Number.isNaN(parseFloat(raw_data.attributes.value))) {

            let promise = ctrl.api.raw_data.save(new_raw_data).then(function (result) {
                raw_data.id = result.data.id;
                raw_data.flag = null;
                raw_data.saving = false;
                //$mdToast.show($mdToast.simple().textContent('Series value saved.'));

            }, function () {
                console.log('Failed to save', new_raw_data)
                //$mdToast.show($mdToast.simple().textContent('Failed to save'));
            });
            return promise;

        } else {
            raw_data.saving = false;
        }
    };

    save() {
        const ctrl = this;
        let promises = [];
        ctrl.series_data.forEach(function (row) {
            if (row.flag) {
                promises.push(ctrl.dataChange(row));
            }
        });

        Promise.all([promises]).then(function () {
            ctrl.series_data = ctrl.series_data.filter(function (del) {
                return del.flag != 'deleted';
            });
        });
    }

}

