import {EventEmitter, Injectable, Output} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";
import {FlatPromise} from '../lib/utils';
import {ApiService} from './api.service';
import {SeriesStringParser} from "./series_string_parser.service";
import {DateTimePeriod} from './datetime_period.service';

@Injectable({
    providedIn: 'root'
})
export class TileDataService {

    public readonly tile_classes = ['quarter', 'third', 'half', 'two-thirds', 'full'];
    public readonly content_types = [
        {name: 'Budget Bar Chart', value: 'budget-bar-chart'},
        {name: 'Comparison Chart', value: 'comparison-chart'},
        {name: 'Category Chart', value: 'category-chart'},
        {name: 'Context Component', value: 'context'},
        {name: 'Custom Chart', value: 'custom-chart'},
        {name: 'Custom Events', value: 'custom-events'},
        {name: 'Custom HTML', value: 'custom'},
        {name: 'Data Review Sheet', value: 'input-sheet'},
        {name: 'Default Chart', value: 'default-chart'},
        {name: 'Events', value: 'events'},
        {name: 'Flowchart', value: 'flowchart'},
        {name: 'Log Sheet', value: 'log-sheet'},
        {name: 'Paragraph', value: 'paragraph'},
        {name: 'Pivot Table', value: 'pivot-tile'},
        {name: 'Series Table', value: 'series-table'},
        {name: 'Stockpile Events', value: 'stockpile-events'},
        {name: 'Weather Widget', value: 'weather-widget'}];
    // {name: 'Divider', value: 'divider'}

    events: any[];
    title: string;
    sub_title: string;
    comment: any = {};
    show_comment_dtp: boolean = true;
    id: string;

    [x: string]: any;

    readonly buttonsChanged: Subject<TileButton[]> = new ReplaySubject(1);

    private permissions: any;
    public tile: Tile;

    // Observable sources
    private _tileTitle = new BehaviorSubject<string>('Please set the tile title');
    private _subtileTitle = new BehaviorSubject<string>('');
    public editingSubject: BehaviorSubject<boolean>;

    // Observable streams
    tileTitle = this._tileTitle.asObservable();
    tileSubtitle = this._subtileTitle.asObservable();
    editing: Observable<boolean>;

    @Output() addCommentClicked = new EventEmitter();
    @Output() commentHover = new EventEmitter();
    @Output() commentLeave = new EventEmitter();
    @Output() commentIconHover = new EventEmitter();
    @Output() commentIconLeave = new EventEmitter();

    constructor(private api: ApiService,
                private seriesStringParser: SeriesStringParser
    ) {
        this.editingSubject = new BehaviorSubject<boolean>(false);
        this.editing = this.editingSubject.asObservable();
    }

    //Service title commands
    setDefaultTitle(title: string) {
        this._tileTitle.next(title);
    }

    setDefaultSubtitle(subtitle: string) {
        this._subtileTitle.next(subtitle);
    }

    public setEditing(newValue: boolean): void {
        this.editingSubject.next(newValue);
    }

    trustHTML(paragraph_string, dtp) {
        const ctrl = this;
        let $p = new FlatPromise();
        let display;
        if ((typeof paragraph_string) === "string") {
            ctrl.api.series_light.search().subscribe(data => {
                ctrl.seriesStringParser.parseSeriesString(paragraph_string, dtp, data.data)
                    .then(function (returned) {
                        $p.resolve(returned);
                    })
            })
        }
        return $p;
    }

    public getEmptyTile() {
        return {
            'class': 'third',
            'category': '',
            'show_header': true,
            'custom_dtp': false,
            parameters: {
                "title": "",
                "hide_comments": false,
                "series": null,
                "estimate_type": "Forecast"
            },
            content: 'default-chart'
        };
    }

}

export interface Session {
    attributes: {
        name: string,
        json: {
            sections?: Section[],
            sample_period?: any,
            layout?: string,
            stockpiles?: any
        },
        range: any,
        visibility: any
    },
    relationships: {
        folders: { data: any[] }
    }
}

export interface Section {
    id?: string | number,
    title?: string;
    class?: string;
    tiles: Tile[];
    page_break?: string;
    drag_orientation?: string; //This value is not saved
    hide_on_print?: boolean;
    printing?: boolean;
    height?: number;
    fixed_height?: boolean;
    string_height?: string;
}

export interface Tile {
    id?: string,
    content?: string,
    title?: string,
    editing?: boolean,
    class?: string;
    display_text?: boolean,
    hide_edit?: boolean,
    tile_category?: any,
    page_break?: string;
    hide_on_print?: boolean,
    new_line?: boolean,
    printing?: boolean,
    custom_dtp?: boolean,
    dtp?: DateTimePeriod,
    parameters: {
        title?: string,
        series?: any,
        show_dtp: boolean;
        colspan?: string;
        rowspan?: string;
        process?: any;
        hide_comments?: boolean;
        column_order?: string[],
        column_widths?: string[]
    };
    show_header: boolean;
}

export interface TileButton {
    name: string;
    func: (...any) => any;
    link?: boolean;
    params?: any;
    class: string;
    HoverOverHint: string;
}
