<form class="form-section column">
  <!--TODO improve user flow and apply the hints that have been provided here-->
  <div class="column">

    <div class="row">
      <mat-form-field (mouseenter)="hint='method'">
        <mat-label>Correction method</mat-label>
        <mat-select (selectionChange)="clearVariables()" [(value)]="selected_correction_method"
                    placeholder="Select correction method here">
          <mat-option *ngFor="let item of correctionMethods" [value]="item">
            {{item}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="tip-box">
        <div [ngClass]="{'showing': hint=='method'}" class="tip">
          <label>Correction method</label>
          <div *ngIf="selected_correction_method !== 'Apply constant value'">
            Please see detailed options below.
          </div>
          <div *ngIf="selected_correction_method === 'Apply constant value'">
            Please see detailed options below.
          </div>
        </div>
      </div>
    </div>

    <div class="column" *ngIf="selected_correction_method=== correctionMethods[1]">
      <mat-label>Apply correction factor to a series or a calculation</mat-label>
      <mat-radio-group [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="selected_base_type">
        <mat-radio-button *ngFor="let base_type of base_types" [value]="base_type" style="margin: 10px 10px 0 0">
          {{base_type}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <ng-container *ngIf="selected_correction_method === 'Apply correction factor'">
      <button (click)="getValue()"
              style="margin: 20px 0; width:200px;"
              [disabled]="!(calculation||series)"
              [ngStyle]="{'opacity': !(calculation||series) ? 0.5 :  1}"
              class="bordered-button" mat-button>Get current value for {{selected_base_type}}
      </button>

      <ng-container *ngIf="display_value !== null && calculation && selected_base_type=='calculation'">
        <div class="info"><i class="fa fa-info-circle"></i> The current value
          for {{calculation.attributes.description}}
          is {{display_value.toFixed(5)}} for the time period from {{dateTimePeriodService.dtp.date_string()}}</div>
      </ng-container>

      <ng-container *ngIf="display_value !== null && series && selected_base_type=='series'">
        <div class="info"><i class="fa fa-info-circle"></i> The current value
          for {{series.attributes.description}}
          is {{display_value.toFixed(5)}} for the time period from {{dateTimePeriodService.dtp.date_string()}}</div>
      </ng-container>

    </ng-container>


    <ng-container *ngIf="selected_correction_method === correctionMethods[1]">

      <div class="inline-tip">To correct the calculation value, select a series to apply a correction factor to</div>
      <div *ngIf="selected_base_type=='calculation'">
        <div>Apply correction to a calculation</div>
        <div (mouseenter)="hint='calculation'" class="select-search">
          <select-search *ngIf="series_list.length>0 && selected_correction_method==correctionMethods[1]"
                         [label]="'Select the calculation here to correct'"
                         [data]="calculation_list"
                         [(value)]="calculation"
          ></select-search>

        </div>

        <div class="tip-box">
          <div [ngClass]="{'showing': hint=='calculation'}" class="tip">
            <label>Calculation Series</label>
            <div>
              Please see detailed options below.
            </div>
          </div>
        </div>
      </div>

    </ng-container>

    <div class="row">
      <div (mouseenter)="hint='series'" class="select-search">
        <select-search (selectChange)="getPermissions();getCorrectionHistory();configure($event)"
                       *ngIf="series_list.length>0"
                       [(value)]="series"
                       [data]="series_list"
                       [fill_stubs]="true"
                       label="{{selected_correction_method===correctionMethods[1]? (
                     selected_base_type=='series'?'Selected series apply correction factor to':'Select the series to adjust to correct the calculation'):'Select series to apply hourly value to'}}"
        ></select-search>
      </div>

      <div class="tip-box">
        <div [ngClass]="{'showing': hint=='series'}" class="tip">
          <label>Series</label>
          <div>
            The series for which you want to view correction history and/or
            apply a correction factor.
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <mat-form-field (mouseenter)="hint='comment'">
        <mat-label>Comment</mat-label>
        <input [(ngModel)]="comment" [ngModelOptions]="{standalone: true}" matInput
               placeholder="Provide a reason here for editing historical data" type="text">
      </mat-form-field>
      <div class="tip-box">
        <div [ngClass]="{'showing': hint=='comment'}" class="tip">
          <label>Comment</label>
          <div>
            Provide a reason here for editing historical data.
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="selected_correction_method !== 'Apply constant value'">
      <mat-form-field *ngIf="selected_correction_method === 'Apply correction factor'" style="max-width:200px;"
                      (mouseenter)="hint='Initialise Correction Factor'">
        <mat-label>Initialise Correction Factor</mat-label>
        <input maxlength="100px" [(ngModel)]="initialise_factor" matInput name="initialise_factor"
               placeholder="Initialise Correction Factor" type="number">
      </mat-form-field>

      <mat-form-field (mouseenter)="hint='Correction_factor'">
        <mat-label>Correction factor</mat-label>
        <input [(ngModel)]="factor" matInput name="factor" placeholder="Correction factor" type="number">
      </mat-form-field>

      <mat-form-field (mouseenter)="hint='Target'">
        <mat-label>Target</mat-label>
        <input [(ngModel)]="target" matInput name="target" placeholder="Target" type="number">
      </mat-form-field>

    </div>

    <div class="row" *ngIf="selected_correction_method !== 'Apply constant value'">
      <mat-checkbox (mouseenter)="hint='Auto_Refresh'" [(ngModel)]="use_offset"
                    class="self-center"
                    [ngModelOptions]="{standalone: true}"
                    style="min-width:unset;font-weight:bold;flex-grow:0.4;">Use offset
      </mat-checkbox>

      <mat-form-field (mouseenter)="hint='Offset_value'">
        <mat-label>Offset value</mat-label>
        <input [(ngModel)]="offset" matInput name="offset" placeholder="Offset value" type="number">
      </mat-form-field>
      <div class="tip-box">
        <div [ngClass]="{'showing': hint=='Auto_Refresh'}" class="tip">
          <label>Use offset</label>
          <div>
            Use this tick box to apply a correction by using an off set, allowing the calculation engine to alter the
            values by a set value for the chosen time period.
          </div>
        </div>
        <div [ngClass]="{'showing': hint=='Offset_value'}" class="tip">
          <label>Offset value</label>
          <div>
            This value will alter the value of the series at each timestamp for the chosen time period by adding this
            value to the current series value.
          </div>
        </div>
        <div [ngClass]="{'showing': hint=='Correction_factor'}" class="tip">
          <label>Correction factor</label>
          <div>
            This value will alter the value of the series at each timestamp for the chosen time period by multiplying this
            value to the current series values.
          </div>
        </div>
        <div [ngClass]="{'showing': hint=='Target'}" class="tip">
          <label>Target</label>
          <div>
            Please enter the target (expected) corrected total for the selected time period.
          </div>
        </div>

        <div [ngClass]="{'showing': hint=='Initialise Correction Factor'}" class="tip">
          <label>Initialise Correction Factor</label>
          <div>
            Provide an initialistion value here for the correction factor. This increases the speed and accuracy of the
            correction factor that WIRE determines.
          </div>
        </div>

      </div>
    </div>

    <div class="row" *ngIf="selected_correction_method === 'Apply constant value'">
      <mat-form-field style="max-width:200px;"
                      (mouseenter)="hint='Value'">
        <mat-label>Value</mat-label>
        <input [(ngModel)]="value" matInput name="value" placeholder="Value" type="number">
      </mat-form-field>

      <button (click)="applyDayValue()"
              [disabled]="!checkDayValueReady()"
              [ngStyle]="{'opacity': !checkDayValueReady() ? 0.5 :  1}"
              style="align-self: center;"
              class="bordered-button" mat-button>Update hourly value
      </button>

      <div class="tip-box">

        <div [ngClass]="{'showing': hint=='Value'}" class="tip">
          <label>Value</label>
          <div>
            This value overrides the current values in WIRE for the *selected series* over the
            selected timespan and sample period and replaces it with the constant value you have provided.
          </div>
        </div>

      </div>
    </div>

    <div class="row" style="margin-top:20px;" *ngIf="selected_correction_method !== 'Apply constant value'">

      <button (click)="getCorrectionFactor()"
              [disabled]="!(checkCorrectionReady() && selected_correction_method !== 'Apply constant value')"
              class="bordered-button" mat-button
              [ngStyle]="{'opacity': checkCorrectionReady() && selected_correction_method !== 'Apply constant value' ? 1 :  0.5}"
              matTooltip="Determine correction factor">Determine correction factor
      </button>

      <button (click)="applyCorrectionFactor()" [disabled]="!checkCorrectionReady()" class="bordered-button" mat-button
              [ngStyle]="{'opacity': checkCorrectionReady() ? 1 :  0.5}"
              matTooltip="Apply correction factor">Apply correction factor
      </button>

      <button (click)="overrideCalculations()"
              [disabled]="!(series && permissions[series.id] && permissions[series.id].includes('override_calculations'))"
              [ngStyle]="{'opacity': series && permissions[series.id] && permissions[series.id].includes('override_calculations') ? 1 :  0.5}"
              class="bordered-button" mat-button>Update calculations
      </button>
    </div>

    <div class="row tip-box detail" *ngIf="selected_correction_method !== 'Apply constant value'">
      <div class="tip">
        <label>Apply correction factor</label>
        <div>
          This method of WIRE data correction can be applied in two ways.
          <p>
            1. By *series* selection: <br/>
            Provide a value a total that this series should be for a given time period (correction total). This then
            allows WIRE to calculate and apply
            a correction factor to the series for the selected time period.
          </p>
          <p>
            Alternatively, you can proved a correction factor that you have pre-determined (in the correction total input
            section) and WIRE will apply
            the correction factor to the selected time period.
          </p>
          <p>
            2. By *calculation* selection: <br/>
            If the series that you wish to correct is a calculation, and you know the total that this calculation should
            be, you can use this method.
            You can select one of the series that this calculation depends on "here" that you wish to correct for the
            selected time period and WIRE will change
            the selected series to achieve the correct calculation total that you provided. -
          </p>
        </div>
      </div>
    </div>

    <div class="row display"
         *ngIf="series&&permissions&&permissions[series.id] && !permissions[series.id].includes('apply_correction_factor')">
      You do not have user rights to apply a correction factor to this series
    </div>

    <div class="row display"
         *ngIf="series && permissions[series.id] && !permissions[series.id].includes('override_calculations')">
      You do not have user rights to override this calculation
    </div>

  </div>
</form>



