import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AppScope} from "../../services/app_scope.service";
import {DomSanitizer} from "@angular/platform-browser";
import {HeaderDataService} from "../../services/header_data.service";

@Component({
    selector: 'help-page',
    templateUrl: './help-page.component.html',
    styleUrls: ['./help-page.component.less']
})
export class HelpPageComponent implements OnInit, AfterViewInit, OnDestroy {
    url;

    constructor(private appScope: AppScope,
                private domSanitizer: DomSanitizer,
                private headerDataService: HeaderDataService) {
    }

    ngOnInit(): void {

        this.headerDataService.title = 'Help documentation';
    }

    ngOnDestroy(): void {
        this.url = null;

    }

    ngAfterViewInit(): void {
        const ctrl = this;
        ctrl.appScope.auth_complete.promise.then(() => {
            let url;
            url = "https://docs.google.com/presentation/d/e/2PACX-1vRIFFNJcsTrX8lWfLFrPPtrPkHMU2Zm6PZwDvr" +
                "UJbgcujDqFGZIVJOwJAaBze4v8cNB4ifGh2oUMiAW/embed?start=true&loop=true&delayms=60000";
            const url_untrusted = url;
            ctrl.url = this.domSanitizer.bypassSecurityTrustResourceUrl(url_untrusted);

        });
    }
}
