import * as moment_ from 'moment';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild, ViewEncapsulation, Input
} from "@angular/core";
import {HeaderDataService} from "../../services/header_data.service";
import {ApiService} from "../../services/api.service";
import {DateTimePeriod, DateTimePeriodService} from "../../services/datetime_period.service";
import {Subject, Subscription} from "rxjs";
import {MatPaginator, MatSort, MatTableDataSource} from "@angular/material";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {SeriesDataService} from "../../services/series_data.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ChartDialog} from "../../charts/chart-dialog.component";

export const moment = moment_["default"];

@Component({
    selector: 'value-driver-tree-table',
    templateUrl: 'value-driver-tree-table.component.html',
    styleUrls: ['value-driver-tree-table.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,  //Global Styles
})
export class ValueDriverTreeTableComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    public calculations: any = [];
    public columns: string[];
    dataSource: MatTableDataSource<any>;
    page_size: number = 10;
    status: any;
    highlight_index: number;
    private sort;
    private readonly onDestroy = new Subject<void>();

    constructor(private api: ApiService, private http: HttpClient, public headerData: HeaderDataService,
                public dialog: MatDialog, private seriesData: SeriesDataService,
                private snackBar: MatSnackBar, private ref: ChangeDetectorRef) {
    }

    private _tableData: any;

    get tableData(): any {
        return this._tableData;
    }

    @Input() set tableData(value: any) {
        this._tableData = value;
        if (value && value.length > 0) {
            this.getTable();
        }
    }

    private _mouseoverNode: any;

    get mouseoverNode(): any {
        return this._mouseoverNode;
    }

    @Input() set mouseoverNode(value: any) {
        this._mouseoverNode = value;
        this.showInTable(this._mouseoverNode);
    }

    @ViewChild(MatSort, {static: false}) set content(content: ElementRef) {
        this.sort = content;
        if (this.sort && this.dataSource) {
            this.dataSource.sort = this.sort;
        }
    }

    ngOnInit(): void {
        this.headerData.title = 'Value Driver Tree';
        this.columns = ['depth', 'name', 'description', 'type', 'formula', 'assumptions', 'parent'];
    }

    getTable() {
        const ctrl = this;

        ctrl.tableData.map((item) => {
            item['name'] = item.series.attributes ? item.series.attributes.name : item.series;
            item['description'] = item.series.attributes ? item.series.attributes.description : item.series;
            item['formula'] = item.series.attributes ? item.series.attributes.name_formula : null;
            item['depth'] = item.depth;
            item['type'] = item.series.type;
            item['assumptions'] = item.series.attributes ? item.series.attributes.assumptions : null;
            item['parent'] = item.parent ? item.parent.attributes.name : null;
            item['index'] = item.index;
            item['parent_index'] = item.parent_index;
        });
        this.dataSource = new MatTableDataSource(ctrl.tableData);
        this.dataSource.filterPredicate = (data, filter) => {
            if (data.name.toLowerCase().includes(filter)
                || data.description.toLowerCase().includes(filter)
                || data.formula.toLowerCase().includes(filter)
                || data.type.toLowerCase().includes(filter)
                || data.depth.includes(filter)
                || data.parent.toLowerCase().includes(filter)
            ) {
                return true;
            }
            return false;
        };
        this.dataSource.paginator = this.paginator;

    }

    highlight(row) {
        this.highlight_index = row.parent_index;
        console.log('ValueDriverTreeTableComponent - highlight: ', this.highlight_index);
    }

    showInTable(node) {
        if (node) {
            let row = this.tableData.find(row => {
                return row.name === node.data.series.attributes.name
            });
            this.highlight_index = row.index;
        } else {
            this.highlight_index = null;
        }
    }

    openChartDialog(series_name): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = series_name;
        dialogConfig.panelClass = 'chart-dialog';
        const dialogRef = this.dialog.open(ChartDialog, dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            if (result) {
            }
        });
    }

    editSeries(element) {
        const ctrl = this;
        let $series_full = this.api[element.type].getById(element.series.id).toPromise();
        $series_full.then(returned => {
            let series_full = returned.data;
            ctrl.seriesData.upsertSeries(null, series_full).afterClosed().pipe(takeUntil(this.onDestroy)).subscribe((series) => {
                if (series) {
                    let updated_series;
                    if (series.series) {
                        updated_series = series.series;
                    } else {
                        updated_series = series;
                    }
                    element['name'] = updated_series.attributes.name;
                    element['description'] = updated_series.attributes.description;
                    element['formula'] = updated_series.attributes.name_formula;
                    ctrl.snackBar.open("Series saved. Click refresh to run validation again", 'Hide');
                }
            })
        })
    }

    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }
}