import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {forkJoin, ReplaySubject, Subscription} from "rxjs";
import {AppScope} from './app_scope.service';
import {UserData} from "./user_data.service";

export enum MenuNodeType {
    FOLDER,
    MENU,
    STATIC_FOLDER,
    STATIC
}

export interface TreeEntry {
    readonly type: MenuNodeType;
    readonly order: number;
}

/**
 * Folders that are configured per customer
 */
export class FolderEntry implements TreeEntry {
    readonly type: MenuNodeType = MenuNodeType.FOLDER;

    readonly folder: Folder;
    readonly order;

    readonly id: string;
    readonly name: string;

    parent?: FolderEntry;
    contents: TreeEntry[] = [];
    menu_entry_count: number = 0;

    constructor(folder: Folder) {
        this.folder = folder;
        this.order = folder.attributes.order;
        this.id = folder.id;
        this.name = folder.attributes.name;
    }

    getParentId(): string | null {
        if (this.folder.relationships.parent.data) {
            return this.folder.relationships.parent.data.id;
        }
        return null;
    }

    toString(): string {
        return self.name;
    }
}

/**
 * Folders that only exist in the client and exist for all customers
 */
export class StaticFolderEntry implements TreeEntry {
    readonly type: MenuNodeType = MenuNodeType.STATIC_FOLDER;

    title: string;
    contents: TreeEntry[] = [];

    menu_entry_count: number = 0;
    order;

    constructor(title: string, contents: TreeEntry[] = []) {
        this.title = title;
        if (contents.length > 0) {
            this.contents = contents;
        }
    }
}

export class MenuEntry implements TreeEntry {
    session_state: any;
    type: MenuNodeType = MenuNodeType.MENU;
    order;
    visible: boolean = true;
    private: boolean = false;
}

export class StaticEntry implements TreeEntry {
    title: string;
    link: any;
    type: MenuNodeType = MenuNodeType.STATIC;
    order;

    constructor(title: string, link: any) {
        this.title = title;
        this.link = link;
    }
}

@Injectable({
    providedIn: 'root'
})
export class MenuTreeService {
    // empty trees pruned from tree
    readonly menuTreeChanged: ReplaySubject<{ [account_id: string]: TreeEntry[] }> = new ReplaySubject<any>(1);
    // full folders list, including empty folders
    readonly menuTreeChangedFull: ReplaySubject<{ [account_id: string]: TreeEntry[] }> = new ReplaySubject<any>(1);
    private refresh_subscription: Subscription;

    constructor(private api: ApiService,
                private appScope: AppScope,
                private userData: UserData) {
        // this.refresh();
    }

    // TODO add method to add session_states without needing to refresh
    public refresh() {
        const ctrl = this;
        this.appScope.auth_complete.promise.then(() => {
            if (this.refresh_subscription) {
                this.refresh_subscription.unsubscribe();
                this.refresh_subscription = null;
            }

            const $session_states = this.api.session_state_light.search();
            const $folders = this.api.folder.search();

            this.refresh_subscription = forkJoin([$session_states, $folders]).subscribe(response => {
                let session_states = response[0].data;
                const folders = response[1].data;
                const current_user = ctrl.appScope.current_user;
                const folderNodes = this.extractFoldersFromResponse(folders);
                const generated = this.generateTreeMap(folderNodes);
                const map = generated.all;
                const roots = generated.roots;

                const session_state_map: { [id: string]: any } = {};

                if (current_user.restricted_access) {
                    const restricted_dashboards = ctrl.userData.getRestrictedDashboardsQuick(session_states);
                    session_states = restricted_dashboards;
                }

                // Hide session_states with visibility private and not linked to this user, hide
                session_states = session_states.filter(session_state => {
                    return session_state.attributes.visibility !== 'private' ||
                        (session_state.relationships.user.data.id === current_user.id);
                });

                session_states.forEach(item => {
                    session_state_map[item.id] = item;
                });

                Object.values(map).forEach(node => {
                    node.contents = node.contents.concat(node.folder.relationships.session_states.data
                        .filter(session_state => !!session_state_map[session_state.id])
                        .map(session_state => {
                            const menuEntry = new MenuEntry();
                            menuEntry.session_state = session_state_map[session_state.id];
                            menuEntry.order = menuEntry.session_state.attributes.code;
                            menuEntry.private = session_state_map[session_state.id].attributes.visibility === 'private';
                            menuEntry.visible = (session_state_map[session_state.id].attributes.visibility !== 'private' ||
                                session_state_map[session_state.id].relationships.user.data.id === current_user.id);
                            return menuEntry;
                        }))
                });

                this.populateMenuEntryCount(map);

                // Add root folders for each account
                const account_folders = {};
                const account_folders_all = {};
                Object.values(roots).forEach(node => {
                    if (node.menu_entry_count <= 0) {
                        return;
                    }

                    const linked_account = node.folder.relationships.account;
                    if (linked_account.data) {
                        const account_id = linked_account.data.id;
                        let account_menus = account_folders[account_id];
                        if (!account_menus) {
                            account_menus = [];
                            account_folders[account_id] = account_menus;
                        }
                        account_menus.push(node);
                    }
                });

                Object.values(roots).forEach(node => {
                    const linked_account = node.folder.relationships.account;
                    if (linked_account.data) {
                        const account_id = linked_account.data.id;
                        let account_menus = account_folders_all[account_id];
                        if (!account_menus) {
                            account_menus = [];
                            account_folders_all[account_id] = account_menus;
                        }
                        account_menus.push(node);
                    }
                });

                const folder_sort = (a: FolderEntry, b: FolderEntry) => {
                    const order_a = a.order;
                    const order_b = b.order;
                    if (order_a == null) {
                        return order_b == null ? 0 : 1;
                    } else {
                        if (order_b == null) {
                            return -1;
                        } else {
                            return order_a > order_b ? 1 : order_a < order_b ? -1 : 0;
                        }
                    }
                };

                // sorts the root folders
                Object.keys(account_folders).forEach(account_id => {
                    account_folders[account_id] = account_folders[account_id].sort(folder_sort)
                });
                Object.keys(account_folders_all).forEach(account_id => {
                    account_folders_all[account_id] = account_folders_all[account_id].sort(folder_sort)
                });

                // sorts all other folder and their enclosed items
                Object.values(map).forEach(node => {
                    node.contents = node.contents.sort(folder_sort);
                });

                this.menuTreeChanged.next(account_folders);
                this.menuTreeChangedFull.next(account_folders_all);
                this.appScope.folders_map = map;
            })
        });
    }

    /**
     * Create a forest of folders.
     *
     * @param list
     * @returns map of
     */
    private generateTreeMap(list: FolderEntry[]): { roots: { [folder_id: string]: FolderEntry }, all: { [folder_id: string]: FolderEntry } } {
        const map: { [folder_id: string]: FolderEntry } = {};
        list.forEach(folder => map[folder.id] = folder);

        // construct the forest of graphs by linking children to parents
        Object.values(map).forEach(node => {
            const parent_id = node.getParentId();
            if (!parent_id) {
                // root node
                return;
            }
            // folders only have a single parent folder
            const parent_node = map[parent_id];
            if (!parent_node) {
                console.error('Folder referenced parent which did not exist.');
                return;
            }
            parent_node.contents.push(node);
            node.parent = parent_node;
        });

        // find and remove folders causing cycles
        const explored_nodes = new Set<string>();
        let to_explore: FolderEntry[] = Object.values(map).filter(node => !node.parent);
        const root_ids = to_explore.map(node => node.folder.id);
        while (to_explore.length > 0) {
            const node = to_explore.shift();
            explored_nodes.add(node.folder.id);
            const tmp: FolderEntry[] = <FolderEntry[]>node.contents.filter((item: FolderEntry) => !explored_nodes.has(item.folder.id));
            node.contents = tmp;
            to_explore = to_explore.concat(tmp);
        }

        // remove folders which are in cycles
        const not_explored = [];
        Object.keys(map).forEach(id => {
            if (!explored_nodes.has(id)) {
                const node = map[id].folder;
                not_explored.push(node);
                delete map[id];
            }
        });

        if (not_explored.length > 0) {
            console.warn('Some folders have been disabled due being contained in cycles:', not_explored)
        }

        const root_map: { [folder_id: string]: FolderEntry } = {};
        root_ids.forEach(id => {
            root_map[id] = map[id];
        });

        return {roots: root_map, all: map};
    }

    private populateMenuEntryCount(map: { [folder_id: string]: FolderEntry }) {
        const countNode = (node: FolderEntry) => {
            node.menu_entry_count = node.contents.filter(item => item.type === MenuNodeType.MENU).length;

            const folders: FolderEntry[] = <FolderEntry[]>node.contents.filter(item => item.type === MenuNodeType.FOLDER);
            if (folders.length > 0) {
                node.menu_entry_count += folders.map(item => {
                    return countNode(item);
                }).reduce((a, b) => a + b);
            }
            return node.menu_entry_count;
        };

        Object.values(map).filter(node => !node.parent).forEach(node => {
            countNode(node);
        })
    }

    public extractMenuEntries(node: TreeEntry): MenuEntry[] {
        let entries = [];
        switch (node.type) {
            case MenuNodeType.FOLDER:
            case MenuNodeType.STATIC_FOLDER:
                const folder = <FolderEntry>node;
                folder.contents.forEach(entry => {
                    entries = entries.concat(this.extractMenuEntries(entry));
                });
                break;
            case MenuNodeType.MENU:
                entries = [<MenuEntry>node];
                break;
            case MenuNodeType.STATIC:
                break;
        }
        return entries
        // if (contents.length > 0) {
        //     contents.forEach(folder => {
        //         if (folder.type === 0) {
        //             ctrl.folder_options.push({
        //                 name: folder.folder.attributes.name, folder: folder.folder,
        //                 parent: folder.parent ? folder.parent.folder.attributes.name : 'Root'
        //             });
        //             ctrl.flatten(folder.contents, ctrl);
        //         }
        //     })
        // }
    }

    private extractFoldersFromResponse(folders: Folder[]): FolderEntry[] {

        return folders.map(folder => new FolderEntry(folder));
    }
}

export class Folder {
    id: string;
    attributes: {
        name: string;
        description?: string;
        order?: number;
    };
    relationships: {
        account: {
            data: { id: string }
        },
        children?: {
            data: { id: string }[]
        },
        parent?: {
            data: { id: string }
        },
        session_states?: {
            data: { id: string }[]
        }
    }
}