import {Component, Input} from '@angular/core';

@Component({
    selector: 'input-data-sheet-form',
    templateUrl: 'input-data-sheet-form.component.html',
})
export class InputDataSheetFormComponent {
    private _config: any;
    @Input()
    set config(config) {
        this._config = config;
        this.inputUpdated();
    }

    get config() {
        return this._config;
    }

    private _processes: any[];
    @Input()
    set processes(processes: any[]) {
        this._processes = processes;
        if (this._processes !== undefined) {
            this.inputUpdated();
        }
    }

    get processes() {
        return this._processes;
    }

    process: any;
    hint: string = '';

    constructor() {
    }

    private inputUpdated() {
        const ctrl = this;
        if (ctrl.processes && ctrl._config.process) {
            ctrl.process = ctrl.processes.find(item => item.id === ctrl._config.process.id);
        }
    }

    private slimProcess(process) {
        return {
            id: process.id,
            attributes: {
                name: process.attributes.name
            }
        }
    }

    processChange() {
        this.config.process = this.slimProcess(this.process);
    }
}
