import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ApiService} from "../services/api.service";

@Component({
    selector: 'constant-component-table',
    templateUrl: 'constant-component-table.component.html',
    styleUrls: ['../forms/forms.less', 'series-component-table.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class ConstantComponentTableComponent implements OnInit {
    @Input() component: any;
    @Input() constant_property_dict: any;
    @Input() constant_components: any;
    @Input() all_series:any;
    @Input() flowchart:any;
    @Input() showHints: any;

    tree_position: string = 'child';
    saving:boolean = false;

    constructor(private api:ApiService) {
        //this.selectChange = new EventEmitter();
    }

    ngOnInit():void {
        const ctrl = this;
        if (ctrl.component.is_parent){
            ctrl.tree_position = 'parent';
        } else {
            ctrl.tree_position = 'child';
        }
    }

    deleteConstantComponent(item){
        const ctrl = this;
        if(item.hasOwnProperty('id')){
            let delete_item = ctrl.api.constant_component.delete(item.id);
            delete_item.then( function (ret) {

                    delete ctrl.component.attributes.custom_constants[ctrl.constant_property_dict[item.relationships.constant_property.data.id].attributes.name];
                    ctrl.component.relationships.constant_components.data = ctrl.component.relationships.constant_components.data.filter (function (deleted_component){
                        return deleted_component.id != item.id
                    });
                    ctrl.flowchart.deleteConstantComponents([item.id]);
                    ctrl.constant_components = ctrl.constant_components.map (function (constant_component){
                        if(item.id === constant_component.id) {
                            return ctrl.constant_component_schema(item);

                        }
                        return constant_component;
                    });
                    ////var attArray = ctrl.component.attributes.custom_constants.split(",");
                },function (ret) {
                    //$mdToast.show($mdToast.simple().textContent('Failed to delete, check dependent objects'));
                }
            );
        }
    };

    addConstantComponent(constant_component){
        const ctrl = this;
        let property_name = ctrl.constant_property_dict[constant_component.relationships.constant_property.data.id].attributes.name;
        ctrl.component.attributes.custom_constants[property_name] = constant_component.attributes.value;
        ctrl.saving = true;
        if(!constant_component.id){
            var new_constant_component = ctrl.api.constant_component.save(constant_component);
            new_constant_component.then(result => {
                new_constant_component = result.data;
                constant_component.id = new_constant_component['id'];
                ctrl.component.relationships.constant_components.data.push(constant_component);
                ctrl.flowchart.addConstantComponent(constant_component, ctrl.flowchart.parent_process, ctrl.component); //chart_process here = the viewmodel of the process
                ctrl.saving = false;
            });
        } else {
            //delete constant_component.attributes.data_type
            var new_constant_component = ctrl.api.constant_component.patch(constant_component);
            new_constant_component.then(function(){
                ctrl.saving = false;
                ctrl.updateComponent(constant_component);
            });
        }
    };

    constant_component_schema (constant_component) {
        const ctrl = this;
        return {
            attributes: {
                value: null,
                json: {}
            },
            relationships: {
                component: {data: {type: 'component', id: ctrl.component.id}},
                constant_property: {data: {type: 'constant_property',
                    id: constant_component.relationships.constant_property.data.id}}
            },
            type: 'constant_component'
        }
    };

    updateComponent (constant_component){
        const ctrl = this;
        ctrl.component.relationships.constant_components.data.forEach(function(rel){
            if(rel.id === constant_component.id){
                rel.attributes = constant_component.attributes;
            }
        })
    }

}
