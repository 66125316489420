<mat-dialog-content [ngClass]="{'showing-hints':showing_hints}">
  <mat-tab-group [disableRipple]="true">
    <mat-tab label="Properties">
      <form class="form-section column space-between">
        <div class="column">
          <div class="row">
            <mat-form-field (mouseenter)="hint='Name'" style="flex-basis:50%;flex-grow:0;">
              <label>Name</label>
              <input [(ngModel)]="efc.component_data.attributes.name" matInput name="name" placeholder="">
            </mat-form-field>

            <mat-form-field (mouseenter)="hint='Description'" style="flex-basis:50%;flex-grow:0;">
              <label>Description</label>
              <input [(ngModel)]="efc.component_data.attributes.description" matInput name="description" placeholder="">
            </mat-form-field>

            <div class="tip-box" style="flex-grow:1;">
              <div [ngClass]="{'showing': hint=='Name'}" class="tip">
                <label>Name</label>
                <div>
                  The name of the stream.
                </div>
              </div>
              <div [ngClass]="{'showing': hint=='Description'}" class="tip">
                <label>Description</label>
                <div>
                  A more lengthy description of the stream.
                </div>
              </div>
            </div>
          </div>
          <div class="row reduce-height">
            <mat-form-field (mouseenter)="hint='Code'">
              <mat-label>Code</mat-label>
              <input [(ngModel)]="efc.component_data.attributes.code" matInput name="code" placeholder="">
            </mat-form-field>

            <div class="tip-box" style="flex-grow:1;">
              <div [ngClass]="{'showing': hint=='Code'}" class="tip">
                <label>Code</label>
                <div>
                  An alpha-numerical code that sets the sort order of a group of streams.
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </mat-tab>
    <mat-tab *ngIf="efc.component_data.hasOwnProperty('id')" label="Display">
      <form class="form-section column space-between">
        <div class="column">
          <div class="row">

            <mat-form-field (mouseenter)="hint='FontSize'">
              <mat-label>Font Size</mat-label>
              <input [(ngModel)]="efc.component_data.attributes.json.fontsize" matInput max="32" name="font_size"
                     type="number">
            </mat-form-field>
            <div style="flex-grow:1;">
              <mat-label>Stream Colour</mat-label>
              <colour-palette
                  [(value)]="efc.component_data.attributes.json.colour"
                  [palette]="1"
                  [show_text]="true">
              </colour-palette>
            </div>
          </div>
          <div class="tip-box" style="flex-grow:1;">
            <div [ngClass]="{'showing': hint=='FontSize'}" class="tip">
              <label>Font Size</label>
              <div>
                The size of the font displayed for this stream in the flowchart. (A maximum of 32px is allowed.)
              </div>
            </div>
          </div>
        </div>

      </form>
    </mat-tab>

    <mat-tab *ngIf="efc.component_data.hasOwnProperty('id')" label="Relationships">
      <form class="form-section column space-between">
        <div class="column">
          <div class="row">
            <mat-form-field (mouseenter)="hint='StreamType'" style="flex-basis:50%;flex-grow:0;">
              <mat-label>Stream Type</mat-label>
              <mat-select (selectionChange)="efc.fillConstantTemplate()"
                          [(ngModel)]="efc.component_data.relationships.component_type.data"
                          name="stream_type">
                <mat-option *ngFor="let component_type of efc.component_types" [value]="component_type">
                  {{component_type.attributes.name}} - {{component_type.attributes.description}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button (click)="efc.fillSeriesTemplate()" (mouseenter)="hint='FillSeriesTemplate'"
                    [hidden]="!efc.component_data.relationships.component_type.data"
                    class="btn"
                    style="justify-self: center;align-self:center;">Fill Template
            </button>

            <div class="tip-box" style="flex-grow:1;">
              <div [ngClass]="{'showing': hint=='StreamType'}" class="tip">
                <label>Stream Type</label>
                <div>
                  This will be used to identify the template of series types that are applicable for this stream.
                  After creating the stream, the 'Fill series template' button can be used to add these series types to
                  this process.
                </div>
              </div>

              <div [ngClass]="{'showing': hint=='FillSeriesTemplate'}" class="tip">
                <label>Fill Series Template</label>
                <div>
                  This will be used to identify the template of series types that are applicable for this stream.
                  After creating the stream, the 'Fill series template' button can be used to add these series types to
                  this stream.
                </div>
              </div>
            </div>
          </div>

          <h4 *ngIf="efc.constant_properties_ready === true">Custom Properties</h4>
          <constant-component-table *ngIf="efc.constant_properties_ready === true" [component]="efc.component_data"
                                    [constant_components]="efc.constant_components"
                                    [constant_property_dict]="efc.constant_property_dict"
                                    [flowchart]="efc.chart"
                                    class="row">
          </constant-component-table>

          <h4>Series</h4>
          <div class="row">
            <div (mouseenter)="hint='select_series'" class="select-search-many" style="flex-grow:2">
              <select-many-search [(value)]="efc.selected_series"
                                  [classes]="'fill'"
                                  [data]="efc.plantData.all_series"
                                  [fill_stubs]="true"
                                  [label]="'Select a series'"

              >
              </select-many-search>
              <!--(selectChange)="selected_series=$event;"-->
            </div>
            <div style="align-self:center;display:flex;">
              <button (click)='efc.addSeries()' class="icon" matTooltip="Add to stream">
                <i class="fa fa-arrow-down"></i></button>
              <button (click)="efc.showSeriesForm()" class="icon-new" matTooltip="Add new series">
              </button>
              <!--<button class="btn" (click)="efc.setTemplate()" matTooltip="Set Template"><i class="fa fa-tasks" ></i></button>-->
            </div>
            <!--<div class="hint">-->
            <!--Select a series and use the Add to process button to add it to this process. The Add new series button can be used to create a new series which will be added to this process.-->
            <!--</div>-->
          </div>

          <series-component-table *ngIf="efc.series_properties" [all_series]="efc.plantData.all_series"
                                  [component]="efc.component_data"
                                  [deleted_component_ids]="efc.deleted_component_ids"
                                  [flowchart]="efc.chart"
                                  [series_properties]="efc.series_property_dict"
                                  class="row">
          </series-component-table>

          <h4 *ngIf="efc.series_permissions" style="margin-top:20px;">Constant Series Data </h4>
          <!--<div class="hint" ng-if="$parent.$parent.showHints && show_constants">-->
          <!--Manage constant values for a series in the form(s) below. Use the 'Add new' button to add a new constant value for a series. To remove an entry, delete the value at the required date-time. Use the 'Save' button to save all changes.-->
          <!--</div>-->

          <ng-container *ngIf="efc.series_permissions">
            <div *ngFor="let series_component of efc.component_data.relationships.series_components.data;"
                 style="min-width:650px;">
              <ng-container *ngIf="series_component.relationships.series.data && (
                                series_component.relationships.series.data.attributes.fill_method=='Constant'
                                || series_component.relationships.series.data.attributes.fill_method=='Constant Monthly Flatten')
                                && efc.series_permissions[series_component.relationships.series.data.id].indexOf('view_process_data') > -1">

                <div class="row">
                  <label
                      (click)="series_component.relationships.series.data.hide_constants=!series_component.relationships.series.data.hide_constants"
                      style="color:gray; text-decoration: underline;padding-top:20px;cursor:pointer;">
                    {{series_component.relationships.series.data.attributes.name}}
                    <i [ngClass]="{'fa-angle-up': !series_component.relationships.series.data.hide_constants, 'fa-angle-down': series_component.relationships.series.data.hide_constants}"
                       aria-label="Hide data values"
                       class="fa fa-angle-up"
                       matTtooltip="Hide data values" role="button" style="border:none; margin-left:12px;" tabindex="0">
                    </i></label>
                </div>
                <constant-series-data *ngIf="!series_component.relationships.series.data.hide_constants"
                                      [series]="series_component.relationships.series.data"
                                      [series_permissions]="efc.series_permissions">
                </constant-series-data>
              </ng-container>
            </div>
          </ng-container>

          <!--<div layout="row" ng-if="component.type==process || component.type==stream">-->
          <!--<div layout="row" class="section" ng-click="show_equipment=!show_equipment">-->
          <!--<h4>Equipment-->
          <!--<i class="fa hideonprint fa-angle-down" ng-class="{'fa-angle-up': show_equipment, 'fa-angle-down': !show_equipment}" aria-label="Show equipment" role="button"></i>-->
          <!--</h4>-->
          <!--</div>-->
        </div>
      </form>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<div class="action-buttons">
  <button (click)="close()" *ngIf="dialog" class="btn-close">Close</button>
  <button (click)="save()" class="btn-save" style="margin-right:10px;">Save</button>
</div>




