import {
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ApiService} from "../../services/api.service";
import * as d3 from 'd3';
import {TreeLayout} from 'd3';
import {Subject} from "rxjs";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {ChartDialog} from "../../charts/chart-dialog.component";
import {HeaderDataService} from "../../services/header_data.service";
import * as utils from '../../lib/utils';
import {Series, SeriesDataService} from "../../services/series_data.service";
import {takeUntil} from "rxjs/operators";
import {DateTimePeriod, DateTimePeriodService} from "../../services/datetime_period.service";
import {CachingService} from "../../services/caching.service";
import {SplitAreaDirective, SplitComponent} from 'angular-split';
import {TreeNode} from "./types/tree.node";

@Component({
    selector: 'value-driver-tree',
    templateUrl: './value-driver-tree.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [CachingService]
})
export class ValueDriverTreeComponent implements OnInit, OnDestroy {
    treemap: TreeLayout<any>;
    formula_view: boolean = false;
    table_data: any = [];

    series_full: Series[] = [];
    treeData: any = {
        "description": "Key Value Driver",
        "children": [
            {
                "description": "Value driver 1",
                "children": [
                    {"description": "Value driver 3"},
                    {"description": "Value driver 4"}
                ]
            },
            {"description": "Value driver 2"}
        ],
    };
    selected_calculations: any[] = [];
    selected_calculation: any;
    calculations_full: any = [];
    dtp: DateTimePeriod;

    default: boolean;

    searchText: any;
    tree: d3.TreeLayout<{}>;
    g: any;
    svg: any;
    width: number = 800;
    duration: number;
    i: number;
    root: any;
    treeNodeMap: { [key: string]: TreeNode } = {};
    chartDiv: HTMLElement;
    margin = {top: 20, right: 20, bottom: 30, left: 20};
    height: number = 600;
    vb_width: number = 1150;
    vb_height: number = 500;
    box_width: number = 180;
    box_height: number = 80;
    depth: number = 220;
    current_depth: number = 0;
    selected_series: any;

    menuVisible: boolean = false;
    treeNodes: any;
    links: any;
    private readonly onDestroy = new Subject<void>();

    @ViewChild('value_driver_tree', {static: false}) value_driver_tree: ElementRef;
    @ViewChild('svg_tree', {static: false}) svg_tree: ElementRef;
    @ViewChild('context_menu', {static: false}) context_menu: ElementRef;

    @ViewChild('split', {static: false}) split: SplitComponent;
    @ViewChild('area1', {static: false}) area1: SplitAreaDirective;
    @ViewChild('area2', {static: false}) area2: SplitAreaDirective;
    direction: 'horizontal' | 'vertical' = 'vertical';
    sizes = {
        percent: {
            area1: 75,
            area2: 25,
        },
        pixel: {
            area1: 120,
            area2: '*',
            area3: 160,
        },
    };

    @HostListener('click', ['$event'])
    onClick() {
        if (this.menuVisible === true) {
            this.menuVisible = false;
        }
    }

    scrollHandler(event) {
        if (event.wheelDelta < 0) {
            this.vb_height += 50;
            this.vb_width += 50;
        } else {
            if (this.vb_height > 50 && this.vb_width > 50) {
                this.vb_height -= 50;
                this.vb_width -= 50;
            }
        }
        event.preventDefault();
    }

    constructor(private api: ApiService,
                public dialog: MatDialog,
                private headerData: HeaderDataService,
                private renderer: Renderer2,
                private seriesData: SeriesDataService,
                private dateTimePeriodService: DateTimePeriodService,
                private cache: CachingService) {
        this.dtp = this.dateTimePeriodService.dtp;
    }

    mouseovernode: any = null;

    ngOnDestroy(): void {
        if (this.onDestroy) {
            this.onDestroy.next();
            this.onDestroy.unsubscribe();
        }
    }

    generateTreeMap(list: Series[]): { [key: string]: TreeNode } {
        const map: { [key: string]: TreeNode } = {};
        list.forEach(series => {
            let node = map[series.attributes.name];
            if (!node) {
                // node was not yet created by a child
                node = new TreeNode();
                map[series.attributes.name] = node;
            }
            // assign series to node and assign children to this node
            node.series = series;
            if (series.type === 'calculation') {
                node.children = series.attributes.variables.map(variable => {
                    // find child node and create & add to map if not exist
                    let child_node = map[variable];
                    if (!child_node) {
                        child_node = new TreeNode();
                        map[variable] = child_node;
                    }
                    // TODO check for duplicate children (maybe calcs that reference series multiple times?)
                    return child_node;
                })
            }
        });
        return map;
    }

    determineTree(event): void {
        const ctrl = this;
        let newID = ctrl.selected_calculation.attributes.name;

        //This is the selected calculation
        let rootNode = this.treeNodeMap[ctrl.selected_calculation.attributes.name];
        ctrl.generateTree(newID, rootNode);
    }

    ngOnInit() {
        const ctrl = this;
        ctrl.headerData.show_dtp = true;
        this.headerData.title = 'Value Driver Trees';
        this.headerData.add_refresh = true;

        //This is way quicker for now since we are only using extra attributes from the calc series
        let $light = ctrl.api.series_light.search().toPromise();
        let $calcs = ctrl.api.calculation.search().toPromise();
        Promise.all([$light, $calcs]).then((results) => {
            let series_light = results[0].data;
            ctrl.calculations_full = results[1].data;
            ctrl.series_full = series_light.map((series) => {
                ctrl.calculations_full.forEach((calc) => {
                    if (calc.id === series.id) {
                        series = calc;
                    }
                });
                return series;
            });

            this.treeNodeMap = this.generateTreeMap(this.series_full);
        })
    }

    mouseEnter(node: MouseEvent) {
        this.mouseovernode = utils.deepCopy(node);
    }

    mouseLeave(node: MouseEvent) {
        this.mouseovernode = null;
    }

    addRow(node, depth, index) {
        let row = {
            series: node.data.series,
            depth: depth,
            parent: null,
            index: index,
            parent_index: node.parent_index
        };
        //row.series = utils.deepCopy(node.data.series);
        if (node.parent) {
            row.parent = node.parent.data.series;
        }
        return row;
    }

    flattenTree(tree, name) {
        let node;
        let stack = utils.deepCopy(tree);
        let i: number = 0;
        stack = stack.map(n => {
            n.parent_index = utils.deepCopy(i);
            return n;
        });
        //All the indexing here is to be able to highlight rows in table when hovering on node or child
        while (stack.length > 0) {
            i += 1;
            node = stack[stack.length - 1];
            node.index = utils.deepCopy(i);
            this.table_data.push(this.addRow(node, node.depth, i));
            stack.pop();
            if (node.data.name === name) return node;
            if (node.children) {
                node.children.map(n => {
                    n.parent_index = i;
                    return n;
                });
                stack.push(...node.children);
            } else if (node._children) {
                node._children.map(n => {
                    n.parent_index = i;
                    return n;
                });
                stack.push(...node._children);
            }
        }
    }

    buildTable(rootNode) {
        let stop = 0;
        const ctrl = this;
        ctrl.table_data = [];
        ctrl.table_data.push(ctrl.addRow(rootNode, 0, 0));
        ctrl.flattenTree(rootNode.children ? rootNode.children : rootNode._children, rootNode.data.name);
    }

    generateTree(selector, rootNode) {
        const ctrl = this;
        ctrl.i = 0;

        //  assigns the data to a hierarchy using parent-child relationships
        ctrl.root = d3.hierarchy(rootNode, function (d) {
            return d.children;
        });

        ctrl.root.x = ctrl.height / 2 - 200;
        ctrl.root.y = 0;

        // declares a tree layout and assigns the size
        ctrl.treemap = d3.tree()
            .nodeSize([ctrl.box_height + 30, ctrl.box_width + 30]);
        ctrl.root.children.forEach((child) => {
            ctrl.collapse(child, this)
        });
        ctrl.createSVG(ctrl.root);
        ctrl.buildTable(ctrl.root);
    }

    createSVG(source) {
        const ctrl = this;
        let treeData = ctrl.treemap(ctrl.root);
        ctrl.treeNodes = treeData.descendants();
        ctrl.links = treeData.descendants().slice(1);
        ctrl.treeNodes.forEach(function (d) {
            d.y = d.depth * ctrl.depth;
        });

        // Store the old positions for transition.
        ctrl.treeNodes.forEach(function (d) {
            d.x0 = d.x;
            d.y0 = d.y;
        });
        ctrl.links.forEach(function (d) {
            d.path = ctrl.genPath(d, d.parent);
        })
    }

    toggle(d) {
        this.current_depth = utils.deepCopy(d.depth);
        if (d.children) {
            d._children = d.children;
            d.children = null;
        } else {
            d.children = d._children;
            d._children = null;
        }
        this.createSVG(d);
    }

    genPath(s, d) {
        let xc = (this.depth - this.box_width) / 2;
        let y = this.box_height / 2;
        let path = `M ${s.y} ${s.x + y}
            C ${((d.y) + this.box_width) + xc} ${s.x + y},
              ${((d.y) + this.box_width) + xc} ${d.x + y},
              ${d.y + this.box_width} ${d.x + y}`;
        return path;
    }

    openChartDialog(series_name): void {
        const ctrl = this;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = series_name;
        dialogConfig.panelClass = 'chart-dialog';
        const dialogRef = this.dialog.open(ChartDialog, dialogConfig);

        dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
            if (result) {
            }
        });
    }

    editSeries(series) {
        const ctrl = this;
        if (series.type === 'series') {
            this.api.series.getById(series.id).toPromise().then((full_series) => {
                ctrl.seriesData.upsertSeries(null, full_series.data);
            })
        } else {
            ctrl.seriesData.upsertSeries(null, series);
        }
    }

    removeTree() {
        d3.select('#value_driver_tree').selectAll('div').remove()
    }

    collapse(d, ctrl) {
        if (d.children) {
            d._children = d.children;
            d._children.forEach((child) => {
                ctrl.collapse(child, ctrl)
            });
            d.children = null;
        }
    }

    contextMenu(e, series, node) {
        this.selected_series = node.data.series;
        console.log('ValueDriverTreeComponent - contextMenu: ', this.selected_series);
        const origin = {
            left: e.pageX,
            top: e.pageY
        };
        this.setPosition(origin);
        return false;
    };

    setPosition({top, left}) {
        this.renderer.setStyle(this.context_menu.nativeElement, 'left', `${left}px`);
        this.renderer.setStyle(this.context_menu.nativeElement, 'top', `${top}px`);
        this.menuVisible = true;
    };

    dragEnd(unit, {sizes}) {
        if (unit === 'percent') {
            this.sizes.percent.area1 = sizes[0];
            this.sizes.percent.area2 = sizes[1];
        } else if (unit === 'pixel') {
            this.sizes.pixel.area1 = sizes[0];
            this.sizes.pixel.area2 = sizes[1];
        }
    }
}
