<style>
  .icon {
    background-color: unset !important;
    width: 16px !important;
    height: 16px !important;
  }
</style>

<div #pivotAnchor (window:resize)="buildPivot()">
</div>

