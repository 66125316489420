<svg
        xmlns="http://www.w3.org/2000/svg"
        height="110%" width="350px"
>
    <defs>
        <linearGradient
                spreadMethod="pad"
                y2="0"
                x2="0"
                y1="1"
                x1="0"
                id="processBackgroundGradient"
        >
            <stop
                    offset="0"
                    stop-opacity="0"
                    stop-color="#56aaff"
            />
            <stop
                    offset="0.63934"
                    stop-opacity="0"
                    stop-color="#d0d0e5"
            />
        </linearGradient>
    </defs>
    <g >
        <rect
                class="process-rect"
                ry="10"
                rx="10"
                x="12"
                y="12"
                [attr.width]="process.width()"
                [attr.height]="process.height()"
                fill="url(#processBackgroundGradient)"
        >


        </rect>
        <image *ngIf = "process.data.attributes.json.pad_image"
               [attr.y]="(process.height()/2 - 94 + 12)"
               [attr.x]="(process.width()/2 - 100 + 12)"
               [attr.width]="200"
               [attr.height]="200"
               alignment-baseline="middle"
               [attr.xlink:href]="process.data.attributes.icon"></image>

        <image  *ngIf = "!process.data.attributes.json.pad_image"
                [attr.y]="(process.height()/2 - 34 + 12)"
                [attr.x]="(process.width()/2 - 40 + 12)"
                [attr.width]="80"
                [attr.height]="80"
                alignment-baseline="middle"
                [attr.xlink:href]="process.data.attributes.icon"></image>
        <text
                [attr.class]="'process-text'"
                [attr.x]="process.text.x() + 12"
                [attr.y] ="process.text.y() + 12"
                text-anchor="middle"
                alignment-baseline="middle"
                [attr.font-size]="process.fontsize()"

        >
            {{process.data.attributes.name}}
        </text>
        <g *ngFor="let connector of process.connectors"
           class="connector input-connector">

            <circle
                    [attr.class]="'connector-circle'"
                    [attr.r]="10"
                    [attr.cx]="connector.x() + 12"
                    [attr.cy]="connector.y() + 12"

            >
            </circle>
        </g>


    </g>
</svg>
