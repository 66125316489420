import * as Handsontable from "handsontable";
import {Model} from "./api.service";
import * as utils from "../lib/utils";

export class HotInstance {
    instance: Handsontable;
    window_height = window.innerHeight - 240;
    resource: Model;
    change_ids: string[];
    handson_id: string;
    save: () => { deferreds: Promise<any>[]; data: any; savePromises: Promise<any>[]; errors: any; };
    settings: any;
    ready: Boolean;
    never_searched: Boolean;
    original_data: any[];
    column_list: any[];
    account: any; //This is not the site Active Account, this is the "filter table by" account

    // TODO series component, process access, schedule have different required_keys, set them when invoked

    constructor(public required_keys: string[] = ["attributes.name"]) {
        this.handson_id = utils.guid();
        // @ts-ignore
        Handsontable.renderers.registerRenderer('date_formatter', utils.dRenderer);
    }

    onResize(event) {
        const ctrl = this;
        if (ctrl.ready) {
            ctrl.window_height = window.innerHeight - 240;

            ctrl.instance.render();
            // ctrl.instance.render();
        }
    }

    toCSVString() {
        const instance = this.instance;
        const headers = instance.getColHeader();

        let csv = ""; //"sep=;\n"
        //@ts-ignore
        csv += headers.join(",") + "\n";

        for (let i = 0; i < instance.countRows(); i++) {
            const row = [];
            //@ts-ignore
            for (let h in headers) {
                //@ts-ignore
                const prop = instance.colToProp(h);
                //@ts-ignore
                const value = instance.getDataAtRowProp(i, prop);
                row.push(value)
            }

            csv += row.join(",");
            csv += "\n";
        }

        return csv;
    }

    download(filename?) {
        const csv = this.toCSVString();
        if (filename == null) {
            filename = 'wire.csv'
        }
        const link = document.createElement("a");
        link.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(csv));
        link.setAttribute("download", filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
    }

    clear() {
        const hotInstance = this;
        if (!hotInstance.never_searched) {
            hotInstance.instance.loadData(hotInstance.original_data);
            if (this.account) {
                this.filter_account(this.account);
            }
            hotInstance.instance.render();
            hotInstance.instance.render();
        }
    }

    filter(search) {
        const hotInstance = this;
        if (hotInstance.never_searched) {
            hotInstance.original_data = utils.deepCopy(hotInstance.instance.getSourceData())
        }
        hotInstance.never_searched = false;

        const countSourceRows = hotInstance.instance.countSourceRows() - hotInstance.settings.minSpareRows - 1;
        const hidden_rows = [];
        const show_rows = [];
        const show_data = [];
        for (let row = 0; row <= countSourceRows; row++) {
            let hide_row = true;
            for (let col = 0; col <= hotInstance.column_list.length; col++) {
                const val = hotInstance.instance.getSourceDataAtCell(row, col);
                if (('' + val).toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    hide_row = false;
                }
            }

            if (hide_row) {
                hidden_rows.push(row)
            } else {
                show_rows.push(row);
                show_data.push(hotInstance.instance.getSourceDataAtRow(row))
            }

        }

        hotInstance.instance.loadData(show_data);
        hotInstance.instance.render();
        hotInstance.instance.render();
    }

    filter_account(account) {
        const ctrl = this;
        ctrl.account = account;

        if (!account) {
            this.clear();
            return;
        }
        if (ctrl.never_searched) {
            ctrl.original_data = utils.deepCopy(ctrl.instance.getSourceData());
        }
        ctrl.never_searched = false;

        ctrl.instance.loadData(ctrl.original_data);
        const countSourceRows = ctrl.instance.countSourceRows() - ctrl.settings.minSpareRows - 1;
        const hidden_rows = [];
        const show_rows = [];
        const show_data = [];
        for (let row = 0; row <= countSourceRows; row++) {
            let hide_row = true;
            const val = ctrl.instance.getSourceDataAtRow(row);
            if (val['attributes'] && val['attributes'].account_name && val['attributes'].account_name === account.attributes.name) {
                hide_row = false;
            } else {
                hide_row = true;
            }

            if (hide_row) {
                hidden_rows.push(row)
            } else {
                show_rows.push(row);
                show_data.push(ctrl.instance.getSourceDataAtRow(row))
            }
        }

        ctrl.instance.loadData(show_data);
        ctrl.instance.render();
        ctrl.instance.render();
    }
}