<div class="page traveler ore-forecasts">
  <div class="outputs">
    <h3>Open Stockpiles</h3>

    <table id="f_orecast" style="width:100%" style="overflow-y:auto;">
      <thead>
      <tr>
        <th>Ore Body (Name)</th>
        <th *ngFor="let col of custom_cols">{{col}}</th>
        <th>WMT Fed</th>
      </tr>
      </thead>

      <tr style="margin-top:10px;">
        <td style="text-align:left;">
          <a (click)="show_delivered=!show_delivered;" style="cursor:pointer;color:black;text-decoration:none;">
            <b>Delivered (Total)</b>
            <i class="fa fa-plus" style="float:right;margin-top:2px;width:20px;"></i></a></td>
        <td *ngFor="let col of custom_cols">
          <span *ngIf="totals['inputs'][col]">{{totals['inputs'][col] | number: '2.0-0'}}</span></td>
        <td></td>
      </tr>
      <ng-container *ngIf="show_delivered">
        <tr *ngFor="let stock of stockpiles">
          <td style="text-align:left;text-indent:10px;">{{stock.attributes.name }}</td>
          <td *ngFor="let col of custom_cols">{{delivered[stock.id][col] | number: '2.0-0'}}</td>
          <td>
            <!--<input type="number" style = "width:95%"-->
            <!--ng-model="stock.fcast_wmt"-->
            <!--placeholder="Enter forecast WMT"-->
            <!--ng-change="calculate()"-->
            <!--ng-model-options="{updateOn: 'blur'}">-->
          </td>
        </tr>
      </ng-container>
      <tr style="margin-top:10px;">
        <td style="text-align:left;">
          <a (click)="show_contained=!show_contained;" style="cursor:pointer;color:black;text-decoration:none;">
            <b>Remaining (Total)</b>
            <i class="fa fa-plus" style="float:right;margin-top:2px;width:20px;"></i>
          </a></td>
        <td *ngFor="let col of custom_cols">
          <span *ngIf="totals['contained'][col]">{{totals['contained'][col] | number: '2.0-0'}}</span></td>
        <td></td>
      </tr>

      <ng-container *ngIf="show_contained">
        <tr *ngFor="let stock of stockpiles">
          <td style="text-align:left;text-indent:10px;">{{stock.attributes.name }}</td>
          <td *ngFor="let col of custom_cols">{{contained[stock.id][col] | number: '2.0-0'}}</td>
          <td>
            <input ng-change="calculate()" ng-model="stock.fcast_wmt"
                   ng-model-options="{updateOn: 'blur'}"
                   placeholder="Enter forecast WMT"
                   style="width:95%"
                   type="number">
          </td>
        </tr>
      </ng-container>
      <tr style="margin-top:10px;">
        <td style="text-align:left;">
          <a (click)="show_fed=!show_fed;" style="cursor:pointer;color:black;text-decoration:none;">
            <b>Fed (Total)</b>
            <i class="fa fa-plus" style="float:right;margin-top:2px;width:20px;"></i></a></td>
        <td *ngFor="let col of custom_cols">
          <span *ngIf="totals['outputs'][col]">{{totals['outputs'][col] | number: '2.0-0'}}</span></td>
        <td></td>
      </tr>

      <ng-container *ngIf="show_fed">
        <tr *ngFor="let stock of stockpiles">
          <td style="text-align:left;text-indent:10px;">{{stock.attributes.name }}</td>
          <td *ngFor="let col of custom_cols">{{fed[stock.id][col] | number: '2.0-0'}}</td>
          <!--<td *ngFor="let col of custom_cols">-->
          <!--{{ stock.attributes.stockpile_properties.inputs[col] | number:2}}</td>-->
          <td>
            <!--<input type="number" style = "width:95%"-->
            <!--ng-model="stock.fcast_wmt"-->
            <!--placeholder="Enter forecast WMT"-->
            <!--ng-change="calculate()"-->
            <!--ng-model-options="{updateOn: 'blur'}">-->
          </td>
        </tr>
      </ng-container>
      <!--<tr style="margin-top:10px;">-->
      <!--<td style="text-align:left;"><b>TOTAL</b>-->
      <!--<a (click)="show_totals=!show_totals;">-->
      <!--<i class="fa fa-plus"  style="float:right;margin-top:2px;"></i></a></td>-->
      <!--<td *ngFor="let col of custom_cols">-->
      <!--<span *ngIf="totals_dict[col]">{{totals_dict[col]|number:2}}</span></td>-->
      <!--<td></td>-->
      <!--&lt;!&ndash;<td></td>&ndash;&gt;-->
      <!--</tr>-->
      <tr>
        <td style="text-align:left;"><b>Monthly Forecast</b></td>
        <td *ngFor="let col of custom_cols">
          <span *ngIf="summary[col]">{{summary[col].Forecast | number: '2.0-0'}}</span></td>
        <td></td>
      </tr>
      <tr>
        <td style="text-align:left;"><b>Month to Date Actual</b></td>
        <td *ngFor="let col of custom_cols">
          <span *ngIf="summary[col]">{{summary[col].MTD | number: '2.0-0'}}</span></td>
        <td></td>
      </tr>
      <tr>
        <td style="text-align:left;"><b>Month to Date Forecast</b></td>
        <td *ngFor="let col of custom_cols">
          <span *ngIf="summary[col]">{{summary[col]['MTD Forecast'] | number: '2.0-0'}}</span></td>
        <td></td>
      </tr>
      <tr>
        <td style="text-align:left;"><b>Daily Plan</b></td>
        <td *ngFor="let col of custom_cols">
          <span *ngIf="forecast_dict[col]">{{forecast_dict[col] | number: '2.0-0'}}</span></td>
        <td></td>
      </tr>
    </table>

  </div>

  <!--&lt;!&ndash;Div that will hold the bar chart&ndash;&gt;-->
  <!--<div class = outputs id="chart2_div"></div>-->

  <!--<div class = outputs id="chart1_div"></div>-->

  <!--<div  class = "details">-->
  <!--<h2>  Calculations  </h2>-->
  <!--</div>-->

</div>