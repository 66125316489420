import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {AppScope} from "../services/app_scope.service";
import {MatDialog} from "@angular/material";
import {DateTimePeriodService} from "../services/datetime_period.service";
import {HeaderDataService} from "../services/header_data.service";
import {PlantDataService} from "../services/plant_data.service";
import {UserData} from "../services/user_data.service";
import {UploadFormDialogComponent} from "../forms/upload_form.component";
import {EditHistoryComponent} from "../forms/edit-history.component";
import {ApiService} from "../services/api.service";
import {PageViewFormComponent} from "../forms/page_view_form.component";
import {Router} from "@angular/router";
import {PresentationService} from "../services/presentation.service";
import {MenuTreeService} from "../services/menu-tree.service";

function createMenuItem(name, link, item_class?, angular_link?) {
    return {
        'name': name,
        'link': link,
        'class': item_class,
        angular_page: angular_link
    }
}

function createMenuDividerItem() {
    return {class: 'md-menu-divider'};
}

function createTopMenu(name, items, menu_class?) {
    return {
        'name': name,
        'items': items,
        'class': menu_class
    }
}

export interface MenuItem {
    name?: string;
    link?: string;
    class?: string;
    angular_page?: boolean
}

export interface Menu {
    name?: string;
    items?: MenuItem[];
    class?: string;
}

@Component({
    selector: 'header-menu',
    templateUrl: 'header.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['header.component.less']
})

export class HeaderComponent implements OnInit {
    restricted_access: any;
    user: any;
    launch_admin_gui: any;
    actual_restricted_dashboards: any;
    top_menu: Menu[] = [];
    right_menu: Menu = {
        'name': '',
        'items': [],
        'class': ''
    };
    right_logo: string;
    session: any;
    @Output()
    finishedLoading: EventEmitter<boolean> = new EventEmitter();

    constructor(private plantData: PlantDataService,
                public headerData: HeaderDataService,
                public dialog: MatDialog,
                public appScope: AppScope,
                private menuTreeService: MenuTreeService,
                private userData: UserData,
                public dateTimePeriodService: DateTimePeriodService,
                public api: ApiService,
                private router: Router,
                private presentationService: PresentationService) {
    }

    ngOnInit(): void {
        const ctrl = this;

        Promise.all([ctrl.dateTimePeriodService.dtp_complete.promise,
            ctrl.appScope.auth_complete.promise]).then(() => {

            ctrl.headerData.isImage('/admin/myfiles/download/right-logo.png').then(test => {
                if (test === true) {
                    ctrl.right_logo = '/admin/myfiles/download/right-logo.png';
                } else {
                    ctrl.right_logo = '/static/images/right-logo1.png';
                }
            });
            this.finishedLoading.emit(true);
        });
    }

    refreshDTP() {
        this.api.cancelActiveQueries();
        //For components subscribing to dtp service reset (non tiles)
        this.dateTimePeriodService.dtpReset.emit(this.dateTimePeriodService.dtp);
        //For components with dtp service provider injected that, therefore, subscribe to headerData.dtpReset
        this.headerData.dtpReset.emit(this.dateTimePeriodService.dtp);
    }

    downloadData(): void {
        this.headerData.downloadDataClicked.next()
    };

    uploadData(): void {
        const dialogRef = this.dialog.open(UploadFormDialogComponent, {})
    };

    buildRestricted() {
        const ctrl = this;
        let menu_items = [];

        if (ctrl.restricted_access) {
            ctrl.actual_restricted_dashboards.forEach(dashboard => {
                menu_items.push(createMenuItem(dashboard.attributes.name, 'view/page_view/' + dashboard.id, '', true));
            });

            if (ctrl.user.json && ctrl.user.json.user_views) {
                ctrl.user.json.user_views.forEach(view => {
                    menu_items.push(createMenuItem(view.name, view.url));
                })
            }
            ctrl.top_menu.push(createTopMenu('My Views', menu_items));

        }

    }

    goToUrl(url) {
        window.open(url, "_blank");
    }

    openEditForm() {
        const dialogRef = this.dialog.open(EditHistoryComponent, {})
    }


    togglePresent() {
        this.presentationService.togglePresent();
    }
}


